import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

interface PermissionDisplay {
	label: string;
	description: string;
	controlName: string;
}

interface ClassPermissionsForm {
	canCreate: boolean;
	canEdit: boolean;
}

@Component({
	selector: 'sp-class-permissions-form',
	templateUrl: './class-permissions-form.component.html',
})
export class ClassPermissionsFormComponent implements OnInit {
	@Input() form: FormGroup;
	@Input() teachersCanAddClasses = false;
	@Input() teachersCanEditClasses = true;
	@Output() onChanges = new Subject<boolean>();
	@Output() formValueChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

	permissions: PermissionDisplay[] = [
		{
			label: 'Teacher Can Create New Classes',
			description: 'Allow teachers to create new classes.',
			controlName: 'canCreate',
		},
		{
			label: 'Teacher Can Edit Classes',
			description: 'Allow teachers to edit classes and accept classes from an integration.',
			controlName: 'canEdit',
		},
	];

	ngOnInit(): void {
		this.initForm();
		this.setupValueChanges();
	}

	private initForm(): void {
		this.form = new FormGroup({
			[this.permissions[0].controlName]: new FormControl(this.teachersCanAddClasses),
			[this.permissions[1].controlName]: new FormControl(this.teachersCanEditClasses),
		});
	}

	private setupValueChanges(): void {
		this.form.valueChanges
			.pipe(
				tap(({ canCreate, canEdit }: ClassPermissionsForm) => {
					if (canCreate && !canEdit) {
						canEdit = true;
						this.form.patchValue({ canEdit: canEdit });
					}
					return { canCreate, canEdit };
				}),
				tap(({ canCreate, canEdit }: ClassPermissionsForm) => {
					const hasChanges = canCreate !== this.teachersCanAddClasses || canEdit !== this.teachersCanEditClasses;
					this.onChanges.next(hasChanges);
					this.formValueChanges.emit(this.form);
				})
			)
			.subscribe();
	}
}
