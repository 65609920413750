import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, iif, of } from 'rxjs';
import { switchMap, takeUntil, filter, tap } from 'rxjs/operators';
import { User } from '../models/User';
import { HttpService } from '../services/http-service';
import { UserService } from '../services/user.service';
import { QRBarcodeGeneratorService } from '../services/qrbarcode-generator.service';
import { ScreenService } from '../services/screen.service';
import { SideNavService } from '../services/side-nav.service';
import { NavbarAnimations } from '../navbar/navbar.animations';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { IDCard, IDCardService, IDCardResponse } from '../services/IDCardService';
import { SettingAction, SettingsComponent } from '../settings/settings.component';
import { MyProfileDialogComponent } from '../my-profile-dialog/my-profile-dialog.component';
import { SpLanguageComponent } from '../sp-language/sp-language.component';
import { SpAppearanceComponent } from '../sp-appearance/sp-appearance.component';
import { DarkThemeSwitch } from '../dark-theme-switch';

declare const window;
@Component({
	selector: 'sp-id-card-standalone',
	templateUrl: './id-card-standalone.component.html',
	styleUrls: ['./id-card-standalone.component.scss'],
	animations: [NavbarAnimations.inboxAppearance, NavbarAnimations.arrowAppearance],
})
export class IdCardStandaloneComponent implements OnInit, OnDestroy {
	@Input() backsideText: string;
	private destroyer$ = new Subject<void>();
	isStaff: boolean;
	private user: User;
	private isAssistant: boolean;
	private IDCardEnabled = false;
	IDCARDDETAILS: IDCard;
	IDCardstickers = [];

	private isOpenSettings: boolean;
	private showSwitchButton = false;

	@ViewChild('navbar') navbar: ElementRef;
	@Output() settingsClick: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('setButton') settingsButton: ElementRef;

	constructor(
		public router: Router,
		private dialog: MatDialog,
		private http: HttpService,
		public sideNavService: SideNavService,
		private userService: UserService,
		private idCardService: IDCardService,
		private qrBarcodeGenerator: QRBarcodeGeneratorService,
		public screenService: ScreenService,
		public darkTheme: DarkThemeSwitch
	) {
		window.appLoaded();
	}

	ngOnInit(): void {
		this.http.globalReload$
			.pipe(
				switchMap(() => {
					return this.userService.userJSON$.pipe(filter((u: User) => !!u));
				}),
				tap((user: User) => {
					if (user) {
						this.user = User.fromJSON(user);
						this.isStaff = this.user.isTeacher();
						this.isAssistant = this.user.isAssistant();
					}
				}),
				switchMap(() => {
					return iif(() => !!this.userService.getFeatureFlagDigitalID(), this.idCardService.getIDCardDetails(), of(null));
				}),
				tap(async (result: IDCardResponse) => {
					if (result?.results?.digital_id_card) {
						if (result.results.digital_id_card.enabled) {
							this.IDCardEnabled = true;
						} else {
							return;
						}
						this.IDCARDDETAILS = await this.parseIdCardData(result);
						if (this.IDCARDDETAILS.stickers) {
							this.IDCardstickers = JSON.parse(JSON.stringify(this.IDCARDDETAILS.stickers));
						}
						switch (result?.results?.digital_id_card?.visible_to_who) {
							case 'Staff only':
								this.user.isTeacher() || this.user.isAdmin() ? (this.IDCardEnabled = true) : (this.IDCardEnabled = false);
								break;
							case 'Students only':
								this.user.isStudent() ? (this.IDCardEnabled = true) : (this.IDCardEnabled = false);
								break;
							case 'Students and Staff':
								this.IDCardEnabled = true;
								break;
							default:
								break;
						}
					}
				}),
				takeUntil(this.destroyer$)
			)
			.subscribe();

		this.darkTheme.switchTheme('Light');

		this.sideNavService.sideNavAction.pipe(takeUntil(this.destroyer$)).subscribe((action) => {
			if (action) {
				this.settingsAction(action);
			}
		});

		this.sideNavService.openSettingsEvent$
			.pipe(
				filter((r) => !!r),
				takeUntil(this.destroyer$)
			)
			.subscribe((res) => this.showOptions(this.settingsButton));
	}
	ngOnDestroy(): void {
		this.destroyer$.next();
		this.destroyer$.complete();
	}

	private async parseIdCardData(idCardResults: IDCardResponse): Promise<IDCard> {
		const idCard = {
			backgroundColor: idCardResults.results.digital_id_card.color,
			greadLevel: idCardResults.results.digital_id_card.show_grade_levels ? '10' : null,
			idNumberData: {
				idNumber: idCardResults.results.digital_id_card.id.toString(),
				barcodeURL: await this.qrBarcodeGenerator.selectBarcodeType(idCardResults.results.digital_id_card.barcode_type, '123456'),
			},
			barcodeType: idCardResults.results.digital_id_card.barcode_type,
			backsideText: idCardResults.results.digital_id_card.backside_text,
			logoURL: idCardResults.results.digital_id_card.signed_url,
			profilePicture: '',
			schoolName: 'Demo School',
			userName: this.user.display_name,
			userRole: this.user.isStudent() ? 'Student' : 'Teacher',
			showCustomID: idCardResults.results.digital_id_card.show_custom_ids,
		};
		return idCard;
	}
	// todo I don't think this is ever actually used
	private settingsAction(action: SettingAction): void {
		switch (action) {
			case SettingAction.Appearance:
				this.dialog.open(SpAppearanceComponent, {
					panelClass: 'sp-form-dialog',
				});
				break;
			case SettingAction.Language:
				this.dialog.open(SpLanguageComponent, {
					panelClass: 'sp-form-dialog',
				});
				break;
			case SettingAction.Privacy:
				window.open('https://www.smartpass.app/privacy');
				break;
			case SettingAction.Profile:
				this.dialog.open(MyProfileDialogComponent, {
					panelClass: 'sp-form-dialog',
					width: '425px',
					height: '500px',
				});
				break;
			case SettingAction.SignOut:
				console.log('navigating to sign-out');
				this.router.navigate(['sign-out']);
				break;
			case SettingAction.SwagShop:
				window.open('https://shop.smartpass.app/');
				break;
			case SettingAction.Term:
				window.open('https://www.smartpass.app/terms');
				break;
		}
	}

	private showOptions(event: ElementRef): void {
		if (!this.isOpenSettings) {
			if (this.screenService.isDeviceLargeExtra) {
				this.sideNavService.toggle$.next(true);
				this.sideNavService.toggleLeft$.next(true);
			}

			const target = new ElementRef(event.nativeElement.target);
			this.isOpenSettings = true;
			UNANIMATED_CONTAINER.next(true);
			const settingRef = this.dialog.open(SettingsComponent, {
				panelClass: ['calendar-dialog-container', 'animation'],
				backdropClass: 'invis-backdrop',
				data: {
					trigger: target,
					isSwitch: this.showSwitchButton,
					settings: [
						{ hidden: false, background: '#134482', icon: 'Username', action: 'profile', title: 'My Profile', isNew: false, adjustForScroll: true },
						{ hidden: false, background: '#134482', icon: 'Language', action: 'language', title: 'Language', isNew: false, adjustForScroll: true },
						{ hidden: false, background: '#134482', icon: 'Swag Shop', action: 'swagShop', title: 'Swag Shop', isNew: false, adjustForScroll: true },
					],
				},
			});

			settingRef.afterClosed().subscribe((action) => {
				UNANIMATED_CONTAINER.next(false);
				this.isOpenSettings = false;
				this.settingsAction(action);
			});

			this.settingsClick.emit({
				trigger: target,
				isSwitch: this.showSwitchButton,
			});

			this.sideNavService.sideNavData$.next({
				trigger: target,
				isSwitch: this.showSwitchButton,
			});

			this.sideNavService.sideNavType$.next('left');
		}
	}

	print(): void {
		window.print();
	}

	ngAfterViewInit(): void {
		window.appLoaded();
	}
}
