<div *ngIf="!mock" [formGroup]="form" [ngClass]="{ layout: defaultLayout, delimiter: delimiter, padding: padding, disabled: disabled }">
	<div [style.padding-top]="'5px'" *ngIf="controlLabel">
		<span class="ds-cell-text-2" style="text-transform: capitalize">{{ controlLabel }}</span>
	</div>
	<div [ngClass]="customClass ? 'togle_cstm_wrp disable_togle' : ''" style="display: flex; align-items: center">
		<div class="togle_cstm_icon" *ngIf="disabled && disabledWithLock">
			<img [src]="'./assets/Lock (Blue-Gray).svg' | resolveAsset" width="14" height="14" style="margin: 0 10px" alt="Lock (Blue-Gray).svg" />
		</div>
		<div class="checkbox-container" [style.color]="color" [ngClass]="sizedLayout" [ngStyle]="{ 'pointer-events': disabled ? 'none' : 'auto' }">
			<input
				type="checkbox"
				[attr.checked]="checked ? checked : null"
				class="ios-toggle checkbox1"
				style="display: none"
				[formControlName]="controlName"
				#inp
				[id]="controlName" />
			<label
				[for]="controlName"
				class="checkbox-label"
				[style.box-shadow]="inp.checked ? 'inset 0 0 0 18px ' + color + ',0 0 0 2px ' + color : ''"></label>
			<img
				class="icon"
				appCrossPointerEventTarget
				(pointerClickEvent)="disabled || inp.click()"
				*ngIf="inp.checked && hasIcon"
				[src]="icon | resolveAsset" />
		</div>
	</div>
</div>
<div *ngIf="mock" class="layout">
	<div [style.padding-top]="'5px'" *ngIf="controlLabel">
		<span class="ds-cell-text-2">{{ controlLabel }}</span>
	</div>
	<div style="display: flex; align-items: center">
		<div class="checkbox-container" [ngClass]="sizedLayout">
			<input type="checkbox" class="ios-toggle checkbox1" checked style="display: none" #inp />
			<label class="checkbox-label"></label>
		</div>
	</div>
</div>
