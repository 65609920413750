import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavbarDataService } from '../main/navbar-data.service';
import { DarkThemeSwitch } from '../dark-theme-switch';
import { ScreenService } from '../services/screen.service';

@Component({
	selector: 'app-display-card',
	templateUrl: './display-card.component.html',
	styleUrls: ['./display-card.component.scss'],
})
export class DisplayCardComponent {
	@Input() intro: number = null;
	@Input() backgroundColor: string;
	@Input() headerBottomSpace = '15px';
	@Input() hasDivider = true;
	@Input() inbox = false;
	@Input() title: string;
	@Input() icon: string;
	@Input() iconSize = '20px';
	@Input() fontSize: string;
	@Input() righticon: string;
	@Input() subtitle: string;
	@Input() subtitle_fontSize: string;
	@Input() righttext: string;
	@Input() hasHeader = true;
	@Input() width = '428px';
	@Input() sortIcon: any;
	@Input() isStaff = false;
	@Output() sortIconClick = new EventEmitter<void>();
	constructor(public darkTheme: DarkThemeSwitch, public screenService: ScreenService) {}

	getIcon(icon) {
		return this.darkTheme.getIcon({
			iconName: icon,
			darkFill: !this.intro ? 'White' : 'Navy',
			lightFill: 'Navy',
			setting: null,
		});
	}

	get titleColor() {
		return this.darkTheme.getColor();
	}
}
