<div class="no-fly-time-admin-nux-container">
	<div class="new-bubble">
		<span> NEW </span>
	</div>
	<div class="no-fly-time-text">
		<span>“No Fly Time” blocks students from creating passes during certain time ranges.</span>
	</div>

	<div class="buttons">
		<app-gradient-button [gradient]="'#7083A0'" [width]="'100px'" [minHeight]="'41px'" (buttonClick)="directToNoFlyTimeDoc()">
			Learn More
		</app-gradient-button>
		<app-gradient-button [gradient]="'#00B476'" [width]="'65px'" [minHeight]="'41px'" (buttonClick)="close()"> Got it </app-gradient-button>
	</div>
</div>
