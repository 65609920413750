import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TimeInputComponent } from '../time-input/time-input.component';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

/*
**************************************
* TimeRangeInputComponent Help Guide *
**************************************


Setting up TimeRangeInputComponent is easy. You just need to pass a FormControl
to it. Default values and basic error validation will be automatically taken
care of:
```
public timeControl = new FormControl();
```

```
<sp-time-range-input [control]="timeControl"></sp-time-range-input>
```

The control will track the range via an object `{start: string, end: string}`.
The strings are the times in 24 clock format. So 4:38 PM start time, 11:22 PM 
end time would be `{start: '16:38', end: '23:22'}`. You can represent a blank
field with `''` and can set you own initial values in the control.

For example, if you want the start to be 2 PM and the end to be blank, you 
would do the following:
```
public timeControl = new FormControl({start: '14:00', end: ''});
```

You can also disable the form or set values in the parent. To disable, add
`timeControl.disable()`. To set value:
`timeControl.setValue({start: '06:01', end: '07:44'})`


Finally, you can also add you own validators. When you set an error, you can
have a message for the `start` input, the `end` input, or `both`. You do this 
by having your validator return an error formatted like
`{ start: 'Start input is bad'}`. When you set a custom error, it takes priority
over the default errors.

The following validator sets a custom message while erroring both fields when
`start` is after `end` and it's 11:11 AM:

```
public timeControl = new FormControl(null, validateTimeRange);

private validateTimeRange(control: FormControl): { [key: string]: string } | null {
	const { start, end } = control.value || {};
	if (start && start >= end && start === '11:11') {
		return { both: 'Whacky doo, a custom error for you!' };
	}
	return null;
}

Hint: if you want to change the "Range must end after it starts" message, add a
custom validator!

```
*/

@Component({
	selector: 'sp-time-range-input',
	templateUrl: './time-range-input.component.html',
	styleUrls: ['./time-range-input.component.scss'],
})
export class TimeRangeInputComponent {
	@Input() control!: FormControl;
	@Input() additionalDuration = 10; // Default duration is 10 minutes

	@Output() onInputChange: EventEmitter<{ start: string; end: string }> = new EventEmitter<{ start: string; end: string }>();
	@Output() onFocusChange: EventEmitter<{ startInputFocused: boolean; endInputFocused: boolean }> = new EventEmitter<{
		startInputFocused: boolean;
		endInputFocused: boolean;
	}>();

	@ViewChild('startInput') startInput!: TimeInputComponent;
	@ViewChild('endInput') endInput!: TimeInputComponent;

	startInputFocused = false;
	endInputFocused = false;
	errorMsg = '';
	initStartTime: string;
	initEndTime: string;
	initError = 1;
	private destroy$ = new Subject<void>();
	private prevStart: string;
	private prevEnd: string;

	ngOnInit() {
		// Default next half time point from now and 10 minutes from start time
		const startTime: string =
			this.control?.value?.start !== undefined && this.control?.value?.start !== null
				? this.control.value.start
				: new Date(Math.ceil(new Date().getTime() / (30 * 60 * 1000)) * (30 * 60 * 1000)).toLocaleTimeString('en-GB', {
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
				  });
		const endTime =
			this.control?.value?.end !== undefined && this.control?.value?.end !== null
				? this.control.value.end
				: new Date(new Date('1970-01-01T' + startTime + ':00Z').getTime() + this.additionalDuration * 60000).toISOString().substring(11, 16);

		this.control.setValue({
			start: startTime,
			end: endTime,
		});
		this.initStartTime = startTime;
		this.initEndTime = endTime;

		// Hide initial error if initial values are null or empty
		if (!startTime || !endTime) {
			this.initError = 0;
		}

		this.control.setValidators(this.control?.validator ? [this.defaultTimeValidators, this.control.validator] : this.defaultTimeValidators);

		// Handle parent using setValues
		this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((time) => {
			this.prevStart = time.start;
			this.prevEnd = time.end;
			this.startInput.setInput(time.start);
			this.endInput.setInput(time.end);
		});
	}

	ngAfterViewInit() {
		// Track focus state
		this.startInput.anyInputFocusedChanged.pipe(takeUntil(this.destroy$)).subscribe((focused: boolean) => {
			this.startInputFocused = focused;
			this.onFocusChange.emit({ startInputFocused: focused, endInputFocused: this.endInputFocused });
		});
		this.endInput.anyInputFocusedChanged.pipe(takeUntil(this.destroy$)).subscribe((focused: boolean) => {
			this.endInputFocused = focused;
			this.onFocusChange.emit({ startInputFocused: this.startInputFocused, endInputFocused: focused });
		});

		// Move cursor from start period to end hour
		this.startInput.switchToEndInput.pipe(takeUntil(this.destroy$)).subscribe((isArrowKey) => this.endInput.focusOnHour(isArrowKey));
		// Move cursor from end hour to start period
		this.endInput.switchToStartInput.pipe(takeUntil(this.destroy$)).subscribe((isArrowKey) => this.startInput.focusOnPeriod(isArrowKey));

		// Emit values to parent and build error
		combineLatest([
			this.startInput.timeFormValueChanges.pipe(startWith(this.initStartTime)),
			this.endInput.timeFormValueChanges.pipe(startWith(this.initEndTime)),
		])
			.pipe(
				distinctUntilChanged((prev, curr) => prev[0] === curr[0] && prev[1] === curr[1]),
				takeUntil(this.destroy$)
			)
			.subscribe(([start, end]: [string, string]) => {
				// If start time changes, end time should move forward the same amount
				if (this.prevStart && this.prevEnd && this.prevStart !== start) {
					end = this.calcNewEndTime(this.prevStart, this.prevEnd, start);
				}
				this.control.setValue({ start: start, end: end });
				if (this.initError > 1) {
					this.onInputChange.emit({ start: start, end: end });
				}

				// Hide error display if default values are bad
				this.initError++;
			});
	}

	private defaultTimeValidators(control: FormControl): { [key: string]: boolean } | null {
		const { start, end } = control.value || {};
		const errors = {};

		// Invalid times
		if (!start) {
			errors['start'] = 'Invalid time';
		}
		if (!end) {
			errors['end'] = 'Invalid time';
		}

		if (!start && !end) {
			errors['both'] = 'Invalid times';
		}

		// No input provided
		if (start === '') {
			errors['start'] = 'Please select a start time';
		} else if (end === '') {
			errors['end'] = 'Please select an end time';
		}

		// End before start
		if (start && start >= end) {
			errors['start'] = 'Pick a start time earlier than the end time';
		}

		return Object.keys(errors).length ? errors : null;
	}

	private calcNewEndTime(prevStart: string, prevEnd: string, currentStart: string): string {
		// Convert time strings to Date objects
		const prevStartDate = new Date(`1970-01-01T${prevStart}:00`);
		const prevEndDate = new Date(`1970-01-01T${prevEnd}:00`);
		const currentStartDate = new Date(`1970-01-01T${currentStart}:00`);

		// Calculate the difference in minutes
		const duration = (prevEndDate.getTime() - prevStartDate.getTime()) / (1000 * 60);

		// Add the duration to currentStart
		const newEndDate = new Date(currentStartDate.getTime() + duration * 60000);

		// Format the result back into "hh:mm" format
		const hours = newEndDate.getHours().toString().padStart(2, '0');
		const minutes = newEndDate.getMinutes().toString().padStart(2, '0');

		return `${hours}:${minutes}`;
	}
}
