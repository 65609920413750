<div>
	<form [formGroup]="holidayForm">
		<sp-input
			[group]="holidayForm"
			[control]="'holidayName'"
			[label]="'Name'"
			[description]="'Everyone will see this name, including students.'"
			[placeholder]="'Ex. Snow Day'"
			[isFocused]="holidayData?.type === 'add'"
			[errorMessage]="'This field is required.'"></sp-input>
	</form>
</div>
<div class="tw-pt-6">
	<label class="tw-text-base tw-text-navy-500 tw-font-semibold tw-mb-3 tw-block">{{ toggleResult == 'Single Day' ? 'Date' : 'Dates' }}</label>
	<div
		(click)="openCalendar($event)"
		class="tw-rounded-lg tw-border tw-border-solid tw-h-10 tw-cursor-pointer tw-pl-3 tw-pr-2.5 tw-flex tw-items-center tw-justify-between"
		[ngClass]="
			dateError?.error
				? 'tw-border-navy-500 tw-border-red-500'
				: isCalendarOpen
				? 'tw-border-navy-500 tw-bg-white'
				: 'tw-border-gray-150 tw-bg-gray-050'
		"
		[style.box-shadow]="
			dateError?.error ? '0px 0px 0px 4px rgba(227, 44, 102, 0.12)' : isCalendarOpen ? '0px 0px 0px 4px rgba(199, 210, 240, 0.40)' : ''
		">
		<p
			class="tw-text-navy-500 tw-text-sm tw-font-normal tw-my-0"
			*ngIf="(selectedDate.start | date : 'yyyy-MM-dd') === (selectedDate.end | date : 'yyyy-MM-dd')">
			{{ selectedDate.start | date : 'MMMM d, yyyy' : 'UTC' }}
		</p>
		<p
			class="tw-text-navy-500 tw-text-sm tw-font-normal tw-my-0"
			*ngIf="(selectedDate.start | date : 'yyyy-MM-dd') !== (selectedDate.end | date : 'yyyy-MM-dd')">
			{{
				(selectedDate.start | date : 'yyyy') !== (selectedDate.end | date : 'yyyy')
					? (selectedDate.start | date : 'MMMM d, yyyy' : 'UTC') + ' - ' + (selectedDate.end | date : 'MMMM d, yyyy' : 'UTC')
					: (selectedDate.start | date : 'MM' : 'UTC') !== (selectedDate.end | date : 'MM' : 'UTC')
					? (selectedDate.start | date : 'MMMM d' : 'UTC') + ' - ' + (selectedDate.end | date : 'MMMM d, yyyy' : 'UTC')
					: (selectedDate.start | date : 'MMMM d' : 'UTC') + ' - ' + (selectedDate.end | date : 'd, yyyy' : 'UTC')
			}}
		</p>
		<img
			class="tw-h-5 tw-transition tw-duration-200"
			[ngClass]="isCalendarOpen ? 'tw-transform tw-rotate-180' : ''"
			[src]="'./assets/Chevron Down (Gray).svg' | resolveAsset"
			alt="" />
	</div>
	<div *ngIf="dateError?.error" class="tw-text-red-500 tw-font-normal tw-text-sm tw-mt-4 tw-flex tw-items-center tw-gap-1">
		<img src="assets/Alert (Red).svg" alt="Error indicator" />{{ dateError?.message }}
	</div>
</div>

<ng-template #toggleCalendarModalBody>
	<div class="date-container tw-bg-white tw-rounded-lg">
		<app-admin-calendar-toggle
			[date]="calendarSelectedDates"
			[toggleOptions]="['Single Day', 'Multiple Days']"
			[openCalendar]="true"
			[toggleResult]="toggleResult"
			[useUtcTime]="true"
			(settingsRes)="saveOptions($event)"
			(adminCalendarRes)="calendarResult($event)"></app-admin-calendar-toggle>
	</div>
</ng-template>
