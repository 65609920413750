<div class="assigned-teachers">
	<div
		*ngFor="let teacher of results$ | async; let i = index"
		#chip
		class="teacher"
		data-cy="chip"
		(mouseenter)="chipHover(chip, true); setChipHover(i, true)"
		(mouseleave)="chipHover(chip, false); setChipHover(i, false)"
		[ngClass]="{ hovered: chipHovered[i] }">
		<div
			*ngIf="teacher.profile_picture; else d"
			[ngStyle]="{ background: 'url(' + teacher.profile_picture + ') no-repeat left center/cover' }"
			class="profile-picture"></div>
		<ng-template #d>
			<ng-container *ngIf="teacher.first_name; else roomImage">
				<img class="profile-picture" src="../../../../../assets/Avatar (Gray-400).svg" alt="Avatar" />
			</ng-container>
		</ng-template>

		<ng-template #roomImage>
			<div
				class="room-icon-wrapper"
				[style.background]="roomIconsAndGradients[i].roomGradient"
				*ngIf="roomIconsAndGradients$ | async as roomIconsAndGradients">
				<img class="room-picture profile-picture" [src]="roomIconsAndGradients[i].icon" alt="Avatar" />
			</div>
		</ng-template>

		<ng-container *ngIf="selectedTarget == 'orgunits' && orgUnitDeleteCheck(teacher); else nonDeleted">
			<img *ngIf="selectedTarget === 'roles'" width="16" style="margin-right: 5px" [src]="teacher.icon" alt="Icon" />
			<span class="chip-field" customToolTip [contentTemplate]="tooltip" [style.cursor]="'default'" [style.user-select]="'none'">
				<span class="material-icons" style="color: #f5de82">warning</span>
				<span class="chname"
					><span style="font-weight: bold" *ngIf="!!textPrepend">{{ textPrepend }}</span>
					{{
						selectedTarget === 'users'
							? teacher.display_name
							: selectedTarget === 'roles'
							? teacher.role
							: selectedTarget === 'rooms'
							? teacher.title
							: teacher.as !== undefined
							? teacher.as
							: teacher
					}}
				</span>
			</span>
			<div *ngIf="chip.hovered && !teacher.lockAccount" class="delete">
				<img
					(click)="selectedProfiles.splice(i, 1); removeSelectedProfile(); updateSelectedEvent.emit(this.selectedProfiles)"
					[src]="'./assets/Cancel (Navy).svg' | resolveAsset"
					width="16"
					alt="Check.svg" />
			</div>
		</ng-container>

		<ng-template #nonDeleted>
			<img *ngIf="selectedTarget === 'roles'" width="16" style="margin-right: 5px" [src]="teacher.icon" alt="Icon" />
			<span #nonDeleted [style.cursor]="'default'" [style.user-select]="'none'">
				<span style="font-weight: bold" *ngIf="!!textPrepend">{{ textPrepend }}</span>
				{{
					selectedTarget === 'users'
						? teacher.display_name
						: selectedTarget === 'roles'
						? teacher.role
						: selectedTarget === 'rooms' || selectedTarget === 'roomsinfolders'
						? teacher.title
						: teacher.as !== undefined
						? teacher.as
						: teacher
				}}
			</span>
			<div
				*ngIf="!teacher.lockAccount"
				class="delete"
				data-cy="chip-delete-btn"
				[ngClass]="{ hovered: iconHovered[i] }"
				(mouseover)="setIconHover(i, true)"
				(click)="selectedProfiles.splice(i, 1); removeSelectedProfile(); updateSelectedEvent.emit(this.selectedProfiles)"
				(mouseleave)="setIconHover(i, false)">
				<img class="close-x" [src]="'./assets/close-x-tiny.svg' | resolveAsset" />
			</div>
		</ng-template>

		<ng-template #tooltip>
			<div class="ds-tooltip">This Organizational unit or group has been deleted in Google</div>
		</ng-template>
	</div>
	<app-gradient-button
		class=""
		*ngIf="!hideAddButton"
		[disabled]="addButtonDisable"
		[size]="'editable'"
		[minWidth]="'100px'"
		[minHeight]="'31px'"
		[textColor]="buttonTextColor"
		[gradient]="buttonGradient"
		[border]="addButtonBorder ? addButtonBorder : null"
		[buttonDownColor]="'#F4F4F4'"
		[customShadow]="addButtonShadow ? addButtonShadow : ''"
		[leftIcon]="buttonLeftIcon | resolveAsset"
		[leftImageWidth]="'16px'"
		[leftImageHeight]="'16px'"
		[withShadow]="false"
		[fontSize]="'14px'"
		(buttonClick)="add.emit(true)"
		>{{ textAddButton }}</app-gradient-button
	>

	<app-gradient-button
		*ngIf="!!suggestedTeacher && isProposed"
		[style.margin-left]="'10px'"
		[size]="'editable'"
		[minWidth]="'100px'"
		[minHeight]="'31px'"
		[textColor]="'#7F879D'"
		[gradient]="'#F4F4F4, #F4F4F4'"
		[buttonDownColor]="'#F4F4F4'"
		[leftIcon]="'./assets/Plus (Blue-Gray).svg' | resolveAsset"
		[leftImageWidth]="'16px'"
		[leftImageHeight]="'16px'"
		[withShadow]="false"
		[fontSize]="'14px'"
		(buttonClick)="addSuggestedTeacher.emit(suggestedTeacher)"
		>Suggested: <span notranslate>{{ suggestedTeacher.display_name }}</span></app-gradient-button
	>
</div>
