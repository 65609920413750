import * as React from 'react';

interface IDimBackgroundProps {
	visible: boolean;
	className?: string;
}

export function DimBackground({ visible, className = '' }: IDimBackgroundProps): React.ReactElement {
	if (!visible) {
		return null;
	}

	return <div className={`tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-40 tw-animate-fadein-300 ${className}`}></div>;
}
