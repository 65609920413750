import { Component, Input } from '@angular/core';
import { PassLimit, Location, Pinnable } from '../../models';
import { LocationsService } from '../../services/locations.service';

const greyHandIcon = './assets/Hand (Blue-Gray).svg';
const whiteHandIcon = './assets/Hand (White).svg';

@Component({
	selector: 'sp-pass-counter-chip',
	templateUrl: './pass-counter-chip.component.html',
	styleUrls: ['./pass-counter-chip.component.scss'],
})
export class PassCounterChipComponent {
	@Input() location: Location;
	@Input() pinnable: Pinnable;

	@Input() set passLimit(pl: PassLimit) {
		if (!this.location || !pl) {
			return;
		}

		this.countFrom = pl.from_count;
		this.countFromLimit = pl.max_passes_from;
		this.roomFull = pl.from_count >= this.location.max_passes_from;
		this.icon = this.roomFull ? whiteHandIcon : greyHandIcon;
		this.tooltipDescription = this.locationService.tooltipDescription('none', pl, 'fromRoom');

		if (this.pinnable && this.roomFull) {
			const gradient: string[] = this.pinnable?.color_profile?.gradient_color.split(',');
			this.backgroundColor = 'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')';
			this.textColor = 'white';
		} else {
			this.backgroundColor = '#eaedf1';
			this.textColor = '#7083a0';
		}
	}

	private roomFull: boolean;
	countFrom: number;
	countFromLimit: number;
	icon: string;
	tooltipDescription: string;
	backgroundColor = '#eaedf1';
	textColor = '#7083a0';

	constructor(private locationService: LocationsService) {}
}
