<div class="no-fly-time-container-kiosk">
	<div class="icon-container">
		<img class="no-fly-time-icon" [src]="'./assets/no_fly_time.svg' | resolveAsset" />
		<img class="close-icon" [src]="'./assets/Close (Grey).svg' | resolveAsset" alt="" (click)="closeDialog()" />
	</div>
	<div class="no-fly-time-text">
		<span class="header-text"> No Fly Time </span>
		<span> You can't create</span>
		<span> passes during these times: </span>
	</div>

	<div class="no-fly-time-list">
		<span *ngFor="let nft of nftTextList; let i = index" [ngClass]="{ 'active-no-fly-time-text': i == currentNoFlyTimeIndex }">
			{{ nft }}
		</span>
	</div>
</div>
