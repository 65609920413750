<div class="wrapper" [style.width]="screenService.isDeviceSmall ? '250px' : '331px'" [style.background-color]="isConnect ? '#00B476' : '#E32C66'">
	<div class="title" *ngIf="isConnect">Connected</div>
	<div class="title" *ngIf="!isConnect">Offline</div>
	<div class="reload-icon" *ngIf="!isConnect">
		<svg width="18px" height="18px" viewBox="0 0 150 150" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
			<title>Refresh (White)</title>
			<desc>Created with Sketch.</desc>
			<g id="Refresh-(White)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="icons8-restart_filled" fill="#FFFFFF" fill-rule="nonzero">
					<path
						d="M75,0.000654987424 C72.6480266,-0.0326076252 70.4603389,1.20309732 69.2746453,3.23459423 C68.0889517,5.26609115 68.0889517,7.77864013 69.2746453,9.81013705 C70.4603389,11.841634 72.6480266,13.0773389 75,13.0440763 C109.29497,13.0440763 136.956522,40.7055077 136.956522,75.0003275 C136.956522,109.295147 109.29497,136.956579 75,136.956579 C40.7050304,136.956579 13.0434783,109.295147 13.0434783,75.0003275 C13.0434783,57.1445392 20.5696223,41.1138646 32.6086957,29.8196476 L32.6086957,42.3917742 C32.5754329,44.7437373 33.8111432,46.9314155 35.842649,48.1171039 C37.8741548,49.3027924 40.3867148,49.3027924 42.4182205,48.1171039 C44.4497263,46.9314155 45.6854367,44.7437373 45.6521739,42.3917742 L45.6521739,6.52236564 L9.7826087,6.52236564 C7.43063533,6.48910303 5.24294762,7.72480797 4.05725399,9.75630489 C2.87156036,11.7878018 2.87156036,14.3003508 4.05725399,16.3318477 C5.24294762,18.3633446 7.43063533,19.5990496 9.7826087,19.5657869 L24.5393172,19.5657869 C9.47837446,33.2922922 0,53.0623526 0,75.0003275 C0,116.344308 33.6558391,150 75,150 C116.344161,150 150,116.344308 150,75.0003275 C150,33.6563472 116.344161,0.000654987424 75,0.000654987424 Z"
						id="Path"></path>
				</g>
			</g>
		</svg>
	</div>
</div>
