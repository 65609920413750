import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogDataWithWizard, DynamicDialogModalComponent } from './dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogData } from './dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogOptions } from './dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from './dynamic-dialog.service';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';

@Injectable({
	providedIn: 'root',
})
export class DialogFactoryService<T = undefined> {
	constructor(private dialog: MatDialog) {}

	open(dialogData: DynamicDialogData | DynamicDialogDataWithWizard, options: DynamicDialogOptions): DynamicDialogService<T> {
		const dialogRef = this.dialog.open<DynamicDialogModalComponent, DynamicDialogData | DynamicDialogDataWithWizard>(DynamicDialogModalComponent, {
			...this.fetchOptions(options),
			data: dialogData,
		});
		dialogRef.afterClosed();
		return new DynamicDialogService(dialogRef);
	}

	private fetchOptions({
		panelClass,
		disableClose,
		autoFocus,
	}: DynamicDialogOptions): Pick<MatDialogConfig<DynamicDialogData>, 'panelClass' | 'disableClose' | 'autoFocus'> {
		return {
			panelClass,
			disableClose,
			autoFocus,
		};
	}
}
