import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NextStep } from '../animations';
import { CreateFormService } from '../create-hallpass-forms/create-form.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ScreenService } from '../services/screen.service';
import { DeviceDetection } from '../device-detection.helper';

export enum KEY_CODE {
	RIGHT_ARROW = 39,
	LEFT_ARROW = 37,
}

// Instead of having this.formService.getFrameMotionDirection() in multiple places all across the code,
// [attr.data-motion-translate]="(frameMotion$ | async).direction" should be included inside the app-pager
// component and target the relevant ng-content children using @ContentChildren directive
// https://angular.io/api/core/ContentChildren

@Component({
	selector: 'app-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss'],
	animations: [NextStep],
})
export class PagerComponent implements OnInit, AfterViewInit {
	@ViewChild('pageContent') pageContent: ElementRef;
	@ViewChild('left') left: ElementRef;

	@Input() page = 1;
	@Input() pages = 2;

	@Input() arrowPosition = '0';
	@Input() isStaff: boolean;

	@Input() pagerClass = '';
	@Input() fontColor = '#7083a0';
	@Input() activeDotColor = 'rgba(112, 131, 160, 1)';
	@Input() inactiveDotColor = 'rgba(226, 230, 236, 1)';
	@Input() leftArrowSrc = 'icon-gray500-left.svg';
	@Input() rightArrowSrc = 'icon-gray500-right.svg';

	hideRightButton = new BehaviorSubject(false);
	hideLeftButton = new BehaviorSubject(true);
	frameMotion$: BehaviorSubject<any>;
	$pages: number[] = [];
	config: SwiperConfigInterface;

	swiperInitialized: boolean;

	@HostListener('window:keyup', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		if (event.keyCode === KEY_CODE.LEFT_ARROW && this.hideLeftButton.value) {
			this.leftPaginator();
		} else if (event.keyCode === KEY_CODE.RIGHT_ARROW && this.hideRightButton.value) {
			this.RightPaginator();
		}
	}

	constructor(private formService: CreateFormService, private cdr: ChangeDetectorRef, public screenService: ScreenService) {}

	get isMobile() {
		return DeviceDetection.isMobile();
	}

	// get $pages() {
	// 	console.log('*** Test Message ***');
	// 	return Array(this.pages)
	// 		.fill(1)
	// 		.map((x, i) => i + 1);
	// }

	ngOnInit() {
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		if (this.pages) {
			this.$pages = Array(this.pages)
				.fill(1)
				.map((x, i) => i + 1);
		}
		if (this.page === 1 && this.pages === 1) {
			this.hideLeftButton.next(false);
		}
		if (this.page === 1 && this.pages === 2) {
			this.hideLeftButton.next(false);
			this.hideRightButton.next(true);
		}
	}

	ngAfterViewInit(): void {
		this.config = {
			direction: 'horizontal',
			slidesPerView: 'auto',
		};
		this.cdr.detectChanges();
	}

	leftPaginator() {
		this.formService.setFrameMotionDirection('back');

		this.hideRightButton.next(true);
		if (this.page === 2) {
			this.hideLeftButton.next(false);
		}
		if (this.page >= 1) {
			this.page -= 1;
		}
	}

	RightPaginator() {
		this.formService.setFrameMotionDirection('forward');

		this.hideLeftButton.next(true);
		if ((this.page === 1 && this.pages === 2) || (this.page === 2 && this.pages === 3) || (this.page === 3 && this.pages === 4)) {
			this.hideRightButton.next(false);
		}
		this.page += 1;
	}

	DotPagination(dot) {
		if (dot > this.page) {
			this.formService.setFrameMotionDirection('forward');
		} else {
			this.formService.setFrameMotionDirection('back');
		}

		setTimeout(() => {
			this.page = dot;
			if (dot === 1) {
				this.hideLeftButton.next(false);
				this.hideRightButton.next(true);
			}
			if (dot > 1 && dot < this.$pages.length + 1) {
				this.hideLeftButton.next(true);
				this.hideRightButton.next(true);
			}
			if (dot === this.$pages.length) {
				this.hideLeftButton.next(true);
				this.hideRightButton.next(false);
			}
		}, 100);
	}

	next() {
		this.page += 1;
	}

	onSlideChange(event) {
		this.page = event + 1;
	}

	swiperInit($event: any) {
		this.swiperInitialized = true;
	}
}
