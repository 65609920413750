<div
	class="wrapper"
	[style.background-color]="backgroundColor"
	[style.width]="width"
	[style.height]="height"
	[@pressState]="buttonState"
	appCrossPointerEventTarget
	(pointerDownEvent)="buttonDown = true"
	(pointerCancelEvent)="buttonDown = false"
	(pointerClickEvent)="buttonDown = false; hovered = false; onClick($event)"
	[style.opacity]="hovered && !buttonDown && !disabled ? 0.85 : buttonDown && !disabled ? 0.7 : disabled ? 0.5 : 1"
	(mouseenter)="onHover(true)"
	(mouseleave)="onHover(false)"
	[style.box-shadow]="shadow">
	<ng-content></ng-content>
</div>
