import { spPostBlob } from './smartpass-http';

export async function exportAttendeeByFlex(schoolId: number, flexPeriodId: number, date: Date, attending = true) {
	let route = 'v2/school_activities/attendee/export';
	if (!attending) {
		route += '/absentees';
	}
	return spPostBlob(
		route,
		{
			flex_period_id: flexPeriodId,
			date: date.toISOString(),
		},
		{
			'X-School-Id': String(schoolId),
		}
	);
}
