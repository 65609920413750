import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DropdownOptions, DropdownSelectionComponent } from 'app/core/components/dropdown-selection/dropdown-selection.component';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'sp-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T> implements OnInit {
	@ViewChild('selectWrapper') selectWrapper!: ElementRef<HTMLDivElement>;

	// @Input() group!: FormGroup;
	// @Input() control = '';
	@Input() label = '';
	@Input() hideLabel = false;
	@Input() placeholder = '';
	@Input() description = '';
	@Input() options: DropdownOptions<T>[] = [];
	@Input() selectedOption: DropdownOptions<T> | null = null;
	@Input() comparisonFunc = (selected: T | undefined, checkValue: T): boolean => selected === checkValue;
	@Output() onSelect = new EventEmitter<DropdownOptions<T>>();

	optionsVisible = false;

	constructor(private dialog: MatDialog) {}

	ngOnInit(): void {
		// no need to implement
	}

	displaySelectOptions(): void {
		if (this.optionsVisible) {
			return;
		}
		this.optionsVisible = true;
		const position = this.selectWrapper.nativeElement.getBoundingClientRect();
		const dialogRef = this.dialog.open(DropdownSelectionComponent, {
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
			panelClass: ['overlay-dialog', 'show-overlay'],
			closeOnNavigation: true,
			width: '484px',
			data: {
				options: this.options,
				currentlySelected: this.selectedOption,
				comparison: this.comparisonFunc,
			},
			position: {
				top: `${position.bottom + 10}px`,
				left: `${position.left}px`,
			},
		});
		dialogRef
			.afterClosed()
			.pipe(filter(Boolean))
			.subscribe({
				next: (option: any) => {
					this.selectedOption = option;
					this.onSelect.emit(option as DropdownOptions<T>);
					this.optionsVisible = false;
				},
			});
		dialogRef.backdropClick().subscribe(() => {
			this.optionsVisible = false;
		});
	}
}
