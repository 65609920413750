<div class="base-timer" [style.width.px]="diameter" [style.height.px]="diameter">
	<div *ngIf="topText" class="top-pill-container">
		<div class="top-pill">
			<span class="top-pill__text">{{ topText }}</span>
		</div>
	</div>
	<ng-container *ngIf="!wideCircleStroke; else wideCircleTemplate">
		<svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
			<g class="base-timer__circle">
				<circle class="base-timer__path-elapsed" [style.stroke]="ringColor" cx="50" cy="50" r="45"></circle>
				<path
					*ngIf="strokeDashNum > 0"
					id="base-timer-path-remaining"
					[attr.stroke-dasharray]="strokeDashNum + ' ' + strokeDashDenom"
					class="base-timer__path-remaining"
					d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
					[style.color]="elapsedRingColor"></path>
			</g>
		</svg>
	</ng-container>
	<div [style.width.px]="diameter" [style.height.px]="diameter" class="centered-content">
		<ng-content> </ng-content>
	</div>
</div>

<ng-template #wideCircleTemplate>
	<svg class="base-timer__svg" viewBox="0 0 116 116" xmlns="http://www.w3.org/2000/svg">
		<g class="base-timer__circle">
			<circle class="base-timer__path-elapsed" [style.stroke]="ringColor" cx="58" cy="58" r="50"></circle>
			<path
				*ngIf="strokeDashNum > 0"
				id="base-timer-path-remaining"
				[attr.stroke-dasharray]="strokeDashNum + ' ' + strokeDashDenom"
				class="base-timer__path-remaining"
				d="
				M 58, 58
				m -50, 0
				a 50,50 0 1,0 100,0
				a 50,50 0 1,0 -100,0
			"
				[style.color]="elapsedRingColor"></path>
		</g>
	</svg>
</ng-template>
