<div class="container">
	<div class="heading">
		{{ name }}
	</div>
	<div class="main">
		<app-gradient-button
			*ngIf="data.selectedReports"
			[size]="'small'"
			[minHeight]="'40px'"
			[width]="'120px'"
			[fontSize]="'14px'"
			[rightIcon]="'./assets/Download (White).svg'"
			(buttonClick)="downloadXlsxFile(); dialogRef.close()">
			<!--<div class="link">-->
			<span>CSV</span>
			<!--<img [src]="'./assets/Download (White).svg' | resolveAsset" alt="External Link (White).png">-->
			<!--</div>-->
		</app-gradient-button>
		<app-gradient-button
			[size]="'small'"
			[documentType]="'pdf'"
			[buttonLink]="pdflink"
			[minHeight]="'40px'"
			[width]="'120px'"
			[fontSize]="'14px'"
			[download]="data.pdfName"
			(buttonClick)="dialogRef.close()">
		</app-gradient-button>
	</div>
</div>
