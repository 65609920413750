import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SchoolActivity, SchoolActivityInstance, SchoolActivityService } from '../services/school-activity.service';
import { FlexPeriod, FlexPeriodService } from '../flex-period.service';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { ToastService } from '../services/toast.service';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { ConfirmationDialogComponent, RecommendedDialogConfig } from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { FlexOptionSelect } from '../admin/flex-period/flex-period.component';

declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

export interface DeleteInstancePayload {
	event: MouseEvent;
	instance: SchoolActivityInstance;
}

@Component({
	selector: 'sp-teacher-activity',
	templateUrl: './teacher-activity.component.html',
	styleUrls: ['./teacher-activity.component.scss'],
})
export class TeacherActivityComponent implements OnInit {
	closeDialog: () => void;
	flexPeriod: FlexPeriod | undefined;
	teacherName = 'Instructor';
	selectedInstance: SchoolActivityInstance | undefined = undefined;
	showDeleteInstance = true;
	teacherIcon = '';
	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild('deleteActivityDialogBody') deleteActivityDialogBody: TemplateRef<HTMLElement> | undefined;
	@ViewChild('deleteInstanceDialogBody') deleteInstanceDialogBody: TemplateRef<HTMLElement> | undefined;

	constructor(
		private flexService: FlexPeriodService,
		private userService: UserService,
		private activityService: SchoolActivityService,
		private toastService: ToastService,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			activity: SchoolActivity;
			day?: Date;
		}
	) {}

	ngOnInit(): void {
		if (this.data.activity.state !== 'deleted' && this.data.activity.flex_period_id) {
			this.flexService
				.GetFlexPeriodById(this.data.activity.flex_period_id)
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: (period) => {
						this.flexPeriod = period[0];
					},
				});
		}

		combineLatest([this.userService.getUserById(this.data.activity.user_id), this.userService.isEnableProfilePictures$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([user, picsEnabled]) => {
				this.teacherName = user.display_name;
				if (picsEnabled && user.profile_picture) {
					this.teacherIcon = user.profile_picture;
				}
			});
	}

	updateSelectedInstance(instance: SchoolActivityInstance | undefined): void {
		if (!instance) {
			this.data.day = undefined;
		}
		this.selectedInstance = instance;
	}

	deleteActivity(id: number): void {
		this.activityService
			.DeleteActivity(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((wasDeleted) => {
				if (wasDeleted) {
					this.closeDialog();
					this.toastService.openToast({ title: `Activity removed`, type: 'success' });
					return;
				}
				this.toastService.openToast({ title: `Issue encountered while deleting activity, try again`, type: 'error' });
			});
	}

	deleteInstance(instance: SchoolActivityInstance): void {
		if (instance.id) {
			this.activityService.DeleteActivityInstance(instance.id).subscribe({
				next: () => {
					this.updateSelectedInstance(undefined);
					this.toastService.openToast({ title: `Instance removed`, type: 'success' });
				},
				error: () => this.toastService.openToast({ title: `Issue encountered while deleting instance, try again`, type: 'error' }),
			});
		} else if (this.data.activity.id) {
			this.activityService
				.CreateActivityInstance(new Date(instance.start_time), new Date(instance.end_time), this.data.activity.id, 'canceled')
				.subscribe({
					next: () => {
						this.updateSelectedInstance(undefined);
						this.toastService.openToast({ title: `Instance removed`, type: 'success' });
					},
					error: () => this.toastService.openToast({ title: `Issue encountered while deleting instance, try again`, type: 'error' }),
				});
		}
	}

	openDeleteActivity(event: MouseEvent, id: number) {
		const target = new ElementRef(event.currentTarget);
		const options = [{ display: 'Remove Activity', color: '#7083A0', action: FlexOptionSelect.DeleteActivity }];

		UNANIMATED_CONTAINER.next(true);
		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
			.subscribe((deleteAction) => {
				if (deleteAction === FlexOptionSelect.DeleteActivity) {
					this.dialog
						.open(ConfirmationDialogComponent, {
							...RecommendedDialogConfig,
							width: '450px',
							data: {
								headerText: 'Remove the activity?',
								body: this.deleteActivityDialogBody,
								buttons: {
									confirmText: 'Remove activity',
									denyText: 'Cancel',
								},
								templateData: {},
							},
						})
						.afterClosed()
						.subscribe((deleteConfirmed: boolean) => {
							if (deleteConfirmed) {
								this.deleteActivity(id);
							}
						});
				}
			});
	}

	openDeleteInstance(data: DeleteInstancePayload): void {
		const target = new ElementRef(data.event.currentTarget);
		const options = [{ display: 'Delete Session', color: '#7083A0', action: FlexOptionSelect.DeleteInstance }];

		UNANIMATED_CONTAINER.next(true);
		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
			.subscribe((action) => {
				if (action === FlexOptionSelect.DeleteInstance) {
					this.dialog
						.open(ConfirmationDialogComponent, {
							...RecommendedDialogConfig,
							width: '450px',
							data: {
								headerText: 'Remove the session?',
								body: this.deleteInstanceDialogBody,
								buttons: {
									confirmText: 'Remove session',
									denyText: 'Cancel',
								},
								templateData: {},
							},
						})
						.afterClosed()
						.subscribe((deleteConfirmed: boolean) => {
							if (deleteConfirmed) {
								this.deleteInstance(data.instance);
							}
						});
				}
			});
	}

	updateShowDeleteInstance(show: boolean): void {
		this.showDeleteInstance = show;
	}

	openIntercom() {
		window.Intercom('show');
	}
}
