<div class="wrapper">
	<div class="label" [style.color]="invalid ? '#E32C66' : selectedRoles ? '#1F195E' : '#7f879d'">Role</div>
	<div class="content">
		<div class="active-roles" *ngIf="selectedRoles.length">
			<div
				class="role"
				[style.background-color]="cardsBackground"
				*ngFor="let role of selectedRoles"
				#r
				(mouseenter)="r.hover = true"
				(mouseleave)="r.hover = false">
				<img width="12" [src]="role.icon | resolveAsset" alt="ii" />
				<span class="title">{{ role.role }}</span>

				<div *ngIf="r.hover && selectedRoles.length > 1" class="delete" appCrossPointerEventTarget (pointerClickEvent)="deleteRole(role)">
					<img [src]="'./assets/Cancel (Navy).svg'" width="16" alt="Check.svg" />
				</div>
			</div>
		</div>
		<!--    <div class="role" *ngIf="isAccessAdd" (click)="">-->
		<!--      <img width="12" [src]="'./assets/Plus (Blue-Gray).svg' | resolveAsset" alt="Plus">-->
		<!--      <div class="title">{{ selectedRoles.length ? 'Add' : 'Choose' }}</div>-->
		<!--    </div>-->
		<app-gradient-button
			*ngIf="isAccessAdd"
			[style.margin]="'10px 10px 0 0'"
			[size]="'editable'"
			[minWidth]="'100px'"
			[minHeight]="'31px'"
			[textColor]="'#7F879D'"
			[gradient]="'#F4F4F4, #F4F4F4'"
			[buttonDownColor]="'#F4F4F4'"
			[leftIcon]="'./assets/Plus (Blue-Gray).svg'"
			[leftImageWidth]="'16px'"
			[leftImageHeight]="'16px'"
			[withShadow]="false"
			[fontSize]="'14px'"
			(buttonClick)="openAddRole($event)"
			>{{ selectedRoles.length ? 'Add' : 'Choose' }}</app-gradient-button
		>
	</div>
</div>
