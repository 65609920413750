import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '../models/Location';
import { DialogPosition, MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { School } from '../models/School';
import { DarkThemeSwitch } from '../dark-theme-switch';
import { User } from '../models/User';
import { DeviceDetection } from '../device-detection.helper';
import { cloneDeep } from 'lodash';
import { COUNTRY_CODES } from '../services/localizejs.service';
import { Term } from 'app/models/Schedule';
import { DatePipe } from '@angular/common';
declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

export type Option = {
	display: string;
	value: string;
};

@Component({
	selector: 'app-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	providers: [DatePipe],
})
export class DropdownComponent implements OnInit {
	options: HTMLElement;
	@Output() selectedLocationEmit: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('optionsWrapper') set content(content: ElementRef<HTMLElement>) {
		if (content) {
			this.options = content.nativeElement;
			this.options.scrollTop = this.scrollPosition;
		}
	}

	@ViewChildren('schoolList') schoolList: QueryList<School>;
	@ViewChildren('langList') langList: QueryList<string>;
	@ViewChildren('_option') locationsList: QueryList<Location>;

	@Input() user: User;
	@Input() heading = '';
	@Input() locations: Location[];
	@Input() selectedLocation: Location;
	@Input() schools: School[];
	@Input() langs: string[];
	@Input() selectedLang: string;
	@Input() isDisabledLang: boolean;
	@Input() betaLanguage = '';
	@Input() countryCodes = COUNTRY_CODES;
	@Input() currentInitialObject;
	@Input() selectedSchool: School;
	@Input() teachers: User[];
	@Input() selectedTeacher: User;
	@Input() _matDialogRef: MatDialogRef<DropdownComponent>;
	@Input() triggerElementRef: HTMLElement;
	@Input() scrollPosition: number;
	@Input() findElement: ElementRef;
	@Input() sortData: any[];
	@Input() plFrequencies: Option[];
	@Input() currentTerm: Term;
	@Input() upcomingTerm: Term;
	@Input() termsSetup: boolean;
	@Input() termsOverlap: boolean;
	@Input() selectedFrequency = '';
	@Input() selectedSort = '';
	@Input() optionsMaxHeight = '282px';
	@Input() optionsWidth: string;
	@Input() mainHeader: string;
	@Input() isSearchField: boolean;
	@Input() isHiddenSearchField: boolean;
	@Input() optionPadding: string;
	@Input() optionMargin: string;
	@Input() positionConfig: string;
	@Input() private adjustForScroll = false;
	@Input() positionY: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any[],
		_matDialogRef: MatDialogRef<DropdownComponent>,
		public darkTheme: DarkThemeSwitch,
		private renderer: Renderer2
	) {
		if (!data['isTooltip']) {
			this._matDialogRef = _matDialogRef;
			this.triggerElementRef = data['trigger'];
			this.heading = data['heading'];
			this.locations = data['locations'];
			this.schools = data['schools'];
			this.langs = data['langs'];
			this.teachers = data['teachers'];
			this.selectedLocation = data['selectedLocation'];
			this.selectedSchool = data['selectedSchool'];
			this.selectedLang = data['selectedLang'];
			this.isDisabledLang = data['isDisabledLang'];
			this.betaLanguage = data['betaLanguage'];
			this.selectedTeacher = data['selectedTeacher'];
			this.user = data['user'];
			this.scrollPosition = data['scrollPosition'];
			this.sortData = data['sortData'];
			this.plFrequencies = data['plFrequencies'];
			this.currentTerm = data['currentTerm'];
			this.upcomingTerm = data['upcomingTerm'];
			this.termsSetup = data['termsSetup'];
			this.termsOverlap = data['termsOverlap'];
			this.selectedFrequency = data['selectedFrequency'] || '';
			this.selectedSort = data['selectedSort'] || '';
			this.optionsMaxHeight = data['maxHeight'] || '282px';
			this.optionsWidth = data['width'];
			this.mainHeader = this.data['mainHeader'];
			this.isSearchField = this.data['isSearchField'];
			this.isHiddenSearchField = this.data['isHiddenSearchField'];
			this.optionPadding = this.data['optionPadding'];
			this.optionMargin = this.data['optionMargin'];
			this.positionConfig = this.data['positionConfig'];
			this.positionY = this.data['positionY'] || 15;

			this.currentInitialObject = cloneDeep(this.schools || this.locations || this.teachers);
			this.adjustForScroll = data['adjustForScroll'];
		}
	}

	get isMobile() {
		return DeviceDetection.isMobile();
	}

	get searchPlaceholder(): string {
		if (this.schools) {
			return 'school';
		} else if (this.locations) {
			return 'location';
		} else if (this.teachers) {
			return 'teacher';
		}
		return '';
	}

	ngOnInit() {
		if (this.triggerElementRef) {
			const matDialogConfig: MatDialogConfig = new MatDialogConfig();
			const rect = this.triggerElementRef.getBoundingClientRect();
			matDialogConfig.width = this.sortData ? '250px' : '300px';
			if (this.optionsWidth) {
				matDialogConfig.width = this.optionsWidth;
			}
			// matDialogConfig.height = this.teachers ? '180px' : '215px';
			let scrollAdjustment = 0;
			if (this.adjustForScroll) {
				scrollAdjustment = Math.abs(document.scrollingElement.getClientRects()[0].top);
			}

			matDialogConfig.position = this.getDropDownPosition(rect, scrollAdjustment, matDialogConfig);

			this._matDialogRef.updateSize(matDialogConfig.width, matDialogConfig.height);
			this._matDialogRef.updatePosition(matDialogConfig.position);
			this._matDialogRef.backdropClick().subscribe(() => {
				this._matDialogRef.close(this.selectedTeacher);
			});
		}
	}

	getDropDownPosition(rect: DOMRect, scrollAdjustment: number, matDialogConfig: MatDialogConfig): DialogPosition {
		switch (this.positionConfig) {
			case 'left':
				return {
					left: `${rect.left - 315}px`,
					top: `${rect.top + scrollAdjustment}px`,
				};
			default:
				return {
					left: `${rect.left + (rect.width / 2 - parseInt(matDialogConfig.width, 10) / 2) - (this.isMobile && this.sortData ? 100 : 0)}px`,
					top: `${rect.bottom + scrollAdjustment + this.positionY}px`,
				};
		}
	}

	changeColor(hovered, elem, pressed?: boolean) {
		if (hovered) {
			if (pressed) {
				this.renderer.setStyle(elem, 'background-color', this.darkTheme.isEnabled$.value ? 'rgba(226, 231, 244, .2)' : '#E2E7F4');
			} else {
				this.renderer.setStyle(elem, 'background-color', this.darkTheme.isEnabled$.value ? 'rgba(226, 231, 244, .2)' : '#ECF1FF');
			}
		} else {
			this.renderer.setStyle(elem, 'background-color', this.darkTheme.isEnabled$.value ? '#0F171E' : 'white');
		}
	}

	get list() {
		if ((this.locationsList as any)._results.length) {
			return this.locationsList;
		} else if ((this.schoolList as any)._results.length) {
			return this.schoolList;
		} else if ((this.langList as any)._results.length) {
			return this.langList;
		}
	}

	search(value) {
		if (this.findElement) {
			this.renderer.setStyle(this.findElement.nativeElement, 'background-color', 'transparent');
		}
		if (value) {
			this.findElement = (this.list as any)._results.find((elem) => {
				return elem.nativeElement.innerText.toLowerCase().includes(value);
			});
			if (this.findElement) {
				this.findElement.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
				this.renderer.setStyle(this.findElement.nativeElement, 'background-color', '#ECF1FF');
			}
		}
	}

	searchObject(value) {
		if (this.schools && School.fromJSON(this.currentInitialObject[0]) instanceof School) {
			if (value) {
				this.schools = this.currentInitialObject.filter((school) => +school.id === +value || school.name.toLowerCase().includes(value.toLowerCase()));
			} else {
				this.schools = this.currentInitialObject;
			}
		} else if (this.teachers && User.fromJSON(this.currentInitialObject[0]) instanceof User) {
			if (value) {
				this.teachers = this.currentInitialObject.filter(
					(teacher) => +teacher.id === +value || teacher.display_name.toLowerCase().includes(value.toLowerCase())
				);
			} else {
				this.teachers = this.currentInitialObject;
			}
		}
	}

	closeDropdown(location) {
		this.scrollPosition = this.options.scrollTop;

		const dataAfterClosing = {
			selectedRoom: location,
			scrollPosition: this.scrollPosition,
		};
		if (this._matDialogRef) {
			this._matDialogRef.close(dataAfterClosing);
		} else {
			this.selectedLocationEmit.emit(location);
		}
	}

	openIntercom() {
		window.Intercom('showNewMessage');
	}
}
