<div class="top" [ngStyle]="{ background: backgroundColor }" *ngIf="isIdCardLoaded">
	<div class="details-wrapper">
		<div class="school-name-wrapper">
			<p class="school-name" ignore>{{ schoolName }}</p>
			<p class="year">{{ schoolYear }}</p>
		</div>
		<div>
			<div class="avatar-info">
				<div class="avatar" #avatarContainer>
					<div
						*ngIf="profile_picture; else d"
						[ngStyle]="{ background: 'url(' + profile_picture + ') no-repeat left center/cover', width: '88px', height: '88px' }"></div>
					<ng-template #d>
						<img width="88" height="88" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="Avatar" />
					</ng-template>
				</div>
				<div class="logo">
					<img [src]="logoURL" alt="" />
				</div>
			</div>
			<!-- <p class="card-holder-name">{{userDetails.display_name}}</p> -->
			<p class="card-holder-name" notranslate>{{ userName }}</p>
			<div class="ds-flex-center-start role-grade">
				<p>
					{{ userRole }}
					<!-- <span *ngIf="userDetails.isStudent()">Student</span>
                    <span *ngIf="userDetails.isAdmin() || userDetails.isAssistant()">Staff</span> -->
				</p>
				<ul *ngIf="(isDummyCard && greadLevel) || (greadLevel && userRole == 'Student')">
					<li>Grade {{ greadLevel }}</li>
				</ul>
			</div>
		</div>
	</div>
	<div *ngIf="(this.IDNumberData?.barcodeURL && this.IDNumberData?.idNumber && this.showCustomID) || stickers?.length > 0" class="id-detail-wrapper">
		<div
			[ngClass]="
				this.IDNumberData?.barcodeURL && this.IDNumberData?.idNumber && this.showCustomID ? 'stickers-wrapper-w-barcode' : 'stickers-wrapper'
			"
			*ngIf="stickers?.length > 0">
			<div class="sticker-icon" *ngFor="let sticker of stickers" [ngStyle]="{ background: sticker.color }">
				<img class="sticker-img" [src]="sticker.icon | resolveAsset" [customToolTip]="sticker.name" alt="" />
			</div>
		</div>
		<ng-container *ngIf="this.IDNumberData?.barcodeURL && this.IDNumberData?.idNumber && this.showCustomID">
			<img class="barcode-img" [src]="IDNumberData.barcodeURL" alt="" />
			<p class="id-number">{{ IDNumberData.idNumber }}</p>
		</ng-container>
	</div>
</div>
