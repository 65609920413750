import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

export interface ConfirmationTemplates {
	headerText: string;
	headerFontSize?: string;
	body?: TemplateRef<HTMLElement>;
	buttons?: {
		confirmText: string;
		confirmColor?: string;
		denyText: string;
	};
	templateData: Record<string, any>;
	icon?: {
		name: string;
		background: string;
		spacing: string;
	};
	padding?: string;
}

export const RecommendedDialogConfig: MatDialogConfig = {
	panelClass: 'overlay-dialog',
	backdropClass: 'custom-backdrop',
	closeOnNavigation: true,
};

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
	@ViewChild('defaultTemplate') defaultTemplate: TemplateRef<HTMLElement>;
	templateData: Record<string, any>;
	buttons: {
		confirmText: string;
		confirmColor?: string;
		denyText: string;
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationTemplates) {
		this.templateData = this.data?.templateData;
		this.buttons = this.data?.buttons || {
			confirmText: 'Ok',
			confirmColor: '#E32C66',
			denyText: 'Cancel',
		};
	}
}
