import { Component, Input, Output, EventEmitter, TemplateRef, OnInit } from '@angular/core';
import { StudentList } from '../../../../models/StudentList';
import { User } from '../../../../models/User';
import { FeatureFlagService, FLAGS } from '../../../../services/feature-flag.service';
import { UserService } from '../../../../services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CreateFormService } from '../../../create-form.service';
import { ScreenService } from '../../../../services/screen.service';

@Component({
	selector: 'app-selection',
	templateUrl: './selection.component.html',
	styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit {
	@Input() selection: StudentList | User;
	@Input() locked = false;
	@Input() showIcon = true;
	@Input() isUser: boolean;
	@Input() tooltipContent: TemplateRef<any>;

	@Output() removeSelectionEvent: EventEmitter<StudentList | User> = new EventEmitter<StudentList | User>();
	constructor(private featureFlags: FeatureFlagService) {}

	hovered = false;
	iconHovered = false;
	hasSchedulesFF = false;

	ngOnInit(): void {
		this.hasSchedulesFF = this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules);
	}

	removeSelection(selection: StudentList | User): void {
		if (!this.locked) {
			this.removeSelectionEvent.emit(selection);
		}
	}
}
