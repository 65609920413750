<div class="rest-tar-wrapper">
	<div [ngClass]="headerTransition">
		<div class="header-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction" [style.background-image]="headerGradient"></div>
		<div class="back-button-wrapper" (click)="back()">
			<img
				[ngClass]="isIOSTablet ? 'ios-back-btn-top' : 'back-btn-top'"
				class="back-button back-button-white"
				[src]="'./assets/Back Button (Light-Gray).svg' | resolveAsset" />
		</div>
		<div class="rest-tar-header-text" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<img class="search-icon" [src]="'./assets/Lock (White).svg' | resolveAsset" />
			<div class="text ds-dialog-title-white">Send Pass Request To?</div>
		</div>
	</div>
	<div class="content-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction"></div>
	<div class="rest-tar-content" (scroll)="tableScroll($event)" #rc>
		<div
			[attr.data-motion-translate]="(frameMotion$ | async).direction"
			style="width: 100%; height: 100%; display: flex; justify-content: center; flex: 1 1 auto">
			<div class="search-wrapper">
				<app-sp-search
					[hideStatuses]="['suspended', 'disabled']"
					[focused]="false"
					[width]="'100%'"
					[role]="'_profile_teacher'"
					[searchTarget]="localSearch ? 'local' : 'users'"
					[placeholder]="'Search teachers'"
					[list]="false"
					[searchingTeachers]="filteredTeachers"
					[emitSingleProfile]="true"
					(onUpdate)="updateTarget($event)"></app-sp-search>
			</div>
			<div class="quick-select" [style.margin-top]="'70px'" *ngIf="filteredTeachers.length">
				<div class="quick-select_header">
					<span>Quick Select</span>
				</div>
				<div class="quick-select_scrollable" notranslate>
					<div
						*ngFor="let teacher of filteredTeachers; let i = index"
						#cell
						tabindex="0"
						class="quick-select_item"
						(mouseenter)="cell.hovered = true"
						(mouseleave)="cell.hovered = false"
						(mousedown)="cell.pressed = true"
						(mouseup)="cell.pressed = false"
						(click)="updateTarget(teacher)"
						[style.background-color]="getBackground(cell)"
						[style.color]="textColor(cell)">
						<span>
							{{ teacher.display_name }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-student-footer [formState]="formState" [date]="date" (changeAnimationDirectionEvent)="onChangeAnimationDirection($event)"></app-student-footer>
</div>
