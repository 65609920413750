import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { DialogFactoryService } from '../../../dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from '../../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../../../dynamic-dialog.service';
import { BellSchedule, ScheduleGroupList } from '../../../models/Schedule';
import { BehaviorSubject, Observable } from 'rxjs';
import { MilitaryTimeStringPipe } from '../../../pipes/military-time-string.pipe';
import { cloneDeep } from 'lodash';
import { ToastService } from '../../../services/toast.service';

type Columns = {
	label: string;
	bubbles: Bubble[];
};

type Bubble = {
	label: string;
	period_groupings: FormattedPeriodGrouping[];
};

type FormattedPeriodGrouping = {
	id: number;
	label: string;
	time_string: string;
};

@Component({
	selector: 'sp-class-period-picker',
	templateUrl: './class-period-picker.component.html',
	styleUrls: ['./class-period-picker.component.scss'],
	providers: [MilitaryTimeStringPipe],
})
export class ClassPeriodPickerComponent implements OnInit {
	@ViewChild('pickSpecialTime', { read: TemplateRef, static: false }) pickSpecialTime: TemplateRef<any>;
	@ViewChild('allPeriodsSelectedToast', { read: TemplateRef, static: false }) allPeriodsSelectedToast: TemplateRef<any>;

	@Input() periodGroupingIds: number[] = [];
	@Input() listGroups: ScheduleGroupList[];

	@Output() selectedPeriodGroupingIds = new EventEmitter<number[]>();

	selectedScheduleGroup: ScheduleGroupList;
	bellSchedules: BellSchedule[];
	columns: Columns[];
	selectedPeriodGroupingIds$: BehaviorSubject<number[]>;
	bubbleSelectedStatuses$: Observable<Map<Bubble, boolean>>;

	periodPickerColumnsClass: string;
	private lunchDialogService: DynamicDialogService;

	openBubble: Bubble;
	openColName: string;

	selectedIndex = 0;

	private initialPeriodGroupingIds: number[] = [];
	hasLunchWaves = false;
	mostRecentlySelectedBubble: Bubble | null;

	constructor(
		private fb: FormBuilder,
		private dialogFactoryService: DialogFactoryService,
		private militaryTimeStringPipe: MilitaryTimeStringPipe,
		public toastService: ToastService
	) {}

	ngOnInit(): void {
		this.initialPeriodGroupingIds = cloneDeep(this.periodGroupingIds);
		this.periodGroupingIds = cloneDeep(this.periodGroupingIds);
		this.selectedScheduleGroup = this.listGroups[0];
		this.bellSchedules = this.selectedScheduleGroup.bell_schedule_groups[0].bell_schedules;
		this.selectedPeriodGroupingIds$ = new BehaviorSubject<number[]>(this.periodGroupingIds);
		for (const bs of this.bellSchedules) {
			for (const pb of bs.period_bubbles) {
				if (pb.period_groupings.length > 1) {
					this.hasLunchWaves = true;
					break;
				}
			}
		}
		this.setColumns();

		this.bubbleSelectedStatuses$ = this.selectedPeriodGroupingIds$.pipe(
			filter((ids) => !!ids),
			map((selectedIds) => {
				const m = new Map<Bubble, boolean>();
				this.columns.forEach((bs) => {
					bs.bubbles.forEach((b) => {
						const selected = b.period_groupings.some((pg) => selectedIds.includes(pg.id));
						m.set(b, selected);
					});
				});
				return m;
			})
		);

		this.bubbleSelectedStatuses$.subscribe((m) => {
			if (!this.openBubble || !this.lunchDialogService?.hasOpenDialog()) {
				return;
			}

			const selected = m.get(this.openBubble);
			this.lunchDialogService.setDialogConfig({ disablePrimaryButton: !selected });
		});
		this.selectedPeriodGroupingIds$.subscribe((ids) => {
			this.selectedPeriodGroupingIds.emit(ids);
		});
	}

	private setColumns(): void {
		this.columns = this.bellSchedules.map((bs) => ({
			label: bs.short_name,
			bubbles: bs.period_bubbles.map((pb) => ({
				label: pb.short_name,
				period_groupings: pb.period_groupings.map((pg) => ({
					id: pg.id,
					label: pg.name,
					time_string: pg.periods
						.filter((p) => p.is_assignable)
						.map((p) => this.militaryTimeStringPipe.transform(p.start_time) + ' - ' + this.militaryTimeStringPipe.transform(p.end_time))
						.join(', '),
				})),
			})),
		}));
		this.periodPickerColumnsClass = `tw-grid-cols-${this.columns.length}`;
	}
	toggleBubble(colName: string, bubble: Bubble): void {
		// select all bubbles of the same period if no bubbles were initially selected and school doesn't have lunch waves
		if (this.periodGroupingIds.length === 0 && !this.hasLunchWaves) {
			this.handleSelectingMultipleBubbles(bubble);
			return;
		}
		// determine if this bubble is selected
		const currSelected = bubble.period_groupings.some((pg) => this.periodGroupingIds.includes(pg.id));

		if (currSelected) {
			this.unselectBubble(bubble);
			return;
		}

		if (bubble.period_groupings.length === 1) {
			// Add this period bubble's period_groupings[0].id to selected
			if (!this.periodGroupingIds.includes(bubble.period_groupings[0].id)) {
				this.periodGroupingIds.push(bubble.period_groupings[0].id);
			}
			this.selectedPeriodGroupingIds$.next(cloneDeep(this.periodGroupingIds));
			return;
		}

		this.openLunchModal(colName, bubble);
	}

	unselectBubble(bubble: Bubble): void {
		this.periodGroupingIds = this.periodGroupingIds.filter((id) => {
			return !bubble.period_groupings.some((pg) => pg.id === id);
		});
		this.selectedPeriodGroupingIds$.next(cloneDeep(this.periodGroupingIds));
	}

	selectPgOfBubble(periodGrouping: FormattedPeriodGrouping, bubble: Bubble): void {
		bubble.period_groupings.forEach((pg) => {
			let curr = this.selectedPeriodGroupingIds$.value;

			if (periodGrouping.id === pg.id) {
				// Select the period grouping that was selected
				curr = curr.filter((i) => i != pg.id).concat(pg.id);
				this.periodGroupingIds = curr;
			} else {
				// Unselect the others
				curr = curr.filter((i) => i != pg.id);
				this.periodGroupingIds = this.periodGroupingIds.filter((i) => i != pg.id);
			}
			this.selectedPeriodGroupingIds$.next(cloneDeep(curr));
		});
	}

	openLunchModal(colName: string, bubble: Bubble): void {
		this.openBubble = bubble;
		this.openBubble.period_groupings = this.openBubble.period_groupings.sort((a, b) => {
			return a.label.localeCompare(b.label);
		});
		this.openColName = colName;
		const data: DynamicDialogData = {
			headerText: `When Does Period ${bubble.label} Class Occur on ${colName} Days?`,
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.pickSpecialTime,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			disablePrimaryButton: true,
		};
		this.lunchDialogService = this.dialogFactoryService.open(data, { panelClass: ['dynamic-dialog-modal', 'wider'], disableClose: false });
		this.lunchDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				this.openBubble = null;
				this.openColName = null;

				if (selectedOption !== 'primary') {
					this.unselectBubble(bubble);
				}
			},
		});
	}

	private handleSelectingMultipleBubbles(bubble: Bubble): void {
		this.mostRecentlySelectedBubble = bubble;
		// Add this period bubble's period_groupings[0].id to selected
		this.bellSchedules.map((bs) => {
			bs.period_bubbles.map((pb) => {
				if (pb.short_name === bubble.label) {
					this.periodGroupingIds.push(pb.period_groupings[0].id);
				}
			});
		});
		this.selectedPeriodGroupingIds$.next(cloneDeep(this.periodGroupingIds));
		this.toastService.openToast({
			title: '',
			type: 'success',
			templateRef: this.allPeriodsSelectedToast,
		});
	}

	onSelectTab(selectedIndex: number): void {
		this.selectedIndex = selectedIndex;
		// The listGroups ID of the currently selected tab is set
		const selectedGroupId = this.listGroups[selectedIndex].id;
		const foundScheduleGroup = this.listGroups.find((group) => group.id === selectedGroupId);
		// If found, it then updates the bell schedule via selectedScheduleGroup to reflect the data in that group
		if (foundScheduleGroup) {
			this.selectedScheduleGroup = foundScheduleGroup;
			this.bellSchedules = this.selectedScheduleGroup.bell_schedule_groups[0].bell_schedules;
			// update period bubble columns
			this.setColumns();
		}
	}

	undoSelect(): void {
		const allPeriodsWithSameShortNameAsSelected = [];
		this.bellSchedules.forEach((bs) => {
			bs.period_bubbles.map((pb) => {
				if (
					pb.short_name === this.mostRecentlySelectedBubble.label &&
					this.mostRecentlySelectedBubble.period_groupings[0].id !== pb.period_groupings[0].id
				) {
					allPeriodsWithSameShortNameAsSelected.push(pb.period_groupings[0].id);
				}
			});
		});
		this.periodGroupingIds = this.periodGroupingIds.filter((id) => !allPeriodsWithSameShortNameAsSelected.includes(id));
		this.selectedPeriodGroupingIds$.next(cloneDeep(this.periodGroupingIds));
		this.mostRecentlySelectedBubble = null;
	}
}
