import { Component, ElementRef, ViewChild } from '@angular/core';
import { ScreenService } from '../services/screen.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmergencyService } from '../services/emergency.service';
import { DatePipe } from '@angular/common';
import { SchedulePassActivityService } from '../services/schedule-pass-activity.service';

@Component({
	selector: 'top-bar-schedule-pass',
	templateUrl: './top-bar-schedule-pass.component.html',
	providers: [DatePipe],
})
export class TopBarSchedulePassComponent {
	@ViewChild('passButtonsContainer') passButtonsContainer: ElementRef<HTMLDivElement> | undefined;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private datePipe: DatePipe,
		public screenService: ScreenService,
		public emergencyService: EmergencyService,
		private schedulePassForm: SchedulePassActivityService
	) {}

	showScheduleForm(): void {
		// Get current route's query parameters
		const queryParams = {
			day_view_date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
			start_date: getFirstWeekdayOfWeekFormatted(new Date()),
		};

		this.router.navigate(['main', 'passes-calendar'], {
			queryParams: queryParams,
			queryParamsHandling: 'merge', // This ensures that existing query parameters are not overwritten
		});
		this.schedulePassForm.ShowForm();
	}
}

function getFirstWeekdayOfWeekFormatted(date: Date): string {
	const day = date.getDay(); // Get current day of the week (0-6, where Sunday is 0 and Monday is 1)
	const firstDay = new Date(date);

	// Calculate the difference to Monday
	// If it's Sunday (0), set to -6; otherwise, subtract the day number by 1
	const difference = day === 0 ? -6 : 1 - day;

	firstDay.setDate(date.getDate() + difference); // Adjust to Monday of that week

	// Format the date
	const dd = String(firstDay.getDate()).padStart(2, '0');
	const mm = String(firstDay.getMonth() + 1).padStart(2, '0'); //January is 0!
	const yyyy = firstDay.getFullYear();

	return `${yyyy}-${mm}-${dd}`;
}
