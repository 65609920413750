<div [ngClass]="{ 'schedules-ff': hasSchedulesFF, 'no-schedules-ff': !hasSchedulesFF }">
	<app-who-you-are *ngIf="currentState === 4" [formState]="FORM_STATE"> </app-who-you-are>

	<div *ngIf="currentState !== 4" [ngClass]="{ 'no-fly-time-style': FORM_STATE.noFlyTimeActive && !FORM_STATE.forLater }" class="groups-container">
		<h3 class="select-heading">{{ FORM_STATE.state === 4 ? 'Who are you?' : 'Select Students ' }}</h3>
		<div
			class="search-container"
			[ngClass]="{ active: isSearchFocused || handleSelection, 'has-selections': selectedGroupsAndStudents?.length }"
			(click)="setFocusOnSearch()">
			<div *ngIf="selectedGroupsAndStudents?.length" class="selections-container">
				<div *ngFor="let selection of selectedGroupsAndStudents" class="selection">
					<app-selection [selection]="selection" [isUser]="selection.display_name" (removeSelectionEvent)="removeSelection($event)"> </app-selection>
				</div>
				<input
					#searchInput
					class="dynamic-search-box"
					[(ngModel)]="currentSearch"
					(input)="searchInputChanges$.next($event.target.value)"
					(focus)="onSearchFocus(true)" />
			</div>

			<div [style.width]="'100%'" *ngIf="!selectedGroupsAndStudents?.length">
				<input
					#searchInput
					class="dynamic-search-box"
					[placeholder]="'Search (ex. Sam Y, ggarland@demontehs.org)'"
					[(ngModel)]="currentSearch"
					(input)="searchInputChanges$.next($event.target.value)"
					(focus)="onSearchFocus(true)" />
			</div>
		</div>
		<app-groups-and-student-select
			*ngIf="(filteredGroups?.length !== 0 || filteredStudents !== undefined) && (isSearchFocused || handleSelection)"
			[selectedNumber]="selectedGroupsAndStudents?.length"
			[formState]="FORM_STATE"
			[groups]="filteredGroups"
			[students]="filteredStudents"
			(handleSelect)="handleSelect($event)"
			(mouseDown)="clickedInside($event)">
		</app-groups-and-student-select>
		<div *ngIf="selectedGroupsAndStudents?.length === 0 && isSearchFocused" class="upload-students-button-container">
			<div class="upload-students-button" (mousedown)="showImportStudentList()">Upload a list of students</div>
		</div>
		<div *ngIf="currentSearch === '' && (!groups || selectedGroupsAndStudents?.length > 0)" style="padding-top: 12px">
			<app-gradient-button
				[size]="'editable'"
				[width]="continueButtonWidth"
				[minHeight]="'51px'"
				[fontSize]="'16px'"
				[cursor]="'pointer'"
				[disabled]="selectedGroupsAndStudents?.length === 0"
				(buttonClick)="nextStep()">
				Continue
			</app-gradient-button>
		</div>
	</div>

	<div class="no-fly-time-footer" *ngIf="FORM_STATE.noFlyTimeActive && !FORM_STATE.forLater">
		<div
			cdkOverlayOrigin
			#overlayTrigger="cdkOverlayOrigin"
			class="no-fly-time-bubble"
			(mouseenter)="showNoFlyTimeTooltip()"
			(mouseleave)="closeNoFlyTimeTooltip()">
			<img class="no-fly-time-icon" [src]="'./assets/no-fly-time (2).svg' | resolveAsset" />
			<span>NO FLY TIME UNTIL {{ FORM_STATE.currentNoFlyEndTimeString }}</span>
		</div>

		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="overlayTrigger"
			[cdkConnectedOverlayOpen]="isOpenNoFlyTimeTooltip | async"
			[cdkConnectedOverlayWidth]="335"
			[cdkConnectedOverlayHeight]="noFlyTimeTooltipHeight">
			<app-no-fly-time-tooltip
				[noFlyTimes]="FORM_STATE.noFlyTimes"
				[isStaff]="true"
				[currentNoFlyTimeIndex]="FORM_STATE.currentNoFlyTimeIndex"
				(mouseleave)="closeNoFlyTimeTooltip()"
				(mouseover)="showNoFlyTimeTooltip()">
			</app-no-fly-time-tooltip>
		</ng-template>
	</div>
</div>
