/* eslint-disable */

import * as moment from 'moment';

export function getStartOfSchoolYear(): Date {
	const now = new Date();
	if (now.getMonth() < 6) {
		return new Date(now.getFullYear() - 1, 6, 1);
	} else {
		return new Date(now.getFullYear(), 6, 1);
	}
}
export function getStartOfSchoolYearAsMoment(): moment.Moment {
	const now = new Date();
	if (now.getMonth() < 6) {
		// months are 0 indexed, so month 6 is July
		return moment(new Date(now.getFullYear() - 1, 6, 1));
	} else {
		return moment(new Date(now.getFullYear(), 6, 1));
	}
}
export function memo<T extends Function>(fnToMemoize: T): T {
	let prevArgs = [{}];
	let result;

	return function (...newArgs) {
		if (hasDifferentArgs(prevArgs, newArgs)) {
			result = fnToMemoize(...newArgs);
			prevArgs = newArgs;
		}
		return result;
	} as any;
}

function hasDifferentArgs(prev: unknown[], next: unknown[]) {
	if (prev.length !== next.length) return true;
	for (let i = 0; i < prev.length; i++) {
		if (!Object.is(prev[i], next[i])) return true;
	}
	return false;
}
