<form class="tw-flex tw-flex-col tw-gap-y-6" [formGroup]="form">
	<ng-container *ngFor="let permission of permissions">
		<div class="tw-flex">
			<div class="tw-flex tw-flex-col tw-gap-y-2 tw-w-90">
				<div class="tw-text-navy-500 tw-font-bold tw-text-base">{{ permission.label }}</div>
				<div class="tw-text-gray-500 tw-font-normal tw-text-sm">{{ permission.description }}</div>
			</div>
			<div class="tw-ml-auto">
				<app-toggle-input [form]="form" [controlName]="permission.controlName" [controlSize]="'small'" [delimiter]="false"></app-toggle-input>
			</div>
		</div>
	</ng-container>
</form>
