import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../models/User';
import { UserService } from '../../services/user.service';
import { filter, take } from 'rxjs/operators';

@Component({
	selector: 'app-back-to-school-teacher-nux',
	templateUrl: './back-to-school-teacher-nux.component.html',
	styleUrls: ['./back-to-school-teacher-nux.component.scss'],
})
export class BackToSchoolTeacherNuxComponent implements OnInit {
	firstName: string;
	dialogRef: MatDialogRef<BackToSchoolTeacherNuxComponent>;

	constructor(private userService: UserService, @Inject(MAT_DIALOG_DATA) public data: { user: User }) {}

	ngOnInit(): void {
		this.firstName = this.data.user.display_name.split(' ')[0];
		this.userService.introsData$
			.pipe(
				filter((i) => !!i),
				take(1)
			)
			.subscribe((intros) => {
				this.userService.updateIntrosSeenBackToSchoolNuxRequest(intros, 'universal', '1');
			});
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
