import { Component, Input } from '@angular/core';

interface OrgUnits {
	path: string;
}
@Component({
	selector: 'app-sp-chip',
	templateUrl: './sp-chip.component.html',
	styleUrls: ['./sp-chip.component.scss'],
})
export class SpChipComponent {
	@Input() bgColor = '#E32C66';
	@Input() text: string;
	@Input() textColor = '#FFFFFF';
}
