<input type="tel" tabindex="1" style="position: absolute; width: 0; height: 0; opacity: 0" autofocus (blur)="blur()" [id]="requestId" #inp />
<div class="teacher-pin-view">
	<div class="content">
		<div class="teacher-pin">
			<div class="pin-title">Enter Teacher Pin</div>
			<div class="circles" [ngClass]="{ incorrect: incorrect }">
				<div class="circle" *ngFor="let pressed of circles" [style.background-color]="pressed ? '#7083a0' : 'transparent'"></div>
			</div>
		</div>
		<div class="pin-info">{{ attempts < 5 ? 'Pass Request will delete after ' + attempts + ' attempts' : 'Type with Keyboard' }}</div>
	</div>
</div>

<ng-template #confirmDialogBody>
	<div class="ds-dialog-normal-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>
