import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
	name: 'momentFormatPipe',
	pure: true,
})
export class MomentFormatPipePipe implements PipeTransform {
	// This pipe allows us to use moment to format dates, which has more
	// options than the angular date pipe.
	transform(date: Date | string, format: string): string {
		return moment(date).format(format);
	}
}
