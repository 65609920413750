<div class="host">
	<div class="appearance-header">
		<div class="back-button" appCrossPointerEventTarget mat-dialog-close>
			<img [width]="28" [src]="'./assets/Back Button (Blue-White).svg'" />
		</div>
		<div>
			<img [width]="24" [src]="'./assets/Glasses (White).svg'" alt="Glasses (White).svg" />
		</div>
		<div class="ds-dialog-title-white ds-pl-10">
			<span>Appearance</span>
		</div>
	</div>
	<div class="appearance-content">
		<div class="top-content">
			<div class="theme">
				<span>Theme</span>
			</div>
			<app-restriction-picker
				[width]="255"
				[height]="32"
				[choices]="['Light', 'Dark', 'Auto']"
				[selectedChoice]="selectedTheme"
				(result)="setSelectedTheme($event)">
			</app-restriction-picker>
		</div>

		<div class="page-layout-wrapper">
			<div class="page-layout" [ngClass]="{ 'page-position': showWrapper }" *ngIf="isStaff">
				<div class="header">
					<div class="header-title">Show Ended Passes</div>
					<div class="header-info">Show ended passes on your home page.</div>
				</div>
				<div class="picker">
					<app-toggle-input
						[delimiter]="false"
						[controlSize]="'small'"
						[form]="form"
						[controlName]="'show_expired_passes'"
						(pushOutValue)="updateUser({ show_expired_passes: $event })"></app-toggle-input>
				</div>
			</div>
			<div class="layout-wrapper" *ngIf="showWrapper"></div>
		</div>
	</div>
</div>
