import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { PassLimitInfo } from 'app/models/HallPassLimits';
import { PassLimitService } from 'app/services/pass-limit.service';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { LocationOverrideConfig } from '../../../../location-table-v2/location-table-v2.component';
import { Location } from '../../../../models/Location';
import { Pinnable } from '../../../../models/Pinnable';
import { FeatureFlagService } from '../../../../services/feature-flag.service';
import { HallPassesService } from '../../../../services/hall-passes.service';
import { LocationsService } from '../../../../services/locations.service';
import { ScreenService } from '../../../../services/screen.service';
import { CreateFormService } from '../../../create-form.service';
import { Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';

@Component({
	selector: 'sp-select-category-room',
	templateUrl: './select-category-room.component.html',
	styleUrls: ['./select-category-room.component.scss'],
})
export class SelectCategoryRoomComponent implements OnInit {
	@ViewChild('header', { static: true }) header: ElementRef<HTMLDivElement>;

	@ViewChild('rc', { static: true }) set rc(rc: ElementRef<HTMLDivElement>) {
		if (rc) {
			fromEvent(rc.nativeElement, 'scroll').subscribe((evt: Event) => {
				let blur: number;

				if ((evt.target as HTMLDivElement).scrollTop < 100) {
					blur = 5;
				} else if ((evt.target as HTMLDivElement).scrollTop > 100 && (evt.target as HTMLDivElement).scrollTop < 400) {
					blur = (evt.target as HTMLDivElement).scrollTop / 20;
				} else {
					blur = 20;
				}
				this.header.nativeElement.style.boxShadow = `0 1px ${blur}px 0px rgba(0,0,0,.2)`;
			});
		}
	}

	@Input() date;

	@Input() isStaff: boolean;
	@Input() isKioskMode: boolean;

	@Input() formState: Navigation;

	@Input() studentText;

	@Output() selectedLocation: EventEmitter<Location> = new EventEmitter();
	@Output() backButton: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('confirmDialogBodyVisibility') confirmDialogVisibility: TemplateRef<HTMLElement>;

	shadow: boolean;
	frameMotion$: BehaviorSubject<any>;

	headerTransition = {
		'to-header': true,
		'to-header_animation-back': false,
	};

	suggestedPinnables: Observable<Pinnable[]> = of([]);
	originLocation: Location;
	originPinnable$: Observable<Pinnable>;
	destPinnable: Pinnable;
	categoryConfig: LocationOverrideConfig;

	@HostListener('scroll', ['$event'])
	tableScroll(event) {
		const tracker = event.target;
		const limit = tracker.scrollHeight - tracker.clientHeight;
		if (event.target.scrollTop < limit) {
			this.shadow = true;
		}
		if (event.target.scrollTop === limit) {
			this.shadow = false;
		}
	}

	updatedLocation$: Observable<Location>;
	destroy$: Subject<any> = new Subject<any>();

	constructor(
		private formService: CreateFormService,
		public screenService: ScreenService,
		private pinnableService: HallPassesService,
		private locationService: LocationsService,
		private featureFlagService: FeatureFlagService,
		private studentPassLimits: PassLimitService
	) {}

	ngOnInit(): void {
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.destPinnable = this.formState.data.direction.pinnable;
		this.originLocation = this.formState.data.direction.from;

		if (this.originLocation) {
			this.originPinnable$ = this.pinnableService.pinnables$.pipe(
				map((pins) => {
					const pin = pins.find((p) => p?.location?.id === this.originLocation.id) || pins.find((p) => p?.category === this.originLocation.category);
					return new Pinnable(
						undefined,
						this.originLocation.title,
						pin.gradient_color,
						pin.icon,
						'location',
						this.originLocation,
						pin.category,
						pin.color_profile,
						pin.ignore_students_pass_limit,
						pin.show_as_origin_room
					);
				})
			);
		}

		if (this.formState.data?.direction?.from?.id) {
			const studentPassLimit$ =
				this.isStaff && !this.formState.kioskMode ? of<PassLimitInfo>({ showPasses: false }) : this.studentPassLimits.passLimitChanges$();

			this.suggestedPinnables = combineLatest(
				this.locationService.getSuggestedRooms(this.formState.data?.direction?.from.id),
				this.pinnableService.pinnables$.pipe(take(1)),
				studentPassLimit$
			).pipe(
				takeUntil(this.destroy$),
				map(([response, allPins, passLimitInfo]) => {
					const pins = response.suggested_locations.map((loc) => {
						return allPins.find((p) => p?.location?.id === loc.location.id) || Pinnable.fromAugmentedLocation(loc);
					});
					const { showPasses, current } = passLimitInfo;
					return pins.map((p) => {
						const newPinnable = cloneDeep(p);
						newPinnable.location.restricted = p.location.restricted || (!p.ignore_students_pass_limit && showPasses && current === 0);
						return newPinnable;
					});
				})
			);
		} else {
			this.suggestedPinnables = of([]);
		}

		const locationConfig = {
			category: this.destPinnable.category,
		};
		this.categoryConfig = {
			location$: this.locationService.getLocationsWithConfigV2(locationConfig),
			searchConfig: locationConfig,
			headerText: this.destPinnable.title,
		};
		this.frameMotion$.subscribe((v: any) => {
			switch (v.direction) {
				case 'back':
					this.headerTransition['from-header'] = false;
					this.headerTransition['from-header_animation-back'] = true;
					break;
				case 'forward':
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
					break;
				default:
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
			}
		});
	}

	backToDestinationSelect() {
		this.formService.setFrameMotionDirection('back');
		setTimeout(() => {
			this.formState.data.direction.to = null;
			this.formState.data.direction.pinnable = null;
			this.formState.previousState = this.formState.state;
			this.formState.state = !this.formState?.forLater ? States.FromToWhere : States.toWhere;
			this.backButton.emit(this.formState);
		}, 100);
	}

	backToOriginSelect() {
		if (this.isKioskMode) {
			return;
		}
		this.formService.setFrameMotionDirection('back');
		setTimeout(() => {
			this.formState.data.direction = {
				to: null,
				from: null,
				pinnable: null,
			};
			this.formState.previousState = this.formState.state;
			this.formState.data.roomStudentsAfterFromStep = null;
			this.formState.state = !this.formState?.forLater ? States.FromToWhere : States.from;
			this.backButton.emit(this.formState);
		}, 100);
	}

	locationSelected(location: Location) {
		this.formService
			.checkRoomVisibility(this.formState, false, location, () => {
				this.selectedLocation.emit(location);
			})
			.subscribe();
	}
}
