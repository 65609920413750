import { useEffect, useState } from 'react';

export function useContainerHeight(numInstances: number, isLoading: boolean) {
	const [containerHeight, setContainerHeight] = useState(0);
	useEffect(() => {
		if (!isLoading) {
			const boxHeight = 6.75; // Height of one box in rem
			const gapHeight = 1.75; // Gap between rows in rem
			const boxesPerRow = 4;
			const heightOfRestOfComponent = 17.4; // Height of the rest of the component in rem

			const numRows = Math.max(Math.ceil(numInstances / boxesPerRow), 1);

			// Total height calculation: Height of all boxes plus gaps between rows
			// For the last row, we don't add the gap
			const totalHeightInRem = numRows * boxHeight + (numRows - 1) * gapHeight;

			setContainerHeight(heightOfRestOfComponent + totalHeightInRem);
		}
	}, [numInstances, isLoading]);

	return { containerHeight };
}
