<div class="sign-up-wrapper">
	<div class="su-content">
		<form [formGroup]="filters" *ngIf="filters">
			<div class="su-header">
				<span>Add to Schedule</span>
				<div>
					<img [src]="'./assets/Search Normal (Blue-Gray).svg' | resolveAsset" alt="search" />
					<input class="su-search" type="text" placeholder="Search" formControlName="search" />
				</div>
			</div>
			<div class="su-filters">
				<div>
					<select formControlName="flexPeriod">
						<option *ngFor="let option of flexOptions" [value]="option.value">{{ option.name }}</option>
					</select>
				</div>
				<div>
					<select formControlName="date">
						<option [value]="DaysOfWeek.Sun">Sunday</option>
						<option [value]="DaysOfWeek.Mon">Monday</option>
						<option [value]="DaysOfWeek.Tue">Tuesday</option>
						<option [value]="DaysOfWeek.Wed">Wednesday</option>
						<option [value]="DaysOfWeek.Thu">Thursday</option>
						<option [value]="DaysOfWeek.Fri">Friday</option>
						<option [value]="DaysOfWeek.Sat">Saturday</option>
					</select>
				</div>
				<div>
					<select formControlName="availability" class="secondary">
						<option [value]="false" default>Include unavailable activities</option>
						<option [value]="true">Only available activities</option>
					</select>
				</div>
			</div>
			<ng-container *ngIf="visibleInstances?.length; else noActivitiesAvailable">
				<div class="su-divider"></div>
				<div class="su-activities">
					<sp-activity
						*ngFor="let vi of visibleInstances"
						[activity]="vi.activity"
						[instance]="vi.instance"
						[isSelected]="vi.instance.selected"
						(selected)="signUp($event)"></sp-activity>
					<div *ngIf="visibleInstances.length === 0">No activities available</div>
				</div>
			</ng-container>
		</form>
	</div>
</div>

<ng-template #noActivitiesAvailable>
	<div [ngSwitch]="processing" class="no-activities">
		<div *ngSwitchCase="ProcessingState.Loading">Loading activities...</div>
		<div *ngSwitchCase="ProcessingState.Loaded">No activities available.</div>
		<div *ngSwitchCase="ProcessingState.Error">Error encountered while retrieving activities. Try refreshing the page.</div>
	</div>
</ng-template>
