<div>
	<ng-container *ngIf="listGroups.length > 1; else noTabs">
		<mat-tab-group
			class="mat-tab-group period-picker-tab"
			id="list-tab-group"
			disableRipple
			disablePagination
			[selectedIndex]="selectedIndex"
			(selectedIndexChange)="onSelectTab($event)">
			<mat-tab *ngFor="let group of listGroups">
				<ng-template mat-tab-label>
					<div class="tab-label">{{ group.name }}</div>
				</ng-template>
				<ng-container [ngTemplateOutlet]="sharedContent"></ng-container>
			</mat-tab>
		</mat-tab-group>
	</ng-container>
</div>

<ng-template #noTabs>
	<ng-container [ngTemplateOutlet]="sharedContent"></ng-container>
</ng-template>

<ng-template #sharedContent>
	<div class="tw-grid {{ periodPickerColumnsClass }} tw-mb-4">
		<div *ngFor="let column of columns" class="tw-overflow-x-auto tw-mb-4">
			<div *ngIf="bubbleSelectedStatuses$ | async as bubbleSelectedStatuses" class="tw-mx-2 tw-text-center">
				<p class="tw-text-navy-500 tw-text-base tw-font-bold">{{ column.label }}</p>
				<div *ngFor="let bubble of column.bubbles" class="tw-my-2">
					<div>
						<div
							class="checkbox tw-mx-auto"
							[ngClass]="{ selected: bubbleSelectedStatuses.get(bubble) }"
							(click)="toggleBubble(column.label, bubble)">
							<span class="index">{{ bubble.label }}</span>
							<span *ngIf="bubble.period_groupings.length > 1" class="sp-lunch">*</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-template #pickSpecialTime>
		<div class="tw-flex tw-justify-start tw-overflow-x-auto tw-mb-4">
			<div *ngIf="selectedPeriodGroupingIds$ | async as selectedPeriodGroupingIds" class="tw-mx-2 tw-text-center">
				<div class="tw-my-2">
					<div *ngFor="let periodGrouping of openBubble.period_groupings" class="radio-buttons">
						<label class="tw-text-gray-500 tw-text-base">
							<input
								type="radio"
								[value]="periodGrouping"
								[checked]="selectedPeriodGroupingIds.includes(periodGrouping.id)"
								(change)="selectPgOfBubble(periodGrouping, openBubble)"
								name="selected" />
							<span class="tw-font-bold tw-pr-1 tw-pt-1">{{ periodGrouping.label }}: </span>
							<span class="tw-pt-1" style="text-wrap: nowrap">{{ periodGrouping.time_string }}</span>
						</label>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</ng-template>

<ng-template #allPeriodsSelectedToast>
	<div>All periods selected. <span (click)="undoSelect()" class="tw-cursor-pointer tw-underline">Undo</span></div>
</ng-template>
