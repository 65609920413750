<div #wrapper [ngClass]="isIOSTablet ? 'ios-form' : 'form'" [style.width]="formSize.containerWidth" [style.height]="formSize.containerHeight">
	<div
		*ngIf="FORM_STATE.step === 1"
		[ngClass]="isIOSTablet ? 'ios-fixed-card' : 'fixed-card'"
		[style.z-index]="1"
		[style.width]="formSize.width"
		[style.height]="formSize.height"
		[@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div [attr.data-motion-compress]="'calendar:' + (frameMotion$ | async).direction" class="calendar inner-container">
			<app-date-time-container [FORM_STATE]="FORM_STATE" (nextStepEvent)="onNextStep($event)"></app-date-time-container>
			<app-pass-limit-info *ngIf="!dialogData.forLater" [passLimitInfo]="passLimitInfo"></app-pass-limit-info>
		</div>
	</div>

	<div
		*ngIf="FORM_STATE.step === 2"
		[ngClass]="isIOSTablet ? 'ios-fixed-card' : 'fixed-card'"
		[style.z-index]="0"
		[style.width]="formSize.width"
		[style.height]="formSize.height"
		[@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div
			[attr.data-motion-compress]="'students:' + (frameMotion$ | async).direction"
			[attr.data-motion-opacity]="(frameMotion$ | async).direction"
			class="student-groups inner-container">
			<app-groups-container-v2 [FORM_STATE]="FORM_STATE" (nextStepEvent)="onNextStep($event)"></app-groups-container-v2>
		</div>
	</div>

	<div
		*ngIf="FORM_STATE.step === 3"
		[ngClass]="isIOSTablet ? 'ios-fixed-card' : 'fixed-card'"
		[style.z-index]="1"
		[style.width]="formSize.width"
		[style.height]="formSize.height"
		[@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div
			[attr.data-motion-compress]="'locations:' + (frameMotion$ | async).direction"
			[attr.data-motion-opacity]="(frameMotion$ | async).direction + 2"
			[attr.data-motion-scale]="isScaled | async"
			class="locations inner-container">
			<app-locations-group-container [FORM_STATE]="FORM_STATE" (nextStepEvent)="onNextStep($event)"></app-locations-group-container>
		</div>
	</div>

	<div
		*ngIf="FORM_STATE.step === 4"
		[ngClass]="isIOSTablet ? 'ios-fixed-card' : 'fixed-card'"
		[style.width]="formSize.width"
		[style.height]="formSize.height"
		[@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-form-factor-container
			[passLimitInfo]="passLimitInfo"
			[FORM_STATE]="FORM_STATE"
			[forStaff]="isStaff"
			(nextStepEvent)="onNextStep($event)"></app-form-factor-container>
	</div>
</div>
