import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { States } from 'app/create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateFormService } from '../../../create-hallpass-forms/create-form.service';
import { Navigation } from '../../../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { GroupsSettingsComponent } from '../../../create-hallpass-forms/main-hallpass--form/student-groups/groups-settings/groups-settings.component';
import { DeviceDetection } from '../../../device-detection.helper';
import { Location } from '../../../models/Location';
import { Pinnable } from '../../../models/Pinnable';
import { StudentList } from '../../../models/StudentList';
import { User } from '../../../models/User';
import { PassesAnimations } from '../../../passes/passes.animations';
import { FeatureFlagService, FLAGS } from '../../../services/feature-flag.service';
import { HallPassesService } from '../../../services/hall-passes.service';
import { HomepageService } from '../../../services/homepage.service';
import { KioskModeService } from '../../../services/kiosk-mode.service';
import { ScheduleService } from '../../../services/schedule.service';
import { ScreenService } from '../../../services/screen.service';
import { UserService } from '../../../services/user.service';

@Component({
	selector: 'app-groups-and-student-select',
	templateUrl: './groups-and-student-select.component.html',
	styleUrls: ['./groups-and-student-select.component.scss'],
	animations: [PassesAnimations.TooltipFadeInOut],
})
export class GroupsAndStudentSelectComponent implements OnInit, OnDestroy, OnChanges {
	@Input() groups: StudentList[];
	@Input() students: User[];
	@Input() selectedNumber: number;
	@Input() canEditGroup = true;
	@Input() emptyStateString = '';
	@Output() handleSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() mouseDown: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() formState: Navigation;

	//styling
	@Input() width: string;
	@Input() height: string;
	@Input() maxHeight: string;
	@Input() border: string;
	@Input() borderBottomRightRadius: string;
	@Input() borderBottomLeftRadius: string;
	@Input() boxShadow: string;
	@Input() background: string;
	@Input() backgroundHovered: string;
	@Input() minHeight: string;

	@Output() createGroupEmit: EventEmitter<Navigation> = new EventEmitter<Navigation>();

	@ViewChild('_item') currentGroupElement: ElementRef;

	isEmptyGroups = false;
	originLocationMap: Record<number, Location[]> = {};
	studentHoverStates: { [key: string]: boolean } = {};
	studentTooltipHoverStates: { [key: string]: boolean } = {};

	frameMotion$: BehaviorSubject<any>;
	destroy$: Subject<void> = new Subject<void>();

	hasSchedulesFF = false;
	pinnables: Pinnable[];

	isKiosk = this.kioskService.isKioskMode();

	importCreateGroupDialogRef: MatDialogRef<GroupsSettingsComponent>;

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		public dialog: MatDialog,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public kioskService: KioskModeService
	) {}

	ngOnInit() {
		this.hasSchedulesFF = this.featureFlagService.isFeatureEnabledV2(FLAGS.Schedules);
		this.frameMotion$ = this.formService.getFrameMotionDirection();

		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.pinnables = pinnables;
		});

		of(!this.groups || (this.groups && !this.groups.length)).subscribe((v) => {
			this.isEmptyGroups = v;
		});

		if (this.hasSchedulesFF) {
			this.groups?.forEach((group) => {
				group.users?.forEach((student) => {
					this.getStudentOriginAndUpdateMap(student);
				});
			});

			this.students?.forEach((student) => {
				this.getStudentOriginAndUpdateMap(student);
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.students && this.hasSchedulesFF) {
			this.groups?.forEach((group) => {
				group.users?.forEach((student) => {
					this.getStudentOriginAndUpdateMap(student);
				});
			});

			this.students?.forEach((student) => {
				this.getStudentOriginAndUpdateMap(student);
			});
		}
	}

	getPinnableBackground(pin: Pinnable): SafeStyle {
		const gradient: string[] = pin?.color_profile?.gradient_color.split(',');
		return this.sanitizer.bypassSecurityTrustStyle('radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')');
	}

	getPinnableFromLocation(location: Location): Pinnable {
		return this.pinnables.find((p) => p?.location?.id === location?.id) || this.pinnables.find((p) => p?.category === location?.category);
	}

	//change to get classes later and update with classes vs location
	private getStudentOriginAndUpdateMap(student: User): void {
		if (student) {
			this.originLocationMap[student.id] = student.current_classes;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	getBackground(item) {
		if (item.hovered) {
			return this.backgroundHovered ? this.backgroundHovered : '#F5F6F8';
		} else {
			return this.background ? this.background : '#FFFFFF';
		}
	}

	nextStep() {
		if (this.screenService.isDeviceLargeExtra) {
			this.formService.setFrameMotionDirection('forward');
			this.formService.compressableBoxController.next(false);
		} else {
			this.formService.setFrameMotionDirection('disable');
		}
		setTimeout(() => {
			if (this.formState.forLater) {
				this.formState.step = 1;
				this.formState.fromState = !this.formState?.forLater ? States.FromToWhere : States.from;
			} else {
				this.formState.step = 3;
				this.formState.state = !this.formState?.forLater ? States.FromToWhere : States.from;
				this.formState.fromState = !this.formState?.forLater ? States.FromToWhere : States.from;
			}

			// if (this.selectedGroup) {
			// 	this.formState.data.selectedGroup = this.selectedGroup;
			// 	this.formState.data.selectedStudents = this.selectedGroup.users;
			// } else {
			// 	this.formState.data.selectedGroup = null;
			// 	this.formState.data.selectedStudents = this.selectedStudents;
			// }
		}, 100);
	}

	delayTooltipForStudentSelect(student: User, value: boolean): void {
		setTimeout(() => {
			this.setStudentHoverState(student.id, value);
		}, 350);
	}

	delayTooltipForTooltipHover(student: User, value: boolean): void {
		setTimeout(() => {
			this.setStudentTooltipHoverState(student.id, value);
		}, 350);
	}

	setStudentHoverState(studentId: number, state: boolean) {
		if (state) {
			setTimeout(() => {
				this.studentHoverStates[studentId] = state;
			}, 300);
		} else {
			this.studentHoverStates[studentId] = state;
		}
	}

	setStudentTooltipHoverState(studentId: number, state: boolean) {
		if (state) {
			setTimeout(() => {
				this.studentTooltipHoverStates[studentId] = state;
			}, 300);
		} else {
			this.studentTooltipHoverStates[studentId] = state;
		}
	}

	createGroup() {
		this.formService.setFrameMotionDirection('disable');

		setTimeout(() => {
			this.formState.state = !this.formState?.forLater ? States.FromToWhere : States.toWhere;
			this.formState.step = 2;
			this.formState.fromState = !this.formState?.forLater ? States.FromToWhere : States.from;
			// this.formState.data.selectedStudents = this.selectedStudents;
			this.createGroupEmit.emit(this.formState);
		}, 100);
	}

	onSelect(item) {
		this.handleSelect.emit(item);
	}

	editGroup(event: Event, group: StudentList) {
		event.stopPropagation();
		this.importCreateGroupDialogRef = this.dialog.open(GroupsSettingsComponent, {
			closeOnNavigation: true,
			panelClass: 'app-group-settings-container',
			data: {
				formState: this.formState,
				groupToEdit: group,
			},
		});
	}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	get isMobile() {
		return DeviceDetection.isMobile();
	}
}
