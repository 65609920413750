import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'sp-sidenav-floating-menu',
	template: `
		<div class="sidenav-floating-menu">
			<ng-container *ngTemplateOutlet="data.submenu"></ng-container>
		</div>
	`,
	styles: [
		`
			.sidenav-floating-menu {
				display: flex;
				flex-direction: column;
				gap: 0.75rem;
			}
		`,
	],
})
export class SidenavFloatingMenuComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: { submenu: TemplateRef<unknown> }) {}
}
