export abstract class BaseModel {
	abstract id: number;

	isSameObject(that: BaseModel) {
		return this?.id === that?.id;
	}

	isAssignedToSchool(schoolId: number): boolean {
		const mySchool: number = (this as any).school_id || (this as any).school_id_fk;

		if (mySchool === undefined) {
			// why is this even here?
			console.log(`Object ${JSON.stringify(this)} has no school_id`);
			return true;
		}

		return mySchool === schoolId;
	}
}

export interface ReadableModel extends BaseModel {
	isRead: boolean;
}
