<div class="section-header">
	<div class="tw-text-base tw-text-navy-500 tw-font-semibold tw-mb-3 tw-pt-3">Dates</div>
</div>
<sp-class-term-picker
	[initialSelectedTermIds]="initialSelectedTermIds"
	[schedules]="schedules"
	(selection)="onTermIdsSelected($event)"></sp-class-term-picker>

<div class="section-header">
	<div class="tw-text-base tw-text-navy-500 tw-font-semibold tw-mb-1 tw-mt-12">Periods</div>
	<div class="tw-text-gray-500 tw-font-normal tw-text-sm tw-mt-1">Tap any bubble to make a change. Don’t forget to add labs!</div>
</div>

<ng-container *ngIf="listGroups">
	<sp-class-period-picker
		[periodGroupingIds]="initialSelectedPeriodGroupingIds"
		[listGroups]="listGroups"
		(selectedPeriodGroupingIds)="selectedPeriodGroupingIds.emit($event)">
	</sp-class-period-picker>
</ng-container>
