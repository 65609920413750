<div *ngIf="scheduleData">
	<ng-container *ngIf="scheduleData.length > 1; else noTabs">
		<mat-tab-group
			(selectedIndexChange)="onSelectSchedule($event)"
			[selectedIndex]="selectedTabIndex"
			[ngClass]="customClass"
			class="mat-tab-group set-schedule-tab"
			disableRipple
			id="schedule-grades-tab-group">
			<mat-tab *ngFor="let s of scheduleData">
				<ng-template mat-tab-label>
					<div class="tab-label tw-font-medium">{{ s.name }}</div>
				</ng-template>

				<!-- MAT TAB CONTENT -->
				<ng-container [ngTemplateOutletContext]="{ schedule: s }" [ngTemplateOutlet]="scheduleContent"></ng-container>
				<!-- END MAT TAB CONTENT -->
			</mat-tab>
		</mat-tab-group>
	</ng-container>

	<ng-template #noTabs>
		<ng-container [ngTemplateOutletContext]="{ schedule: selectedScheduleGroup }" [ngTemplateOutlet]="scheduleContent"></ng-container>
	</ng-template>
	<ng-template #scheduleContent let-s="schedule">
		<div
			class="set-schedule-wrapper tw-flex tw-mt-5 tw-border tw-border-solid tw-border-gray-300 tw-text-gray-500 tw-rounded-xl tw-overflow-hidden tw-box-border">
			<!-- LEFT PANE -->
			<div
				#scrollContainer
				class="tw-bg-gray-050 set-schedule-left-pane tw-border-r tw-border-solid tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-box-border tw-overflow-auto">
				<ng-container *ngFor="let group of s.bell_schedule_groups; let first = first; let last = last; let index = index">
					<div [ngClass]="{ 'tw-mb-9': !last }">
						<!-- GROUP NAME = 'Regular', 'early dismissal', etc -->
						<div
							[ngClass]="{
								'tw-text-navy-500': group.id === selectedBellScheduleGroup.id,
								'tw-text-gray-400': group.id !== selectedBellScheduleGroup.id
							}"
							class="tw-font-medium tw-text-xs tw-ml-2 group-name">
							{{ group.name }}
						</div>
						<!-- DAY TYPE NAME = 'A Day', 'B Day,', etc -->
						<ng-container *ngFor="let bs of group.bell_schedules; let index = index; let nestedLast = last">
							<div
								(click)="onSelectDayType(bs.id)"
								[ngClass]="{
									'tw-text-white tw-bg-navy-500': bs.id | selectedBellSchedule : dataToSave : displayAddHolidayPane,
									'tw-text-gray-500 hover:tw-bg-gray-150': !(bs.id | selectedBellSchedule : dataToSave : displayAddHolidayPane)
								}"
								class="day-type-name tw-font-medium tw-text-base tw-rounded-lg tw-mb-1 tw-flex tw-gap-2 tw-whitespace-nowrap"
								id="{{ bs.id === selectedBellScheduleId ? 'initially-selected' : 'bs-' + index }}">
								<div>{{ bs.long_name }}</div>
							</div>

							<!-- NO SCHOOL OPTION -->
							<ng-container *ngIf="!readOnly && !isDeletingHoliday && first && nestedLast">
								<div
									(click)="selectHolidayDisplay()"
									[ngClass]="{
										'tw-text-white tw-bg-navy-500': displayAddHolidayPane,
										'tw-text-gray-500 hover:tw-bg-gray-150': !displayAddHolidayPane
									}"
									class="day-type-name tw-font-medium tw-text-base tw-rounded-lg tw-mb-1 tw-flex tw-gap-2 tw-whitespace-nowrap"
									id="no-school">
									<div>No School</div>
									<img
										[ngClass]="{ selected: displayAddHolidayPane }"
										[src]="displayAddHolidayPane ? './assets/Beach Umbrella-selected.svg' : './assets/Beach Umbrella.svg'"
										alt="umbrella icon" />
								</div>
							</ng-container>
							<!-- END NO SCHOOL OPTION -->
						</ng-container>
					</div>
				</ng-container>
			</div>
			<!-- END LEFT PANE -->

			<!-- RIGHT PANE -->
			<div class="set-schedule-right-pane tw-flex-grow tw-box-border tw-overflow-auto">
				<!-- BELL SCHEDULE DISPLAY -->
				<div *ngIf="selectedBellScheduleGroup && !displayAddHolidayPane" class="tw-flex-col tw-flex tw-justify-center tw-items-center tw-text-center">
					<ng-container *ngIf="selectedBellSchedule?.period_bubbles.length > 0; else noPeriods">
						<div class="tw-text-navy-500 tw-text-base tw-font-medium tw-px-2">{{ selectedBellSchedule?.long_name }}</div>

						<div class="tw-mt-3 tw-flex-col tw-flex tw-justify-center tw-items-center">
							<ng-container *ngFor="let bubble of selectedBellSchedule?.period_bubbles; let bubbleIdx = index">
								<div
									*ngIf="
										bubble.period_groupings.length > 1 && (bubbleIdx === 0 ? true : selectedBellSchedule[bubbleIdx - 1]?.period_bubbles?.length <= 1)
									"
									class="tw-font-normal tw-text-sm tw-text-gray-500">
									---
								</div>
								<div class="tw-font-normal tw-text-sm tw-text-gray-500">
									{{ bubble.long_name }}: {{ bubble.start_time | militaryTimeString }} - {{ bubble.end_time | militaryTimeString }}
								</div>
								<ng-container *ngIf="bubble.period_groupings.length > 1">
									<ng-container *ngFor="let grouping of bubble.period_groupings">
										<div class="tw-font-normal tw-text-sm tw-text-gray-500">
											{{ grouping.name }}:
											<ng-container *ngFor="let period of getAssignable(grouping.periods); let last = last">
												{{ period.start_time | militaryTimeString }} - {{ period.end_time | militaryTimeString }}<span *ngIf="!last">, </span>
											</ng-container>
										</div>
									</ng-container>
									<div class="tw-font-normal tw-text-sm tw-text-gray-500">---</div>
								</ng-container>
							</ng-container>
						</div>
					</ng-container>

					<ng-template #noPeriods>
						<sp-no-periods [selectedBellSchedule]="selectedBellSchedule"></sp-no-periods>
					</ng-template>
				</div>
				<div
					*ngIf="
						!readOnly &&
						!displayAddHolidayPane &&
						nextDayTypeInRotation &&
						this.initiallySelectedBellScheduleId !== this.selectedBellSchedule.id &&
						scheduleOnboardingStatus === 'active'
					"
					class="tw-text-gray-400 tw-text-center tw-text-sm tw-font-normal tw-mt-10">
					This will also set {{ nextDateInRotation | date : 'MMM d, yyyy' : 'UTC' }} to a {{ nextDayTypeInRotation }} and shift future days.
					<a class="tw-text-gray-400 tw-text-center tw-text-sm tw-font-normal" href="https://www.smartpass.app/shift-schedule" target="_blank"
						>Learn more</a
					>
				</div>
				<!-- END BELL SCHEDULE DISPLAY -->

				<!-- ADD HOLIDAY DISPLAY -->
				<div *ngIf="displayAddHolidayPane" class="tw-flex-col tw-flex tw-justify-center tw-items-center tw-h-full">
					<div class="no-school-holiday tw-flex-col tw-flex tw-justify-center tw-items-center">
						<div class="tw-mb-2"><img [src]="'./assets/Beach Umbrella.svg' | resolveAsset" alt="umbrella icon" /></div>
						<div class="tw-text-navy-500 tw-text-base tw-font-medium tw-text-center">Add a Holiday to Set as No School</div>
						<div class="new-holiday-btn tw-mt-3">
							<app-gradient-button
								(click)="!isAddingHoliday && onOpenHolidayModal()"
								[disabled]="isAddingHoliday"
								[border]="'1px solid #D4D9E2'"
								[customBackground]="'#FFFFFF'"
								[customShadow]="'0px 2px 10px 0px rgba(16, 20, 24, 0.10)'"
								[leftIcon]="'./assets/Plus (Gray-500).svg'"
								[textColor]="'#7083A0'"
								>New Holiday
							</app-gradient-button>
						</div>
					</div>
				</div>
				<!-- END ADD HOLIDAY DISPLAY -->
			</div>

			<!-- END RIGHT PANE -->
		</div>
	</ng-template>
</div>
<ng-template #addHolidayModalBody>
	<sp-add-edit-holidays
		[holidaysList]="holidayService.holidayList$ | async"
		[holidayData]="selectedHolidayData"
		[selectedDate]="selectedHolidayDate"
		[addHolidayDialogService]="addHolidayService"></sp-add-edit-holidays>
</ng-template>
