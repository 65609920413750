<div class="wrapper">
	<div class="options-content">
		<div class="option-container" *ngFor="let option of settings">
			<div
				[ngClass]="option.cssClass ? ['option', option.cssClass] : ['option']"
				#opt
				(mouseenter)="opt.hover = true; hoverOption = option; showConfirmButton = false"
				(mouseleave)="opt.hover = false; !hoverOption.withoutHoverDescription ? (hoverOption = null) : null"
				[style.background-color]="opt.hover ? option.backgroundColor : 'transparent'"
				(click)="selectedOption(option, $event)">
				<img width="18" *ngIf="option.icon" [src]="option.icon | resolveAsset" alt="opt-icon" />
				<div class="label" [style.color]="option.textColor" [innerHTML]="option.label"></div>
				<div *ngIf="option.isPro" class="ds-flex-center-start plus-plan-label" customToolTip [contentTemplate]="plusPlanText">
					<img width="15" [src]="'./assets/Plus Plan (Green).svg' | resolveAsset" alt="" />
					<p>Unlock</p>
				</div>
				<ng-template #plusPlanText>
					<div class="ds-tooltip" style="width: 200px">This feature is included in the Digital ID Cards Add-on.</div>
				</ng-template>
			</div>
		</div>
	</div>
	<mat-divider *ngIf="(hoverOption && !hoverOption.withoutHoverDescription) || showConfirmButton"></mat-divider>
	<div class="description" *ngIf="(hoverOption && hoverOption.description && !hoverOption.withoutHoverDescription) || showConfirmButton">
		{{ hoverOption.description }}
		<div class="button" *ngIf="showConfirmButton">
			<app-gradient-button [gradient]="'#E32C66, #E32C66'" (buttonClick)="dialogRef.close('delete')">Confirm delete</app-gradient-button>
		</div>
	</div>
</div>
