<div class="wrapper">
	<div class="options-content">
		<div class="option-container" *ngFor="let option of options">
			<div
				class="option"
				*ngIf="!isSelectedRole(option)"
				#opt
				(mouseenter)="opt.hover = true; hoverOption = option"
				(mouseleave)="opt.hover = false; hoverOption = null"
				[style.background-color]="opt.hover ? 'rgba(127, 135, 157, .1)' : 'transparent'"
				(click)="dialogRef.close(option)">
				<img style="margin-left: 30px" width="18" [src]="option.icon | resolveAsset" alt="opt-icon" />
				<div class="label" [style.color]="'#7F879D'">{{ option.role }}</div>
			</div>
		</div>
	</div>
	<mat-divider *ngIf="!!hoverOption"></mat-divider>
	<div class="description" *ngIf="!!hoverOption">
		{{ hoverOption.description }}
	</div>
</div>
