<div *ngIf="passLimitInfo?.showPasses" class="pass-limit-info">
	<div class="pass-limit-title-container">
		<span class="pass-limit-title"
			>{{ passLimitInfo.current }}/{{ passLimitInfo.max }} passes remaining
			{{ passLimitInfo.frequency === 'day' ? 'today' : 'this ' + passLimitInfo.frequency }}</span
		>
		<div
			*ngIf="passLimitInfo.frequency === 'term'"
			style="display: flex; align-items: center"
			customToolTip
			[additionalOffsetY]="-35"
			[position]="'top'"
			[contentTemplate]="termDatesTooltip">
			<img class="support-icon" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
		</div>
		<ng-template #termDatesTooltip>
			<div class="term-tooltip">
				<div class="header">
					<span class="title">
						{{
							passLimitInfo?.termData?.currentTerm?.name
								? 'Current Term: '
								: passLimitInfo?.termData?.upcomingTerm.name
								? 'Upcoming Term: '
								: 'No Upcoming Term'
						}}
					</span>
					<span>
						{{
							passLimitInfo?.termData?.currentTerm?.name
								? passLimitInfo?.termData?.currentTerm?.name
								: passLimitInfo?.termData?.upcomingTerm.name
								? passLimitInfo?.termData?.upcomingTerm.name
								: ''
						}}
					</span>
				</div>
				<span class="dates" *ngIf="passLimitInfo?.termData?.currentTerm || passLimitInfo?.termData?.upcomingTerm">
					{{
						(passLimitInfo?.termData?.currentTerm
							? passLimitInfo?.termData?.currentTerm?.start_date
							: passLimitInfo?.termData?.upcomingTerm?.start_date
						) | date : 'MM/dd/yyyy'
					}}
					-
					{{
						(passLimitInfo?.termData?.currentTerm ? passLimitInfo?.termData?.currentTerm?.end_date : passLimitInfo?.termData?.upcomingTerm?.end_date)
							| date : 'MM/dd/yyyy'
					}}
				</span>
			</div>
		</ng-template>
	</div>
	<span class="pass-limit-subtitle" *ngIf="passLimitInfo.current === 0">You must request a pass from your teacher.</span>
	<div *ngIf="passLimitInfo.current === 0" class="pass-limit-lock">
		<img [src]="'./assets/Locked (White).svg'" alt="Lock icon" />
	</div>
</div>
