<div
	class="class-container"
	[style.background]="background"
	[style.width]="width"
	[style.height]="height"
	[style.border-radius]="borderRadius"
	[style.padding]="padding">
	<div *ngIf="tooltipType === 'origin'" class="class-name">
		{{ classes[0].display_name }}
	</div>

	<div *ngIf="headerOn && scheduleService.countdownData$ | async as countdownData" class="class-header">
		<span class="header-text"> {{ period }},&nbsp;</span>
		<span class="day-text"> {{ dayType }}&nbsp;</span>

		<div class="countdown">
			<!-- BEFORE SCHOOL -->
			<ng-container
				*ngIf="countdownData.beforeSchool && countdownData.nextPeriod && countdownData.timeUntilNextPeriodStarts < thirtyMinutesInMilliseconds">
				<div class="countdown-period-inactive" *ngIf="countdownData.nextPeriod">in {{ countdownData.timeUntilNextPeriodStarts | countdown }}</div>
			</ng-container>

			<!-- BETWEEN CLASSES -->
			<ng-container *ngIf="!countdownData.beforeSchool && !countdownData.schoolOver && countdownData.noActivePeriod && countdownData.nextPeriod">
				<div class="countdown-period-inactive" *ngIf="countdownData.nextPeriod">in {{ countdownData.timeUntilNextPeriodStarts | countdown }}</div>
			</ng-container>

			<!-- ACTIVE PERIOD, IN CURRENT PERIOD -->
			<ng-container *ngIf="!countdownData.noActivePeriod && countdownData.currentPeriod">
				<div class="countdown-period-active">{{ countdownData.timeUntilCurrentPeriodEnds | countdown }}</div>
			</ng-container>
		</div>
	</div>

	<div class="missing-class-text" *ngIf="tooltipType === 'missing'">
		{{ student.display_name }} isn't assigned to a class right now.
		<a href="https://www.smartpass.app/schedules-class-info-missing" target="_blank" [style.color]="'white'">Learn more.</a>
	</div>

	<div class="conflicting-class-text" *ngIf="tooltipType === 'error'">
		Student is assigned to multiple classes this period.
		<a href="https://www.smartpass.app/schedules-class-info-error" target="_blank" [style.color]="'white'">Learn more.</a>
	</div>

	<div class="line-break" *ngIf="tooltipType === 'error' || tooltipType === 'origin'"></div>

	<div class="class-list" *ngIf="tooltipType === 'error'">
		<span *ngFor="let class of classesTextList; let i = index">
			{{ class }}
		</span>
	</div>
	<div class="teachers-string" *ngIf="tooltipType === 'origin'">
		<span>
			{{ teachersString }}
		</span>
	</div>
</div>
