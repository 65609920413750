<div class="wrapper">
	<div class="heading" #header>
		<div class="back-button">
			<img (click)="back()" *ngIf="page !== 3 && page !== 4" [src]="'./assets/Back Button (Light-Gray).svg'" alt="Back Button (Blue-White).svg" />
			<!-- <div class="cancel" (click)="openConfirm($event)" *ngIf="page === 3 && !(showProcessingSpinner$ | async)">Cancel</div> -->
			<!-- <div class="cancel" (click)="dialog.closeAll()" *ngIf="page === 3 && (showProcessingSpinner$ | async)">Close</div> -->
		</div>
		<div class="ds-dialog-title-white ds-flex-center-center">
			<img [src]="'./assets/Grade level (White).svg' | resolveAsset" width="20" height="20" alt="(White).svg" class="ds-mr-10" />
			<span>Grade Levels</span>
		</div>
		<div class="save-button">
			<app-gradient-button
				*ngIf="page !== 3 && page !== 5"
				[disabled]="!uploadingProgress.csv.complete && page === 2"
				[size]="'small'"
				[gradient]="'#FFFFFF, #FFFFFF'"
				[buttonDownColor]="'#FCFDFF'"
				[textColor]="'#1F195E'"
				[cursor]="'pointer'"
				(buttonClick)="nextPage()">
				<div class="ds-flex-center-start">
					<span>
						{{ page === 4 ? 'Done' : 'Next' }}
					</span>
				</div>
			</app-gradient-button>
			<!-- <div class="dots" *ngIf="page === 5" #dots>
                <app-icon-button [size]="17" [srcString]="'Dots'" [onDarkShade]="true" (clickEvent)="openSettings()"></app-icon-button>
            </div> -->
			<div class="incomplete" *ngIf="false">Incomplete</div>
		</div>
	</div>

	<div class="content">
		<div class="page" *ngIf="page === 1">
			<div class="center-content">
				<img style="margin-right: 15px" [src]="'./assets/emptyStates/upload-file.svg' | resolveAsset" />
				<div style="margin-top: 35px" class="ds-dialog-heading">Add Profile Pictures</div>
				<div class="subtitle">Quickly add profile pictures for all your users with an image map from your SIS.</div>
				<app-gradient-button
					[gradient]="'#07ABC3, #07ABC3'"
					[buttonDownColor]="'#07ABC3'"
					[rightIcon]="'./assets/Chevron Right (White).svg'"
					(buttonClick)="nextPage()"
					>Continue
				</app-gradient-button>
			</div>
		</div>

		<div class="page" [style.padding]="'30px 25px 0 30px'" *ngIf="page === 2">
			<!-- <div class="step-wrapper"> -->
			<!-- <div class="step-container">
                    <div class="step-number" [ngClass]="{selected: uploadingProgress.csv.complete}">1</div>
                    <div class="step"> -->
			<div class="ds-dialog-heading">Add map file</div>
			<div class="description">Upload a CSV containing the email and the corresponding Grade level.</div>
			<!-- </div>
                </div> -->
			<div class="buttons">
				<app-gradient-button
					[gradient]="!uploadingProgress.csv.inProcess && !uploadingProgress.csv.complete ? '#7F879D, #7F879D' : '#1F195E, #1F195E'"
					[buttonDownColor]="'#7F879D'"
					[leftIcon]="'./assets/Simple CSV (White).svg'"
					(buttonClick)="csvFile.click()"
					[size]="'small'"
					>{{ !uploadingProgress.csv.inProcess && !uploadingProgress.csv.complete ? 'Choose CSV' : 'File selected' }}
				</app-gradient-button>
				<app-gradient-button
					[style.margin-left]="'7px'"
					[size]="'small'"
					[gradient]="'#F4F4F4, #F4F4F4'"
					[buttonLink]="'https://www.smartpass.app/gradeleveltemplate'"
					[linkType]="'_blank'"
					[draggable]="true"
					[buttonDownColor]="'#F4F4F4'"
					[textColor]="'#1E194F'"
					[leftIcon]="'./assets/Download (Navy).svg'"
					[text]="'Download Template'"
					[cursor]="'pointer'">
				</app-gradient-button>

				<input type="file" accept=".csv" [formControl]="form.get('csvFile')" #csvFile style="display: none" />
			</div>
			<!-- </div> -->
		</div>

		<div class="page" *ngIf="page === 3">
			<div class="image-container" *ngIf="!(showProcessingSpinner$ | async); else complete">
				<svg
					width="113px"
					height="108px"
					viewBox="0 0 113 108"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink">
					<defs>
						<path
							d="M9.41666667,0 C4.21395833,0 0,4.39363636 0,9.81818182 L0,19.6363636 C0,25.0609091 4.21395833,29.4545455 9.41666667,29.4545455 L14.125,29.4545455 L14.125,49.0909091 L14.125,58.9090909 L14.125,93.2727273 C14.12526,95.983831 16.2331003,98.1818182 18.8333333,98.1818182 L65.9166667,98.1818182 L65.9166667,88.3636364 L23.5416667,88.3636364 L23.5416667,58.9090909 L65.9166667,58.9090909 L65.9166667,49.0909091 L23.5416667,49.0909091 L23.5416667,29.4545455 L28.25,29.4545455 C33.4527083,29.4545455 37.6666667,25.0609091 37.6666667,19.6363636 L37.6666667,9.81818182 C37.6666667,4.39363636 33.4527083,0 28.25,0 L9.41666667,0 Z M84.75,39.2727273 C79.5472917,39.2727273 75.3333333,43.6663636 75.3333333,49.0909091 L75.3333333,58.9090909 C75.3333333,64.3336364 79.5472917,68.7272727 84.75,68.7272727 L103.583333,68.7272727 C108.786042,68.7272727 113,64.3336364 113,58.9090909 L113,49.0909091 C113,43.6663636 108.786042,39.2727273 103.583333,39.2727273 L84.75,39.2727273 Z M84.75,78.5454545 C79.5472917,78.5454545 75.3333333,82.9390909 75.3333333,88.3636364 L75.3333333,98.1818182 C75.3333333,103.606364 79.5472917,108 84.75,108 L103.583333,108 C108.786042,108 113,103.606364 113,98.1818182 L113,88.3636364 C113,82.9390909 108.786042,78.5454545 103.583333,78.5454545 L84.75,78.5454545 Z"
							id="path-99-2r53ixm-1"></path>
					</defs>
					<g id="Grade-levels-(SP-1201)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="Import" transform="translate(-1934.000000, -342.000000)">
							<g id="Shape-2" transform="translate(1934.000000, 342.000000)">
								<mask id="mask-99-2r53ixm-2" fill="white">
									<use xlink:href="#path-99-2r53ixm-1"></use>
								</mask>
								<use id="Shape" fill="#E2E6EC" fill-rule="nonzero" xlink:href="#path-99-2r53ixm-1"></use>
								<rect id="Rectangle" fill="#1F195E" mask="url(#mask-99-2r53ixm-2)" x="-5" y="57" width="124" height="51"></rect>
							</g>
						</g>
					</g>
				</svg>
				<!-- <svg width="124px" height="96px">
                  <linearGradient id="lg" x1="0.5" y1="1" x2="0.5" y2="0">
                    <stop offset="0%" stop-opacity="1" stop-color="#07ABC3" #fgf1></stop>
                    <stop offset="0%" stop-opacity="1" stop-color="#7f879d"></stop>
                  </linearGradient>
                <path fill="url(#lg)" d="M9.53846154,0 C4.26846154,0 0,4.296 0,9.6 L0,86.4 C0,91.704 4.26846154,96 9.53846154,96 L114.461538,96 C119.731538,96 124,91.704 124,86.4 L124,9.6 C124,4.296 119.731538,0 114.461538,0 L9.53846154,0 Z M100.153846,14.4 C105.423846,14.4 109.692308,18.696 109.692308,24 C109.692308,29.304 105.423846,33.6 100.153846,33.6 C94.8838462,33.6 90.6153846,29.304 90.6153846,24 C90.6153846,18.696 94.8838462,14.4 100.153846,14.4 Z M33.3846154,33.6093744 C36.3379615,33.6093744 39.2910072,34.7444976 41.5444735,37.0125024 L57.1003594,52.6687488 L62,57.6 L67.5144231,63.15 C69.5270385,65.1756 72.7767494,65.1568512 74.7893648,63.1312512 C76.8067494,61.1056512 76.8067494,57.8116512 74.7893648,55.7812512 L69.2935703,50.2593744 L72.9170649,46.6125024 C77.4239928,42.0764976 84.7298534,42.0764976 89.2367812,46.6125024 L109.692308,67.2 L109.692308,81.6 L14.3076923,81.6 L14.3076923,48 L25.2247596,37.0125024 C27.4782212,34.7444976 30.4312692,33.6093744 33.3846154,33.6093744 Z"></path>
              </svg> -->

				<div class="ds-dialog-title" style="margin-top: 15px">{{ picturesLoaderPercent$ | async }}% Uploading Grade Levels...</div>
			</div>

			<ng-template #complete>
				<div class="image-container">
					<div class="syncing-spin-wrapper">
						<img class="spinner" width="43px" height="43px" [src]="'./assets/Sync Loading (Turquoise).svg' | resolveAsset" alt="Sync" />
						<div class="syncing-title">Pictures are being processed. This may take a while…</div>
					</div>
					<div class="syncing-description">You may close this window.<br />Processing will continue in the background.</div>
				</div>
			</ng-template>
		</div>

		<div class="page" *ngIf="page === 4">
			<div class="upload-info" style="padding: 20px">
				<div class="upload-title">Upload complete</div>
				<div class="info">
					<span style="font-weight: bold">New Grade Levels: </span><span>{{ newUploadedIDS }} accounts</span>
				</div>
				<div class="info">
					<span style="font-weight: bold">Uploaded Grade Levels: </span><span>{{ totalUploadedIDS }} accounts</span>
				</div>
				<div class="info">
					<span style="font-weight: bold">Errors: </span
					><span
						>{{ errors.length }} errors
						<a *ngIf="errors.length" download="error.txt" [href]="downloadData()" style="color: #e32c66; cursor: pointer">[Download]</a></span
					>
				</div>
			</div>

			<!-- <div class="uploaded-users">
                <div class="search-input">
                    <app-round-input [isSearch]="true" [placeholder]="'Search accounts'" [focused]="true" [width]="'242px'" [minWidth]="'242px'" (ontextupdate)="searchUsers($event)"></app-round-input>
                </div>

                <div class="user-list" *ngIf="uploadedProfiles.length; else empty">
                    <div class="user" *ngFor="let user of uploadedProfiles">
                        <div class="user-container">
                            <div>
                                <div class="picture" [ngStyle]="{'background': 'url(' + user.profile_picture + ') no-repeat left center/cover'}"></div>
                            </div>
                            <div class="profile-info">
                                <div class="name">{{ user.display_name }}</div>
                                <div class="info" *ngIf="user.student_number"><span style="font-weight: bold">Student Number: </span><span ignore>{{ user.student_number }}</span></div>
                                <div class="info"><span style="font-weight: bold">Image Filename: </span><span ignore>{{ user.file_name }}</span></div>
                            </div>
                        </div>
                        <mat-divider *ngIf="uploadedProfiles.length > 1"></mat-divider>
                    </div>

                </div>
                <ng-template #empty>
                    <div>
                        No students found
                    </div>
                </ng-template>
            </div> -->
		</div>

		<div class="update-page" *ngIf="page === 5">
			<!-- <div class="block" *ngIf="!school.profile_pictures_enabled">
                <div class="upload-info" style="background-color: #e5f9f1; margin-top: 15px">
                    <div class="upload-content">
                        <div class="text">
                            <div class="title">Ready to enable profile pictures?</div>
                            <div class="subtitle">Enable profile pictures so teachers can see them on pass tiles.</div>
                        </div>
                        <div class="button">
                            <app-gradient-button [gradient]="'#01b376, #01b376'" (buttonClick)="switchProfilePictures(true)">Enable</app-gradient-button>
                        </div>
                    </div>
                </div>
            </div> -->
			<div class="block" style="margin-top: 15px">
				<div class="upload-info">
					<div class="update-button">
						<div>
							<div class="title">Add or Update Grade Levels</div>
							<div class="subtitle">Bulk upload Grade Levels</div>
						</div>
						<app-gradient-button
							[rightIconWidth]="'15px'"
							[rightIcon]="'./assets/Chevron Right (White).svg'"
							[gradient]="'#1F195E, #1F195E'"
							(buttonClick)="clearData(); page = 2"
							>New Upload</app-gradient-button
						>
					</div>
					<mat-divider *ngIf="accountMissingGradeLevels$.length > 0"></mat-divider>
					<div class="missing" *ngIf="accountMissingGradeLevels$.length > 0">
						<div style="display: flex; align-items: center">
							<img [src]="'./assets/Warning (Orange).svg'" alt="" />
							<div class="info-title">Missing Grade Levels:</div>
						</div>
						<div class="info">{{ accountMissingGradeLevels$.length }} students don’t have Grade Levels.</div>
						<div class="info-icon">
							<app-icon-button
								(clickEvent)="generateStudentsCsv(accountMissingGradeLevels$)"
								[size]="24"
								[srcString]="'Circle Download'"></app-icon-button>
						</div>
					</div>
				</div>
			</div>
			<!-- <mat-divider></mat-divider>
            <div class="upload-history">
                <div class="ds-dialog-title" style="padding: 20px">Upload History</div>
                <div class="block" *ngFor="let group of uploadedGroups$">
                    <div class="upload-info" style="padding: 20px">
                        <div class="upload-title">{{ getUploadedGroupTime(group.created) }}</div>
                        <div class="info"><span style="font-weight: bold">New Grade Levels: </span><span>{{ group.num_assigned_new }} accounts</span></div>
                        <div class="info"><span style="font-weight: bold">Updated Grade Levels: </span><span>{{ group.num_assigned_update }} accounts</span></div>
                        <div class="info"><span style="font-weight: bold">Errors: </span><span>{{ group.error_count }} errors <span *ngIf="group.error_count" (click)="prepareErrorsToCsv(group)" style="color: #E32C66; cursor: pointer;">[Download]</span></span>
                        </div>
                    </div>
                </div>
            </div> -->
		</div>

		<div class="footer" *ngIf="page !== 4">
			<mat-divider></mat-divider>
			<div class="footer-content">
				<div class="link" style="color: #7f879d">Need some help?</div>
				<div class="link"><a style="color: #7f879d" href="https://www.smartpass.app/integrations/grade-levels" target="_blank">Read Guide</a></div>
				<div class="link"><a style="color: #7f879d; cursor: pointer; text-decoration: underline" target="_blank">Chat with Us</a></div>
			</div>
		</div>
	</div>
</div>
