import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OctagonComponent } from '../admin/accounts/encounter-prevention-dialog/octagon/octagon.component';
import { IdCardFrontComponent } from '../admin/id-cards/id-card-front/id-card-front.component';
import { IdCardComponent } from '../admin/id-cards/id-card/id-card.component';
import { ChattingComponent } from '../chatting/chatting.component';
import { CrossPointerEventTargetDirective } from '../cross-pointer-event-target.directive';
import { ErrorToastComponent } from '../error-toast/error-toast.component';
import { GradientButtonComponent } from '../gradient-button/gradient-button.component';
import { MoovingTilesComponent } from '../mooving-tiles/mooving-tiles.component';
import { PassTileComponent } from '../pass-tile/pass-tile.component';
import { SchoolToggleBarComponent } from '../school-toggle-bar/school-toggle-bar.component';
import { MaterialModule } from '../shared/material/material.module';
import { SignedOutToastComponent } from '../signed-out-toast/signed-out-toast.component';
import { EncounterPreventionTooltipComponent } from '../student-passes/encounter-prevention-tooltip/encounter-prevention-tooltip.component';
import { StudentPassesInfoCardComponent } from '../student-passes/student-passes-info-card/student-passes-info-card.component';
import { StudentPassesComponent } from '../student-passes/student-passes.component';
import { SupportOptionsComponent } from '../support/support-options/support-options.component';
import { ToastConnectionComponent } from '../toast-connection/toast-connection.component';
import { DropdownSelectionComponent } from './components/dropdown-selection/dropdown-selection.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TimerSpinnerComponent } from './components/timer-spinner/timer-spinner.component';
import { CheckUserInputDirective } from './directives/check-user-input.directive';
import { NuxTooltipRendererDirective } from './directives/nux-tooltip-renderer.directive';
import { SwipeEventDirective } from './directives/swipe-event.directive';
import { ToolTipRendererDirective } from './directives/tool-tip-renderer.directive';
import { SpEmailPipe } from './pipes/sp-email.pipe';
import { PositionPipe } from './position.pipe';
import { BackpackModule } from '../backpack/backpack.module';
import { RestrictInputDirective } from './directives/restrict-input.directive';

@NgModule({
	declarations: [
		ErrorToastComponent,
		SchoolToggleBarComponent,
		ToastConnectionComponent,
		SignedOutToastComponent,
		GradientButtonComponent,
		CrossPointerEventTargetDirective,
		MoovingTilesComponent,
		CheckUserInputDirective,
		SupportOptionsComponent,
		ToolTipRendererDirective,
		SwipeEventDirective,
		PassTileComponent,
		StudentPassesComponent,
		StudentPassesInfoCardComponent,
		OctagonComponent,
		EncounterPreventionTooltipComponent,
		NuxTooltipRendererDirective,
		SpEmailPipe,
		IdCardComponent,
		IdCardFrontComponent,
		DropdownSelectionComponent,
		TimerSpinnerComponent,
		PositionPipe,
		ChattingComponent,
		LoaderComponent,
		RestrictInputDirective,
	],
	imports: [CommonModule, MaterialModule, FormsModule, BackpackModule],
	exports: [
		MaterialModule,
		ErrorToastComponent,
		SchoolToggleBarComponent,
		ToastConnectionComponent,
		CrossPointerEventTargetDirective,
		SignedOutToastComponent,
		GradientButtonComponent,
		MoovingTilesComponent,
		ToolTipRendererDirective,
		CheckUserInputDirective,
		SwipeEventDirective,
		PassTileComponent,
		StudentPassesComponent,
		StudentPassesInfoCardComponent,
		OctagonComponent,
		EncounterPreventionTooltipComponent,
		NuxTooltipRendererDirective,
		IdCardComponent,
		IdCardFrontComponent,
		DropdownSelectionComponent,
		TimerSpinnerComponent,
		PositionPipe,
		ChattingComponent,
		LoaderComponent,
		RestrictInputDirective,
	],
})
export class CoreModule {}
