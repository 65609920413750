<div class="wrapper">
	<form class="time" [formGroup]="timeForm">
		<div class="hours" (mouseenter)="hourHovered = true" (mouseleave)="hourHovered = false" [style.width]="hourInputWidth">
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="hourUp = false"
				(pointerDownEvent)="hourUp = true"
				(pointerCancelEvent)="hourUp = false"
				(mouseleave)="hourUp = false"
				[@pressState]="hourUp ? 'down' : 'up'"
				[style.opacity]="hourHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'hour', 'up')"
				[src]="'./assets/chevron-collapse-up.svg' | resolveAsset"
				alt="Icon" />
			<input
				#hourInput
				[tabindex]="-1"
				class="timeInput"
				[ngClass]="{ active: anyInputFocused, disabled: isDisabled, typing: typing }"
				[style.width]="hourInputWidth"
				type="text"
				maxlength="2"
				formControlName="hour"
				[attr.disabled]="isDisabled ? true : null"
				(focus)="onInputFocus('hour')"
				(click)="handleInputClick($event)"
				(mouseup)="handleInputClick($event)"
				(input)="onInput(hourInput, minutesInput)"
				(blur)="updateHour()"
				(keydown)="numericOnly($event, 'hour', null, minutesInput)" />
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="hourDown = false"
				(pointerDownEvent)="hourDown = true"
				(pointerCancelEvent)="hourDown = false"
				(mouseleave)="hourDown = false"
				[@pressState]="hourDown ? 'down' : 'up'"
				[style.opacity]="hourHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'hour', 'down')"
				[src]="'./assets/chevron-collapse.svg' | resolveAsset"
				alt="Icon" />
		</div>
		<div class="separator">:</div>
		<div class="minutes" (mouseenter)="minHovered = true" (mouseleave)="minHovered = false">
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="minUp = false"
				(pointerDownEvent)="minUp = true"
				(pointerCancelEvent)="minUp = false"
				(mouseleave)="minUp = false"
				[@pressState]="minUp ? 'down' : 'up'"
				[style.opacity]="minHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'minutes', 'up')"
				[src]="'./assets/chevron-collapse-up.svg' | resolveAsset"
				alt="Icon" />
			<input
				#minutesInput
				[tabindex]="timeForm.touched ? 1 : -1"
				class="timeInput"
				[ngClass]="{ active: anyInputFocused, disabled: isDisabled, typing: typing }"
				maxlength="2"
				type="text"
				formControlName="minutes"
				[attr.disabled]="isDisabled ? true : null"
				(focus)="onInputFocus('minutes')"
				(click)="handleInputClick($event)"
				(mouseup)="handleInputClick($event)"
				(input)="onInput(minutesInput, periodInput)"
				(blur)="updateMinute()"
				(keydown)="numericOnly($event, 'minutes', hourInput, periodInput)" />
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="minDown = false"
				(pointerDownEvent)="minDown = true"
				(pointerCancelEvent)="minDown = false"
				(mouseleave)="minDown = false"
				[@pressState]="minDown ? 'down' : 'up'"
				[style.opacity]="minHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'minutes', 'down')"
				[src]="'./assets/chevron-collapse.svg' | resolveAsset"
				alt="Icon" />
		</div>
		<div class="period" (mouseenter)="periodHovered = true" (mouseleave)="periodHovered = false">
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="periodUp = false"
				(pointerDownEvent)="periodUp = true"
				(pointerCancelEvent)="periodUp = false"
				(mouseleave)="periodUp = false"
				[@pressState]="periodUp ? 'down' : 'up'"
				[style.opacity]="periodHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'period', 'up')"
				[src]="'./assets/chevron-collapse-up.svg' | resolveAsset"
				alt="Icon" />
			<input
				#periodInput
				[tabindex]="timeForm.touched ? 1 : -1"
				class="timeInput"
				[ngClass]="{ active: anyInputFocused, disabled: isDisabled, typing: typing }"
				maxlength="2"
				type="text"
				formControlName="period"
				[attr.disabled]="isDisabled ? true : null"
				(focus)="onInputFocus('period')"
				(click)="handleInputClick($event)"
				(mouseup)="handleInputClick($event)"
				(input)="onInput(periodInput)"
				(blur)="updatePeriod()"
				(keydown)="amPmOnly($event, 'period', minutesInput)" />
			<img
				class="arrow"
				appCrossPointerEventTarget
				(pointerClickEvent)="periodDown = false"
				(pointerDownEvent)="periodDown = true"
				(pointerCancelEvent)="periodDown = false"
				(mouseleave)="periodDown = false"
				[@pressState]="periodDown ? 'down' : 'up'"
				[style.opacity]="periodHovered && !isDisabled ? 1 : 0"
				(click)="enqueueArrowClick($event, 'period', 'down')"
				[src]="'./assets/chevron-collapse.svg' | resolveAsset"
				alt="Icon" />
		</div>
	</form>
</div>
