import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PeriodAgendaItem, ScheduleGroupIndexChange } from '../../../services/schedule.service';
import { Router } from '@angular/router';
import { ScheduleGrouping } from '../../../models/Schedule';

@Component({
	selector: 'app-schedule-tooltip',
	templateUrl: './schedule-tooltip.component.html',
	styleUrls: ['./schedule-tooltip.component.scss'],
})
export class ScheduleTooltipComponent {
	@Input() scheduleGroups: ScheduleGrouping[];
	@Input() isRotating: boolean;
	@Input() multipleDayTypes: boolean;
	@Input() periodTimes: PeriodAgendaItem[];
	@Input() currentPeriod: PeriodAgendaItem;
	@Input() noActivePeriod = false;
	@Input() nextPeriod: PeriodAgendaItem;
	@Input() tooltipType = 'staff';
	@Input() close: () => void;
	@Input() isStaff: boolean;
	@Input() isAdmin: boolean;

	@Input() background = '#101418';
	@Input() width = 'auto';
	@Input() height = 'auto';
	@Input() borderRadius = '10px';
	@Input() padding = '16px 12px 16px 12px';
	@Input() selectedTabIndex = 0;
	@Input() dayType: string;

	@Output() scheduleGroupChanged: EventEmitter<ScheduleGroupIndexChange> = new EventEmitter<ScheduleGroupIndexChange>();

	constructor(private router: Router) {}

	adminScheduleRoute() {
		this.router.navigateByUrl('/admin/schedules');
	}
	onSelectScheduleGroup(selectedIndex: number): void {
		this.selectedTabIndex = selectedIndex;
		this.scheduleGroupChanged.emit({
			manualTrigger: true,
			index: selectedIndex,
		});
	}
}
