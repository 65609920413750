import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Util } from '../../Util';
import { scalePassCards } from '../animations';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { CreateFormService } from '../create-hallpass-forms/create-form.service';
import { CreateHallpassFormsComponent } from '../create-hallpass-forms/create-hallpass-forms.component';
import { States } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { MainHallPassFormComponent, Navigation } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { DeviceDetection } from '../device-detection.helper';
import { NavbarDataService } from '../main/navbar-data.service';
import { Invitation } from '../models/Invitation';
import { Location } from '../models/Location';
import { School } from '../models/School';
import { User } from '../models/User';
import { DataService } from '../services/data-service';
import { DomCheckerService } from '../services/dom-checker.service';
import { FeatureFlagService } from '../services/feature-flag.service';
import { HallPassesService, PASS_CARD_FOOTER_HEIGHT, STUDENT_INFO_FOOTER_HEIGHT } from '../services/hall-passes.service';
import { HttpService } from '../services/http-service';
import { LocationsService } from '../services/locations.service';
import { RequestsService } from '../services/requests.service';
import { ToastService } from '../services/toast.service';
import { UserService } from '../services/user.service';

@Component({
	selector: 'app-invitation-card',
	templateUrl: './invitation-card.component.html',
	styleUrls: ['./invitation-card.component.scss'],
	animations: [scalePassCards],
})
export class InvitationCardComponent implements OnInit, OnDestroy, AfterViewInit {
	@HostListener('window:resize', ['$event.target'])
	onResize(event) {
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (this.forStaff && this.isModal) {
				footerHeight = STUDENT_INFO_FOOTER_HEIGHT;
			} else if (this.forStaff && !this.isModal) {
				footerHeight = PASS_CARD_FOOTER_HEIGHT;
			}
			this.passesService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
			this.cdr.detectChanges();
		}
	}

	@Input() invitation: Invitation;
	@Input() forFuture = false;
	@Input() fromPast = false;
	@Input() forStaff = false;
	@Input() forInput = false;
	@Input() formState: Navigation;
	@Input() selectedStudents: User[] = [];
	@Input() inFormContainer = false;

	@Output() cardEvent: EventEmitter<Navigation> = new EventEmitter();

	private selectedOrigin: Location;
	private denyOpen = false;
	selectedDuration: number;
	private selectedTravelType = 'one_way';
	private user: User;
	performingAction: boolean;
	private fromHistory;
	private fromHistoryIndex;
	private dateEditOpen: boolean;
	private setOriginDialogOpen: boolean;
	private showCloseIcon: boolean;

	frameMotion$: BehaviorSubject<any>;
	scaleCardTrigger$: Observable<string>;

	private isEnableProfilePictures$: Observable<boolean>;

	isModal: boolean;
	issuerName = '';
	studentName = '';
	invalidDate = false;
	isMissedInvitation = false;
	private header: string;
	private options: any = [];
	private currentSchool: School;

	backgroundGradient = 'linear-gradient(0deg, #FFF, #FFF),linear-gradient(0deg, #FFF, #FFF)';
	buttonBackgroundGradient: string;
	footerBackground = '';
	passDuration: number;
	studentText: string;

	private destroy$: Subject<any> = new Subject<any>();

	constructor(
		public dialogRef: MatDialogRef<InvitationCardComponent | MainHallPassFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private viewContainerRef: ViewContainerRef,
		public dialog: MatDialog,
		private requestService: RequestsService,
		public dataService: DataService,
		private createFormService: CreateFormService,
		private http: HttpService,
		private navbarData: NavbarDataService,
		private domCheckerService: DomCheckerService,
		private userService: UserService,
		private passesService: HallPassesService,
		private cdr: ChangeDetectorRef,
		private locationsService: LocationsService,
		private toast: ToastService,
		private featureFlagService: FeatureFlagService,
		private router: Router
	) {}

	get isMobile() {
		return DeviceDetection.isMobile();
	}

	get studentEmail() {
		return this.invitation.student.primary_email.split('@', 1)[0];
	}

	get durationPlural() {
		return this.selectedStudents && this.selectedStudents.length > 1;
	}

	ngOnInit(): void {
		this.scaleCardTrigger$ = this.domCheckerService.scalePassCard;
		this.frameMotion$ = this.createFormService.getFrameMotionDirection();
		this.currentSchool = this.http.getSchool();

		this.userService.userJSON$
			.pipe(
				tap((user) => {
					this.user = user;
					if (this.data['forFuture']) {
						this.forFuture = this.data['forFuture'];
					}
					if (this.data['fromPast']) {
						this.fromPast = this.data['fromPast'];
					}
					if (this.data['forStaff']) {
						this.forStaff = this.data['forStaff'];
					}
					if (this.data['forInput']) {
						this.forInput = this.data['forInput'];
					}
					if (this.data['fromHistory']) {
						this.fromHistory = this.data['fromHistory'];
					}
					if (this.data['fromHistoryIndex']) {
						this.fromHistoryIndex = this.data['fromHistoryIndex'];
					}
					if (this.data['selectedStudents']) {
						this.selectedStudents = this.data['selectedStudents'];
					} else if (this.inFormContainer && this.data?.adminSelectedStudent) {
						this.selectedStudents = [this.data?.adminSelectedStudent];
					}
					if (this.data['pass']) {
						this.isModal = true;
						this.populateInvitationData(this.data['pass']);
					} else if (this.invitation) {
						this.populateInvitationData(this.invitation);
					}
				}),
				switchMap(() => {
					return merge(this.requestService.watchInvitationCancel(this.invitation.id), this.requestService.watchInvitationAccept(this.invitation.id));
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((invitation) => {
				if (invitation instanceof Invitation) {
					this.populateInvitationData(invitation as Invitation);
				}
				this.dialogRef.close();
			});

		this.isEnableProfilePictures$ = this.userService.isEnableProfilePictures$;

		this.locationsService
			.listenLocationSocket()
			.pipe(
				takeUntil(this.destroy$),
				filter((res) => !!res),
				tap((res) => {
					try {
						const loc: Location = Location.fromJSON(res.data);
						this.locationsService.updateLocationSuccessState(loc);
						this.invitation.destination.title = loc.title;
					} catch (e) {
						console.log(e);
					}
				})
			)
			.subscribe();

		this.dialogRef.afterOpened().subscribe(() => {
			this.showCloseIcon = this.isModal;
		});

		this.dialogRef.backdropClick().subscribe(() => {
			this.showCloseIcon = false;
		});
	}

	ngAfterViewInit(): void {
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (this.forStaff && this.isModal) {
				footerHeight = STUDENT_INFO_FOOTER_HEIGHT;
			} else if (this.forStaff && !this.isModal) {
				footerHeight = PASS_CARD_FOOTER_HEIGHT;
			}
			this.passesService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
			this.cdr.detectChanges();
		}
	}

	private populateInvitationData(invitation: Invitation): void {
		this.invitation = invitation;
		if (this.inFormContainer && this.data?.adminSelectedStudent && !this.invitation.student) {
			this.invitation.student = this.data.adminSelectedStudent;
		}

		this.passDuration = Math.floor(this.invitation.duration / 60);
		this.selectedOrigin = this.invitation.default_origin;
		this.invalidDate = Util.invalidDate(this.invitation.date_choices[0]);
		this.isMissedInvitation = !this.forInput && this.invalidDate;
		if (this.isMissedInvitation || this.invitation.status === 'declined') {
			this.backgroundGradient = 'linear-gradient(0deg, #F0F2F5, #F0F2F5),linear-gradient(0deg, #E2E6EC, #E2E6EC)';
		}
		if (this.invitation.student) {
			const student = User.fromJSON(this.invitation.student);
			this.studentName = student.abbreviatedName(!this.userService.getFeatureFlagNewAbbreviation());
		}
		if (this.invitation.issuer) {
			const issuer = User.fromJSON(this.invitation.issuer);
			this.issuerName = issuer.isSameObject(this.user) ? 'Me' : this.invitation.issuer.abbreviatedName();
		}

		this.setButtonBackgroundColor();
		this.setFooterBackgroundColor();

		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	formatDateTime(date: Date): string {
		return Util.formatDateTime(date);
	}

	openSetOriginDialog(): void {
		if (this.setOriginDialogOpen) {
			return;
		}
		this.setOriginDialogOpen = true;

		const config = {
			panelClass: 'form-dialog-container',
			maxWidth: '100vw',
			backdropClass: 'invis-backdrop',
			data: {
				forInput: false,
				forLater: true,
				hasClose: true,
				entryState: { step: 3, state: States.FromToWhere },
				originalToLocation: this.invitation.destination,
				lockDestinationLocation: true,
				colorProfile: this.invitation.color_profile,
				originalFromLocation: this.invitation['default_origin'],
			},
		};
		if (this.forFuture && !this.forStaff) {
			config.data['date'] = {
				date: this.invitation.date_choices[0],
				declinable: true,
			};
		}
		const locationDialog = this.dialog.open(CreateHallpassFormsComponent, config);
		locationDialog
			.afterClosed()
			.pipe(
				filter((res) => !!res),
				finalize(() => (this.setOriginDialogOpen = false))
			)
			.subscribe((data) => {
				this.setLocation(data.data && data.data['fromLocation'] ? data.data['fromLocation'] : this.invitation['default_origin']);
				this.acceptInvitation();
			});
	}

	private setButtonBackgroundColor(): void {
		let gradientArray = [];
		if (this.invitation) {
			gradientArray = this.invitation.color_profile.gradient_color.split(',');
			if (this.invitation.status === 'pending' || this.invitation.status === 'accepted') {
				this.buttonBackgroundGradient =
					'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ' 0%, ' + gradientArray[1] + ' 100%)';
			}
		}
	}

	private setFooterBackgroundColor(): void {
		let gradientArray = [];
		gradientArray = this.invitation.color_profile.gradient_color.split(',');
		if (this.invitation.status === 'pending' && this.forStaff && !this.isMissedInvitation && !this.inFormContainer) {
			this.footerBackground = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ' 0%, ' + gradientArray[1] + ' 100%)';
		} else if (this.invitation.status === 'declined') {
			this.footerBackground = '#E2E6EC';
		} else if (this.invitation.status === 'accepted') {
			this.backgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ' 0%, ' + gradientArray[1] + ' 100%)';
			this.footerBackground = `${this.invitation.color_profile.solid_color}33`;
		}
	}

	setLocation(location: Location): void {
		this.invitation.default_origin = location;
		this.selectedOrigin = location;
	}

	newInvitation(): void {
		this.performingAction = true;
		const body = {
			students: this.selectedStudents.map((user) => user.id),
			default_origin: this.invitation.default_origin ? this.invitation.default_origin.id : null,
			destination: this.invitation.destination.id,
			date_choices: this.invitation.date_choices.map((date) => date.toISOString()),
			duration: this.selectedDuration * 60,
			travel_type: this.selectedTravelType,
			issuer_message: this.invitation.issuer_message,
		};

		this.requestService
			.createInvitation(body)
			.pipe(
				catchError((error) => {
					this.openErrorToast(error);
					return of(error);
				})
			)
			.subscribe((data) => {
				if (DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile()) {
					this.dataService.openRequestPageMobile();
				}
				this.dialogRef.close();
				this.passesService.createPassEvent$.next();
			});
	}

	acceptInvitation(): void {
		this.performingAction = true;
		const body = {
			start_time: this.invitation.date_choices[0].toISOString(),
			origin: this.selectedOrigin.id,
		};

		this.requestService
			.acceptInvitation(this.invitation.id, body)
			.pipe(
				catchError((error) => {
					this.openErrorToast(error);
					return of(error);
				})
			)
			.subscribe((data: any) => {
				this.dialogRef.close();
				const dateS = moment(this.invitation.date_choices[0]).format('YYYY-MM-DD');
				this.router
					.navigate(['main/passes-calendar'], {
						queryParams: {
							day_view_date: dateS,
							start_date: dateS,
						},
					})
					.then();
			});
	}

	changeDate(resend_request?: boolean): void {
		if (!this.dateEditOpen) {
			this.dateEditOpen = true;
			this.dialogRef.close();
			const conf = {
				panelClass: 'form-dialog-container',
				maxWidth: '100vw',
				backdropClass: 'custom-backdrop',
				data: {
					entryState: {
						step: 1,
						state: States.FromToWhere,
					},
					forInput: false,
					missedRequest: !this.forStaff,
					originalToLocation: this.invitation.destination,
					lockDestinationLocation: true,
					colorProfile: this.invitation.color_profile,
					request: this.invitation,
					request_time: resend_request || this.invalidDate ? new Date() : this.invitation.date_choices[0],
					resend_request: resend_request,
				},
			};

			const dateDialog = this.dialog.open(CreateHallpassFormsComponent, conf);

			dateDialog
				.afterClosed()
				.pipe(
					tap(() => (this.dateEditOpen = false)),
					filter((res) => res?.data?.date && resend_request && this.forStaff),
					switchMap((state) => {
						const body = {
							students: this.invitation.student.id,
							default_origin: this.invitation.default_origin ? this.invitation.default_origin.id : null,
							destination: +this.invitation.destination.id,
							date_choices: [new Date(state.data.date.date).toISOString()],
							duration: this.invitation.duration,
							travel_type: this.invitation.travel_type,
						};
						return this.requestService.createInvitation(body);
					}),
					switchMap(() => this.requestService.cancelInvitation(this.invitation.id, '')),
					catchError((error) => {
						this.openErrorToast(error);
						return of(error);
					})
				)
				.subscribe(console.log);
		}
	}

	changeDateForStudentMissedInvitation(): void {
		if (!this.dateEditOpen) {
			this.dateEditOpen = true;
			this.dialogRef.close();
			const conf = {
				panelClass: 'form-dialog-container',
				maxWidth: '100vw',
				backdropClass: 'custom-backdrop',
				data: {
					entryState: {
						step: 1,
						state: States.FromToWhere,
					},
					forInput: false,
					forLater: true,
					// Hmmm, I'm not sure why we're setting |missedRequest| because this is called when a
					// student misses an invitation... something is broken here!
					missedRequest: !this.forStaff,
					originalToLocation: this.invitation.destination,
					lockDestinationLocation: true,
					colorProfile: this.invitation.color_profile,
					request: this.invitation,
					request_time: this.invalidDate ? new Date() : this.invitation.date_choices[0],
				},
			};

			const dateDialog = this.dialog.open(CreateHallpassFormsComponent, conf);

			dateDialog
				.afterClosed()
				.pipe(
					tap(() => {
						this.dialogRef.close();
					}),
					catchError((error) => {
						this.openErrorToast(error);
						return of(error);
					})
				)
				.subscribe(console.log);
		}
	}

	private openErrorToast(error): void {
		this.toast.openToast(
			{
				title: 'Oh no! Something went wrong',
				subtitle: `Please try refreshing the page. If the issue keeps occurring, contact us at support@smartpass.app. (${error.status})`,
				type: 'error',
			},
			`${error.status}`
		);
	}

	denyInvitationByStudent(): void {
		const body = {
			message: '',
		};
		this.requestService.denyInvitation(this.invitation.id, body).subscribe((httpData) => {
			this.dialogRef.close();
		});
	}

	denyInvitation(evt: MouseEvent) {
		// if (this.screenService.isDeviceMid) {
		//   this.cancelEditClick = !this.cancelEditClick;
		// }
		if (!this.denyOpen) {
			const target = new ElementRef(evt.currentTarget);
			this.options = [];
			this.header = '';
			if (this.forInput) {
				this.formState.step = 3;
				this.formState.previousStep = 4;
				this.createFormService.setFrameMotionDirection('disable');
				this.cardEvent.emit(this.formState);
				return false;
			} else if (!this.forStaff) {
				this.options.push(this.genOption('Delete Pass', '#7083A0', 'decline'));
			} else {
				this.options.push(this.genOption('Delete Pass', '#7083A0', 'delete'));
			}

			// if (!this.screenService.isDeviceMid) {
			UNANIMATED_CONTAINER.next(true);
			this.denyOpen = true;
			const consentDialog = this.dialog.open(ConsentMenuComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'invis-backdrop',
				data: { header: this.header, options: this.options, trigger: target, adjustForScroll: true },
			});

			consentDialog
				.afterClosed()
				.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
				.subscribe((action) => {
					this.chooseAction(action);
				});
			// }
		}
	}

	private chooseAction(action): void {
		this.denyOpen = false;
		if (action === 'cancel') {
			this.dialogRef.close();
		} else if (action === 'decline') {
			const body = {
				message: '',
			};
			this.requestService.denyInvitation(this.invitation.id, body).subscribe((httpData) => {
				this.dialogRef.close();
			});
		} else if (action === 'delete') {
			const body = {
				message: '',
			};
			this.requestService.cancelInvitation(this.invitation.id, body).subscribe((httpData) => {
				this.dialogRef.close();
			});
		} else if (action === 'resend') {
			this.changeDate(true);
		}
	}

	private genOption(
		display,
		color,
		action,
		icon?,
		hoverBackground?,
		clickBackground?
	): { display; color; action; icon; hoverBackground; clickBackground } {
		return { display, color, action, icon, hoverBackground, clickBackground };
	}
}
