<div class="travel-view-wrapper" [style.color]="textColor">
	<div class="room-name">
		{{ originRoomName }}
	</div>
	<div [style.opacity]="'0.2'">
		<svg xmlns="http://www.w3.org/2000/svg" width="126" height="132" viewBox="0 0 126 132" [attr.fill]="iconColor">
			<path
				d="M70.1581 126.847L110.996 86.0651L111.098 86.1668L121.234 76.045C126.662 70.6242 126.638 61.8108 121.179 56.3597L69.9015 5.15303C64.3576 -0.383286 55.3941 -0.408296 49.881 5.09716C44.3679 10.6026 44.393 19.5537 49.9369 25.0901L91.0317 66.1281L50.1935 106.91C44.6495 112.446 44.6245 121.397 50.1375 126.903C55.6506 132.408 64.6141 132.383 70.1581 126.847ZM24.2061 107.82L44.6268 87.4275L44.6683 87.469L54.7255 77.4257C60.1116 72.047 60.0627 63.2776 54.6163 57.8386L24.1473 27.4117C18.6157 21.8878 9.69697 21.8382 4.22669 27.3009C-1.24359 32.7636 -1.19393 41.6701 4.33762 47.194L24.8171 67.6452L4.39649 88.0377C-1.13506 93.5616 -1.18473 102.468 4.28555 107.931C9.75584 113.393 18.6746 113.344 24.2061 107.82Z" />
		</svg>
	</div>
	<div>
		{{ destinationRoomName }}
	</div>
</div>
