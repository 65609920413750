import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { User } from '../../models/User';
import { SelectOption } from './starlight-select.component';
import { SchoolActivityInstance } from '../../services/school-activity.service';
import { AttendanceRecord, AttendanceRecordState } from '../../models/AttendanceRecord';
import { memo } from '../../helpers/helper';
import * as moment from 'moment';

@Component({
	selector: 'sp-attendee-student',
	template: `
		<div class="ta-student-item">
			<img [src]="student.profile_picture ? student.profile_picture : './assets/Default Avatar.png'" alt="profile picture" />
			<span>{{ student.display_name }}</span>
			<div *ngIf="showAttendance" class="attendance-container">
				<span class="late-time" *ngIf="attendanceRecord?.state === stateEnum.LateNotExcused">{{ formatLateTime(attendanceRecord?.updated_at) }}</span>
				<sp-starlight-select
					[options]="selectOptions"
					[selected]="selectedOption"
					[placeholder]="placeholder"
					(onSelect)="selectOption($event)"></sp-starlight-select>
			</div>
			<div *ngIf="!showAttendance" class="check" (click)="onCheckClicked.emit()"></div>
		</div>
	`,
	styleUrls: ['./attendee-student.component.scss'],
})
export class AttendeeStudentComponent {
	@Input()
	student: User;

	@Input()
	attendanceRecord?: AttendanceRecord | undefined;

	@Input()
	showAttendance: boolean;

	@Input()
	activityInstance: SchoolActivityInstance;

	@Output()
	onCheckClicked = new EventEmitter<void>();

	@Output()
	upsertAttendanceRecord = new EventEmitter<{ state: string; student: User; attendanceRecord: AttendanceRecord | undefined }>();

	stateEnum = AttendanceRecordState;
	selectedOption: SelectOption<string>;
	placeholder: SelectOption<void> = { display: 'Select', textColor: '#7083A0', backgroundColor: '#F0F2F5', value: void 0 };
	selectOptions: SelectOption<string>[] = [
		{
			display: 'Present',
			textColor: '#00B476',
			backgroundColor: '#E5F7F1',
			value: this.stateEnum.Present,
		},
		{
			display: 'Absent',
			textColor: '#E32C66',
			backgroundColor: '#FCE9EF',
			value: this.stateEnum.Absent,
		},
		{
			display: 'Late, Excused',
			textColor: '#72CC1B',
			backgroundColor: '#F0F9E8',
			value: this.stateEnum.LateExcused,
		},
		{
			display: 'Late, Unexcused',
			textColor: '#E48C15',
			backgroundColor: '#FCF3E7',
			value: this.stateEnum.LateNotExcused,
		},
	];

	ngOnChanges(changes: SimpleChanges) {
		if (changes.attendanceRecord && !!changes.attendanceRecord.currentValue) {
			this.setSelectedOption(changes.attendanceRecord.currentValue);
		}
	}

	setSelectedOption(a: AttendanceRecord) {
		this.selectedOption = this.selectOptions.find((o) => o.value == a.state);
	}

	selectOption(o: SelectOption<string>) {
		this.selectedOption = o;
		this.upsertAttendanceRecord.emit({ state: o.value, student: this.student, attendanceRecord: this.attendanceRecord });
	}

	formatLateTime = memo((time: Date) => {
		return moment(time).format('h:mm a');
	});
}
