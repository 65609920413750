import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { bumpIn200 } from '../../../animations';

type action = 'hour' | 'minutes' | 'period';
type direction = 'up' | 'down';

enum Period {
	am = 'AM',
	pm = 'PM',
}

interface hhMmPp {
	hour: string;
	minutes: string;
	period: string;
}

@Component({
	selector: 'sp-time-input',
	templateUrl: './time-input.component.html',
	styleUrls: ['../time-range-input/time-range-input.component.scss'],
	animations: [bumpIn200],
})
export class TimeInputComponent implements OnInit, OnDestroy {
	@Input() initTime = '';
	@Input() erroring = false;
	@Input() isDisabled = false;

	@Output() timeResult = new EventEmitter<string>();
	@Output() anyInputFocusedChanged = new EventEmitter<boolean>();
	@Output() timeFormValueChanges = new EventEmitter<string | null>();
	@Output() switchToStartInput = new EventEmitter<boolean>();
	@Output() switchToEndInput = new EventEmitter<boolean>();

	@ViewChild('hourInput') hourInput: ElementRef<HTMLInputElement>;
	@ViewChild('minutesInput') minutesInput: ElementRef<HTMLInputElement>;
	@ViewChild('periodInput') periodInput: ElementRef<HTMLInputElement>;

	timeForm: FormGroup;
	hourHovered = false;
	minHovered = false;
	periodHovered = false;
	anyInputFocused = false;
	hourInputWidth = '1ch';
	typing = false;
	hourUp = false;
	hourDown = false;
	minUp = false;
	minDown = false;
	periodUp = false;
	periodDown = false;
	private destroy$ = new Subject<void>();
	private timeArrowChanges$ = new Subject<{ action: action; direction: direction }>();
	private blockClick = false;
	private debounceMs = 100;

	ngOnInit() {
		const { hour, minutes, period } = this.parseTime(this.initTime);
		this.timeForm = new FormGroup({
			hour: new FormControl(hour),
			minutes: new FormControl(minutes),
			period: new FormControl(period),
		});
		this.timeForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values: hhMmPp) => {
			if (!this.anyInputFocused) {
				this.emitFormChanges(values);
			}
		});

		this.timeArrowChanges$
			.pipe(
				debounceTime(this.debounceMs),
				takeUntil(this.destroy$),
				tap((input) => this.changeTime(input.action, input.direction))
			)
			.subscribe();

		this.setHourWidth();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setInput(time: string): void {
		const { hour, minutes, period } = this.parseTime(time ? time : this.initTime);
		// Parent updating value
		this.timeForm.setValue({ hour: hour, minutes: minutes, period: period });
		this.setHourWidth();
	}

	private emitFormChanges({ hour, minutes, period }: hhMmPp): void {
		const timeStr = this.buildTime(hour, minutes, period);
		this.timeFormValueChanges.emit(timeStr);
	}

	private buildTime(hourStr: string, minutesStr: string, period: string): string | null {
		// Incomplete time
		if (hourStr === '' && minutesStr === '' && period === '') {
			return '';
		}

		let hour = parseInt(hourStr, 10);
		const minutes = parseInt(minutesStr, 10);

		// Invalid time
		if (
			Number.isNaN(hour) ||
			Number.isNaN(minutes) ||
			hour < 1 ||
			hour > 12 ||
			minutes < 0 ||
			minutes > 59 ||
			(period !== Period.pm && period !== Period.am)
		) {
			return null;
		}

		if (period === Period.pm && hour < 12) {
			hour += 12;
		} else if (period === Period.am && hour === 12) {
			hour = 0;
		}

		// Format the result
		const hoursFormatted = hour.toString().padStart(2, '0');
		const minutesFormatted = minutes.toString().padStart(2, '0');

		return `${hoursFormatted}:${minutesFormatted}`;
	}

	private parseTime(timeString: string): hhMmPp {
		// Define the return object for invalid input
		const invalidTimeObject = { hour: '', minutes: '', period: '' };

		// Check if the input string is formatted correctly (HH:mm)
		const timeFormatRegex = /^\d{2}:\d{2}$/;
		if (!timeFormatRegex.test(timeString)) {
			return invalidTimeObject;
		}

		// Split the input string into hours and minutes
		const [hoursStr, minutesStr] = timeString.split(':');
		const hours = parseInt(hoursStr, 10);
		const minutes = parseInt(minutesStr, 10);

		// Validate if the time provided is valid
		if (hours < 0 || hours >= 24 || minutes < 0 || minutes >= 60) {
			return invalidTimeObject;
		}

		// Determine the period (am/pm)
		const period = hours < 12 ? Period.am : Period.pm;

		// Convert the hours to 12-hour format
		const hour12 = hours > 12 ? (hours - 12).toString() : hours === 0 ? '12' : hours < 10 ? hoursStr.slice(1) : hours.toString();

		return {
			hour: hour12,
			minutes: minutesStr,
			period: period,
		};
	}

	onInputFocus(action: action): void {
		this.selectInputElement(action);
		this.setAnyInputFocused(true);
		this.blockClick = true;

		setTimeout(() => {
			this.blockClick = false;
		}, 2 * this.debounceMs);
	}

	private setAnyInputFocused(value: boolean): void {
		this.anyInputFocused = value;
		this.anyInputFocusedChanged.emit(value);
	}

	onInput(currentInput: HTMLInputElement, nextInput?: HTMLInputElement): void {
		const isHour = currentInput.getAttribute('formcontrolname') === 'hour';
		const hourSpecificValues = ['2', '3', '4', '5', '6', '7', '8', '9'];

		if (isHour) {
			this.setHourWidth();
		}

		const shouldHopRight = currentInput.value.length === 2 || (isHour && hourSpecificValues.includes(currentInput.value));
		if (shouldHopRight) {
			this.handleHopRight(nextInput);
		}
	}

	focusOnHour(setCursorAtStart = false): void {
		this.hourInput.nativeElement.focus();
		if (setCursorAtStart) {
			this.hourInput.nativeElement.setSelectionRange(0, 0);
		}
	}

	focusOnPeriod(setCursorAtEnd = false): void {
		this.periodInput.nativeElement.focus();
		if (setCursorAtEnd) {
			const inputLen = this.periodInput.nativeElement.value.length;
			this.periodInput.nativeElement.setSelectionRange(inputLen, inputLen);
		}
	}

	updateHour(): void {
		this.updateAnyField('hour');

		const parsedHour = parseInt(this.timeForm?.value?.hour, 10);
		if (Number.isNaN(parsedHour) || parsedHour < 1) {
			return this.setHourInput('');
		}

		this.setHourInput(`${parsedHour}`);
	}

	updateMinute(): void {
		this.updateAnyField('minutes');

		const parsedMinutes = parseInt(this.timeForm?.value?.minutes, 10);
		if (Number.isNaN(parsedMinutes) || parsedMinutes < 0) {
			return this.timeForm.get('minutes').setValue('');
		}

		if (parsedMinutes < 10) {
			return this.timeForm.get('minutes').setValue(`0${parsedMinutes}`);
		}

		this.timeForm.get('minutes').setValue(`${parsedMinutes}`);
	}

	updatePeriod(): void {
		this.updateAnyField('period');

		let input = this.timeForm?.value?.period;
		if (input && typeof input === 'string') {
			input = input.toUpperCase();
			if (input === Period.am) {
				this.timeForm.get('period').setValue(Period.am);
			}
			if (input === Period.pm) {
				this.timeForm.get('period').setValue(Period.pm);
			}
		}
	}

	private updateAnyField(action: action): void {
		this.setAnyInputFocused(false);
		this.typing = false;

		if (this.timeForm?.value[action] === '') {
			this.setDefaultFieldValue(action);
		}
	}

	private changeTime(action: action, direction: direction): void {
		switch (action) {
			case 'hour':
				this.incHour(direction);
				break;
			case 'minutes':
				this.incMinutes(direction);
				break;
			case 'period':
				this.togglePeriod();
		}
	}

	private setHourInput(hour: string): void {
		this.timeForm.get('hour').setValue(hour);
		this.setHourWidth();
	}

	private setHourWidth(): void {
		this.hourInputWidth = `${this.timeForm?.value?.hour.length || 1}ch`;
	}

	selectInputElement(action: action): void {
		let inputElement: HTMLInputElement;

		switch (action) {
			case 'hour':
				inputElement = this.hourInput.nativeElement;
				break;
			case 'minutes':
				inputElement = this.minutesInput.nativeElement;
				break;
			case 'period':
				inputElement = this.periodInput.nativeElement;
				break;
			default:
				return;
		}
		inputElement.select();
	}

	private setDefaultFieldValue(action: action): void {
		const nextHour = (new Date(new Date().setHours(new Date().getHours() + 1)).getHours() % 12 || 12).toString();
		const hour = parseInt(this.timeForm?.value?.hour, 10);
		const isPm = Number.isInteger(hour) && (hour === 12 || (hour >= 1 && hour <= 6));

		switch (action) {
			case 'hour':
				this.setHourInput(nextHour);
				break;
			case 'minutes':
				this.timeForm.get('minutes').setValue('00');
				break;
			case 'period':
				this.timeForm.get('period').setValue(isPm ? Period.pm : Period.am);
				break;
		}
	}

	enqueueArrowClick(event: MouseEvent, action: action, direction: direction): void {
		event.stopPropagation();
		this.timeArrowChanges$.next({ action: action, direction: direction });
	}

	handleInputClick(event: MouseEvent): void {
		event.stopPropagation();
		if (this.blockClick) {
			event.preventDefault();
		}
	}

	private incHour(direction: direction): void {
		const hourInput = this.timeForm?.value?.hour;
		let parsedHour = parseInt(hourInput, 10);

		if (!hourInput || Number.isNaN(parsedHour)) {
			this.setHourInput('12');
			return;
		}

		if (direction === 'up') {
			parsedHour++;
			if (parsedHour === 12) {
				this.togglePeriod();
			}
		} else {
			parsedHour--;
			if (parsedHour === 11) {
				this.togglePeriod();
			}
		}

		if (parsedHour < 1) {
			this.setHourInput('12');
			return;
		}

		if (parsedHour > 12) {
			this.setHourInput('1');
			return;
		}

		this.setHourInput(`${parsedHour}`);
	}

	private incMinutes(direction: direction): void {
		const minutesInput = this.timeForm?.value?.minutes;
		let parsedMinutes = parseInt(minutesInput, 10);

		if (!minutesInput || Number.isNaN(parsedMinutes)) {
			this.timeForm.get('minutes').setValue(`00`);
			return;
		}

		if (direction === 'up') {
			parsedMinutes++;
		} else {
			parsedMinutes--;
		}

		if (parsedMinutes < 0) {
			this.timeForm.get('minutes').setValue(`59`);
			return;
		}

		if (parsedMinutes > 59) {
			this.timeForm.get('minutes').setValue(`00`);
			return;
		}

		if (parsedMinutes < 10) {
			this.timeForm.get('minutes').setValue(`0${parsedMinutes}`);
			return;
		}

		this.timeForm.get('minutes').setValue(`${parsedMinutes}`);
	}

	private togglePeriod(): void {
		if (this.timeForm?.value?.period === Period.am) {
			this.timeForm.get('period').setValue(Period.pm);
			return;
		}
		this.timeForm.get('period').setValue(Period.am);
	}

	numericOnly(event: KeyboardEvent, action: action, prevInput?: HTMLInputElement, nextInput?: HTMLInputElement): boolean {
		if (this.handleDirectionKeys(event, action, prevInput, nextInput)) {
			return !this.isDeleteKey(event.key);
		}

		this.typing = true;
		const pattern = /^([0-9]|[\b])$/;
		const result = pattern.test(event.key);

		return result || this.isDeleteKey(event.key);
	}

	amPmOnly(event: KeyboardEvent, action: action, prevInput?: HTMLInputElement, nextInput?: HTMLInputElement): boolean {
		if (this.handleDirectionKeys(event, action, prevInput, nextInput)) {
			return !this.isDeleteKey(event.key);
		}

		const pattern = /^([aApP])$/;
		const isAorP = pattern.test(event.key);
		if (isAorP) {
			if (event.key === 'a' || event.key === 'A') {
				this.timeForm.get('period').setValue(Period.am);
			} else {
				this.timeForm.get('period').setValue(Period.pm);
			}
			this.handleHopRight(nextInput);
			return false;
		}

		this.typing = true;
		if (this.isDeleteKey(event.key)) {
			return true;
		}

		return false;
	}

	private isDeleteKey(key: string): boolean {
		return key === 'Backspace' || key === 'Delete';
	}

	private handleDirectionKeys(keyEvent: KeyboardEvent, action: action, prevInput?: HTMLInputElement, nextInput?: HTMLInputElement): boolean {
		const currentInput = keyEvent.target as HTMLInputElement;
		const cursorPosition = currentInput?.selectionStart;
		const key = keyEvent.key;

		// Increment or decrement
		if (key === 'ArrowUp' || key === 'ArrowDown') {
			this.handleArrowKeys(key, action);
		}

		// Move right
		if (key === 'Enter' || (!keyEvent.shiftKey && key === 'Tab') || key === 'ArrowRight') {
			// Allow arrow keys to move within the input
			if (key === 'ArrowRight' && cursorPosition < currentInput.value.length) {
				return true;
			}
			this.handleHopRight(nextInput, key === 'ArrowRight');
		}

		// Move left
		if (
			key === 'ArrowLeft' ||
			(keyEvent.shiftKey && keyEvent.key === 'Tab') ||
			(keyEvent.key === 'Backspace' && cursorPosition === 0 && currentInput?.selectionEnd === 0)
		) {
			if (key === 'ArrowLeft' && cursorPosition > 0) {
				return true;
			}
			this.handleHopLeft(prevInput, key === 'ArrowLeft');
			return keyEvent.key === 'Backspace';
		}

		return false;
	}

	private handleArrowKeys(direction: 'ArrowUp' | 'ArrowDown', action: action): void {
		if (direction === 'ArrowUp') {
			this.changeTime(action, 'up');
		}
		if (direction === 'ArrowDown') {
			this.changeTime(action, 'down');
		}
	}

	private handleHopRight(nextInput?: HTMLInputElement, isArrowKey = false): void {
		// Move to next input
		if (nextInput) {
			nextInput.focus();
			if (isArrowKey) {
				nextInput.setSelectionRange(0, 0);
			}
		}
		// Hop over to next time field
		else {
			this.switchToEndInput.emit(isArrowKey);
		}
		this.typing = false;
	}

	private handleHopLeft(prevInput?: HTMLInputElement, isArrowKey = false): void {
		// Move to next input
		if (prevInput) {
			prevInput.focus();
			if (isArrowKey) {
				const inputLen = prevInput.value.length;
				prevInput.setSelectionRange(inputLen, inputLen);
			}
		}

		// Hop over to next time field
		else {
			this.switchToStartInput.emit(isArrowKey);
		}
		this.typing = false;
	}
}
