<div class="tw-flex tw-flex-col tw-bg-white tw-h-full">
	<div
		class="tw-flex tw-items-center tw-h-5 tw-border-0 tw-border-solid tw-border-gray-200 tw-px-6.5 tw-py-6 tw-gap-x-2"
		[ngClass]="{ 'tw-border-b': scrolling }">
		<img class="tw-w-4" src="assets/Search Normal (Blue-Gray).svg" />
		<input
			class="tw-border-0 tw-focus:outline-none tw-text-gray-500 tw-font-normal tw-text-base tw-caret-navy-500 tw-placeholder-gray-500"
			style="border: none !important; outline: none !important"
			type="text"
			placeholder="Search students"
			[(ngModel)]="searchText"
			(ngModelChange)="onSearchChange()" />
	</div>
	<div
		*ngIf="school$ | async as school"
		(scroll)="onScroll($event)"
		class="tw-animate-fadein-200 tw-relative tw-flex tw-flex-col tw-track-transparent-thumb-gray-100 tw-px-3.5 tw-pb-4">
		<div
			*ngIf="searchText.length === 0"
			class="tw-animate-fadein-200 tw-relative tw-h-10 tw-flex tw-items-center tw-rounded-xl tw-py-1.5"
			[ngClass]="{ 'tw-cursor-pointer tw-transition tw-duration-200 hover:tw-bg-gray-050': accountType !== 'Clever' }"
			customToolTip
			[position]="'left'"
			[showToolTip]="!userCanEditClass"
			[contentTemplate]="onlyAdminEditTooltip"
			(click)="!isCleverSynced && userCanEditClass && openSearch()"
			(mouseenter)="showCleverNotice = true"
			(mouseleave)="showCleverNotice = false">
			<img
				src="assets/Add Group Woman Man (Blue-Gray).svg"
				class="tw-bg-gray-100 tw-rounded-lg tw-w-5 tw-p-2.5 tw-mr-3 tw-ml-3.5"
				[ngClass]="{ 'tw-opacity-50': accountType }" />
			<div class="tw-text-navy-500 tw-font-medium tw-text-base" [ngClass]="{ 'tw-opacity-50': accountType }">Add Students</div>
			<sp-clever-notice
				*ngIf="showCleverNotice && accountType == 'clever'"
				message="To add students to this class, add them in Clever."
				classes="tw-top-0 tw-left-60"></sp-clever-notice>
			<sp-classlink-notice
				*ngIf="showCleverNotice && accountType == 'classlink'"
				message="To add students to this class, add them in Classlink."
				classes="tw-top-0 tw-left-60"></sp-classlink-notice>
			<sp-google-notice
				*ngIf="showCleverNotice && accountType == 'google-classroom'"
				message="To add students to this class, add them in Google Classroom."
				classes="tw-top-0 tw-left-60"></sp-google-notice>
		</div>
		<ng-template #onlyAdminEditTooltip>
			<div class="ds-tooltip" style="max-width: 321px; text-align: left; padding: 12px">
				<p class="tw-w-max tw-my-0 tw-bg-purple-100 tw-py-1 tw-px-1.5 tw-text-purple-500 tw-text-xs tw-font-bold tw-uppercase tw-rounded">
					Admin Only
				</p>
				<p class="tw-text-sm tw-font-medium tw-mb-0 tw-mt-1.5">Only admins can edit classes.</p>
			</div>
		</ng-template>
		<sp-class-student-list-item
			*ngFor="let student of displayStudents"
			[accountType]="accountType"
			[student]="student"
			(closeModal)="closeModal.emit($event)"
			(removeStudent)="handleRemoveStudent($event)"></sp-class-student-list-item>
		<div *ngIf="displayStudents.length === 0" class="tw-mx-auto tw-mt-14 tw-text-gray-500 tw-font-medium tw-text-base">No students found</div>
	</div>
</div>
<ng-template #addStudentsModalBody>
	<sp-student-class-search
		[studentsInClass]="students"
		[autoSetFocusOnSearch]="autoSetFocusOnSearch"
		(toAdd)="handleStudentsAdded($event)"></sp-student-class-search>
</ng-template>
