import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getStartOfSchoolYearAsMoment } from '../../helpers/helper';

export type CalendarToggleOptions = 'Range' | 'Days' | 'Weeks' | 'Single Day' | 'Multiple Days';

@Component({
	selector: 'app-admin-calendar-toggle',
	templateUrl: './admin-calendar-toggle.component.html',
	styleUrls: ['./admin-calendar-toggle.component.scss'],
})
export class AdminCalendarToggleComponent implements OnInit, OnDestroy {
	@ViewChild('elem') elem: ElementRef;
	@ViewChild('day') day: ElementRef;
	@ViewChild('dayButton') dayButton: ElementRef;
	@ViewChild('rangeButton') rangeButton: ElementRef;
	@ViewChild('container', { static: true }) set content(content: ElementRef) {
		if (content) {
			setTimeout(() => {
				this.container = content.nativeElement;
				const rect = this.container.getBoundingClientRect();
				// this.containerInitialHeight = this.containerInitialHeight ? this.containerInitialHeight : rect.height;
				this.windowInnerHeight = window.innerHeight;

				if (window.innerHeight < rect.top + rect.height) {
					this.container.style.height = window.innerHeight - rect.top - 5 + 'px';
				}
			}, 300);
		}
	}

	@Input() selectedOptions;

	@Input() date;
	@Input() toggleOptions = ['Range', 'Days', 'Weeks'];
	@Input() openCalendar: boolean;
	@Input() toggleResult: CalendarToggleOptions;
	@Input() useUtcTime: boolean;

	@Output() settingsRes: EventEmitter<any> = new EventEmitter<any>();
	@Output() adminCalendarRes: EventEmitter<any> = new EventEmitter<any>();

	container: HTMLElement;
	containerInitialHeight = 345;
	windowInnerHeight: number;

	rangeOptions = [
		{ id: 'range_0', title: 'Today', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_5', title: 'Last 3 Days', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_1', title: 'Last 7 Days', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_2', title: 'Last 30 Days', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_3', title: 'Last 90 Days', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_6', title: 'This school year', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'range_4', title: 'Custom Date Range', selectedIcon: './assets/Check (Navy).svg', arrowIcon: './assets/Chevron Down (Blue-Gray).svg' },
	];

	daysOptions = [
		{ id: 'days_1', title: 'All Day', selectedIcon: './assets/Check (Navy).svg' },
		{ id: 'days_2', title: 'Custom Time Range', selectedIcon: './assets/Check (Navy).svg', arrowIcon: './assets/Chevron Down (Blue-Gray).svg' },
	];
	openTimeRange: boolean;

	selectedRangeId: string;
	selectedDayId: string;

	exportDates: moment.Moment[] = [];
	exportDate = [];
	exportHoveredDates: moment.Moment[] = [];

	currentDate: moment.Moment = moment();
	selectedDate: {
		start: moment.Moment;
		end: moment.Moment;
	} = { start: null, end: null };

	selectedDay: moment.Moment;

	destroy$ = new Subject();

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		if (this.toggleResult == 'Single Day' || this.toggleResult == 'Multiple Days') {
			this.selectedDate = this.date;
			if (this.toggleResult == 'Multiple Days') {
				this.exportDates = [this.selectedDate.start, this.selectedDate.end];
				const countDiff = this.selectedDate.end.diff(moment(this.selectedDate.start), 'days');
				for (let i = 0; i <= countDiff; i++) {
					const hoveredDate = moment(this.selectedDate.start).add(i, 'days');
					this.exportHoveredDates.push(hoveredDate);
					this.exportHoveredDates.push(this.selectedDate.end);
				}
			}
		}
		if (this.selectedOptions) {
			setTimeout(() => {
				this.toggleResult = this.selectedOptions.toggleResult;
				if (this.selectedOptions.rangeId) {
					this.selectedRangeId = this.selectedOptions.rangeId;
				}
				if (this.date) {
					if (this.toggleResult === 'Range') {
						this.selectedDate = this.date;
						if (this.selectedRangeId === 'range_4') {
							this.openCalendar = true;
							this.exportDates = [this.selectedDate.start, this.selectedDate.end];
							const countDiff = this.selectedDate.end.diff(moment(this.selectedDate.start), 'days');
							for (let i = 0; i <= countDiff; i++) {
								const hoveredDate = moment(this.selectedDate.start).add(i, 'days');
								this.exportHoveredDates.push(hoveredDate);
								this.exportHoveredDates.push(this.selectedDate.end);
							}
							this.cdr.detectChanges();
							if (this.selectedDate.start && this.selectedDate.end) {
								this.elem.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
							}
						}
					} else if (this.toggleResult === 'Days') {
						this.selectedDayId = this.selectedOptions.dayOptId;
						this.exportDate = [this.date.start];
						this.selectedDay = this.date.start;
						this.selectedDate = this.date;
						if (this.selectedOptions.dayOptId === 'days_2') {
							this.selectedDaysOption('days_2');
						}
					} else if (this.toggleResult === 'Weeks') {
						this.selectedDate = this.date;
						const countDiff = this.selectedDate.end.diff(moment(this.selectedDate.start), 'days');
						for (let i = 0; i <= countDiff; i++) {
							const hoveredDate = moment(this.selectedDate.start).add(i, 'days');
							this.exportHoveredDates.push(hoveredDate);
							this.exportHoveredDates.push(this.selectedDate.end);
						}
					}
				}
			}, 10);
		}

		fromEvent(window, 'resize')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				const rect = this.container.getBoundingClientRect();
				let h;

				if (this.openCalendar && this.toggleResult === 'Range') {
					h = 451;
				} else if (this.toggleResult === 'Weeks') {
					h = 370;
				} else if (this.toggleResult === 'Days') {
					h = 479;
				} else {
					h = this.containerInitialHeight;
				}

				if (window.innerHeight < rect.top + rect.height) {
					this.container.style.height = window.innerHeight - rect.top - 5 + 'px';
				} else if (window.innerHeight > rect.top + rect.height && rect.height + 5 < h) {
					this.container.style.height = (window.innerHeight - rect.top - 5 < h ? window.innerHeight - rect.top - 5 : h) + 'px';
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	selectedRangeOption(id): boolean {
		this.openCalendar = false;
		if (id === 'range_0') {
			this.selectedDate.end = this.currentDate;
			this.selectedDate.start = moment(this.currentDate).startOf('day');
		} else if (id === 'range_1') {
			this.selectedDate.end = this.currentDate;
			this.selectedDate.start = moment().subtract(7, 'days').startOf('day');
		} else if (id === 'range_2') {
			this.selectedDate.end = this.currentDate;
			this.selectedDate.start = moment().subtract(30, 'days').startOf('day');
		} else if (id === 'range_3') {
			this.selectedDate.end = this.currentDate;
			this.selectedDate.start = moment().subtract(90, 'days').startOf('day');
		} else if (id === 'range_4') {
			this.openCalendar = true;
			this.settingsRes.emit({ toggleResult: this.toggleResult, rangeId: id });
			setTimeout(() => {
				this.rangeButton.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
			}, 100);
			return false;
		} else if (id === 'range_5') {
			this.selectedDate.end = this.currentDate;
			this.selectedDate.start = moment().subtract(3, 'days').startOf('day');
		} else if (id === 'range_6') {
			this.selectedDate.start = moment('1/7/' + getStartOfSchoolYearAsMoment().year(), 'DD/MM/YYYY');
			this.selectedDate.end = moment(moment(), 'DD/MM/YYYY');
		}
		this.settingsRes.emit({ toggleResult: this.toggleResult, rangeId: id });
		this.adminCalendarRes.emit(this.selectedDate);
	}

	selectedDaysOption(id): void {
		this.openTimeRange = id === 'days_2';
		if (this.openTimeRange) {
			this.settingsRes.emit({ toggleResult: this.toggleResult, dayOptId: id });
			this.cdr.detectChanges();
			setTimeout(() => {
				this.dayButton.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
			}, 100);
		}
	}

	calendarResult(selectedDates): void {
		if (this.toggleResult === 'Range' || this.toggleResult === 'Multiple Days') {
			this.selectedDate.start = selectedDates[0];
			this.selectedDate.end = selectedDates[1];
			this.cdr.detectChanges();
			if (this.selectedDate.start && this.selectedDate.end) {
				this.elem.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
			}
		} else if (this.toggleResult === 'Days' || this.toggleResult === 'Single Day') {
			this.selectedDay = selectedDates[0];
			this.selectedDate.end = selectedDates[1];
			this.exportDates = [this.selectedDate.start, this.selectedDate.end];
			if (this.openTimeRange) {
				this.selectedDate.start = moment(this.selectedDate.start)
					.set('month', this.selectedDay.month())
					.set('year', this.selectedDay.year())
					.set('date', this.selectedDay.date());
				this.selectedDate.end = moment(this.selectedDate.end)
					.set('month', this.selectedDay.month())
					.set('year', this.selectedDay.year())
					.set('date', this.selectedDay.date());
				this.cdr.detectChanges();
				this.dayButton.nativeElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
			} else {
				this.settingsRes.emit({ toggleResult: this.toggleResult });
				this.adminCalendarRes.emit({ start: moment(this.selectedDay).startOf('day'), end: moment(this.selectedDay) });
			}
		} else if (this.toggleResult === 'Weeks') {
			this.settingsRes.emit({ toggleResult: this.toggleResult });
			this.adminCalendarRes.emit({ start: selectedDates[0], end: selectedDates[1] });
		}
	}

	changeRangeTime(start, date): void {
		if (start) {
			this.selectedDate.start = date;
		} else {
			this.selectedDate.end = date;
		}
	}

	save(): void {
		this.settingsRes.emit({ toggleResult: this.toggleResult });
		this.adminCalendarRes.emit(this.selectedDate);
	}
}
