<div class="dialog-content">
	<ng-container *ngFor="let item of items">
		<div class="menu-item tw-cursor-pointer" (click)="onClick(item)" [class.selected]="item.selected" [id]="item.id">
			<img
				*ngIf="item.iconLeftPath"
				[src]="item.selected && item.iconLeftPathHighlighted ? item.iconLeftPathHighlighted : item.iconLeftPath"
				class="menu-item-icon left" />
			{{ item.text }}
			<img *ngIf="item.iconRightPath" class="menu-item-icon right" [src]="item.iconRightPath" />
		</div>
		<div *ngIf="item.hasDivider" class="menu-item-divider"></div>
	</ng-container>
</div>
