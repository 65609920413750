<div *ngIf="scheduleService.getTermData$ | async as termData">
	<div class="wrapper" *ngIf="page === 1" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div class="header" *ngIf="!(passLimitFormChanged | async); else changedFormHeader">
			<div class="background-header"></div>
			<img class="back-button" (click)="dialogRef.close()" [src]="'./assets/Back Button (Blue-White).svg' | resolveAsset" />
			<div class="title-header ds-flex-center-center" style="z-index: 10" [attr.data-motion-translate]="(frameMotion$ | async)?.direction">
				<div class="pass-limit-white ds-flex-center-center" style="background: rgb(102, 81, 241); border-radius: 6px; padding: 7px">
					<img style="width: 25px" [src]="'./assets/Pass Limit (White).svg' | resolveAsset" alt="" />
				</div>
				<div class="ds-dialog-title" [style.margin-left]="'12px'">Pass Limits</div>
			</div>
		</div>

		<ng-template #changedFormHeader>
			<div class="header" style="justify-content: space-around">
				<div class="background-header"></div>
				<app-gradient-button (buttonClick)="resetPassLimitsForm()" customBackground="#7083A0">Cancel</app-gradient-button>
				<div class="title-header" style="z-index: 10; display: flex">
					<div class="ds-dialog-title" [style.margin-left]="'12px'">Pass Limits</div>
				</div>
				<app-gradient-button (buttonClick)="updatePassLimits()" customBackground="#00B476" textColor="white" [disabled]="passLimitForm.invalid">
					<ng-template [ngTemplateOutlet]="buttonLoading" [ngTemplateOutletContext]="{ loading: requestLoading, text: 'Save' }"></ng-template>
				</app-gradient-button>
			</div>
		</ng-template>

		<ng-template #contentSpinner>
			<div class="ds-w-100 ds-flex-center-center" style="height: 150px">
				<mat-spinner [diameter]="50" class="content-spinner"></mat-spinner>
			</div>
		</ng-template>

		<div class="content">
			<ng-container *ngIf="showPassLimitNux" [ngTemplateOutlet]="passLimitNux"></ng-container>
			<div
				class="ds-p-20"
				[style.height]="showPassLimitNux ? '250px' : ''"
				*ngIf="!contentLoading; else contentSpinner"
				[attr.data-motion-translate]="(frameMotion$ | async)?.direction">
				<div class="accent-background-section ds-flex-column">
					<div style="display: flex" class="ds-flex-column">
						<span class="ds-dialog-heading section-header" style="padding-bottom: 20px">
							Timeframe for Limits
							<div style="display: flex" customToolTip [additionalOffsetY]="-15" [position]="'top'" [contentTemplate]="passLimitTimeFrameLearnMore">
								<img class="support-icon" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
							</div>
							<ng-template #passLimitTimeFrameLearnMore>
								<div class="ds-tooltip">
									<span>
										Limit the number of passes all students can have per timeframe.
										<a href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
									</span>
								</div>
							</ng-template>
						</span>

						<div
							class="tw-w-full tw-flex tw-items-center tw-justify-between tw-text-navy-500 tw-font-normal tw-text-base tw-p-3 tw-bg-white tw-rounded-lg tw-border tw-border-solid tw-border-gray-150 tw-box-border"
							[ngClass]="{ 'tw-cursor-pointer': true, 'tw-text-navy-500': passLimit }"
							controlName="frequency"
							(click)="openDropdown($event, termData)">
							<div>{{ frequencyText }}</div>
							<img class="tw-h-2" src="../../../../assets/Chevron%20Down%20(Blue-Gray).svg" />
						</div>
					</div>
				</div>

				<div class="accent-background-section ds-flex-column">
					<div class="ds-flex-row ds-flex-center-between tw-mb-3 tw-flex">
						<div class="ds-dialog-heading section-header" style="padding-bottom: 0 !important">
							Limit for All Students
							<div
								style="display: flex"
								customToolTip
								[additionalOffsetY]="frequency === 'term' ? (termData?.currentTerm || termData.upcomingTerm ? -105 : -85) : -30"
								[position]="'top'"
								[contentTemplate]="passLimitLearnMore">
								<img class="support-icon" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
							</div>
							<ng-template #passLimitLearnMore>
								<div class="ds-tooltip">
									<div style="display: flex; flex-direction: column; width: 212px">
										<span> Limit the number of passes all students can have per {{ frequency }}. </span>
										<a *ngIf="frequency === 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
									</div>
									<a *ngIf="frequency !== 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>

									<div *ngIf="frequency === 'term'" class="term-tooltip">
										<ng-container *ngIf="termData.currentTerm">
											<div class="header">
												<span class="title">Current Term: </span>
												<span>{{ termData.currentTerm.term_name }} </span>
											</div>
											<span class="dates">
												{{ termData.currentTerm.start_date | date : 'MM/dd/yyyy' }}
												-
												{{ termData.currentTerm.end_date | date : 'MM/dd/yyyy' }}
											</span>
										</ng-container>
										<ng-container *ngIf="!termData.currentTerm && termData.upcomingTerm">
											<div class="header">
												<span class="title">Upcoming Term: </span>
												<span>{{ termData.upcomingTerm.term_name }} </span>
											</div>
											<span class="dates">
												{{ termData.upcomingTerm.start_date | date : 'MM/dd/yyyy' }}
												-
												{{ termData.upcomingTerm.end_date | date : 'MM/dd/yyyy' }}
											</span>
										</ng-container>
										<ng-container *ngIf="!termData.currentTerm && !termData.upcomingTerm">
											<div class="header">
												<span class="title">No Upcoming Term</span>
											</div>
										</ng-container>
										<span (click)="openIntercom()" style="color: white; text-decoration: underline; cursor: pointer">Edit Terms</span>
									</div>
								</div>
							</ng-template>
						</div>
						<app-toggle-input
							style="width: auto"
							controlSize="small"
							[form]="passLimitForm"
							controlName="limitEnabled"
							[delimiter]="false"></app-toggle-input>
					</div>

					<ng-container *ngIf="passLimitForm.value['limitEnabled']">
						<app-pass-limit-input
							#schoolPassLimitInput
							[frequency]="frequency"
							[formGroup]="passLimitForm"
							[control]="passLimitForm.get('passLimit')"
							[isIndividual]="false"
							[currentTerm]="termData?.currentTerm"
							[upcomingTerm]="termData?.upcomingTerm"
							[showSupportIcon]="false">
						</app-pass-limit-input>
					</ng-container>
				</div>

				<div *ngIf="individualStudentLimits.length === 0" class="accent-background-section ds-flex-column ds-flex-center-between">
					<div class="ds-dialog-heading section-header" style="align-self: flex-start; padding-bottom: 0 !important">
						Individual Limits
						<div class="tooltip-container" style="display: inline">
							<div style="display: flex" customToolTip [additionalOffsetY]="-50" [position]="'top'" [contentTemplate]="individualLimitLearnMore">
								<img class="support-icon" style="width: 17px" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
							</div>
						</div>
						<ng-template #individualLimitLearnMore>
							<div class="ds-tooltip">
								<span>
									Limit the number of passes certain students can have per {{ frequency }}. If enabled, it overrides the Limit for All Students.
									<a *ngIf="frequency === 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
								</span>
								<a *ngIf="frequency !== 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
							</div>
						</ng-template>
					</div>

					<div class="individual-limits-list ds-py-20" style="text-align: center">
						<p class="ds-details-text-bold" style="color: #b7c1cf !important; font-weight: 500 !important">No individual limits set</p>

						<app-gradient-button
							size="medium"
							gradient="#00B476"
							textColor="#FFFFFF"
							text="Add Limit"
							leftIcon="assets/Plus (White).svg"
							(buttonClick)="goToIndividualLimitPage()">
							Add Limit
						</app-gradient-button>
					</div>
				</div>

				<div *ngIf="individualStudentLimits.length > 0" class="accent-background-section">
					<div class="ds-flex-row ds-flex-center-between ds-mb-20">
						<div class="ds-dialog-heading section-header" style="align-self: flex-start; padding-bottom: 0 !important">
							Individual Limits
							<div class="tooltip-container" style="display: inline">
								<div style="display: flex" customToolTip [additionalOffsetY]="-75" [position]="'top'" [contentTemplate]="individualLimitLearnMore">
									<img class="support-icon" style="width: 17px" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
								</div>
							</div>
							<ng-template #individualLimitLearnMore>
								<div class="ds-tooltip">
									<div style="display: flex; flex-direction: column; width: 212px">
										<span>
											Limit the number of passes certain students per {{ frequency }}. If enabled, it overrides the Limit for All Students.
										</span>
										<a *ngIf="frequency === 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
									</div>
									<a *ngIf="frequency !== 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank"> Learn more</a>
								</div>
							</ng-template>
						</div>
						<app-gradient-button
							size="editable"
							width="70px"
							minHeight="30px"
							(buttonClick)="goToIndividualLimitPage()"
							fontSize="16px"
							fontWeight="400"
							cornerRadius="4px"
							leftIcon="assets/Plus (White).svg"
							leftImageWidth="15px"
							leftImageHeight="15px">
							<span>Add</span>
						</app-gradient-button>
					</div>

					<div
						#individualItem
						(mousedown)="individualItem.classList.add('pressed')"
						(mouseup)="individualItem.classList.remove('pressed')"
						(mouseleave)="individualItem.classList.remove('pressed')"
						class="individual-list-item ds-flex-column"
						*ngFor="let i of individualStudentLimits"
						style="font-size: 14px; display: flex; row-gap: 5px"
						[ngClass]="{ 'no-padding-y': !i.description }"
						(click)="goToIndividualLimitPage(i)">
						<div class="ds-flex-row ds-flex-center-between">
							<span class="ds-dialog-heading" style="padding-bottom: 0 !important" ignore>{{ i.student.display_name }}</span>
							<span>{{ i.passLimit === -2 ? 'Unlimited' : i.passLimit }} {{ i.passLimit === 1 ? 'pass' : 'passes' }}/{{ frequency }}</span>
						</div>

						<span class="ds-dialog-normal-text" *ngIf="i.description">{{ i.description }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="wrapper" *ngIf="page === 2" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div class="header" *ngIf="!(individualFormChanged | async); else changeIndividualForm">
			<div class="background-header"></div>
			<img class="back-button" (click)="goToHomePage()" [src]="'./assets/Back Button (Blue-White).svg' | resolveAsset" />
			<div class="title-header ds-flex-center-center" style="z-index: 10">
				<div class="ds-dialog-title" [style.margin-left]="'12px'">{{ selectedExistingIndividualLimit ? 'Edit' : 'New' }} Individual Limit</div>
			</div>
		</div>

		<ng-template #changeIndividualForm>
			<div class="header" style="justify-content: space-around">
				<div class="background-header"></div>
				<app-gradient-button [disabled]="requestLoading" (buttonClick)="resetIndividualForm()" customBackground="#7083A0">Cancel</app-gradient-button>
				<div class="title-header" style="z-index: 10; display: flex">
					<div class="ds-dialog-title">{{ selectedExistingIndividualLimit ? 'Edit' : 'New' }} Individual Limit</div>
				</div>
				<app-gradient-button
					(buttonClick)="submitIndividualLimits()"
					customBackground="#00B476"
					textColor="white"
					[disabled]="!!individualOverrideForm?.invalid">
					<ng-template [ngTemplateOutlet]="buttonLoading" [ngTemplateOutletContext]="{ loading: individualLoading, text: 'Save' }"></ng-template>
				</app-gradient-button>
			</div>
		</ng-template>

		<div class="content ds-p-20" #individualContent>
			<div>
				<section class="individual-name ds-mb-15">
					<p class="ds-dialog-heading ds-blue-gray" style="padding: 0 !important; margin-top: 0px !important">Student</p>
					<app-sp-search
						*ngIf="!selectedExistingIndividualLimit"
						#studentSearch
						(onUpdate)="updateStudentList($event)"
						[chipsMode]="true"></app-sp-search>
					<div class="selected-name" *ngIf="selectedExistingIndividualLimit as iLimit">
						<div class="student-picture">
							<div
								*ngIf="iLimit.student.profile_picture; else d"
								[ngStyle]="{
									background: 'url(' + iLimit.student.profile_picture + ') no-repeat left center/cover',
									width: '23px',
									height: '23px'
								}"></div>
							<ng-template #d>
								<img width="23" height="23" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="" />
							</ng-template>
						</div>
						<div class="name" ignore>{{ iLimit.student.display_name }}</div>
					</div>
				</section>

				<section class="individual-limit-override ds-mb-15">
					<span class="ds-dialog-heading ds-blue-gray" style="padding: 0 !important">Limit</span>
					<p class="ds-dialog-normal-text" style="margin-top: 4px">
						This limit overrides the Limit for All Students of {{ passLimit?.passLimit }} {{ passLimit?.passLimit === 1 ? 'pass' : 'passes' }}/{{
							frequency
						}}.
					</p>
					<app-pass-limit-input
						#individualPassLimitInput
						[frequency]="frequency"
						[formGroup]="individualOverrideForm"
						[control]="individualOverrideForm.get('passLimit')"
						[currentTerm]="termData?.currentTerm"
						[upcomingTerm]="termData?.upcomingTerm"
						[isIndividual]="true"></app-pass-limit-input>
				</section>

				<section class="individual-limit-description" style="margin-bottom: 10px">
					<span class="ds-dialog-heading ds-blue-gray" style="padding: 0 !important">Description</span>
					<p class="ds-dialog-normal-text" style="margin-top: 4px">This description is only visible to staff.</p>
					<app-textarea width="85%" [control]="individualOverrideForm.get('description')" placeholder="Ex. Parents requested"></app-textarea>
				</section>
			</div>

			<div class="delete-button ds-flex-center-center" *ngIf="selectedExistingIndividualLimit">
				<app-gradient-button
					[disabled]="requestLoading"
					size="editable"
					minHeight="40px"
					minWidth="190px"
					fontSize="14px"
					cornerRadius="8px"
					padding="0px 16px"
					(buttonClick)="openDeleteDialog()"
					gradient="#E32C66"
					textColor="#FFFFFF">
					<ng-template
						[ngTemplateOutlet]="buttonLoading"
						[ngTemplateOutletContext]="{ loading: deleteLoading, text: 'Remove Individual Limit' }"></ng-template>
				</app-gradient-button>
			</div>
		</div>
	</div>
</div>

<ng-template #buttonLoading let-loading="loading" let-text="text">
	<span *ngIf="!loading">{{ text }}</span>
	<mat-spinner class="request-loading" *ngIf="loading" [diameter]="25"></mat-spinner>
</ng-template>

<ng-template #passLimitNux>
	<div class="nux-section ds-flex-row" style="position: relative">
		<div style="width: 50px">
			<img [src]="'./assets/Pass Limit (Purple).svg' | resolveAsset" alt="Options" />
		</div>
		<div style="width: 80%">
			<p class="ds-dialog-heading ds-mb-5" style="margin-top: 0">Limit the number of passes students can have per day, week, or term</p>
			<p class="ds-dialog-normal-text" style="margin-top: 0">
				Set a limit for all students, or just for individual students. Teachers can override it if the pass limit has been reached.
				<a href="https://www.smartpass.app/passlimits" target="_blank">Learn more</a>
			</p>
		</div>

		<button id="closePassLimitsIntro" (click)="dismissPassLimitNux()">
			<img style="width: 14px; height: 14px" [src]="'./assets/Close (Grey).svg' | resolveAsset" />
		</button>
	</div>
</ng-template>

<ng-template #deleteDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove the individual limit, the student's pass limit will be restored to the Limit for All Students, if enabled.
	</p>
</ng-template>

<ng-template #timeFrameConfirmDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 16px !important">
		This will change your existing school limit and all individual limits to '{{ newFrequencyText }}'.
	</p>
</ng-template>
