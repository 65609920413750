import * as React from 'react';
import { TextButton, DimBackground, Modal } from '../../components';

interface IConfirmationModalProps {
	className?: string;
	showModal: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	description?: string;
	title?: string;
	confirmText?: string;
}

export function ConfirmationDialog({
	onConfirm,
	onCancel,
	showModal,
	title = '',
	description = '',
	className = '',
	confirmText = 'Confirm',
}: IConfirmationModalProps): React.ReactElement | null {
	return (
		<>
			<DimBackground visible={showModal} />
			<Modal
				showModal={showModal}
				className={`tw-w-100 tw-py-5 tw-px-4.5 tw-bg-white tw-shadow-base tw-rounded-2xl ${className}`}
				onCancel={onCancel}>
				{title.length > 0 && <div className="tw-text-lg tw-font-bold tw-mb-3">{title}</div>}
				<p className="tw-text-base tw-font-normal tw-text-gray-500 tw-leading-5">{description}</p>
				<div className="tw-flex tw-justify-end tw-mt-8 tw-gap-3">
					<TextButton onClick={onCancel} text="Cancel" color="gray" />
					<TextButton onClick={onConfirm} text={confirmText} color="red" />
				</div>
			</Modal>
		</>
	);
}
