import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { TimeService } from '../../../services/time.service';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceDetection } from '../../../device-detection.helper';
import { PassLike } from '../../../models';
import { HallPass } from '../../../models/HallPass';
import { PassLimitInfo } from '../../../models/HallPassLimits';
import { Invitation } from '../../../models/Invitation';
import { Location } from '../../../models/Location';
import { Pinnable } from '../../../models/Pinnable';
import { Request } from '../../../models/Request';
import { WaitingInLinePass } from '../../../models/WaitInLine';
import { DataService } from '../../../services/data-service';
import { StorageService } from '../../../services/storage.service';
import { CreateFormService } from '../../create-form.service';
import { States } from '../locations-group-container/locations-group-container.component';
import { FormFactor, Navigation, Role } from '../main-hall-pass-form.component';

export type PassLayout = 'pass' | 'request' | 'inlinePass' | 'inlineRequest';

@Component({
	selector: 'app-form-factor-container',
	templateUrl: './form-factor-container.component.html',
	styleUrls: ['./form-factor-container.component.scss'],
})
export class FormFactorContainerComponent implements OnInit {
	@Input() FORM_STATE: Navigation;
	@Input() forStaff: boolean;
	@Input() passLimitInfo: PassLimitInfo;
	@Output() nextStepEvent: EventEmitter<Navigation> = new EventEmitter<Navigation>();

	// using ViewChild as a setter ensures the element is available for assigning values
	@ViewChild('wrapper') set wrapper(divRef: ElementRef<HTMLDivElement>) {
		if (!divRef?.nativeElement) {
			return;
		}

		if (this.forStaff) {
			return;
		}

		const { nativeElement } = divRef;

		this.fullScreenPass$
			.asObservable()
			.pipe(takeUntil(this.dialogRef.afterClosed()))
			.subscribe((scalePassUp) => {
				this.isOpenBigCard = scalePassUp;
				this.storage.setItem('pass_full_screen', scalePassUp);
				scalePassUp ? this.scaleCardUp(nativeElement) : this.scaleCardDown(nativeElement);
			});
	}

	isMobile = DeviceDetection.isMobile();
	states = FormFactor;
	template: Request | HallPass | Invitation | Pinnable | WaitingInLinePass;
	isOpenBigCard: boolean;
	fullScreenPass$: BehaviorSubject<boolean> = new BehaviorSubject(true);

	constructor(
		private dataService: DataService,
		private timeService: TimeService,
		@Inject(MAT_DIALOG_DATA)
		public dialogData: {
			kioskModeRoom: Location;
			pass: PassLike;
			isActive: boolean;
			forInput: boolean;
			passLayout: PassLayout;
		},
		@Optional() public dialogRef: MatDialogRef<FormFactorContainerComponent>,
		private storage: StorageService,
		private formService: CreateFormService
	) {}

	ngOnInit() {
		this.isOpenBigCard = JSON.parse(this.storage.getItem('pass_full_screen')) && !this.forStaff;
		this.fullScreenPass$ = new BehaviorSubject(this.isOpenBigCard);
		const now = this.timeService.nowDate();

		this.dataService.currentUser.subscribe((_user) => {
			let user = null;

			if (this.FORM_STATE.formMode.role === Role.Student) {
				user = _user;
			} else if (this.FORM_STATE.formMode.role === Role.Teacher && this.dialogData.kioskModeRoom) {
				user = this.FORM_STATE.data.selectedStudents[0];
			}

			switch (this.FORM_STATE.formMode.formFactor) {
				case this.states.HallPass:
					this.template = new HallPass(
						0,
						user,
						null,
						null,
						null,
						null,
						null,
						null,
						this.FORM_STATE.data.date ? this.FORM_STATE.data.date.date : now,
						null,
						null,
						this.FORM_STATE.data.direction.from,
						this.FORM_STATE.data.direction.to,
						'',
						'',
						this.FORM_STATE.data.direction.pinnable.icon,
						this.FORM_STATE.data.direction.pinnable.color_profile,
						null,
						'',
						'',
						undefined,
						this.FORM_STATE.data.date ? this.FORM_STATE.data.date.declinable : false,
						this.forStaff ? this.FORM_STATE.data.message : null
					);
					break;
				case this.states.Request:
					if (this.FORM_STATE.previousStep === 1) {
						setTimeout(() => {
							this.FORM_STATE.data.request.request_time = this.FORM_STATE.data.date.date;
							this.template = this.FORM_STATE.data.request;
						}, 100);
					} else {
						this.template = new Request(
							0,
							this.FORM_STATE.data.selectedStudents[0],
							this.FORM_STATE.data.direction.from,
							this.FORM_STATE.data.direction.to,
							this.FORM_STATE.data.message,
							'',
							'pending',
							null,
							'',
							this.FORM_STATE.data.direction.pinnable.icon,
							this.FORM_STATE.data.requestTarget ? [this.FORM_STATE.data.requestTarget] : [],
							this.FORM_STATE.data.date ? this.FORM_STATE.data.date.date : null,
							'',
							null,
							null,
							this.FORM_STATE.data.direction.pinnable.color_profile,
							null,
							null,
							60,
							null,
							null
						);
					}
					break;
				case this.states.Invitation:
					this.template = new Invitation(
						0,
						null,
						null,
						this.FORM_STATE.data.direction.to,
						[this.FORM_STATE.data.date ? this.FORM_STATE.data.date.date : now],
						_user,
						'pending',
						60,
						this.FORM_STATE.data.direction.pinnable.color_profile.gradient_color,
						this.FORM_STATE.data.direction.pinnable.icon,
						'round_trip',
						this.FORM_STATE.data.direction.pinnable.color_profile,
						null,
						null,
						null,
						null,
						this.FORM_STATE.data.message
					);
					break;
			}
		});
	}

	onNextStep(evt) {
		this.FORM_STATE = evt;
		this.nextStepEvent.emit(this.FORM_STATE);
	}

	onBack() {
		this.formService.setFrameMotionDirection('back');
		const { state, step } = this.FORM_STATE;
		this.FORM_STATE.step = this.FORM_STATE.previousStep;

		if (this.forStaff) {
			//empty out previous selections in new pass creation UI
			if (!this.FORM_STATE?.forLater) {
				this.FORM_STATE.data.direction.to = null;
				this.FORM_STATE.data.direction.pinnable = null;
			}
			this.FORM_STATE.state = !this.FORM_STATE?.forLater ? States.FromToWhere : States.toWhere;
		} else {
			// could be coming from a restricted target if student
			this.FORM_STATE.previousState = state;
		}

		this.FORM_STATE.previousStep = step;
		this.nextStepEvent.emit(this.FORM_STATE);
	}

	openBigPass() {
		this.fullScreenPass$.next(!this.fullScreenPass$.value);
	}

	private scaleCardUp(wrapper: HTMLDivElement) {
		let translationDistance = this.passLimitInfo !== undefined ? (this.isMobile ? -65 : -60) : -65;

		if (this.isMobile) {
			wrapper.style.transform = `scale(1.15) translateY(${translationDistance}px)`;
			return;
		}
		/**
		 * - we want the full-screen pass to exist in the top 75% of the page
		 * - we want to take off 10% of the remaining pixels to account for some space from the top of the page
		 */
		const { height } = wrapper.getBoundingClientRect();
		const targetHeight = document.documentElement.clientHeight * 0.7 * 0.9;
		const scalingFactor = targetHeight / height;
		translationDistance = this.forStaff || this.dialogData.kioskModeRoom ? 0 : -100;
		// translate happens before the scaling
		//wrapper.style.transform = `translateY(${translationDistance}px) scale(${scalingFactor})`;
	}

	private scaleCardDown(wrapper: HTMLDivElement) {
		wrapper.style.removeProperty('transform');
	}
}
