<div class="groups-settings-container" [ngClass]="{ 'schedules-ff': hasSchedulesFF, 'no-schedules-ff': !hasSchedulesFF }">
	<div class="header">
		<img class="groups-icon" src="../../../../../assets/Group (Navy).svg" />
		<h3>Student Groups</h3>
	</div>

	<div class="selections-container">
		<div class="groups-selection-container">
			<ng-container *ngIf="!createMode; else renderNewGroup">
				<app-round-input
					data-cy="search-inp-groups"
					class="search"
					[width]="'100%'"
					[backgroundColor]="'#FFFFFF'"
					[height]="'32px'"
					[borderRadius]="'8px'"
					[focused]="true"
					[boxShadow]="false"
					[placeholder]="'Search Groups'"
					(ontextupdate)="onGroupSearch($event)"
					#groupSearchInput="roundInputRef">
				</app-round-input>

				<div class="create-group-button">
					<img class="add-button" (click)="createModeOn()" src="../../../../../assets/Plus (White-Gray).svg" />
					<span class="text" (click)="createModeOn()"> New Student Group </span>
				</div>

				<app-groups-and-student-select
					[canEditGroup]="false"
					[width]="'100%'"
					[border]="'none'"
					[boxShadow]="'none'"
					[groups]="filteredGroups"
					(handleSelect)="handleGroupSelect($event)">
				</app-groups-and-student-select>
			</ng-container>

			<ng-template #renderNewGroup>
				<div class="new-group">
					<div class="icon-name">
						<img class="group-icon" src="../../../../../assets/Groups Icon.svg" />
						<span class="name">{{ groupTitle }}</span>
					</div>
					<img class="arrow-icon" src="../../../../../assets/Arrow Right.svg" />
				</div>
			</ng-template>
		</div>

		<div class="students-selection-container" [style.justify-content]="editMode ? 'flex-start' : 'center'">
			<ng-container *ngIf="selectedGroup || createMode; else renderCreate">
				<div class="selected-group-title-header">
					<img *ngIf="canEdit" class="emoticon" src="../../../../../assets/Emoticon (Gray).svg" />
					<input class="selected-group-title" [value]="groupTitle" [disabled]="!editMode && !createMode" [(ngModel)]="groupTitle" #groupTitleInput />
				</div>

				<div *ngIf="!editMode && !createMode" class="display-group-container">
					<app-round-input
						class="search"
						data-cy="search-inp-students"
						[width]="'100%'"
						[backgroundColor]="'#F5F6F8'"
						[height]="'32px'"
						[borderRadius]="'8px'"
						[focused]="true"
						[boxShadow]="false"
						(ontextupdate)="onStudentInGroupSearch($event)"
						[placeholder]="'Search Students'"
						#studentSearchInput="roundInputRef"
						#inputComponent>
					</app-round-input>
				</div>

				<div *ngIf="editMode || createMode" class="edit-group-container">
					<div *ngFor="let selection of studentsInSelectedGroup" class="selection">
						<app-selection [selection]="selection" [isUser]="!!selection.display_name" (removeSelectionEvent)="removeSelection($event)">
						</app-selection>
					</div>
					<app-round-input
						data-cy="search-inp"
						[fieldIcon]="''"
						[width]="'385px'"
						[backgroundColor]="'#EAEDF1'"
						[height]="'32px'"
						[borderRadius]="'8px'"
						[focused]="true"
						[boxShadow]="false"
						[minWidth]="'250px'"
						(ontextupdate)="onStudentSearch($event)"
						[placeholder]="'Search for students to add'"
						[padding]="'0px'"
						#studentSearchInput="roundInputRef"
						#inputComponent>
					</app-round-input>
				</div>

				<app-groups-and-student-select
					class="selections"
					[canEditGroup]="false"
					[width]="searchInProgress ? '417px' : '457px'"
					[height]="editMode && searchInProgress ? '286px' : editMode && !searchInProgress ? '100%' : '309px'"
					[maxHeight]="editMode && !searchInProgress ? '286px' : ''"
					[border]="searchInProgress ? '1px solid #EAEDF1' : 'none'"
					[borderBottomRightRadius]="editMode ? '12px' : ''"
					[background]="(editMode || createMode) && searchInProgress ? '#FFFFFF' : '#F5F6F8'"
					[backgroundHovered]="'#EAEDF1'"
					[boxShadow]="'none'"
					[(students)]="filteredStudents"
					(handleSelect)="editMode || createMode ? handleStudentSelect($event) : ''">
				</app-groups-and-student-select>

				<div *ngIf="searchInProgress" class="upload-students-button-container">
					<div class="upload-students-button" appCrossPointerEventTarget (pointerClickEvent)="showImportStudentList()">Upload a list of students</div>
				</div>

				<div *ngIf="(!editMode || !createMode) && !searchInProgress" class="buttons-footer">
					<app-gradient-button
						*ngIf="!createMode && !searchInProgress"
						[size]="'editable'"
						[width]="'205px'"
						[minHeight]="'51px'"
						[buttonDownColor]="'#DA2370'"
						[gradient]="'#DA2370, #FB434A'"
						[textColor]="'#FFFFFF'"
						[leftImageHeight]="'13px'"
						[leftImageWidth]="'13px'"
						[cursor]="'pointer'"
						(buttonClick)="removeGroup()">
						<div class="button">
							<img class="delete-icon" src="../../../../../assets/Delete (White).svg" />
							<span class="text">Delete Group</span>
						</div>
					</app-gradient-button>

					<app-gradient-button
						*ngIf="!editMode && !createMode"
						[size]="'editable'"
						[width]="'205px'"
						[minHeight]="'51px'"
						[buttonDownColor]="'#DA2370'"
						[gradient]="'#FFFFFF'"
						[textColor]="'#7083A0'"
						[leftImageHeight]="'13px'"
						[leftImageWidth]="'13px'"
						[cursor]="'pointer'"
						(buttonClick)="editGroup()">
						<div class="button">
							<span class="text">Edit Group</span>
						</div>
					</app-gradient-button>

					<app-gradient-button
						*ngIf="editMode && !searchInProgress"
						[size]="'editable'"
						[width]="'205px'"
						[minHeight]="'51px'"
						[buttonDownColor]="'#DA2370'"
						[gradient]="'#FFFFFF'"
						[textColor]="'#7083A0'"
						[leftImageHeight]="'13px'"
						[leftImageWidth]="'13px'"
						[cursor]="'pointer'"
						(buttonClick)="updateGroup()">
						<div class="button">
							<span class="text">Save Changes</span>
						</div>
					</app-gradient-button>

					<app-gradient-button
						*ngIf="createMode && !searchInProgress"
						[size]="'editable'"
						[width]="'205px'"
						[minHeight]="'51px'"
						[buttonDownColor]="'#DA2370'"
						[gradient]="'#FFFFFF'"
						[textColor]="'#7083A0'"
						[leftImageHeight]="'13px'"
						[leftImageWidth]="'13px'"
						[cursor]="'pointer'"
						(buttonClick)="createModeOff()">
						<div class="button">
							<span class="text">Cancel</span>
						</div>
					</app-gradient-button>

					<app-gradient-button
						*ngIf="createMode && !searchInProgress"
						[size]="'editable'"
						[width]="'205px'"
						[minHeight]="'51px'"
						[buttonDownColor]="'#DA2370'"
						[gradient]="'#FFFFFF'"
						[textColor]="'#7083A0'"
						[leftImageHeight]="'13px'"
						[leftImageWidth]="'13px'"
						[cursor]="'pointer'"
						(buttonClick)="createGroup()">
						<div class="button">
							<span class="text">Save Group</span>
						</div>
					</app-gradient-button>
				</div>
			</ng-container>

			<ng-template #renderCreate>
				<app-gradient-button
					*ngIf="!createMode"
					class="create-group-button"
					[size]="'editable'"
					[width]="'205px'"
					[minHeight]="'51px'"
					[fontSize]="'16px'"
					[cursor]="'pointer'"
					(buttonClick)="createModeOn()">
					+ New Student Group
				</app-gradient-button>
			</ng-template>
		</div>
	</div>
</div>
