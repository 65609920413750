import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'sp-glass-button',
	template: ` <div class="container" style="position:relative">
		<div class="overlay" [style.display]="hovered && !pressed ? 'block' : 'none'"></div>
		<button
			(click)="click.emit($event)"
			class="button"
			[style.padding]="padding"
			[attr.aria-label]="ariaLabel"
			(mousedown)="pressed = true"
			(mouseleave)="pressed = false; hovered = false"
			(mouseenter)="hovered = true"
			[ngClass]="{ pressed: pressed }">
			<ng-content></ng-content>
		</button>
	</div>`,
	styles: [
		`
			.button {
				position: relative;
				border-radius: 12px;
				box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);

				color: white;

				border: none;
				outline: none;
				font-family: inherit;
				font-size: inherit;
				background: none;
				cursor: pointer;
			}

			.button::before {
				content: '';
				position: absolute;
				border-radius: 12px;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #fff;
				mix-blend-mode: soft-light;
			}
			.button:hover,
			.button:hover::before {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%), #ffffff20;
				background-blend-mode: normal, soft-light;
			}
			.overlay {
				display: none;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #ffffff20;
				border-radius: 12px;
			}
		`,
	],
})
export class GlassButtonComponent {
	@Output()
	click = new EventEmitter<MouseEvent>();

	@Input()
	ariaLabel: string; // Allows parent component to set the aria-label

	@Input() padding: '20px 28px';

	pressed = false;
	hovered = false;
}
