import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScreenService } from '../services/screen.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { HallPass, NoFlyTime } from '../models';
import { EmergencyService } from '../services/emergency.service';
import { CreatePassDialogData } from '../create-hallpass-forms/create-hallpass-forms.component';
import { PassLimitInfo } from '../models/HallPassLimits';
import { States } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/locations-group-container.component';
import { FormFactor, MainHallPassFormComponent, Role } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { NoFlyTimeService } from '../services/no-fly-time.service';
import { HallPassesService } from '../services/hall-passes.service';
import { PassLimitService } from '../services/pass-limit.service';
import { ScheduleService } from '../services/schedule.service';
import { ScrollPositionService } from '../scroll-position.service';

@Component({
	selector: 'top-bar-create-pass',
	templateUrl: './top-bar-create-pass.component.html',
})
export class TopBarCreatePassButtonComponent implements OnInit, OnDestroy {
	@ViewChild('passButtonsContainer') passButtonsContainer: ElementRef<HTMLDivElement> | undefined;

	private destroyed$ = new Subject<void>();

	private createHallPassDialogRef: MatDialogRef<MainHallPassFormComponent> | undefined;

	// todo: simplify this bunch of variables
	noFlyTimes: NoFlyTime[] = [];
	currentNoFlyTimeIndex = -1;
	currentNoFlyEndTimeString: string | undefined;
	noFlyTimeActive = false;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		public screenService: ScreenService,
		public emergencyService: EmergencyService,
		private noFlyTimeService: NoFlyTimeService,
		private hallPassService: HallPassesService,
		private passLimitsService: PassLimitService,
		private scheduleService: ScheduleService,
		private scrollService: ScrollPositionService
	) {}

	private subscribeToNoFlyTimeData(): void {
		combineLatest([this.noFlyTimeService.noFlyTimes$, this.noFlyTimeService.noFlyTimeEnabled])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([noFlyTimes, noFlyTimeEnabled]) => {
				this.noFlyTimes = noFlyTimes;
				this.currentNoFlyTimeIndex = this.noFlyTimeService.checkNoFlyTimes(this.noFlyTimes);
				if (this.currentNoFlyTimeIndex >= 0 && noFlyTimeEnabled) {
					this.noFlyTimeActive = true;
					const currentEndTime = this.noFlyTimeService.momentize(this.noFlyTimes[this.currentNoFlyTimeIndex].end_time);
					this.currentNoFlyEndTimeString = this.noFlyTimeService.formatTimeString(currentEndTime);
				} else {
					this.noFlyTimeActive = false;
				}
			});
	}

	ngOnInit(): void {
		this.subscribeToNoFlyTimeData();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}
	async showMainForm(): Promise<void> {
		const data: Partial<CreatePassDialogData> = {
			forLater: false,
			forStaff: true,
			forInput: true,
			noFlyTimes: this.noFlyTimes,
			currentNoFlyTimeIndex: this.currentNoFlyTimeIndex,
			noFlyTimeActive: this.noFlyTimeActive,
			currentNoFlyEndTimeString: this.currentNoFlyEndTimeString,
			openedFrom: 'navbar',
			adminSelectedStudent: this.hallPassService.preselectedStudent$.getValue(),
		};
		// todo: get rid of this check. details: https://github.com/smartpass/smartpass-frontend/pull/394#discussion_r1838799801
		if (window.location.pathname.includes('main/student')) {
			combineLatest([
				this.passLimitsService.getStudentPassLimit(this.hallPassService.preselectedStudent$.getValue().id),
				this.passLimitsService.getRemainingLimits({ studentId: this.hallPassService.preselectedStudent$.getValue().id }),
				this.passLimitsService.getPassLimit(),
				this.scheduleService.getTermData$.pipe(take(1)),
				this.scheduleService.otherCurrentAgendaItems$.pipe(startWith([])),
			])
				.pipe(take(1))
				.subscribe((res) => {
					if (res[4].length === 1) {
						const passLimitInfo: PassLimitInfo = {
							max: res[0].passLimit,
							showPasses: !res[0].noLimitsSet && !res[0].isUnlimited && res[0].passLimit !== null,
							current: res[1].remainingPasses,
							frequency: res[2].pass_limit?.frequency,
							termData: res[3],
						};
						const selectedStudent = this.hallPassService.preselectedStudent$.getValue();
						selectedStudent.current_classes = [res[4][0].class];
						data.FORM_STATE = {
							step: 3,
							previousStep: 2,
							state: States.FromToWhere,
							fromState: 2.5,
							formMode: {
								role: Role.Teacher,
								formFactor: FormFactor.HallPass,
							},
							data: {
								selectedGroup: undefined,
								selectedStudents: [selectedStudent],
								direction: {
									from: res[4][0].class.room,
								},
								roomStudents: null,
								teacherRooms: undefined,
							},
							forInput: true,
							forLater: false,
							kioskMode: false,
							noFlyTimes: this.noFlyTimes || [],
							currentNoFlyTimeIndex: this.currentNoFlyTimeIndex >= 0 ? this.currentNoFlyTimeIndex : -1,
							noFlyTimeActive: this.noFlyTimeActive || false,
							currentNoFlyEndTimeString: this.currentNoFlyEndTimeString,
							extras: new BehaviorSubject<string>(''),
							openedFrom: 'navbar',
							passLimitInfo: passLimitInfo,
							lockOriginLocation: undefined, //this.dialogData.lockOriginLocation,
							lockDestinationLocation: undefined,
						};
						this.openCreateHallPassDialog(data);
					} else {
						this.openCreateHallPassDialog(data);
					}
				});
		} else {
			this.openCreateHallPassDialog(data);
		}
	}

	private openCreateHallPassDialog(data: Partial<CreatePassDialogData>): void {
		if (!this.passButtonsContainer) {
			// should never hit
			return;
		}
		const topBorderDelta = 5;
		const rightBorderDelta = 40;

		const passButtonContainerCoords = this.passButtonsContainer.nativeElement.getBoundingClientRect();
		const dialogTop = `${passButtonContainerCoords.bottom + topBorderDelta}px`;
		const dialogRight = `${window.innerWidth - passButtonContainerCoords.right + rightBorderDelta}px`;

		this.createHallPassDialogRef = this.dialog.open(MainHallPassFormComponent, {
			hasBackdrop: false,
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'cdk-overlay-transparent-backdrop',
			maxWidth: '100vw',
			position: {
				top: dialogTop,
				right: dialogRight,
			},
			data: data,
		});

		this.createHallPassDialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroyed$))
			.subscribe({
				next: (createdPasses) => {
					// todo: is this necessary?
					if (createdPasses?.length > 0) {
						const newPass = createdPasses[0];

						//scroll to top of the page after creating pass if staff
						if (newPass instanceof HallPass && this.router.url === '/main/passes') {
							this.scrollService.triggerScrollToTop();
						}
					}
				},
			});
	}
}
