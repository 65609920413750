import { Injectable } from '@angular/core';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlexPeriod } from '../flex-period.service';
import { Location, User } from '../models';
import { ColorProfile } from '../models/ColorProfile';
import { HttpService } from './http-service';

export type SchoolActivityState = 'flex_recurring' | 'scheduled' | 'canceled' | 'deleted' | 'archived';
export type SchoolActivityStatus = 'active' | 'archived';
type SchoolActivityInstanceState = 'scheduled' | 'canceled';
export interface CreateSchoolActivityReq {
	name: string;
	icon: string;
	description: string;
	location_id: number;
	max_attendees: number;
	public_event: boolean;
	flex_period_id: number;
	state: SchoolActivityState;
	status: SchoolActivityStatus;
	manager_ids?: number[];
}

export interface UpdateSchoolActivityRequest extends CreateSchoolActivityReq {
	id: number;
}

export interface SchoolActivity {
	id?: number;
	icon: string;
	name: string;
	description: string;
	user_id: number;
	location_id: number;
	color_profile?: ColorProfile;
	flex_period_id?: number;
	max_attendees: number;
	public_event: boolean;
	teacher_name?: string;
	profile_picture?: string;
	created_at?: string;
	updated_at?: string;
	deleted_at?: string;
	state: SchoolActivityState;
	status: SchoolActivityStatus;
	managers: User[];
}
export interface SchoolActivityRow extends SchoolActivity {
	location_icon: string;
	location_name: string;
	location?: Location;
	flex_period_name: string;
}
export interface SchoolActivityInstance {
	id: number;
	start_time: string;
	end_time: string;
	activity_id: number;
	user_id: number;
	school_id: number;
	created_at: string;
	updated_at: string;
	current_num_attendees?: number;
	state: SchoolActivityInstanceState;
	color_profile?: ColorProfile;
	selected?: boolean;
	flex_period_id?: number;
	flex_period_name?: string;
	activity_name?: string;
	deleted_at?: string;
}

export interface SchoolActivityAttendee {
	id: number;
	activity_id: number;
	activity_instance_id: number;
	flex_period_id: number;
	user_id: number;
	pass_id: number;
	created_at: string;
	updated_at: string;
	state: string;
	school_id: number;
	assigner_id: number;
	start_time?: Date;
}
type GetActivitiesReq = {
	start_time: string;
	end_time: string;
	activity_id?: number;
};
export type MaxCapacityValues = {
	errorMessage: string;
	maxCapacityString: string;
	maxCapacityNumber?: number;
};
@Injectable({
	providedIn: 'root',
})
export class SchoolActivityService {
	constructor(private http: HttpService) {}

	CreateActivity(body: CreateSchoolActivityReq): Observable<SchoolActivity> {
		return this.http.post<SchoolActivity>('v2/school_activities/add', body, undefined, false);
	}

	DeleteActivity(id: number): Observable<Record<string, never>> {
		return this.http.post<Record<string, never>>('v2/school_activities/delete', { id: id }, undefined, false);
	}

	RemoveAttendeeFromActivityInstance(antendeeRecordId: number): Observable<null> {
		return this.http.post<null>('v2/school_activities/attendee/delete', { id: antendeeRecordId }, undefined, false);
	}

	GetActivities(flexPeriodId?: number, state?: SchoolActivityState, status?: SchoolActivityStatus): Observable<SchoolActivity[]> {
		return this.http.post<SchoolActivity[]>(
			'v2/school_activities/list',
			{ flex_period_id: flexPeriodId, state: state, status: status },
			undefined,
			false
		);
	}

	GetActivitiesByStatus(status: SchoolActivityStatus, flexPeriodId?: number): Observable<SchoolActivity[]> {
		return this.http.post<SchoolActivity[]>('v2/school_activities/list', { flex_period_id: flexPeriodId, status: status }, undefined, false);
	}

	GetActivityById(schoolActivityId: number): Observable<SchoolActivity[]> {
		return this.http.post<SchoolActivity[]>('v2/school_activities/list', { school_activity_id: schoolActivityId }, undefined, false);
	}

	SignUpForActivity(userId: number, activityId: number, scheduledDate: Date, instanceId = 0): Observable<SchoolActivityAttendee> {
		return this.http.post<SchoolActivityAttendee>(
			'v2/school_activities/attendee/add',
			{ user_id: userId, activity_id: activityId, scheduled_date: scheduledDate.toISOString(), activity_instance_id: instanceId },
			undefined,
			false
		);
	}

	BulkSignUpForActivity(userIds: number[], activityId: number, scheduledDate: Date, instanceId = 0): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>(
			'v2/school_activities/attendee/bulk_add',
			{ user_ids: userIds, activity_id: activityId, scheduled_date: scheduledDate.toISOString(), activity_instance_id: instanceId },
			undefined,
			false
		);
	}

	BulkSignOutForActivity(attendeeIds: number[]): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>('v2/school_activities/attendee/bulk_delete', { attendee_ids: attendeeIds }, undefined, false);
	}

	CreateActivityInstance(
		start: Date,
		end: Date,
		activityId: number,
		state: SchoolActivityInstanceState = 'scheduled'
	): Observable<SchoolActivityInstance> {
		return this.http.post<SchoolActivityInstance>(
			'v2/school_activities/instances/add',
			{ start_time: start.toISOString(), end_time: end.toISOString(), activity_id: activityId, state: state },
			undefined,
			false
		);
	}

	UpdateActivity(activity: Partial<SchoolActivity>): Observable<SchoolActivity> {
		return this.http.post<SchoolActivity>('v2/school_activities/update', activity, undefined, false);
	}

	UpdateActivityInstance(instance: Partial<SchoolActivityInstance>): Observable<SchoolActivityInstance> {
		return this.http.post<SchoolActivityInstance>('v2/school_activities/instances/update', instance, undefined, false);
	}

	DeleteActivityInstance(id: number): Observable<Record<string, never>> {
		return this.http.post<Record<string, never>>('v2/school_activities/instances/delete', { id: id }, undefined, false);
	}

	GetAttendeeRecordForStudent(instanceId: number, studentId: number, start_time: Date): Observable<SchoolActivityAttendee | null> {
		return this.http
			.post<SchoolActivityAttendee[]>(
				'v2/school_activities/attendee/list',
				{ activity_instance_id: instanceId, user_id: studentId, start_time: start_time.toISOString() },
				undefined,
				false
			)
			.pipe(map((l) => (l.length > 0 ? l[0] : null)));
	}

	GetAttendeeRecordsForTimePeriod(from: Date, to: Date): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>(
			'v2/school_activities/attendee/list',
			{ start_time: from.toISOString(), end_time: to.toISOString() },
			undefined,
			false
		);
	}

	GetActivityInstances(from: Date, to: Date, activityId?: number): Observable<SchoolActivityInstance[]> {
		const req: GetActivitiesReq = { start_time: from.toISOString(), end_time: to.toISOString() };
		if (activityId) {
			req.activity_id = activityId;
		}
		return this.http.post<SchoolActivityInstance[]>('v2/school_activities/instances/list', req, undefined, false);
	}

	GetStartedActivityInstances(locationId: number, bufferMinutes: number): Observable<SchoolActivityInstance[]> {
		return this.http.post<SchoolActivityInstance[]>(
			'v2/school_activities/instances/location/list',
			{ location_id: locationId, buffer: bufferMinutes, include_past_instances: false },
			undefined,
			false
		);
	}

	GetAttendeesForInstance(instanceId: number, startTime: string): Observable<SchoolActivityAttendee[]> {
		return this.http.post<SchoolActivityAttendee[]>(
			'v2/school_activities/attendee/list',
			{ activity_instance_id: instanceId, start_time: startTime },
			undefined,
			false
		);
	}

	GetActivityInstancesByPeriodAndFillExtra(data: {
		day: Date;
		activities: SchoolActivity[];
		flexPeriod: FlexPeriod;
		timezone: string;
	}): Observable<SchoolActivityInstance[]> {
		const beginningOfDay = new Date(data.day);
		beginningOfDay.setHours(0, 0, 0, 0);
		const localBeginningOfDay = new Date(beginningOfDay.toLocaleString());
		const endOfDay = new Date(data.day);
		endOfDay.setHours(23, 59, 59, 999);
		const localEndOfDay = new Date(endOfDay.toLocaleString());

		const startDateTime = new Date(data.day);
		const endDateTime = new Date(data.day);
		if (data && data.flexPeriod && data.flexPeriod.schedules) {
			data.flexPeriod.schedules.forEach((schedule) => {
				if (schedule.days_of_week.find((day) => day === startDateTime.getDay())) {
					startDateTime.setHours(schedule.start_hour, schedule.start_minute);
					endDateTime.setHours(schedule.end_hour, schedule.end_minute);
				}
			});
		}

		const filteredActivities = data.activities.filter((activity) => activity.flex_period_id === data.flexPeriod?.id);

		return this.http
			.post<SchoolActivityInstance[]>(
				'v2/school_activities/instances/list',
				{ start_time: localBeginningOfDay.toISOString(), end_time: localEndOfDay.toISOString() },
				undefined,
				false
			)
			.pipe(
				map((instances) => {
					let studentInstances: SchoolActivityInstance[] = [];

					filteredActivities.forEach((activity) => {
						const foundInstance = instances.find((instance) => instance.activity_id === activity.id);
						if (foundInstance) {
							studentInstances.push(foundInstance);
						} else if (activity.state === 'flex_recurring') {
							const newInstance: SchoolActivityInstance = {
								id: 0,
								start_time: this.utcDateTimeZone(startDateTime, data.timezone).toISOString(),
								end_time: this.utcDateTimeZone(endDateTime, data.timezone).toISOString(),
								activity_id: activity.id || 0,
								user_id: 0,
								school_id: 0,
								created_at: new Date().toDateString(),
								updated_at: new Date().toDateString(),
								state: 'scheduled',
								current_num_attendees: 0,
							};

							studentInstances.push(newInstance);
						}
					});

					studentInstances = studentInstances.filter((instance) => instance.state !== 'canceled');

					return studentInstances;
				})
			);
	}

	GetActivityInstancesByIdAndFillExtra(data: {
		from: Date;
		to: Date;
		activity: SchoolActivity;
		timezone: string;
		flexPeriod?: FlexPeriod;
	}): Observable<SchoolActivityInstance[]> {
		return this.http
			.post<SchoolActivityInstance[]>(
				'v2/school_activities/instances/list',
				{ start_time: data.from.toISOString(), end_time: data.to.toISOString(), activity_id: data.activity?.id },
				undefined,
				false
			)
			.pipe(
				map((instances) => {
					if (data.activity?.state !== 'flex_recurring') {
						return instances.sort((a, b) => {
							const startTimeA = new Date(a.start_time).getTime();
							const startTimeB = new Date(b.start_time).getTime();
							return startTimeA - startTimeB;
						});
					}

					const filledInstances: SchoolActivityInstance[] = [];

					// Iterate through each day in the range
					const currentDate = new Date(data.from);
					while (currentDate <= data.to) {
						const dayOfWeek = currentDate.getDay();
						const matchingSchedules = data.flexPeriod?.schedules?.filter((schedule) => schedule.days_of_week.includes(dayOfWeek));

						// Add instances for each matching schedule
						if (matchingSchedules && matchingSchedules.length > 0) {
							matchingSchedules.forEach((schedule) => {
								const startDateTime = new Date(currentDate);
								startDateTime.setHours(schedule.start_hour, schedule.start_minute, 0, 0);

								const endDateTime = new Date(currentDate);
								endDateTime.setHours(schedule.end_hour, schedule.end_minute, 0, 0);

								const existingInstance = instances.find((instance) => {
									const instanceStart = new Date(instance.start_time);
									return instance.activity_id === data.activity.id && instanceStart.toDateString() === startDateTime.toDateString();
								});

								// Add instance only if it doesn't already exist for the day
								if (!existingInstance) {
									const newInstance: SchoolActivityInstance = {
										id: 0,
										start_time: this.utcDateTimeZone(startDateTime, data.timezone).toISOString(),
										end_time: this.utcDateTimeZone(endDateTime, data.timezone).toISOString(),
										activity_id: data.activity.id || 0,
										user_id: 0,
										school_id: 0,
										created_at: new Date().toDateString(),
										updated_at: new Date().toDateString(),
										state: 'scheduled',
										current_num_attendees: 0,
									};

									filledInstances.push(newInstance);
								}
							});
						}

						// Move to the next day
						currentDate.setDate(currentDate.getDate() + 1);
					}

					// Remove cancelled instances
					instances = instances.filter((instance) => instance.state !== 'canceled');

					// Combine existing and newly created instances
					const allInstances = [...instances, ...filledInstances];

					// Sort instances by start_time
					allInstances.sort((a, b) => {
						const startTimeA = new Date(a.start_time).getTime();
						const startTimeB = new Date(b.start_time).getTime();
						return startTimeA - startTimeB;
					});

					return allInstances;
				})
			);
	}

	private utcDateTimeZone(time: Date, zone: string): Date {
		if (zone) {
			return zonedTimeToUtc(time, zone);
		}
		return time;
	}

	public handleMaxCapacity(isFocused: boolean, fieldValue: string | number | undefined): MaxCapacityValues {
		const numberValue = fieldValue ? parseInt(fieldValue.toString()) : null;
		if (isFocused) {
			const values: MaxCapacityValues = { errorMessage: '', maxCapacityString: '' };
			if (numberValue) {
				values.maxCapacityNumber = numberValue;
			}
			return values;
		} else {
			if (fieldValue === '0' || fieldValue === 0) {
				return { errorMessage: 'Please add a max capacity greater than “0”.', maxCapacityString: '' };
			}
			if (fieldValue === undefined || fieldValue === '') {
				return { errorMessage: 'Please add a max capacity for this activity.', maxCapacityString: '' };
			}
			return {
				errorMessage: '',
				maxCapacityString: `${fieldValue} student${numberValue !== 1 ? 's' : ''}`,
			};
		}
	}
}
