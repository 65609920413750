<div class="wrapper" [style.background-color]="darkTheme.getBackground('high', true)">
	<div class="options">
		<div
			#opt
			class="option-container"
			*ngFor="let option of options"
			appCrossPointerEventTarget
			(pointerCancelEvent)="opt.pressed = false"
			(pointerDownEvent)="opt.pressed = true"
			(mouseleave)="opt.pressed = false">
			<a
				class="option"
				[href]="option.link"
				target="_blank"
				[style.background]="option.hovered ? (opt.pressed ? 'rgba(127, 135, 157, .15)' : 'rgba(127, 135, 157, .1)') : 'transparent'"
				*ngIf="option.hasShow && option.link"
				(mouseenter)="option.hovered = true"
				(mouseleave)="option.hovered = false">
				<img class="icon" [src]="getIcon(option.image, null) | resolveAsset" />
				<div class="name" [style.color]="getColor('#FFFFFF', '#7f879d')">{{ option.name }}</div>
			</a>
			<a
				class="option"
				id="click-to-chat__cta--text"
				appCrossPointerEventTarget
				[style.background]="option.hovered ? (opt.pressed ? 'rgba(127, 135, 157, .15)' : 'rgba(127, 135, 157, .1)') : 'transparent'"
				(pointerClickEvent)="!option.isNolt ? openHubspot() : null"
				[ngClass]="{ 'nolt-css-selector': option.isNolt }"
				*ngIf="option.hasShow && !option.link"
				(mouseenter)="option.hovered = true"
				(mouseleave)="option.hovered = false">
				<img class="icon" [src]="getIcon(option.image, null) | resolveAsset" />
				<div class="name" [style.color]="getColor('#FFFFFF', '#7f879d')">{{ option.name }}</div>
			</a>
			<mat-divider [style.margin]="'8px 0'" *ngIf="option.image === 'Chat'"></mat-divider>
		</div>
	</div>
</div>
