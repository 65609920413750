import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HallPass } from '../models/HallPass';
import { HallPassesService } from '../services/hall-passes.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimeService } from '../services/time.service';

@Component({
	selector: 'sp-student-active-pass',
	templateUrl: './student-active-pass.component.html',
	styleUrls: ['./student-active-pass.component.scss'],
})
export class StudentActivePassComponent implements OnInit, OnChanges {
	private destroy$ = new Subject<void>();

	@Input()
	heartbeat: Subject<void>;

	@Input()
	pass: HallPass;

	@Input() width = '460px';
	@Input() height = '112px';
	@Input() endByTeacher = false;

	@Output() openPassModal: EventEmitter<HallPass> = new EventEmitter<HallPass>();

	constructor(private hallPassService: HallPassesService, private timeService: TimeService) {}

	private overtimeBackground = 'linear-gradient(139deg, #E32C66 0%, #CB1B53 100%)';
	private normalBackground;
	background: string;
	progress: number;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.pass && !!changes.pass.currentValue) {
			this.setup(changes.pass.currentValue);
			this.updateProgress(changes.pass.currentValue);
		}
	}

	ngOnInit(): void {
		this.heartbeat.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateProgress(this.pass);
		});
	}

	updateProgress(pass: HallPass) {
		const denom = pass.expiration_time.getTime() - pass.start_time.getTime();
		const num = pass.expiration_time.getTime() - this.timeService.nowDate().getTime();
		if (num < 0) {
			this.progress = 0;
			this.background = this.overtimeBackground;
			return;
		}
		this.progress = num / denom;
		this.background = this.normalBackground;
	}

	setup(pass: HallPass) {
		this.hallPassService
			.getPinnable(pass.destination)
			.pipe(
				filter((pin) => !!pin),
				takeUntil(this.destroy$)
			)
			.subscribe((pin) => {
				const [color1, color2] = pin.color_profile.gradient_color.split(',');
				// Do something with the gradient color
				this.normalBackground = `radial-gradient(114.35% 114.35% at 73.62% 81.35%, ${color1} 0%, ${color2} 100%)`;
				this.background = this.normalBackground;
			});
	}

	endPass(event) {
		event.stopImmediatePropagation();
		if (this.pass.needs_check_in && !this.endByTeacher) {
			this.openPassModal.emit(this.pass);
		} else {
			this.hallPassService.endPassRequest(this.pass.id);
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
