import { Component, Input, OnInit } from '@angular/core';
import { ClasslinkClassInfo, CleverClassInfo, GetExternalInfoResp, GoogleClassInfo } from '../services/classes.service';

@Component({
	selector: 'sp-class-sync-info-tooltip',
	templateUrl: './class-sync-info-tooltip.component.html',
	styleUrls: ['./class-sync-info-tooltip.component.scss'],
})
export class ClassSyncInfoTooltipComponent implements OnInit {
	@Input() syncData: GetExternalInfoResp;
	cleverSyncInfo: CleverClassInfo;
	classlinkSyncInfo: ClasslinkClassInfo;
	googleSyncInfo: GoogleClassInfo;

	ngOnInit(): void {
		this.cleverSyncInfo = this.syncData?.clever_class;
		this.classlinkSyncInfo = this.syncData?.classlink_class;
		this.googleSyncInfo = this.syncData?.google_class;
	}
}
