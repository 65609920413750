import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { bumpIn } from '../../animations';

@Component({
	selector: 'app-pager-button',
	templateUrl: './pager-button.component.html',
	styleUrls: ['./pager-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [bumpIn],
})
export class PagerButtonComponent implements OnInit, OnDestroy {
	@ViewChild('iconButtonRef', { static: true }) iconButtonRef: ElementRef;

	@Input() srcString = '';
	@Input() backgroundColor = '';
	@Input() pressed = false;
	@Input() eventBubbling = false;
	@Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	hovered = false;
	down = false;
	selected = false;

	private destroyer$: Subject<any> = new Subject();

	constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		fromEvent(document.body, 'click')
			.pipe(
				delay(200),
				filter(() => !!this.selected)
			)
			.subscribe(() => {
				this.hovered = false;
				this.selected = false;
				this.cdr.detectChanges();
			});
	}

	onClick(evt): void {
		this.pressed = !this.pressed;
		this.selected = !this.selected;
		if (!this.eventBubbling) {
			evt.stopPropagation();
		}
		this.clickEvent.emit(evt);
	}

	ngOnDestroy(): void {
		this.destroyer$.next();
		this.destroyer$.complete();
	}
}
