import { Component, ContentChild, ContentChildren, Directive, Input, OnInit, QueryList, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DarkThemeSwitch } from '../../../dark-theme-switch';
import { PassCollectionV2Module } from '../pass-collection-v2.module';

@Directive({
	selector: 'sp-collection-title',
})
export class PassCollectionTitle {}

@Directive({
	selector: 'sp-collection-header-action',
})
export class PassCollectionHeaderAction {}

@Directive({
	selector: '[collection-content]',
})
export class PassCollectionContent {}

@Directive({
	selector: 'sp-collection-empty-state',
})
export class PassCollectionEmptyState {}

/**
 * Responsible for showing a collection of pass-like tiles. Sorting can be enabled.
 * This component should not be used for any other collection besides pass-like data structures.
 */
@Component({
	selector: 'sp-pass-collection-v2',
	templateUrl: './pass-collection-v2.component.html',
	styleUrls: ['./pass-collection-v2.component.scss'],
})
export class PassCollectionV2Component implements OnInit {
	// If columns are not defined, the component will apply a flex-wrap by default. This value should not be more than 5.
	// This component assumes the width of the parent
	@Input() columns: number;

	// In the event that a custom css grid or flexbox is required, enable this input
	// The developer is responsible for providing their own container this component's parent
	@Input() customGrid = false;

	// Determine whether the sort icon should be displayed

	@ContentChild(PassCollectionTitle) title: PassCollectionTitle;
	@ContentChild(PassCollectionHeaderAction) action: PassCollectionHeaderAction;
	@ContentChild(PassCollectionEmptyState) emptyState: PassCollectionEmptyState;
	@ContentChild('[custom-collection-wrapper]', { read: TemplateRef }) customContent!: TemplateRef<HTMLElement>;
	@ContentChildren(PassCollectionContent) passes: QueryList<PassCollectionContent>;

	get _showTitle(): boolean {
		return !!this.title && (this.passes.toArray().length > 0 || this._showEmptyState);
	}

	get _isCollectionEmpty(): boolean {
		return this.passes.toArray().length === 0 || (this.customGrid && !this.customContent);
	}

	get _showEmptyState(): boolean {
		return this.emptyState && this._isCollectionEmpty;
	}

	_gridTemplateColumns: string;

	constructor(public dialog: MatDialog, public darkTheme: DarkThemeSwitch) {}

	ngOnInit(): void {
		// the ui can either be grid (columns between 2 and 5) or flex
		if (this.columns > 5) {
			this.columns = 5;
		} else if (this.columns <= 0) {
			this.columns = null; // effectively, set to a value that the template will ignore
		}

		this._gridTemplateColumns = new Array(this.columns || 0).fill('1fr').join(' ');
	}
}
