import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'sp-edlink-trampoline',
	templateUrl: './edlink-trampoline.component.html',
	styleUrls: ['./edlink-trampoline.component.scss'],
})
export class EdlinkTrampolineComponent implements OnInit {
	uhOhMessage: boolean;

	constructor(private route: ActivatedRoute) {}

	// http://localhost:4200/?integration_id=0ce4b84f-8d59-47c7-bd9f-f9c7f6761c6c

	ngOnInit(): void {
		const integration_id = this.route.snapshot.queryParams['integration_id'];
		if (integration_id) {
			window.opener.postMessage(`app.smartpass.new_edlink_integration:${integration_id}`, '*');
			window.opener.focus();
			window.close();
			console.log('posted message');
		}

		setTimeout(() => {
			this.uhOhMessage = true;
		}, 3000);
	}
}
