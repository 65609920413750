import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bumpIn } from '../animations';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-round-button',
	templateUrl: './round-button.component.html',
	styleUrls: ['./round-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [bumpIn],
})
export class RoundButtonComponent implements OnInit {
	@Input() icon: string;
	@Input() fullIcon: string;
	@Input() width = 44;
	@Input() iconOffset = 0;
	@Input() backgroundColor = '#FFFFFF';
	@Input() borderRadius = '50%';
	@Input() bordorColor = '#D4D9E2';
	@Input() iconWidth = 20;
	@Input() size: 'regular' | 'small' = 'regular';

	@Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();

	pressed: boolean;
	hovered: boolean;
	selected: boolean;

	destroy$: Subject<any> = new Subject<any>();

	ngOnInit(): void {
		if (this.size === 'regular') {
			this.width = 40;
		} else if (this.size === 'small') {
			this.width = 34;
		}
		// fromEvent(document.body, 'click').pipe(delay(200), filter(() => !!this.selected)).subscribe(() => {
		//   this.hovered = false;
		//   this.selected = false;
		// });
	}

	getBackground(backgroundColor: string): string {
		if (this.hovered) {
			if (this.pressed) {
				if (this.selected) {
					return this.blendColors(backgroundColor, '#C6ECDF');
				}
				return this.blendColors(backgroundColor, '#EAEDF1');
			}
			if (this.selected) {
				return this.blendColors(backgroundColor, '#D9F4EB');
			}
			return this.blendColors(backgroundColor, '#F0F2F5');
		}
		if (this.selected) {
			return this.blendColors(backgroundColor, '#E5F7F1');
		}
		return backgroundColor;
	}

	// Function to blend two colors
	blendColors(color1: string, color2: string): string {
		const factor = 0.5; // You can adjust this factor based on how much you want the new color to be influenced by each color
		const [r1, g1, b1] = this.hexToRgb(color1);
		const [r2, g2, b2] = this.hexToRgb(color2);
		const r = Math.round(r1 * factor + r2 * (1 - factor));
		const g = Math.round(g1 * factor + g2 * (1 - factor));
		const b = Math.round(b1 * factor + b2 * (1 - factor));
		return this.rgbToHex(r, g, b);
	}

	// Function to convert hex color to RGB
	hexToRgb(hex: string): number[] {
		const bigint = parseInt(hex.substring(1), 16);
		return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
	}

	// Function to convert RGB color to hex
	rgbToHex(r: number, g: number, b: number): string {
		return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
	}
}
