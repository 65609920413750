<div class="frames">
	<div class="frame frame__1">
		<img [src]="'./assets/Moving Tiles/Tile 1.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 2.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 3.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 4.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 5.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 6.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 7.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 8.svg' | resolveAsset" alt="Tile.svg" />
	</div>

	<div class="frame frame__2">
		<img [src]="'./assets/Moving Tiles/Tile 1.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 2.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 3.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 4.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 5.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 6.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 7.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 8.svg' | resolveAsset" alt="Tile.svg" />
	</div>
	<div class="frame frame__3">
		<img [src]="'./assets/Moving Tiles/Tile 1.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 2.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 3.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 4.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 5.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 6.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 7.svg' | resolveAsset" alt="Tile.svg" />
		<img [src]="'./assets/Moving Tiles/Tile 8.svg' | resolveAsset" alt="Tile.svg" />
	</div>
</div>
