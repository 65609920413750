import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/User';

@Pipe({
	name: 'userInArray',
})
export class UserInArrayPipe implements PipeTransform {
	transform(user: User, userArray: User[]): boolean {
		return userArray?.some((u) => u.id === user.id);
	}
}
