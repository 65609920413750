import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	PassCollectionTitle,
	PassCollectionHeaderAction,
	PassCollectionContent,
	PassCollectionEmptyState,
	PassCollectionV2Component,
} from './pass-collection-v2/pass-collection-v2.component';

@NgModule({
	declarations: [PassCollectionTitle, PassCollectionHeaderAction, PassCollectionContent, PassCollectionEmptyState, PassCollectionV2Component],
	imports: [CommonModule],
	exports: [PassCollectionTitle, PassCollectionHeaderAction, PassCollectionContent, PassCollectionEmptyState, PassCollectionV2Component],
})
export class PassCollectionV2Module {}
