import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolActivity, SchoolActivityInstance } from '../../services/school-activity.service';

@Component({
	selector: 'sp-activity',
	templateUrl: './activity.component.html',
	styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
	@Input() activity: SchoolActivity;
	@Input() instance: SchoolActivityInstance;
	@Input() isSelected = false;
	@Output() selected: EventEmitter<SchoolActivityInstance> = new EventEmitter<SchoolActivityInstance>();
	isFull = false;
	studentCapacity = 'No capacity limits';

	ngOnInit(): void {
		this.isFull = this.activity?.max_attendees > 0 && this.instance.current_num_attendees >= this.activity?.max_attendees;
		if (this.activity?.max_attendees) {
			this.studentCapacity = `${this.instance.current_num_attendees}/${this.activity.max_attendees} students`;
		}
	}

	onSelectActivity(): void {
		this.isSelected = !this.isSelected;
		this.selected.emit(this.instance);
	}
}
