import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ROLES, User } from '../../models/User';
import { SPClassUser } from '../../services/classes.service';
import { UserService } from '../../services/user.service';
import { SpInputComponent } from '../../sp-input/sp-input.component';

@Component({
	selector: 'sp-edit-teacher-form',
	templateUrl: './edit-teacher-form.component.html',
	styleUrls: ['./edit-teacher-form.component.scss'],
})
export class EditTeacherFormComponent implements OnInit {
	@Input() teachers: SPClassUser[] = [];
	@Input() isAutoHeight = false;
	@Input() isInputFocus = true;
	@Input() initialSelection: User[] = [];
	@Input() editTeachersForm: FormGroup = new FormGroup({});
	@Input() description = 'Teachers that can view and manage this class.';
	@Input() resourceName = 'class';

	@Output() editTeachers: EventEmitter<User[]> = new EventEmitter<User[]>();

	@ViewChild('spInputComponent', { static: false }) spInputComponent: SpInputComponent | undefined;

	teacherList: User[] = [];
	existingTeachers: User[] = [];
	newSelectedTeachers: User[] = []; // used only for template indicator
	private destroy$ = new Subject<void>();

	constructor(private userService: UserService, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		if (!this.editTeachersForm.controls.teachers) {
			this.editTeachersForm.addControl('teachers', new FormControl(''));
		}
		this.existingTeachers = this.teachers.map((t) => t.user);
		this.editTeachersForm.valueChanges
			.pipe(
				tap((formChanges) => {
					if (!formChanges.teachers.length) {
						this.teacherList = [];
					}
				}),
				filter((formChanges) => {
					return formChanges.teachers.length;
				}),
				switchMap((formChanges) => {
					return this.userService
						.searchProfile(ROLES.Teacher, 100, formChanges.teachers)
						.pipe(map((responses) => responses.results.map((r) => User.fromJSON(r))));
				}),
				takeUntil(this.destroy$)
			)
			.subscribe((results) => {
				this.teacherList = results;
			});
	}

	onSelectTeacher(selection: User): void {
		if (this.teachers?.some((u) => u.user.id === selection.id)) {
			return;
		}
		this.teachers.push(new SPClassUser(selection, ''));
		this.newSelectedTeachers.push(selection);
		this.editTeachersForm.get('teachers')?.setValue('');
		this.editTeachers.emit(this.teachers.map((t) => t.user));
		this.teacherList = [];
		this.spInputComponent?.onFocus(true);
		this.cdr.detectChanges();
	}

	removeSelection(selection: User): void {
		this.teachers = this.teachers.filter((s) => s.user.id !== selection.id);
		this.newSelectedTeachers = this.newSelectedTeachers.filter((s) => s.id !== selection.id);
		this.editTeachers.emit(this.teachers.map((t) => t.user));
		this.spInputComponent?.onFocus(true);
		this.cdr.detectChanges();
	}

	onSearchBlur(): void {
		setTimeout(() => {
			this.teacherList = [];
		}, 90);
	}
}
