import { SimpleChanges } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'sp-no-pass-message',
	templateUrl: './no-pass-message.component.html',
	styleUrls: ['./no-pass-message.component.scss'],
})
export class NoPassMessageComponent implements OnInit {
	@Input() messageList: { emoji: string; message: string }[];
	@Input() refreshMessage: boolean;

	imageLoaded = false;
	emptyPassMessage: { emoji: string; message: string };
	private previousIndex: number | null = null;

	ngOnInit(): void {
		this.getEmptyPassMessage();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.refreshMessage && changes.refreshMessage.currentValue) {
			this.imageLoaded = false;
			this.getEmptyPassMessage();
		}
	}

	getEmptyPassMessage(): void {
		let randomIndex: number;
		do {
			randomIndex = Math.floor(Math.random() * this.messageList.length);
		} while (randomIndex === this.previousIndex);

		this.emptyPassMessage = this.messageList[randomIndex];
		this.previousIndex = randomIndex;
	}

	onImageLoaded(): void {
		this.imageLoaded = true;
	}

	setDefaultImage(event): void {
		this.imageLoaded = true;
		event.target.src = './assets/no-pass-emojis/beach_with_umbrella.png';
	}
}
