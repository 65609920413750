import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';
import { KeyboardShortcutsService } from 'app/services/keyboard-shortcuts.service';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Button } from '../models';

@Component({
	selector: 'sp-nav-tabs',
	templateUrl: './nav-tabs.component.html',
	styleUrls: ['./nav-tabs.component.scss'],
})
export class NavTabsComponent implements OnInit, OnDestroy {
	@Input() disableKeyboardShortcuts = false;

	private destroyed$ = new Subject<void>();

	buttons: Button[] = [
		{
			title: 'Home',
			route: '/main/passes',
			imgUrl: 'Nav School',
			requiredRoles: ['_profile_teacher', 'access_passes', '_profile_student'],
			hidden: false,
		},
		{
			title: 'Hall Monitor',
			route: '/main/hallmonitor',
			imgUrl: 'Nav Hall Monitor',
			requiredRoles: ['_profile_admin', 'access_hall_monitor'],
			hidden: false,
		},
		{
			title: 'Calendar',
			route: '/main/passes-calendar',
			imgUrl: 'Nav Calendar',
			requiredRoles: ['_profile_teacher', 'access_teacher_room', '_profile_student'],
			hidden: false,
		},
		{
			title: 'Activities',
			route: '/main/activities',
			imgUrl: 'Activities',
			requiredRoles: ['_profile_teacher'],
			hidden: !this.featureService.isFeatureEnabledV2(FLAGS.ActivityManagement),
		},
		{
			title: 'Admin',
			route: '/admin',
			imgUrl: 'Nav Admin',
			requiredRoles: ['_profile_admin'],
			hidden: !this.featureService.isFeatureEnabledV2(FLAGS.CombinedTeacherAdmin),
		},
	];

	selectedRoute$ = this.router.events.pipe(
		filter((event): event is NavigationEnd => event instanceof NavigationEnd),
		map((event) => event.urlAfterRedirects),
		startWith(this.router.url),
		distinctUntilChanged(),
		map((url) => url.split('?')[0]),
		takeUntil(this.destroyed$)
	);

	roles = this.userService.userJSON$.pipe(
		map((user) => user.roles),
		takeUntil(this.destroyed$)
	);

	keyboardShortcuts$ = this.roles.pipe(
		switchMap((userRoles) =>
			this.shortcutsService.onPressKeyEvent$.pipe(
				pluck('key'),
				// Only handle single digit key presses, to navigate to that tab
				map((key) => parseInt(key[0])),
				filter((key) => !isNaN(key)),
				map<number, [number, string[]]>((key) => [key, userRoles])
			)
		),
		takeUntil(this.destroyed$)
	);

	constructor(
		protected router: Router,
		private featureService: FeatureFlagService,
		private userService: UserService,
		private shortcutsService: KeyboardShortcutsService
	) {}

	ngOnInit(): void {
		this.keyboardShortcuts$.subscribe(([key, userRoles]) => {
			if (this.disableKeyboardShortcuts) {
				return;
			}
			const visibleButtons = this.buttons.filter((button) => this.isVisible(button, userRoles));
			const targetButton = visibleButtons[key - 1];
			if (targetButton) {
				this.router.navigateByUrl(targetButton.route);
			}
		});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	protected isVisible(button: Button, userRoles: string[]): boolean {
		return !button.hidden && this.hasAnyRole(button.requiredRoles, userRoles);
	}

	protected hasAnyRole(desiredRoles: string[], userRoles: string[] | null): boolean {
		return userRoles != null && desiredRoles.some((role) => userRoles.includes(role));
	}
}
