import * as React from 'react';
import { ReactWrapperComponent } from '../../../../../angular-react';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FlexPeriod } from '../../../flex-period.service';
import { useActivityInstancesByPeriodAndFillExtra } from '../../hooks';
import { useContainerHeight, useFlexStats, useNumAttendees } from './hooks';
import { relativeDate } from './lib';
import { exportInstanceByFlex, downloadBlob } from '../../lib/network';
import { HeaderStats, DateNavigation, ActivitiesHeader, ActivityBoxes, TitleHeader } from './components';

interface IFlexStatsProps {
	flexPeriod: FlexPeriod;
	numStudents: number;
	schoolId: number;
	timeZone: string;
	deleteFlexPeriod: () => void;
}

@Component({
	selector: 'app-flex-stats',
	exportAs: 'flexStats',
	template: `<FlexStats
		#reactNode
		[flexPeriod]="flexPeriod"
		[schoolId]="schoolId"
		[numStudents]="numStudents"
		[timeZone]="timeZone"
		(deleteFlexPeriod)="(deleteFlexPeriod)"></FlexStats>`,
	styles: ['react-renderer'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexStatsComponent extends ReactWrapperComponent {
	@ViewChild('reactNode', { static: true }) protected reactNodeRef: ElementRef;

	@Input() flexPeriod: IFlexStatsProps['flexPeriod'];
	@Input() schoolId: IFlexStatsProps['schoolId'];
	@Input() numStudents: IFlexStatsProps['numStudents'];
	@Input() timeZone: IFlexStatsProps['timeZone'];
	@Input() deleteFlexPeriod: IFlexStatsProps['deleteFlexPeriod'];

	constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef, renderer: Renderer2) {
		super(elementRef, changeDetectorRef, renderer, { setHostDisplay: true });
	}
}

export function FlexStats({ flexPeriod, schoolId, numStudents, timeZone, deleteFlexPeriod }: IFlexStatsProps): React.ReactElement {
	const { startTime, endTime, atStartDate, nextDay, prevDay, openActivityDialog, refreshKey, isStartingDay } = useFlexStats(flexPeriod);
	const { activityInstances, isLoading } = useActivityInstancesByPeriodAndFillExtra({
		day: startTime,
		flexPeriod,
		schoolId,
		timezone: timeZone,
		refreshKey,
	});
	const { containerHeight } = useContainerHeight(activityInstances.length, isLoading);
	const { numAttending } = useNumAttendees(activityInstances);

	const timeFormat: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
	const inPast = new Date(startTime).setHours(12, 0, 0, 0) < new Date().setHours(12, 0, 0, 0);
	const relativeDateText = relativeDate(startTime);

	const flexTimeRangeTxt = `${flexPeriod.name} - ${startTime.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
	})}, ${startTime.toLocaleTimeString('en-US', timeFormat)} - ${endTime.toLocaleTimeString('en-US', timeFormat)}`;

	const downloadActivitiesCsv = async () => {
		const blob = await exportInstanceByFlex(schoolId, flexPeriod.id, startTime);
		downloadBlob(blob, `Activities for ${flexTimeRangeTxt}.csv`);
	};

	return (
		<div
			className={`
				tw-p-7
				tw-pt-6
				tw-flex
				tw-flex-col
				tw-bg-white
				tw-rounded-xl
				tw-shadow-soft
				tw-w-221
				tw-transition-height
				tw-duration-500
				tw-ease-in-out
			`}
			style={{ height: `${containerHeight}rem` }}>
			{/* This line is here to force the React library to be included in Angular's optimization */}
			<div className="tw-hidden">{React.version}</div>
			<TitleHeader name={flexPeriod.name} deleteFlexPeriod={deleteFlexPeriod} />
			<DateNavigation
				atStartDate={atStartDate}
				prevDay={prevDay}
				nextDay={nextDay}
				relativeDateText={relativeDateText}
				startTime={startTime}
				endTime={endTime}
				isStartingDay={isStartingDay}
				timeFormat={timeFormat}
			/>
			<div className="tw-bg-gray-300 tw-h-px tw--ml-7 tw-w-235" />
			<HeaderStats
				isLoading={isLoading}
				inPast={inPast}
				numAttending={numAttending}
				numStudents={numStudents}
				schoolId={schoolId}
				flexPeriodId={flexPeriod.id}
				startTime={startTime}
				fileName={flexTimeRangeTxt}
			/>
			<ActivitiesHeader isLoading={isLoading} numActivityInstances={activityInstances.length} downloadCsv={downloadActivitiesCsv} />
			<ActivityBoxes isLoading={isLoading} activityInstances={activityInstances} openActivityDialog={openActivityDialog} />
		</div>
	);
}
