import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { uniqBy } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImportStudentListComponent } from '../../../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { DeviceDetection } from '../../../../device-detection.helper';
import { StudentList } from '../../../../models/StudentList';
import { ROLES, User } from '../../../../models/User';
import { FeatureFlagService, FLAGS } from '../../../../services/feature-flag.service';
import { ScreenService } from '../../../../services/screen.service';
import { UserService } from '../../../../services/user.service';
import { CreateFormService } from '../../../create-form.service';
import { States } from '../../locations-group-container/locations-group-container.component';
import { Navigation } from '../../main-hall-pass-form.component';

@Component({
	selector: 'app-groups-container-v2',
	templateUrl: './groups-container-v2.component.html',
	styleUrls: ['./groups-container-v2.component.scss'],
})
export class GroupsContainerV2Component implements OnInit {
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;

	@Input() FORM_STATE: Navigation;

	@Output() nextStepEvent: EventEmitter<Navigation | { action: string; data: any }> = new EventEmitter<Navigation | { action: string; data: any }>();
	@Output() stateChangeEvent: EventEmitter<Navigation | string> = new EventEmitter<Navigation | string>();

	updateData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	currentState: number;
	selectedGroupsAndStudents: (StudentList | User)[] = [];
	groups: StudentList[];
	filteredGroups: StudentList[];
	selectedStudents: User[] = [];
	filteredStudents: User[] | undefined;
	currentSearch = '';
	isSearchFocused = true;
	searchInputChanges$ = new Subject<string>();
	groupDTO: FormGroup;
	importStudentListDialogRef: MatDialogRef<ImportStudentListComponent>;
	noFlyTimeTooltipHeight = 160;
	isOpenNoFlyTimeTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	destroy$ = new Subject();

	frameMotion$: BehaviorSubject<any>;
	continueButtonWidth: string;

	handleSelection = false;
	hasSchedulesFF = false;

	@HostListener('window:resize')
	onResize() {
		this.continueButtonWidth = this.screenService.isDeviceLargeExtra ? '295px' : '388px';
	}

	// Listen for clicks on the document
	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!this.isClickOnScrollbar(targetElement)) {
			this.onSearchBlur();
		}
	}

	// Function to check if the click occurred on the scrollbar
	private isClickOnScrollbar(targetElement): boolean {
		return targetElement.offsetWidth !== targetElement.scrollWidth || targetElement.offsetHeight !== targetElement.scrollHeight;
	}

	constructor(
		private userService: UserService,
		private formService: CreateFormService,
		private screenService: ScreenService,
		public dialog: MatDialog,
		private featureFlags: FeatureFlagService,
		private renderer: Renderer2,
		private changeDetector: ChangeDetectorRef,
		private elementRef: ElementRef
	) {}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	get isMobile() {
		return DeviceDetection.isMobile();
	}

	ngOnInit(): void {
		if (this.FORM_STATE.data.selectedStudents) {
			this.selectedGroupsAndStudents = this.FORM_STATE.data.selectedStudents;
		}
		this.hasSchedulesFF = this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules);

		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.groupDTO = new FormGroup({
			title: new FormControl('', { updateOn: 'change' }),
			users: new FormControl(this.selectedStudents, { updateOn: 'change' }),
		});
		this.continueButtonWidth = this.screenService.isDeviceLargeExtra ? '295px' : '388px';
		if (this.FORM_STATE.state !== States.restrictedTarget) {
			this.userService.getStudentGroupsRequest();
		}
		this.checkCompresingAbbility();

		if (this.FORM_STATE.noFlyTimes) {
			this.noFlyTimeTooltipHeight = 160 + 19 * (this.FORM_STATE.noFlyTimes.length - 1);
		}

		if (this.FORM_STATE) {
			this.currentState = this.FORM_STATE.state || !this.FORM_STATE?.forLater ? States.FromToWhere : States.from;
		}

		this.updateData$
			.pipe(
				switchMap(() => {
					return this.userService.studentGroups$.pipe(
						map((groups: StudentList[]) => {
							this.groups = groups;
							this.filteredGroups = this.groups;
						})
					);
				})
			)
			.subscribe();

		this.searchInputChanges$
			.pipe(
				debounceTime(150),
				takeUntil(this.destroy$),
				tap((search) => {
					this.onSearch(search);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.setFocusOnSearch();
			this.changeDetector.detectChanges();
		});
	}

	private checkCompresingAbbility(): void {
		if (!this.screenService.isDeviceLargeExtra && !this.FORM_STATE.kioskMode) {
			this.formService.compressableBoxController.next(true);
		}
	}

	onSearch(search: string): void {
		this.currentSearch = search;
		if (search !== '') {
			this.filteredGroups = this.groups.filter((group) => {
				const titleMatches = group.title.toLowerCase().includes(search.toLowerCase());
				const alreadyAdded = this.selectedGroupsAndStudents.includes(group);
				return titleMatches && !alreadyAdded;
			});

			const currentDate = new Date();
			//formatted time to pass to searchProfile if needed?
			const rfc3339Format = this.formatRFC3339(currentDate);

			// Construct the formatted date string
			this.userService
				.searchProfile(ROLES.Student, 100, search, ['suspended'], true, rfc3339Format)
				?.pipe(
					map((responses) => responses.results.map((r) => User.fromJSON(r))),
					map((students) => {
						return students.filter((student) => !this.selectedGroupsAndStudents.some((selectedStudent) => selectedStudent.id === student.id));
					})
				)
				.subscribe((filteredStudents) => (this.filteredStudents = filteredStudents));
		} else {
			if (this.selectedGroupsAndStudents?.length == 0) {
				this.filteredGroups = this.groups.filter((group) => !this.selectedGroupsAndStudents.includes(group));
			} else {
				this.filteredGroups = [];
			}
			this.filteredStudents = undefined;
		}
	}

	//format current time to rfc3339 WITHOUT OFFSET of local timezone
	private formatRFC3339(date: Date): string {
		const year = date.getFullYear().toString();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because January is month 0
		const day = date.getDate().toString().padStart(2, '0');
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');
		const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
	}

	onSearchFocus(isFocused: boolean): void {
		setTimeout(() => (this.isSearchFocused = isFocused), 50);
	}

	onSearchBlur(): void {
		setTimeout(() => {
			if (this.handleSelection == false) {
				this.isSearchFocused = false;
				this.currentSearch = '';
			}
		}, 90);
	}

	private setFocusOnSearch(): void {
		if (this.searchInput?.nativeElement) {
			setTimeout(() => this.renderer.selectRootElement(this.searchInput.nativeElement).focus(), 50);
			this.onSearch('');
		}
	}

	removeSelection(selection: StudentList | User): void {
		this.handleSelection = true;
		this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.filter((s) => s !== selection);
		this.setSelectedStudents();
	}

	showImportStudentList(): void {
		this.renderer.selectRootElement(this.searchInput.nativeElement).blur();
		const dialogRef = (this.importStudentListDialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		}));

		dialogRef.afterClosed().subscribe((students: User[]) => {
			if (students && students.length > 0) {
				this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.concat(students);
				//filter out duplicate imports by user id
				const idMap = new Map(this.selectedGroupsAndStudents.map((user) => [user.id, user]));
				this.selectedGroupsAndStudents = Array.from(idMap.values());
				this.setSelectedStudents();
			}
		});
	}

	nextStep(): void {
		this.formService.setFrameMotionDirection('forward');
		this.formService.compressableBoxController.next(false);
		setTimeout(() => {
			console.log('formstate after student select: ', this.FORM_STATE);
			if (this.FORM_STATE.forLater) {
				this.FORM_STATE.step = 1;
				this.FORM_STATE.fromState = !this.FORM_STATE?.forLater ? States.FromToWhere : States.from;
			} else if (this.FORM_STATE.state === States.restrictedTarget) {
				this.FORM_STATE.step = 3;
				this.FORM_STATE.state = !this.FORM_STATE?.forLater ? States.FromToWhere : States.from;
				this.FORM_STATE.previousState = States.restrictedTarget;
			} else {
				this.FORM_STATE.step = 3;
				this.FORM_STATE.state = !this.FORM_STATE?.forLater ? States.FromToWhere : States.from;
				this.FORM_STATE.fromState = !this.FORM_STATE?.forLater ? States.FromToWhere : States.from;
			}

			this.setSelectedStudents();
			if (this.FORM_STATE.data) {
				this.FORM_STATE.data.selectedStudents = uniqBy(this.selectedStudents, (user) => user.id);
			}

			this.stateChangeEvent.emit(this.FORM_STATE);
		}, 100);
	}

	private setSelectedStudents(): void {
		this.selectedStudents = [];
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if ('users' in groupOrStudent) {
				(groupOrStudent as StudentList).users.forEach((student) => this.selectedStudents.push(student));
			} else {
				this.selectedStudents.push(groupOrStudent as User);
			}
		});
		this.isSearchFocused = true;

		setTimeout(() => {
			this.handleSelection = false;
		}, 150);
		this.currentSearch = '';
		this.filteredGroups = [];
		this.filteredStudents = undefined;
		setTimeout(() => this.renderer.selectRootElement(this.searchInput.nativeElement).focus(), 50);
	}

	clickedInside(event: boolean): void {
		this.handleSelection = event;
		this.isSearchFocused = event;
	}

	handleSelect(item): void {
		this.handleSelection = true;
		if (this.filteredGroups.includes(item)) {
			this.filteredGroups = this.filteredGroups.filter((group) => group !== item);
			this.FORM_STATE.data.selectedGroup = item;
		} else {
			this.filteredStudents = this.filteredStudents?.filter((student) => student !== item);
		}
		this.selectedGroupsAndStudents.push(item);
		this.setSelectedStudents();
	}

	showNoFlyTimeTooltip(): void {
		this.isOpenNoFlyTimeTooltip.next(true);
	}

	closeNoFlyTimeTooltip(): void {
		this.isOpenNoFlyTimeTooltip.next(false);
	}
}
