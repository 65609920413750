<div
	class="wrapper"
	#wrapper
	appCrossPointerEventTarget
	(pointerClickEvent)="openSupportOptions($event)"
	[style.box-shadow]="isOpenOptions ? 'none' : ''">
	<div class="content">
		<div class="close" *ngIf="isOpenOptions; else ask"></div>
		<ng-template #ask>
			<div>?</div>
		</ng-template>
	</div>
</div>
