import { ActivityWithInstance } from '../../../hooks/use-activity-instance';

export function countAttendees(awis: ActivityWithInstance[]): number {
	let count = 0;
	for (const awi of awis) {
		if (awi.instance?.current_num_attendees) {
			count += awi.instance.current_num_attendees;
		}
	}

	return count;
}
