import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-no-fly-time-admin-nux',
	templateUrl: './no-fly-time-admin-nux.component.html',
	styleUrls: ['./no-fly-time-admin-nux.component.scss'],
})
export class NoFlyTimeAdminNuxComponent {
	@Input() close: () => void;

	directToNoFlyTimeDoc(): void {
		window.open('https://www.smartpass.app/noflytime', '_blank');
	}
}
