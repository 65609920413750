import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-duration-picker',
	templateUrl: './duration-picker.component.html',
	styleUrls: ['./duration-picker.component.scss'],
})
export class DurationPickerComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input()
	minDuration = 1;

	@Input()
	maxDuration = 5;

	@Input() inFormContainer = false;

	@Input()
	forStaff: boolean;

	@Input()
	plural: boolean;

	@Input() disabled;
	@Input() choices: string[];

	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() onSelectTravelType: EventEmitter<any> = new EventEmitter();

	selectedDuration: number = this.maxDuration;
	private dialogContainer: HTMLElement;

	private wheelHandler = (e: WheelEvent) => {
		if (this.dialogContainer?.contains(e.target as Node)) {
			e.preventDefault();
			const delta = e.deltaY || e.detail;
			if (delta < 0 && this.selectedDuration < this.maxDuration) {
				this.selectedDuration += 1;
			} else if (delta > 0 && this.selectedDuration > this.minDuration) {
				this.selectedDuration -= 1;
			}
			this.emitResult(this.selectedDuration);
		}
	};

	constructor(@Inject(DOCUMENT) private document: Document) {
		this.wheelHandler.bind(this);
	}

	ngOnInit() {
		this.dialogContainer = this.document.querySelector('.main-form-dialog-container');
		this.dialogContainer?.addEventListener('wheel', this.wheelHandler, { passive: false });

		if (this.maxDuration === this.maxDuration) {
			this.minDuration = 0;
			this.disabled = true;
		}

		this.selectedDuration = this.maxDuration;

		this.emitResult(this.selectedDuration);
	}
	ngAfterViewInit(): void {
		this.dialogContainer = this.document.querySelector('.main-form-dialog-container');
		if (this.dialogContainer) {
			this.dialogContainer.addEventListener('wheel', this.wheelHandler, { passive: false });
		}
	}

	ngOnDestroy() {
		if (this.dialogContainer) {
			this.dialogContainer.removeEventListener('wheel', this.wheelHandler);
		}
	}

	emitResult(value) {
		if (value && value <= this.maxDuration && value >= this.minDuration) {
			this.selectedDuration = value;
			// console.log('Value ==>>>', this.selectedDuration);
			this.onChange.emit(this.selectedDuration);
		} else {
			if (!value) {
				this.selectedDuration = 1;
				this.minDuration = 1;
			} else if (value > this.maxDuration) {
				this.selectedDuration = this.maxDuration;
			}
		}
	}

	updateDuration(event: any) {
		if (!event.value) {
			this.minDuration = 1;
		} else {
			this.emitResult(event.value);
		}
	}
}
