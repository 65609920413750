/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Input, OnInit } from '@angular/core';
import { DarkThemeSwitch } from '../../../dark-theme-switch';
import { User } from '../../../models/User';
import { QRBarcodeGeneratorService } from '../../../services/qrbarcode-generator.service';
import { UserService } from '../../../services/user.service';
import { filter } from 'rxjs/operators';
import { IDCardService } from '../../../services/IDCardService';
import { getStartOfSchoolYear } from 'app/helpers/helper';

@Component({
	selector: 'sp-id-card-front',
	templateUrl: './id-card-front.component.html',
	styleUrls: ['./id-card-front.component.scss'],
})
export class IdCardFrontComponent implements OnInit {
	@Input() userName = 'Nicholas Demarco';
	@Input() schoolName = 'Walt Whitman High School';
	@Input() userRole = 'Student';
	@Input() backgroundColor = '#00B476';
	@Input() barcodeType = 'qr-code';
	@Input() profile_picture: string;
	@Input() backsideText: string;
	@Input() logoURL: string;
	@Input() IDNumberData: any = {};
	@Input() greadLevel: number;
	@Input() buttonBackColor = '#FFFFFF';
	@Input() isDummyCard = false;
	@Input() isLoggedIn = true;
	@Input() showCustomID = false;
	@Input() stickers: any = [];
	sticker_list: any = [];

	userDetails: any;

	isIdCardLoaded = false;

	private currentYear: number = getStartOfSchoolYear().getUTCFullYear();
	private nextYear: number = this.currentYear + 1;
	private nextYearShort: string = this.nextYear.toString().slice(-2);
	schoolYear = `${this.currentYear} - ${this.nextYearShort}`;

	constructor(
		public darkTheme: DarkThemeSwitch,
		private userService: UserService,
		private qrBarcodeGenerator: QRBarcodeGeneratorService,
		private idCardService: IDCardService
	) {}

	ngOnInit(): void {
		if (!this.idCardService.isValidColor(this.backgroundColor)) {
			this.backgroundColor = '#00B476';
		}
		this.sticker_list = this.stickers;
		this.schoolName = this.userService.getUserSchool().name;
		if (this.isLoggedIn) {
			this.userService.userJSON$.pipe(filter((user) => !!user)).subscribe({
				next: async (user) => {
					this.userDetails = User.fromJSON(user);
					this.schoolName = this.userService.getUserSchool().name;
					this.userName = this.userDetails.display_name;
					this.userRole = this.userDetails.isStudent() ? 'Student' : 'Staff';
					this.profile_picture = this.userDetails?.profile_picture;
					this.greadLevel = this.userDetails?.grade_level;
					if (this.showCustomID && this.userDetails?.custom_id && this.userDetails?.custom_id != undefined) {
						this.IDNumberData.idNumber = this.userDetails?.custom_id;
						this.IDNumberData.barcodeURL = await this.qrBarcodeGenerator.selectBarcodeType(this.barcodeType, this.IDNumberData.idNumber);
					} else {
						this.showCustomID = false;
					}

					this.isIdCardLoaded = true;
				},
			});
		} else {
			this.isIdCardLoaded = true;
		}
	}
}
