import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DialogFactoryService } from '../../dialog-factory.service';
import { DYNAMIC_DIALOG_OPTIONS, DynamicDialogAction, DynamicDialogData } from '../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../../dynamic-dialog.service';
import { ClassStatus } from '../../services/classes.service';
import { SchoolActivityStatus } from '../../services/school-activity.service';

@Component({
	selector: 'sp-archive',
	templateUrl: './archive.component.html',
	styleUrls: ['./archive.component.scss'],
})
export class ArchiveComponent {
	@Input() archiveModalBody!: TemplateRef<unknown>;
	@Input() tooltipTemplate!: TemplateRef<unknown>;
	@Input() currentStatus: ClassStatus | SchoolActivityStatus | undefined;
	@Input() userCanEdit = false;
	@Input() resourceName: string | undefined;
	@Input() archiveText: string | undefined;
	@Input() unarchiveText: string | undefined;

	@Output() updateStatus: EventEmitter<ClassStatus | SchoolActivityStatus> = new EventEmitter<ClassStatus | SchoolActivityStatus>();

	private archiveDialogService: DynamicDialogService | undefined;

	constructor(private dialogFactoryService: DialogFactoryService) {}

	openArchiveConfirmationModal(): void {
		if (this.currentStatus === 'archived') {
			this.updateStatus.emit('active');
			return;
		}
		const headerText = `Archive This ${this.resourceName}?`;
		const data: DynamicDialogData = {
			headerText: headerText,
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: `Archive ${this.resourceName}?`,
			secondaryButtonLabel: 'Cancel',
			modalBody: this.archiveModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66,#E32C66',
			primaryButtonTextColor: '#FFFFFF',
		};
		this.archiveDialogService = this.dialogFactoryService.open(data, DYNAMIC_DIALOG_OPTIONS);
		this.archiveDialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: unknown) => {
				const option = selectedOption as DynamicDialogAction;
				if (option === 'secondary') {
					//cancel
				} else if (option === 'primary') {
					this.updateStatus.emit('archived');
				}
			},
		});
	}
}
