<div class="wrapper" #container>
	<div class="sticky-header">
		<div class="toggle-header" [style.height]="'70px'">
			<div class="toggle-picker">
				<app-restriction-picker
					(result)="toggleResult = $event"
					[selectedChoice]="toggleResult || 'Range'"
					[width]="265"
					[height]="32"
					[choices]="toggleOptions"></app-restriction-picker>
			</div>
			<!--<div class="selected-date" *ngIf="toggleResult === 'Range'">-->
			<!--{{ selectedDateText }}-->
			<!--</div>-->
		</div>
		<mat-divider></mat-divider>
	</div>
	<div class="content" [ngSwitch]="toggleResult" [ngClass]="{ scrollable: openCalendar && toggleResult === 'Range', days: toggleResult === 'Days' }">
		<div class="range-options" *ngSwitchCase="'Range'">
			<app-toggle-options [options]="rangeOptions" [selectedId]="selectedRangeId" (result)="selectedRangeOption($event)"></app-toggle-options>
			<div class="calendar-picker" #rangeButton *ngIf="openCalendar">
				<app-calendar-picker
					[selectedDates]="exportDates"
					[hoveredDates]="exportHoveredDates"
					(onSelectDate)="calendarResult($event)"
					[showYear]="false"
					[showTime]="false"
					[range]="true"></app-calendar-picker>
			</div>
			<div class="button" #elem *ngIf="selectedDate.start && selectedDate.end && openCalendar">
				<app-gradient-button [style.margin.bottom]="'15px'" [width]="'101px'" [cursor]="'pointer'" [minHeight]="'40px'" (buttonClick)="save()"
					>Done</app-gradient-button
				>
			</div>
		</div>

		<div class="days-options" *ngSwitchCase="'Days'">
			<div class="calendar-picker">
				<app-calendar-picker
					[selectedDates]="[selectedDay]"
					(onSelectDate)="calendarResult($event)"
					[showYear]="false"
					[showTime]="false"></app-calendar-picker>
			</div>
			<mat-divider></mat-divider>
			<div class="day-options">
				<app-toggle-options
					[selectedId]="selectedDayId || 'days_1'"
					[options]="daysOptions"
					(result)="selectedDaysOption($event)"></app-toggle-options>
			</div>
			<div class="time-range" #day *ngIf="openTimeRange">
				<app-time-picker [currentDate]="selectedDate.start || currentDate" (timeResult)="changeRangeTime(true, $event)"></app-time-picker>
				<mat-divider [vertical]="true"></mat-divider>
				<app-time-picker [currentDate]="selectedDate.end || currentDate" (timeResult)="changeRangeTime(false, $event)"></app-time-picker>
			</div>
			<div class="button" #dayButton *ngIf="selectedDate.start && selectedDate.end && openTimeRange">
				<app-gradient-button [width]="'101px'" [cursor]="'pointer'" [minHeight]="'40px'" (buttonClick)="save()">Done</app-gradient-button>
			</div>
		</div>
		<div class="weeks" *ngSwitchCase="'Weeks'">
			<app-calendar-picker
				[selectedDates]="exportHoveredDates"
				[showTime]="false"
				[showYear]="false"
				[rangeWeeks]="true"
				(onSelectDate)="calendarResult($event)"></app-calendar-picker>
		</div>
		<div class="days-options" style="height: 372px" *ngSwitchCase="'Single Day'">
			<div class="calendar-picker">
				<app-calendar-picker
					[selectedDates]="[selectedDate.start]"
					(onSelectDate)="calendarResult($event)"
					[showYear]="false"
					[showTime]="false"
					[useUtcTime]="true"></app-calendar-picker>
			</div>
		</div>
		<div class="days-options" style="height: 372px" *ngSwitchCase="'Multiple Days'">
			<div class="calendar-picker">
				<app-calendar-picker
					[selectedDates]="exportDates"
					[hoveredDates]="exportHoveredDates"
					(onSelectDate)="calendarResult($event)"
					[showYear]="false"
					[showTime]="false"
					[range]="true"></app-calendar-picker>
			</div>
			<div class="button" #elem *ngIf="selectedDate.start && selectedDate.end && openCalendar">
				<app-gradient-button [style.margin.bottom]="'15px'" [width]="'101px'" [cursor]="'pointer'" [minHeight]="'40px'" (buttonClick)="save()"
					>Done</app-gradient-button
				>
			</div>
		</div>
	</div>
</div>
