<div *ngIf="_showTitle" data-cy="collection-header" class="collection-header">
	<div class="collection-title ds-mt-20">
		<div class="collection-title" [style.color]="!!(this.darkTheme.isEnabled$ | async) ? '#FFFFFF' : '#1F195E'">
			<ng-content select="sp-collection-title"></ng-content>
		</div>
	</div>

	<ng-content select="sp-collection-header-action"></ng-content>
</div>

<div
	data-cy="collection-content"
	class="collection-content"
	[ngClass]="columns > 1 ? 'collection-grid' : 'collection-flex'"
	[style.grid-template-columns]="_gridTemplateColumns">
	<!--
        The intended template to be passed here consists of single pass-like tiles
        Passing a custom grid here and using the collection-tile attribute on its wrapper is an incorrect
        way to use this component 😡
    -->
	<ng-content select="[collection-content]"></ng-content>
</div>

<div data-cy="custom-collection-content" class="custom-collection-content" *ngIf="customGrid">
	<!-- The template being passed here should be a wrapper with custom css that contains the items of the custom grid-->
	<ng-content select="[custom-collection-wrapper]"></ng-content>
</div>

<ng-content select="sp-collection-empty-state"></ng-content>
