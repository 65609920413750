import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Toast } from '../models/Toast';
import { AppState } from '../ngrx/app-state/app-state';
import { ToastObj, getDataToast, getIsOpenToast, getOpenedToasts, getOpenedToastsIds } from '../ngrx/toast/states';
import { closeAllToasts, closeToastAction, getCurrentToastData, openToastAction } from '../ngrx/toast/actions';

export enum ToastState {
	Error = 'error',
	Success = 'success',
	Info = 'info',
}
export enum ToastIdEnum {
	SESSION_EXPIRED = 'session-expired',
}

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	isOpen$: Observable<boolean> = this.store.select(getIsOpenToast);
	data$: Observable<Toast> = this.store.select(getDataToast);
	toasts$: Observable<ToastObj[]> = this.store.select(getOpenedToasts);
	openedToastsIds$: Observable<string[] | number[]> = this.store.select(getOpenedToastsIds);

	toastButtonClick$: Subject<string> = new Subject<string>();

	constructor(private store: Store<AppState>) {}

	getToastDataById(id: string) {
		this.store.dispatch(getCurrentToastData({ id }));
	}

	openToast(data: Toast, id?: string) {
		this.store.dispatch(openToastAction({ data, id }));
	}

	closeToast(ids: string[] = []) {
		this.store.dispatch(closeToastAction({ ids }));
	}

	closeAllToasts() {
		this.store.dispatch(closeAllToasts());
	}
}
