import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { MonetizationInfo } from '../models/School';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { UserService } from './user.service';

const DAYS_BEFORE_RENEWAL = 60;

@Injectable({
	providedIn: 'root',
})
// MonetizationService powers features such as our banners, and subscripton cards.
export class MonetizationService {
	monetizationInfo$: Observable<MonetizationInfo>;

	mockMonetizationData: Partial<MonetizationInfo> = {
		monetization_pipeline: 'customer',
		recent_subscription_plan: 'SmartPass One',
		// Set these fields during local testing to test the various banners
		subscription_end_date: '2024-07-01',
		account_suspension_date: '2024-07-18',
		cta_link: '',
		trial_start_date: '2024-02-01',
	};

	formatDate(dateString): string {
		// date only strings are interpreted as UTC,
		// but we want this in local time so add the time component.
		const date = new Date(`${dateString}T00:00`);
		return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
	}

	calculateDaysDiff(date1: Date, date2: Date): number {
		const diffInMilliseconds = date1.getTime() - date2.getTime();
		return Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
	}

	subtractDays(date: Date, days: number): Date {
		const result = new Date(date);
		result.setDate(result.getDate() - days);
		return result;
	}

	constructor(private httpService: HttpService, private userService: UserService) {
		this.monetizationInfo$ = this.userService.effectiveUser$.pipe(
			filter((u) => !!u),
			switchMap(() => this.httpService.post<MonetizationInfo>('v2/school/GetMonetizationInfo', {}, undefined, false)),
			map((data) => {
				// // override the keys from the response with the mock data
				// // TODO (dhruv): remove this when the renewal info endpoint is updated to fetch this data
				// Object.keys(this.mockMonetizationData).forEach((key) => {
				// 	data[key] = this.mockMonetizationData[key];
				// });

				const today = new Date();
				const subscriptionStartDate = new Date(`${data.trial_start_date}T00:00:00`);
				const subscriptionEndDate = new Date(`${data.subscription_end_date}T00:00:00`);
				const suspensionDate = new Date(`${data.account_suspension_date}T00:00:00`);

				const daysRemaining = Math.max(0, this.calculateDaysDiff(subscriptionEndDate, today));
				const suspensionDaysRemaining = Math.max(0, this.calculateDaysDiff(suspensionDate, today));
				const daysElapsed = this.calculateDaysDiff(today, subscriptionStartDate);
				const suspensionDaysElapsed = this.calculateDaysDiff(today, subscriptionEndDate);

				const durationPeriod = this.calculateDaysDiff(subscriptionEndDate, subscriptionStartDate);
				// This is the duration of time between subscription end and suspension date
				const suspendDurationPeriod = this.calculateDaysDiff(suspensionDate, subscriptionEndDate);
				// This is the duration of time between subscription start date and suspension date
				const freeTrialEarlyAccessDuration = this.calculateDaysDiff(suspensionDate, subscriptionStartDate);

				// Update to handle subscription progress
				const subscriptionProgressStart = this.subtractDays(subscriptionEndDate, DAYS_BEFORE_RENEWAL);
				const subscriptionDurationPeriod = this.calculateDaysDiff(subscriptionEndDate, subscriptionProgressStart);
				const subscriptionDaysElapsed = this.calculateDaysDiff(today, subscriptionProgressStart);

				const progressBar = 1 - subscriptionDaysElapsed / subscriptionDurationPeriod;
				const suspendProgressBar = 1 - suspensionDaysElapsed / suspendDurationPeriod;
				const freeTrialEarlyAccessProgressBar = 1 - daysElapsed / freeTrialEarlyAccessDuration;

				const activeAccount = today < subscriptionEndDate;
				const suspendedAccount = today > suspensionDate;

				const formattedSubscriptionEndDate = this.formatDate(data.subscription_end_date);

				// Controls trial banner and subscription card display state for customers
				const customerSubscriptionFirstWarning = 120;
				const customerSubscriptionSecondWarning = 60;
				return {
					...data,
					daysRemaining,
					progressBar,
					suspendProgressBar,
					activeAccount,
					suspensionDaysRemaining,
					suspendDurationPeriod,
					suspendedAccount,
					formattedSubscriptionEndDate,
					freeTrialEarlyAccessDuration,
					freeTrialEarlyAccessProgressBar,
					customerSubscriptionFirstWarning,
					customerSubscriptionSecondWarning,
				};
			}),
			shareReplay()
		);
	}
}
