import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { Schedule } from '../../../models/Schedule';
import { ScheduleService } from '../../../services/schedule.service';

export interface TermSelection {
	term_ids: number[];
}

@Component({
	selector: 'sp-class-term-picker',
	templateUrl: './class-term-picker.component.html',
	styles: [''],
	providers: [DatePipe],
})
export class ClassTermPickerComponent implements OnInit {
	@Input() schedules: Schedule[];
	@Input() initialSelectedTermIds: number[] = [];

	@Output() selection = new EventEmitter<TermSelection>();

	selected: Schedule;
	selectedSubterms = new Map<number, boolean>();
	private dropdownOptions: {
		display: string;
		action: string;
	}[] = [];

	constructor(private dialog: MatDialog, public scheduleService: ScheduleService, private datePipe: DatePipe) {}

	ngOnInit(): void {
		this.buildOptions();

		if (this.initialSelectedTermIds?.length > 0) {
			// get the first schedule that has a matching term or subterm
			this.selected = this.schedules.find((s) => {
				return this.initialSelectedTermIds.some((t) => s.term.id === t || s.subterms.some((t2) => t2.id === t));
			});
			this.initialSelectedTermIds.forEach((t) => this.selectedSubterms.set(t, true));
		} else if (this.schedules?.length > 0) {
			this.selected = this.schedules[0];
			this.selected.subterms?.forEach((t) => this.selectedSubterms.set(t.id, true));
		}
		this.emitCurrentState();
	}

	private buildOptions(): void {
		this.dropdownOptions = this.schedules?.map((schedule) => {
			const term = schedule.term;
			return {
				display: this.rangeDisplay(term.start_date, term.end_date),
				action: String(schedule.id),
				header: term.term_name,
			};
		});
	}

	private rangeDisplay(start: string, end: string): string {
		const startAsDate = new Date(start);
		const startDate = this.datePipe.transform(startAsDate, 'MMMM d, yyyy', 'UTC');
		const endAsDate = new Date(end);
		const endDate = this.datePipe.transform(endAsDate, 'MMMM d, yyyy', 'UTC');
		return `${startDate} - ${endDate}`;
	}

	openDropdown(target): void {
		const filterDialog = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: target.currentTarget,
				sortData: this.dropdownOptions,
				selectedSort: String(this.selected?.id),
				maxHeight: '332px',
				width: '484px',
				optionPadding: '7px 20px',
			},
		});

		filterDialog
			.afterClosed()
			.pipe(filter((res) => !!res))
			.subscribe((action: string) => {
				const selectedSchedule = this.schedules?.find((schedule) => String(schedule.id) === action);
				if (selectedSchedule.id !== this.selected.id) {
					this.selected = selectedSchedule;
					this.selectedSubterms.clear();
					if (this.selected?.subterms) {
						for (const subterm of this.selected.subterms) {
							this.selectedSubterms.set(subterm.id, true);
						}
					}
					this.emitCurrentState();
				}
			});
	}

	handleChipToggle(subtermId, status): void {
		this.selectedSubterms.set(subtermId, status);
		this.emitCurrentState();
	}

	private emitCurrentState(): void {
		if (!this.selected) {
			return;
		}

		// If there are no subterms, emit the main term
		if (!this.selected.subterms || this.selected.subterms.length == 0) {
			this.selection.next({ term_ids: [this.selected.term.id] });
			return;
		}

		// Otherwise emit the subterms
		const ids: number[] = [];
		this.selectedSubterms.forEach((selected, id) => {
			if (selected) {
				ids.push(id);
			}
		});

		this.selection.emit({
			term_ids: ids,
		});
	}
}
