<div
	class="card-button-container"
	(click)="buttonClicked()"
	(mousedown)="onPress(true)"
	(mouseup)="onPress(false)"
	[style.height]="height"
	[ngClass]="{ pressed: pressed, disabled: disabled, 'in-form-container': inFormContainer }"
	[style.background]="backgroundGradient"
	[style.border-radius]="inFormContainer ? '16px' : '20px'"
	[style.border]="border ? border : ''"
	[style.background-color]="waitInLine ? 'rgba(255, 255, 255, 0.2)' : 'inherit'">
	<div
		*ngIf="useOverlay"
		class="card-button-base"
		[style.width]="width"
		[style.height]="height"
		[style.mix-blend-mode]="valid ? 'soft-light' : 'normal'"
		[style.background-color]="!valid ? 'rgba(200, 200, 200, .7)' : 'initial'"
		[style.border-radius]="inFormContainer ? '16px' : '20px'"></div>
	<div
		*ngIf="useOverlay"
		class="card-button-overlay-wrapper"
		[style.width]="width"
		[style.height]="height"
		[style.border-radius]="inFormContainer ? '16px' : '20px'">
		<div
			class="card-button-overlay-base"
			(click)="buttonClicked()"
			[style.height]="height"
			[style.mix-blend-mode]="valid ? 'soft-light' : 'normal'"
			[style.background]="!valid ? 'radial-gradient(circle at 73% 71%,#DA2370 ,#FB434A)' : 'rgba(200, 200, 200, .7)'"
			[style.border-radius]="inFormContainer ? '16px' : '20px'"></div>
	</div>

	<div [style.margin-top]="marginTop">
		<ng-content></ng-content>
	</div>
</div>
