import { Pipe, PipeTransform } from '@angular/core';
import { calendarSlot } from '../passes-calendar/calendar-view/calendar-view.component';
import { CalendarEvent } from 'calendar-utils';

@Pipe({
	name: 'eventHasOverlappingEvents',
	pure: true,
})
export class EventHasOverlappingEventsPipe implements PipeTransform {
	transform(calendarEvent: CalendarEvent, slots: calendarSlot[]): boolean {
		const filteredSlots = slots.filter((slot) => slot.day === calendarEvent.start.getDate() && slot.eventIds.includes(calendarEvent.id as number));
		if (filteredSlots.length > 1) {
			return true;
		}
		return false;
	}
}
