<div>
	<form [formGroup]="editRoomForm" *ngIf="editRoomForm && displayEditRoomForm">
		<sp-input
			#spInputComponent
			[disabled]="disabled"
			[isFocused]="false"
			[iconDisplay]="true"
			[icon]="selectedRoomIcon"
			[iconBGColor]="selectedRoomIconBGColor"
			[group]="editRoomForm"
			[control]="'room'"
			[label]="'Room'"
			[description]="description"
			[placeholder]="'Select room'"
			[errorMessage]="'Please select a room for this class.'"
			(focus)="onFocusRoomSelect()"
			(blur)="onBlurRoomSelect()"></sp-input>
	</form>
	<div class="tw-relative">
		<ng-container *ngIf="displayRoomList && !disabled">
			<mat-list [ngStyle]="{ 'padding-top': '7px' }" id="rooms-list" *ngIf="allRoomsFiltered?.length">
				<!-- YOUR ROOMS -- ONLY DISPLAYS WHEN SEARCH FIELD IS EMPTY -->
				<ng-cointainer *ngIf="displayRoomListHeadings">
					<ng-container *ngFor="let room of myRoomsFiltered; let i = index">
						<mat-list-item *ngIf="i === 0 && displayRoomListHeadings" class="heading"> Your Rooms </mat-list-item>
						<ng-container [ngTemplateOutlet]="roomListItem" [ngTemplateOutletContext]="{ room: room }"></ng-container>
					</ng-container>
				</ng-cointainer>
				<ng-container *ngFor="let room of allRoomsFiltered; let i = index">
					<mat-list-item *ngIf="i === 0 && displayRoomListHeadings" class="heading"> All Rooms </mat-list-item>
					<ng-container [ngTemplateOutlet]="roomListItem" [ngTemplateOutletContext]="{ room: room }"></ng-container>
				</ng-container>
			</mat-list>
		</ng-container>
		<ng-container *ngIf="displayNoRoomResults">
			<div class="no-results">
				<div class="tw-center">
					<div class="tw-text-base tw-text-gray-500 tw-font-medium tw-pb-2">No results found</div>
					<div class="tw-text-base tw-text-gray-400 tw-font-normal">Check your spelling and try again.</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #roomListItem let-room="room">
	<!-- (mousedown) is necessary here because (click) won't always fire on mat-list-item (if it's being removed from the dom before the click registers-->
	<mat-list-item (mousedown)="onSelectRoom(room.location.id)" class="tw-cursor-pointer list-item" spHover="#F5F6F8,#FFFFFF">
		<div class="room tw-flex tw-w-full tw-items-center tw-gap-2">
			<div [ngStyle]="{ background: room.iconColor }" class="room-icon tw-rounded-full tw-flex tw-items-center tw-justify-center">
				<img [src]="room.icon | resolveAsset" alt="room icon" />
			</div>
			<div class="tw-text-navy-500 tw-text-sm tw-font-medium tw-flex-grow">{{ room.location.title }}</div>
			<div class="tw-text-gray-500 tw-text-sm tw-font-normal">{{ room.location.room }}</div>
		</div>
	</mat-list-item>
</ng-template>
