import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { bufferWhen, delay, filter, map, takeUntil } from 'rxjs/operators';
import { PollingService } from './polling-service';
import { KioskModeService } from './kiosk-mode.service';
import { HallPass } from '../models/HallPass';
import { captureException } from '@sentry/angular';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';

const createIdFilter = (id: number) => (hallPassId: number) => hallPassId === id;
const createInstanceFilter = (instance: any) => (data: unknown) => data instanceof instance;
const datePipe = new DatePipe('en-US');
export const idGetter = ({ id }: { id: number }) => id;
export const hallPassInstance = createInstanceFilter(HallPass);

type Checkpoint = {
	step: number;
	name: string;
	timestamp: string; // formatted date string
	extra?: Record<string, unknown> | string; // any additional information
};
type Trace = Record<number, Checkpoint>; // key: trace step number
type TraceRecords = Record<number, Trace>; // key: hall pass id

/**
 * Intended to be used as a way to monitor kiosk-mode behaviour
 */
@Injectable({
	providedIn: 'root',
})
export class KioskMonitoringService {
	/**
	 * hallPassTrigger$ is intended to receive a created HallPass from an Http request.
	 * HallPass data should only be passed into this BehaviourSubject when the http request
	 * has successfully completed.
	 */
	private hallPassTrigger$ = new BehaviorSubject<HallPass>(null);

	/**
	 * emitBuffer$ is intended to trigger a release of the bufferedMessages$ Observable. This Subject
	 * should only be fired after hallPassTrigger$ has been loaded with data. This ensures both the web sockets
	 * message buffer and the hallPassTrigger$ has data before releasing the buffered web socket messages for
	 * comparison.
	 */
	private emitBuffer$ = new Subject<void>();

	/**
	 * This is a dictionary of hall passes whose tiles appear on the DOM along with
	 * the relevant time stamp. It helps us to record passes that appear on the
	 * DOM even if they've been deleted (either by the kiosk user or another user)
	 */
	private hallPassTileTimestamps: Record<number, Date> = {};

	/**
	 * Stops the Observable in startMonitoringKioskPasses() from monitoring web socket messages.
	 * This is similar to the destroy$ Observables seen in most components
	 */
	private stopMonitoringPasses$ = new Subject<void>();

	private activeTraces: TraceRecords = {};

	constructor(private polling: PollingService, private kiosk: KioskModeService) {}

	startMonitoringKioskPasses() {
		/**
		 * The following timer removes entries from this.hallPassTileTimestamps
		 * older than 10 seconds. Helps to prevent this object from becoming too
		 * large.
		 */
		timer(0, 10000)
			.pipe(
				map(() => Date.now() - 10000),
				takeUntil(this.stopMonitoringPasses$)
			)
			.subscribe({
				next: (tenSecsAgo) => {
					for (const hpId in this.hallPassTileTimestamps) {
						if (this.hallPassTileTimestamps[hpId].getTime() < tenSecsAgo) {
							delete this.hallPassTileTimestamps[hpId];
						}
					}
				},
			});

		/**
		 * The following Observable listens and collects web socket messages on kiosk mode for the
		 * creation of hall passes.
		 * A list of hall passes is emitted only when emitBuffer$ fires.
		 */
		this.polling
			.listenOnCurrentSchool()
			.pipe(
				filter(() => this.kiosk.isKioskMode()),
				filter((message) => message.action === 'hall_pass.start' || message.action === 'pass_request.accept'),
				map((message) => (message.data as HallPass).id),
				bufferWhen(() => this.emitBuffer$.asObservable().pipe(delay(5000))),
				takeUntil(this.stopMonitoringPasses$)
			)
			.subscribe({
				next: (wsIds) => {
					/**
					 * We want to ensure that the pass tile appears on kiosk mode after the request was successfully sent
					 * and the web socket message was received. If either of these do not occur, a message is sent to Sentry
					 */
					const rootId = this.hallPassTrigger$.value.id;
					const idFilter = createIdFilter(rootId);
					const wsMessageReceived = !!wsIds.find(idFilter);

					/**
					 * even if the pass tile was removed within the 5-second window before the buffer drains,
					 * we still get to check if the tile appeared on the DOM
					 */
					const tileAppearedOnDOM = rootId in this.hallPassTileTimestamps;
					if (!wsMessageReceived || !tileAppearedOnDOM) {
						console.warn(`Hall Pass was not displayed on kiosk`);
						/**
						 * using cloneDeep to copy data and create a new object reference
						 * ensures the following deletion step doesn't affect our trace in
						 * any way (just in case)
						 *
						 * A pass trace is supposed to have the last trace message being
						 * "end:pass_in_tile"
						 */
						const passDataTrace = cloneDeep(this.activeTraces[rootId]);
						for (const id in passDataTrace) {
							const entry = passDataTrace[id];
							if (entry.extra) {
								entry.extra = JSON.stringify(entry.extra);
							}
						}
						const traceEntries = Object.values(passDataTrace ?? {});
						const traceIncomplete = !traceEntries.map((t) => t.name).includes('end:pass_in_tile');
						captureException(new Error('Kiosk Monitoring Service'), {
							level: 'warning',
							tags: {
								createdPassId: rootId,
								wsMessageReceived,
								tileAppearedOnDOM,
								traceIncomplete,
							},
							extra: {
								createdPassId: rootId,
								wsMessageReceived,
								tileAppearedOnDOM,
								passDataTrace,
								traceIncomplete,
								hallPassTimeStamps: this.hallPassTileTimestamps,
								timestamp: this.createdFormattedTimestamp(),
							},
						});
					}
					this.stopTrace(rootId);
				},
			});
	}

	stopMonitoringKioskPasses() {
		// trigger stopMonitoringPasses$ and clear relevant BehaviourSubjects
		this.stopMonitoringPasses$.next();
		this.hallPassTrigger$.next(null);
	}

	sendCreatedPassForMonitoring(pass: HallPass) {
		this.hallPassTrigger$.next(pass);
		this.emitBuffer$.next();
	}

	sendPassesOnDOMForMonitoring(passes: HallPass[]) {
		passes.forEach((p) => {
			if (!(p.id in this.hallPassTileTimestamps)) {
				// only adds the timestamps of new passes created on the DOM
				this.hallPassTileTimestamps[p.id] = new Date();
			}
		});
	}

	private createdFormattedTimestamp() {
		const date = new Date();
		return datePipe.transform(date, 'MMMM dd, y hh:mm:ss.SSS');
	}

	startHallPassTrace(hallPassId: number) {
		if (!this.kiosk.isKioskMode()) {
			return;
		}
		if (hallPassId in this.activeTraces) {
			return;
		}
		this.activeTraces[hallPassId] = {
			1: {
				step: 1,
				name: 'Start',
				timestamp: this.createdFormattedTimestamp(),
			},
		};
	}

	markTraceCheckpoint(hallPassId: number, step: number, name: string, extra?: Record<string, unknown>) {
		if (!this.kiosk.isKioskMode()) {
			return;
		}
		if (!(hallPassId in this.activeTraces)) {
			return;
		}

		const trace = this.activeTraces[hallPassId];
		trace[step] = {
			name,
			step,
			extra,
			timestamp: this.createdFormattedTimestamp(),
		};
	}

	private stopTrace(hallPassId: number) {
		delete this.activeTraces[hallPassId];
	}
}
