import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-octagon',
	templateUrl: './octagon.component.html',
	styleUrls: ['./octagon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OctagonComponent implements OnInit {
	@Input() size = 70;
	@Input() backgroundColor = '#E32C66';
	@Input() borderColor = '#EF8BAB';
	@Input() borderWidth = 2.5;
	@Input() hasTooltip: boolean;
	@Input() tooltipText: string;

	constructor() {}

	ngOnInit(): void {}
}
