import { Pipe, PipeTransform } from '@angular/core';
import { HallPass } from '../models/HallPass';
import * as moment from 'moment/moment';
import { ClassAgendaItem } from '../services/schedule.service';
import { SPClassWithUsers } from '../services/classes.service';

@Pipe({
	name: 'classUpcomingPassesCount',
})
export class ClassUpcomingPassesCountPipe implements PipeTransform {
	transform(passes: HallPass[], agenda: ClassAgendaItem): number {
		// this gets a count of passes that are upcoming during a class period
		if (passes && passes.length) {
			const startTime = moment(agenda.start_time, 'HH:mm');
			const endTime = moment(agenda.end_time, 'HH:mm');
			const classStudentIds = agenda.class.class_users.students.map((s) => s.user.id);
			// get passes in the period time
			const passesDuringPeriod = passes.filter((pass) => {
				const passStartTime = moment(pass.start_time);
				return passStartTime.isBetween(startTime, endTime) && classStudentIds.includes(pass.student.id);
			});
			// only count passes for a student once
			return passesDuringPeriod.reduce((accumulator, current) => {
				if (!accumulator.some((pass) => pass.student.id === current.student.id)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []).length;
		}
		return 0;
	}
}
