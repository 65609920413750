<div class="tw-mb-2">
	<p class="tw-my-0 tw-text-base tw-font-bold tw-text-navy-500 tw-p-b-2 add-term-title">
		Additional Terms <span class="tw-font-normal">(optional)</span>
	</p>
	<p [ngClass]="{ 'add-term-subtitle': scheduleSetupFlow }" class="tw-my-0 tw-font-normal tw-text-gray-500">
		Add if you have classes that occur for just a semester, trimester, quarter, etc.
		<span class="tw-underline tw-cursor-pointer" (click)="openAdditionalTermsLearnMoreLink()">Learn more</span>
	</p>
</div>
<div *ngIf="subTermsToEmit.length === 0 && scheduleSetupFlow; else addTermButton" class="no-terms">
	<img class="no-term-svg" [src]="'./assets/Clock Schedule (Gray 300).svg'" />
	<div class="no-term-title">No Terms Added</div>
	<div class="no-term-subtitle">You can always add terms later on the Bell Schedule page.</div>
	<div class="add-term">
		<app-gradient-button
			[cornerRadius]="'8px'"
			[disabled]="disableAddTermButton || disableAddingOrEditing"
			customToolTip
			[position]="'bottom'"
			[contentTemplate]="disableAddTermButton ? addTermTooltip : null"
			[textColor]="'#7083A0'"
			[border]="'1px solid #D4D9E2'"
			[withShadow]="false"
			[size]="'editable'"
			leftIcon="./assets/Add (Gray).svg"
			[fontSize]="'12px'"
			[fontWeight]="'normal'"
			[leftImageHeight]="'12px'"
			leftImageWidth="12px"
			[width]="'88px'"
			[minHeight]="'31px'"
			[gradient]="'#FFF, #FFF'"
			[customShadow]="'0px 2px 10px 0px #10141833'"
			[cursor]="disableAddTermButton || disableAddingOrEditing ? 'default' : 'pointer'"
			(buttonClick)="!disableAddTermButton && !disableAddingOrEditing && openAddSubTermModal()"
			>Add Term
		</app-gradient-button>
	</div>
</div>
<ng-template #addTermTooltip>
	<div class="ds-tooltip" style="padding-bottom: 12px">
		<div>You can't add a term because overlapping terms aren't supported. Edit your existing terms to be shorter.</div>
		<div (click)="openAddTermLearnMoreLink()" class="tw-pt-5 tw-underline tw-cursor-pointer">Learn more</div>
	</div>
</ng-template>

<div *ngIf="subTermsToEmit.length > 0" [ngClass]="{ 'tw-mb-1': scheduleSetupFlow }">
	<div
		class="tw-bg-gray-050 tw-rounded-lg tw-p-4 tw-my-2 tw-flex tw-justify-between tw-items-center"
		[ngClass]="{ 'tw-mb-0': !scheduleSetupFlow && last }"
		*ngFor="let subterm of subTermsToEmit; let i = index; let last = last">
		<div class="tw-leading-5">
			<div class="tw-text-navy-500 tw-text-base tw-font-bold tw-pb-1">{{ subterm.term_name }}</div>
			<div class="tw-text-gray-500 tw-text-sm tw-font-normal">
				{{ subterm.start_date | momentFormatPipe : 'MMMM D, YYYY' }} - {{ subterm.end_date | momentFormatPipe : 'MMMM D, YYYY' }}
			</div>
		</div>
		<ng-template #deleteTooltip>
			<div class="ds-tooltip" style="padding-bottom: 12px" *ngIf="subterm.hasClassesAssigned">
				<div>Terms can’t be deleted once classes have been added.</div>
			</div>
			<div class="ds-tooltip" style="padding-bottom: 12px" *ngIf="!subterm.hasClassesAssigned">Delete Term</div>
		</ng-template>
		<ng-template #editTooltip>
			<div class="ds-tooltip" style="padding-bottom: 12px">Edit Term</div>
		</ng-template>
		<div class="tw-flex tw-gap-4">
			<div customToolTip [position]="'top'" [contentTemplate]="deleteTooltip">
				<img
					*ngIf="subterm.hasClassesAssigned || disableAddingOrEditing"
					class="tw-opacity-25"
					height="14"
					width="14"
					[src]="'./assets/Delete (Gray-500).svg' | resolveAsset" />
				<img
					*ngIf="!subterm.hasClassesAssigned && !disableAddingOrEditing"
					class="tw-cursor-pointer"
					height="14"
					width="14"
					[src]="'./assets/Delete (Gray-500).svg' | resolveAsset"
					(click)="openDeleteSubTermModal(i)" />
			</div>
			<div customToolTip [position]="'top'" [contentTemplate]="editTooltip">
				<img
					class="tw-cursor-pointer"
					[ngClass]="{ 'tw-opacity-25': disableAddingOrEditing }"
					[src]="'./assets/Edit (Gray).svg' | resolveAsset"
					height="14"
					width="14"
					(click)="!disableAddingOrEditing && openEditSubTermModal(i, subterm.id)" />
			</div>
		</div>
	</div>
</div>
<ng-template #addSubTermModal>
	<sp-school-term
		[allowStartDateInPast]="true"
		[placeholder]="'Term Name'"
		[termNameErrorMessage]="'Please enter a term name.'"
		[isFocused]="true"
		[termForm]="subTermForm"
		[allowEndDateInPast]="true"
		[tooltipDates]="calendarService.tooltipDates$ | async"
		[schoolYearName]="schoolYearName"
		[label]="'Term Name'"
		[minDate]="schoolYearStartDate"
		[maxDate]="schoolYearEndDate"></sp-school-term>
</ng-template>
<ng-template #editSubTermModal>
	<sp-school-term
		[allowStartDateInPast]="true"
		[allowEndDateInPast]="true"
		[termForm]="subTermForm"
		[tooltipDates]="calendarService.tooltipDates$ | async"
		[schoolYearName]="schoolYearName"
		[label]="'Term Name'"
		[minDate]="schoolYearStartDate"
		[maxDate]="schoolYearEndDate"></sp-school-term>
</ng-template>
<ng-template #deleteSubTermModal>
	<div class="tw-text-gray-500 tw-text-base tw-font-normal">
		Are you sure you want to delete {{ subTermSelectedForDeletion }}? This action will remove the term for everyone and cannot be undone.
	</div>
</ng-template>
<ng-template #addTermButton>
	<div class="add-term">
		<app-gradient-button
			[cornerRadius]="'8px'"
			[disabled]="disableAddTermButton || disableAddingOrEditing"
			customToolTip
			[position]="'bottom'"
			[contentTemplate]="disableAddTermButton ? addTermTooltip : null"
			[textColor]="'#7083A0'"
			[border]="'1px solid #D4D9E2'"
			[withShadow]="false"
			[size]="'editable'"
			leftIcon="./assets/Add (Gray).svg"
			[fontSize]="'12px'"
			[fontWeight]="'normal'"
			[leftImageHeight]="'12px'"
			leftImageWidth="12px"
			[width]="'88px'"
			[minHeight]="'31px'"
			[gradient]="'#FFF, #FFF'"
			[customShadow]="'0px 2px 10px 0px #10141833'"
			[cursor]="disableAddTermButton || disableAddingOrEditing ? 'default' : 'pointer'"
			(buttonClick)="!disableAddTermButton && !disableAddingOrEditing && openAddSubTermModal()"
			>Add Term
		</app-gradient-button>
	</div>
</ng-template>
