import { Injectable, TemplateRef } from '@angular/core';
import { HttpService } from './http-service';
import { concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { PassLimitDialogComponent } from '../create-hallpass-forms/main-hallpass--form/locations-group-container/pass-limit-dialog/pass-limit-dialog.component';
import {
	ConfirmationDialogComponent,
	ConfirmationTemplates,
	RecommendedDialogConfig,
} from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';
import { LocationsService } from './locations.service';
import { PassLimitService } from './pass-limit.service';
import { MatDialog } from '@angular/material/dialog';
import { Request } from '../models/Request';
import { PassLimit } from '../models/PassLimit';
import { FrequencyType, StudentPassLimit } from '../models/HallPassLimits';
import { ColorProfile } from '../models/ColorProfile';
import { Location } from '../models/Location';
import { User } from '../models/User';
import { FeatureFlagService, FLAGS } from './feature-flag.service';
import { PollingEvent, PollingService } from './polling-service';
import { Invitation } from '../models/Invitation';
import { HallPass } from '../models/HallPass';
import { DynamicDialogData } from 'app/dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DialogFactoryService } from 'app/dialog-factory.service';

export interface AcceptRequestBody {
	duration?: string;
	teacher_pin?: string;
	overrode_student_pass_limit?: boolean;
	overrode_room_active_limit?: boolean;
}

export interface AcceptResponseBody {
	cancellable_by_student: boolean;
	cancelled: null;
	color_profile: ColorProfile;
	created: string; // date string
	destination: Location;
	end_time: string; // date
	expiration_time: string; // date
	flow_start: string; // date
	gradient_color: string; // "HEX:HEX"
	icon: string; // icon url string
	id: number;
	issuer: User;
	issuer_message: string;
	last_read: string; // date
	last_updated: string; // date
	needs_check_in: boolean;
	origin: Location;
	parent_invitation: number;
	parent_request: number;
	school_id: number;
	start_time: string; // date
	student: User;
	travel_type: 'round_trip' | 'one_way';
}

const OverrideMessage = 'override cancelled';
const OverrideCancelled = new Error(OverrideMessage);

@Injectable({
	providedIn: 'root',
})
export class RequestsService {
	constructor(
		private http: HttpService,
		private locationsService: LocationsService,
		private passLimitService: PassLimitService,
		private features: FeatureFlagService,
		private dialog: MatDialog,
		private pollingService: PollingService,
		private dialogFactoryService: DialogFactoryService
	) {}

	// Invitations
	createInvitation(data) {
		return this.http.post('v1/invitations/bulk_create', data);
	}

	acceptInvitation(id, data) {
		return this.http.post(`v1/invitations/${id}/accept`, data);
	}

	denyInvitation(id, data) {
		return this.http.post(`v1/invitations/${id}/deny`, data);
	}

	cancelInvitation(id, data) {
		return this.http.post(`v1/invitations/${id}/cancel`, data);
	}

	// Requests
	createRequest(data) {
		return this.http.post('v1/pass_requests', data);
	}

	acceptRequest(request: Request, data: AcceptRequestBody): Observable<HallPass> {
		return this.http.post(`v1/pass_requests/${request.id}/accept`, data).pipe(map((response) => HallPass.fromJSON(response)));
	}

	private openOverrideRoomLimitDialog(passLimit: PassLimit): Observable<boolean> {
		return this.dialog
			.open(PassLimitDialogComponent, {
				panelClass: 'overlay-dialog',
				backdropClass: 'custom-backdrop',
				width: '450px',
				height: '215px',
				disableClose: true,
				data: {
					passLimit: passLimit.max_passes_to,
					studentCount: 1,
					currentCount: passLimit.to_count,
				},
			})
			.afterClosed()
			.pipe(map((result) => result.override));
	}

	private overrideRoomLimit(body: AcceptRequestBody, request: Request): Observable<AcceptRequestBody> {
		if (this.features.isFeatureEnabled(FLAGS.WaitInLine)) {
			return of(body);
		}
		return this.locationsService.getPassLimit().pipe(
			map((pl) => pl.pass_limits),
			filter((pl) => pl.length > 0),
			map((pl) => pl.find((p) => p.id.toString() === request.destination.id.toString())),
			take(1),
			concatMap((pl) => {
				const roomLimitReached = pl ? pl?.max_passes_to_active && pl?.max_passes_to <= pl?.to_count : false;

				if (!roomLimitReached) {
					return of(body);
				}

				return this.openOverrideRoomLimitDialog(pl).pipe(
					concatMap((overrodeRoomLimit) => {
						if (!overrodeRoomLimit) {
							return throwError(OverrideCancelled);
						}
						return of({ ...body, overrode_room_active_limit: true });
					})
				);
			})
		);
	}

	private openOverrideStudentLimitDialog(
		studentPassLimit: StudentPassLimit,
		request: Request,
		bodyTemplate: TemplateRef<any>,
		frequency: FrequencyType
	): Observable<boolean> {
		const frequencyText = frequency === 'day' ? 'today' : 'this ' + frequency;
		const data: DynamicDialogData = {
			headerText: `Student's Pass limit reached: ${request.student.display_name} has had ${studentPassLimit.passLimit}/${studentPassLimit.passLimit} passes ${frequencyText}`,
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Override Limit',
			secondaryButtonLabel: 'Cancel',
			modalBody: bodyTemplate,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66',
			primaryButtonTextColor: 'white',
			classes: 'tw-min-h-0',
			templateData: {},
			icon: {
				name: 'Pass Limit (White).svg',
				background: '#6651F1',
				spacing: '16px',
			},
		};

		return this.dialogFactoryService
			.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false })
			.closed$.pipe(map((action) => action === 'primary'));
	}

	private overrideStudentLimit(body: AcceptRequestBody, request: Request, overriderTemplate: TemplateRef<any>): Observable<AcceptRequestBody> {
		// Fetch the pass limit
		return this.passLimitService.getPassLimit().pipe(
			switchMap((passLimit) => {
				// Use pass limit to fetch student pass limit
				return this.passLimitService.getStudentPassLimit(request.student.id).pipe(
					take(1),
					concatMap((studentLimit) => {
						if (studentLimit.noLimitsSet) {
							return of(body);
						}

						return this.passLimitService.getRemainingLimits({ studentId: request.student.id }).pipe(
							take(1),
							map((response) => response.remainingPasses === 0),
							concatMap((limitReached) => {
								if (!limitReached) {
									return of(body);
								}

								return this.openOverrideStudentLimitDialog(studentLimit, request, overriderTemplate, passLimit.pass_limit.frequency).pipe(
									concatMap((overrodeLimit) => {
										if (!overrodeLimit) {
											return throwError(OverrideCancelled);
										}

										return of({ ...body, overrode_student_pass_limit: true });
									})
								);
							})
						);
					})
				);
			})
		);
	}

	checkLimits(body: AcceptRequestBody, request: Request, overriderBody: TemplateRef<any>): Observable<AcceptRequestBody> {
		return this.overrideRoomLimit(body, request).pipe(concatMap((updatedBody) => this.overrideStudentLimit(updatedBody, request, overriderBody)));
	}

	denyRequest(id, data) {
		return this.http.post(`v1/pass_requests/${id}/deny`, data);
	}

	cancelRequest(id) {
		return this.http.post(`v1/pass_requests/${id}/cancel`);
	}

	watchRequestDeny(id: number): Observable<Request> {
		return this.filterRequestWithId(id, this.pollingService.listenOnCurrentSchool('pass_request.deny'));
	}

	watchRequestAccept(id): Observable<HallPass> {
		return this.pollingService.listenOnCurrentSchool('pass_request.accept').pipe(
			filter((e) => {
				const data = e?.data as HallPass | { parent_request: number };
				return data?.parent_request === id;
			}),
			map((event) => HallPass.fromJSON(event?.data))
		);
	}

	watchRequestUpdate(id): Observable<Request> {
		return this.filterRequestWithId(id, this.pollingService.listenOnCurrentSchool('pass_request.update'));
	}

	watchRequestCancel(id): Observable<Request> {
		return this.filterRequestWithId(id, this.pollingService.listenOnCurrentSchool('pass_request.cancel'));
	}

	watchRequestDelete(id): Observable<Request> {
		return this.filterRequestWithId(id, this.pollingService.listenOnCurrentSchool('pass_request.delete'));
	}

	watchRequestsCanceledForStudent(userId: number): Observable<Request> {
		return this.pollingService.listen('pass_request.cancel').pipe(
			map((e) => Request.fromJSON(e.data)),
			filter((r) => r.student.id === userId)
		);
	}
	watchRequestsCanceledForTeacher(userId: number): Observable<Request> {
		return this.pollingService.listen('pass_request.cancel').pipe(
			map((e) => Request.fromJSON(e.data)),
			filter((r) => (r.teachers.find((teacher) => teacher.id === userId) ? true : false))
		);
	}

	watchInvitationCancel(id): Observable<Invitation> {
		return this.pollingService.listenOnCurrentSchool('pass_invitation.cancel').pipe(
			filter((e) => (e?.data as Invitation)?.id === id),
			map((event) => Invitation.fromJSON(event?.data))
		);
	}

	watchInvitationsCancelledByLocation(locationIds: number[]): Observable<Invitation> {
		return this.pollingService.listen('pass_invitation.cancel').pipe(
			map((e) => Invitation.fromJSON(e.data)),
			filter((p) => locationIds.includes(p.destination.id))
		);
	}

	watchInvitationsCancelledForIssuer(issuerId: number): Observable<Invitation> {
		return this.pollingService.listen('pass_invitation.cancel').pipe(
			map((e) => Invitation.fromJSON(e.data)),
			filter((p) => p.issuer.id === issuerId)
		);
	}

	watchInvitationsCanceledForStudent(userId: number): Observable<Invitation> {
		return this.pollingService.listen('pass_invitation.cancel').pipe(
			map((e) => Invitation.fromJSON(e.data)),
			filter((r) => r.student.id === userId)
		);
	}

	watchInvitationsDeclinedByLocation(locationIds: number[]): Observable<Invitation> {
		return this.pollingService.listen('pass_invitation.deny').pipe(
			map((e) => Invitation.fromJSON(e.data)),
			filter((p) => locationIds.includes(p.destination.id))
		);
	}

	watchInvitationAcceptForLocations(locationIds: number[]): Observable<HallPass> {
		return this.pollingService.listen('pass_invitation.accept').pipe(
			map((e) => HallPass.fromJSON(e.data)),
			filter((p) => locationIds.includes(p.destination.id))
		);
	}
	watchInvitationAcceptForIssuer(issuerId: number): Observable<HallPass> {
		return this.pollingService.listen('pass_invitation.accept').pipe(
			map((e) => HallPass.fromJSON(e.data)),
			filter((p) => p.issuer.id === issuerId)
		);
	}

	watchInvitationAccept(id): Observable<HallPass> {
		return this.pollingService.listenOnCurrentSchool('pass_invitation.accept').pipe(
			filter((e) => parseInt((e?.data as unknown as HallPass)?.parent_invitation, 10) === id),
			map((event) => HallPass.fromJSON(event?.data))
		);
	}

	filterRequestWithId(id: number, events: Observable<PollingEvent>): Observable<Request> {
		return events.pipe(
			map((e) => Request.fromJSON(e.data)),
			filter((r) => r.id == id)
		);
	}
}
