<ng-template #Rooms>
	<div #menu class="currently-in" (click)="onClickOptions()">
		<div class="pinnable" [style.background]="pinnableBackground">
			<img draggable="false" [style.width]="'13px'" [style.height]="'13px'" [src]="pinnableIcon | resolveAsset" alt="ICON" />
		</div>
		<div class="text" *ngIf="selectedLocation || roomOptions.length">
			{{ selectedLocation?.title || roomOptions[0]?.title }}
		</div>
		<img class="twinkie" [src]="'./assets/Twinkie (Blue-Gray).svg' | resolveAsset" alt="options menu" />
	</div>
</ng-template>

<ng-container *ngIf="!roomOptions?.length && !selectedLocation; else Rooms">
	<!--	Don't show anything -->
</ng-container>
