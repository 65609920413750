import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { DarkThemeSwitch } from '../../dark-theme-switch';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from '../../services/http-service';
import { NoFlyTime } from '../../models/NoFlyTime';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { TimeRangeInputComponent } from '../../shared/time-range-input/time-range-input/time-range-input.component';
//Can be 'text', 'multilocation', 'multiuser', or 'dates'  There may be some places where multiuser may need to be split into student and teacher. I tried finding a better way to do this, but this is just short term.

export type RoundInputType = 'text' | 'multilocation' | 'multiuser' | 'dates' | 'email';

@Component({
	selector: 'app-no-fly-times',
	templateUrl: './no-fly-times.component.html',
	styleUrls: ['./no-fly-times.component.scss'],
	exportAs: 'noFlyTimesRef',
})
export class NoFlyTimesComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChildren(TimeRangeInputComponent) timeRangeInputs: QueryList<TimeRangeInputComponent>;

	@Input() noFlyTimes: NoFlyTime[] = [];

	@Output() onAddNoFlyTime = new EventEmitter<void>();
	@Output() onDeleteNoFlyTime = new EventEmitter<number>();
	@Output() onUpdateNoFlyTime = new EventEmitter<number>();

	e: Observable<Event>;
	nftFormControls: FormControl[] = [];
	numberOfNft = 0;
	focusedIndex = -1;
	private destroyer$ = new Subject<void>();

	constructor(
		public httpService: HttpService,
		public dialog: MatDialog,
		public darkTheme: DarkThemeSwitch,
		public sanitizer: DomSanitizer,
		public elRef: ElementRef
	) {}

	ngOnInit() {
		this.populateNoFlyTimeFormControls();
		this.numberOfNft = this.getNumberOfNoFlyTimes(0);
	}

	ngOnChanges(sc: SimpleChanges) {
		if (sc.noFlyTimes) {
			this.populateNoFlyTimeFormControls();
		}
	}

	ngOnDestroy(): void {
		this.destroyer$.next();
		this.destroyer$.complete();
	}

	addNoFlyTime() {
		this.onAddNoFlyTime.emit();
		this.numberOfNft = this.getNumberOfNoFlyTimes(1);
	}

	deleteNoFlyTime(index: number) {
		this.numberOfNft = this.getNumberOfNoFlyTimes(-1);
		this.onDeleteNoFlyTime.emit(index);
	}

	updateNoFlyTime(index) {
		this.onUpdateNoFlyTime.emit(index);
	}

	onInputChange(index: number, times: { start: string; end: string }) {
		this.noFlyTimes[index].start_time = times.start;
		this.noFlyTimes[index].end_time = times.end;
		this.updateNoFlyTime(index);
	}

	onFocusChange(index: number, focus: { startInputFocused: boolean; endInputFocused: boolean }) {
		if (focus.startInputFocused || focus.endInputFocused) {
			this.focusedIndex = index;
		} else {
			this.focusedIndex = -1;
		}
	}

	populateNoFlyTimeFormControls() {
		this.nftFormControls = [];
		const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

		for (let i = 0; i < this.noFlyTimes.length; i++) {
			const nft = this.noFlyTimes[i];
			const formattedStartTime = timeFormatRegex.test(nft.start_time) ? nft.start_time : this.formatTimeString24Hour(nft.start_time);
			const formattedEndTime = timeFormatRegex.test(nft.end_time) ? nft.end_time : this.formatTimeString24Hour(nft.end_time);
			const timeControl = new FormControl({ start: formattedStartTime, end: formattedEndTime });
			timeControl.setValidators([this.validateNoFlyTimes(i)]);
			this.nftFormControls.push(timeControl);
		}
	}

	validateNoFlyTimes(index: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const { start, end } = control.value || {};

			const hasOverlap = this.noFlyTimes
				.filter((_, i) => i !== index && this.noFlyTimes[i]?.change_type !== 'delete')
				.some((otherNoFlyTime) => {
					return this.doesTimeRangeOverlap(
						start,
						end,
						this.checkTimeStrFormat(otherNoFlyTime.start_time),
						this.checkTimeStrFormat(otherNoFlyTime.end_time)
					);
				});

			if (hasOverlap) {
				return { both: 'Time range cannot overlap with another time' };
			}
			return null;
		};
	}
	checkTimeStrFormat(timeStr: string): string {
		const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
		return timeFormatRegex.test(timeStr) ? timeStr : moment.utc(timeStr).format('HH:mm');
	}

	getNumberOfNoFlyTimes(addOrDelete: number): number {
		let numberOfNft = 0;
		for (const nft of this.noFlyTimes) {
			if (!nft?.change_type || nft?.change_type !== 'delete') {
				numberOfNft++;
			}
		}
		return numberOfNft + addOrDelete;
	}

	doesTimeRangeOverlap(start1: string, end1: string, start2: string, end2: string): boolean {
		return (
			(start1 > start2 && start1 < end2) ||
			(end1 > start2 && end1 < end2) ||
			start1 === start2 ||
			start1 === end2 ||
			end1 === start2 ||
			end1 === end2 ||
			(start2 > start1 && start2 < end1) ||
			(end2 > start1 && end2 < end1)
		);
	}

	checkForErrors(): ValidationErrors | null {
		const timeRangeInputsArray = this.timeRangeInputs.toArray();

		for (let index = 0; index < timeRangeInputsArray.length; index++) {
			const formControl = this.nftFormControls[index];

			if (formControl && formControl.errors) {
				return formControl.errors;
			}
		}

		return null;
	}

	private formatTimeString24Hour(timeStr: string): string {
		const formattedTime = moment.utc(timeStr).format('HH:mm');
		return formattedTime;
	}
}
