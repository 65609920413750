import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sp-room-icon',
	templateUrl: './room-icon.component.html',
	styleUrls: ['./room-icon.component.scss'],
})
export class RoomIconComponent implements OnInit {
	@Input() iconPath: string;
	@Input() iconColor: string;
	@Input() altText: string;
	@Input() height = '48px';
	@Input() width = '48px';

	filter: string;

	ngOnInit(): void {
		this.filter = this.getFilter();
	}

	// hard coding these for now - used https://codepen.io/sosuke/pen/Pjoqqp to calculate them
	private getFilter(): string {
		switch (this.iconColor) {
			case '#E32C66':
				return 'brightness(0) saturate(100%) invert(27%) sepia(32%) saturate(6565%) hue-rotate(326deg) brightness(93%) contrast(90%)';
				break;
			case '#F86F4D':
				return 'brightness(0) saturate(100%) invert(64%) sepia(31%) saturate(5712%) hue-rotate(329deg) brightness(99%) contrast(96%)';
				break;
			case '#F59602':
				return 'brightness(0) saturate(100%) invert(63%) sepia(62%) saturate(1210%) hue-rotate(349deg) brightness(91%) contrast(97%)';
				break;
			case '#72CC1B':
				return 'brightness(0) saturate(100%) invert(70%) sepia(12%) saturate(5115%) hue-rotate(46deg) brightness(104%) contrast(79%)';
				break;
			case '#EBBB00':
				return 'brightness(0) saturate(100%) invert(80%) sepia(41%) saturate(5068%) hue-rotate(10deg) brightness(104%) contrast(101%)';
				break;
			case '#04CD33':
				return 'brightness(0) saturate(100%) invert(51%) sepia(59%) saturate(1144%) hue-rotate(91deg) brightness(100%) contrast(97%)';
				break;
			case '#12C29E':
				return 'brightness(0) saturate(100%) invert(58%) sepia(71%) saturate(1370%) hue-rotate(122deg) brightness(94%) contrast(86%)';
				break;
			case '#07ABC3':
				return 'brightness(0) saturate(100%) invert(50%) sepia(98%) saturate(1079%) hue-rotate(147deg) brightness(89%) contrast(94%)';
				break;
			case '#139BE6':
				return 'brightness(0) saturate(100%) invert(42%) sepia(91%) saturate(1348%) hue-rotate(173deg) brightness(98%) contrast(85%)';
				break;
			case '#134482':
				return 'brightness(0) saturate(100%) invert(18%) sepia(23%) saturate(6495%) hue-rotate(202deg) brightness(89%) contrast(88%)';
				break;
			case '#6651F1':
				return 'brightness(0) saturate(100%) invert(32%) sepia(70%) saturate(4369%) hue-rotate(238deg) brightness(98%) contrast(92%)';
				break;
			case '#5352E4':
				return 'brightness(0) saturate(100%) invert(31%) sepia(96%) saturate(2803%) hue-rotate(232deg) brightness(91%) contrast(95%)';
				break;
			case '#AC0CDF':
				return 'brightness(0) saturate(100%) invert(14%) sepia(77%) saturate(7252%) hue-rotate(285deg) brightness(95%) contrast(103%)';
				break;
			case '#DA389D':
				return 'brightness(0) saturate(100%) invert(65%) sepia(90%) saturate(6749%) hue-rotate(302deg) brightness(91%) contrast(86%)';
				break;
			case '#7E879D':
				return 'brightness(0) saturate(100%) invert(58%) sepia(9%) saturate(747%) hue-rotate(185deg) brightness(89%) contrast(87%)';
				break;
			case '#FFF':
				return '';
				break;
			default:
				return 'brightness(0) saturate(100%) invert(54%) sepia(17%) saturate(616%) hue-rotate(177deg) brightness(91%) contrast(89%)';
				break;
		}
	}
}
