import { Action, createReducer, on } from '@ngrx/store';
import { IIntrosState } from '../state';
import * as introsActions from '../actions';

export const introsInitialState: IIntrosState = {
	data: null,
	loading: false,
	loaded: false,
};

const reducer = createReducer(
	introsInitialState,
	on(
		introsActions.getIntros,
		introsActions.updateIntros,
		introsActions.updateIntrosMain,
		introsActions.updateIntrosEncounter,
		introsActions.updateIntrosSearch,
		introsActions.updateIntrosShareSmartpass,
		introsActions.updateIntrosHelpCenter,
		introsActions.updateIntrosStudentPassLimits,
		introsActions.updateIntrosAdminPassLimitsMessage,
		introsActions.updateIntrosPassLimitsOnlyCertainRooms,
		introsActions.updateIntrosSortPassRequestsNux,
		(state) => ({ ...state, loading: true, loaded: false, data: null })
	),
	on(
		introsActions.getIntrosSuccess,
		introsActions.updateIntrosSuccess,
		introsActions.updateIntrosMainSuccess,
		introsActions.updateIntrosEncounterSuccess,
		introsActions.updateIntrosSearchSuccess,
		introsActions.updateIntrosShareSmartpassSuccess,
		introsActions.updateIntrosHelpCenterSuccess,
		introsActions.updateIntrosStudentPassLimitsSuccess,
		introsActions.updateIntrosAdminPassLimitsMessageSuccess,
		introsActions.updateIntrosDisableRoomSuccess,
		introsActions.updateIntrosPassLimitsOnlyCertainRoomsSuccess,
		introsActions.updateIntrosSortPassRequestsNuxSuccess,
		(state: any, { data }: any) => {
			return { ...state, loading: false, loaded: true, data };
		}
	)
);

export function IntrosReducer(state: any | undefined, action: Action) {
	return reducer(state, action);
}
