import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogPosition } from '@angular/material/dialog/dialog-config';
import { ScreenService } from '../../services/screen.service';
import { UNANIMATED_CONTAINER } from '../../consent-menu-overlay';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { filter, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { PassesAnimations } from '../../passes/passes.animations';

@Component({
	selector: 'sp-display-options',
	templateUrl: './display-options.component.html',
	styleUrls: ['./display-options.component.scss'],
	animations: [PassesAnimations.TooltipFadeInOut],
})
export class DisplayOptionsComponent implements OnInit {
	@ViewChild('displayOptionsElement') displayOptionsElement: ElementRef<HTMLElement>;

	private showEndedPasses = false;
	//remove later when new homepage UI is released to all users
	showSwitchRooms = false;
	toggleForm: FormGroup;
	isOpenRoomOptions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	roomOptionsPosition = [
		{
			originX: 'start',
			originY: 'bottom',
			overlayX: 'start',
			overlayY: 'top',
			offsetX: -255, // Adjust this value as needed
			offsetY: -50, //
		},
	];
	optionsHovered = false;
	dropDownHovered = false;
	dropDownWidth = 0;

	constructor(
		public dialogRef: MatDialogRef<DisplayOptionsComponent>,
		public optionDialog: MatDialogRef<DropdownComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialog: MatDialog,
		public screenService: ScreenService
	) {
		this.showSwitchRooms = data.showSwitchRooms;
		this.showEndedPasses = data.showEndedPasses;
	}

	ngOnInit(): void {
		this.data.isTooltip = this.data.showSwitchRooms && this.data.roomOptions && this.data.roomOptions.length > 1;
		this.setDropDownWidth();
		this.toggleForm = new FormGroup(
			{
				toggle: new FormControl(this.showEndedPasses),
			},
			[]
		);
		this.dialogRef.afterOpened().subscribe((res) => {
			this.updatePosition();
		});
	}

	private setDropDownWidth(): void {
		if (!this.data?.roomOptions?.length) {
			return;
		}

		const roomNames = this.data.roomOptions;

		// Initialize a variable to store the maximum width
		let maxWidth = 0;

		// Create a temporary div to measure the scrollbar width
		const scrollDiv = document.createElement('div');
		scrollDiv.style.overflow = 'scroll';
		scrollDiv.style.visibility = 'hidden';
		document.body.appendChild(scrollDiv);
		const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);

		// Loop through roomNames to find the maximum width
		roomNames.forEach((room) => {
			// Create a temporary span element for each room name
			const tempSpan = document.createElement('span');
			tempSpan.style.fontSize = '14px';
			tempSpan.style.fontWeight = 'normal';
			tempSpan.style.visibility = 'hidden';
			tempSpan.style.position = 'absolute';
			tempSpan.style.whiteSpace = 'nowrap';
			tempSpan.innerText = room.title + ' (' + room.room + ')';

			// Append the temporary span to the document body to measure its width
			document.body.appendChild(tempSpan);

			// Get the width of the temporary span
			const width = tempSpan.offsetWidth;

			// Update maxWidth if the current width is greater
			maxWidth = Math.max(maxWidth, width);

			// Remove the temporary span from the document body
			document.body.removeChild(tempSpan);
		});

		//*** This would need to be updated if stylign changes on dropdown.component.html
		// Equation = someWidthForRoomTitle(text width) + 40(padding and marked roomicon) + 40(padding the option) + 20(padding of entire container) + 10px buffer for smoothness
		this.dropDownWidth = maxWidth + 40 + 40 + 20 + 10;
		//+ (scrollbarWidth when theres 6 or more options) + 1 for a buffer
		if (roomNames.length >= 6 && scrollbarWidth > 0) {
			this.dropDownWidth += scrollbarWidth + 1;
		}
		//update dropdown position
		this.roomOptionsPosition = [
			{
				originX: 'start',
				originY: 'bottom',
				overlayX: 'start',
				overlayY: 'top',
				offsetX: -this.dropDownWidth,
				offsetY: -50, //
			},
		];
	}

	private updatePosition(): void {
		const top = this.dialogRef.componentInstance.displayOptionsElement.nativeElement.getBoundingClientRect().top;
		let left = this.dialogRef.componentInstance.displayOptionsElement.nativeElement.getBoundingClientRect().left;
		const width = this.dialogRef.componentInstance.displayOptionsElement.nativeElement.getBoundingClientRect().width;
		if (width + left > this.screenService.windowWidth) {
			left = this.screenService.windowWidth - width - 20;
		}
		const position: DialogPosition = {
			top: `${top}px`,
			left: `${left}px`,
		};
		this.dialogRef.updatePosition(position);
	}

	private displayOptionsPopover(target: HTMLElement): void {
		if (this.data.roomOptions && this.data.roomOptions.length > 1) {
			UNANIMATED_CONTAINER.next(true);
			this.optionDialog = this.dialog.open(DropdownComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'invis-backdrop',
				data: {
					heading: 'CHANGE ROOM',
					locations: this.data.roomOptions,
					selectedLocation: this.data.selectedLocation,
					trigger: target,
					positionConfig: 'left',
				},
			});

			this.optionDialog
				.afterClosed()
				.pipe(
					tap(() => {
						this.dialogRef?.close();
						UNANIMATED_CONTAINER.next(false);
					}),
					filter((res) => !!res)
				)
				.subscribe((data) => {
					this.data.selectedLocation = data.selectedRoom === 'all_rooms' ? null : data.selectedRoom;
					this.data.selectedLocation = data.selectedRoom;
					this.data.selectedLocationEmit.emit(data.selectedRoom);
				});
		}
	}

	emitSelectedLocation(location): void {
		this.data.selectedLocation = location === 'all_rooms' ? null : location;
		this.data.selectedLocation = location;
		this.data.selectedLocationEmit.emit(location);
		this.dialogRef?.close();
	}

	setOptionsHoveredFalse(value: boolean): void {
		setTimeout(() => {
			this.optionsHovered = false;
		}, 100);
	}

	setDropDownHoveredFalse(value: boolean): void {
		setTimeout(() => {
			this.dropDownHovered = false;
		}, 100);
	}

	setOptionsHoveredTrue(value: boolean): void {
		this.optionsHovered = true;
	}

	setDropDownHoveredTrue(value: boolean): void {
		this.dropDownHovered = true;
	}

	hideOptionsPopover(): void {
		this.optionDialog?.close();
	}

	onShowEndedPassesToggle(value): void {
		this.dialogRef.close('show_ended_passes');
	}
}
