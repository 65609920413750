import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DarkThemeSwitch, SPTheme } from '../dark-theme-switch';
import { ScreenService } from '../services/screen.service';
import { User } from '../models/User';
import { UserService } from '../services/user.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-sp-appearance',
	templateUrl: './sp-appearance.component.html',
	styleUrls: ['./sp-appearance.component.scss'],
})
export class SpAppearanceComponent implements OnInit, OnDestroy {
	selectedTheme: string;
	form: FormGroup;
	user: User;
	isStaff: boolean;
	showWrapper: boolean;

	destroy$: Subject<any> = new Subject<any>();

	constructor(
		private darkTheme: DarkThemeSwitch,
		private screenService: ScreenService,
		private userService: UserService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	get IpadDevice() {
		return this.screenService.isIpadWidth;
	}
	get extraLargeDevice() {
		return this.screenService.isDeviceLargeExtra;
	}

	ngOnInit() {
		if (this.data && this.data['fromFilter']) {
			this.showWrapper = this.data['fromFilter'];
			setTimeout(() => {
				this.showWrapper = false;
			}, 2000);
		}

		this.selectedTheme = this.darkTheme.currentTheme();
		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.user = user;
			this.isStaff = User.fromJSON(user).isTeacher() || User.fromJSON(user).isAssistant();
			this.form = new FormGroup({
				show_expired_passes: new FormControl(this.userService.showExpiredPasses.value),
			});
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setSelectedTheme(evt: SPTheme) {
		this.selectedTheme = evt;
		this.darkTheme.switchTheme(evt);
	}

	updateUser(value: Record<string, any>) {
		if ('show_expired_passes' in value) {
			this.userService.showExpiredPasses.next(value.show_expired_passes as boolean);
		}
		this.userService.updateUserRequest(this.user, value);
	}
}
