<div class="wrapper" [style.width]="width - 30 + 'px'" (click)="selected()" [style.background-color]="darkTheme.getBackground('extra', false)">
	<div class="disabled-content" *ngIf="disabled"></div>
	<div class="content">
		<div class="title" [style.color]="darkTheme.getColor({ white: '#1E194F', dark: '#FFFFFF' })">
			<img
				width="28"
				height="28"
				[src]="
					openInfo
						? darkTheme.getIcon({ iconName: icon, lightFill: 'Navy', darkFill: 'White' })
						: darkTheme.getIcon({ iconName: icon, lightFill: 'Blue-Gray', darkFill: 'White' })
				"
				alt="" />
			<div
				class="text"
				[style.color]="
					openInfo ? darkTheme.getColor({ white: '#1E194F', dark: '#FFFFFF' }) : darkTheme.getColor({ white: '#7F879D', dark: '#FFFFFF' })
				">
				{{ title + 's' }}
			</div>
		</div>

		<img width="16" height="16" *ngIf="openInfo || isSelected" [src]="'./assets/Mark (Jade).svg' | resolveAsset" alt="" />
		<img
			width="16"
			height="16"
			*ngIf="plusIcon && !openInfo"
			[src]="
				openInfo
					? darkTheme.getIcon({ iconName: 'Plus', lightFill: 'Navy', darkFill: 'White' })
					: darkTheme.getIcon({ iconName: 'Plus', lightFill: 'Blue-Gray', darkFill: 'White' })
			"
			alt="" />
	</div>
	<div class="info" *ngIf="openInfo && plusIcon">
		<mat-divider [style.margin]="'9px 0'"></mat-divider>

		<div class="items">
			<div class="item" *ngFor="let item of selector$ | async">
				{{ item.path }}
			</div>
		</div>
	</div>
</div>
