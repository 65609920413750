import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '../models/User';
import { HallPass } from '../models/HallPass';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../services/admin.service';
import { NextStep } from '../animations';
import { CreateFormService } from '../create-hallpass-forms/create-form.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetection } from '../device-detection.helper';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

@Component({
	selector: 'app-report-form',
	templateUrl: './report-form.component.html',
	styleUrls: ['./report-form.component.scss'],
	animations: [NextStep],
})
export class ReportFormComponent implements OnInit, OnDestroy {
	@ViewChild('messageBox') set content(content: ElementRef) {
		if (content) {
			content.nativeElement.focus();
		}
	}

	frameMotion$;
	formState = 'studentSelect';
	selectedStudents: User[] = [];
	showOptions = true;
	reportMessage = '';

	// click on closing elements will close the dialog
	// instead of other closing actions
	forceCloseClick = false;
	// do not use search input
	// but replace it with a dumb chip
	useChipInsteadSearch = false;
	// use search input on chip mode
	useChipMode = false;
	pass: HallPass;

	destroy$: Subject<any> = new Subject();
	heartBeat = new Subject<number>();
	constructor(
		private adminService: AdminService,
		private dialogRef: MatDialogRef<ReportFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private createForm: CreateFormService,
		private sanitizer: DomSanitizer
	) {
		timer(0, 1000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.heartBeat.next(0);
			});
	}

	ngOnInit() {
		this.frameMotion$ = this.createForm.getFrameMotionDirection();
		if (this.data?.report) {
			this.selectedStudents.push(this.data['report']);
			this.useChipInsteadSearch = this.data?.useChipInsteadSearch ?? this.useChipInsteadSearch;
			this.showOptions = !this.data['report'];
		}
		if (this.data?.pass) {
			this.pass = HallPass.fromJSON(this.data.pass);
		}
		this.useChipMode = this.data?.useChipMode ?? this.useChipMode;
	}

	textColor(item) {
		if (item.hovered) {
			return this.sanitizer.bypassSecurityTrustStyle('#1F195E');
		} else {
			return this.sanitizer.bypassSecurityTrustStyle('#555558');
		}
	}

	getBackground(item) {
		if (item.hovered) {
			if (item.pressed) {
				return '#E2E7F4';
			} else {
				return '#ECF1FF';
			}
		} else {
			return '#FFFFFF';
		}
	}

	setFormState(state: string) {
		this.formState = state;
		this.showOptions = this.formState === 'studentSelect';
	}

	sendReport() {
		const body = {
			students: this.selectedStudents.map((user) => user.id),
			message: this.reportMessage,
		};
		if (!!this.data?.pass && !!this.data?.isHallPass) {
			// ensure passid is null when we may bulk report more students
			// only on a single student a one reported pass may be
			const rpi = this.selectedStudents.length == 1 ? this.data.pass.id : null;
			if (rpi !== null) body['reported_pass_id'] = rpi;
		}

		this.adminService
			.sendReportRequest(body)
			.pipe(filter((res) => !!res))
			.subscribe((data) => {
				this.dialogRef.close(data);
			});
	}

	nextStep() {
		this.createForm.setFrameMotionDirection('forward');
		setTimeout(() => {
			this.showOptions = false;
		}, 100);
	}
	back() {
		this.createForm.setFrameMotionDirection('back');
		setTimeout(() => {
			if (!this.showOptions) {
				this.showOptions = true;
				if (this.forceCloseClick) {
					this.dialogRef.close(null);
				}
			} else {
				this.dialogRef.close(null);
			}
		}, 100);
	}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
