import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CreateFormService, FrameMotionTransition } from '../../create-hallpass-forms/create-form.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { User } from '../../models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { HttpService } from '../../services/http-service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-change-displayname',
	templateUrl: './change-displayname.component.html',
	styleUrls: ['./change-displayname.component.scss'],
})
export class ChangeDisplaynameComponent implements OnInit, OnDestroy {
	@Input() user: User;
	me: User;

	@Output() back: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancel: EventEmitter<never> = new EventEmitter<never>();

	frameMotion$: BehaviorSubject<FrameMotionTransition>;
	form: FormGroup;
	errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	destroy$: Subject<void> = new Subject<void>();

	constructor(
		private formService: CreateFormService,
		private router: Router,
		private userService: UserService,
		private http: HttpService,
		private toast: ToastService
	) {}

	get isSaveButton() {
		return this.form.get('firstName').valid && this.form.get('lastName').valid;
	}

	ngOnInit() {
		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.me = user;
		});
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.form = new FormGroup({
			firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
			lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
		});
		this.form.valueChanges.subscribe(() => {
			this.errorMessage$.next(null);
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	updateDisplayname() {
		this.userService
			.updateUser(this.user.id, {
				first_name: this.form.get('firstName').value,
				last_name: this.form.get('lastName').value,
			})
			.pipe(
				tap(() => {
					this.cancel.emit();
				}),
				map(() => {
					this.toast.openToast({
						title: 'User Name Change',
						type: 'success',
					});
				}),
				catchError(() => {
					return of(null);
				})
			)
			.subscribe();
	}
}
