import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-empty-state',
	templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
	@Input() title!: string;
	@Input() subtitle!: string;
}
