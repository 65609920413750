<div class="pass-limit-dialog">
	<div class="content">
		<div class="icon-wrapper">
			<img width="30" [src]="'./assets/Hand (Navy).svg' | resolveAsset" />
		</div>
		<div class="main-wrapper">
			<div class="main-text">
				<span>{{ header }}</span>
			</div>
			<div class="supporting-text">
				<span [innerHTML]="mainText"></span>
			</div>
		</div>
	</div>
	<div class="buttons">
		<app-gradient-button textColor="#7083A0" [customBackground]="'#F0F2F5'" [withShadow]="false" [mat-dialog-close]="cancelButtonResponse"
			>Cancel
		</app-gradient-button>
		<app-gradient-button
			textColor="#FFFFFF"
			[customBackground]="!isStudent ? '#E32C66' : '#7083A0'"
			[withShadow]="false"
			[leftIcon]="!isStudent ? null : './assets/Chevron Left (White).svg'"
			[mat-dialog-close]="confirmButtonResponse"
			>{{ data.isWaitInLine ? 'Skip the Line' : !isStudent ? 'Override' : 'Back' }}
		</app-gradient-button>
	</div>
</div>
