import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
	name: 'countdown',
})
export class CountdownPipe implements PipeTransform {
	transform(durationInMilliseconds: moment.Duration): string {
		if (durationInMilliseconds && durationInMilliseconds.asMilliseconds() > 0) {
			const minutes = Math.floor(durationInMilliseconds.asMinutes());
			const seconds = Math.floor(durationInMilliseconds.seconds());

			return `${this.minuteCount(minutes)}:${this.secondCount(seconds)}`;
		}
		return '';
	}

	private secondCount(value: number): string {
		return value < 10 ? `0${value}` : `${value}`;
	}

	private minuteCount(value: number): string {
		return value === 0 ? '00' : `${value}`;
	}
}
