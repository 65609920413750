import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { catchError, filter, map, scan, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Location } from '../models/Location';
import { User } from '../models/User';
import { HallPass } from '../models/HallPass';
import { HttpService } from './http-service';
import { Pinnable } from '../models/Pinnable';
import { HallPassesService } from './hall-passes.service';
import { PeriodGrouping, Schedule, ScheduleGroupList, Term } from '../models/Schedule';
import { UserService } from './user.service';

export type CreateSPClassReq = {
	id?: number;
	display_name: string;
	room_id?: number;
	school_id;
	status: ClassStatus;
};

export type CleverClassInfo = {
	section_name: string;
	course_name: string;
	period_name: string;
	term_name: string;
	subject: string;
	sis_id: string;
	grade: string;
	last_modified: Date;
};

export type ClasslinkClassInfo = {
	section_name: string;
	course_name: string;
	class_type: string;
	class_code: string;
	grades: string;
	period_name: string;
	term_name: string;
	subjects: string;
	location: string;
	terms: string;
	last_modified: Date;
};

export type GoogleClassInfo = {
	name: string;
	section: string;
	description: string;
	room: string;
	last_modified: Date;
};

export type SPClass = {
	id: number;
	synced_name?: string;
	display_name: string;
	terms: number[];
	period_name?: string;
	external_period_name: string;
	external_source_name: ClassSyncedFrom;
	status: ClassStatus;
	school_id: number;
	room?: Location;
	room_id?: number;
	term_id?: number;
	clever_info?: CleverClassInfo;
	classlink_info?: ClasslinkClassInfo;
	google_info?: GoogleClassInfo;
};

export type SPClassWithUsers = {
	class_users: SPClassUsers;
	collapsed?: boolean;
	pinnable?: Pinnable;
} & SPClass;

export type ClassDetailsModalData = {
	classDetails: SPClassWithUsers;
	schedules: Observable<Schedule[]>;
	listGroups: Observable<ScheduleGroupList[]>;
	selectedTabIndex?: number;
	initiateAddStudent?: boolean;
	autoSetFocusOnSearch?: boolean;
	dialogSecondaryBtnLbl?: string;
};

export class SPClassUser {
	user: User;
	external_source_name: string;

	constructor(u: any, external_source_name: string) {
		this.user = User.fromJSON(u);
		this.external_source_name = external_source_name;
	}

	static fromJSON(json): SPClassUser {
		return new SPClassUser(json['user'], json['external_source_name']);
	}
}

export type SPClassTeachers = {
	teachers: SPClassUser[];
};
export type SPClassStudents = {
	students: SPClassUser[];
};
export type SPClassUsers = SPClassTeachers & SPClassStudents;

export type SPClassUserType = {
	users: SPClassUser[];
};
export type SPClassPassOverview = {
	student: User;
	active_pass: HallPass;
	upcoming_passes: HallPass[];
};
export type SPClassPassOverviewReq = {
	class_id: number;
	upcoming_passes_start_before?: Date;
};
export type SPClassPassOverviewResp = {
	students_and_passes: SPClassPassOverview[];
};
export type ClassStatus = 'archived' | 'pending' | 'active';
export type ClassSyncedFrom = 'clever' | 'google-classroom' | 'classlink';
export type ClassUserType = 'teacher' | 'student';
export type GetSPClassesReq = {
	user_id?: number;
	schedule_id?: number;
	status?: ClassStatus;
};
export type GetSPClassUsersReq = {
	class_id: number;
};
export type DeleteSPClassReq = {
	class_id: number;
};
export type UpdateSPClassUsersReq = {
	user_ids: number[];
	class_id: number;
	type: string;
};
export type GetTermsAndPeriodsReq = {
	class_id: number;
};
export type GetTermsAndPeriodGroupingsResp = {
	terms: Term[];
	period_groupings: PeriodGrouping[];
};
export type GetExternalInfoResp = {
	clever_class?: CleverClassInfo;
	classlink_class?: ClasslinkClassInfo;
	google_class?: GoogleClassInfo;
};

@Injectable({
	providedIn: 'root',
})
export class ClassesService implements OnDestroy {
	allClassesDataSource$: BehaviorSubject<SPClassWithUsers[]> = new BehaviorSubject<SPClassWithUsers[]>(null);
	myClasses$: BehaviorSubject<SPClass[]> = new BehaviorSubject<SPClass[]>(null);

	initAllClassesDataSource(userId: number, isAdmin: boolean): Observable<SPClassWithUsers[]> {
		return iif(
			() => isAdmin,
			this.listAllClasses().pipe(
				catchError(() => {
					return of([] as SPClassWithUsers[]);
				}),
				tap((data) => {
					this.allClassesDataSource$.next(data);
				})
			),
			this.getClassesAndClassUsersByUserId(userId).pipe(
				catchError(() => {
					return of([] as SPClassWithUsers[]);
				}),
				tap((data) => {
					this.allClassesDataSource$.next(data);
				})
			)
		);
	}

	allClassesToDisplay$: Observable<SPClassWithUsers[]> = this.allClassesDataSource$.pipe(
		filter((c) => !!c),
		scan((acc, newData) => this.addClassToStream(acc, newData), []),
		this.sortClasses(),
		shareReplay(1)
	);

	allArchivedClasses$: Observable<SPClassWithUsers[]> = this.allClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'archived' && c.display_name !== '');
		})
	);
	allPendingClasses$: Observable<SPClassWithUsers[]> = this.allClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'pending');
		})
	);
	allActiveClasses$: Observable<SPClassWithUsers[]> = this.allClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'active');
		})
	);

	allClassesCount$: Observable<number> = this.allClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => c.length)
	);
	myClassesToDisplay$: Observable<SPClassWithUsers[]> = this.allClassesToDisplay$.pipe(
		withLatestFrom(this.userService.effectiveUser$),
		filter(([c, user]) => {
			return !!c && !!user;
		}),
		// TO-DO this is a quick fix to handle the API not returning teachers[]
		map(([c, user]) => {
			if (!user) {
				return [];
			}
			if (user.isAdmin) {
				return c.filter(
					(c) => c.class_users.teachers?.some((t) => t.user.id === user.id) || c.class_users.students?.some((s) => s.user.id === user.id)
				);
			}
			return c;
		}),
		scan((acc, newData, _) => this.addClassToStream(acc, newData), []),
		shareReplay(1)
	);

	myArchivedClasses$: Observable<SPClassWithUsers[]> = this.myClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'archived' && c.display_name !== '');
		})
	);
	myPendingClasses$: Observable<SPClassWithUsers[]> = this.myClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'pending');
		})
	);
	myActiveClasses$: Observable<SPClassWithUsers[]> = this.myClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			return c.filter((c) => c.status === 'active');
		})
	);
	subTermIdsWithClassesAssigned$: Observable<number[]> = this.allClassesToDisplay$.pipe(
		filter((c) => !!c),
		map((c) => {
			const terms = c.flatMap((c) => c.terms || []);
			return Array.from(new Set(terms));
		})
	);

	private destroy$: Subject<void> = new Subject<void>();

	constructor(private http: HttpService, private hallPassesService: HallPassesService, private userService: UserService) {}

	private addClassToStream(currentData: SPClassWithUsers[], newData: SPClassWithUsers[]): SPClassWithUsers[] {
		const itemsToAdd = [];
		newData.map((newItem) => {
			const currentItem = currentData.find((cd) => cd.id === newItem.id);
			const currentItemIndex = currentData.findIndex((cd) => cd.id === newItem.id);
			if (currentItem) {
				currentData[currentItemIndex] = newItem;
			} else {
				itemsToAdd.push(newItem);
			}
			return currentItem;
		});

		return currentData.concat(itemsToAdd);
	}

	// Use this method when you need class data including the students and teachers in the class.
	getClassesAndClassUsersByUserId(userId: number, status?: ClassStatus): Observable<SPClassWithUsers[]> {
		return this.listClassesByUserId(userId, status).pipe(parseClassesWithUsersAndPinnable(this.hallPassesService));
	}

	getClassById(classId: number): Observable<SPClassWithUsers> {
		const req = {
			class_id: classId,
		};
		return this.http
			.post<SPClassWithUsers>(`v2/class/GetClass`, req, undefined, false)
			.pipe(switchMap((c) => classFromJson(c, this.hallPassesService)));
	}

	listClassesByUserId(userId: number, status?: ClassStatus): Observable<SPClassWithUsers[]> {
		const req: GetSPClassesReq = {
			user_id: userId,
		};
		if (status) {
			req.status = status;
		}
		return this.http
			.post<SPClassWithUsers[]>(`v2/class/ListClasses`, req, undefined, false)
			.pipe(parseClassesWithUsersAndPinnable(this.hallPassesService));
	}

	listAllClasses(): Observable<SPClassWithUsers[]> {
		return this.http
			.post<SPClassWithUsers[]>(`v2/class/ListClasses`, {}, undefined, false)
			.pipe(parseClassesWithUsersAndPinnable(this.hallPassesService));
	}

	sortClasses(): OperatorFunction<SPClassWithUsers[], SPClassWithUsers[]> {
		return map((data) => data.sort((a, b) => a.display_name.localeCompare(b.display_name)));
	}

	getClassUsers(classId: number): Observable<SPClassUsers> {
		const req: GetSPClassUsersReq = {
			class_id: classId,
		};
		return this.http.post<SPClassUsers>(`v2/class/GetClassUsers`, req, undefined, false).pipe(
			map((data) => {
				const teachers =
					data.teachers?.map((t) => {
						return SPClassUser.fromJSON(t);
					}) || [];
				const students =
					data.students?.map((t) => {
						return SPClassUser.fromJSON(t);
					}) || [];
				return { teachers, students };
			})
		);
	}

	createOrUpdateClass(data: CreateSPClassReq): Observable<SPClassWithUsers> {
		return this.http
			.post<SPClassWithUsers>(`v2/class/CreateOrUpdateClass`, data, undefined, false)
			.pipe(switchMap((c) => classFromJson(c, this.hallPassesService)));
	}

	deleteClass(classId: number): Observable<void> {
		const req: DeleteSPClassReq = {
			class_id: classId,
		};
		return this.http.post<void>(`v2/class/DeleteClass`, req, undefined, false);
	}

	getClassPassOverview(classId: number, upcoming_passes_start_before?: Date): Observable<SPClassPassOverviewResp> {
		const req: SPClassPassOverviewReq = {
			class_id: classId,
		};
		if (upcoming_passes_start_before) {
			req.upcoming_passes_start_before = upcoming_passes_start_before;
		}
		return this.http.post<SPClassPassOverviewResp>(`v2/class/GetClassPassOverview`, req).pipe(
			map((passOverview) => {
				const students_and_passes = passOverview.students_and_passes.map((sp) => {
					const student = User.fromJSON(sp.student);
					const active_pass = HallPass.fromJSON(sp.active_pass);
					const upcoming_passes = sp.upcoming_passes.map((up) => {
						return HallPass.fromJSON(up);
					});
					return { student, active_pass, upcoming_passes };
				});
				return { students_and_passes };
			})
		);
	}

	updateClassUsers(userIds: number[], classId: number, type: string): Observable<SPClassUser[]> {
		const req: UpdateSPClassUsersReq = {
			user_ids: userIds,
			class_id: classId,
			type: type,
		};
		return this.http.post<SPClassUserType>(`v2/class/UpdateClassUsers`, req, undefined, false).pipe(
			map((resp) => {
				return resp.users?.map((u) => SPClassUser.fromJSON(u));
			})
		);
	}

	updateTermsAndPeriodGroupings(classId: number, termIds: number[], regularPeriodGroupingIds: number[]): Observable<GetTermsAndPeriodGroupingsResp> {
		const req = {
			class_id: classId,
			term_ids: termIds,
			regular_period_grouping_ids: regularPeriodGroupingIds,
		};

		return this.http.post<GetTermsAndPeriodGroupingsResp>(`v2/class/UpdateTermsAndPeriodGroupings`, req, undefined, false);
	}

	getTermsAndPeriodGroupings(classId: number): Observable<GetTermsAndPeriodGroupingsResp> {
		const req: GetTermsAndPeriodsReq = {
			class_id: classId,
		};
		return this.http.post<GetTermsAndPeriodGroupingsResp>(`v2/class/GetTermsAndPeriodGroupings`, req, undefined, false);
	}

	getExternalInfo(class_id: number) {
		return this.http.post<GetExternalInfoResp>(`v2/class/GetExternalInfo`, { class_id }, undefined, false);
	}

	declineSyncedClass(class_id: number): Observable<SPClassWithUsers> {
		return this.http
			.post<SPClassWithUsers>(`v2/class/DeclineClass`, { class_id }, undefined, false)
			.pipe(switchMap((c) => classFromJson(c, this.hallPassesService)));
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}

export function parseClassesWithUsersAndPinnable(hpSrv: HallPassesService): OperatorFunction<SPClassWithUsers[], SPClassWithUsers[]> {
	return switchMap((classes) => {
		if (classes.length === 0) {
			return of([]);
		}
		const withPins = classes.map((c) => classFromJson(c, hpSrv));
		return forkJoin(withPins);
	});
}

export function classFromJson(
	c: { class_users: SPClassUsers; collapsed?: boolean; pinnable?: Pinnable } & SPClass,
	hallPassesService: HallPassesService
): Observable<SPClassWithUsers> {
	if (c.room) {
		c.room = Location.fromJSON(c.room);
	}
	c.class_users.students.map((t) => SPClassUser.fromJSON(t));
	c.class_users.teachers.map((t) => SPClassUser.fromJSON(t));
	if (!c.room) {
		c.room = null;
		c.pinnable = null;
		return of(c);
	}

	c.room = Location.fromJSON(c.room);
	if (!c.external_source_name) {
		c.external_source_name = c.clever_info
			? 'clever'
			: c.classlink_info
			? 'classlink'
			: c.google_info
			? 'google-classroom'
			: c.class_users.students[0]?.external_source_name
			? (c.class_users.students[0].external_source_name as ClassSyncedFrom)
			: undefined;
	}

	// Get pinnable too
	return hallPassesService.getPinnable(c.room).pipe(
		take(1),
		withLatestFrom(of(c)),
		map(([p, c]) => {
			c.pinnable = Pinnable.fromJSON(p);
			return c;
		})
	);
}
