import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Navigation } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';

@Injectable({
	providedIn: 'root',
})
export class NavbarDataService {
	createPassReceiver = new Subject<Navigation>();
}
