<div
	class="nav-button-wrapper"
	#button
	[style.color]="textColor"
	[@pressState]="button.pressed ? 'down' : 'up'"
	appCrossPointerEventTarget
	(pointerClickEvent)="doClick(); button.pressed = false"
	(pointerDownEvent)="button.pressed = true">
	<div
		class="nav-button-background"
		[style.background-color]="
			button.hovered && !selected
				? button.pressed
					? darkTheme.getColor({ dark: '#364151', white: '#EAEDF1' })
					: darkTheme.getColor({ dark: '#2B3340', white: '#F0F2F5' })
				: 'transparent'
		"
		(mouseenter)="button.hovered = true"
		(mouseleave)="button.hovered = false; button.pressed = false">
		<img width="28px" height="28px" [src]="'./assets/' + icon + ' (' + (selected ? 'Jade' : isDark ? 'White' : 'Blue-Gray') + ').svg'" alt="" />
		<div class="nav-button-text">{{ content }}</div>
	</div>

	<div class="selected-tab-pointer" *ngIf="selected && !isMobile"></div>
</div>
