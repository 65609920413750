import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

// Components
import {
	DynamicDialogAction,
	DynamicDialogData,
	DynamicDialogDataWithWizard,
	DynamicDialogModalComponent,
} from './dynamic-dialog-modal/dynamic-dialog-modal.component';

type DialogRef<T> = MatDialogRef<DynamicDialogModalComponent | undefined>;

export class DynamicDialogService<T = undefined> {
	opened$: Observable<void> = this.dialogRef.afterOpened();
	closed$: Observable<DynamicDialogAction> = this.dialogRef.afterClosed();

	constructor(public dialogRef: DialogRef<T>) {}

	close(action: DynamicDialogAction): void {
		this.dialogRef.close(action);
	}

	hasOpenDialog(): boolean {
		return !!this.dialogRef?.componentInstance;
	}

	// Use this to update the display of the modal elements.
	setDialogConfig(data: Partial<DynamicDialogData | DynamicDialogDataWithWizard>): void {
		if (this.dialogRef.componentInstance) {
			this.dialogRef.componentInstance.data = {
				...this.dialogRef.componentInstance?.data,
				...data,
			};
		}
	}
}
