import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NextReleaseComponent } from './next-release.component';
import { CoreModule } from '../core/core.module';
import { BackpackModule } from '../backpack/backpack.module';

@NgModule({
	declarations: [NextReleaseComponent],
	entryComponents: [NextReleaseComponent],
	imports: [CommonModule, CoreModule, BackpackModule],
	exports: [NextReleaseComponent],
})
export class NextReleaseModule {}
