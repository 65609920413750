import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { bumpIn } from '../animations';
import { SortMenuComponent } from '../sort-menu/sort-menu.component';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { filter, tap } from 'rxjs/operators';
import { Location } from '../models/Location';
import { ScreenService } from '../services/screen.service';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { DisplayOptionsComponent } from './display-options/display-options.component';
import { clickAction } from '../models/PassFilters';

@Component({
	selector: 'app-currently-in',
	templateUrl: './currently-in.component.html',
	styleUrls: ['./currently-in.component.scss'],
	animations: [bumpIn],
})
export class CurrentlyInComponent implements OnInit {
	@Input() roomOptions: Location[];
	@Input() selectedLocation: Location;
	@Input() showEndedPasses: boolean;
	@Output() selectedLocationEmit: EventEmitter<Location> = new EventEmitter<Location>();
	@Output() kioskModeEmit: EventEmitter<void> = new EventEmitter<void>();
	@Output() roomCodeEmit: EventEmitter<void> = new EventEmitter<void>();
	@Output() showEndedPassesEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('twinkieMenu') twinkieMenu: ElementRef<HTMLElement>;

	label: string;
	currentRoom: string;

	constructor(public screenService: ScreenService, public dialog: MatDialog, private titleService: Title) {}

	ngOnInit() {
		if (!(this.roomOptions && this.selectedLocation)) {
			this.label = 'Currently Unavailable';
		} else {
			this.label = 'Currently In';
			this.currentRoom = this.selectedLocation?.title || (this.roomOptions?.length > 1 ? 'All Rooms' : this.roomOptions[0].title);
		}
		this.label = this.roomOptions && this.selectedLocation ? 'Currently in' : 'Currently Unavailable';
	}

	showOptions(target: HTMLElement): void {
		if (this.screenService.isDeviceMid || this.screenService.isIpadWidth) {
			this.openOptionsMenu();
		} else {
			this.displayOptionsPopover(target);
		}
	}

	openOptionsMenu(): void {
		const dialogRef = this.dialog.open(SortMenuComponent, {
			position: { bottom: '0' },
			panelClass: 'options-dialog',
			data: {
				selectedItem: this.selectedLocation,
				items: this.roomOptions,
				showAll: true,
			},
		});
		dialogRef.componentInstance.onListItemClick.subscribe((location) => {
			this.selectedLocation = location;
			this.selectedLocationEmit.emit(location);
		});
	}

	private displayOptionsPopover(target: HTMLElement): void {
		if (this.roomOptions && this.roomOptions.length > 1) {
			UNANIMATED_CONTAINER.next(true);
			const optionDialog = this.dialog.open(DropdownComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'invis-backdrop',
				data: {
					heading: 'CHANGE ROOM',
					locations: this.roomOptions,
					selectedLocation: this.selectedLocation,
					trigger: target,
					//scrollPosition: this.holdScrollPosition,
				},
			});

			optionDialog
				.afterClosed()
				.pipe(
					tap(() => {
						UNANIMATED_CONTAINER.next(false);
					}),
					filter((res) => !!res)
				)
				.subscribe((data) => {
					console.log('data : ', data);
					//this.holdScrollPosition = data.scrollPosition;
					this.selectedLocation = data.selectedRoom === 'all_rooms' ? null : data.selectedRoom;
					this.selectedLocation = data.selectedRoom;
					this.selectedLocationEmit.emit(data.selectedRoom);
				});
		}
	}

	onClickOptions(): void {
		const top = this.twinkieMenu.nativeElement.getClientRects()[0].top + this.twinkieMenu.nativeElement.offsetHeight + 2;
		const left = this.twinkieMenu.nativeElement.getClientRects()[0].left;
		const optionDialog = this.dialog.open(DisplayOptionsComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				showEndedPasses: this.showEndedPasses,
			},
			position: {
				top: `${top}px`,
				left: `${left}px`,
			},
		});

		optionDialog
			.afterClosed()
			.pipe(
				tap(() => {
					UNANIMATED_CONTAINER.next(false);
				}),
				filter((res) => !!res)
			)
			.subscribe((data: clickAction) => {
				switch (data) {
					case 'kiosk':
						this.kioskModeEmit.emit();
						break;
					case 'room_code':
						this.roomCodeEmit.emit();
						break;
					case 'show_ended_passes':
						this.showEndedPasses = !this.showEndedPasses;
						this.showEndedPassesEmit.emit(this.showEndedPasses);
						break;
				}
				console.log('data : ', data);
			});
	}
}
