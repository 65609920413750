import { Component, Input } from '@angular/core';
import { BellSchedule } from '../../../models/Schedule';

@Component({
	selector: 'sp-no-periods',
	templateUrl: './no-periods.component.html',
	styleUrls: ['./no-periods.component.scss'],
})
export class NoPeriodsComponent {
	@Input() selectedBellSchedule: BellSchedule;
}
