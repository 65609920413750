<sp-thin-pass [title]="pass.destination.title" [progress]="progress" [background]="background" [width]="width" [height]="height">
	<img
		class="icon"
		[style.width]="width == '460px' ? '28px' : '21px'"
		[style.height]="width == '460px' ? '28px' : '21px'"
		circle
		[src]="pass.icon"
		alt="pass icon" />
	<sp-countdown-label
		subtitle
		class="countdown"
		[style.font-size]="width == '460px' ? '16px' : '14px'"
		[showOvertimeText]="true"
		[showRemainingText]="true"
		[heartbeat]="heartbeat"
		[date]="pass.expiration_time">
	</sp-countdown-label>
	<sp-glass-button right ariaLabel="End Hall Pass" [padding]="width == '460px' ? '20px 28px' : '16px 25px'" (click)="endPass($event)">
		<span class="button-title">End</span>
	</sp-glass-button>
</sp-thin-pass>
