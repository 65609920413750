<div class="wrapper">
	<div class="options-content">
		<div class="option-container" *ngFor="let option of options">
			<div
				class="option"
				#opt
				(mouseenter)="opt.hover = true; hoverOption = option; showConfirmButton = false"
				(mouseleave)="opt.hover = false"
				[style.background-color]="opt.hover ? option.hoverColor : 'transparent'"
				(click)="selectedOption(option)">
				<img width="18" [src]="option.icon | resolveAsset" alt="opt-icon" />
				<div class="label" [style.color]="option.textColor">{{ option.label }}</div>
			</div>
		</div>
	</div>
	<mat-divider *ngIf="hoverOption || showConfirmButton"></mat-divider>
	<div class="description" *ngIf="hoverOption || showConfirmButton">
		{{ hoverOption.description }}
		<div class="button" *ngIf="showConfirmButton">
			<app-gradient-button [gradient]="'#E32C66, #E32C66'" (buttonClick)="dialogRef.close('delete')">Confirm delete</app-gradient-button>
		</div>
	</div>
</div>
