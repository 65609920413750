import * as React from 'react';
import { ImageButton } from '../../../components';

interface IDateNavigationProps {
	atStartDate: boolean;
	isStartingDay: boolean;
	prevDay: () => void;
	nextDay: () => void;
	startTime: Date;
	endTime: Date;
	timeFormat: Intl.DateTimeFormatOptions;
	relativeDateText: string;
}

export function DateNavigation({
	atStartDate,
	isStartingDay,
	nextDay,
	prevDay,
	startTime,
	endTime,
	timeFormat,
	relativeDateText,
}: IDateNavigationProps): React.ReactElement {
	const formatDate = (date: Date) => date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
	const formatTime = (date: Date) => date.toLocaleString('en-US', timeFormat);

	const buttonClass = 'tw-h-5 tw-p-1.5 tw-rounded-full';
	const hoverColor = 'tw-bg-gray-050';
	const pressedColor = 'tw-bg-gray-100';

	return (
		<div className={`tw-flex tw-items-center tw-mb-6 ${isStartingDay ? 'tw-text-green-500' : 'tw-text-gray-500'}`}>
			<ImageButton
				defaultImage="./assets/Chevron Left (Blue-Gray).svg"
				disabledImage="./assets/Chevron Left (Gray-300).svg"
				alt="Previous Day"
				className={buttonClass}
				hoverClassName={hoverColor}
				pressedClassName={pressedColor}
				onClick={prevDay}
				disabled={atStartDate}
			/>
			<ImageButton
				defaultImage="./assets/Chevron Right (Blue-Gray).svg"
				alt="Next Day"
				className={`tw-ml-1 ${buttonClass}`}
				hoverClassName={hoverColor}
				pressedClassName={pressedColor}
				onClick={nextDay}
			/>
			<div className="tw-text-xl tw-ml-5 tw-font-medium">{`${relativeDateText}, ${formatDate(startTime)}`}</div>
			<div className={`tw-text-base tw-ml-3 tw-font-normal ${isStartingDay ? 'tw-opacity-60' : 'tw-text-gray-400'}`}>{`${formatTime(
				startTime
			)} - ${formatTime(endTime)}`}</div>
		</div>
	);
}
