<div
	class="wrapper"
	[style.background-color]="bgColor()"
	[@pressState]="pressed ? 'down' : 'up'"
	appCrossPointerEventTarget
	(pointerDownEvent)="pressed = true"
	(pointerClickEvent)="pressed = false; buttonClick.emit($event)"
	(mouseenter)="hover = true"
	(mouseleave)="hover = false; pressed = false">
	<div class="block">
		<div class="title">
			{{ dateText(date) }}
		</div>
		<img width="10px" [src]="'./assets/Rect (Navy).svg' | resolveAsset" alt="" />
	</div>
	<mat-divider [style.border-color]="'#D4D9E2'" [vertical]="true"></mat-divider>
	<div class="block">
		<img width="12px" [src]="'./assets/Calendar (Blue-Gray).svg'" />
		<div class="date" notranslate>{{ selectedDate(date) }}</div>
	</div>
</div>
