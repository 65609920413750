import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	private bannerStatus = new BehaviorSubject<boolean>(false);
	private bannerMessage = new BehaviorSubject<string>('');
	private bannerImage = new BehaviorSubject<string>('');
	private bannerButtonText = new BehaviorSubject<string>('');
	currentStatus = this.bannerStatus.asObservable();
	currentMessage = this.bannerMessage.asObservable();
	currentImage = this.bannerImage.asObservable();
	currentButtonText = this.bannerButtonText.asObservable();

	changeStatus(status: boolean): void {
		this.bannerStatus.next(status);
	}

	changeMessage(message: string): void {
		this.bannerMessage.next(message);
	}

	changeImage(image: string): void {
		this.bannerImage.next(image);
	}

	changeButtonText(text: string): void {
		this.bannerButtonText.next(text);
	}
}
