import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { SPClassWithUsers } from '../../services/classes.service';

@Component({
	selector: 'sp-table-v2',
	templateUrl: './table-v2.component.html',
	styleUrls: ['./table-v2.component.scss'],
})
export class TableV2Component<T> implements OnInit {
	@Input() data: T[] = [];
	@Input() initialCountToShow: number;
	@Input() resourceName: string;
	@Input() rowTemplate: TemplateRef<any>;
	@Input() width = 'tw-full';
	@Input() bgColor = 'tw-bg-white';
	@Output() rowClicked: EventEmitter<SPClassWithUsers> = new EventEmitter();

	hideRows = false;
	countToShow: number;

	ngOnInit(): void {
		if (this.initialCountToShow) {
			this.countToShow = this.initialCountToShow;
			this.hideRows = this.data.length > this.initialCountToShow;
		}
	}

	seeAllData(): void {
		this.countToShow = this.data.length;
		this.hideRows = false;
	}
}
