<div
	class="button"
	[style.border]="border"
	[style.background]="hovered && hoverColor ? hoverColor : styleGradient"
	[style.width]="width"
	[style.min-width]="minWidth"
	[style.min-height]="minHeight"
	[style.height]="minHeight"
	[style.box-shadow]="customShadow ? customShadow : shadow"
	[style.opacity]="hovered && !buttonDown && !disabled ? 0.85 : buttonDown && !disabled ? 0.7 : disabled ? 0.5 : 1"
	[style.cursor]="cursor"
	[style.border-radius]="cornerRadius"
	[style.display]="display"
	(mouseenter)="disabled ? onHover(false) : onHover(true)"
	(mouseleave)="onHover(false)"
	appCrossPointerEventTarget
	(pointerDownEvent)="buttonDown = true; $event.stopPropagation()"
	(pointerCancelEvent)="buttonDown = false"
	(pointerClickEvent)="buttonDown = false; hovered = false; onClick($event)"
	[@pressState]="buttonState"
	[attr.id]="buttonId"
	[ngClass]="{ center: !(leftIcon || rightIcon), disabled: disabled }">
	<div *ngIf="!buttonLink" class="wrapper-container" [style.width]="textWidth" [style.padding]="padding">
		<div class="wrapper">
			<img
				draggable="false"
				*ngIf="leftIcon"
				[style.width]="leftImageWidth"
				[style.height]="leftImageHeight"
				class="icon left-icon"
				[src]="leftIcon | resolveAsset" />
			<div class="text-content" [style.text-align]="contentAlign">
				<div
					class="content-wrapper"
					[style.line-height]="1"
					[style.white-space]="whiteSpace"
					[style.color]="textColor"
					[style.font-weight]="fontWeight"
					[style.font-size]="fontSize">
					<ng-content></ng-content>
				</div>
			</div>
			<img
				draggable="false"
				[style.width]="rightIconWidth"
				[style.height]="rightIconHeight"
				*ngIf="rightIcon"
				class="icon right-icon"
				[src]="rightIcon | resolveAsset"
				[alt]="altText" />
		</div>
	</div>
	<div *ngIf="buttonLink" class="wrapper-container button-link">
		<a class="link" [href]="buttonLink" [attr.target]="linkType" name="link" [attr.draggable]="draggable" [download]="download">
			<img
				draggable="false"
				*ngIf="leftIcon"
				[style.width]="leftImageWidth"
				[style.height]="leftImageHeight"
				class="icon left-icon"
				[src]="leftIcon | resolveAsset" />

			<div [style.color]="textColor" [style.font-weight]="fontWeight" [style.font-size]="fontSize">
				<span>{{ text }}</span>
			</div>
			<img
				draggable="false"
				*ngIf="linkType === '_blank' && rightIcon"
				[style.width]="rightIconWidth"
				[style.height]="rightIconHeight"
				class="icon right-icon"
				[src]="rightIcon | resolveAsset" />
		</a>
	</div>
	<div class="subtitle" *ngIf="subtitle" [style.margin-top]="subtitle ? '0px' : '-12px'" [style.opacity]="subtitle ? '1' : '0'">
		<span>{{ subtitle }}</span>
	</div>
</div>
