<div class="card-container">
	<ng-container *ngIf="wil">
		<div class="pass-card-container wait-in-line">
			<div class="pass-card-wrapper" style="z-index: 501 !important" [style.background-image]="gradient" ondragstart="return false">
				<sp-pass-modal-header
					*ngIf="originRoomIcon"
					[pass]="wil"
					[forStaff]="forStaff"
					[optionsIcon]="optionsIcon"
					[originRoomIcon]="originRoomIcon"
					[originRoomTitle]="wil.origin.title"
					(showOptionsEmit)="showOptions($event)"></sp-pass-modal-header>

				<div class="center-block">
					<div class="travel">
						<div class="timer-wrapper">
							<sp-circle-countdown [progress]="0" [diameter]="316">
								<img [src]="'assets/icon-walking.svg' | resolveAsset" alt="Walking person icon" />
								<div>{{ formattedDuration }}</div>
							</sp-circle-countdown>
						</div>
					</div>
				</div>

				<div class="bottom-block">
					<ng-container *ngIf="!wil.isReadyToStart(); else wilCardButton">
						<ng-container *ngIf="!forStaff; else teacherHistoryPager" [ngTemplateOutlet]="positionInLine"></ng-container>
					</ng-container>
				</div>
			</div>
			<div class="student-footer-info" *ngIf="openedFromPassTile">
				<app-student-passes [pass]="wil" [profile]="wil.student" [displayEncounterPreventionInfo]="!isKiosk"></app-student-passes>
			</div>
		</div>
	</ng-container>
</div>
<ng-template #wilCardButton>
	<div class="button-wrapper">
		<div class="button single-button">
			<app-card-button [height]="'144px'" [backgroundGradient]="backgroundGradient">
				<ng-container *ngIf="requestLoading; else frontOfLine">
					<div class="end-pass-content">
						<mat-spinner [color]="'accent'" [diameter]="35"></mat-spinner>
					</div>
				</ng-container>

				<ng-template #frontOfLine>
					<div
						class="end-pass-content ds-flex-center-between"
						style="justify-content: space-around; width: 100%; border-radius: 8px"
						(click)="startPass()">
						<div class="spinner-timer">
							<sp-timer-spinner
								customToolTip
								[contentTemplate]="passAttemptTooltip"
								[maxSeconds]="30"
								[startAt]="remainingAttemptSeconds"
								[showNumber]="true"
								(pulse)="readyToStartTick($event)">
							</sp-timer-spinner>
						</div>
						<span>Start Pass</span>

						<ng-template #passAttemptTooltip>
							<div class="ds-tooltip" style="width: 200px">
								<span>
									You have {{ acceptingPassTimeRemaining }} sec left to start your pass or
									{{ wil.missed_start_attempts === 1 ? 'your pass will be deleted' : 'you will be sent back in line' }}.</span
								>
							</div>
						</ng-template>
					</div>
				</ng-template>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #teacherHistoryPager>
	<div *ngIf="!wilUiRefresh; else waitInLineTitleTemplate" class="card-button-wrapper">
		<app-card-button [waitInLine]="true">
			<div class="end-pass-content animated">{{ waitInLineTitle | async }}</div>
		</app-card-button>
	</div>
</ng-template>

<ng-template #positionInLine>
	<div *ngIf="!wilUiRefresh; else waitInLineTitleTemplate" class="button-wrapper">
		<div class="button single-button disabled">
			<app-card-button [height]="'144px'" [backgroundGradient]="backgroundGradient" [disabled]="true">
				<ng-container [ngTemplateOutlet]="waitInLineTitleTemplate"></ng-container>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #waitInLineTitleTemplate>
	<div class="end-pass-content">
		<span>{{ waitInLineRefreshTitle | async }}</span>
	</div>
</ng-template>
