import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bumpIn } from '../animations';

@Component({
	selector: 'app-card-button',
	templateUrl: './card-button.component.html',
	styleUrls: ['./card-button.component.scss'],
	animations: [bumpIn],
})
export class CardButtonComponent implements OnInit {
	@Input() width = '100%';
	@Input() height: string;
	@Input() valid = true;
	@Input() disabled: boolean;
	@Input() content: string;
	@Input() useOverlay = true;
	@Input() inFormContainer = false;
	@Input() waitInLine: boolean;
	@Input() backgroundColor: string;
	@Input() backgroundGradient: string;
	@Input() border: string;

	@Output() onClick: EventEmitter<any> = new EventEmitter();

	marginTop: string;
	pressed = false;
	gradient: string;

	ngOnInit(): void {
		if (this.height) {
			this.marginTop = `-${this.height}px`;
		}
		if (this.backgroundGradient) {
			const gradient: string[] = this.backgroundGradient.split(',');
			this.gradient = `radial-gradient(144.10% 144.10% at 72.94% 71.48%, ${gradient[0]} 0%, ${gradient[1]} 100%)`;
		}
	}

	buttonClicked() {
		if (!this.disabled) {
			this.onClick.emit();
		}
	}

	onPress(press: boolean) {
		if (!this.disabled) {
			this.pressed = press;
		}
	}
}
