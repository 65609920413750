<div class="input-wrapper">
	<div *ngIf="labelText" class="label-wrapper">
		<img draggable="false" class="label-icon" [src]="labelIcon | resolveAsset" />
		<div class="label-text" [style.color]="labelColor">{{ labelText }}</div>
	</div>

	<div class="field-wrapper" [style.min-width]="minWidth">
		<div
			class="input-focus-wrapper"
			[ngClass]="{ inputSelector: selected === true }"
			[style.border]="border"
			[style.border-radius]="borderRadius"
			[style.width]="width"
			[style.min-width]="minWidth">
			<div *ngIf="fieldIcon && fieldIconPosition === 'left'" class="field-icon">
				<img *ngIf="!(pending$ | async)" [src]="fieldIcon | resolveAsset" alt="icon" draggable="false" />
				<div *ngIf="pending$ | async" class="loading-theme_blue-gray">
					<mat-spinner [color]="'primary'" [diameter]="18"></mat-spinner>
				</div>
			</div>

			<input
				#input
				ignore
				class="input"
				spellcheck="false"
				tabindex="-1"
				[attr.data-cy]="dataCy"
				[type]="html5type"
				[style.margin]="'0px'"
				[style.border-radius]="borderRadius"
				[style.font-size]="fontSize"
				[style.color]="color"
				[style.height]="height"
				[style.width]="width"
				[style.box-shadow]="customBoxShadow ? customBoxShadow : _boxShadow"
				[style.min-width]="minWidth"
				[style.padding]="
					padding
						? padding
						: fieldIconPosition === 'left'
						? (showCloseIcon | async)
							? '0 40px 0 45px'
							: fieldIcon
							? '0 5px 0 45px'
							: '0 5px 0 20px'
						: '0 45px 0 15px'
				"
				[style.background-color]="backgroundColor"
				[placeholder]="placeholder"
				[readonly]="type == 'dates'"
				(focus)="multipleSearchType ? onMultipleSearchFocus() : focusAction($event, true)"
				(focusout)="multipleSearchType ? onMultipleSearchBlur() : focusAction($event, false)"
				[(ngModel)]="value"
				[ngClass]="{ isFocus: !noFocus && isFocus }"
				[style.--placeholder-color]="placeholderColor" />

			<img
				*ngIf="fieldIcon && fieldIconPosition === 'right' && !(showCloseIcon | async)"
				class="field-icon field-icon__right"
				[src]="fieldIcon | resolveAsset"
				alt="icon"
				draggable="false" />
			<img
				*ngIf="closeIcon && (showCloseIcon.asObservable() | async)"
				class="field-icon field-icon__close"
				[style.opacity]="input.value.length ? '.7' : '0'"
				[src]="closeIconAsset | resolveAsset"
				alt="icon"
				draggable="false"
				(click)="reset()" />
		</div>
	</div>
</div>
