import * as React from 'react';
import { SchoolActivity } from '../../../../services/school-activity.service';
import { ActivityWithInstance } from '../../../hooks/use-activity-instance';
import { ActivityBox } from './activity-box';

interface IActivityBoxesProps {
	isLoading: boolean;
	activityInstances: ActivityWithInstance[];
	openActivityDialog: (activity: SchoolActivity) => void;
}

export function ActivityBoxes({ isLoading, activityInstances, openActivityDialog }: IActivityBoxesProps): React.ReactElement {
	const textClass =
		'tw-animate-fadein-300 tw-text-base tw-text-gray-400 tw-font-medium tw-text-center tw-flex tw-flex-col tw-justify-center tw-w-full';

	return (
		<div className="tw-flex tw-gap-7 tw-flex-wrap tw-mt-5 tw-h-full">
			{isLoading ? (
				<div className={textClass}>Loading...</div>
			) : activityInstances.length > 0 ? (
				activityInstances.map((ai) => (
					<ActivityBox
						key={ai.activity.id + ai.instance.id}
						name={ai.activity.name}
						teacher={ai.activity.teacher_name}
						numStudents={ai.instance.current_num_attendees}
						onClick={() => openActivityDialog(ai.activity)}
						maxCapacity={ai.activity.max_attendees}
					/>
				))
			) : (
				<div className={textClass}>No Activities Scheduled</div>
			)}
		</div>
	);
}
