import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const defaultPipe = new DatePipe('en-US');

@Pipe({
	name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
	transform(value: string): string {
		// Split the time string into hours and minutes
		const parts = value.split(':');
		const hours = parseInt(parts[0]);
		const minutes = parseInt(parts[1]);

		// Create a new Date object with the current date and the given hours and minutes
		const date = new Date();
		date.setHours(hours);
		date.setMinutes(minutes);

		// Use the DatePipe to format the time as "H:mm a"
		return defaultPipe.transform(date, 'h:mm a');
	}
}
