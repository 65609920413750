<div
	class="tw-flex tw-items-center tw-mt-4 tw-px-5 tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-300 tw-border-solid tw-bg-white"
	[ngClass]="{
		'tw-text-gray-500': currentStatus === 'archived',
		'tw-text-red-500': currentStatus !== 'archived',
		'tw-opacity-50': !userCanEdit,
		'hover:tw-bg-gray-100': userCanEdit
	}"
	style="height: 56px"
	customToolTip
	[position]="'left'"
	[showToolTip]="!userCanEdit"
	[contentTemplate]="tooltipTemplate"
	(click)="userCanEdit && openArchiveConfirmationModal()">
	<svg
		*ngIf="currentStatus === 'archived'"
		class="tw-mr-3 tw-mb-0.5"
		width="18"
		height="19"
		viewBox="0 0 18 19"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.00039 1.7002L6.00039 4.7002H12.0004L9.00039 1.7002ZM4.5707 2.9002C4.1123 2.9002 3.70127 3.15488 3.49727 3.56348L2.40039 5.7584V5.9002V7.70019V14.9002C2.40039 15.5632 2.93739 16.1002 3.60039 16.1002H14.4004C15.0634 16.1002 15.6004 15.5632 15.6004 14.9002V7.70019V5.9002V5.7584L14.5035 3.56348C14.2995 3.15428 13.8885 2.9002 13.4301 2.9002H11.8973L13.0973 4.1002H13.4301L14.3301 5.9002H3.6707L4.5707 4.1002H4.90352L6.10352 2.9002H4.5707ZM7.20039 7.10019H10.8004C11.1316 7.10019 11.4004 7.369 11.4004 7.70019C11.4004 8.03139 11.1316 8.30019 10.8004 8.30019H7.20039C6.86919 8.30019 6.60039 8.03139 6.60039 7.70019C6.60039 7.369 6.86919 7.10019 7.20039 7.10019Z"
			fill="#7083A0" />
	</svg>
	<svg
		*ngIf="currentStatus !== 'archived'"
		class="tw-mr-3 tw-mb-0.5"
		width="19"
		height="20"
		viewBox="0 0 19 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_2702_2235)">
			<path
				d="M4.82409 3.0332C4.34565 3.0332 3.90456 3.30621 3.69102 3.73457L2.5332 6.0502V6.19987V8.09987V15.6999C2.5332 16.3997 3.10004 16.9665 3.79987 16.9665H15.1999C15.8997 16.9665 16.4665 16.3997 16.4665 15.6999V8.09987V6.19987V6.0502L15.3075 3.73333C15.0936 3.30591 14.6535 3.0332 14.1757 3.0332H4.82409ZM4.82533 4.29987H14.1744L15.1244 6.19987H3.87533L4.82533 4.29987ZM7.59987 7.46654H11.3999C11.7495 7.46654 12.0332 7.75027 12.0332 8.09987C12.0332 8.44947 11.7495 8.7332 11.3999 8.7332H7.59987C7.25027 8.7332 6.96654 8.44947 6.96654 8.09987C6.96654 7.75027 7.25027 7.46654 7.59987 7.46654Z"
				fill="#E32C66" />
		</g>
		<defs>
			<clipPath id="clip0_2702_2235">
				<rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
	<p class="tw-text-base tw-font-semibold">
		{{ currentStatus === 'archived' ? unarchiveText : archiveText }}
	</p>
</div>
