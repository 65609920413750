import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Schedule, ScheduleGroupList } from '../../models/Schedule';
import { TermSelection } from './class-term-picker/class-term-picker.component';

@Component({
	selector: 'sp-class-term-and-period-form',
	templateUrl: './class-term-and-period-form.component.html',
	styleUrls: ['./class-term-and-period-form.component.scss'],
})
export class ClassTermAndPeriodFormComponent {
	@Input() schedules: Schedule[];
	@Input() initialSelectedPeriodGroupingIds: number[] = [];
	@Input() initialSelectedTermIds: number[] = [];
	@Input() listGroups: ScheduleGroupList[] | null;
	@Output() selectedPeriodGroupingIds = new EventEmitter<number[]>();
	@Output() selectedTermIds = new EventEmitter<number[]>();
	@Output() isValid = new EventEmitter<boolean>();

	onTermIdsSelected(termSelection: TermSelection): void {
		const termIds = termSelection.term_ids;
		this.selectedTermIds.emit(termIds);
	}
}
