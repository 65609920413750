import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Pinnable } from 'app/models/Pinnable';
import { HallPassesService } from 'app/services/hall-passes.service';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Util } from '../../../../../Util';
import { Location } from '../../../../models/Location';
import { ScreenService } from '../../../../services/screen.service';
import { UserService } from '../../../../services/user.service';
import { CreateFormService } from '../../../create-form.service';
import { Navigation } from '../../main-hall-pass-form.component';
import { States } from '../locations-group-container.component';

@Component({
	selector: 'app-from-where',
	templateUrl: './from-where.component.html',
	styleUrls: ['./from-where.component.scss'],
})
export class FromWhereComponent implements OnInit, OnDestroy {
	@ViewChild('header', { static: true }) header: ElementRef<HTMLDivElement>;
	@ViewChild('rc', { static: true }) set rc(rc: ElementRef<HTMLDivElement>) {
		if (rc) {
			fromEvent(rc.nativeElement, 'scroll').subscribe((evt: Event) => {
				let blur: number;

				if ((evt.target as HTMLDivElement).scrollTop < 100) {
					blur = 5;
				} else if ((evt.target as HTMLDivElement).scrollTop > 100 && (evt.target as HTMLDivElement).scrollTop < 400) {
					blur = (evt.target as HTMLDivElement).scrollTop / 20;
				} else {
					blur = 20;
				}
				this.header.nativeElement.style.boxShadow = `0 1px ${blur}px 0px rgba(0,0,0,.2)`;
			});
		}
	}

	@Input() date;

	@Input() isStaff: boolean;

	@Input() formState: Navigation;

	@Input() studentText;

	@Output() selectedLocation: EventEmitter<Pinnable> = new EventEmitter();
	@Output() backButton: EventEmitter<any> = new EventEmitter<any>();

	shadow: boolean;
	frameMotion$: BehaviorSubject<any>;
	placeholder: string;

	headerTransition = {
		'from-header': true,
		'from-header_animation-back': false,
	};

	pinnables: Pinnable[];

	@HostListener('scroll', ['$event'])
	tableScroll(event) {
		const tracker = event.target;
		const limit = tracker.scrollHeight - tracker.clientHeight;
		if (event.target.scrollTop < limit) {
			this.shadow = true;
		}
		if (event.target.scrollTop === limit) {
			this.shadow = false;
		}
	}
	numSelectedStudents: string;

	updatedLocation$: Observable<Location>;
	destroy$: Subject<any> = new Subject<any>();

	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<FromWhereComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private formService: CreateFormService,
		public screenService: ScreenService,
		private userService: UserService,
		private pinnableService: HallPassesService
	) {}

	ngOnInit() {
		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.pinnables = pinnables;
		});
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		let placeholder = 'Select where your student(s) are';
		if (!this.isStaff) {
			placeholder = 'Where are you?';
			if (this.data?.date?.date && this.data?.date?.declinable) {
				placeholder = `Where will you be ${Util.formatRelativeTime(this.data.date.date, ' at', false)}?`;
			}
		}
		this.placeholder = placeholder;
		this.frameMotion$.subscribe((v) => {
			switch (v.direction) {
				case 'back':
					this.headerTransition['from-header'] = false;
					this.headerTransition['from-header_animation-back'] = true;
					break;
				case 'forward':
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
					break;
				default:
					this.headerTransition['from-header'] = true;
					this.headerTransition['from-header_animation-back'] = false;
			}
		});

		this.updatedLocation$ = this.formService.getUpdatedChoice();
		this.numSelectedStudents = this.getNumSelectedStudents();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private overwritePinWithNewLocation(pin: Pinnable, loc: Location): Pinnable {
		const clonedPin = cloneDeep(pin);
		clonedPin.location = loc;
		clonedPin.title = loc.title;
		clonedPin.type = 'location';
		clonedPin.id = null;
		return clonedPin;
	}

	locationChosen(location: Location) {
		const originPinnable = this.pinnables.find((p) => p.location?.id === location.id || p.category === location.category);

		// advance form to next componet
		// emit chosen location
		const emitter = () => {
			this.selectedLocation.emit(this.overwritePinWithNewLocation(originPinnable, location));
		};
		// students go forward
		if (!this.isStaff) {
			this.formService.nextStep(this.formState, true, emitter);
			return;
		}

		this.formService.checkRoomVisibility(this.formState, true, location, emitter).pipe(takeUntil(this.destroy$)).subscribe();
	}

	back() {
		if (!this.isStaff && !this.formState.forLater) {
			this.formService.setFrameMotionDirection('disable');
			this.formService.compressableBoxController.next(true);
		} else {
			this.formService.compressableBoxController.next(false);
			this.formService.setFrameMotionDirection('back');
		}

		setTimeout(() => {
			if (this.formState.forLater || this.formState.missedRequest) {
				this.formState.previousState = States.from;
				this.formState.previousStep = 3;
				this.formState.step = 1;
				this.formState.state = States.from;
				this.formState.previousStep = 3;
			} else if (!!this.studentText && this.formState.state === States.from) {
				this.formState.previousState = States.from;
				this.formState.step = 2;
				this.formState.state = States.from;
				this.formState.previousStep = 3;
			} else if (this.formState.previousState === States.restrictedTarget) {
				this.formState.previousState = null;
				this.formState.state = States.restrictedTarget;
				this.formState.step = 0;
			} else {
				this.formState.step = 0;
			}
			this.formState.previousState = States.from;

			this.backButton.emit(this.formState);
		}, 20);
	}

	private getNumSelectedStudents(): string {
		const num = this.formState.data.selectedStudents.length;
		return `${num} student${num != 1 ? 's' : ''}`;
	}
}
