import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { timer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Location } from '../../../models/Location';
import { generateRoomCode } from './generate-room-code';

@Component({
	selector: 'app-room-checkin-code-dialog',
	templateUrl: './room-checkin-code-dialog.component.html',
	styleUrls: ['./room-checkin-code-dialog.component.scss'],
})
export class RoomCheckinCodeDialogComponent implements OnInit {
	selectedLocationData: Location;

	color = '#07ABC3';
	mode: ProgressSpinnerMode = 'determinate';
	spinnerValue$ = timer(0, 1).pipe(
		startWith(() => this.spinnerValue),
		map((_) => this.spinnerValue)
	);

	roomCode$ = timer(0, 1000).pipe(
		startWith(() => this.roomCode),
		map((_) => this.roomCode)
	);

	constructor(public dialogRef: MatDialogRef<RoomCheckinCodeDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) {}

	ngOnInit(): void {
		this.selectedLocationData = this.dialogData.roomData;
	}

	private get roomCode() {
		const secondsSinceEpoch = Math.round(Date.now() / 1000);
		return generateRoomCode(secondsSinceEpoch, this.selectedLocationData.id);
	}

	private get spinnerValue() {
		let remainingSeconds: number;
		const seconds = new Date().getSeconds();

		if (seconds == 30) {
			remainingSeconds = 30;
		} else if (seconds < 30) {
			remainingSeconds = 30 - seconds;
		} else {
			remainingSeconds = 60 - seconds;
		}
		return (remainingSeconds * 100) / 30;
	}
}
