import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class NavbarElementsRefsService {
	navbarRef$: Subject<ElementRef> = new ReplaySubject<ElementRef>(1);

	schoolToggle$: Subject<ElementRef> = new ReplaySubject<ElementRef>(1);

	langToggle$: Subject<ElementRef> = new ReplaySubject<ElementRef>(1);

	private _pointerVisible = new BehaviorSubject<boolean>(true);
	private _pointerVisible$ = this._pointerVisible.asObservable();

	getPointerVisible(): Observable<boolean> {
		return this._pointerVisible$;
	}

	setPointerVisible(isVisible: boolean) {
		this._pointerVisible.next(isVisible);
	}
}
