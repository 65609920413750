import { useRef, useCallback } from 'react';

export const useIntervalFunction = <F extends (...args: unknown[]) => void>(func: F, delay: number): ((...args: Parameters<F>) => void) => {
	const lastCalled = useRef<number | null>(null);

	return useCallback(
		(...args: Parameters<F>) => {
			const now = Date.now();
			if (lastCalled.current === null || now - lastCalled.current > delay) {
				lastCalled.current = now;
				func(...args);
			}
		},
		[func, delay]
	);
};

export const useIntervalAsyncFunction = <F extends (...args: unknown[]) => unknown>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => ReturnType<F>) => {
	const lastCalled = useRef<number | null>(null);

	return useCallback(
		(...args: Parameters<F>): ReturnType<F> => {
			const now = Date.now();
			if (lastCalled.current === null || now - lastCalled.current > delay) {
				lastCalled.current = now;
				return func(...args) as ReturnType<F>;
			}
		},
		[func, delay]
	);
};
