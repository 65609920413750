import { Component, Directive, Input, OnInit } from '@angular/core';
import { User } from '../models/User';
import { ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { HomepageService } from 'app/services/homepage.service';

@Directive({
	selector: '[sp-student-list-item-right]',
})
export class StudentListItemRightContent {}

@Component({
	selector: 'sp-student-list-item',
	templateUrl: './student-list-item.component.html',
	styleUrls: ['./student-list-item.component.scss'],
})
export class StudentListItemComponent implements OnInit {
	@Input() student!: User;
	@Input() user!: User;
	@Input() ordered!: boolean;
	@Input() index!: number;
	@Input() groups: ExclusionGroupWithOverrides[] = [];
	kioskMode!: boolean;
	showEPIcon = false;
	isHovered = false;
	isPressed = false;

	constructor(public homePageService: HomepageService) {}

	ngOnInit(): void {
		this.kioskMode = this.homePageService.isKioskMode;
	}
}
