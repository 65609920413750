import { AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmergencyService } from '../../../services/emergency.service';

@Component({
	selector: 'sp-emergency-banner',
	templateUrl: './emergency-banner.component.html',
	styleUrls: ['./emergency-banner.component.scss'],
})
export class EmergencyBannerComponent implements OnInit, AfterViewInit {
	emergencyData: any = {};
	eventMessage = [];
	enableMessage = false;
	emergencyActive = false;
	searchText = '';
	studentsList = [];
	studentsListEdited = [];
	filteredList = [];
	changedObjects = [];
	checkedIn = false;
	checkedInNumber = 0;

	constructor(private emergencyService: EmergencyService, private cdr: ChangeDetectorRef, private router: Router) {}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.listenEmergency();
		this.checkForEvents();
		this.listenEmergencyMessage();
		this.listenEmergencyStudentCheckin();
	}

	onSearchTextChanged() {
		this.filteredList = this.studentsListEdited?.filter((student) =>
			student?.checkin_user?.display_name?.toLowerCase().includes(this.searchText.toLowerCase())
		);
	}

	checkForEvents() {
		const body = {
			state: 'started',
		};
		this.emergencyService.getEmergencyEvent(body).subscribe({
			next: (events) => {
				const event = events && events[0];
				if (event?.state == 'started') {
					this.emergencyData = event;
					this.emergencyActive = true;
					this.emergencyService.isEmergencyActivated.next(true);
					this.getMessage();
					this.getCheckInList();
				}
			},
			error: (error) => {
				console.log('error : ', error);
			},
		});
	}

	getCheckInList() {
		const body = {
			event_id: this.emergencyData.id,
			has_checkin_time: true,
			state: '',
		};
		this.emergencyService.getCheckInList(body).subscribe({
			next: (result: any) => {
				if (result?.event_checkins?.length) {
					this.studentsList = result?.event_checkins;
					this.studentsListEdited = JSON.parse(JSON.stringify(this.studentsList));
					this.filteredList = this.studentsListEdited;
				}
			},
			error: (error) => {
				console.log('error : ', error);
			},
		});
	}

	toggleSelection(student: any): void {
		if (student?.state == 'not_checked_in') {
			student.state = 'checked_in';
		} else {
			student.state = 'not_checked_in';
		}
		this.changedObjects = this.studentsListEdited.filter((obj, index) => {
			// Check if the state key is different in the two objects
			return obj.state !== this.studentsList[index].state;
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	chatHistory() {
		this.enableMessage ? (this.enableMessage = false) : (this.enableMessage = true);
	}

	getTime() {
		const date = new Date(this.emergencyData.start_time);

		const hours = date.getHours(); // Get the hours (in 24-hour format)
		const minutes = date.getMinutes(); // Get the minutes

		// Convert hours to 12-hour format
		const hours12 = hours % 12 || 12;

		// Format minutes with leading zeros
		const formattedMinutes = minutes.toString().padStart(2, '0');

		// Determine whether it's AM or PM
		const meridiem = hours < 12 ? 'AM' : 'PM';
		return ` ${hours12}:${formattedMinutes} ${meridiem}`;
	}

	getMessage() {
		const body = {
			event_id: this.emergencyData.id,
		};
		this.emergencyService.getEmergencyEventMessage(body).subscribe({
			next: (messages: []) => {
				this.eventMessage = [];
				if (this.emergencyData?.message) {
					const firstMessage = {
						message: this.emergencyData?.message,
						user: this.emergencyData.user,
					};
					this.eventMessage = [...this.eventMessage, firstMessage];
				}
				if (messages) {
					for (const message of messages) {
						this.eventMessage = [...this.eventMessage, message];
					}
				}
			},
			error: (error) => {
				console.log('error : ', error);
			},
		});
	}

	listenEmergency() {
		this.emergencyService.listenForEmergencyEventsUpdate().subscribe({
			next: (result: any) => {
				if (result?.data && this.emergencyService.isRightSchool(result?.data?.school_id)) {
					if (result?.data?.state == 'ended') {
						this.emergencyActive = false;
					}
				}
			},
		});

		this.emergencyService.listenForEmergencyEventsCreate().subscribe({
			next: (result: any) => {
				if (result?.data[0]?.state == 'started') {
					this.emergencyData = result.data;
					this.emergencyActive = true;
				}
			},
		});
	}

	listenEmergencyMessage() {
		this.emergencyService.listenForEmergencyMessageUpdate().subscribe({
			next: (result) => {
				if (result?.data[0] && this.emergencyService.isRightSchool(result?.data[0]?.school_id)) {
					this.eventMessage = [...this.eventMessage, result.data[0]];
					this.cdr.detectChanges();
				}
			},
		});
	}

	listenEmergencyStudentCheckin() {
		this.emergencyService.listenForEmergencyStudentCheckin().subscribe({
			next: (result) => {
				const updatedList = result.data[0].event_checkins;
				console.log('updatedList : ', updatedList, this.studentsList);
				// Assuming this.studentsList and this.studentsListNew are arrays of objects
				for (let i = 0; i < this.studentsList.length; i++) {
					const student = this.studentsList[i];
					const matchingStudentIndex = updatedList.findIndex((newStudent) => newStudent.checkin_user.id === student.checkin_user.id);
					console.log('matchingStudentIndex : ', matchingStudentIndex);

					if (matchingStudentIndex !== -1) {
						// Replace the object in this.studentsList with the matching object from this.studentsListNew
						this.studentsList.splice(i, 1, updatedList[matchingStudentIndex]);
						this.studentsListEdited.splice(i, 1, updatedList[matchingStudentIndex]);
						//   this.studentsListEdited = JSON.parse(JSON.stringify(this.studentsList));
					}
				}
			},
		});
	}

	checkIn() {
		const checkedInIds: Array<number> = this.changedObjects.filter((obj) => obj.state === 'checked_in').map((obj) => obj.checkin_user.id);

		const notCheckedInIds: Array<number> = this.changedObjects.filter((obj) => obj.state === 'not_checked_in').map((obj) => obj.checkin_user.id);
		this.checkedInNumber = checkedInIds.length;
		if (checkedInIds.length > 0) {
			const body = {
				event_id: this.emergencyData.id,
				checkin_user_ids: checkedInIds,
				state: 'checked_in',
			};
			this.emergencyService.addCheckInList(body).subscribe({
				next: (result: any) => {
					this.checkedIn = true;
					this.changedObjects = [];
					this.studentsList = JSON.parse(JSON.stringify(this.studentsListEdited));
				},
				error: (error) => {
					console.log('error : ', error);
				},
			});
		}
	}

	checkInMore() {
		this.checkedIn = false;
		this.checkedInNumber = 0;
		this.getCheckInList();
	}

	signOut() {
		console.log('navigating to sign-out');
		this.router.navigate(['sign-out']);
	}
}
