import { Pipe, PipeTransform } from '@angular/core';
import { Button } from '../models/Button';

@Pipe({
	name: 'buttonVisibility',
})
export class ButtonVisibilityPipe implements PipeTransform {
	transform(button: Button, roles: string[]): boolean {
		return !button.hidden && button.requiredRoles.some((role) => roles.includes(role));
	}
}
