import { Component, Directive, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Directive({
	selector: '[sp-collapsible-card-header]',
})
export class CollapsibleCardHeader {}

@Directive({
	selector: '[collapsible-card-content]',
})
export class CollapsibleCardContent {}

@Component({
	selector: 'sp-collapsible-card',
	templateUrl: './collapsible-card.component.html',
	styleUrls: ['./collapsible-card.component.scss'],
})
export class CollapsibleCardComponent {
	@ViewChild('cardContent', { read: ElementRef, static: false }) cardContent: ElementRef;
	@Input() title: string;
	@Input() leftIcon: string;
	@Input() rightIcon: string;
	@Input() collapsible = false;
	@Input() collapsed = false;
	@Input() collapsedBubbleText: string;
	@Output() titleLeftContainerClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() titleRightContainerClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	toggleCollapsedState() {
		this.collapsed = !this.collapsed;
		this.titleRightContainerClicked.emit(this.collapsed);
	}
}
