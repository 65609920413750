import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { captureMessage } from '@sentry/angular';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	showError$: Subject<unknown> = new Subject<unknown>();

	memoryStore: Record<string, string> = {};

	shouldUseLocalStorage: boolean;

	constructor(private router: Router, private matDialog: MatDialog) {
		this.shouldUseLocalStorage = this.confirm();
	}

	confirm(): boolean {
		const uid = 'confirm';
		let result;
		try {
			localStorage.setItem(uid, uid);
			result = localStorage.getItem(uid) === uid;
			localStorage.removeItem(uid);
			return result && !!localStorage;
		} catch (exception) {
			return false;
		}
	}

	getItem(key: string): string | null {
		if (this.confirm()) {
			if (!this.shouldUseLocalStorage) {
				captureMessage('getItem using localStorage after memoryStore', { extra: { key } });
			}
			let value = localStorage.getItem(key);
			// If localStorage isn't set and the key exists in defaults,
			// get the value and set it to localStorage.
			if (value === null && key in StorageKeys.defaults) {
				value = StorageKeys.defaults[key];
				this.setItem(key, value);
			}
			return value;
		} else {
			if (this.shouldUseLocalStorage) {
				captureMessage('getItem using memoryStore after localStorage', { extra: { key } });
			}
			if (!this.memoryStore[key]) {
				return null;
			}
			return this.memoryStore[key];
		}
	}

	setItem(key: string, data: any) {
		if (this.confirm()) {
			if (!this.shouldUseLocalStorage) {
				captureMessage('setItem using localStorage after memoryStore', { extra: { key, data } });
			}
			return localStorage.setItem(key, data);
		} else {
			if (this.shouldUseLocalStorage) {
				captureMessage('setItem using memoryStore after localStorage', { extra: { key, data } });
			}
			if (this.memoryStore[key]) {
				this.removeItem(key);
			}
			return (this.memoryStore[key] = data);
		}
	}

	setMap(key: string, value: unknown): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getMap(key: string): unknown | null {
		const item = localStorage.getItem(key);
		return item ? JSON.parse(item) : null;
	}

	removeItem(key: string) {
		if (this.confirm()) {
			if (!this.shouldUseLocalStorage) {
				captureMessage('removeItem using localStorage after memoryStore', { extra: { key } });
			}
			return localStorage.removeItem(key);
		}
		if (this.shouldUseLocalStorage) {
			captureMessage('removeItem using memoryStore after localStorage', { extra: { key } });
		}
		return delete this.memoryStore[key];
	}

	clear() {
		if (this.confirm()) {
			return localStorage.clear();
		} else {
			this.memoryStore = {};
		}
	}
}

export class StorageKeys {
	static sounds = 'sounds';
	static selectedEffectiveUserId = 'selected_effective_user_id';
	static kioskModeLocationId = 'kiosk_mode_location_id';
	static googleAuth = 'google_auth';
	static currentKioskRoom = 'current-kiosk-room'; // deprecated but leaving for schools that haven't logged out of kiosk mode
	static calendarDateAndTime = 'calendar_date_and_time';
	static selectedLocation = 'selectedLocation'; // deprecated but leaving for schools that haven't logged out of kiosk mode
	static selectedLocationV2 = 'selected_location_v2';
	static showPastHolidays = 'show_past_holidays';
	static schoolYearTabIndex = 'school_year_tab_index';
	static selectSchoolYearORHoliday = 'select_school_year_or_holiday';

	static defaults = {
		sounds: true,
	};
}
