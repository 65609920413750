import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[spHover]',
})
export class HoverDirective implements OnInit {
	@Input('spHover') spHover: string;

	private hovered = false;
	private pressed = false;
	private defaultBackground: string; // valid CSS color value
	private activeBackground: string; // valid CSS color value

	constructor(private elementRef: ElementRef<any>, private renderer2: Renderer2) {}

	ngOnInit() {
		[this.activeBackground, this.defaultBackground] = this.spHover.split(',').map((c) => c.trim());
	}

	@HostListener('mouseenter') onMouseEnter() {
		this.hovered = true;
		this.calculateBackground();
	}

	@HostListener('mouseleave') onMouseLeave() {
		this.hovered = false;
		this.pressed = false;
		this.calculateBackground();
	}

	@HostListener('mousedown') onMouseDown() {
		this.pressed = true;
		this.calculateBackground();
	}

	@HostListener('mouseUp') onMouseUp() {
		this.pressed = false;
		this.calculateBackground();
	}

	private calculateBackground() {
		const background = this.hovered ? this.activeBackground : this.defaultBackground;
		this.renderer2.setStyle(this.elementRef.nativeElement, 'background', background);
	}
}
