<div class="confirm-dialog" [style.gap]="data?.icon?.spacing" [style.padding]="data?.padding">
	<div *ngIf="data?.icon" class="icon-wrapper" [style.background]="data?.icon?.background">
		<img *ngIf="data?.icon?.name" [src]="'./assets/' + data?.icon?.name | resolveAsset" />
	</div>
	<div class="main-wrapper">
		<div *ngIf="!!data.headerText" class="ds-mb-10">
			<p class="dialog-title" style="margin: 0" [style.fontSize]="data?.headerFontSize">{{ data.headerText }}</p>
		</div>

		<ng-container *ngIf="data.body; else defaultTemplate">
			<ng-template [ngTemplateOutlet]="data.body" [ngTemplateOutletContext]="templateData"></ng-template>
		</ng-container>
		<div class="buttons">
			<app-gradient-button textColor="#7083A0" customBackground="#F0F2F5" [withShadow]="false" [mat-dialog-close]="false"
				>{{ buttons.denyText }}
			</app-gradient-button>
			<app-gradient-button
				textColor="#FFFFFF"
				[customBackground]="buttons?.confirmColor ? buttons.confirmColor : '#E32C66'"
				[withShadow]="false"
				[mat-dialog-close]="true"
				>{{ buttons.confirmText }}
			</app-gradient-button>
		</div>
	</div>
</div>

<ng-template #defaultTemplate>
	<p class="ds-dialog-normal-text ds-my-5">{{ data.templateData.detailText }}</p>
</ng-template>
