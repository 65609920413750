<div
	[class]="'tw-flex tw-flex-col tw-min-h-66 ' + displayData.classes ? displayData.classes : ''"
	[ngClass]="{ 'tw-pb-7': !displayData.displayModalFooter }"
	#modalContainer>
	<!-- HEADER -->
	<div class="tw-p-7 tw-pb-6" #modalHeader [ngStyle]="{ 'box-shadow': scrolled ? '0px 9.6px 9.6px 0px rgba(16, 20, 24, 0.05)' : 'none' }">
		<div class="tw-flex tw-justify-between">
			<div *ngIf="displayData?.icon" class="icon-wrapper" [style.background]="displayData?.icon.background">
				<img
					*ngIf="displayData?.icon?.name"
					[src]="'./assets/' + displayData?.icon?.name | resolveAsset"
					[style.height]="displayData?.icon.size || 'auto'" />
			</div>
			<div [style.width]="'100%'">
				<div class="tw-font-bold tw-flex-col tw-text-xl tw-text-navy-500" *ngIf="displayData?.headerText">
					{{ displayData.headerText }}
				</div>
			</div>
			<div class="tw-flex-col tw-cursor-pointer" *ngIf="displayData?.showCloseIcon" (click)="dialogRef.close('secondary')">
				<img src="assets/Close (Gray-500).svg" />
			</div>
		</div>
		<div class="tw-m-0">
			<ng-template [ngTemplateOutlet]="displayData.subHeaderTemplate" *ngIf="displayData?.subHeaderTemplate"></ng-template>
		</div>
	</div>
	<!-- BODY -->
	<div
		class="tw-flex tw-flex-grow tw-flex-col tw-pr-7"
		[ngClass]="{ 'tw-overflow-auto': hasScroll }"
		#modalBodyContainer
		[style.height]="modalBodyContainerHeightStr"
		[style.padding-left]="displayData?.icon ? '85px' : '28px'"
		(scroll)="onScroll($event)">
		<ng-template
			[ngTemplateOutlet]="displayData.modalBody"
			[ngTemplateOutletContext]="displayData?.templateData"
			*ngIf="displayData?.modalBody"></ng-template>
	</div>
	<!-- FOOTER -->
	<div
		*ngIf="displayData.displayModalFooter"
		class="tw-flex tw-items-center tw-p-6 tw-pt-5.75"
		[ngClass]="{
			'tw-justify-between': isWizard || displayData?.leftFooterTemplate,
			'tw-justify-end': !isWizard && !displayData?.leftFooterTemplate
		}"
		[ngStyle]="{ 'border-top': hasScroll ? '1px solid #D4D9E2' : 'none' }"
		#modalFooter>
		<p *ngIf="isWizard" class="tw-m-0 tw-text-gray-500 tw-font-normal tw-text-sm">Step {{ stepIndex + 1 }} of {{ data.steps.length }}</p>
		<div class="tw-m-0 tw-text-gray-500 tw-font-normal tw-text-sm">
			<ng-template [ngTemplateOutlet]="displayData.leftFooterTemplate" *ngIf="displayData?.leftFooterTemplate"></ng-template>
		</div>
		<div class="tw-flex tw-justify-end tw-gap-2 tw-items-center">
			<app-gradient-button
				[gradient]="displayData.secondaryButtonGradientBackground"
				[textColor]="displayData.secondaryButtonTextColor"
				[disabled]="displayData.disableSecondaryButton"
				*ngIf="displayData?.secondaryButtonLabel"
				(buttonClick)="onSecondaryButtonClick()">
				<div>
					{{ displayData.secondaryButtonLabel }}
				</div>
			</app-gradient-button>
			<app-gradient-button
				*ngIf="displayData?.primaryButtonLabel"
				customToolTip
				[contentTemplate]="displayData?.primaryButtonTooltip"
				[positionStrategy]="{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'top', offsetY: 35 }"
				(buttonClick)="onPrimaryButtonClick()"
				[disabled]="data?.disablePrimaryButton"
				[customShadow]="'0px 2px 10px 0px #10141833'"
				[gradient]="displayData.primaryButtonGradientBackground ? displayData.primaryButtonGradientBackground : null"
				[textColor]="displayData.primaryButtonTextColor ? displayData.primaryButtonTextColor : null">
				<div>
					{{ displayData.primaryButtonLabel }}
				</div>
			</app-gradient-button>
		</div>
	</div>
</div>
