import { Injectable } from '@angular/core';

import { Moment } from 'moment';
import * as moment from 'moment-timezone';

@Injectable({
	providedIn: 'root',
})
export class TimeZoneService {
	tenantTimeZone: string;

	constructor() {
		this.tenantTimeZone = 'GMT';
	}

	setTenantTimeZone(tenantTz: string) {
		this.tenantTimeZone = tenantTz;
	}

	utcToTenant(utcDateTime: Moment): Moment {
		return moment.tz(utcDateTime, this.tenantTimeZone);
	}

	utcToTenantString(utcDateTime: Moment, format: string): string {
		format = moment.tz.guess() === this.tenantTimeZone ? format : format + ' (UTC Z)';
		return moment.tz(utcDateTime, this.tenantTimeZone).format(format);
	}

	tenantToUtc(tenantDateTime: Moment): Moment {
		return moment(tenantDateTime).utc();
	}
}
