import { environment } from '../../../../environments/environment';
import { LoginServer, makeUrl } from '../../../services/http-service';

function getServerFromStorage(): { server: LoginServer } {
	const server = localStorage.getItem('server');
	if (!server) {
		return null;
	}
	return JSON.parse(server);
}

function getAuthHeader() {
	const headers = {};
	if (/(proxy)/.test(environment.buildType)) {
		const auth = JSON.parse(localStorage.getItem('auth'));
		if (auth && auth.auth && auth.auth.access_token) {
			headers['Authorization'] = `Bearer ${auth.auth.access_token}`;
		}
	}
	return headers;
}

async function httpRequest<T>(method, url: string, headers: HeadersInit = {}, body: object = null): Promise<T> {
	const response = await fetch(url, {
		method,
		headers,
		body: body ? JSON.stringify(body) : null,
		credentials: 'include',
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(`HTTP Error: ${response.status}, ${errorText}`);
	}

	return await response.json();
}

async function fetchBlob(url: string, headers: HeadersInit, body: object): Promise<Blob> {
	const response = await fetch(url, {
		method: 'POST',
		headers,
		body: JSON.stringify(body),
		credentials: 'include',
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(`HTTP Error: ${response.status}, ${errorText}`);
	}

	return await response.blob();
}

export async function spPost<T>(endpoint: string, body = {}, additionalHeaders: HeadersInit = {}): Promise<T> {
	const server = getServerFromStorage();
	if (!server) {
		throw new Error('Server not found');
	}
	const fullUrl = makeUrl(server.server, endpoint);
	const headers = {
		'Content-Type': 'application/json',
		...getAuthHeader(),
		...additionalHeaders,
	};

	return httpRequest<T>('POST', fullUrl, headers, body);
}

export async function spPostBlob(endpoint: string, body = {}, additionalHeaders: HeadersInit = {}): Promise<Blob> {
	const server = getServerFromStorage();
	if (!server) {
		throw new Error('Server not found');
	}
	const fullUrl = makeUrl(server.server, endpoint);
	const headers: HeadersInit = {
		'Content-Type': 'application/json',
		...getAuthHeader(),
		...additionalHeaders,
	};

	return fetchBlob(fullUrl, headers, body);
}

export async function spGet<T>(endpoint: string, additionalHeaders: HeadersInit = {}): Promise<T> {
	const server = getServerFromStorage();
	if (!server) {
		throw new Error('Server not found');
	}
	const fullUrl = makeUrl(server.server, endpoint);
	const headers = {
		...getAuthHeader(),
		...additionalHeaders,
	};

	return httpRequest<T>('GET', fullUrl, headers);
}

export async function spDel<T>(endpoint: string, additionalHeaders: HeadersInit = {}, body: object = null): Promise<T> {
	const server = getServerFromStorage();
	if (!server) {
		throw new Error('Server not found');
	}
	const fullUrl = makeUrl(server.server, endpoint);
	const headers = {
		'Content-Type': 'application/json',
		...getAuthHeader(),
		...additionalHeaders,
	};

	return httpRequest<T>('DELETE', fullUrl, headers, body);
}
