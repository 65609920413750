<div class="host">
	<div class="container">
		<div class="unit-box">
			<div class="scroll">
				<!--        (mouseenter)="onOver($event)"-->
				<!--        (mouseleave)="onOut($event)"-->
				<!--        (scroll)="onScroll($event)"-->
				<div class="scrollable-container" (touchstart)="onDown($event)" (touchend)="onOut($event)" (touchmove)="onMove($event)">
					<div class="scrollable">
						<!--            {{counterX}} - {{overScroll}}-->
					</div>
				</div>
			</div>

			<div class="shadow-gradient-top"></div>

			<div class="unit-clip" [style.left.px]="leftShift" [style.right.px]="rightShift">
				<div class="unit" [style.transform]="daysRotate$ | async" [style.transition]="endTransition">
					<div class="item" *ngFor="let day of days | async" [style.justify-content]="align" [style.transform]="day.rotate">
						<span>
							{{ day.value }}
						</span>
					</div>
				</div>
			</div>

			<div class="shadow-gradient-bottom"></div>

			<div class="completed-date" [style.left.px]="leftShift" [style.right.px]="rightShift">
				<div class="unit-clip">
					<div class="unit" [style.transform]="daysRotate$ | async" [style.transition]="endTransition">
						<div class="item" *ngFor="let day of days | async" [style.justify-content]="align" [style.transform]="day.rotate">
							<span>
								{{ day.value }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
