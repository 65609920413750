import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddFlexScheduleRequest, FlexPeriodService, FlexSchedule } from '../../../flex-period.service';
import { finalize } from 'rxjs/operators';
import { ToastService } from '../../../services/toast.service';

export enum DaysOfWeek {
	Sun,
	Mon,
	Tue,
	Wed,
	Thu,
	Fri,
	Sat,
}

@Component({
	selector: 'sp-create-flex-period',
	templateUrl: './create-flex-period.component.html',
	styleUrls: ['./create-flex-period.component.scss'],
})
export class CreateFlexPeriodComponent implements OnInit {
	closeDialog: () => void;

	editingMode = false;
	flexForm: FormGroup;
	loading = false;
	processing = false;
	private schedules: FlexSchedule[] = [];
	private selectedDays: number[] = [];
	private headerText = 'New Flex Period';

	constructor(private flexPeriodService: FlexPeriodService, private toastService: ToastService) {}

	ngOnInit() {
		this.flexForm = new FormGroup({
			name: new FormControl('', Validators.required),
			start_time: new FormControl('10:00', Validators.required),
			end_time: new FormControl('11:00', Validators.required),
		});
	}

	toggleDay(day: number) {
		const index = this.selectedDays.indexOf(day);

		if (index === -1) {
			// If the day is not in the array, add it
			this.selectedDays.push(day);
		} else {
			// If the day is in the array, remove it
			this.selectedDays.splice(index, 1);
		}
		this.selectedDays.sort((a, b) => a - b);
	}

	displayDays(days: number[]): string {
		if (this.selectedDays.length === 0) {
			return 'No days selected';
		}
		return 'Every ' + days.map((day) => DaysOfWeek[day]).join(', ');
	}

	private isFormValid(): boolean {
		return (
			this.flexForm.valid &&
			this.selectedDays.length > 0 &&
			this.flexForm.value.start_time < this.flexForm.value.end_time &&
			this.flexForm.value.name.length > 0
		);
	}

	handleAddScheduleClick() {
		if (this.selectedDays.length > 0) {
			this.addFormToSchedules();
		}
		this.toggleEditMode();
	}

	toggleEditMode() {
		this.editingMode = !this.editingMode;
		if (this.editingMode) {
			this.headerText = `${this.toAmPm(this.flexForm.value.start_time)} - ${this.toAmPm(this.flexForm.value.end_time)}`;
		} else {
			this.headerText = 'New Flex Period';
		}
	}

	toAmPm(time: string): string {
		// Convert to a Date object
		const timeDate = new Date(`1970-01-01T${time}:00`);

		// Get the hours and minutes
		let hours = timeDate.getHours();
		const minutes = timeDate.getMinutes();

		// Convert to 12-hour format and determine AM/PM
		const period = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'

		// Ensure minutes are two digits
		const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();

		return `${hours}:${minutesStr} ${period}`;
	}

	onSubmit() {
		if (this.editingMode) {
			this.toggleEditMode();
			return;
		} else if (this.processing) {
			return;
		} else if (!this.isFormValid) {
			this.toastService.openToast({ title: `Invalid data provided, try again with different selections`, type: 'error' });
			return;
		}
		this.processing = true;
		this.addFormToSchedules();
		const request = {
			name: this.flexForm.value.name as string,
			schedules: this.schedules,
		} as AddFlexScheduleRequest;

		this.loading = true;
		this.flexPeriodService
			.AddScheduleToFlexPeriod(request)
			.pipe(finalize(() => (this.loading = false)))
			.subscribe({
				next: () => {
					this.toastService.openToast({ title: `Flex period successfully created!`, type: 'success' });
					this.editingMode = false;
					this.processing = false;
					this.closeDialog();
				},
				error: (err) => {
					console.error(err);
					this.toastService.openToast({ title: `Error while creating flex period, try again.`, type: 'error' });
				},
			});
	}

	addFormToSchedules(): void {
		if (!this.isFormValid) {
			this.toastService.openToast({ title: `Invalid data provided, try again with different selections`, type: 'error' });
			return;
		}
		const startParts = this.flexForm.value.start_time.split(':');
		const endParts = this.flexForm.value.end_time.split(':');
		const schedule = {
			days_of_week: this.selectedDays,
			start_hour: parseInt(startParts[0], 10),
			start_minute: parseInt(startParts[1], 10),
			end_hour: parseInt(endParts[0], 10),
			end_minute: parseInt(endParts[1], 10),
		};

		this.schedules.push(schedule);
		this.selectedDays = [];
		this.flexForm.patchValue({
			start_time: '10:00',
			end_time: '11:00',
		});
	}
}
