import { Component, Input } from '@angular/core';
@Component({
	selector: 'sp-thin-pass',
	template: `
		<div
			class="container"
			[style.width]="width"
			[style.height]="height"
			[style.background]="background"
			(mousedown)="onMouseDown()"
			(mouseleave)="onMouseLeave()"
			[ngClass]="{ pressed: pressed }">
			<div class="left-content">
				<sp-circle-countdown [diameter]="width == '460px' ? 60 : 49" [progress]="progress">
					<ng-content select="[circle]"></ng-content>
				</sp-circle-countdown>
				<div class="text-container">
					<span class="title" [style.font-size]="width == '460px' ? '24px' : '16px'">{{ title }}</span>
					<ng-content select="[subtitle]"></ng-content>
				</div>
			</div>
			<div class="right-content">
				<ng-content select="[right]"></ng-content>
			</div>
		</div>
	`,
	styles: [
		`
			.container {
				border-radius: 16px;
				box-shadow: 0px 2px 4px 0px rgba(16, 20, 24, 0.08);
				border: 2px solid rgba(255, 255, 255, 0.7);

				transition: background 2s;
				position: relative;

				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.container:hover {
				box-shadow: 0px 2px 8px 0px rgba(16, 20, 24, 0.2);
			}
			.container.pressed,
			.container.pressed:hover {
				transform: scale(0.98);
				box-shadow: 0px 2px 4px 0px rgba(16, 20, 24, 0.08);
			}

			.left-content {
				width: 316px;
				padding: 24px;
				flex-direction: row;
				display: flex;
				gap: 14px;
				align-items: center;
			}

			.right-content {
				margin-right: 24px;
			}

			.text-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 4px;
			}

			.title {
				color: #fff;
				text-align: center;

				/* Header 4 */
				font-family: Inter, sans-serif;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		`,
	],
})
export class ThinPassComponent {
	@Input()
	title: string;

	@Input()
	progress: number;

	@Input()
	background: string;

	@Input() width = '460px';
	@Input() height = '112px';

	pressed = false;

	onMouseDown(): void {
		this.pressed = true;
	}
	onMouseLeave(): void {
		this.pressed = false;
	}
}
