import _refiner from 'refiner-js';

import { School } from './models/School';
import { User } from './models/User';

function userToRefinerType(user: User) {
	if (user.isAdmin()) {
		return 'admin';
	} else if (user.isTeacher()) {
		return 'teacher';
	} else if (user.isAssistant()) {
		return 'assistant';
	} else if (user.isParent()) {
		return 'parent';
	} else if (user.isKiosk()) {
		return 'kiosk';
	} else if (user.isStudent()) {
		return 'student';
	} else {
		return 'other';
	}
}

export function registerRefiner(user: User, school: School): void {
	_refiner('setProject', 'e832a600-7fe2-11ec-9b7a-cd5d0014e33d');
	const actively_using_date = school.actively_using_date;
	let isoStr = '';

	if (actively_using_date) {
		const date = new Date(actively_using_date);
		isoStr = date.toISOString();
	}
	const data = {
		id: user.id,
		email: user.primary_email,
		created_at: user.created,
		last_login_at: user.last_login,
		last_active_at: user.last_active,
		first_login_at: user.first_login,
		first_name: user.first_name,
		last_name: user.last_name,
		type: userToRefinerType(user),
		status: user.status,
		sync_types: !user.sync_types.length ? 'password' : user.sync_types.length === 1 ? user.sync_types[0] : user.sync_types,
		name: user.display_name,
		account: {
			id: school.id,
			name: school.name,
			actively_using_at: isoStr,
		},
	};
	_refiner('identifyUser', data);
	console.debug('refiner registered');
}

export function deregisterRefiner(): void {
	_refiner('stopPinging');
	_refiner('resetUser');
	console.debug('refiner deregistered');
}
