<ng-container>
	<div class="ds-tooltip">
		<p *ngIf="text" class="tw-m-0">{{ text }}</p>
		<ng-content select="[dataDetails]"></ng-content>
		<a *ngIf="link" [href]="link" target="_blank" class="link" (mousedown)="$event.stopPropagation()">{{ linkText || 'Learn more' }}</a>
		<div *ngIf="contentTemplate">
			<ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
		</div>
	</div>
</ng-container>
