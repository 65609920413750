import { Component } from '@angular/core';

@Component({
	selector: 'sp-top-nav-bar',
	templateUrl: './top-nav-bar.component.html',
	styleUrls: ['./top-nav-bar.component.scss'],
})
export class TopNavBarComponent {
	showMainForm(_future: boolean): void {
		// TODO - Implement
	}

	showScheduleForm(): void {
		// TODO - Implement
	}
}
