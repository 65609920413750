<div class="teacher-toggle-bar-wrapper" #teacherToggle id="teacher_toggle_bar">
	<div class="teacher-selection" appCrossPointerEventTarget (pointerClickEvent)="showTeaches($event)">
		<div class="assistant-text">Acting on behalf of</div>
		<div class="profile-image avatar-animate" *ngIf="isEnabledProfilePictures$ | async">
			<div
				*ngIf="effectiveUser?.profile_picture; else avatar"
				[ngStyle]="{
					background: 'url(' + effectiveUser?.profile_picture + ') no-repeat left center/cover',
					width: '34px',
					height: '34px'
				}"></div>
			<ng-template #avatar>
				<img class="tw-h-8" [src]="'./assets/Avatar Default.svg' | resolveAsset" />
			</ng-template>
		</div>
		<span notranslate class="tw-cursor-pointer tw-font-medium">
			{{ effectiveUser?.display_name || '' }}
		</span>
		<img *ngIf="effectiveUser && effectiveUser?.badge" style="margin-left: 8px" width="25" height="25" [src]="'./assets/Badge.png' | resolveAsset" />
		<img width="10" class="ds-ml-5" [src]="icon" alt="Rect" />
	</div>
</div>
