<!-- TEACHER -->
<div
	class="pass-tile-medium"
	[ngClass]="{ pressed: pressed }"
	*ngIf="isStaff"
	[ngStyle]="{ 'background-image': tileBackgroundStyle, border: tileBorder, 'box-shadow': boxShadow }"
	(mouseleave)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none'); pressed = false"
	(mouseenter)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none')"
	(mousedown)="pressed = true">
	<div class="profile-picture">
		<img *ngIf="studentProfilePictureSafe" [src]="studentProfilePictureSafe" alt="photo of {{ studentName }}" />
		<img *ngIf="!studentProfilePictureSafe" src="assets/Avatar Default.svg" alt="photo of {{ studentName }}" />
	</div>
	<div class="middle-col">
		<div class="student-name">{{ studentName }}</div>
		<div class="room-icon-name">
			<div class="room-icon" [ngStyle]="{ 'background-image': roomIconBackgroundStyle }">
				<img *ngIf="roomIconSafe" [src]="roomIconSafe" alt="room icon" />
			</div>
			<div class="room-name">{{ roomName }}</div>
		</div>
		<div class="time-to-go" *ngIf="dateMessage" [ngStyle]="{ 'text-decoration': isMissed ? 'line-through' : 'none' }">
			{{ dateMessage }}
		</div>
	</div>
	<div class="right-col">
		<div *ngIf="isMissed" class="missed">Missed</div>
		<div *ngIf="isDeclined && isFutureRequest" class="missed">Denied</div>
		<div class="time-ago" *ngIf="sentOrReceivedTime">{{ sentOrReceivedTime }}</div>
		<div class="action"></div>
	</div>
</div>

<!-- STUDENT -->
<div
	class="pass-tile-medium"
	[ngClass]="{ pressed: pressed }"
	*ngIf="isStudent"
	[ngStyle]="{ 'background-image': tileBackgroundStyle, border: tileBorder, 'box-shadow': boxShadow }"
	(mouseleave)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none')"
	(mouseenter)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none'); pressed = false"
	(mousedown)="pressed = true">
	<div class="room-icon" [ngStyle]="{ 'background-image': roomIconBackgroundStyle }">
		<img *ngIf="roomIconSafe" [src]="roomIconSafe" alt="room icon" />
	</div>
	<div class="middle-col">
		<div class="room-name">{{ roomName }}</div>
		<div class="time-to-go" *ngIf="dateMessage" [ngStyle]="{ 'text-decoration': isMissed ? 'line-through' : 'none' }">{{ dateMessage }}</div>
	</div>
	<div class="right-col">
		<div *ngIf="isMissed" class="missed">Missed</div>
		<div *ngIf="isDeclined && isFutureRequest" class="missed">Denied</div>
		<div class="time-ago" *ngIf="sentOrReceivedTime">{{ sentOrReceivedTime }}</div>
		<div class="action"></div>
	</div>
</div>
