<div class="create-flex">
	<form [formGroup]="flexForm" (ngSubmit)="onSubmit()" *ngIf="flexForm">
		<div class="cf-header">
			<div>
				<img [src]="'./assets/Chevron Left (Blue-Gray).svg' | resolveAsset" alt="Add Schedule" *ngIf="editingMode" (click)="toggleEditMode()" />
			</div>
			<span>{{ headerText }}</span>
			<button
				type="submit"
				[disabled]="!flexForm.valid || selectedDays.length === 0 || flexForm.get('start_time').value > flexForm.get('end_time').value || loading">
				Save
			</button>
		</div>
		<div class="cf-content" *ngIf="!loading">
			<div class="cf-start" *ngIf="!editingMode">
				<span class="cf-field-name">Name</span>
				<input type="text" formControlName="name" [placeholder]="'Flex Period'" />
				<div class="add-schedule">
					<span class="cf-field-name">Schedule</span>
					<div (click)="handleAddScheduleClick()">
						<img [src]="'./assets/Plus (Blue-Gray).svg' | resolveAsset" alt="Clock" />
						<span>Add Schedule</span>
					</div>
				</div>
				<div class="schedule-list" *ngIf="selectedDays?.length > 0">
					<div class="schedule-display">
						<img [src]="'./assets/Clock (Navy).svg' | resolveAsset" alt="Add Schedule" />
						<div>
							<span>{{ flexForm.value.start_time | timeFormat }} - {{ flexForm.value.end_time | timeFormat }}</span>
							<span>{{ displayDays(selectedDays) }}</span>
						</div>
					</div>
					<div class="schedule-display" *ngFor="let schedule of schedules">
						<img [src]="'./assets/Clock (Navy).svg' | resolveAsset" alt="Clock" />
						<div>
							<span
								>{{ schedule.start_hour + ':' + schedule.start_minute | timeFormat }} -
								{{ schedule.end_hour + ':' + schedule.end_minute | timeFormat }}</span
							>
							<span>{{ displayDays(schedule.days_of_week) }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="cf-schedule-add" *ngIf="editingMode">
				<div class="cf-clock">
					<span>From</span>
					<input type="time" formControlName="start_time" required />
				</div>
				<div class="cf-clock">
					<span>To</span>
					<input type="time" formControlName="end_time" required />
				</div>
				<div class="days-of-week">
					<div (click)="toggleDay(1)" [ngClass]="{ selected: selectedDays.indexOf(1) !== -1 }">M</div>
					<div (click)="toggleDay(2)" [ngClass]="{ selected: selectedDays.indexOf(2) !== -1 }">T</div>
					<div (click)="toggleDay(3)" [ngClass]="{ selected: selectedDays.indexOf(3) !== -1 }">W</div>
					<div (click)="toggleDay(4)" [ngClass]="{ selected: selectedDays.indexOf(4) !== -1 }">T</div>
					<div (click)="toggleDay(5)" [ngClass]="{ selected: selectedDays.indexOf(5) !== -1 }">F</div>
				</div>
			</div>
			<div *ngIf="editingMode" class="days-msg">{{ displayDays(selectedDays) }}</div>
		</div>
		<div class="cf-loading spinner ds-flex-center-center" *ngIf="loading">
			<mat-spinner [diameter]="45"></mat-spinner>
		</div>
	</form>
</div>
