<div [formGroup]="formGroup">
	<div [ngClass]="{ InputWrapper: true }">
		<div style="display: flex; position: relative">
			<label
				[ngClass]="{
					'ds-dialog-heading': controlName.valid || controlName.pending || isFocus,
					'ds-dialog-heading-gray': controlName.invalid,
					clserrorLabel: (!isFocus && controlName.invalid && controlName.errors && controlName.dirty) || forceError
				}"
				[attr.for]="inputId">
				{{ input_label }}
			</label>
			<div class="tooltip-container">
				<img
					[customToolTip]="tooltipText"
					(mouseover)="over.emit({ event: $event, action: 'timeLimit' })"
					(mouseleave)="leave.emit('timeLimit')"
					class="support-icon"
					*ngIf="rightIcon"
					[src]="rightIcon | resolveAsset" />
			</div>
			<div class="show-password" *ngIf="showPasswordButton" appCrossPointerEventTarget (pointerClickEvent)="changeInput()">
				<img width="25" height="20" [src]="'./assets/Eye' + (input_type === 'password' ? '' : ' crossed') + ' (Blue-Gray).svg' | resolveAsset" />
				<div class="password-text">{{ input_type === 'password' ? 'Show' : 'Hide' }}</div>
			</div>
		</div>
		<div class="input-container">
			<input
				[attr.data-cy]="dataCy"
				[style.color]="color"
				[autocomplete]="autocomplete"
				[attr.disabled]="disabled ? '' : null"
				[style.background-color]="isFocus && inp.focused ? 'white' : unfocusedBackgroundColor"
				[tabindex]="tabIndex"
				[pattern]="pattern"
				[style.width]="containerWidth"
				[style.height]="height"
				[style.margin-top]="fieldSpace"
				[style.text-align]="textAlign"
				[style.box-sizing]="'border-box'"
				[style.padding]="padding + (textAlign ? ' 16px ' : ' 0px ') + padding + ' 16px'"
				[maxlength]="maxLength"
				[formControl]="controlName"
				[type]="input_type"
				ignore
				(focus)="updateFocus(inp); controlName.markAsUntouched(); inp.focused = true"
				(blur)="onBlur(inp.value)"
				(mouseover)="hovered = true"
				(mouseleave)="hovered = false"
				(click)="isFocus = true; updateFocus(inp)"
				[placeholder]="placeholder == '' ? '' : placeholder"
				[ngClass]="{
					clserrorInput: (controlName.invalid && controlName.errors && controlName.dirty && !isFocus) || forceError,
					contrastLight: contrastColor === 'light'
				}"
				spellcheck="false"
				[attr.id]="inputId"
				#inp />
			<div
				class="min"
				style="font-size: 1rem"
				(click)="isFocus = true; updateFocus(inp)"
				*ngIf="!disabled && controlName.valid && !isFocus && showUnits"
				[style.left]="minLeftMargin + 'px'"
				[style.color]="color">
				{{ units }}
			</div>
			<div class="tooltip-container tooltip-container__inline" *ngIf="controlName.touched && controlName.dirty && !isControlValid()">
				<img
					[customToolTip]="
						controlName.errors?.folder_name
							? 'Folder name already used'
							: controlName.errors?.room_name
							? 'Room name already used'
							: controlName.errors?.email_in_use
							? 'Email already in use'
							: controlName.errors?.invalid_email
							? 'Please use your school email address'
							: controlName.errors?.minPin
							? 'Pins must be 4 digits'
							: controlName.errors?.uniqEmail
							? 'This email/username is already taken'
							: controlName.errors?.minlength
							? 'Should be at least ' + controlName.errors.minlength.requiredLength + ' characters'
							: controlName.errors?.pattern
							? 'Does not meet requirements'
							: controlName.errors?.email
							? 'Must be Email'
							: controlName.errors?.schoolEmail
							? 'Please use a school email'
							: controlName.errors?.max
							? 'Must be less than 1000'
							: controlName.errors?.min
							? 'Must be greater than 0'
							: null
					"
					class="success-icon"
					[src]="'./assets/Report (Red).svg' | resolveAsset" />
			</div>
			<!--			<img-->
			<!--				*ngIf="isErrorIcon && !isControlValid()"-->
			<!--				class="success-icon"-->
			<!--				[src]="'./assets/Report (Red).svg' | resolveAsset" />-->
			<img *ngIf="isSuccessIcon && controlName.touched && isControlValid()" class="success-icon" [src]="'./assets/Check (Navy).svg' | resolveAsset" />
			<div class="disabled"></div>
		</div>
	</div>
</div>
