/* eslint-disable @typescript-eslint/no-empty-function */
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from '../models/User';
import { UserService } from '../services/user.service';

interface Message {
	created_at?: Date;
	id?: number;
	event_id?: number;
	message: string;
	school_id?: number;
	updated_at?: Date;
	user: User;
}

@Component({
	selector: 'sp-chatting',
	templateUrl: './chatting.component.html',
	styleUrls: ['./chatting.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChattingComponent implements OnInit, OnChanges {
	@Input() messageList: any[] = [];
	@Input() enableToSend = true;
	@Output() emitMessage: EventEmitter<any> = new EventEmitter<any>();
	user: User;
	newMessage = '';
	destroy$: Subject<any> = new Subject<any>();
	finalMessageList = [];

	constructor(private userService: UserService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.messageList && !changes.messageList.firstChange) {
			this.groupUser();
		}
	}

	ngOnInit(): void {
		this.userService.currentUpdatedUser$.pipe(filter<User>(Boolean), takeUntil(this.destroy$)).subscribe(
			(user: any) => {
				this.user = user;
			},
			(error) => {
				// Handle any errors that occur during the subscription
				console.error(error);
			}
		);

		this.groupUser();
	}

	isSendByUser(sender: User): boolean {
		return this.user.id === sender.id;
	}

	sendMessage() {
		if (this.newMessage?.trim() === '') {
			// If the newMessage is empty or contains only whitespace, return without sending.
			return;
		}
		this.emitMessage.emit(this.newMessage);
		this.newMessage = '';
	}

	groupUser() {
		function removeDuplicateDisplayNames(messages: Message[]): Message[] {
			let prevDisplayName = '';

			return messages.map((message) => {
				if (message.user.display_name === prevDisplayName) {
					delete message.user.display_name;
				} else {
					prevDisplayName = message.user.display_name;
				}

				return message;
			});
		}

		// Example usage
		const messages: Message[] = JSON.parse(JSON.stringify(this.messageList));

		this.finalMessageList = removeDuplicateDisplayNames(messages);
	}
}
