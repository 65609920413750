<!-- TEACHER -->
<div
	class="pass-tile-medium"
	[ngClass]="{ pressed: pressed }"
	*ngIf="isStaff"
	[ngStyle]="{ 'background-image': tileBackgroundStyle, border: tileBorder, 'box-shadow': boxShadow, height: isFutureRequest ? '126px' : '104px' }"
	(mouseleave)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none'); pressed = false"
	(mouseenter)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none')"
	(mousedown)="pressed = true"
	(click)="openPassInFullScreen()">
	<div
		class="profile-picture tw-relative"
		customToolTip
		[showToolTip]="pass?.attachment_message"
		[contentTemplate]="passMessageTooltip"
		[position]="'above'">
		<img
			(click)="openPassWithMessage($event)"
			*ngIf="pass?.attachment_message"
			class="tw-w-3 tw-p-1 tw-bg-white tw-absolute tw--top-1.5 tw--right-1.5 tw-border tw-border-gray-100 tw-border-solid tw-rounded-full"
			src="./assets/message-icon (Gray-400).svg"
			alt="Message" />
		<img class="profile" *ngIf="studentProfilePictureSafe" [src]="studentProfilePictureSafe" alt="photo of {{ studentName }}" />
		<img class="profile" *ngIf="!studentProfilePictureSafe" src="assets/Avatar Default.svg" alt="photo of {{ studentName }}" />
	</div>
	<ng-template #passMessageTooltip>
		<div class="ds-tooltip" style="max-width: 200px">"{{ pass?.attachment_message }}"</div>
	</ng-template>
	<div class="tw-w-full">
		<div class="tw-flex">
			<div class="middle-col">
				<div class="student-name">{{ studentName }}</div>
				<div class="room-icon-name" [ngStyle]="{ 'padding-bottom': !isFutureRequest ? '8px' : '4px' }">
					<div class="room-icon" [ngStyle]="{ 'background-image': roomIconBackgroundStyle }">
						<img *ngIf="roomIconSafe" [src]="roomIconSafe" alt="room icon" />
					</div>
					<div class="room-name">{{ roomName }}</div>
				</div>
				<div class="time-to-go tw-pb-2" *ngIf="dateMessage" [ngStyle]="{ 'text-decoration': isMissed ? 'line-through' : 'none' }">
					{{ dateMessage }}
				</div>
			</div>
			<div class="right-col">
				<div *ngIf="isMissed" class="missed">Missed</div>
				<div *ngIf="isDeclined && isFutureRequest" class="missed">Denied</div>
				<div class="time-ago" *ngIf="sentOrReceivedTime">{{ sentOrReceivedTime }}</div>
				<div class="action"></div>
			</div>
		</div>
		<div
			class="tw-flex tw-align-center tw-justify-between"
			*ngIf="!isMissed"
			(mouseenter)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none')"
			(mouseleave)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none')">
			<app-gradient-button
				[textColor]="'#FFFFFF'"
				leftIcon="./assets/Checkmark (White).svg"
				[leftImageHeight]="'12px'"
				[leftImageWidth]="'12px'"
				[fontSize]="'12px'"
				[fontWeight]="500"
				[width]="'134px'"
				[minHeight]="'31px'"
				[gradient]="pass?.color_profile?.gradient_color"
				[buttonDownColor]="pass?.color_profile?.gradient_color"
				[isGradient]="true"
				[size]="'editable'"
				[cursor]="'pointer'"
				[customShadow]="'0px 2px 10px 0px rgba(16, 20, 24, 0.10)'"
				(buttonClick)="approvePass($event)"
				>Approve
			</app-gradient-button>
			<app-gradient-button
				[textColor]="'#7083A0'"
				leftIcon="./assets/Close (Gray-500).svg"
				[leftImageHeight]="'12px'"
				[leftImageWidth]="'12px'"
				[fontSize]="'12px'"
				[fontWeight]="500"
				[width]="'134px'"
				[minHeight]="'29px'"
				[gradient]="'#FFFFFF, #FFFFFF'"
				[hoverColor]="'#F0F2F5'"
				[size]="'editable'"
				[cursor]="'pointer'"
				[customShadow]="'0px 2px 4px 0px rgba(16, 20, 24, 0.08)'"
				[border]="'1px solid #D4D9E2'"
				(buttonClick)="denyPass($event)"
				>Deny
			</app-gradient-button>
		</div>
		<div
			class="tw-flex tw-align-center tw-justify-between"
			*ngIf="isMissed"
			(mouseenter)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none')"
			(mouseleave)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none')">
			<app-gradient-button
				[textColor]="'#7083A0'"
				leftIcon="./assets/Delete (Gray-500).svg"
				[leftImageHeight]="'12px'"
				[leftImageWidth]="'12px'"
				[fontSize]="'12px'"
				[fontWeight]="500"
				[width]="'278px'"
				[minHeight]="'31px'"
				[gradient]="'#FFFFFF, #FFFFFF'"
				[hoverColor]="'#F0F2F5'"
				[size]="'editable'"
				[cursor]="'pointer'"
				[customShadow]="'0px 2px 4px 0px rgba(16, 20, 24, 0.08)'"
				[border]="'1px solid #D4D9E2'"
				(buttonClick)="deletePass($event)"
				>Delete
			</app-gradient-button>
		</div>
	</div>
</div>

<!-- STUDENT -->
<div
	class="pass-tile-medium"
	[ngClass]="{ pressed: pressed }"
	*ngIf="isStudent"
	[ngStyle]="{ 'background-image': tileBackgroundStyle, border: tileBorder, 'box-shadow': boxShadow }"
	(mouseleave)="hasBoxShadow ? (boxShadow = defaultBoxShadow) : (boxShadow = 'none')"
	(mouseenter)="hasBoxShadow ? (boxShadow = hoverBoxShadow) : (boxShadow = 'none'); pressed = false"
	(mousedown)="pressed = true">
	<div class="room-icon" [ngStyle]="{ 'background-image': roomIconBackgroundStyle }">
		<img *ngIf="roomIconSafe" [src]="roomIconSafe" alt="room icon" />
	</div>
	<div class="middle-col">
		<div class="room-name">{{ roomName }}</div>
		<div class="time-to-go" *ngIf="dateMessage" [ngStyle]="{ 'text-decoration': isMissed ? 'line-through' : 'none' }">{{ dateMessage }}</div>
	</div>
	<div class="right-col">
		<div *ngIf="isMissed" class="missed">Missed</div>
		<div *ngIf="isDeclined && isFutureRequest" class="missed">Denied</div>
		<div class="time-ago" *ngIf="sentOrReceivedTime">{{ sentOrReceivedTime }}</div>
		<div class="action"></div>
	</div>
</div>

<ng-template #PassLimitOverride>
	<div class="ds-dialog-normal-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>
