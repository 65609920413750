<div *ngIf="user" [ngClass]="{ 'no-fly-time-style': FORM_STATE.noFlyTimeActive && !FORM_STATE.forLater }" class="locations-group-wrapper">
	<!--  <pre>  {{FORM_STATE.state}} {{FORM_STATE.previousState}} {{ FORM_STATE.state > FORM_STATE.previousState}}</pre>-->
	<!--<pre>{{frameMotion$| async | json}}</pre>-->
	<!--  <pre>{{FORM_STATE.previousStep}} - {{FORM_STATE.previousState}}</pre>-->
	<div *ngIf="FORM_STATE.state === 1" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-from-where
			[studentText]="studentText"
			[formState]="FORM_STATE"
			[isStaff]="isStaff"
			[date]="showDate"
			(backButton)="back($event)"
			(selectedLocation)="originSelected($event)"></app-from-where>
	</div>
	<div *ngIf="FORM_STATE.state === 2" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-to-where
			[studentText]="studentText"
			[formState]="FORM_STATE"
			[isStaff]="isStaff"
			[date]="showDate"
			(backButton)="back($event)"
			(selectedLocation)="destinationSelected($event)"></app-to-where>
	</div>

	<div *ngIf="FORM_STATE.state === 2.5" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-from-to-where
			[studentText]="studentText"
			[formState]="FORM_STATE"
			[isStaff]="isStaff"
			[date]="showDate"
			[lockOriginLocation]="FORM_STATE.lockOriginLocation"
			[lockDestinationLocation]="FORM_STATE.lockDestinationLocation"
			(backButton)="back($event)"
			(selectedOriginLocation)="setOriginSelected($event)"
			(selectedDestinationLocation)="destinationSelected($event)">
		</app-from-to-where>
	</div>

	<div *ngIf="FORM_STATE.state === 3" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<sp-select-category-room
			[studentText]="studentText"
			[formState]="FORM_STATE"
			[isStaff]="isStaff"
			[isKioskMode]="isKioskMode"
			[date]="showDate"
			(backButton)="back($event)"
			(selectedLocation)="fromCategory($event)"></sp-select-category-room>
	</div>

	<div *ngIf="FORM_STATE.state === 4" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-restricted-target
			[formState]="FORM_STATE"
			[date]="showDate"
			[pinnable]="pinnable"
			(backButton)="back($event)"
			(requestTarget)="requestTarget($event)"></app-restricted-target>
	</div>

	<div *ngIf="FORM_STATE.state === 5" class="next-step" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<app-restricted-message
			[formState]="FORM_STATE"
			[isStaff]="isStaff"
			(backButton)="back($event)"
			[gradient]="FORM_STATE.data.gradient"
			[teacher]="data.requestTarget"
			[date]="showDate"
			(resultMessage)="resultMessage($event, !FORM_STATE.forInput && !FORM_STATE.missedRequest)"></app-restricted-message>
	</div>
</div>

<div
	*ngIf="pwaBackBtnVisibility"
	appCrossPointerEventTarget
	[ngClass]="isIOSTablet ? 'ios-back-btn-bottom' : 'back-btn-bottom'"
	class="back-button back-button-grey">
	<app-icon-button
		(clickEvent)="stepBack()"
		[srcString]="'Back Button'"
		[size]="28"
		[onDarkShade]="false"
		[blueWhite]="true"
		[hasShadow]="false"></app-icon-button>
</div>

<div class="no-fly-time-footer" *ngIf="FORM_STATE.noFlyTimeActive && !FORM_STATE.forLater">
	<div
		cdkOverlayOrigin
		#overlayTrigger="cdkOverlayOrigin"
		class="no-fly-time-bubble"
		(mouseenter)="showNoFlyTimeTooltip()"
		(mouseleave)="closeNoFlyTimeTooltip()">
		<img class="no-fly-time-icon" [src]="'./assets/no-fly-time (2).svg' | resolveAsset" />
		<span>NO FLY TIME UNTIL {{ FORM_STATE.currentNoFlyEndTimeString }}</span>
	</div>

	<ng-template
		cdkConnectedOverlay
		[cdkConnectedOverlayOrigin]="overlayTrigger"
		[cdkConnectedOverlayOpen]="isOpenNoFlyTimeTooltip | async"
		[cdkConnectedOverlayWidth]="335"
		[cdkConnectedOverlayHeight]="noFlyTimeTooltipHeight">
		<app-no-fly-time-tooltip
			[noFlyTimes]="FORM_STATE.noFlyTimes"
			[currentNoFlyTimeIndex]="FORM_STATE.currentNoFlyTimeIndex"
			[isStaff]="isStaff"
			(mouseleave)="closeNoFlyTimeTooltip()"
			(mouseover)="showNoFlyTimeTooltip()">
		</app-no-fly-time-tooltip>
	</ng-template>
</div>
