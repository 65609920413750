import { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import { DialogFactoryService } from '../dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from '../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../dynamic-dialog.service';
import { School } from '../models/School';
import { StudentList } from '../models/StudentList';
import { User } from '../models/User';
import { HttpService } from '../services/http-service';
import { ClassSyncedFrom, SPClassUser } from '../services/classes.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'sp-class-student-list',
	templateUrl: './class-student-list.component.html',
})
export class ClassStudentListComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() students: SPClassUser[];
	@Input() accountType: ClassSyncedFrom;
	@Input() initiateAddStudent = false;
	@Input() userCanEditClass = false;
	@Input() autoSetFocusOnSearch = true;
	@Input() dialogSecondaryBtnLbl = 'Cancel';
	@Input() syncData: Observable<any>;
	@Output() closeModal = new EventEmitter<boolean>();
	@Output() updateStudents = new EventEmitter<number[]>();

	@ViewChild('addStudentsModalBody', { read: TemplateRef, static: false }) addStudentsModalBody: TemplateRef<unknown>;

	searchText = '';
	displayStudents: SPClassUser[] = [];
	scrolling = false;
	showCleverNotice = false;
	school$: Observable<School>;
	isCleverSynced: boolean;
	private studentsGroupsToAdd: (StudentList | User)[] = [];
	private dialogService: DynamicDialogService;

	constructor(private dialogFactoryService: DialogFactoryService, private http: HttpService) {}

	ngOnInit(): void {
		this.sortStudents();
		this.displayStudents = this.students;
		this.school$ = this.http.currentSchoolSubject;

		this.syncData.subscribe((data) => {
			this.isCleverSynced = !!data;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.students) {
			this.displayStudents = changes.students.currentValue;
			this.sortStudents();
		}
	}

	ngAfterViewInit(): void {
		if (this.initiateAddStudent) {
			this.openSearch();
		}
	}

	private sortStudents(): void {
		this.students = this.students.sort((a, b) => {
			const lastNameOrder = a.user.last_name.localeCompare(b.user.last_name);
			return lastNameOrder === 0 ? a.user.first_name.localeCompare(b.user.first_name) : lastNameOrder;
		});
	}

	handleRemoveStudent(studentToRemove: User): void {
		if (studentToRemove) {
			this.students = this.students.filter((s) => s.user.id != studentToRemove.id);
			this.updateStudents.emit(this.students.map((s) => s.user.id));
		}
	}

	openSearch(): void {
		const data: DynamicDialogData = {
			headerText: 'Add Students to Class',
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Save',
			secondaryButtonLabel: this.dialogSecondaryBtnLbl,
			disablePrimaryButton: true,
			modalBody: this.addStudentsModalBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			classes: 'tw-min-h-0',
		};

		this.dialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
		this.dialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					const userIds = this.students.map((s) => s.user.id);
					this.studentsGroupsToAdd.forEach((sg) => {
						if ((sg as StudentList)?.users?.length) {
							(sg as StudentList).users.forEach((u) => {
								userIds.push(u.id);
							});
						} else {
							userIds.push(sg.id);
						}
					});
					this.updateStudents.emit(userIds);
				}
			},
		});
	}

	onSearchChange(): void {
		this.displayStudents = this.students.filter((student) => student.user.display_name.toLowerCase().includes(this.searchText.toLowerCase()));
	}

	handleStudentsAdded(toAdd: (StudentList | User)[]): void {
		this.studentsGroupsToAdd = toAdd;
		this.dialogService.setDialogConfig({ disablePrimaryButton: this.studentsGroupsToAdd.length === 0 });
	}

	onScroll(event: Event): void {
		const element = event.target as HTMLElement;

		if (element.scrollTop > 0) {
			this.scrolling = true;
		} else {
			this.scrolling = false;
		}
	}
}
