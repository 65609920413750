import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import _refiner from 'refiner-js';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

import { DialogFactoryService } from 'app/dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from 'app/dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from 'app/dynamic-dialog.service';
import { TermData } from 'app/models/Schedule';
import { ScheduleService } from 'app/services/schedule.service';
import { NextStep } from '../animations';
import { CreateFormService } from '../create-hallpass-forms/create-form.service';
import { DropdownComponent, Option } from '../dropdown/dropdown.component';
import { FrequencyType, HallPassLimit, IndividualPassLimit, IndividualPassLimitCollection } from '../models/HallPassLimits';
import { User } from '../models/User';
import { IntroData } from '../ngrx/intros';
import { PassLimitInputComponent } from '../pass-limit-input/pass-limit-input.component';
import { PassLimitService } from '../services/pass-limit.service';
import { ScreenService } from '../services/screen.service';
import { UserService } from '../services/user.service';
import { SPSearchComponent } from '../sp-search/sp-search.component';

declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

// Ensures the school pass limit is between 0 and 50 inclusive
const schoolPassLimitRangeValidator =
	(): ValidatorFn =>
	(form: AbstractControl): ValidationErrors | null => {
		if (!form.value['limitEnabled']) {
			return null;
		}
		const num = parseInt(form.value['passLimit'], 10);
		if (Number.isNaN(num) || form.value['passLimit'] === '') {
			return { format: true };
		}
		if (num < 0 || num > 50) {
			return { range: true };
		}
		return null;
	};

// Ensures the school pass limit form requests hte pass limit input if the limit is enabled
const limitsRequiredValidator =
	(): ValidatorFn =>
	(form: AbstractControl): ValidationErrors | null => {
		if (!(form instanceof FormGroup)) {
			return null;
		}
		const v = form.value;
		const plValidators = v.limitEnabled
			? [Validators.required, Validators.pattern(/^([1-9]\d*)$|^(0){1}$/)]
			: [Validators.pattern(/^([1-9]\d*)$|^(0){1}$/)];

		form.controls['passLimit'].setValidators(plValidators);

		if (!v.limitEnabled) {
			return null;
		}

		return v === undefined || v === null ? { limitEnabled: true } : null;
	};

// Ensures the school pass limit is between -2 and 50 inclusive
// -2 means Unlimited passes, -1 means no individual limit set
const individualPassLimitRangeValidator =
	(): ValidatorFn =>
	(form: AbstractControl): ValidationErrors | null => {
		if (form.value['passLimit'] === 'Unlimited') {
			return null;
		}
		const num = parseInt(form.value['passLimit'], 10);
		if (Number.isNaN(num)) {
			return { format: true };
		}
		if (num < -2 || num > 50) {
			return { range: true };
		}
		return null;
	};

/**
 * TODOS for pass limits v2
 * TODO: Add fade animation to angular material tabs
 */

@Component({
	selector: 'app-admin-pass-limits-dialog',
	templateUrl: './admin-pass-limits-dialog.component.html',
	styleUrls: ['./admin-pass-limits-dialog.component.scss'],
	animations: [NextStep],
})
export class AdminPassLimitDialogComponent implements OnInit, OnDestroy {
	requestLoading = false;
	contentLoading = true;
	deleteLoading = false;
	hasPassLimit = false;
	passLimit?: HallPassLimit;
	frequency: FrequencyType = 'day';
	frequencyText = 'Per Day';
	newFrequencyText = '';
	individualStudentLimits: IndividualPassLimit[] = [];

	// school pass limit form props
	passLimitForm = new FormGroup(
		{
			limitEnabled: new FormControl(false),
			passLimit: new FormControl(null, Validators.pattern(/^([1-9]\d*)$|^(0){1}$/)),
			frequency: new FormControl(null, Validators.required),
		},
		[schoolPassLimitRangeValidator(), limitsRequiredValidator()]
	);
	passLimitFormChanged: Observable<boolean> = of(false);
	passLimitFormLastValue!: { limitEnabled: boolean; passLimit: string; frequency: FrequencyType };
	schoolPassLimitInput!: PassLimitInputComponent;

	// individual form props
	individualOverrideForm = new FormGroup(
		{
			students: new FormArray([], Validators.required),
			passLimit: new FormControl(null, [Validators.required, Validators.pattern(/^([1-9]\d*)$|^(0){1}$|^(Unlimited)$/)]),
			description: new FormControl(null),
		},
		individualPassLimitRangeValidator()
	);
	individualFormPreviousValue: { students: string[]; passLimit: string; description: string } | undefined;
	individualFormChanged!: Observable<boolean>;
	individualLoading!: boolean;
	selectedExistingIndividualLimit: IndividualPassLimit | undefined;
	individualPassLimitInput!: PassLimitInputComponent;

	// nux props
	showPassLimitNux = false;
	introsData!: IntroData;
	introSubs!: Subscription;
	private dialogService!: DynamicDialogService;
	private deleteDialogService!: DynamicDialogService;

	// Framer motion controls
	page = 1;
	frameMotion$ = this.formService.getFrameMotionDirection();

	@ViewChild('studentSearch') studentSearcher!: SPSearchComponent;
	@ViewChild('deleteDialogBody') deleteDialogBody!: TemplateRef<HTMLElement>;
	@ViewChild('timeFrameConfirmDialogBody') timeFrameConfirmBody!: TemplateRef<HTMLElement>;
	@ViewChild('schoolPassLimitInput') set setSchoolLimitInput(comp: PassLimitInputComponent) {
		if (!comp) {
			return;
		}

		this.schoolPassLimitInput = comp;
	}
	@ViewChild('individualPassLimitInput') set individualInput(comp: PassLimitInputComponent) {
		if (!comp) {
			return;
		}

		this.individualPassLimitInput = comp;
	}

	constructor(
		private dialog: MatDialog,
		public dialogRef: MatDialogRef<AdminPassLimitDialogComponent>,
		public screenService: ScreenService,
		private passLimitService: PassLimitService,
		private userService: UserService,
		public formService: CreateFormService,
		public scheduleService: ScheduleService,
		public dialogFactoryService: DialogFactoryService
	) {}

	ngOnInit(): void {
		/**
		 * Fetch the pass limit and individual limits for the school
		 * If fetching the school-wide pass limit returns null (??), then the school has
		 * no pass limits.
		 * Enabling the pass limit slider creates the pass limit and the user has the option to specify the number
		 * of passes per day that is allowed
		 *
		 * If the pass limit does not exist, then the slider creates the limit when enabled
		 * If a pass limit already exists, then the slider enables/disables the limit
		 */
		this.passLimitForm.disable();
		forkJoin({
			pl: this.passLimitService.getPassLimit(),
			overrides: this.passLimitService.getIndividualLimits(),
		})
			.pipe(
				concatMap(({ pl, overrides }) => {
					this.hasPassLimit = !!pl.pass_limit;
					if (this.hasPassLimit) {
						this.passLimit = pl.pass_limit;
						this.frequency = this.passLimit.frequency;
						this.frequencyText = 'Per ' + this.passLimit.frequency.charAt(0).toUpperCase() + this.passLimit.frequency.slice(1);
					} else {
						this.passLimit = {
							frequency: 'day',
							limitEnabled: false,
							passLimit: 10,
						} as HallPassLimit;
					}
					this.passLimitForm.patchValue(this.passLimit);
					if (overrides.length) {
						this.individualStudentLimits = overrides;
					}
					return of(true);
				}),
				tap(() => {
					this.passLimitFormLastValue = this.passLimitForm.value;
					console.log('last value: ', this.passLimitFormLastValue);
					this.passLimitFormChanged = this.passLimitForm.valueChanges.pipe(
						map((v) => {
							if (v?.passLimit) {
								v.passLimit = parseInt(v.passLimit, 10);
							}
							return JSON.stringify(v) !== JSON.stringify(this.passLimitFormLastValue);
						})
					);
					this.passLimitForm.enable();
					this.contentLoading = false;
				})
			)
			.subscribe();

		this.introSubs = this.userService.introsData$.subscribe((intros) => {
			this.introsData = intros;
			this.showPassLimitNux = !intros?.admin_pass_limit_message?.universal?.seen_version;
		});

		this.loadIndividualForm();
	}

	resetPassLimitsForm() {
		this.frequency = this.passLimitFormLastValue.frequency;
		this.newFrequencyText = 'Per ' + this.frequency?.charAt(0).toUpperCase() + this.frequency?.slice(1);
		this.frequencyText = this.newFrequencyText;
		this.passLimitForm.patchValue(this.passLimitFormLastValue);
		if (this.schoolPassLimitInput?.passLimitDropdownRef?.getState() === MatDialogState.OPEN) {
			this.schoolPassLimitInput.passLimitDropdownRef.close();
		}
	}

	updatePassLimits() {
		this.requestLoading = true;
		const passLimit = parseInt(this.passLimitForm.value['passLimit'], 10);
		const newValue: HallPassLimit = {
			...this.passLimit,
			...this.passLimitForm.value,
			passLimit,
		};
		const request = this.hasPassLimit ? this.passLimitService.updatePassLimits(newValue) : this.passLimitService.createPassLimit(newValue);

		request.subscribe({
			next: () => {
				this.hasPassLimit = true;
				this.requestLoading = false;
				this.passLimitFormLastValue = cloneDeep(this.passLimitForm.value);
				this.passLimitFormChanged = this.passLimitForm.valueChanges.pipe(
					map((v) => {
						if (v?.passLimit) {
							v.passLimit = parseInt(v.passLimit, 10);
						}
						return JSON.stringify(v) !== JSON.stringify(this.passLimitFormLastValue);
					})
				);
			},
			error: () => {
				this.requestLoading = false;
			},
		});
	}

	dismissPassLimitNux() {
		this.userService.updateIntrosAdminPassLimitsMessageRequest(this.introsData, 'universal', '1');
		this.showPassLimitNux = false;
	}

	goToIndividualLimitPage(limit?: IndividualPassLimit) {
		this.formService.setFrameMotionDirection();
		setTimeout(() => {
			this.loadIndividualForm(limit);
			this.page = 2;
		}, 100);
	}

	goToHomePage() {
		this.formService.setFrameMotionDirection('back');
		setTimeout(() => {
			this.destroyIndividualForm();
			this.page = 1;
		}, 100);
	}

	openDropdown(target: MouseEvent, termData: TermData): void {
		const options: Option[] = [
			{ display: 'Per Day', value: 'day' },
			{ display: 'Per Week', value: 'week' },
			{ display: 'Per Term', value: 'term' },
		];

		const filterDialog = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: target.currentTarget,
				plFrequencies: options,
				currentTerm: termData.currentTerm,
				upcomingTerm: termData.upcomingTerm,
				termsSetup: termData.termsSetup,
				termsOverlap: termData.termsOverlap,
				selectedFrequency: this.frequency,
				maxHeight: '258px',
				width: '351px',
				optionPadding: '0px 20px',
				optionMargin: '5px 0px',
				positionY: 7.5,
			},
		});

		filterDialog.afterClosed().subscribe((frequency) => {
			if (frequency && this.frequency !== frequency) {
				this.newFrequencyText = 'Per ' + frequency.charAt(0).toUpperCase() + frequency.slice(1);
				const data: DynamicDialogData = {
					headerText: `Are you sure you want to change the timeframe?`,
					displayModalFooter: true,
					showCloseIcon: true,
					primaryButtonLabel: 'Yes, Confirm',
					secondaryButtonLabel: 'Cancel',
					modalBody: this.timeFrameConfirmBody,
					secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
					secondaryButtonTextColor: '#7083A0',
					primaryButtonGradientBackground: '#00B476',
					primaryButtonTextColor: 'white',
					classes: 'tw-min-h-0',
				};

				this.dialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
				this.dialogService.closed$.subscribe({
					next: (selectedOption: DynamicDialogAction) => {
						if (selectedOption === 'primary') {
							if (this.frequency === 'week' && frequency === 'day') {
								_refiner('showForm', 'b5cecfc0-8836-11ef-be98-7f1557365423');
							}
							this.frequency = frequency;
							this.frequencyText = this.newFrequencyText;
							this.passLimitForm.patchValue({
								frequency: frequency,
								passLimit: this.getDefaultPassLimit(frequency),
							});
						}
					},
				});
			}
		});
	}

	private getDefaultPassLimit(frequency: FrequencyType): number | null {
		switch (frequency) {
			case 'day':
				return 5;
			case 'week':
				return 10;
			case 'term':
				return null;
		}
	}

	// loads individual form with properly parsed values
	// limit param is only truthy when loading an already existing limit
	// most likely, the user wants to edit an individual limit if this is truthy
	private loadIndividualForm(limit?: IndividualPassLimit) {
		this.selectedExistingIndividualLimit = limit || this.selectedExistingIndividualLimit;
		const controls: FormControl[] = [];
		if (limit) {
			controls.push(new FormControl(limit.student.id, Validators.required));
		}

		let passLimitValue = limit?.passLimit?.toString();
		if (passLimitValue === '-2') {
			passLimitValue = 'Unlimited';
		}

		this.individualOverrideForm.removeControl('students');
		this.individualOverrideForm.addControl('students', new FormArray(controls));
		this.individualOverrideForm.patchValue({
			passLimit: passLimitValue,
			description: limit?.description || '',
		});
		this.individualFormPreviousValue = this.individualOverrideForm.value;
		this.individualFormChanged = this.individualOverrideForm.valueChanges.pipe(
			map((v) => {
				const { students, passLimit, description } = v;
				const str1 = JSON.stringify(students) + JSON.stringify(passLimit) + JSON.stringify(description);
				const str2 =
					JSON.stringify(this.individualFormPreviousValue?.students) +
					JSON.stringify(this.individualFormPreviousValue?.passLimit) +
					JSON.stringify(this.individualFormPreviousValue?.description);
				return str1 !== str2;
			})
		);
	}

	private destroyIndividualForm() {
		this.individualFormPreviousValue = undefined;
		this.resetIndividualForm();
		this.individualFormChanged = of(false);
		this.selectedExistingIndividualLimit = undefined;
	}

	resetIndividualForm() {
		if (this.studentSearcher) {
			this.studentSearcher.reset();
			this.individualOverrideForm.removeControl('students');
			this.individualOverrideForm.addControl('students', new FormArray([]));
		}
		this.individualOverrideForm.patchValue(
			{
				students: [],
				passLimit: undefined,
				description: '',
			},
			{ emitEvent: true }
		);
		if (this.individualPassLimitInput?.passLimitDropdownRef?.getState() === MatDialogState.OPEN) {
			this.individualPassLimitInput.passLimitDropdownRef.close();
		}
	}

	updateStudentList(selectedUsers: User[]) {
		if (selectedUsers === undefined) {
			this.individualOverrideForm.markAsPristine();
			return;
		}
		this.individualOverrideForm.removeControl('students');
		const controls = selectedUsers.map((u) => new FormControl(u.id));
		this.individualOverrideForm.addControl('students', new FormArray(controls));
		this.individualOverrideForm.markAsDirty();
	}

	submitIndividualLimits() {
		const parsedForm: IndividualPassLimitCollection = {
			students: this.individualOverrideForm.value.students,
			passLimit: this.individualOverrideForm.value.passLimit === 'Unlimited' ? -2 : parseInt(this.individualOverrideForm.value.passLimit, 10),
			description: (this.individualOverrideForm?.value?.description || '').trim(),
		};

		if (parsedForm.students.length === 0) {
			throw new Error('Invalid form: must have at least one student and a properly formatted pass limit string');
		}

		this.individualLoading = true;
		const request = this.selectedExistingIndividualLimit
			? this.passLimitService.updateIndividualLimit({
					...parsedForm,
					description: parsedForm.description || this.selectedExistingIndividualLimit.description,
			  })
			: this.passLimitService.createIndividualLimits(parsedForm);

		request.pipe(concatMap(() => this.passLimitService.getIndividualLimits())).subscribe({
			next: (value) => {
				this.individualStudentLimits = value;
				this.individualLoading = false;
				this.goToHomePage();
			},
		});
	}

	openDeleteDialog() {
		const id = this.individualOverrideForm.value.students[0];
		const data: DynamicDialogData = {
			headerText: 'Remove the individual limit?',
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Remove limit',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.deleteDialogBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66',
			primaryButtonTextColor: 'white',
			classes: 'tw-min-h-0',
			templateData: {},
		};

		this.deleteDialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
		this.deleteDialogService.closed$
			.pipe(
				filter((action) => action === 'primary'),
				tap(() => (this.deleteLoading = true)),
				concatMap(() => this.passLimitService.removeIndividualLimit(id)),
				concatMap(() => this.passLimitService.getIndividualLimits())
			)
			.subscribe({
				next: (individualLimits) => {
					this.individualStudentLimits = individualLimits;
					this.deleteLoading = false;
					this.goToHomePage();
				},
				error: console.error,
			});
	}

	openIntercom() {
		window.Intercom('showNewMessage');
	}

	ngOnDestroy() {
		if (this.introSubs) {
			this.introSubs.unsubscribe();
		}
	}
}
