import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { School } from '../models';
import { HttpService } from './http-service';

/**
 * Add any future entitlements to this enum
 */
export enum ENTITLEMENTS {
	HallPassStandard = 'hall_pass_standard',
	HallPassLite = 'hall_pass_lite',
	HallPassPro = 'hall_pass_pro',
	Flex = 'flex',
	IdCardPro = 'id_card_pro',
	Attendance = 'attendance',
	DataConcierge = 'data_concierge',
}
type EntitlementInfo = { schoolKey: keyof School; enabledValue: string };

@Injectable({
	providedIn: 'root',
})
export class EntitlementsService {
	constructor(private http: HttpService) {}

	isEntitlementEnabled$(entitlement: ENTITLEMENTS): Observable<boolean> {
		const { schoolKey, enabledValue } = this.getEntitlementInfo(entitlement);

		return this.http.currentSchool$.pipe(
			map((rawSchool) => {
				const school = School.fromJSON(rawSchool);
				return school != null && school[schoolKey] === enabledValue;
			})
		);
	}

	isEntitlementEnabled(entitlement: ENTITLEMENTS): boolean {
		const { schoolKey, enabledValue } = this.getEntitlementInfo(entitlement);
		const school = School.fromJSON(this.http.currentSchoolSubject.getValue());
		if (school) {
			return school[schoolKey] === enabledValue;
		}
		return false;
	}

	private getEntitlementInfo(entitlement: ENTITLEMENTS): EntitlementInfo {
		switch (entitlement) {
			case ENTITLEMENTS.HallPassStandard:
				return {
					schoolKey: 'hall_pass_access',
					enabledValue: 'hall_pass_standard',
				};
			case ENTITLEMENTS.HallPassLite:
				return {
					schoolKey: 'hall_pass_access',
					enabledValue: 'hall_pass_lite',
				};
			case ENTITLEMENTS.HallPassPro:
				return {
					schoolKey: 'hall_pass_access',
					enabledValue: 'hall_pass_pro',
				};
			case ENTITLEMENTS.Flex:
				return {
					schoolKey: 'flex_access',
					enabledValue: 'yes',
				};
			case ENTITLEMENTS.Attendance:
				return {
					schoolKey: 'attendance_access',
					enabledValue: 'yes',
				};
			case ENTITLEMENTS.IdCardPro:
				return {
					schoolKey: 'id_card_access',
					enabledValue: 'pro',
				};
			case ENTITLEMENTS.DataConcierge:
				return {
					schoolKey: 'dc_access',
					enabledValue: 'yes',
				};
		}
	}
}
