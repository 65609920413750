<div
	class="wrapper"
	#cell
	appCrossPointerEventTarget
	(pointerClickEvent)="cellSelected(); screen.enabledLocationTableDnD.next(false)"
	(pointerDownEvent)="onPress(true)"
	(pointerCancelEvent)="screen.enabledLocationTableDnD.next(false)"
	(mouseenter)="tooltipDelay(true, 300)"
	(mouseleave)="tooltipDelay(false)"
	[style.color]="textColor"
	[style.cursor]="cursor"
	[style.border-radius]="borderRadius"
	cdkDrag
	[cdkDragDisabled]="!isFavorite">
	<div class="info" customToolTip [nonDisappearing]="false" [contentTemplate]="tooltip" [showToolTip]="showTooltip || isSameRoom">
		<img
			*ngIf="showStar"
			(mouseenter)="overStar = true"
			(mouseleave)="overStar = false"
			class="star"
			[src]="'./assets/Star' + (this.value.starred ? ' (Yellow)' : ' (Blue-Gray)') + '.svg' | resolveAsset" />

		<div *ngIf="!showStar && showPicture" class="profile-circle">
			<div class="picture" [style.background]="getPinnableGradient()">
				<img draggable="false" [style.width]="'18px'" [style.height]="'18px'" [src]="pinnableIcon | resolveAsset" alt="ICON" />
			</div>
		</div>

		<div class="title">
			{{ value.title }}
			<span class="disabled-block" *ngIf="disabledRoom && (currentPage === 'to' || currentPage === 'from')">Closed</span>
		</div>
		<div class="room" [style.color]="roomColor">
			<img
				style="z-index: 1000"
				*ngIf="isFavorite"
				cdkDragHandle
				appCrossPointerEventTarget
				(pointerClickEvent)="$event.stopPropagation()"
				style="margin-left: 5px"
				width="14"
				height="14"
				[src]="'./assets/Burger (Blue-Gray).svg'"
				alt="" />
			<div class="circle" *ngIf="show_max_passes" [style.background]="gradient" (mouseenter)="tooltipDelay(true, 300)">
				{{ currentPage === 'from' ? passLimit.from_count : passLimit.to_count }}
			</div>
			<div notranslate>{{ value.room }}</div>
			<img *ngIf="showLock && hasLocks" class="lock" [src]="'./assets/Locked (Blue-Gray).svg' | resolveAsset" />
		</div>
	</div>

	<ng-template #tooltip>
		<app-pass-limit-tooltip
			[isHand]="isSameRoom ? false : value.enable"
			*ngIf="showTooltipWithDelay"
			[description]="
				!isSameRoom
					? tooltipDescription
					: currentPage === 'to'
					? 'You selected ' + value.title + ' as the origin (where you are now)!'
					: 'You selected ' + value.title + ' as the destination (where you are going to)!'
			"></app-pass-limit-tooltip>
	</ng-template>
	<div *ngIf="showDivider" class="divider"></div>
</div>
