import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	SecurityContext,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToolTipDataType } from 'app/backpack/tooltip/tooltip.component';
import * as moment from 'moment';
import { Term } from '../../models/Schedule';
import { TooltipDateInfo } from '../../services/calendar.service';
import { TimeService } from '../../services/time.service';

type calendarToggle = 'start' | 'end';
type ScheduleFormValues = { term_name?: string; start_date?: string; end_date?: string };

@Component({
	selector: 'sp-school-term',
	templateUrl: './school-term.component.html',
	styleUrls: ['./school-term.component.scss'],
})
export class SchoolTermComponent implements OnInit {
	@Input() termForm: FormGroup;
	@Input() label: string;
	@Input() placeholder = 'Add school year name';
	@Input() termNameErrorMessage = 'Please enter a name for this school year.';
	@Input() isFocused = false;
	@Input() minDate: moment.Moment; // school year start date
	@Input() maxDate: moment.Moment; // school year end date
	@Input() schoolYearName: string;
	@Input() termsWithClassesBeingDeleted: Term[] = [];
	@Input() tooltipDates: TooltipDateInfo[];
	@Input() allowEndDateInPast = true; // currently, school year end date can't be in the past, but subterm end dates can
	@Input() allowStartDateInPast = false; // currently, school year start date can't be in the past, but subterm end dates can
	@Output() hasErrorState: EventEmitter<boolean> = new EventEmitter();
	@ViewChild('errorMessageTooltipContent', { read: TemplateRef, static: false }) errorMessageTooltipContent: TemplateRef<any>;

	calendarFor: calendarToggle = 'start';
	openCalendar = false;
	selectedDate: moment.Moment = moment(this.timeService.nowDate());
	outsideRangeTooltip: ToolTipDataType;
	exclusionId: string;
	disableStartDate: boolean;
	dateBeingChanged: moment.Moment;

	@ViewChild('calendar', { static: false, read: ElementRef }) calendar: ElementRef;
	@HostListener('document:mousedown', ['$event'])
	handleClick(event: Event) {
		this.onDocumentClick(event);
	}

	constructor(private timeService: TimeService, private domSanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.termForm.setValidators(this.validateTermForm);
		this.termForm.get('term_name')?.setValidators([Validators.required, Validators.pattern(/^\s*\S.*\S\s*$|^\s*\S\s*$/)]);
		this.exclusionId = this.termForm?.get('exclusion_id')?.value;
		if (this.schoolYearName && this.minDate && this.maxDate) {
			this.outsideRangeTooltip = this.getOutsideSchoolYearTooltipContent(
				this.schoolYearName,
				this.minDate.format('M/D/YYYY'),
				this.maxDate.format('M/D/YYYY')
			);
		}

		if (!this.allowStartDateInPast && this.termForm.get('start_date')?.value) {
			const startDate = moment.utc(this.termForm.get('start_date')?.value);
			if (startDate.isBefore(moment().utc())) {
				this.disableStartDate = true;
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.termForm.updateValueAndValidity();
	}

	private validateTermForm(control: { value: ScheduleFormValues }): ValidatorFn {
		return (): ValidationErrors | null => {
			const { term_name, start_date, end_date } = control.value || {};
			if (this.termsWithClassesBeingDeleted?.length && this.calendarFor === 'start') {
				this.dateBeingChanged = moment.utc(start_date);
				this.hasErrorState.emit(true);
				return { invalidStartDate: 'Set a different date.' };
			}
			if (this.termsWithClassesBeingDeleted?.length && this.calendarFor === 'end') {
				this.dateBeingChanged = moment.utc(end_date);
				this.hasErrorState.emit(true);
				return { invalidEndDate: 'Set a different date.' };
			}
			if (moment(start_date).isAfter(moment(end_date)) && this.calendarFor === 'start') {
				this.hasErrorState.emit(true);
				return { invalidStartDate: 'Set a date before the end date.' };
			}
			if (moment(end_date).isBefore(moment(start_date)) && this.calendarFor === 'end') {
				this.hasErrorState.emit(true);
				return { invalidEndDate: 'Set a date after the start date.' };
			}
			// throw an error if the there are disabled tooltip dates between the start and end date for other terms
			if (this.tooltipDates) {
				const filtered = this.tooltipDates.filter((date) => {
					return (
						date.disabled &&
						date.exclusionId !== this.exclusionId &&
						moment.utc(date.date).isBetween(moment.utc(start_date), moment.utc(end_date), 'day', '()')
					);
				});

				if (filtered.length > 0) {
					this.hasErrorState.emit(true);
					return { invalidDateRange: 'Set range with no term overlap.' };
				}
			}

			if (!this.allowEndDateInPast && moment(end_date).isBefore(moment()) && this.calendarFor === 'end') {
				this.hasErrorState.emit(true);
				return { invalidEndDate: 'Set an end date in the future.' };
			}
			if (!term_name?.trim().length) {
				this.hasErrorState.emit(true);
				return { invalidTermName: this.termNameErrorMessage };
			}
			this.hasErrorState.emit(false);
			return null;
		};
	}

	toggleCalendar(term: calendarToggle = 'start'): void {
		if (!this.openCalendar) {
			if (term == 'start') {
				// this is the start date
				if (this.termForm.get('start_date')?.value) {
					this.selectedDate = moment(this.termForm.get('start_date')?.value);
				}
			} else {
				// this is the end date
				if (this.termForm.get('end_date')?.value) {
					this.selectedDate = moment(this.termForm.get('end_date')?.value);
				}
			}
		}
		this.calendarFor = term;
		this.openCalendar = !this.openCalendar;
	}

	private onDocumentClick(event: Event): void {
		if (this.openCalendar && !this.calendar?.nativeElement?.contains(event.target)) {
			this.toggleCalendar();
		}
	}

	calendarResult(selectedDate: moment.Moment[]): void {
		const newDate: moment.Moment = selectedDate[0];
		if (this.calendarFor == 'start') {
			this.termForm.get('start_date')?.patchValue(newDate.format('YYYY-MM-DD'));
		} else {
			this.termForm.get('end_date')?.patchValue(newDate.format('YYYY-MM-DD'));
		}
		this.openCalendar = false;
	}
	private getOutsideSchoolYearTooltipContent(schoolYearName: string, startDate: string, endDate: string): ToolTipDataType {
		const sanitizedSchoolYearName = this.domSanitizer.sanitize(SecurityContext.HTML, schoolYearName);
		const sanitizedStartDate = this.domSanitizer.sanitize(SecurityContext.HTML, startDate);
		const sanitizedEndDate = this.domSanitizer.sanitize(SecurityContext.HTML, endDate);
		return {
			text: '',
			link: '',
			linkText: '',
			dataDetails: this.domSanitizer.bypassSecurityTrustHtml(
				`<div>This day falls outside of the <span class="tw-font-bold">${sanitizedSchoolYearName}</span>.</div><div class="tw-pt-3 tw-text-white tw-text-opacity-50">${sanitizedStartDate} - ${sanitizedEndDate}</div>`
			),
		};
	}
}
