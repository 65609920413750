import { Component } from '@angular/core';
import * as moment from 'moment/moment';
import { TimeRange } from '../new-time-range-picker/new-time-range-picker.component';
import { AttendanceRecordService } from '../../../services/attendance-record-service';
import { UserService } from '../../../services/user.service';
import { forkJoin } from 'rxjs';
import { ToastService } from '../../../services/toast.service';
import { take } from 'rxjs/operators';
import { getStartOfSchoolYearAsMoment } from '../../../helpers/helper';

@Component({
	selector: 'sp-attendance-export',
	templateUrl: './attendance-export.component.html',
	styleUrls: ['./attendance-export.component.scss'],
})
export class AttendanceExportComponent {
	constructor(private attendanceRecordService: AttendanceRecordService, private userService: UserService, private toastService: ToastService) {}

	selectedTimeRange: TimeRange = {
		start: getStartOfSchoolYearAsMoment(),
		end: moment(),
	};

	exportAttendanceRecords() {
		forkJoin([
			this.attendanceRecordService
				.downloadRecords({
					started_at: this.selectedTimeRange.start.toISOString(true),
					stopped_at: this.selectedTimeRange.end.endOf('day').toISOString(true),
				})
				.pipe(take(1)),
			this.userService.userJSON$.pipe(take(1)),
		]).subscribe(([, user]) => {
			this.toastService.openToast({
				title: `Attendance data exporting...`,
				subtitle: `In a few minutes, check your email (${user.primary_email}) for a link to download the CSV file.`,
				type: 'success',
				showButton: false,
			});
		});
	}
}
