export function downloadBlob(blob: Blob, filename: string) {
	// Create a URL for the blob
	const url = window.URL.createObjectURL(blob);

	// Create a temporary link element
	const a = document.createElement('a');
	a.href = url;
	a.download = filename;

	// Append link to the body, trigger click, and remove it
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);

	// Release the URL
	window.URL.revokeObjectURL(url);
}
