import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'sp-circle-countdown',
	templateUrl: './circle-countdown.component.html',
	styleUrls: ['./circle-countdown.component.scss'],
})

/*
CircleCountdown is a circular countdown.
 */
export class CircleCountdown implements OnInit, OnChanges {
	// Text to show at the top of the timer
	@Input() topText?: string;
	// A number from [0, 1]. 1 is fully filled. 0 is empty.
	@Input() progress = 0.5;

	// Colors
	@Input() ringColor = 'rgba(255, 255, 255, 0.40)';
	@Input() elapsedRingColor = 'white';

	@Input() diameter = 250;
	@Input() wideCircleStroke = false;

	strokeDashNum: number;

	// Don't change this value. 283 = 2 * pi * 45;
	strokeDashDenom = 283;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.progress) {
			this.setupCircle(this.progress);
		}
	}

	ngOnInit(): void {
		this.setupCircle(this.progress);
	}

	private setupCircle(progress: number) {
		this.strokeDashNum = this.strokeDashDenom * progress;
	}
}
