export const hexToRgba = (hex: string, alpha: number) => {
	// Remove the '#' if present
	hex = hex.replace('#', '');

	// Parse the hexadecimal values for red, green, and blue
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Return the RGBA string
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const rgbToHSL = (r, g, b) => {
	r /= 255;
	g /= 255;
	b /= 255;
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h,
		s = (max + min) / 2;
	const l = (max + min) / 2;

	if (max === min) {
		h = s = 0; // achromatic
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}
	return { hue: h * 360, saturation: s * 100, lightness: l * 100 };
};

export const hslToRGB = (h, s, l) => {
	h /= 360;
	s /= 100;
	l /= 100;
	let r, g, b;

	if (s === 0) {
		r = g = b = l; // achromatic
	} else {
		const hue2rgb = (p, q, t) => {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
			return p;
		};
		const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		const p = 2 * l - q;
		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);
	}

	return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
};

export const hexToRGBAWithAdjustments = (
	hex: string,
	hueIncrement: number,
	saturationDecrement: number,
	lightnessIncrement: number,
	alpha: number
) => {
	// Convert hex to RGB
	const r = parseInt(hex.substring(1, 3), 16);
	const g = parseInt(hex.substring(3, 5), 16);
	const b = parseInt(hex.substring(5, 7), 16);
	// Convert RGB to HSL
	const hsl = rgbToHSL(r, g, b);

	// Adjust HSL values
	const adjustedHSL = {
		hue: (hsl.hue + hueIncrement) % 360,
		saturation: Math.max(0, hsl.saturation - saturationDecrement),
		lightness: Math.min(100, hsl.lightness + lightnessIncrement),
	};

	// Convert adjusted HSL back to RGB
	const { r: adjustedR, g: adjustedG, b: adjustedB } = hslToRGB(adjustedHSL.hue, adjustedHSL.saturation, adjustedHSL.lightness);

	// Clamp RGB values to ensure they stay within the valid range of 0 to 255
	const clampedR = Math.max(0, Math.min(255, adjustedR - 10));
	const clampedG = Math.max(0, Math.min(255, adjustedG - 10));
	const clampedB = Math.max(0, Math.min(255, adjustedB - 10));

	// Return the RGBA color string with adjusted and clamped RGB values and alpha
	return `rgba(${clampedR}, ${clampedG}, ${clampedB}, ${alpha})`;
};
