import * as React from 'react';

interface IActivityBoxProps {
	name: string;
	teacher: string;
	numStudents: number;
	maxCapacity?: number;
	onClick: () => void;
}

export function ActivityBox({ name, teacher, numStudents, maxCapacity, onClick }: IActivityBoxProps): React.ReactElement {
	const studentMessage = () => {
		if (maxCapacity) {
			return `${numStudents}/${maxCapacity} ${numStudents === 1 ? 'student' : 'students'}`;
		}
		return `${numStudents} ${numStudents === 1 ? 'student' : 'students'}`;
	};

	const overflowText = 'tw-text-center tw-w-48 tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden';

	return (
		<div
			onClick={onClick}
			className={`
				tw-bg-gray-100
				tw-text-gray-500
				tw-rounded-lg 
				tw-flex 
				tw-flex-col
				tw-justify-center
				tw-gap-y-2 
				tw-text-xs
				tw-font-normal
				tw-items-center 
				tw-w-50 
				tw-h-27
				tw-cursor-pointer
				tw-animate-fadein-700
			`}>
			<div className={`tw-text-gray-700 tw-font-bold tw-text-sm ${overflowText}`}>{name}</div>
			<div className={overflowText}>Created by {teacher}</div>
			<div className={overflowText}>{studentMessage()}</div>
		</div>
	);
}
