import * as React from 'react';
import { HeaderStat } from './header-stat';

interface IHeaderStatsProps {
	isLoading: boolean;
	inPast: boolean;
	numStudents: number;
	numAttending: number;
	schoolId: number;
	flexPeriodId: number;
	startTime: Date;
	fileName: string;
}

export function HeaderStats({
	inPast,
	isLoading,
	schoolId,
	numStudents,
	numAttending,
	flexPeriodId,
	startTime,
	fileName,
}: IHeaderStatsProps): React.ReactElement {
	return (
		<div className="tw-flex tw-h-20 tw-mt-4">
			<HeaderStat
				active={!inPast}
				attending={true}
				num={numAttending}
				schoolId={schoolId}
				flexPeriodId={flexPeriodId}
				date={startTime}
				isLoading={isLoading}
				fileName={fileName}
			/>
			<HeaderStat
				active={!inPast}
				attending={false}
				num={numStudents - numAttending}
				schoolId={schoolId}
				flexPeriodId={flexPeriodId}
				date={startTime}
				isLoading={isLoading}
				fileName={fileName}
			/>
		</div>
	);
}
