import { Component, Input, OnInit } from '@angular/core';
import { NoFlyTime } from '../../../models/NoFlyTime';
import { NoFlyTimeService } from '../../../services/no-fly-time.service';

@Component({
	selector: 'app-no-fly-time-tooltip',
	templateUrl: './no-fly-time-tooltip.component.html',
	styleUrls: ['./no-fly-time-tooltip.component.scss'],
})
export class NoFlyTimeTooltipComponent implements OnInit {
	@Input() noFlyTimes: NoFlyTime[] = [];
	@Input() currentNoFlyTimeIndex: number;
	@Input() tooltipType = 'staff';
	@Input() close: () => void;
	@Input() isStaff: boolean;

	@Input() background = '#101418';
	@Input() width = 'auto';
	@Input() height = 'auto';
	@Input() borderRadius = '10px';
	@Input() padding = '16px 12px 16px 12px';

	nftTextList: string[] = [];
	currentEndTimeString = '';

	constructor(public nftService: NoFlyTimeService) {}

	ngOnInit() {
		this.populateNftTextList();
	}

	populateNftTextList() {
		this.nftTextList = [];
		for (let i = 0; i < this.noFlyTimes.length; i++) {
			const nft = this.noFlyTimes[i];
			const startTimeStr = this.nftService.momentize(nft.start_time);
			const endTimeStr = this.nftService.momentize(nft.end_time);

			const formattedStartTime = this.nftService.formatTimeString(startTimeStr);
			const formattedEndTime = this.nftService.formatTimeString(endTimeStr);

			const formattedListTime = `${formattedStartTime} - ${formattedEndTime}`;

			this.nftTextList.push(formattedListTime);
			if (i === this.currentNoFlyTimeIndex) {
				this.currentEndTimeString = formattedEndTime;
			}
		}
	}
}
