import * as React from 'react';

interface IOptionsMenuProps {
	className?: string;
	children: React.ReactNode;
	options: MenuOption[];
}

export interface MenuOption {
	name: string;
	action: () => void;
	icon?: string;
}

export function OptionsMenu({ children, options, className = '' }: IOptionsMenuProps): React.ReactElement {
	const [isOpen, setIsOpen] = React.useState(false);
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const menuRef = React.useRef<HTMLDivElement>(null);

	const togglePopup = () => setIsOpen(!isOpen);

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			setIsOpen(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="tw-relative" ref={wrapperRef}>
			<div onClick={togglePopup}>{children}</div>
			{isOpen && (
				<div
					ref={menuRef}
					className={`
                        tw-absolute
                        tw-right-0
                        tw-translate-y-full
                        tw-bg-white
                        tw-rounded-xl
                        tw-shadow-base
                        tw-text-sm
                        tw-font-medium
                        tw-text-gray-500
                        tw-p-7
                        tw-animate-fadein-300
                        tw-flex
                        tw-flex-col
                        tw-z-10
                        ${className}
                    `}>
					{options.map((option, index) => (
						<div
							key={index}
							className="tw-flex tw-flex-nowrap tw-items-center tw-whitespace-nowrap tw-p-2 tw-cursor-pointer tw-bg-white hover:tw-bg-gray-100"
							onClick={() => {
								option.action();
								togglePopup();
							}}>
							{option.icon && <img src={option.icon} alt={option.name} className="tw-mr-2 tw-h-5 tw-w-5" />}
							{option.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
}
