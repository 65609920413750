<div class="pager-wrapper" *ngIf="!isMobile" [style.color]="fontColor">
	<div class="paginator-button paginator-button__left" (click)="leftPaginator()" [style.margin-top]="arrowPosition">
		<app-pager-button *ngIf="hideLeftButton.value" [srcString]="'/assets/' + leftArrowSrc" (clickEvent)="leftPaginator()"></app-pager-button>
	</div>
	<ng-container *ngIf="page == 1">
		<div class="page-content" [style.align-items]="pages > 1 ? 'stretch' : 'center'">
			<ng-content select=".page-1"></ng-content>
			<ng-template *ngTemplateOutlet="paginatorContainer"></ng-template>
		</div>
	</ng-container>
	<ng-container *ngIf="page == 2">
		<div class="page-content" [style.align-items]="pages > 1 ? 'stretch' : 'center'">
			<ng-content select=".page-2"></ng-content>
			<ng-template *ngTemplateOutlet="paginatorContainer"></ng-template>
		</div>
	</ng-container>
	<ng-container *ngIf="page == 3">
		<div class="page-content" [style.align-items]="pages > 1 ? 'stretch' : 'center'">
			<ng-content select=".page-3"></ng-content>
			<ng-template *ngTemplateOutlet="paginatorContainer"></ng-template>
		</div>
	</ng-container>
	<ng-container *ngIf="page == 4">
		<div class="page-content" [style.align-items]="pages > 1 ? 'stretch' : 'center'">
			<ng-content select=".page-4"></ng-content>
			<ng-template *ngTemplateOutlet="paginatorContainer"></ng-template>
		</div>
	</ng-container>

	<ng-template #paginatorContainer>
		<div class="paginator-container">
			<div *ngIf="pages > 1" class="paginator-wrapper">
				<div
					*ngFor="let $page of $pages"
					class="pager-page-selector"
					[ngClass]="pagerClass"
					(click)="DotPagination($page)"
					[style.background-color]="page === $page ? activeDotColor : inactiveDotColor"></div>
			</div>
		</div>
	</ng-template>

	<div class="paginator-button paginator-button__right" [style.margin-top]="arrowPosition">
		<app-pager-button *ngIf="hideRightButton.value" [srcString]="'/assets/' + rightArrowSrc" (clickEvent)="RightPaginator()"></app-pager-button>
	</div>
</div>

<div class="swiper" *ngIf="isMobile">
	<swiper [config]="config" [index]="page - 1" (indexChange)="onSlideChange($event)" (init)="swiperInit($event)">
		<ng-content select=".page-5"></ng-content>
		<ng-content select=".page-6"></ng-content>
		<ng-content select=".page-7"></ng-content>
		<ng-content select=".page-8"></ng-content>
	</swiper>

	<div class="dots-wrapper" [style.bottom]="'-16px'">
		<div class="paginator-container">
			<div *ngIf="pages > 1" class="paginator-wrapper">
				<div
					*ngFor="let $page of $pages"
					class="pager-page-selector"
					(click)="DotPagination($page)"
					[style.background-color]="page === $page ? activeDotColor : inactiveDotColor"></div>
			</div>
		</div>
	</div>
</div>
