import { Injectable } from '@angular/core';
import { User } from '../models/User';

@Injectable({
	providedIn: 'root',
})
export class SchedulePassActivityService {
	private initialStudent: User | null;
	private formVisible = false;

	ShowForm(): void {
		this.formVisible = true;
	}

	SetInitialStudent(user: User | null = null): void {
		if (user) {
			this.initialStudent = user;
		} else {
			this.initialStudent = null;
		}
	}

	HideForm(): void {
		this.formVisible = false;
	}

	isFormVisible(): boolean {
		return this.formVisible;
	}

	getInitialStudent(): User[] {
		if (this.initialStudent) {
			return [this.initialStudent];
		}
		return [];
	}
}
