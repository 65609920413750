import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	ViewChild,
} from '@angular/core';
import { User } from '../models/User';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HallPass } from '../models/HallPass';

import {
	bumpIn,
	ResizeProfileImage,
	resizeStudentPasses,
	scaleStudentPasses,
	showHideProfileEmail,
	studentPassFadeInOut,
	topBottomProfileName,
} from '../animations';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PassCardComponent } from '../pass-card/pass-card.component';
import { DomCheckerService } from '../services/dom-checker.service';
import { PassLike } from '../models';
import { HallPassesService, MODAL_MAX_HEIGHT } from '../services/hall-passes.service';
import { QuickPreviewPasses } from '../models/QuickPreviewPasses';
import { filter, map, take, tap } from 'rxjs/operators';
import { DeviceDetection } from '../device-detection.helper';
import * as moment from 'moment';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from '../services/encounter-prevention.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { TimeService } from '../services/time.service';
import { ReportFormComponent } from '../report-form/report-form.component';
import { KioskModeService } from '../services/kiosk-mode.service';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { SettingsDescriptionPopupComponent } from '../settings-description-popup/settings-description-popup.component';
import { CreateHallpassFormsComponent } from '../create-hallpass-forms/create-hallpass-forms.component';
import { WaitingInLinePass } from '../models/WaitInLine';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';

@Component({
	selector: 'app-student-passes',
	templateUrl: './student-passes.component.html',
	styleUrls: ['./student-passes.component.scss'],
	animations: [ResizeProfileImage, showHideProfileEmail, topBottomProfileName, scaleStudentPasses, resizeStudentPasses, studentPassFadeInOut, bumpIn],
})
export class StudentPassesComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() profile: Pick<User, 'id' | 'profile_picture' | 'first_name' | 'last_name' | 'display_name' | 'primary_email'>;
	@Input() height = 75;
	@Input() isResize = true;
	@Input() openedFromSmallPassTile = false;
	@Input() forMonitor = false;
	@Input() pass: PassLike;
	@Input() hasProfilePicture = true;
	@Input() disableActions = false;
	@Input() displayEncounterPreventionInfo = true;

	@Output() close = new EventEmitter();
	@Output() destroyClose = new EventEmitter();

	@ViewChild('profileImage') profileImage: ElementRef;

	lastStudentPasses: Observable<HallPass[]>;

	isStaff: boolean; // for staff the passes have a richer UI
	private isSmartphone = DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile();
	extraSpace = 50; // space we need for staff UI
	isWaitInLine: boolean;

	isScrollable: boolean;
	//create seperateanimationtrigger for profile image
	animationTrigger = { value: 'open', params: { size: '60' } };
	scaleCardTrigger$: Observable<string>;
	resizeTrigger$: Subject<'open' | 'close'> = new Subject<'open' | 'close'>();
	resizeTriggerParams$: Observable<{ value: 'open' | 'close'; params: { height: number } }>;
	fadeInOutTrigger$: Observable<string>;
	isOpenEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	loading$: Observable<boolean>;
	loaded$: Observable<boolean>;
	passesStats$: Observable<QuickPreviewPasses>;
	private exclusionGroups$: Observable<ExclusionGroupWithOverrides[]>;
	exclusionGroups: ExclusionGroupWithOverrides[] = [];

	hovered: boolean;
	pressed: boolean;
	pressedFooterButtonId: string;
	headerInfoHover: boolean;

	isValid = true;
	isLongName = false;
	user$: Observable<User>;

	destroy$: Subject<any> = new Subject<any>();
	kioskModeRoom$ = this.kioskMode.getCurrentRoom();

	@HostListener('document.scroll', ['$event'])
	scroll(event) {
		if (event.currentTarget.scrollTop >= 50) {
			this.isScrollable = true;
			this.animationTrigger = { value: 'close', params: { size: '42' } };
		} else {
			this.isScrollable = false;
			this.animationTrigger = { value: 'open', params: { size: '75' } };
		}
	}

	constructor(
		private dialog: MatDialog,
		@Optional() private dialogRef: MatDialogRef<PassCardComponent>,
		private domCheckerService: DomCheckerService,
		private passesService: HallPassesService,
		private encounterPreventionService: EncounterPreventionService,
		private router: Router,
		private userService: UserService,
		private timeService: TimeService,
		private kioskMode: KioskModeService,
		private featureFlagService: FeatureFlagService
	) {}

	ngAfterViewInit() {
		if (!this.isResize) {
			this.domCheckerService.fadeInOutTrigger$.next('fadeIn');
		}
	}

	ngOnInit() {
		if (this.pass instanceof HallPass) {
			const passStatus = this.passesService.getPassStatus(this.pass.start_time, this.pass.end_time, this.pass.expiration_time, false, false, false);
			if (passStatus !== 'ended') {
				this.isValid = moment().isBefore(moment(this.pass.expiration_time));
			}
		}
		if (this.profile.display_name.length > 20) {
			this.isLongName = true;
		}
		if (this.isResize && !this.isOpenEvent$.getValue()) this.isWaitInLine = this.pass instanceof WaitingInLinePass;
		this.isWaitInLine = this.pass instanceof WaitingInLinePass;
		this.user$ = this.userService.userJSON$.pipe(map((u) => User.fromJSON(u)));
		this.fadeInOutTrigger$ = this.domCheckerService.fadeInOutTrigger$;
		this.passesService.getQuickPreviewPassesRequest(this.profile.id, true);
		this.encounterPreventionService.getExclusionGroupsRequest({ student: this.profile.id });
		this.scaleCardTrigger$ = this.domCheckerService.scalePassCard;
		this.lastStudentPasses = this.passesService.quickPreviewPasses$.pipe(map((passes) => passes.map((pass) => HallPass.fromJSON(pass))));
		this.loading$ = this.passesService.quickPreviewPassesLoading$;
		this.loaded$ = this.passesService.quickPreviewPassesLoaded$;
		this.passesStats$ = this.passesService.quickPreviewPassesStats$;
		this.exclusionGroups$ = this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride)
			? this.encounterPreventionService.getExclusionGroupsWithOverrides(this.profile.id)
			: this.encounterPreventionService.getExclusionGroups({ student: this.profile.id });
		this.exclusionGroups$.subscribe((groups) => (this.exclusionGroups = groups));

		this.resizeTriggerParams$ = this.resizeTrigger$.pipe(
			map((s: 'open' | 'close') => {
				const h = s === 'open' ? 475 : 75;
				const args = {
					value: s,
					params: {
						height: this.extraSpace + h,
					},
				};
				return args;
			})
		);

		this.user$
			.pipe(
				take(1),
				map((user) => user.isStaff()),
				tap((isStaff) => {
					this.isStaff = isStaff;
					if (!this.kioskModeRoom$.value) {
						this.height += this.extraSpace;
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	get isOpen() {
		return this.isOpenEvent$.getValue() || !this.isResize;
	}

	get isClose() {
		return !this.isOpenEvent$.getValue() && this.isResize;
	}

	get isMobile() {
		return DeviceDetection.isMobile();
	}

	openStudentInfoPage() {
		this.router.navigate([`/main/student/${this.profile.id}`]);
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}

	get isIOSTablet() {
		return DeviceDetection.isIOSTablet();
	}

	preparePassData(pass) {
		const now = this.timeService.nowDate();
		now.setSeconds(now.getSeconds() + 10);

		pass = Object.assign({}, pass);
		let data: any = {};
		const fromPast = false;
		const forFuture = false;
		const isActive = false;
		const forStaff = true;
		const forMonitor = false;

		if (pass instanceof HallPass) {
			data = {
				pass,
				fromPast: pass['end_time'] < now,
				forFuture: pass['start_time'] > now,
				forMonitor: forMonitor,
				forStaff: forStaff && !this.kioskMode.getCurrentRoom().value,
				kioskMode: !!this.kioskMode.getCurrentRoom().value,
				//hideReport: this.isAdminPage,
				//activePassTime$: this.activePassTime$,
				showStudentInfoBlock: !this.kioskMode.getCurrentRoom().value,
			};
			data.isActive = !data.fromPast && !data.forFuture;
		} else {
			data = {
				pass,
				fromPast,
				forFuture,
				forMonitor,
				isActive,
				forStaff,
			};
		}

		return data;
	}

	openReport(event: Event, pass: PassLike) {
		event.stopPropagation();
		this.pressedFooterButtonId = 'report-button';
		const passData = this.preparePassData(pass);
		const isHallPass = pass instanceof HallPass;
		const data = {
			// to skip choosing the students
			// as the student's pass is to be reported
			report: this.profile,
			isHallPass,
			...passData,
		};

		const reportRef = this.dialog.open(ReportFormComponent, {
			panelClass: ['form-dialog-container', this.isIOSTablet ? 'ios-report-dialog' : 'report-dialog'],
			hasBackdrop: false,
			data,
		});

		reportRef.afterOpened().subscribe(() => {
			// back button will close the dialog
			// it usually close the dialog or it goes back one step the report form
			// report form was/is a 2-step form
			const c = reportRef.componentInstance;
			c.forceCloseClick = true;
			c.useChipInsteadSearch = true;
		});

		this.dialogRef.afterClosed().subscribe(() => {
			reportRef.close();
		});
	}

	openCreatePass(event) {
		this.pressedFooterButtonId = 'create-pass-button';
		event.stopPropagation();
		const settings = [
			{
				label: 'Create pass for now',
				icon: './assets/Plus (Blue-Gray).svg',
				textColor: '#7f879d',
				backgroundColor: '#F4F4F4',
				action: 'now',
			},
			{
				label: 'Create future pass',
				icon: './assets/Schedule pass (Blue-Gray).svg',
				textColor: '#7f879d',
				backgroundColor: '#F4F4F4',
				action: 'feature',
			},
		];
		UNANIMATED_CONTAINER.next(true);
		const st = this.dialog.open(SettingsDescriptionPopupComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { trigger: event.currentTarget, settings, adjustForScroll: true },
		});

		st.afterClosed()
			.pipe(
				tap(() => UNANIMATED_CONTAINER.next(false)),
				filter((r) => !!r)
			)
			.subscribe((action) => {
				const mainFormRef = this.dialog.open(CreateHallpassFormsComponent, {
					panelClass: 'main-form-dialog-container',
					//backdropClass: 'custom-backdrop',
					hasBackdrop: false,
					maxWidth: '100vw',
					data: {
						forLater: action === 'feature',
						forStaff: true,
						forInput: true,
						fromAdmin: true,
						adminSelectedStudent: this.profile,
					},
				});
			});
	}

	notClose(value) {
		if (value) {
			setTimeout(() => {
				this.destroyClose.emit(value);
			}, 200);
		} else {
			this.destroyClose.emit(value);
		}
	}

	openPass({ pass }) {
		if (!this.isResize) {
			this.close.emit();
		}
		const modalHeight = this.passesService.getModalHeight(pass, this.isStaff, this.kioskMode.isKioskMode());
		const modalWidth = this.passesService.getModalWidth(this.isSmartphone);
		const data = {
			pass,
			forStaff: true,
			showStudentInfoBlock: !this.isResize,
			passForStudentsComponent: this.isResize,
			hasDeleteButton: true,
		};

		const config: MatDialogConfig = {
			panelClass: (this.isStaff ? 'teacher-' : 'student-') + 'pass-card-dialog-container',
			width: modalWidth,
			height: modalHeight,
			maxHeight: `${MODAL_MAX_HEIGHT}px`,
			data: data,
		};
		this.domCheckerService.scalePassCardTrigger$.next('open');
		const expiredPass = this.dialog.open(PassCardComponent, config);

		expiredPass.afterClosed().subscribe(() => {
			this.domCheckerService.scalePassCardTrigger$.next('close');
		});
	}
}
