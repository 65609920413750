import { Component } from '@angular/core';
import { FeatureFlagService, Flag, UpdateFlag } from '../../services/feature-flag.service';
import { FormControl, FormGroup } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { cloneDeep, findIndex } from 'lodash';

@Component({
	selector: 'feature-flag-panel',
	template: `
		<div *ngIf="flags$ | withLoading | async as flags" class="tw-p-8 tw-overflow-y-auto tw-h-full tw-box-border">
			<app-loader [loading]="flags.loading">
				<div class="tw-text-lg tw-font-bold tw-text-navy-500 tw-mb-6">🏁 Feature Flags</div>
				<div class="tw-flex tw-flex-col tw-items-stretch">
					<div *ngFor="let flag of flags.value" class="tw-flex tw-pb-4 tw-items-center">
						<div class="tw-flex tw-flex-col tw-flex-grow">
							<div class="tw-text-base tw-font-medium tw-text-gray-700">
								{{ flag.title }}
							</div>
							<div class="tw-text-sm tw-text-gray-500 tw-pt-1">
								{{ flag.description }}
							</div>
						</div>

						<app-toggle-input
							*ngIf="flagForm$ | async as flagForm"
							[form]="flagForm"
							[hasIcon]="false"
							[controlSize]="'small'"
							[delimiter]="false"
							[controlName]="flag.internal_name"
							(pushOutValue)="updateFlags(flagForm.value).subscribe()"
							class="tw-ml-4"
							style="width: fit-content">
						</app-toggle-input>
					</div>
				</div>
			</app-loader>
		</div>
	`,
	styles: [
		`
			::ng-deep .feature-flag-dialog-panel {
				mat-dialog-container {
					border-radius: 16px;
					overflow: hidden;
					padding: 0px;
				}
			}
		`,
	],
})
export class FeatureFlagPanelComponent {
	flags$: Observable<Flag[]>;
	flagForm$: Observable<FormGroup>;
	flagsUpdated$ = new Subject<Flag[]>();
	flagWasUpdated = false;

	constructor(private featureFlagService: FeatureFlagService, private dialogRef: MatDialogRef<void>) {
		this.flags$ = merge(
			this.featureFlagService.listFeatureFlags().pipe(
				map((resp) => {
					const { flags } = resp;
					return this.placeFlagAtFront(this.placeFlagAtFront(flags, 'wil_ui_refresh'), 'origin_wait_in_line');
				}),
				shareReplay()
			),
			this.flagsUpdated$
		);

		this.flagForm$ = this.flags$.pipe(
			map((flags) => {
				const formControls = flags.reduce((prev, curr) => {
					prev[curr.internal_name] = new FormControl(curr.value.enabled);
					return prev;
				}, {});

				return new FormGroup(formControls);
			}),
			shareReplay()
		);

		dialogRef.afterClosed().subscribe(() => {
			if (this.flagWasUpdated) {
				location.reload();
			}
		});
	}

	updateFlags(formValue: any): Observable<Flag[]> {
		this.flagWasUpdated = true;
		const updateFlags: UpdateFlag[] = Object.keys(formValue).map((flagName) => {
			return { internal_name: flagName, value: { enabled: formValue[flagName] } };
		});

		return this.featureFlagService.updateFeatureFlags({ flags: updateFlags }).pipe(
			map((resp) => this.placeFlagAtFront(this.placeFlagAtFront(resp.flags, 'wil_ui_refresh'), 'origin_wait_in_line')),
			tap((flags) => this.flagsUpdated$.next(flags))
		);
	}

	private placeFlagAtFront(flags: Flag[], flagInternalName: string) {
		const index = findIndex(flags, (f) => f.internal_name === flagInternalName);
		if (index === -1) {
			return flags;
		}
		const frontFlag = cloneDeep(flags[index]);
		flags.splice(index, 1);
		return [frontFlag, ...flags];
	}
}
