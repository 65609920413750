<div (click)="isStaff && !disableActions ? openStudentInfoPage() : null" [ngClass]="{ 'small-pass-tile-view': openedFromSmallPassTile }">
	<div
		[@scaleStudentPasses]="scaleCardTrigger$ | async"
		[@resizeStudentPasses]="resizeTriggerParams$ | async"
		[@studentPassFadeInOut]="fadeInOutTrigger$ | async"
		[@pressState]="!disableActions && pressed && !isOpen ? 'down' : 'up'"
		class="student-wrapper"
		[style.cursor]="disableActions ? 'default' : 'pointer'"
		[style.border]="!isOpen ? '1px solid #E2E6EC' : 'none'"
		(mouseenter)="hovered = true"
		(mouseleave)="hovered = false; pressed = false">
		<div class="header-actions-wrapper">
			<div
				class="header-container"
				[style.box-shadow]="isScrollable ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none'"
				[style.border-radius]="isOpen ? '13px 13px 0 0' : '13px'">
				<div
					class="header"
					[style.padding]="isClose ? 0 : '15px 0'"
					[style.background-color]="isOpen ? '#FFFFFF' : 'transparent'"
					(mouseenter)="headerInfoHover = isOpen"
					(mouseleave)="headerInfoHover = false"
					(click)="isOpen ? openStudentInfoPage() : null">
					<div class="profile-info" [style.padding]="isScrollable ? '0 0 0 10px' : hasProfilePicture ? '0 10px' : '10px 10px'">
						<div class="profile-image avatar-animate" *ngIf="hasProfilePicture">
							<div
								[@openCloseProfile]="animationTrigger"
								*ngIf="profile.profile_picture; else d"
								[ngStyle]="{
									background: 'url(' + profile.profile_picture + ') no-repeat left center/cover',
									width: '88px',
									height: '88px',
									'border-radius': '50px'
								}"></div>
							<ng-template #d>
								<img
									[@openCloseProfile]="animationTrigger"
									*ngIf="user$ | async"
									width="88"
									height="88"
									[src]="'./assets/Avatar Default.svg' | resolveAsset"
									alt="Avatar"
									customToolTip
									[contentTemplate]="tooltip"
									[nonDisappearing]="false"
									[showToolTip]="!(user$ | async).isAdmin()" />
								<ng-template #tooltip>
									<div class="ds-tooltip" style="text-align: left">
										<div class="only-admin">ADMIN ONLY</div>
										Please contact your admin so they can bulk upload profile pictures for your school.
									</div>
								</ng-template>
							</ng-template>
						</div>
						<div class="title">
							<div
								class="full-name"
								[style.font-size]="isLongName ? '15px' : isMobile ? '22px' : '24px'"
								[@topBottomName]="animationTrigger"
								[style.text-decoration]="headerInfoHover ? 'underline' : 'none'">
								<span *ngIf="!isMobile" style="white-space: nowrap" notranslate>{{ profile.first_name }} {{ profile.last_name }}</span>
								<span *ngIf="isMobile" style="white-space: nowrap" notranslate>{{ profile.first_name.charAt(0) }}. {{ profile.last_name }}</span>
								<span style="margin-left: 7px" *ngIf="exclusionGroups?.length && displayEncounterPreventionInfo" (mouseenter)="notClose(true)">
									<app-octagon
										[size]="22"
										customToolTip
										[contentTemplate]="tooltip"
										[position]="'top'"
										[toolTipHideDelay]="800"
										(leave)="notClose(false)">
										<img
											class="value"
											style="position: absolute; z-index: 10"
											height="18"
											[src]="'./assets/Walking One (White).svg' | resolveAsset" />
									</app-octagon>
									<ng-template #tooltip>
										<app-encounter-prevention-tooltip
											[groups]="exclusionGroups"
											[user]="user$ | async"
											*ngIf="displayEncounterPreventionInfo"></app-encounter-prevention-tooltip>
									</ng-template>
								</span>
							</div>
							<div notranslate class="email" [@showHideEmail]="animationTrigger">{{ profile.primary_email | spEmail }}</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="isStaff && !isOpen && !kioskModeRoom$.value" class="action-buttons-wrapper">
				<div
					class="sp-action-button"
					[ngClass]="{ overtime: !isValid, pressed: pressedFooterButtonId === 'report-button' }"
					(click)="openReport($event, pass)"
					(mousedown)="pressedFooterButtonId = 'report-button'"
					(mouseleave)="pressedFooterButtonId = ''"
					customToolTip
					[contentTemplate]="reportTooltip">
					<img [src]="isValid ? './assets/Report (Gray-500).svg' : './assets/Report (White).svg'" />
				</div>
				<ng-template #reportTooltip>
					<div class="ds-tooltip">
						<div class="ds-tooltip-arrow"></div>
						Report
					</div>
				</ng-template>
				<div
					class="sp-action-button"
					(click)="openCreatePass($event)"
					customToolTip
					[contentTemplate]="createPassTooltip"
					(mousedown)="pressedFooterButtonId = 'create-pass-button'"
					(mouseleave)="pressedFooterButtonId = ''"
					[ngClass]="{ pressed: pressedFooterButtonId === 'create-pass-button' }">
					<img [src]="'./assets/Plus (Gray-500).svg' | resolveAsset" />
				</div>
				<ng-template #createPassTooltip>
					<div class="ds-tooltip">Create Pass</div>
				</ng-template>
			</div>
		</div>

		<div
			class="collection-wrapper"
			*ngIf="(isOpenEvent$ | async) || !isResize"
			(scroll)="scroll($event)"
			[style.height]="!isResize ? '300px' : '475px'">
			<div class="content" (mouseenter)="headerInfoHover = true" (mouseleave)="headerInfoHover = false" (click)="openStudentInfoPage()">
				<div class="student-info" *ngIf="passesStats$ | async as stats">
					<app-student-passes-info-card
						[inSmallContainer]="true"
						[customToolTip]="'The student had ' + stats?.past_passes_today + (stats?.past_passes_today === 1 ? ' pass' : ' passes') + ' today.'"
						[nonDisappearing]="false"
						[number]="stats?.past_passes_today"
						[title]="'today'"
						[loading]="loading$ | async"></app-student-passes-info-card>
					<app-student-passes-info-card
						[inSmallContainer]="true"
						[customToolTip]="'The student had ' + stats?.past_passes_week + (stats?.past_passes_week === 1 ? ' pass' : ' passes') + ' this week.'"
						[nonDisappearing]="false"
						[number]="stats?.past_passes_week"
						[title]="'this week'"
						[loading]="loading$ | async"></app-student-passes-info-card>
					<app-student-passes-info-card
						[inSmallContainer]="true"
						[customToolTip]="'The student had ' + stats?.past_passes_month + (stats?.past_passes_month === 1 ? ' pass' : ' passes') + ' this month.'"
						[nonDisappearing]="false"
						[number]="stats?.past_passes_month"
						[title]="'this month'"
						[loading]="loading$ | async"></app-student-passes-info-card>
				</div>
			</div>

			<mat-divider></mat-divider>
			<div class="collection">
				<div class="emptyState" *ngIf="(loaded$ | async) && !(lastStudentPasses | async).length; else passes">
					<img style="opacity: 0.5" width="60" [src]="'./assets/Passes (Blue-Gray).svg'" alt="Passes" />
					<div class="empty-title">No expired passes</div>
				</div>

				<ng-template #passes>
					<div class="pass-collection" [style.width]="isMobile ? '325px' : '300px'">
						<div class="title">
							<div style="margin-left: 8px; font-weight: 600">Passes</div>
						</div>
						<div
							class="passes"
							*ngIf="!(loading$ | async); else fakePasses"
							[style.grid-template-columns]="isMobile ? 'repeat(auto-fill, 157px)' : 'repeat(auto-fill, 144px)'">
							<div class="pass" *ngFor="let pass of lastStudentPasses | async">
								<app-pass-tile
									[forFuture]="false"
									[fromPast]="true"
									[forStaff]="false"
									[pass]="pass"
									(tileSelected)="openPass($event)"
									[profileImage]="false"
									[isMiniCard]="true"></app-pass-tile>
							</div>
						</div>
						<ng-template #fakePasses>
							<div class="passes">
								<div class="fake-pass animate" *ngFor="let pass of [1, 1, 1, 1, 1, 1]"></div>
							</div>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
