<div class="notif-select-students">
	<mat-spinner *ngIf="!showAddStudent" [color]="'accent'" [diameter]="15"></mat-spinner>

	<div *ngFor="let student of displayedStudents; let i = index" (click)="removeStudent(i)" class="student real">
		<div class="student-overlay">
			<img [src]="'./assets/Close.svg' | resolveAsset" alt="" />
		</div>
		<img alt="" [src]="student['icon'] | resolveAsset" />
		<span>{{ student['name'] }}</span>
	</div>
	<div *ngIf="showAddStudent" class="student new" (click)="beginSearch()">
		<img alt="" [src]="'./assets/Plus (Blue-Gray).svg' | resolveAsset" />
		<span>Add Student</span>
	</div>
</div>
