import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { bumpIn } from '../../../../animations';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';

@Component({
	selector: 'app-encounter-group',
	templateUrl: './encounter-group.component.html',
	styleUrls: ['./encounter-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [bumpIn],
})
export class EncounterGroupComponent implements OnInit {
	@Input() group!: ExclusionGroupWithOverrides;
	@Input() backgroundColor = 'transparent';
	@Input() infoCard = false;
	@Input() studentProfileView = false;

	@Output() clickEvent = new EventEmitter<ExclusionGroupWithOverrides>();

	numOverrides = 0;
	numPreventedEncounters = 0;
	hovered = false;
	pressed = false;

	constructor(private ePService: EncounterPreventionService) {}

	ngOnInit(): void {
		this.numOverrides = this.ePService.countNumOverrides(this.group);
		if (this.group.prevented_encounters) {
			this.numPreventedEncounters = this.group.prevented_encounters.length;
		}
	}
}
