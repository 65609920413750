<input type="tel" tabindex="1" style="position: absolute; width: 0; height: 0; opacity: 0" autofocus (blur)="blur()" [id]="pass.id" #inp />
<div class="teacher-pin-view">
	<div class="content">
		<div class="teacher-pin">
			<div class="pin-title">Enter Teacher Pin</div>
			<div class="circles" [ngClass]="{ incorrect: incorrect }">
				<div class="circle" *ngFor="let circle of circles; let i = index" [style.background-color]="circle.pressed ? 'white' : 'transparent'"></div>
			</div>
			<div class="pin-info">Type with Keyboard</div>
		</div>
	</div>
</div>
