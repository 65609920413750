import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-pass-limit-feedback',
	templateUrl: './pass-limit-feedback.component.html',
	styleUrls: ['./pass-limit-feedback.component.scss'],
})
export class PassLimitFeedbackComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
