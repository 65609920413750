import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { User } from '../../models/User';
import { School } from '../../models/School';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { CreateFormService } from '../../create-hallpass-forms/create-form.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyProfileDialogComponent } from '../my-profile-dialog.component';
import { StorageService } from '../../services/storage.service';
import { EditAvatarComponent } from '../../admin/profile-card-dialog/edit-avatar/edit-avatar.component';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { DarkThemeSwitch } from 'app/dark-theme-switch';
import { ProfilePictureComponent } from 'app/admin/accounts/profile-picture/profile-picture.component';
import { ConsentMenuComponent } from 'app/consent-menu/consent-menu.component';

export const UNANIMATED_CONTAINER: ReplaySubject<boolean> = new ReplaySubject(1);

@Component({
	selector: 'app-profile-info',
	templateUrl: './profile-info.component.html',
	styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit {
	@Input() user: User;
	@Input() schools: School[];

	@Output() nextPage: EventEmitter<any> = new EventEmitter<any>();

	isStaff: boolean;

	frameMotion$: BehaviorSubject<any>;
	userAuthType: string;

	isOpenAvatarDialog: boolean;
	loadingProfilePicture: Subject<boolean> = new Subject<boolean>();

	@ViewChild('editIcon') editIcon: ElementRef;

	constructor(
		private formService: CreateFormService,
		private storage: StorageService,
		public dialogRef: MatDialogRef<MyProfileDialogComponent>,
		private matDialog: MatDialog,
		private userService: UserService,
		private darkTheme: DarkThemeSwitch
	) {}

	ngOnInit() {
		this.isStaff = !User.fromJSON(this.user).isStudent();
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.userAuthType = this.storage.getItem('authType');
	}

	checkAccountType(account: User) {
		if (account.sync_types.indexOf('google') !== -1) {
			return 'Connected with G Suite';
		} else if (account.sync_types.indexOf('gg4l') !== -1) {
			return 'Connected with GG4L';
		} else if (account.sync_types.indexOf('clever') !== -1) {
			return 'Connected with Clever';
		} else if (!account.sync_types.length) {
			return 'Standard account';
		}
	}

	checkUserRoles(roles: string[]) {
		return roles
			.reduce((acc, currRole) => {
				if (currRole === '_profile_admin') {
					return [...acc, 'Admin'];
				} else if (currRole === '_profile_teacher') {
					return [...acc, 'Teacher'];
				} else if (currRole === '_profile_student') {
					return [...acc, 'Student'];
				} else if (currRole === '_profile_assistant') {
					return [...acc, 'Assistant'];
				} else if (currRole === '_profile_parent') {
					return [...acc, 'Parent'];
				}
				return [...acc];
			}, [])
			.join(', ');
	}

	openEditAvatar(event) {
		this.isOpenAvatarDialog = true;
		const target = event.currentTarget ? event.currentTarget : event;
		const ED = this.matDialog.open(EditAvatarComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { trigger: target, user: this.user },
		});

		ED.afterClosed()
			.pipe(
				tap(() => (this.isOpenAvatarDialog = false)),
				filter((r) => !!r),
				tap(({ action, file }) => {
					this.loadingProfilePicture.next(true);
					if (action === 'add') {
						this.userService.addProfilePictureRequest(this.user, '_profile_teacher', file);
					} else if (action === 'edit') {
						this.userService.addProfilePictureRequest(this.user, '_profile_teacher', file);
					}
				}),
				switchMap(() => {
					return this.userService.currentUpdatedAccount$['_profile_teacher'].pipe(filter((res) => !!res));
				}),
				tap((user) => {
					this.user = User.fromJSON(user);
					this.userService.clearCurrentUpdatedAccounts();
					this.loadingProfilePicture.next(false);
				})
			)
			.subscribe();
	}

	genOption(display, color, action, icon?) {
		return { display, color, action, icon };
	}

	consentDialogOpen(evt) {
		const options = [];
		options.push(
			this.genOption(
				'Add individual picture',
				this.darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' }),
				'individual',
				this.darkTheme.getIcon({ iconName: 'Plus', darkFill: 'White', lightFill: 'Blue-Gray' })
			)
		);
		options.push(
			this.genOption(
				'Bulk upload pictures',
				this.darkTheme.getColor({ dark: '#FFFFFF', white: '#7f879d' }),
				'bulk',
				this.darkTheme.getIcon({ iconName: 'Add Avatar', darkFill: 'White', lightFill: 'Blue-Gray' })
			)
		);

		UNANIMATED_CONTAINER.next(true);

		const cancelDialog = this.matDialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { options: options, trigger: new ElementRef(evt), adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(
				tap(() => {
					UNANIMATED_CONTAINER.next(false);
					this.isOpenAvatarDialog = false;
				}),
				filter((r) => !!r)
			)
			.subscribe((action) => {
				if (action === 'individual') {
					this.isOpenAvatarDialog = true;
					this.openEditAvatar(this.editIcon.nativeElement);
				} else if (action === 'bulk') {
					this.matDialog.open(ProfilePictureComponent, {
						id: 'student-info',
						panelClass: 'accounts-profiles-dialog',
						backdropClass: 'custom-bd',
						width: '425px',
						height: '500px',
					});
					this.dialogRef.close();
				}
			});
	}

	editWindow() {
		this.isOpenAvatarDialog = true;
		if (!this.userService.getUserSchool().profile_pictures_completed) {
			this.consentDialogOpen(this.editIcon.nativeElement);
		} else {
			this.openEditAvatar(this.editIcon.nativeElement);
		}
	}
}
