<div class="main">
	<div class="setting-card">
		<div class="left">
			<div class="left__title">Export Attendance Data</div>
			<div class="left__subtitle">
				Export all attendance records to import into your SIS and run statistics. If you need it in a different format or have feedback, send us a
				message.
			</div>
		</div>
		<div class="right">
			<sp-attendance-export></sp-attendance-export>
		</div>
	</div>
</div>
