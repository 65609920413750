<div *ngIf="hasSchedulesFF; else oldChip" class="schedules-ff">
	<!-- Student Group chip -->
	<div
		*ngIf="!isUser"
		class="selection tw-relative"
		[ngClass]="{ 'hovered-chip': hovered }"
		(mouseover)="hovered = true"
		(mouseleave)="hovered = false">
		<img class="group-icon" src="../../../../../assets/Groups Icon.svg" />
		<div class="name">{{ selection.title }}</div>
		<div
			class="close-button tw-cursor-pointer tw-rounded-lg"
			(click)="removeSelection(selection)"
			*ngIf="showIcon"
			(mouseover)="iconHovered = true"
			(mouseleave)="iconHovered = false">
			<img src="../../../../../assets/close-x-tiny.svg" alt="remove selection icon" />
		</div>
	</div>

	<!-- Individual User chip -->
	<div
		*ngIf="isUser"
		class="selection tw-relative"
		[ngClass]="{ locked: locked, hover: showIcon }"
		(mouseover)="hovered = true"
		(mouseleave)="hovered = false">
		<div
			*ngIf="selection.profile_picture; else d"
			[ngStyle]="{ background: 'url(' + selection.profile_picture + ') no-repeat left center/cover' }"
			class="profile-picture"></div>
		<ng-template #d>
			<img class="profile-picture" src="../../../../../assets/Avatar (Gray-400).svg" alt="Avatar" />
		</ng-template>
		<div class="name">
			<div>{{ selection.display_name }}</div>
		</div>
		<div *ngIf="!locked && showIcon" (click)="removeSelection(selection)" (mouseover)="iconHovered = true" (mouseleave)="iconHovered = false">
			<div class="close-button tw-cursor-pointer tw-rounded-lg" [ngClass]="{ 'hovered-icon': iconHovered }">
				<img src="../../../../../assets/close-x-tiny.svg" alt="remove selection icon" />
			</div>
		</div>
		<div
			*ngIf="locked"
			class="lock-icon tw-rounded-lg"
			customToolTip
			[showToolTip]="locked"
			[contentTemplate]="tooltipContent"
			(mouseover)="iconHovered = true"
			(mouseleave)="iconHovered = false"
			[ngClass]="{ 'hovered-icon': iconHovered }">
			<img class="tw-h-3" src="./../../../../assets/Lock (Small).svg" />
		</div>
	</div>
</div>

<ng-template #oldChip>
	<div class="no-schedules-ff">
		<div *ngIf="!isUser" class="selection">
			<img class="group-icon" src="../../../../../assets/Groups Icon.svg" />
			<span class="name">{{ selection.title }}</span>
			<img class="close-button" src="../../../../../assets/Close (Navy).svg" (click)="removeSelection(selection)" />
		</div>

		<div *ngIf="isUser" class="selection">
			<div
				*ngIf="selection.profile_picture; else d"
				[ngStyle]="{ background: 'url(' + selection.profile_picture + ') no-repeat left center/cover' }"
				class="profile-picture"></div>
			<ng-template #d>
				<img class="profile-picture" src="../../../../../assets/Avatar (Gray-400).svg" alt="Avatar" />
			</ng-template>
			<span class="name">{{ selection.display_name }}</span>
			<img class="close-button" src="../../../../../assets/Close (Navy).svg" (click)="removeSelection(selection)" />
		</div>
	</div>
</ng-template>
