import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Location } from '../models/Location';
import { User } from '../models/User';
import { HallPassesService } from '../services/hall-passes.service';
import { SearchEntity } from '../sp-search/sp-search.component';
interface OrgUnits {
	path: string;
}
type RoomIconsAndGradient = {
	icon: string;
	roomGradient: string;
};
@Component({
	selector: 'app-sp-chips',
	templateUrl: './sp-chips.component.html',
	styleUrls: ['./sp-chips.component.scss'],
})
export class SpChipsComponent implements OnChanges {
	// text for the button that triggers adding entities
	private textAddButtonDefault = 'Add';

	@Input() selectedProfiles: User[] | any[] = [];
	@Input() preventRemovingLast = false;
	@Input() suggestedTeacher: User;
	@Input() isProposed: boolean;
	@Input() hideAddButton: boolean;
	@Input() textAddButton: string | null;
	@Input() buttonTextColor = '#7F879D';
	@Input() buttonGradient = '#F4F4F4, #F4F4F4';
	@Input() addButtonBorder: string;
	@Input() addButtonShadow: string;
	@Input() addButtonDisable = false;
	@Input() buttonLeftIcon = './assets/Plus (Blue-Gray).svg';
	@Input() textPrepend: string | null;
	@Input() selectedTarget: SearchEntity = 'users';
	@Input() orgUnitList: string[];
	@Input() orgUnitExistCheck: BehaviorSubject<boolean>;

	@Output() add: EventEmitter<boolean> = new EventEmitter();
	@Output() updateSelectedEvent: EventEmitter<User[]> = new EventEmitter();
	@Output() addSuggestedTeacher: EventEmitter<User> = new EventEmitter<User>();
	private orgUnits: any = [];

	constructor(private sanitizer: DomSanitizer, private hallPassesService: HallPassesService) {}

	iconHovered: { [key: string]: boolean } = {};
	chipHovered: { [key: string]: boolean } = {};

	// todo better typing - this component is used for users, locations, orgunits (whatever those are) and possibly other things
	results$: BehaviorSubject<unknown[]> = new BehaviorSubject<unknown[]>([]);

	roomIconsAndGradients$: Observable<RoomIconsAndGradient[]> = combineLatest([this.results$, this.hallPassesService.pinnables$]).pipe(
		filter(([r, p]) => !!r && !!p),
		map(([r, p]) => {
			const iconAndRoomGradients: RoomIconsAndGradient[] = [];
			r.forEach((l) => {
				const pin = this.hallPassesService.findPin(p, l as Location);
				const colors = pin.color_profile.gradient_color.split(',').map((color) => color.trim());
				if (colors.length === 2) {
					const rg = this.getRoomGradientStyle([colors[0], colors[1]]);
					iconAndRoomGradients.push({
						icon: pin.icon,
						roomGradient: rg,
					});
				}
			});
			return iconAndRoomGradients;
		})
	);

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedProfiles) {
			const results = this.getResults();
			this.results$.next(results);
		}
		this.textAddButton = this.textAddButton ?? this.textAddButtonDefault;
	}

	// todo better typing
	private getResults(): any[] {
		if (!this.selectedProfiles?.length) {
			return [];
		}
		// teacher use case
		if (this.selectedProfiles.length > 0 && (typeof this.selectedProfiles[0] !== 'object' || !('title' in this.selectedProfiles[0]))) {
			return this.selectedProfiles;
		}
		// room use case
		this.selectedProfiles.forEach((sp) => {
			const pin = this.hallPassesService.getPinnable(sp);
		});
		return uniqBy(this.selectedProfiles, 'title');
	}
	private getRoomGradientStyle([startColor, endColor]: [string, string]): string {
		return `radial-gradient(circle at 73% 71%, ${startColor.trim()}, ${endColor.trim()})`;
	}

	chipHover(chip: any, hover: boolean): void {
		if (!(this.preventRemovingLast && this.selectedProfiles.length === 1)) {
			chip.hovered = hover;
		}
	}

	setChipHover(index: number, state: boolean): void {
		this.chipHovered[index] = state;
	}

	setIconHover(index: number, state: boolean): void {
		this.iconHovered[index] = state;
	}

	orgUnitDeleteCheck(item: any): boolean {
		if (this.orgUnitList?.length > 0) {
			if (this.orgUnitList.includes(item.path)) {
				return false;
			} else {
				this.orgUnitExistCheck.next(true);
				return true;
			}
		}
		return false;
	}

	removeSelectedProfile(): void {
		let existCount = 0;
		if (this.selectedProfiles?.length > 0 && this.orgUnitList?.length > 0) {
			this.selectedProfiles.forEach((sp: any) => {
				if (this.orgUnitList.includes(sp.path)) {
					existCount = existCount + 1;
				}
			});
			if (existCount == this.selectedProfiles.length && this.orgUnitExistCheck) {
				this.orgUnitExistCheck.next(false);
			}
		} else if (this.selectedProfiles.length == 0 && this.orgUnitExistCheck) {
			this.orgUnitExistCheck.next(false);
		}
		const results = this.getResults();
		this.results$.next(results);
	}
}
