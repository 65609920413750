import { spPostBlob } from './smartpass-http';

export async function exportInstanceByFlex(schoolId: number, flexPeriodId: number, date: Date) {
	return spPostBlob(
		'v2/school_activities/instances/export',
		{
			flex_period_id: flexPeriodId,
			date: date.toISOString(),
		},
		{
			'X-School-Id': String(schoolId),
		}
	);
}
