<div class="date-time-wrapper">
	<app-date-time
		[isStaff]="FORM_STATE.formMode.role === 1"
		[formState]="FORM_STATE"
		(backButton)="back($event)"
		(result)="nextStep($event)"></app-date-time>
</div>

<!--<img *ngIf="isStaff" [ngClass]=" isIOSTablet ? 'ios-back-button' : 'back-button'" [src]="'./assets/Back Button (Blue-White).svg' | resolveAsset" (click)="back($event)">-->

<div [ngClass]="isIOSTablet ? 'ios-back-button' : 'back-button'">
	<app-icon-button
		(clickEvent)="stepBackMobile()"
		[srcString]="'Back Button (Blue-White).svg '"
		[size]="28"
		[onDarkShade]="false"
		[blueWhite]="true"
		[hasShadow]="false"></app-icon-button>
</div>
