<div class="ca-wrapper">
	<div class="ca-close-btn" (click)="close()">
		<div></div>
	</div>
	<div class="create-activity">
		<form [formGroup]="activityForm" (ngSubmit)="onSubmit()" class="ca-form">
			<div class="ca-content" *ngIf="!loading" [style.height]="maxHeightValue">
				<div *ngIf="schedulesFeatureFlag; else emptyTopPadding">
					<ng-container *ngTemplateOutlet="refinerSurvey"></ng-container>
				</div>
				<div class="ca-padding">
					<div class="name-input" *ngIf="flexMode" [@fadeInOut]>
						<input type="text" formControlName="name" [placeholder]="'Add title'" />
					</div>
					<div *ngIf="isFlexAccessible" class="input-container">
						<div class="one-line" [ngClass]="{ 'upsell-no-access': !isFlexAccessible }">
							<div class="ca-icon-label">
								<img [src]="'./assets/Flex (Blue-Gray).svg' | resolveAsset" alt="Flex Period" />
								<label for="flex_period">Flex Period</label>
								<img class="upsell-stars" [src]="'./assets/Plus Plan (Gray).svg' | resolveAsset" alt="" />
							</div>
							<select formControlName="flex_period_id">
								<option [value]="0">None</option>
								<option *ngFor="let opt of flexPeriods" [value]="opt.id">
									{{ opt.name }}
								</option>
							</select>
						</div>
						<div *ngIf="flexMode" [@fadeInOut] class="ca-public">
							<div>
								Public Event
								<span>Students can sign up on their own.</span>
							</div>
							<app-toggle-input [controlSize]="'small'" [form]="activityForm" [controlName]="'public'" [delimiter]="false"></app-toggle-input>
						</div>
					</div>
					<div class="input-container">
						<div class="ca-icon-label">
							<img [src]="'./assets/Schedule (Blue-Gray).svg' | resolveAsset" alt="Schedule" />
							<label for="calendar">Date and time</label>
						</div>
						<div class="day-container">
							<div class="fake-change-date" *ngIf="!flexMode && !openCalendar" (click)="toggleCalendar()"></div>
							<div class="change-date" *ngIf="!flexMode">
								on&nbsp;<span class="date-span">{{ selectedDate.format('MMM D') }}</span>
							</div>
							<div class="ds-px-20 ds-py-10 calendar-wrapper" *ngIf="openCalendar" #calendar>
								<app-calendar-picker
									[width]="240"
									[min]="startDate"
									[showTime]="false"
									[selectedDates]="[selectedDate]"
									(onSelectDate)="calendarResult($event)"
									[showYear]="false">
								</app-calendar-picker>
							</div>
							<div class="fake-input" *ngIf="flexMode">on {{ dayTxt }}</div>
						</div>
						<div class="time-container" *ngIf="!flexMode">
							<sp-time-range-input
								[control]="timeControl"
								[additionalDuration]="5"
								(onFocusChange)="handleTimeRangeFocus($event)"></sp-time-range-input>
							<div *ngIf="roomTimeError" class="special-error" (click)="fixTimeError()">Update</div>
						</div>
						<span *ngIf="selectedFlexPeriod?.schedules.length > 1" style="margin-left: 2px">Mixed Times:</span>
						<div class="time-container" *ngFor="let schedule of selectedFlexPeriod?.schedules">
							<sp-time-range-input [control]="getTimeControl(schedule)"></sp-time-range-input>
						</div>
					</div>

					<div class="input-container one-line" style="padding: 6px 8.7px">
						<div class="ca-icon-label">
							<img [src]="'./assets/Repeat Outline (Blue-Gray).svg' | resolveAsset" alt="Repeats" />
							<label for="repeats">Repeats</label>
						</div>
						<select formControlName="repeats">
							<option *ngIf="!flexMode" value="never">Never</option>
							<option *ngIf="flexMode" value="flex_recurring">
								{{ selectedFlexPeriod ? 'Every ' + selectedFlexPeriod.name : 'Every Flex Period' }}
							</option>
							<option *ngIf="flexMode" value="scheduled">On Selected Days</option>
							<option *ngIf="!flexMode && !activityForm.value.declinable" value="daily">Daily</option>
							<option *ngIf="!flexMode && !activityForm.value.declinable" value="weekly">
								{{ dayOfWeekSelected ? 'Every ' + dayOfWeekSelected : 'Weekly' }}
							</option>
						</select>
					</div>
					<div class="input-container" *ngIf="!flexMode" [@fadeInOut]>
						<div class="ca-public">
							<div>
								Declinable
								<span>The student has the option to decline the pass.</span>
							</div>
							<app-toggle-input [controlSize]="'small'" [form]="activityForm" [controlName]="'declinable'" [delimiter]="false"></app-toggle-input>
						</div>
					</div>

					<div class="input-container" *ngIf="!flexMode && !activityForm.value.declinable">
						<div class="ca-icon-label">
							<img [src]="'./assets/Home (Blue-Gray).svg' | resolveAsset" alt="Origin Icon" />
							<label>From Where?</label>
						</div>
						<app-sp-search
							[style.z-index]="originZIndex"
							[placeholder]="'Select Location'"
							[searchTarget]="'rooms'"
							[width]="'100%'"
							[height]="'51px'"
							[fontSize]="'16px'"
							[color]="'#4E5D74'"
							[border]="'0.6px solid #E2E6EC'"
							[boxShadow]="'0px 2.3px 4.6px 0px rgba(16, 20, 24, 0.08)'"
							[cancelButton]="false"
							[focused]="false"
							[displaySelectedTitle]="true"
							[chipsMode]="false"
							[preventRemovingLast]="true"
							[isOriginSearch]="true"
							[selectedOptions]="originLocation"
							(focusEvent)="originZIndex = 10"
							(blurEvent)="originZIndex = 0"
							(onUpdate)="setOriginLocation($event)"></app-sp-search>
					</div>

					<div class="input-container">
						<div class="ca-icon-label">
							<img [src]="'./assets/Location (Blue-Gray).svg' | resolveAsset" alt="Destination Icon" />
							<label for="location">{{ flexMode ? 'Location' : 'To Where?' }}</label>
						</div>
						<app-sp-search
							#spSearchElement
							[style.z-index]="destinationZIndex"
							[style]="'z-index: 0;'"
							[placeholder]="'Select Location'"
							[searchTarget]="'rooms'"
							[width]="'100%'"
							[height]="'51px'"
							[fontSize]="'16px'"
							[color]="'#4E5D74'"
							[border]="'0.6px solid #E2E6EC'"
							[boxShadow]="'0px 2.3px 4.6px 0px rgba(16, 20, 24, 0.08)'"
							[cancelButton]="false"
							[focused]="false"
							[displaySelectedTitle]="true"
							[chipsMode]="false"
							[preventRemovingLast]="true"
							[selectedOptions]="selectedLocations"
							(onUpdate)="handleLocationSelection($event)"
							(focusEvent)="destinationZIndex = 10"
							(blurEvent)="destinationZIndex = 0"></app-sp-search>
					</div>
					<div *ngIf="!flexMode" [@fadeInOut] class="input-container">
						<div class="ca-icon-label">
							<img [src]="'./assets/Username (Blue-Gray).svg' | resolveAsset" alt="Students" />
							<label for="students">Select Students</label>
						</div>
						<app-round-input
							data-cy="search-inp"
							[style]="'z-index: 0;'"
							[backgroundColor]="'#FFF'"
							[height]="'51px'"
							[width]="'100%'"
							[borderRadius]="'93px'"
							[border]="'0.6px solid #E2E6EC'"
							[color]="'#4E5D74'"
							[fontSize]="'16px'"
							[padding]="'0 0 0 41px'"
							[boxShadow]="'0px 2.3px 4.6px 0px rgba(16, 20, 24, 0.08)'"
							(ontextupdate)="onSearch($event)"
							(focusEvent)="onInputFocus($event)"
							[placeholder]="'Search for students or student groups'"
							[selectReset$]="inputValue$"
							#searchInput="roundInputRef"
							#inputComponent>
						</app-round-input>

						<div class="selection-container" [hidden]="!isInputFocused && !searchInput.value">
							<app-groups-and-student-select
								class="ca-student-select"
								[@fadeInOut]
								[minHeight]="'48px'"
								[height]="'auto'"
								[groups]="filteredGroups"
								[students]="filteredStudents"
								(handleSelect)="handleStudentGroupAdd($event)">
							</app-groups-and-student-select>
							<div class="upload-students-button-container">
								<div class="upload-students-button" appCrossPointerEventTarget (pointerClickEvent)="showImportStudentList()">
									Upload a list of students
								</div>
							</div>
						</div>

						<div class="ca-selections">
							<div
								*ngFor="let entity of selectedGroupsAndStudents"
								class="ca-selection-detail"
								(mouseover)="setChipHovered(true)"
								(mouseleave)="setChipHovered(false)"
								[ngClass]="{ hovered: chipHovered }">
								<img
									[src]="
										entity.display_name
											? entity?.profile_picture
												? entity.profile_picture
												: ('./assets/Avatar (Gray-400).svg' | resolveAsset)
											: ('./assets/Group (Blue-Gray).svg' | resolveAsset)
									"
									alt="Students" />
								<span>{{ entity.display_name ? entity.display_name : entity.title }}</span>
								<img
									(mouseover)="setIconHovered(true)"
									(mouseleave)="setIconHovered(false)"
									[ngClass]="{ hovered: iconHovered }"
									class="close-icon"
									[src]="'./assets/close-x-tiny.svg' | resolveAsset"
									alt="Student Group"
									(click)="removeFromSelected(entity.id)" />
							</div>
						</div>
					</div>
					<div *ngIf="!flexMode" [@fadeInOut] class="input-container">
						<div class="ca-icon-label">
							<img [src]="'./assets/Message (Blue-Gray).svg' | resolveAsset" alt="message" />
							<label for="message">Message (optional)</label>
						</div>
						<textarea formControlName="issuer_message"></textarea>
					</div>
					<div *ngIf="flexMode" [@fadeInOut] class="input-container one-line">
						<label for="capacity">Max Capacity</label>
						<select formControlName="capacity">
							<option [value]="0">None</option>
							<option *ngFor="let i of [].constructor(50); let j = index" [value]="j + 1">{{ j + 1 }} students</option>
						</select>
					</div>
				</div>
			</div>
			<div class="ca-spinner" *ngIf="loading">
				<mat-spinner></mat-spinner>
			</div>
			<div class="big-btn-box">
				<button class="big-btn" type="submit" [disabled]="!isFormValid || loading">Schedule</button>
			</div>
		</form>
	</div>
</div>
<ng-template #refinerSurvey>
	<div class="refiner-wrapper">
		<div class="refiner-header">
			<div class="refiner-title">A new look is coming soon</div>
			<div class="refiner-body">What would you like to see changed?</div>
		</div>
		<app-gradient-button
			[customBackground]="'#F0F2F5'"
			[textColor]="'#7083A0'"
			[hoverColor]="''"
			[gradient]="''"
			[withShadow]="false"
			(click)="onRefinerClick()">
			Send Feedback
		</app-gradient-button>
	</div>
</ng-template>

<ng-template #emptyTopPadding>
	<div class="tw-pt-3"></div>
</ng-template>
