<div
	class="icon-button-container"
	ignore
	[style.width]="hasShadow ? size * 2 + 'px' : 'unset'"
	[style.height]="hasShadow ? size * 2 + 'px' : 'unset'"
	[style.background-color]="newIconButton ? newbgc : bgc"
	[@pressState]="down ? 'down' : 'up'"
	(mouseover)="hovered = true"
	(mouseleave)="hovered = false; down = false"
	appCrossPointerEventTarget
	(pointerDownEvent)="down = true"
	(pointerClickEvent)="down = false; onClick($event)"
	#iconButtonRef>
	<img [src]="src | resolveAsset" [attr.width]="size" [attr.height]="size" draggable="false" class="icon" alt="img" />
	<div class="notification" *ngIf="notificationBadge">{{ notificationCount }}</div>
</div>
