import { animate, state, style, transition, trigger } from '@angular/animations';
import { HostListener } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { BannerService } from '../../../services/banner.service';

@Component({
	selector: 'sp-bottom-banner',
	templateUrl: './bottom-banner.component.html',
	styleUrls: ['./bottom-banner.component.scss'],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translateY(-24px)',
				})
			),
			transition(':enter', [style({ transform: 'translateY(0)' }), animate(200)]),
			transition(':leave', [animate(300, style({ transform: 'translateY(40px)' }))]),
		]),
	],
})
export class BottomBannerComponent {
	message: string;
	image: string;
	buttonText: string;
	showBanner = false;

	constructor(private bannerService: BannerService) {
		this.bannerService.currentStatus.subscribe((status) => (this.showBanner = status));
		this.bannerService.currentMessage.subscribe((message) => (this.message = message));
		this.bannerService.currentImage.subscribe((image) => (this.image = image));
		this.bannerService.currentButtonText.subscribe((text) => (this.buttonText = text));
	}

	@HostListener('document:click', ['$event'])
	documentClick(event: MouseEvent) {
		this.callBack();
	}

	callBack() {
		if (this.showBanner) {
			this.showBanner = false;
			this.bannerService.changeStatus(false);
			this.showSuccessMessage();
			setTimeout(() => {
				this.showBanner = false;
				this.bannerService.changeStatus(false);
			}, 1500);
		}
	}

	private showSuccessMessage() {
		this.bannerService.changeMessage('Notification sounds are now enabled.');
		this.bannerService.changeButtonText('');
		this.bannerService.changeImage('./assets/Check (Gray).svg');
		this.bannerService.changeStatus(true);
	}
}
