import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'sp-input',
	templateUrl: './sp-input.component.html',
	styleUrls: ['./sp-input.component.scss'],
})
export class SpInputComponent implements OnInit, OnChanges, AfterViewInit {
	@ViewChild('formInput', { static: false }) formInput: ElementRef | undefined;

	@Input() group!: FormGroup;
	@Input() control = '';
	@Input() label = '';
	@Input() hideLabel = false;
	@Input() placeholder = '';
	@Input() description = '';
	@Input() maxLength = '';
	@Input() showErrorMessage = true;
	@Input() disabled = false;
	@Input() errorMessage = '';
	@Input() autoComplete = false;
	@Input() displayPlaceholder = true;
	@Input() height: number | null = 40;
	@Input() restrictInputPattern: RegExp | undefined;

	@Input() iconDisplay = false;
	@Input() icon = '';
	@Input() iconBGColor = '';
	@Input() isFocused = true;

	@Output() focus: EventEmitter<void> = new EventEmitter<void>();
	@Output() blur: EventEmitter<void> = new EventEmitter<void>();
	@Output() keydown: EventEmitter<void> = new EventEmitter<void>();

	handleSelection = false;
	placeholderDisplay = '';
	showError = false;

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		if (this.displayPlaceholder) {
			this.placeholderDisplay = this.placeholder;
		}
	}
	ngAfterViewInit(): void {
		if (this.disabled) {
			this.group.disable();
		}
		if (this.isFocused && !this.group.disabled) {
			this.setFocus();
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.displayPlaceholder?.currentValue) {
			this.placeholderDisplay = this.placeholder;
		} else if (!changes.displayPlaceholder?.currentValue && !this.placeholder) {
			this.placeholderDisplay = '';
		}
	}

	onFocus(isFocused: boolean): void {
		setTimeout(() => {
			this.isFocused = isFocused;
			this.setFocus();
		}, 50);
	}

	setFocus(): void {
		if (this.formInput?.nativeElement) {
			setTimeout(() => {
				this.renderer.selectRootElement(this.formInput?.nativeElement).focus();
				this.focus.emit();
			}, 50);
		}
	}

	onBlur(): void {
		setTimeout(() => {
			if (this.handleSelection == false) {
				this.isFocused = false;
				this.showError = true;
				this.blur.emit();
			}
		}, 90);
	}
}
