<!-- *************** NORMAL PASS TILES *************** -->
<ng-container *ngIf="forStaff && !isMiniCard && !presentational && !mock && pass">
	<div
		#passTileWrapper
		class="tile-wrapper {{ passCssClasses }}  {{ endedBgColorClass }}"
		(click)="clickWholeTile && onClick($event)"
		[style.justify-content]="'center'"
		[style.background]="backgroundGradient"
		[style.border]="border"
		[ngClass]="{ pressed: pressed, 'tw-cursor-pointer': clickable }"
		(mousedown)="clickable ? (pressed = true) : (pressed = false)"
		(mouseleave)="pressed = false">
		<div
			*ngIf="passStatus === 'overtime' && !isKioskMode && !disableReportButton"
			class="overtime-warning"
			(click)="passStatus === 'overtime' && !isKioskMode && openReport($event)">
			<img src="assets/icon-warning.svg" alt="warning icon" />
		</div>
		<div
			class="tile-title-container"
			(click)="clickable && onClick($event)"
			(mouseover)="displayStudentInfoTooltip()"
			(mouseleave)="hideStudentInfoTooltip()"
			cdkOverlayOrigin
			#overlayTrigger="cdkOverlayOrigin">
			<div [style.height]="'152px'">
				<div class="tile-title-inner">
					<div class="profile-image animate">
						<div
							*ngIf="pass.student?.profile_picture; else d"
							#avatar
							[ngStyle]="{
								background:
									passStatus === 'waitinline'
										? 'linear-gradient(0deg, rgba(16, 20, 24, 0.50) 0%, rgba(16, 20, 24, 0.50) 100%), url(\'' +
										  pass.student?.profile_picture +
										  '\') no-repeat left center/cover'
										: 'url(\'' + pass.student?.profile_picture + '\') no-repeat left center/cover',

								width: '88px',
								height: '88px'
							}"></div>
						<ng-template #d>
							<img width="88" height="88" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="Avatar" />
						</ng-template>
					</div>
					<div class="tile-content">
						<div notranslate class="name" *ngIf="tileName">{{ tileName }}</div>
						<div class="tile-destination" *ngIf="pass.destination?.title">
							{{ pass.destination.title }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="isBadgeVisible" class="badge">1</div>
		</div>
		<div
			class="status-overlay"
			[ngClass]="{ 'tw-cursor-pointer': clickable }"
			(mouseover)="!disableFooterHover && (footerHover = true)"
			(mouseleave)="footerHover = false"
			(click)="endPass.emit(pass)"></div>
		<div class="overlay" [style.background]="footerBackgroundGradient ? footerBackgroundGradient : null">
			<div class="status-wrapper">
				<div notranslate class="status" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && !footerHover">
					{{ timeLeft }} {{ passFooterText }}
				</div>
				<div notranslate class="status end-pass" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && footerHover">End Pass</div>

				<div notranslate class="status" *ngIf="passStatus === 'upcoming'">{{ passFooterText }}</div>
				<div notranslate class="status" *ngIf="passStatus === 'ended'">{{ passFooterText }}</div>
				<div notranslate class="status" *ngIf="passStatus === 'waitinline'" [ngClass]="{ animated: !pass.isReadyToStart() }">
					{{ tileContent }}{{ pass.isReadyToStart() ? '' : (waitInLineEllipse | async) }}
				</div>
			</div>

			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="overlayTrigger"
				[cdkConnectedOverlayOpen]="isOpenTooltip | async"
				[cdkConnectedOverlayWidth]="313"
				[cdkConnectedOverlayHeight]="325"
				(positionChange)="updateOverlayPosition($event)">
				<div
					class="student-passes"
					*ngIf="allowPopup && !isKioskMode"
					#studentPasses
					(mouseleave)="setAnimationTrigger('fadeOut'); hideStudentInfoTooltip()"
					(mouseover)="destroyAnimation$.next(); disableClose$.next()"
					[style.position]="'relative'"
					[style.top]="topView ? '-80px' : '40px'">
					<app-student-passes
						[openedFromSmallPassTile]="true"
						*ngIf="pass.student"
						[pass]="pass"
						[profile]="pass.student"
						[height]="300"
						[forMonitor]="forMonitor"
						[isResize]="false"
						(close)="isOpenTooltip.next(false)"
						(destroyClose)="destroyCloseQuickPreview = $event"></app-student-passes>
				</div>
			</ng-template>
		</div>
	</div>
</ng-container>

<!-- *************** MINI CARD IS DISPLAYED IN POPUPS *************** -->
<ng-container *ngIf="isMiniCard && !presentational && pass">
	<div
		*ngIf="!mock"
		class="tile-wrapper {{ passCssClasses }} {{ endedBgColorClass }}"
		[style.justify-content]="'unset'"
		[style.background]="backgroundGradient"
		[style.border]="border"
		[ngClass]="{ pressed: pressed, 'tw-cursor-pointer': clickable }"
		(mousedown)="clickable ? (pressed = true) : (pressed = false)"
		(mouseleave)="pressed = false"
		(click)="clickable && onClick($event)">
		<div
			class="tile-title-container"
			(mouseover)="displayStudentInfoTooltip()"
			(mouseleave)="hideStudentInfoTooltip()"
			cdkOverlayOrigin
			#overlayTrigger="cdkOverlayOrigin">
			<div [ngClass]="{ miniCard: isMiniCard }">
				<div class="tile-title-inner">
					<div class="tile-content">
						<div class="tile-destination" *ngIf="pass.destination?.title">
							{{ pass.destination.title }}
						</div>
						<div class="tile-date" *ngIf="pass.start_time">
							{{ pass.start_time | date : 'MMM d h:mm a' }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="isBadgeVisible" class="badge">1</div>
		</div>

		<div *ngIf="!isKioskMode" class="status-overlay" [ngClass]="{ 'tw-cursor-pointer': clickable }"></div>
		<div class="overlay" [style.background]="footerBackgroundGradient ? footerBackgroundGradient : null">
			<div class="status-wrapper" (mouseover)="!disableFooterHover && (footerHover = true)" (mouseleave)="footerHover = false">
				<div notranslate class="status" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && !footerHover">{{ timeLeft }}</div>
				<div notranslate class="status" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && footerHover" (click)="endPass.emit(pass)">
					End Pass
				</div>

				<div notranslate class="status" *ngIf="passStatus === 'upcoming'">{{ passFooterText }}</div>
				<div notranslate class="status" *ngIf="passStatus === 'ended'">{{ passFooterText }}</div>
			</div>

			<ng-template
				cdkConnectedOverlay
				[cdkConnectedOverlayOrigin]="overlayTrigger"
				[cdkConnectedOverlayOpen]="isOpenTooltip | async"
				[cdkConnectedOverlayWidth]="313"
				[cdkConnectedOverlayHeight]="325"
				(positionChange)="updateOverlayPosition($event)">
				<div
					class="student-passes"
					*ngIf="allowPopup && !isKioskMode"
					#studentPasses
					(mouseleave)="setAnimationTrigger('fadeOut'); hideStudentInfoTooltip()"
					(mouseover)="destroyAnimation$.next(); disableClose$.next()">
					<app-student-passes
						[openedFromSmallPassTile]="true"
						*ngIf="pass.student"
						[pass]="pass"
						[profile]="pass.student"
						[forMonitor]="forMonitor"
						[height]="300"
						[isResize]="false"
						(close)="isOpenTooltip.next(false)"
						(destroyClose)="destroyCloseQuickPreview = $event"></app-student-passes>
				</div>
			</ng-template>
		</div>
	</div>
</ng-container>

<!-- *************** PRESENTATIONAL IS DISPLAYED IN REPORT FORM POPUP *************** -->
<ng-container *ngIf="presentational && pass">
	<div
		class="tile-wrapper {{ passCssClasses }}  {{ endedBgColorClass }}"
		[style.justify-content]="'unset'"
		[style.background]="backgroundGradient"
		[style.border]="border"
		[ngClass]="{ pressed: pressed, 'tw-cursor-pointer': clickable }"
		(mousedown)="clickable ? (pressed = true) : (pressed = false)"
		(mouseleave)="pressed = false">
		<div class="tile-title-container">
			<div>
				<div class="tile-title-inner">
					<div class="profile-image animate">
						<div
							*ngIf="pass.student?.profile_picture; else d"
							#avatar
							[ngStyle]="{
								background:
									passStatus === 'waitinline'
										? 'linear-gradient(0deg, rgba(16, 20, 24, 0.50) 0%, rgba(16, 20, 24, 0.50) 100%), url(\'' +
										  pass.student?.profile_picture +
										  '\') no-repeat left center/cover'
										: 'url(\'' + pass.student?.profile_picture + '\') no-repeat left center/cover',

								width: '88px',
								height: '88px'
							}"></div>
						<ng-template #d>
							<img width="88" height="88" [src]="'./assets/Avatar Default.svg' | resolveAsset" alt="Avatar" />
						</ng-template>
					</div>
					<div class="tile-content">
						<div notranslate class="name">{{ tileName }}</div>
						<div class="tile-destination" *ngIf="pass.destination?.title">
							{{ pass.destination.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="status-overlay"
			[ngClass]="{ 'tw-cursor-pointer': clickable }"
			(mouseover)="!disableFooterHover && (footerHover = true)"
			(mouseleave)="footerHover = false"
			(click)="endPass.emit(pass)"></div>
		<div class="overlay" [style.background]="footerBackgroundGradient ? footerBackgroundGradient : null">
			<div class="status-wrapper">
				<div notranslate class="status" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && !footerHover">
					{{ timeLeft }} {{ passFooterText }}
				</div>
				<div notranslate class="status end-pass" *ngIf="(passStatus === 'active' || passStatus === 'overtime') && footerHover">End Pass</div>

				<div notranslate class="status" *ngIf="passStatus === 'upcoming'">{{ passFooterText }}</div>
				<div notranslate class="status" *ngIf="passStatus === 'ended'">{{ passFooterText }}</div>
				<div notranslate class="status" *ngIf="passStatus === 'waitinline'">{{ tileContent }}</div>
			</div>
		</div>
	</div>
</ng-container>

<!-- *************** INTRO SLIDES *************** -->
<ng-container *ngIf="mock">
	<div
		class="tile-wrapper"
		[ngClass]="{ 'tw-cursor-pointer': clickable }"
		[style.background]="'radial-gradient(circle at 73% 71%,' + mock.gradient + ')'"
		[style.justify-content]="'center'">
		<div class="tile-title tile-title-demo" [style.margin-top]="forStaff ? '13px' : '0px'">{{ mock.header }}</div>
		<div class="tile-subtitle">{{ mock.content }}</div>
		<ng-container *ngIf="!forStaff">
			<img
				[style.width]="'15px'"
				[style.height]="'15px'"
				*ngIf="mock.arrows === 'one'"
				[src]="'./assets/SP Arrow (White).svg' | resolveAsset"
				class="tile-round-trip" />
			<img
				[style.width]="'35px'"
				[style.height]="'15px'"
				*ngIf="mock.arrows === 'two'"
				[src]="'./assets/SP Arrow Double (White).svg' | resolveAsset"
				class="tile-one-way" />
		</ng-container>
		<div *ngIf="mock.badge" class="badge">1</div>
		<div *ngIf="forStaff" class="status-overlay" [ngClass]="{ 'tw-cursor-pointer': clickable }"></div>
		<div *ngIf="forStaff" class="status">{{ mock.footer }}</div>
	</div>
</ng-container>
