import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
	name: 'militaryTimeString',
})
export class MilitaryTimeStringPipe implements PipeTransform {
	transform(value: string): string {
		const time = moment(value, 'HH:mm');
		return time.format('h:mm A').toString();
	}
}
