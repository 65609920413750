import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { bumpIn } from '../../animations';

@Component({
	selector: 'app-user-profile-button',
	templateUrl: './user-profile-button.component.html',
	styleUrls: ['./user-profile-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [bumpIn],
})
export class UserProfileButtonComponent {
	@Input() userProfileImage: string;
	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
	hover = false;
	pressed = false;
	disabled = false;
}
