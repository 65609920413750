<div class="view-activity" *ngIf="flexPeriod || data?.activity?.state === 'deleted'">
	<div class="left-section">
		<div class="va-details">
			<div class="va-title">
				{{ data.activity.name
				}}<app-icon-button
					[srcString]="'Dots'"
					[size]="17"
					[hasShadow]="true"
					(clickEvent)="openDeleteActivity($event, data.activity.id)"></app-icon-button>
			</div>
			<div class="va-tags">
				<div>
					<img
						[id]="teacherIcon && 'va-teacher-icon'"
						[src]="teacherIcon ? teacherIcon : ('./assets/Teacher (Blue-Gray).svg' | resolveAsset)"
						alt="Teacher" />
					{{ teacherName }}
				</div>
				<div *ngIf="data.activity?.max_attendees > 0">
					<img [src]="'./assets/Room Icon (Blue-Gray).svg' | resolveAsset" alt="Room" />
					{{ data.activity?.max_attendees }} students max
				</div>
				<div>
					<ng-container *ngIf="data.activity?.state === 'flex_recurring'">
						<img [src]="'./assets/Repeat Outline (Blue-Gray).svg' | resolveAsset" alt="Repeat" />
						{{ 'Every ' + flexPeriod?.name }}
					</ng-container>

					<ng-container *ngIf="data.activity?.state === 'scheduled'">
						{{ 'Scheduled for ' + flexPeriod?.name }}
					</ng-container>

					<ng-container *ngIf="data.activity?.state === 'canceled' || data.activity.state === 'deleted'"> Activity cancelled </ng-container>
				</div>

				<div>
					<ng-container *ngIf="data.activity?.public_event"> Public activity </ng-container>

					<ng-container *ngIf="!data.activity?.public_event"> Private activity </ng-container>
				</div>
			</div>
			<div class="va-desc">
				{{ data.activity.description }}
			</div>
		</div>

		<div class="flex-feedback-button">
			<span> How do you like Flex? </span>
			<div>
				<img [src]="'./assets/Thumbs Up (Gray).svg' | resolveAsset" alt="Thumbs Up" (click)="openIntercom()" />
				<img [src]="'./assets/Thumbs Down (Gray).svg' | resolveAsset" alt="Thumbs Down" (click)="openIntercom()" />
			</div>
		</div>
	</div>

	<div class="va-right">
		<sp-upcoming
			*ngIf="!selectedInstance"
			[activity]="data.activity"
			[initialDay]="data?.day"
			[flexPeriod]="flexPeriod"
			(instanceSelected)="updateSelectedInstance($event)"
			(showDeleteInstance)="updateShowDeleteInstance($event)"></sp-upcoming>
		<sp-attendees
			*ngIf="selectedInstance"
			[selectedInstance]="selectedInstance"
			[maxNumAttending]="data.activity?.max_attendees"
			[showDeleteInstance]="showDeleteInstance"
			(instanceSelected)="updateSelectedInstance($event)"
			(openDeleteInstance)="openDeleteInstance($event)"></sp-attendees>
	</div>
</div>

<ng-template #deleteActivityDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove an activity, activity sessions that have students in them will still exist, but new sessions can't be created.
	</p>
</ng-template>
<ng-template #deleteInstanceDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove an activity session, student passes for the session will be cancelled.
	</p>
</ng-template>
