import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-twinkie-button',
	templateUrl: './twinkie-button.component.html',
	styleUrls: ['./twinkie-button.component.scss'],
})
export class TwinkieButtonComponent {
	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

	hovered: boolean;
	pressed: boolean;
}
