<div class="wrapper" style="position: relative">
	<div class="timer">
		<svg
			class="back-svg"
			preserveAspectRatio="xMidYMid meet"
			focusable="false"
			ng-reflect-ng-switch="false"
			viewBox="0 0 72.5 72.5"
			[style.height]="size + 'px'"
			[style.width]="size + 'px'">
			<circle
				cx="50%"
				cy="50%"
				r="32.5"
				class="ng-star-inserted"
				style="stroke-dashoffset: 6.28319px; stroke-dasharray: 210.496px; stroke-width: 10%"></circle>
		</svg>
		<mat-spinner class="example-margin" [diameter]="size" mode="determinate" [value]="remainingPercentage"></mat-spinner>
	</div>
	<span class="timer-number" *ngIf="showNumber">{{ countdown }}</span>
</div>
