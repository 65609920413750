import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { HttpService } from '../../services/http-service';
import { filter, mergeMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagService, FLAGS } from '../../services/feature-flag.service';
import { FileDownloadService } from '../../services/file-download-service';
import { UserService } from '../../services/user.service';
import { of } from 'rxjs';

declare const window;

@Component({
	selector: 'sp-year-in-review',
	templateUrl: './year-in-review.component.html',
	styleUrls: ['./year-in-review.component.scss'],
})
export class YearInReviewComponent implements OnInit {
	@Input()
	pdfUrl: URL;

	yearInReviewPdfUrl: string;

	title: string;

	constructor(
		private httpService: HttpService,
		private dialogService: MatDialog,
		private featureFlagService: FeatureFlagService,
		private adminService: AdminService,
		private fileDownloadService: FileDownloadService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.httpService.currentSchool$
			.pipe(
				filter((s) => !!s),
				take(1)
			)
			.subscribe((s) => {
				this.title = `${s.name}'s Year in Review Report`;
			});

		this.getYearInReviewData();
	}

	getYearInReviewData() {
		if (!this.featureFlagService.isFeatureEnabledV2(FLAGS.YearInReview)) {
			return;
		}

		this.adminService.getYearInReviewData().subscribe((resp) => {
			if (resp.pdf_url) {
				this.yearInReviewPdfUrl = resp.pdf_url;
				window.aptrinsic('track', 'year_in_review_pdf_available', { pdf_url: this.yearInReviewPdfUrl });
			}
		});
	}
}
