import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from '../services/http-service';
import { catchError, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BannerType } from '../models/School';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { MonetizationService } from '../services/monetization.service';
import { UserService } from '../services/user.service';
import { User } from '../models/User';
import { NavigationEnd, Router } from '@angular/router';
import _refiner from 'refiner-js';
@Component({
	selector: 'sp-trial-bar',
	templateUrl: './trial-bar.component.html',
	styleUrls: ['./trial-bar.component.scss'],
})
export class TrialBarComponent implements OnInit, OnDestroy {
	today = new Date();
	bannerType: any; // component is displayed when this is set
	dayString: 'day' | 'days';
	tenseString: 'do' | 'did';

	daysRemaining: number;
	duration: number;
	progressBar: number;
	activeAccount: boolean;
	suspensionDaysRemaining: number;
	suspendProgressBar: number;
	suspendedAccount: boolean;
	freeTrialEarlyAccessProgressBar: number;
	externalLink: string;
	customerSubscriptionSecondWarning: number;
	private user: User;
	isAdmin: boolean;
	isTeacher: boolean;
	isAdminPage: boolean;
	displayBanner = false;

	/**
	 * bannerClass is set in the format `banner-<bannerType>`. For example: `banner-free_trial` or
	 * `banner-payment_overdue`. The bannerType is set on the server set and is available on the school object.
	 * Keep this in mind if this file is being edited and the styles don't quite match up.
	 */
	bannerClass: string;

	private destroy$ = new Subject<void>();

	getDayString(dayCount: number): string {
		return dayCount === 1 ? 'day' : 'days';
	}

	externalPricingLink(): string {
		if (this.externalLink === '') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return window.Intercom('showNewMessage');
		} else {
			window.open(this.externalLink, '_blank');
		}
	}

	constructor(
		private http: HttpService,
		private monetizationService: MonetizationService,
		private userService: UserService,
		private router: Router
	) {}

	ngOnInit(): void {
		const routerEvent$ = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map((event: NavigationEnd) => event.url.includes('/admin')),
			startWith(this.router.url.includes('/admin'))
		);

		const school$ = this.http.currentSchoolSubject.asObservable().pipe(filter(Boolean));

		const userData$ = this.userService.effectiveUser$.pipe(
			filter(Boolean),
			tap((u) => {
				this.user = User.fromJSON(u);
				this.isAdmin = this.user.isAdmin();
				this.isTeacher = this.user.isTeacher();
			})
		);

		combineLatest([routerEvent$, userData$, school$])
			.pipe(
				takeUntil(this.destroy$),
				switchMap(([isAdminPage, user, school]) =>
					this.monetizationService.monetizationInfo$.pipe(
						map((renewal_info) => ({
							isAdminPage,
							school,
							renewal_info,
						})),
						catchError((error) => {
							console.error('Error fetching monetization info:', error);
							return of(null);
						})
					)
				)
			)
			.subscribe((result) => {
				if (!result) {
					return;
				}
				const { isAdminPage, school, renewal_info } = result;
				this.isAdminPage = isAdminPage;
				if (!school) {
					this.bannerType = null;
					return; // Early exit if no school data
				}

				this.bannerType = renewal_info.monetization_pipeline;
				this.daysRemaining = renewal_info.daysRemaining;
				this.duration = renewal_info.duration;
				this.progressBar = renewal_info.progressBar;
				this.activeAccount = renewal_info.activeAccount;
				this.suspensionDaysRemaining = renewal_info.suspensionDaysRemaining;
				this.suspendProgressBar = renewal_info.suspendProgressBar;
				this.suspendedAccount = renewal_info.suspendedAccount;
				this.freeTrialEarlyAccessProgressBar = renewal_info.freeTrialEarlyAccessProgressBar;
				this.externalLink = renewal_info.cta_link;
				this.customerSubscriptionSecondWarning = renewal_info.customerSubscriptionSecondWarning;

				this.bannerClass = this.getBannerClass();
				const date = new Date(school?.trial_end_date ?? school?.account_suspension_date);
				this.dayString = this.daysRemaining === 1 ? 'day' : 'days';
				this.tenseString = this.suspensionDaysRemaining < 1 ? 'did' : 'do';
				this.evaluateBannerDisplay();
			});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private getBannerClass() {
		return `banner-${this.bannerType}`;
	}

	// .helpContainer > .earlyAccessHelp is used for the Early Access Banner's "Need Help" button
	// .helpContainer > .help is used for the Subscription Expired Banner's "Need Help" button
	// .helpContainer > .overdueContact is used for the Overdue Payment Banner's "Contact Us" button
	// .helpContainer > .suspendedContact is used for the Suspended Banner's "Contact Us" button
	// These are important so that clicking on these buttons opens intercom on the right page.
	// Opening intercom on a specific page is set by CS team, not by eng.
	openIntercom() {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.Intercom('showNewMessage');
	}

	protected readonly BannerType = BannerType;

	private evaluateBannerDisplay() {
		if (this.isAdmin) {
			this.displayBanner = this.evaluateAdminDisplay();
		} else if (this.isTeacher) {
			this.displayBanner = this.evaluateTeacherDisplay();
		}
	}

	private evaluateAdminDisplay(): boolean {
		if (this.isAdminPage) {
			return (
				this.bannerType === 'early_access' ||
				(this.bannerType === 'customer' && this.daysRemaining <= this.customerSubscriptionSecondWarning) ||
				this.bannerType === 'payment_overdue' ||
				this.bannerType === 'churned' ||
				this.bannerType === 'free_trial'
			);
		} else {
			return this.bannerType === 'free_trial';
		}
	}

	private evaluateTeacherDisplay(): boolean {
		return this.isTeacher && this.bannerType === 'free_trial';
	}

	getBannerStyle() {
		if (this.suspendedAccount) {
			if (this.bannerType === 'customer' || this.bannerType === 'churned') {
				return { 'background-color': '#E32C66' };
			}
			if (this.bannerType === 'early_access' || this.bannerType === 'free_trial') {
				return { 'background-color': '#E48C15' };
			}
		} else if (this.bannerType === 'customer' && !this.activeAccount) {
			return { 'background-color': '#E48C15' };
		}
		return null; // Default case: no special styling
	}

	onFeedbackClick(): void {
		_refiner('showForm', 'c1641910-eaca-11ee-9d10-0b59fd390628', true);
	}
}
