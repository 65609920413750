<div class="about-container tw-bg-white tw-text-gray-500 tw-border tw-border-gray-300 tw-rounded-xl">
	<div
		*ngIf="data.classDetails?.display_name"
		class="about-item tw-border-b tw-border-gray-300 tw-p-5 tw-cursor-pointer"
		[ngClass]="{ 'hover:tw-bg-gray-100': userCanEdit }"
		(click)="userCanEdit && editType.emit('class_name')">
		<div class="tw-flex tw-pb-2">
			<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-flex-grow">Class Name</div>
			<div
				class="tw-text-green-500 tw-text-sm tw-font-medium"
				[ngClass]="{ 'hover:tw-underline': userCanEdit, 'tw-opacity-50': !userCanEdit }"
				customToolTip
				[position]="'left'"
				[showToolTip]="!userCanEdit"
				[contentTemplate]="onlyAdminEditTooltip">
				Edit
			</div>
		</div>
		<div class="tw-text-base tw-font-normal tw-flex-row">
			{{ data.classDetails?.display_name }}
		</div>
	</div>
	<div
		class="about-item tw-border-b tw-border-gray-300 tw-p-5 tw-cursor-pointer"
		[ngClass]="{ 'hover:tw-bg-gray-100': userCanEdit }"
		(click)="userCanEdit && editType.emit('room_name')">
		<div class="tw-flex tw-pb-2">
			<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-flex-grow">Room</div>
			<div
				class="tw-text-green-500 tw-text-sm tw-font-medium"
				[ngClass]="{ 'hover:tw-underline': userCanEdit, 'tw-opacity-50': !userCanEdit }"
				customToolTip
				[position]="'left'"
				[showToolTip]="!userCanEdit"
				[contentTemplate]="onlyAdminEditTooltip">
				Edit
			</div>
		</div>
		<div class="tw-text-base tw-font-normal tw-flex tw-items-center" *ngIf="data.classDetails?.pinnable?.icon">
			<div
				class="tw-rounded-full tw-w-5 tw-h-5 tw-text-center"
				[ngStyle]="{
					background: data.classDetails.pinnable.color_profile.solid_color
				}">
				<img class="tw-w-3 tw-mt-1" [src]="data.classDetails.pinnable.icon | resolveAsset" alt="" />
			</div>
			<p class="tw-my-0 tw-ml-2">{{ data.classDetails.room.title }}</p>
		</div>
	</div>
	<div
		class="about-item tw-border-b tw-border-gray-300 tw-p-5 tw-cursor-pointer"
		[ngClass]="{ 'hover:tw-bg-gray-100': userCanEdit }"
		(click)="userCanEdit && editType.emit('date_time')">
		<div class="tw-flex tw-pb-2">
			<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-flex-grow">Dates & Periods</div>
			<div
				class="tw-text-green-500 tw-text-sm tw-font-medium"
				[ngClass]="{ 'hover:tw-underline': userCanEdit, 'tw-opacity-50': !userCanEdit }"
				customToolTip
				[position]="'left'"
				[showToolTip]="!userCanEdit"
				[contentTemplate]="onlyAdminEditTooltip">
				Edit
			</div>
		</div>
		<div class="tw-text-base tw-font-normal tw-flex-row">
			{{ periodName ? periodName : 'No Dates & Periods' }}
			<span *ngIf="term"> ({{ term }})</span>
		</div>
	</div>
	<div
		class="about-item tw-p-5 tw-cursor-pointer"
		[ngClass]="{ 'hover:tw-bg-gray-100': userCanEdit }"
		(click)="userCanEdit && editType.emit('teachers')">
		<div class="tw-flex tw-pb-2">
			<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-flex-grow">Teachers</div>
			<div
				class="tw-text-green-500 tw-text-sm tw-font-medium"
				[ngClass]="{ 'hover:tw-underline': userCanEdit, 'tw-opacity-50': !userCanEdit }"
				customToolTip
				[position]="'left'"
				[showToolTip]="!userCanEdit"
				[contentTemplate]="onlyAdminEditTooltip">
				Edit
			</div>
		</div>
		<div class="tw-text-base tw-font-normal tw-flex tw-flex-wrap tw-gap-2">
			<ng-container *ngFor="let teacher of teachers">
				<app-selection [showIcon]="false" [selection]="teacher.user" [isUser]="true"> </app-selection>
			</ng-container>
		</div>
	</div>

	<ng-template #onlyAdminEditTooltip>
		<div class="ds-tooltip" style="max-width: 321px; text-align: left; padding: 12px">
			<p class="tw-w-max tw-my-0 tw-bg-purple-100 tw-py-1 tw-px-1.5 tw-text-purple-500 tw-text-xs tw-font-bold tw-uppercase tw-rounded">Admin Only</p>
			<p class="tw-text-sm tw-font-medium tw-mb-0 tw-mt-1.5">Only admins can edit classes.</p>
		</div>
	</ng-template>
</div>
