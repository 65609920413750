import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from '@sentry/types';
import { forkJoin, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Location } from '../../models/Location';
import { Pinnable } from '../../models/Pinnable';
import { DEFAULT_ROOM_ICON, DEFAULT_ROOM_ICON_BG_COLOR, HallPassesService } from '../../services/hall-passes.service';
import { LocationsService } from '../../services/locations.service';
import { UserService } from '../../services/user.service';
import { SpInputComponent } from '../../sp-input/sp-input.component';

type RoomListItem = {
	icon: string;
	iconColor: string;
	location: Location;
};

@Component({
	selector: 'sp-edit-room-form',
	templateUrl: './edit-room-form.component.html',
	styleUrls: ['./edit-room-form.component.scss'],
})
export class EditRoomFormComponent implements OnInit, OnChanges {
	@ViewChild('spInputComponent', { static: false }) spInputComponent!: SpInputComponent;
	@Input() editRoomForm!: FormGroup;
	@Input() initialRoom: Location | undefined;
	@Input() user!: User;
	@Input() showRoomList = true;
	@Input() displayEditRoomForm = false;
	@Input() disabled = false;
	@Input() description = 'The room the class occurs in. If class is held in multiple rooms, choose the more frequent room.';

	@Output() onSelect = new Subject<Location>();
	@Output() onRoomChanges = new Subject<boolean>();
	@Output() formValueChanges: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

	displayNoRoomResults = false;
	displayRoomListHeadings = false;
	allRoomsFiltered: RoomListItem[] = [];
	myRoomsFiltered: RoomListItem[] = [];
	roomFormHasChanges = false;
	selectedRoomIcon = '';
	selectedRoomIconBGColor = '';
	displayRoomList = false;
	private locations: Location[] = [];
	private allRooms: RoomListItem[] = [];
	private myRooms: RoomListItem[] = [];
	private destroy$ = new Subject<void>();
	private pinnables: Pinnable[] = [];

	constructor(private userService: UserService, private hallPassesService: HallPassesService, private locationsService: LocationsService) {}

	ngOnInit(): void {
		if (!this.initialRoom && this.showRoomList) {
			this.displayRoomList = true;
			this.displayRoomListHeadings = true;
		} else {
			this.displayRoomListHeadings = true;
		}

		// get user and then get school's rooms and figure out which rooms the user is assigned to
		this.hallPassesService.pinnables$
			.pipe(
				filter((pinnables) => !!pinnables.length),
				tap((pinnables) => {
					// store all the pinnables
					this.pinnables = pinnables.map((pin) => Pinnable.fromJSON(pin));

					// add pinnables that are locations to this.allRooms
					// we will later add locations that are in folders
					const allPinnableLocations = pinnables.filter((p) => p.type === 'location').map((p) => p.location);
					this.allRooms = this.mapLocationsToListItem(allPinnableLocations);
					this.locations = allPinnableLocations;
					// add pinnables that are locations teacher is assigned to to this.myRooms
					// we will later add locations that are in folders
					const myPinnableLocations = pinnables
						.filter(
							(p) =>
								p.type === 'location' &&
								p.location.teachers.some((t: User | number) => {
									if (typeof t === 'number') {
										return t === this.user.id;
									}
									return t.id === this.user.id;
								})
						)
						.map((p) => p.location);
					this.myRooms = this.mapLocationsToListItem(myPinnableLocations);
				}),
				switchMap((pinnables: Pinnable[]) => {
					// get locations for pinnables that are folders (category)
					const folders = pinnables.filter((p) => p.type === 'category');
					const locations = folders.map((p) => this.locationsService.getLocationsWithCategory(p.category));
					// Display select room even if there are no locations
					if (locations.length === 0) {
						this.displayEditRoomForm = true;
					}
					return forkJoin(locations).pipe(
						// we end up with an array containing arrays, so we have to rearrange the data a little
						map((results: Location[][]) => {
							return results.reduce((acc, _, i) => {
								const location = results[i];
								return acc.concat(location);
							});
						})
					);
				}),
				map((allRoomsInFolders: Location[]) => {
					this.locations = this.locations.concat(allRoomsInFolders);
					const myRoomsInFolders = allRoomsInFolders.filter((loc) =>
						loc.teachers.some((t: User | number) => {
							if (typeof t === 'number') {
								return t === this.user.id;
							}
							return t.id === this.user.id;
						})
					);
					// map data to arrays of RoomListItem for display in template
					const allRoomsInFoldersListItems = this.mapLocationsToListItem(allRoomsInFolders);
					const myRoomsInFoldersListItems = this.mapLocationsToListItem(myRoomsInFolders);
					return { allRoomsInFoldersListItems, myRoomsInFoldersListItems };
				}),
				tap(({ allRoomsInFoldersListItems, myRoomsInFoldersListItems }) => {
					this.allRooms = this.allRooms.concat(allRoomsInFoldersListItems);
					this.allRoomsFiltered = this.allRooms;
					if (this.initialRoom) {
						this.allRoomsFiltered = this.allRooms.filter((r) => r.location.id === this.initialRoom?.id);
					} else {
						this.myRooms = this.myRooms.concat(myRoomsInFoldersListItems);
						this.myRoomsFiltered = this.myRooms;
					}
					this.displayEditRoomForm = true;
				}),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				if (this.initialRoom) {
					this.getSelectedRoomIcon();
				}
			});

		this.editRoomForm.addControl('room', new FormControl(this.initialRoom?.title || ''));
		this.editRoomForm.addControl('roomId', new FormControl(this.initialRoom?.id || ''));

		this.editRoomForm.valueChanges
			.pipe(
				takeUntil(this.destroy$),
				tap<{ room: string; roomId: number }>((formChanges) => {
					// reset filtered room data
					this.allRoomsFiltered = this.allRooms;
					this.myRoomsFiltered = this.myRooms;

					// We need this check so that we can clear hide the room list and clear the input
					// at the same time.
					if (formChanges.room) {
						this.displayRoomList = true;
					}

					// filter all rooms based on form input
					this.allRoomsFiltered = this.filterRooms(this.allRooms, formChanges.room);
					this.myRoomsFiltered = this.filterRooms(this.myRooms, formChanges.room);

					this.displayNoRoomResults = !this.allRoomsFiltered.length;
					// enable primary form button
					if (this.roomFormHasChanges) {
						this.onRoomChanges.next(true);
						this.formValueChanges.emit(this.editRoomForm);
					}
				})
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.initialRoom) {
			this.initialRoom = changes.initialRoom.currentValue;
			if (changes.initialRoom.currentValue) {
				this.getSelectedRoomIcon();
				this.displayRoomListHeadings = false;
			} else {
				this.clearSelection();
			}
		}

		if (changes.showRoomList && changes.showRoomList.currentValue) {
			this.displayRoomList = true;
			this.displayRoomListHeadings = true;
		}
	}

	onFocus(focused: boolean): void {
		this.spInputComponent.onFocus(focused);
	}

	clearSelection() {
		this.selectedRoomIcon = '';
		this.selectedRoomIconBGColor = '';
		this.displayRoomList = false;

		if (this.editRoomForm.get('room')) {
			this.editRoomForm.get('room')?.setValue('');
		}
		if (this.editRoomForm.get('roomId')) {
			this.editRoomForm.get('roomId')?.setValue('');
		}
	}

	private filterRooms(roomListItems: RoomListItem[], formRoomValue: string | null): RoomListItem[] {
		if (formRoomValue === null) {
			return roomListItems;
		}

		return roomListItems.filter((room) => {
			return (
				room.location.title.toLowerCase().includes(formRoomValue.toLowerCase()) ||
				room.location.room.toLowerCase().includes(formRoomValue.toLowerCase())
			);
		});
	}

	private mapLocationsToListItem(locations: Location[]): RoomListItem[] {
		return locations.map((l) => {
			let pin = null;
			pin = this.pinnables.find((p) => p.type === 'category' && p.category === l.category);
			if (!pin) {
				pin = this.pinnables.find((p) => p.type === 'location' && p.location.id === l.id);
			}
			return {
				icon: pin ? pin.icon : '',
				iconColor: pin ? pin.color_profile.solid_color : '',
				location: l,
			};
		});
	}

	onSelectRoom(locationId: number): void {
		// set input value in form
		const roomTitle = this.allRooms.find((r) => r.location.id === locationId)?.location?.title || '';
		this.initialRoom = this.locations.find((l) => l.id === locationId);
		this.getSelectedRoomIcon();
		this.editRoomForm.get('room')?.setValue(roomTitle);
		this.editRoomForm.get('roomId')?.setValue(locationId);
		this.roomFormHasChanges = true;
		// enable save button
		this.onSelect.next(this.initialRoom);
		// hide room list
		this.displayRoomList = false;
	}

	private getSelectedRoomIcon(): void {
		const pin = this.pinnables.find((p) =>
			p.type === 'category' ? p.category === this.initialRoom?.category : p.location.id === this.initialRoom?.id
		);
		this.selectedRoomIcon = pin?.icon || DEFAULT_ROOM_ICON;
		this.selectedRoomIconBGColor = pin?.color_profile.solid_color || DEFAULT_ROOM_ICON_BG_COLOR;
	}

	onFocusRoomSelect(): void {
		this.selectedRoomIcon = '';
		this.selectedRoomIconBGColor = '';
	}

	onBlurRoomSelect(): void {
		if (this.editRoomForm.get('room')?.value && this.initialRoom) {
			this.getSelectedRoomIcon();
			if (this.initialRoom.id === this.editRoomForm.get('roomId')?.value) {
				this.editRoomForm.get('room')?.setValue(this.initialRoom.title);
				this.editRoomForm.get('roomId')?.setValue(this.initialRoom.id);
			}
		}
		this.displayRoomList = false;
	}
}
