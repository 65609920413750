import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Holiday, ScheduleForDatesResponse, ScheduleGroupBellSchedule } from '../models/Schedule';
import { HttpService } from './http-service';

export type AddHolidayBody = {
	name: string;
	start_date: Date;
	end_date: Date;
	schedule_id: number;
	return_date: Date;
	school_id?: number;
};

export type EditHolidayBody = {
	id: number;
	name: string;
	start_date: Date;
	end_date: Date;
	schedule_id: number;
	return_date: Date;
	schedule_groups?: ScheduleGroupBellSchedule[];
	school_id?: number;
};

export type DeleteHolidayBody = {
	id: number;
	schedule_id: number;
	return_date: Date;
	schedule_groups?: ScheduleGroupBellSchedule[];
};

@Injectable({
	providedIn: 'root',
})
export class HolidaysService {
	holidayList$: BehaviorSubject<Holiday[]> = new BehaviorSubject<Holiday[]>([]);

	constructor(private http: HttpService) {}

	getSchoolHolidays(schoolId: number): Observable<Holiday[]> {
		return this.http.post<Holiday[]>('v2/holidays/ListBySchoolId', { school_id: schoolId }, undefined, false);
	}

	addHoliday(body: AddHolidayBody): Observable<ScheduleForDatesResponse> {
		return this.http.post('v2/holidays/Insert', body, undefined, false);
	}

	editHoliday(body: EditHolidayBody): Observable<ScheduleForDatesResponse> {
		return this.http.post('v2/holidays/EditById', body, undefined, false);
	}

	deleteHoliday(
		holidayId: number,
		scheduleId: number,
		returnDate: Date,
		scheduleGroups: ScheduleGroupBellSchedule[]
	): Observable<ScheduleForDatesResponse> {
		const body: DeleteHolidayBody = {
			id: holidayId,
			schedule_id: scheduleId,
			return_date: returnDate,
			schedule_groups: scheduleGroups,
		};
		return this.http.post('v2/holidays/DeleteById', body, undefined, false);
	}
}
