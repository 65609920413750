import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-student-passes-info-card',
	templateUrl: './student-passes-info-card.component.html',
	styleUrls: ['./student-passes-info-card.component.scss'],
})
export class StudentPassesInfoCardComponent {
	@Input() number: string | number;
	@Input() title: string;
	@Input() loading: boolean;
	@Input() inSmallContainer = false;
}
