import { Pipe, PipeTransform } from '@angular/core';
import { HallPass } from '../models/HallPass';
import * as moment from 'moment';
import { ClassAgendaItem } from '../services/schedule.service';
import { SPClassWithUsers } from '../services/classes.service';

@Pipe({
	name: 'classUpcomingPasses',
})
export class ClassUpcomingPassesPipe implements PipeTransform {
	transform(passes: HallPass[], agenda: ClassAgendaItem, spClass: SPClassWithUsers, studentId: number): HallPass[] {
		// this filters hall passes to those within the period start and end times of the agenda
		// that belong to the student ID provided
		// if the student is in the class
		if (passes && passes.length) {
			const startTime = moment(agenda.start_time, 'HH:mm');
			const endTime = moment(agenda.end_time, 'HH:mm');
			return passes.filter((pass) => {
				const passStartTime = moment(pass.start_time);
				// todo - do we need to worry about pass end time?
				//const passEndTime = moment(pass.expiration_time);
				const classHasStudent = spClass.class_users.students.filter((s) => s.user.id === studentId);
				const passBelongsToStudent = pass.student.id === studentId;
				return passStartTime.isBetween(startTime, endTime) && classHasStudent.length && passBelongsToStudent;
			});
		}
		return [];
	}
}
