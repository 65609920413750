<div
	#tooltipParent
	*ngIf="student && student?.user.display_name"
	(mouseenter)="onHover()"
	(mouseleave)="offHover()"
	(click)="directToProfilePage()"
	class="tw-animate-fadein-200 tw-z-10 tw-relative tw-cursor-pointer tw-h-10 tw-flex tw-items-center tw-rounded-xl tw-py-1.5 tw-transition tw-duration-200 hover:tw-bg-gray-100">
	<img [src]="profileImage | resolveAsset" class="tw-rounded-full tw-h-full tw-w-10 tw-mr-3 tw-ml-3.5" />
	<div class="tw-text-navy-500 tw-font-medium tw-text-base">{{ student.user.display_name }}</div>
	<img
		*ngIf="isHovering"
		class="tw-animate-fadein-200 tw-w-3.5 tw-ml-auto tw-mr-2 tw-p-3.25 tw-rounded-full tw-bg-gray-100 hover:tw-bg-gray-150"
		src="./assets/Dots (Blue-Gray).svg"
		(click)="openOptions($event)"
		role="button" />
	<sp-clever-notice
		*ngIf="accountType === 'clever' && showCleverNotice"
		message="To remove students from this class, remove them in Clever."
		[classes]="'tw-left-80 ' + tooltipPositionClass"></sp-clever-notice>
</div>

<ng-template #deleteDialogBody><div class="tw-mb-7 tw-text-gray-500">They can always be added back later.</div></ng-template>
