import { Component, EventEmitter, Input, OnInit, Output, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { UNANIMATED_CONTAINER } from '../../consent-menu-overlay';
import { ConsentMenuComponent } from '../../consent-menu/consent-menu.component';
import { DialogFactoryService } from '../../dialog-factory.service';
import { DynamicDialogAction, DynamicDialogData } from '../../dynamic-dialog-modal/dynamic-dialog-modal.component';
import { DynamicDialogService } from '../../dynamic-dialog.service';
import { User } from '../../models/User';
import { ClassSyncedFrom, SPClassUser } from '../../services/classes.service';
import { Option } from '../../consent-menu/consent-menu.component';
import { DynamicStylingService, ElementProperties } from 'app/dynamic-styling.service';
import { TABBED_CONTENT_MODAL } from 'app/dynamic-styling-consts';

enum classStudentOptions {
	removeFromClass = 'Remove From Class',
}

@Component({
	selector: 'sp-class-student-list-item',
	templateUrl: 'class-student-list-item.component.html',
	styleUrls: ['./class-student-list-item.component.scss'],
})
export class ClassStudentListItemComponent implements OnInit {
	@Input() student: SPClassUser;
	@Input() accountType: ClassSyncedFrom;
	@Output() closeModal = new EventEmitter<boolean>();
	@Output() removeStudent = new EventEmitter<User>();

	@ViewChild('deleteDialogBody') deleteDialogBody: TemplateRef<HTMLElement>;
	@ViewChild('tooltipParent', { static: false, read: ElementRef }) tooltipParent: ElementRef;

	profileImage = './assets/Avatar Default.svg';
	isHovering = false;
	showCleverNotice = false;
	private optionsOpen = false;
	tooltipPositionClass = 'tw-top-110';
	private dialogService: DynamicDialogService;
	private TOOLTIP_HEIGHT = 214;

	constructor(
		private dialog: MatDialog,
		private router: Router,
		private dialogFactoryService: DialogFactoryService,
		private dynamicStylingService: DynamicStylingService
	) {}

	ngOnInit(): void {
		if (this.student?.user.profile_picture) {
			this.profileImage = this.student.user.profile_picture;
		}
	}

	onHover(): void {
		this.isHovering = true;
	}

	offHover(): void {
		if (!this.optionsOpen) {
			this.isHovering = false;
		}
	}

	private closeOptions(): void {
		this.optionsOpen = false;
		this.offHover();
	}

	openOptions(event: MouseEvent): void {
		event.stopPropagation();
		this.optionsOpen = true;
		const syncedClass = this.accountType === 'clever' || this.accountType === 'classlink' || this.accountType == 'google-classroom';
		const target = new ElementRef(event.currentTarget);
		const options = [
			{
				display: classStudentOptions.removeFromClass,
				color: syncedClass ? '#E32C6680' : '#E32C66',
				action: classStudentOptions.removeFromClass,
				cursor: syncedClass ? 'default' : 'pointer',
				hoverBackground: syncedClass ? 'white' : '',
				mouseOver: () => {
					if (syncedClass) {
						this.showCleverNotice = true;
						const tooltipParentElement = this.tooltipParent?.nativeElement;
						const parentModalProperties = this.dynamicStylingService.getElementProperties(TABBED_CONTENT_MODAL);
						if (parentModalProperties && tooltipParentElement) {
							this.adjustTooltipPosition(parentModalProperties, tooltipParentElement);
						}
					}
				},
				mouseLeave: () => {
					this.showCleverNotice = false;
				},
				disabled: syncedClass,
			},
		];

		UNANIMATED_CONTAINER.next(true);
		const optionsDialog = this.openConsentMenu(target, options);

		optionsDialog
			.afterClosed()
			.pipe(
				tap(() => {
					UNANIMATED_CONTAINER.next(false);
					this.closeOptions();
				}),
				filter((action) => action === classStudentOptions.removeFromClass)
			)
			.subscribe(() => {
				if (!syncedClass) {
					this.confirmAndRemoveStudent();
				}
			});
	}

	private openConsentMenu(target: ElementRef, options: Option[]): MatDialogRef<ConsentMenuComponent, unknown> {
		return this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true, offsetY: -20 },
		});
	}

	private confirmAndRemoveStudent(): void {
		const data: DynamicDialogData = {
			headerText: `Remove ${this.student.user.display_name} from this class?`,
			displayModalFooter: true,
			showCloseIcon: true,
			primaryButtonLabel: 'Remove',
			secondaryButtonLabel: 'Cancel',
			modalBody: this.deleteDialogBody,
			secondaryButtonGradientBackground: '#F0F2F5,#F0F2F5',
			secondaryButtonTextColor: '#7083A0',
			primaryButtonGradientBackground: '#E32C66',
			primaryButtonTextColor: 'white',
			classes: 'tw-min-h-0',
		};

		this.dialogService = this.dialogFactoryService.open(data, { panelClass: 'dynamic-dialog-modal-min', disableClose: false });
		this.dialogService.closed$.pipe(filter(Boolean)).subscribe({
			next: (selectedOption: DynamicDialogAction) => {
				if (selectedOption === 'primary') {
					this.removeStudent.emit(this.student.user);
				}
			},
		});
	}

	directToProfilePage(): void {
		this.router.navigateByUrl(`/main/student/${this.student.user.id}`);
		this.closeModal.emit(true);
	}

	private adjustTooltipPosition(modalProperties: ElementProperties, tooltipElement: HTMLElement): void {
		const modalBottom = modalProperties.boundingClientRect.bottom;
		const tooltipBottom = tooltipElement.getBoundingClientRect().bottom;
		if (tooltipBottom + this.TOOLTIP_HEIGHT > modalBottom) {
			this.tooltipPositionClass = 'tw--top-30';
		} else {
			this.tooltipPositionClass = 'tw-top-27.5';
		}
	}
}
