import * as React from 'react';
import { useIntervalAsyncFunction } from '../../../hooks';
import { HoverText } from '../../hover-text/hover-text';

interface IActivitiesHeaderProps {
	isLoading: boolean;
	numActivityInstances: number;
	downloadCsv: () => Promise<void>;
}

export function ActivitiesHeader({ isLoading, numActivityInstances, downloadCsv }: IActivitiesHeaderProps): React.ReactElement | null {
	const shouldRender = isLoading || numActivityInstances > 0;
	if (!shouldRender) {
		return null;
	}

	const handleClick = useIntervalAsyncFunction(downloadCsv, 1000);

	return (
		<div className="tw-text-gray-500 tw-text-base tw-font-medium tw-mt-7 tw-h-6">
			{!isLoading && (
				<div className="tw-animate-fadein-700 tw-flex tw-items-center">
					{numActivityInstances} Session Activities
					{numActivityInstances > 0 && (
						<div className="tw-h-4 tw-leading-none tw-ml-1.5 tw-cursor-pointer">
							<HoverText message="Download CSV">
								<img onClick={handleClick} src="./assets/Download circle (Blue-Gray).svg" alt="Download Activities CSV" className="tw-w-4" />
							</HoverText>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
