	<div class="logo-and-search">
	<app-smartpass-search></app-smartpass-search>
</div>
<div class="nav-buttons">
	<sp-nav-tabs></sp-nav-tabs>
</div>
<div class="action-buttons">
	<top-bar-schedule-pass></top-bar-schedule-pass>
	<top-bar-create-pass></top-bar-create-pass>
	<settings-nav-bar ></settings-nav-bar>
</div>

