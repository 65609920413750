import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type ClickFilter = { event: MouseEvent | TouchEvent; action: string };
@Component({
	selector: 'app-overview-container',
	templateUrl: './overview-container.component.html',
	styleUrls: ['./overview-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewContainerComponent {
	@Input() width = '100%';
	@Input() title: string;
	@Input() icon: string;
	@Input() showIcon: boolean;
	@Input() showRightIcon: boolean;

	@Output() clickFilter: EventEmitter<ClickFilter> = new EventEmitter<ClickFilter>();
}
