import { Component, Input, TemplateRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export type ToolTipDataType = {
	text: string;
	link: string;
	linkText: string;
	dataDetails: SafeHtml;
};

@Component({
	selector: 'sp-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
	@Input() text = '';
	@Input() link: string | undefined;
	@Input() linkText: string | undefined;
	@Input() contentTemplate: TemplateRef<any> | undefined; // use this if you need to style the content of the tooltip
}
