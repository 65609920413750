<div class="message-content">
	<div class="messages">
		<div *ngFor="let message of finalMessageList" [ngClass]="isSendByUser(message.user) ? 'send-by-user' : 'send-by-other'">
			<div class="message-div">
				<p class="sender-name" *ngIf="message.user.display_name">{{ message.user.display_name }}</p>
				<p class="chatting-message">{{ message.message }}</p>
			</div>
		</div>
	</div>
	<div class="input-wrapper" *ngIf="enableToSend">
		<input class="message-input" type="text" placeholder="Send message" [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" />
	</div>
</div>
