// app/drag-and-drop.directive.ts
import { Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
	selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
	private originalColor: string;

	@Output() filesDraggedAndDropped = new EventEmitter<FileList>();

	@HostBinding('style.background-color') private background;
	@HostBinding('style.opacity') private opacity = '1';

	constructor(private el: ElementRef, private renderer: Renderer2) {
		// Save original background color
		this.originalColor = el.nativeElement.style.backgroundColor;
	}

	oldBackground = '';

	// Dragover listener
	@HostListener('dragover', ['$event']) onDragOver(evt: any) {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '#E5F7F1');
		this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.8');
	}

	// Dragleave listener
	@HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
		evt.preventDefault();
		evt.stopPropagation();
		this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', this.originalColor);
		this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
	}

	// Drop listener
	@HostListener('drop', ['$event']) ondrop(evt: any) {
		evt.preventDefault();
		this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', this.originalColor);
		this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');

		const files = evt.dataTransfer.files as FileList;
		if (files.length > 0) {
			console.log(files);
			this.filesDraggedAndDropped.emit(files);
		}
	}
}
