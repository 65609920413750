<div class="password-wrapper">
	<div class="header-container">
		<div class="background-header" [attr.data-motion-opacity]="(frameMotion$ | async).direction"></div>
		<img
			class="back-button"
			*ngIf="!form.dirty"
			[src]="'./assets/Back Button (Blue-White).svg' | resolveAsset"
			appCrossPointerEventTarget
			(pointerClickEvent)="back.emit()" />
		<div class="back-button" appCrossPointerEventTarget (pointerClickEvent)="back.emit()" *ngIf="form.dirty">Cancel</div>
		<div class="password-header-text" [attr.data-motion-translate]="(frameMotion$ | async).direction">Change Password</div>
		<div class="save-button" *ngIf="isSaveButton">
			<app-gradient-button [width]="'85px'" [minHeight]="'30px'" [fontSize]="'14px'" [cursor]="'pointer'" (buttonClick)="updateUserPassword()"
				>Save
				<mat-spinner *ngIf="false" [color]="'accent'" [diameter]="30"></mat-spinner>
			</app-gradient-button>
		</div>
	</div>
	<div class="password-content" [attr.data-motion-translate]="(frameMotion$ | async).direction">
		<div class="password" *ngIf="showOldPasswordInput">
			<app-input
				[formGroup]="form"
				[controlName]="form.get('oldPassword')"
				[autocomplete]="'new-password'"
				[input_label]="'Current Password'"
				[input_type]="'password'"
				[showPasswordButton]="true"
				[width]="'250px'"></app-input>
			<div class="error" *ngIf="errorMessage$ | async as error">{{ error }}</div>
		</div>
		<div class="password">
			<app-input
				[formGroup]="form"
				[controlName]="form.get('newPassword')"
				[autocomplete]="'new-password'"
				[input_label]="'New Password'"
				[input_type]="'password'"
				[showPasswordButton]="true"
				[width]="'250px'"></app-input>
		</div>

		<div class="save-mobile" *ngIf="isSaveButton">
			<app-gradient-button [width]="'85px'" [minHeight]="'30px'" [fontSize]="'14px'" [cursor]="'pointer'" (buttonClick)="updateUserPassword()">
				Save
				<mat-spinner *ngIf="false" [color]="'accent'" [diameter]="30"></mat-spinner>
			</app-gradient-button>
		</div>
	</div>
</div>
