<div
	class="wrapper"
	[ngClass]="{ 'in-form-container': inFormContainer }"
	[style.left]="choice2 ? '78px' : '104px'"
	[style.cursor]="choice2 ? 'pointer' : 'default'">
	<div
		class="option"
		[ngClass]="{ selected: selectedChoice === choice1 }"
		[style.width]="choice2 ? '50%' : 'inherit'"
		(click)="updateTravelType(choice1)"
		[@Select]="{ value: selectedChoice === choice1 ? 'selected' : 'unselected', params: { background: '#ffffff' } }">
		{{ choice1 }}
	</div>
	<div
		*ngIf="choice2"
		[ngClass]="{ selected: selectedChoice === choice2 }"
		[style.width]="'50%'"
		class="option"
		(click)="updateTravelType(choice2)"
		[@Select]="{ value: selectedChoice === choice2 ? 'selected' : 'unselected', params: { background: '#ffffff' } }">
		{{ choice2 }}
	</div>
</div>
