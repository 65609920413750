import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdlinkTrampolineComponent } from './edlink-trampoline/edlink-trampoline.component';
import { RouterModule, Routes } from '@angular/router';
import { TrampolineRootComponent } from './trampoline-root.component';

const routes: Routes = [{ path: 'edlink', component: EdlinkTrampolineComponent }];

@NgModule({
	declarations: [EdlinkTrampolineComponent, TrampolineRootComponent],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TrampolineModule {}
