<div class="tw-flex tw-flex-col">
	<div
		class="tw-w-full tw-flex tw-items-center tw-justify-between tw-text-navy-500 tw-font-normal tw-text-base tw-p-3 tw-bg-gray-050 tw-rounded-lg tw-border tw-border-solid tw-border-gray-150 tw-box-border"
		[ngClass]="{ 'tw-cursor-pointer': schedules?.length > 0, 'tw-text-gray-500': !selected }"
		(click)="openDropdown($event)">
		<div>{{ selected ? selected.term.term_name : 'Select term' }}</div>
		<img class="tw-h-2" src="../../../../assets/Chevron%20Down%20(Blue-Gray).svg" />
	</div>
	<div *ngIf="selected?.subterms?.length > 0" class="tw-flex tw-flex-wrap tw-gap-x-1 tw-gap-y-1 tw-mt-2 tw-animate-fadein-200">
		<sp-add-remove-chip
			*ngFor="let subterm of selected.subterms; index as i"
			[text]="subterm.term_name"
			[isSelected]="selectedSubterms.get(subterm.id)"
			[start]="subterm.start_date"
			[end]="subterm.end_date"
			(onAddRemove)="handleChipToggle(subterm.id, $event)"></sp-add-remove-chip>
	</div>
</div>
