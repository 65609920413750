<div class="dummy-wrapper">
	<div class="ds-flex-start-center">
		<img [src]="'./assets/emptyStates/No Access.svg'" alt="" />
	</div>
	<div class="dummy">
		<div style="font-size: 17px">No Access</div>
		<p style="font-size: 14px">
			You do not have access to this tab. <br />
			Please contact your school administrator.
		</p>
	</div>
	<div class="go_back" (click)="goHome()">
		<app-back-button></app-back-button>
	</div>
</div>
