import * as React from 'react';

interface IModalProps {
	showModal: boolean;
	onCancel: () => void;
	className?: string;
	children?: React.ReactNode;
}

export function Modal({ showModal, onCancel, className = '', children = null }: IModalProps): React.ReactElement {
	const modalRef = React.useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			onCancel();
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (!showModal) {
		return null;
	}

	return (
		<div className="tw-fixed tw-inset-0 tw-flex tw-justify-center tw-items-center tw-z-50 tw-animate-fadein-300">
			<div ref={modalRef} className={className}>
				{children}
			</div>
		</div>
	);
}
