import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { WaitingInLinePass } from '../models/WaitInLine';
import { PositionPipe } from '../core/position.pipe';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../models/User';
@Component({
	selector: 'sp-student-wait-in-line-pass',
	templateUrl: './student-wait-in-line-pass.component.html',
	styleUrls: ['./student-wait-in-line-pass.component.scss'],
	providers: [PositionPipe],
})
export class StudentWaitInLinePassComponent implements OnInit, OnDestroy {
	@Input()
	pass: WaitingInLinePass;

	@Input() width = '460px';
	@Input() height = '112px';

	@Output() openPassModal: EventEmitter<WaitingInLinePass> = new EventEmitter<WaitingInLinePass>();

	user: User;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(private positionPipe: PositionPipe, private userService: UserService) {}

	ngOnInit(): void {
		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.user = User.fromJSON(user);
		});
	}

	waitInLineTitle: Observable<string> = timer(0, 750).pipe(
		takeUntil(this.destroy$),
		map((count) => {
			let title = `${this.positionPipe.transform((this.pass as WaitingInLinePass).line_position)} in Line${'.'.repeat(count % 4)}`;
			if (this.pass.isReadyToStart()) {
				title = 'Ready to Start!';
			}
			return title;
		})
	);

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
