import { Component } from '@angular/core';

/**
 * This component is a container for a set of sidenav tabs.
 *
 * It has two slots for content: one for pinned tabs and one for unpinned tabs.
 * The "pinned" and "unpinned" names used to indicate the scroll behavior of the
 * sections. Currently, the whole sidenav scrolls as one. If we add more sections
 * in the future, we may want to rename or extend this to take arbitrary slots.
 */
@Component({
	selector: 'sp-sidenav-menu',
	template: `
		<nav class="sidenav-menu__pinned"><ng-content select="[pinned]"></ng-content></nav>
		<mat-divider></mat-divider>
		<nav class="sidenav-menu__unpinned"><ng-content></ng-content></nav>
	`,
	styles: [
		`
			:host {
				display: block;
				height: 100%;
				width: 13.6875rem;
				overflow: auto;
				background: white;
				box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
			}

			nav {
				padding-inline: 0.9875rem;
				display: flex;
				flex-direction: column;
				gap: 12px;
			}

			.sidenav-menu__pinned {
				padding-block: 1.875rem 1.25rem;
			}
			.sidenav-menu__unpinned {
				padding-block: 1.25rem 1.875rem;
			}
		`,
	],
})
export class SidenavMenuComponent {}
