<div
	[ngClass]="{ wrapper: true, disabled: disabled, highlighted: highlighted }"
	role="button"
	#button
	(mouseenter)="!disabled && (button.hover = true)"
	(mouseleave)="!disabled && (button.hover = false); !disabled && (button.pressed = false)"
	appCrossPointerEventTarget
	(pointerDownEvent)="!disabled && (button.pressed = true)"
	(pointerCancelEvent)="!disabled && (button.pressed = false)"
	(pointerClickEvent)="!disabled && buttonClick.emit(button); !disabled && (button.pressed = false)"
	[style.background-color]="!disabled && background(button.hover || openedFilter, button.pressed)">
	<div class="content-button">
		<div [innerHTML]="title" class="title" [style.color]="filter ? '#1F195E' : '#7f879d'">{{ title }}</div>
		<ng-container [ngSwitch]="iconType">
			<ng-container *ngSwitchCase="'rect'">
				<img width="8" height="6" [src]="'./assets/Rect (' + (filter ? 'Navy' : 'Blue-Gray') + ').svg' | resolveAsset" alt="Rect" />
				<img
					*ngIf="filter && showClearIcon"
					appCrossPointerEventTarget
					(pointerClickEvent)="clear($event)"
					class="close-button"
					[src]="'./assets/Close.svg' | resolveAsset"
					alt="Close" />
			</ng-container>
			<img
				*ngSwitchCase="'circleDownload'"
				[src]="'./assets/Circle Download (' + (filter ? 'Navy' : 'Blue-Gray') + ').svg' | resolveAsset"
				alt="CD icon" />
			<img *ngSwitchCase="'custom'" [src]="customIconSrc" alt="Custom icon" />
		</ng-container>

		<ng-template #downloadButton>
			<img [src]="'./assets/Circle Download (' + (filter ? 'Navy' : 'Blue-Gray') + ').svg' | resolveAsset" alt="CD icon" />
		</ng-template>
	</div>
</div>
