<div
	class="pass-tile-large {{ status }}"
	(mousedown)="press()"
	(mouseleave)="mouseLeave()"
	[ngClass]="{ pressed: pressed, denied: status === 'declined', pending: status === 'pending' }"
	[ngStyle]="{ border: tileBorder }">
	<div class="room-icon" [ngStyle]="{ 'background-image': roomIconBackgroundStyle }">
		<img *ngIf="roomIconSafe" [src]="roomIconSafe" alt="room icon" />
	</div>
	<div class="middle-col">
		<div class="room-name">{{ roomName }}</div>
		<div class="pass-time">{{ passTime }}</div>
	</div>
</div>
