import { isObject } from 'lodash';

export function isEqualOmit(obj1, obj2, ignoreKeys = []) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		// Skip comparison for specified dynamic fields
		if (ignoreKeys.includes(key)) continue;

		const val1 = obj1[key];
		const val2 = obj2[key];
		const areObjects = isObject(val1) && isObject(val2);

		if ((areObjects && !isEqualOmit(val1, val2, ignoreKeys)) || (!areObjects && val1 !== val2)) {
			return false;
		}
	}

	return true;
}
