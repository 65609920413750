import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ColorProfile } from '../../../models/ColorProfile';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'sp-pass-tile-large',
	templateUrl: './pass-tile-large.component.html',
	styleUrls: ['./pass-tile-large.component.scss'],
	providers: [DatePipe],
})
export class PassTileLargeComponent implements OnInit {
	@Input() colorProfile: ColorProfile;
	@Input() isStaff: boolean;
	@Input() isStudent: boolean;
	@Input() studentProfilePicture: string;
	@Input() studentName: string;
	@Input() roomName: string;
	@Input() roomIcon: string;
	@Input() status: string;
	@Input() startTime: Date;
	@Input() endTime: Date;
	private waitingEllipse = '...';
	studentProfilePictureSafe: SafeStyle;
	roomIconSafe: SafeStyle;
	roomIconBackgroundStyle: SafeStyle;
	tileBorder: string;
	passTime: string;
	pressed = false;
	boxShadow = 'none';
	private destroy$: Subject<void> = new Subject<void>();

	constructor(private domSanitizer: DomSanitizer, private datePipe: DatePipe) {}

	ngOnInit(): void {
		timer(0, 750)
			.pipe(
				takeUntil(this.destroy$),
				tap((count) => {
					this.waitingEllipse = '.'.repeat(count % 4);
					count++;
				})
			)
			.subscribe();

		timer(0, 1000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.tileBorder = `1px solid #E2E6EC`;
				if (this.status === 'declined') {
					this.tileBorder = `1px solid ${this.colorProfile.solid_color}4D`;
					this.boxShadow = '0px 2px 4px 0px #10141814';
					this.passTime = 'Denied';
				} else if (this.status === 'pending') {
					this.tileBorder = `1px solid ${this.colorProfile.solid_color}4D`;
					this.boxShadow = '0px 2px 4px 0px #10141814';
					this.passTime = `Waiting for Approval${this.waitingEllipse}`;
				} else {
					const isToday = moment(this.startTime).isSame(new Date(), 'day');
					const isTomorrow = moment(this.startTime).isSame(new Date().setDate(new Date().getDate() + 1), 'day');
					const tomorrow = moment().add(1, 'day');
					if (isToday) {
						this.passTime = `Today, ${this.datePipe.transform(this.startTime, 'h:mm a')} - ${this.datePipe.transform(this.endTime, 'h:mm a')}`;
					}
					if (isTomorrow) {
						this.passTime = `Tomorrow, ${this.datePipe.transform(this.startTime, 'h:mm a')} - ${this.datePipe.transform(this.endTime, 'h:mm a')}`;
					} else if (moment(this.startTime).isAfter(tomorrow)) {
						// pass is upcoming after tomorrow
						this.passTime = `${moment(this.startTime).format('dddd')}, ${this.datePipe.transform(
							this.startTime,
							'h:mm a'
						)} - ${this.datePipe.transform(this.endTime, 'h:mm a')}`;
					}
				}

				if (this.studentProfilePicture) {
					this.studentProfilePictureSafe = this.domSanitizer.sanitize(SecurityContext.URL, this.studentProfilePicture);
				}
				if (this.roomIcon) {
					this.roomIconSafe = this.domSanitizer.sanitize(SecurityContext.URL, this.roomIcon);
				}
				if (this.colorProfile && this.colorProfile.gradient_color) {
					const colors = this.colorProfile.gradient_color.split(',');
					this.roomIconBackgroundStyle = 'radial-gradient(circle at 73% 71%, ' + colors[0] + ', ' + colors[1] + ')';
				}
			});
	}

	press(): void {
		this.pressed = true;
	}

	mouseLeave(): void {
		this.pressed = false;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
