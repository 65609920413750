import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EncountersState } from '../encounter-prevention-dialog.component';
import { EncounterPreventionService, ExclusionGroupWithOverrides } from 'app/services/encounter-prevention.service';
import { FeatureFlagService, FLAGS } from 'app/services/feature-flag.service';

@Component({
	selector: 'app-encounter-group-description',
	templateUrl: './encounter-group-description.component.html',
	styleUrls: ['./encounter-group-description.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EncounterGroupDescriptionComponent implements OnInit {
	@Input() state: EncountersState;
	@Input() group: ExclusionGroupWithOverrides;
	@Input() collapseGroups = false;

	numOverrides: number;

	constructor(private featureFlagService: FeatureFlagService, private ePService: EncounterPreventionService) {}

	ngOnInit(): void {
		this.group.prevented_encounters = this.group.prevented_encounters.reverse();
		if (this.featureFlagService.isFeatureEnabledV2(FLAGS.EncounterPreventionOverride)) {
			this.numOverrides = this.ePService.countNumOverrides(this.group);
		}
	}
}
