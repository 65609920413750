<div
	#globalContainer
	class="global-container"
	[ngClass]="{ 'ds-dark-theme': darkThemeEnabled, 'teacher-home-screen': teacherHomeScreen }"
	*ngIf="showUI | async"
	ondragstart="return false"
	[style.min-height]="!hideScroll ? '100%' : 'auto'">
	<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
	<div class="toasts-wrapper" *ngIf="(toasts$ | async).length">
		<div class="toasts">
			<div class="toast" *ngFor="let toast; index as i; of: toasts$ | async">
				<app-custom-toast [user]="user$ | async" [toast]="toast" [indexPosition]="i"></app-custom-toast>
			</div>
		</div>
	</div>

	<sp-outage-warning *ngIf="!isKioskMode && ((isAuthenticated && !!user && user.isStaff()) || !isAuthenticated)"></sp-outage-warning>

	<div class="top-bar-container" *ngIf="isAuthenticated && !isKioskMode" #topBarContainer>
		<sp-trial-bar *ngIf="isAdmin || isTeacher"></sp-trial-bar>

		<app-school-toggle-bar *ngIf="schools && schools.length > 1 && !hideSchoolToggleBar" [schools]="schools"> </app-school-toggle-bar>

		<sp-teacher-toggle-bar *ngIf="isAssistant"> </sp-teacher-toggle-bar>
	</div>

	<!--	Component building, use this section to build a component-->
	<!--		<div class="component-builder-main">-->
	<!--			<sp-starlight-dropdown-select-->
	<!--			[options]='selectOptions'-->
	<!--			[selected]='selectedOption'-->
	<!--			[placeholder]='placeholder'-->
	<!--			(onSelect)='selectOption($event)'-->
	<!--			></sp-starlight-dropdown-select>-->
	<!--		</div>-->

	<!--	Change this to false when using the above component building section-->
	<!-- Note that the routerOutletContainer is having a margin-top set in the component file not to be confused with the margin-top here -->
	<ng-container *ngIf="true">
		<div #routerOutletContainer class="router-outlet" [style.width]="mainContentWidth" [style.margin-top]="mainContentMarginTop">
			<router-outlet></router-outlet>
		</div>

		<div class="notification-backdrop" *ngIf="hasCustomBackdrop" [ngStyle]="customStyle"></div>
		<ng-container *ngIf="user$ | async as user">
			<app-help-center
				*ngIf="isAuthenticated && !!user && !user.isStudent() && !(helpCenter.isHelpCenterOpen | async) && !isKioskMode"
				(onClick)="openHelpCenter($event)"></app-help-center>
		</ng-container>
		<ng-container *ngIf="helpCenter.isHelpCenterOpen | async">
			<div class="help-center-content-wrapper" id="help-center-content">
				<div class="help-center-unsubscribe"></div>
				<div class="drag-divider" id="drag-divider"></div>
				<div class="close-icon-wrapper" (click)="closeHelpCenter()">
					<div class="circle">
						<img class="close-icon" [src]="'./assets/Close (Grey).svg' | resolveAsset" alt="" />
					</div>
				</div>
				<iframe #helpIframe class="help-centre-iframe" id="help-centre-iframe" src="https://www.smartpass.app/help-center" frameborder="0"></iframe>
				<div class="contact-wrapper">
					<div class="call-main">
						<div class="call-contant" (click)="openCallDialog($event)" *ngIf="isAdmin">
							<img class="pro-icon" *ngIf="!isUserHasPhoneAccess" [src]="'./assets/Plus Plan (Yellow).svg' | resolveAsset" alt="" />
							<img class="call-icon" [src]="'./assets/Call (Gray).svg' | resolveAsset" alt="" />
						</div>
					</div>
					<div class="live-chat open-intercom-btn" id="live-chat">
						<img class="chat-icon" [src]="'./assets/Chat (Blue-Gray).svg' | resolveAsset" alt="" />
						<p class="live-chat-text">Live Chat</p>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<sp-bottom-banner></sp-bottom-banner>

	<div #dialogContainer [@.disabled]="true"></div>
</div>
