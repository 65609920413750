import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timeAgo',
	pure: true,
})
export class TimeAgoPipe implements PipeTransform {
	// This pipe returns time ago in seconds.
	transform(value: any): number {
		return Math.floor((+new Date() - +new Date(value)) / 1000);
	}
}
