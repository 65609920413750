import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, skip, switchMap } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { StudentList } from '../../../../models/StudentList';
import { ROLES, User } from '../../../../models/User';
import { cloneDeep } from 'lodash';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImportStudentListComponent } from '../../../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { FeatureFlagService, FLAGS } from '../../../../services/feature-flag.service';

interface UpdateGroupPayload {
	[key: string]: any;
}

interface CreateGroupPayload {
	title: string;
	users: number[];
}

@Component({
	selector: 'app-groups-settings',
	templateUrl: './groups-settings.component.html',
	styleUrls: ['./groups-settings.component.scss'],
})
export class GroupsSettingsComponent implements OnInit {
	@ViewChild('studentSearchInput') studentSearchInput;
	@ViewChild('groupTitleInput') groupTitleInput;

	@Input() canEdit = true;
	@Input() editMode = false;
	@Input() createMode = false;

	updateData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	groups: StudentList[];
	filteredGroups: StudentList[];
	selectedGroup: StudentList | undefined;
	studentsInSelectedGroup: User[];
	filteredStudents: User[] | undefined;
	groupTitle: string;
	searchInProgress = false;
	importStudentListDialogRef: MatDialogRef<ImportStudentListComponent>;
	hasSchedulesFF = false;

	constructor(
		private userService: UserService,
		private featureFlags: FeatureFlagService,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: { groupToEdit: StudentList }
	) {}

	ngOnInit(): void {
		this.hasSchedulesFF = this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules);
		this.userService.getStudentGroupsRequest();
		this.updateData$
			.pipe(
				switchMap(() => {
					return this.userService.studentGroups$.pipe(
						map((groups: StudentList[]) => {
							this.groups = groups;
							this.filteredGroups = groups;
						})
					);
				})
			)
			.subscribe();
		this.handleGroupSelect(this.data?.groupToEdit);
	}

	handleGroupSelect(group: StudentList) {
		if (this.selectedGroup == group) {
			this.selectedGroup = undefined;
			this.studentsInSelectedGroup = [];
			this.filteredStudents = undefined;
			this.groupTitle = '';
		} else {
			this.selectedGroup = group;
			this.studentsInSelectedGroup = group.users;
			this.filteredStudents = group.users;
			this.groupTitle = group.title;
		}
		this.editMode = false;
		this.createMode = false;
	}

	handleStudentSelect(item) {
		if (this.studentsInSelectedGroup?.length) {
			this.studentsInSelectedGroup.push(item);
		} else {
			this.studentsInSelectedGroup = [item];
		}
		this.studentSearchInput.reset();
	}

	removeGroup() {
		this.userService.deleteStudentGroupRequest(this.selectedGroup?.id).subscribe();
		this.selectedGroup = undefined;
		this.filteredStudents = undefined;
		this.studentsInSelectedGroup = [];
	}

	editGroup() {
		this.editMode = true;
		this.filteredStudents = undefined;
	}

	updateGroup() {
		const studentsCopy = cloneDeep(this.studentsInSelectedGroup);
		const groupPayload: UpdateGroupPayload = this.selectedGroup as UpdateGroupPayload;
		groupPayload.users = studentsCopy.map((student) => student.id);
		groupPayload.title = this.groupTitleInput.nativeElement.value;
		this.userService.updateStudentGroupRequest(this.selectedGroup?.id, this.selectedGroup).subscribe((group) => {
			this.filteredStudents = group.users;
			this.editMode = false;
		});
	}

	createModeOn() {
		this.createMode = true;
		this.groupTitle = 'New Student Group';
		this.filteredStudents = undefined;
		this.studentsInSelectedGroup = [];
	}

	createModeOff() {
		this.createMode = false;
		this.groupTitle = '';
		this.filteredStudents = undefined;
		this.studentsInSelectedGroup = [];
	}

	createGroup() {
		const newGroupPayload: CreateGroupPayload = {
			title: this.groupTitleInput.nativeElement.value,
			users: this.studentsInSelectedGroup.map((student) => student.id),
		};

		this.userService.createStudentGroupRequest(newGroupPayload).subscribe((group) => {
			console.log(group);
			this.createMode = false;
		});
	}

	onGroupSearch(search: string) {
		if (search !== '') {
			this.filteredGroups = this.groups.filter((group) => {
				const titleMatches = group.title.toLowerCase().includes(search.toLowerCase());
				return titleMatches;
			});
		} else {
			this.filteredGroups = this.groups;
		}
	}

	onStudentInGroupSearch(search: string) {
		if (search !== '') {
			this.filteredStudents = this.studentsInSelectedGroup.filter((student) => {
				const nameMatches = student.display_name.toLowerCase().includes(search.toLowerCase());
				const emailMatches = student.primary_email.toLowerCase().includes(search.toLowerCase());
				return nameMatches || emailMatches;
			});
		} else {
			this.filteredStudents = this.studentsInSelectedGroup;
		}
	}

	onStudentSearch(search: string) {
		if (search !== '') {
			this.userService
				.getAccountsRoles(ROLES.Student, search, 100)
				?.pipe(
					skip(1),
					map((students) => {
						return students.filter((student) => !this.studentsInSelectedGroup.some((selectedStudent) => selectedStudent.id === student.id));
					})
				)
				.subscribe((filteredStudents) => (this.filteredStudents = filteredStudents));
			this.searchInProgress = true;
		} else {
			this.filteredStudents = undefined;
			this.searchInProgress = false;
		}
	}

	removeSelection(selection) {
		this.studentsInSelectedGroup = this.studentsInSelectedGroup.filter((s) => s !== selection);
	}

	showImportStudentList(): void {
		const dialogRef = (this.importStudentListDialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		}));

		dialogRef.afterClosed().subscribe((students: User[]) => {
			if (students && students.length > 0) {
				this.studentsInSelectedGroup = this.studentsInSelectedGroup.concat(students);
				//filter out duplicate imports by user id
				const idMap = new Map(this.studentsInSelectedGroup.map((user) => [user.id, user]));
				this.studentsInSelectedGroup = Array.from(idMap.values());
				this.studentSearchInput.reset();
				this.searchInProgress = false;
			}
		});
	}
}
