<!-- <ng-container *ngIf="emergencyActive"> -->
<div class="emergency-wrapper">
	<div class="content">
		<div class="header">
			<div class="img-wrapepr">
				<img class="icon" [src]="'./assets/Emergency (White).svg' | resolveAsset" />
			</div>
			<div class="banner-text">
				<p>Emergency Mode Activated</p>
			</div>
		</div>
		<div class="activate-info">
			<p>Activated at {{ getTime() }} by {{ emergencyData?.user?.display_name }}.</p>
		</div>
		<!-- <div class="button" *ngIf="!enableMessage">
            <app-gradient-button [size]="'editable'" [minHeight]="'64px'" [minWidth]="'234px'" [cornerRadius]="'15px'" [rightIcon]="'./assets/Arrow (Blue-Gray).svg'" [textColor]="'#7083A0'" [gradient]="'#FFFFFF, #FFFFFF'" [buttonDownColor]="'#00B476'" [cursor]="'pointer'"
                (buttonClick)="chatHistory()" [border]="'1.5px solid #D4D9E2'" [withShadow]="true">
                View Messages
            </app-gradient-button>
        </div> -->
		<ng-container>
			<div class="checkin-chat-wrapper">
				<div class="checkin-box">
					<div class="checkin-content">
						<div *ngIf="!checkedIn">
							<p class="checkin-title">Check Students In</p>
							<p class="question">Which students are in your room now?</p>
							<div class="search-bar">
								<img width="20" [src]="'./assets/Search Normal (Gray500).svg' | resolveAsset" />
								<input
									type="text"
									[(ngModel)]="searchText"
									(ngModelChange)="onSearchTextChanged()"
									placeholder="Search students with active passes" />
							</div>
							<div class="checkin-wrapper">
								<ul>
									<li *ngFor="let student of filteredList" (click)="toggleSelection(student)">
										<img
											class="student-profile"
											[src]="
												student?.checkin_user?.profile_picture
													? student?.checkin_user?.profile_picture
													: ('./assets/Avatar Default.svg' | resolveAsset)
											"
											alt="{{ student.checkin_user.display_name }}" />
										<div class="name-wrapper">
											<span class="name">{{ student.checkin_user.display_name }}</span>
											<span class="room-name" *ngIf="student.hall_pass?.destination">{{ student.hall_pass.destination.title }}</span>
										</div>
										<span class="select-icon">
											<div class="circle" [ngClass]="student.state == 'checked_in' ? 'checked-in' : 'not-checked-in'">
												<img
													*ngIf="student.state == 'checked_in'"
													class="check-icon"
													[src]="'./assets/Checkmark (White).svg' | resolveAsset"
													alt="{{ student.state }}" />
											</div>
										</span>
									</li>
									<li *ngIf="this.searchText != '' && filteredList.length === 0 && studentsList.length != 0" class="empty-result">
										No results for “{{ searchText }}”
									</li>
								</ul>
								<div class="checkin-btn">
									<app-gradient-button
										[disabled]="studentsList.length == 0"
										[size]="'editable'"
										[fontWeight]="'400'"
										[minHeight]="'48px'"
										[minWidth]="'428px'"
										[cornerRadius]="'8px'"
										[cursor]="'pointer'"
										[gradient]="'#00B476, #00B476'"
										(buttonClick)="checkIn()">
										Check In
									</app-gradient-button>
								</div>
							</div>
						</div>
						<div class="after-checkin-wrapper" *ngIf="checkedIn">
							<div style="margin: auto">
								<p class="info">{{ checkedInNumber }} Students Successfully Checked In</p>
								<div class="checkin-more-btn">
									<app-gradient-button
										[size]="'editable'"
										[fontWeight]="'400'"
										[minHeight]="'50px'"
										[minWidth]="'295px'"
										[cornerRadius]="'8px'"
										[cursor]="'pointer'"
										[textColor]="'#7083A0'"
										[gradient]="'#F0F2F5, #F0F2F5'"
										[buttonDownColor]="'#EAEDF1'"
										(buttonClick)="checkInMore()">
										Check In More Students
									</app-gradient-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="chat-box">
					<div class="chat-heading">
						<p>Latest Messages</p>
					</div>
					<div class="message-wrapper">
						<sp-chatting [messageList]="eventMessage" [enableToSend]="false"></sp-chatting>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="signout-button">
			<app-gradient-button
				[style.margin-left]="'15px'"
				[buttonDownColor]="'#00B476'"
				[cursor]="'pointer'"
				[gradient]="'#E32C66, #E32C66'"
				(buttonClick)="signOut()">
				Sign out
			</app-gradient-button>
		</div>
	</div>
</div>
<!-- </ng-container> -->
