<div
	class="collapsible-card-wrapper"
	[ngClass]="{
		collapsed: collapsed && !collapsedBubbleText,
		'collapsed-with-bubble': collapsed && collapsedBubbleText,
		'not-collapsible': !collapsible
	}">
	<div data-cy="sp-collapsible-card-header" class="title">
		<div (click)="titleLeftContainerClicked.emit(true)">
			<ng-content select="[sp-collapsible-card-header]" class="title-left-container"></ng-content>
		</div>

		<div class="title-right-container" (click)="toggleCollapsedState()">
			<div *ngIf="collapsible && collapsed && collapsedBubbleText" class="bubble-text">
				<span> {{ collapsedBubbleText }} </span>
			</div>
			<div class="arrow-container" *ngIf="collapsible">
				<img *ngIf="collapsed" class="arrow" [src]="'./assets/Chevron Down (Blue-Gray).svg' | resolveAsset" alt="Icon" />
				<img *ngIf="!collapsed" class="arrow" [src]="'./assets/Chevron Up (Blue-Gray).svg' | resolveAsset" alt="Icon" />
			</div>
		</div>
	</div>
	<div
		#cardContent
		data-cy="collapsible-card-content"
		class="collapsible-card-content tw-transition-height tw-duration-200 tw-ease-in-out"
		[style.overflow]="collapsed ? 'hidden' : 'visible'">
		<ng-content *ngIf="!collapsed" select="[collapsible-card-content]"></ng-content>
	</div>
</div>
