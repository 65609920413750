import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Type } from '@angular/core';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { DialogData, SettingAction, SettingsComponent } from '../settings/settings.component';
import { SideNavService } from '../services/side-nav.service';
import { ScreenService } from '../services/screen.service';
import { SpAppearanceComponent } from '../sp-appearance/sp-appearance.component';
import { FavoriteFormComponent } from '../favorite-form/favorite-form.component';
import { GroupsSettingsComponent } from '../create-hallpass-forms/main-hallpass--form/student-groups/groups-settings/groups-settings.component';
import { SpLanguageComponent } from '../sp-language/sp-language.component';
import { TeacherPinComponent } from '../teacher-pin/teacher-pin.component';
import { NotificationService } from '../services/notification-service';
import { NotificationFormComponent } from '../notification-form/notification-form.component';
import { MyProfileDialogComponent } from '../my-profile-dialog/my-profile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocationsService } from '../services/locations.service';
import { DeviceDetection } from '../device-detection.helper';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Subject } from 'rxjs';
import { IntroData } from '../ngrx/intros';
import { User } from '../models';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { IDCardResponse, IDCardService, IDCardDetails, IDCard } from '../services/IDCardService';
import { IdcardOverlayContainerComponent } from '../idcard-overlay-container/idcard-overlay-container.component';
import { QRBarcodeGeneratorService } from '../services/qrbarcode-generator.service';

@Component({
	selector: 'settings-nav-bar',
	templateUrl: './settings-nav-bar.component.html',
})
export class SettingsNavBarComponent implements OnInit, OnDestroy {
	private destroyed$ = new Subject<void>();

	isOpenSettings = false;
	showSwitchButton = false;
	introsData: IntroData | null = null;
	user: User | null = null;
	isStaff = true;
	isAssistant = false;
	profilePicturesEnabled = false;
	IDCardDetails: IDCardDetails | null = null;

	isSafari = DeviceDetection.isSafari();
	@Output() settingsClick: EventEmitter<DialogData> = new EventEmitter<DialogData>();

	constructor(
		private notifService: NotificationService,
		private locationService: LocationsService,
		private router: Router,
		private sideNavService: SideNavService,
		private screenService: ScreenService,
		private dialog: MatDialog,
		private userService: UserService,
		private idCardService: IDCardService,
		private qrBarcodeGenerator: QRBarcodeGeneratorService
	) {}

	ngOnInit(): void {
		this.subscribeToUserData();
		this.subscribeToIntrosData();
		this.subscribeToIDCardDetails();
		this.setProfilePicturesEnabled();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	private subscribeToUserData(): void {
		this.userService.userData.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
			this.user = user;
			this.isStaff = user.isStaff();
			this.isAssistant = user.isAssistant();
		});
	}

	private subscribeToIntrosData(): void {
		this.userService.introsData$
			.pipe(
				filter((res) => !!res),
				takeUntil(this.destroyed$)
			)
			.subscribe((data) => {
				this.introsData = data;
			});
	}

	private subscribeToIDCardDetails(): void {
		this.idCardService.getIDCardDetails().subscribe({
			next: (result: IDCardResponse) => {
				if (result?.results?.digital_id_card?.enabled) {
					this.IDCardDetails = result.results.digital_id_card;
				}
			},
		});
	}

	private setProfilePicturesEnabled(): void {
		this.profilePicturesEnabled = this.userService.getUserSchool().profile_pictures_enabled;
	}

	showOptions(event: PointerEvent) {
		if (!this.isOpenSettings) {
			if (this.screenService.isDeviceLargeExtra) {
				this.sideNavService.toggle$.next(true);
			}

			const target = new ElementRef(event.currentTarget);
			if (!this.screenService.isDeviceLargeExtra) {
				this.isOpenSettings = true;
				UNANIMATED_CONTAINER.next(true);
				const settingRef = this.dialog.open(SettingsComponent, {
					panelClass: ['calendar-dialog-container', 'animation'],
					backdropClass: 'invis-backdrop',
					data: { trigger: target, isSwitch: this.showSwitchButton, adjustForScroll: true },
				});

				settingRef.afterClosed().subscribe((action) => {
					UNANIMATED_CONTAINER.next(false);
					this.isOpenSettings = false;
					this.settingsAction(action);
				});
			}

			this.settingsClick.emit({
				trigger: target,
				isSwitch: this.showSwitchButton,
			});

			this.sideNavService.sideNavData$.next({
				trigger: target,
				isSwitch: this.showSwitchButton,
			});

			this.sideNavService.sideNavType$.next('left');
		}
	}

	private settingsAction(action: SettingAction): void {
		console.log(action);
		const actionMap: Record<SettingAction, () => void> = {
			[SettingAction.Appearance]: () => this.openDialog(SpAppearanceComponent),
			[SettingAction.Bug]: () => window.open('https://www.smartpass.app/bugreport'),
			[SettingAction.Classes]: () => this.router.navigate(['main', 'classes']),
			[SettingAction.Favorite]: () => this.openFavoriteForm(),
			[SettingAction.Groups]: () => this.openDialog(GroupsSettingsComponent, { panelClass: 'app-group-settings-container' }),
			[SettingAction.Language]: () => this.openDialog(SpLanguageComponent, { panelClass: 'sp-form-dialog' }),
			[SettingAction.IdCard]: () => this.openIDCard(),
			[SettingAction.MyPin]: () => this.openDialog(TeacherPinComponent, { panelClass: 'sp-form-dialog', backdropClass: 'custom-backdrop' }), // not working
			[SettingAction.Notifications]: () => this.handleNotificationAction(), // not working
			[SettingAction.Privacy]: () => window.open('https://www.smartpass.app/privacy?new=true'),
			[SettingAction.Profile]: () => this.openDialog(MyProfileDialogComponent, { panelClass: 'sp-form-dialog', width: '425px', height: '500px' }),
			[SettingAction.Refer]: () => this.handleReferAction(),
			[SettingAction.ReferSchool]: () => this.handleReferSchoolAction(),
			[SettingAction.SignOut]: () => this.router.navigate(['sign-out']),
			[SettingAction.Support]: () => this.handleSupportAction(),
			[SettingAction.SwagShop]: () => window.open('https://shop.smartpass.app/'),
			[SettingAction.Switch]: () => this.router.navigate(['admin', 'dashboard']),
			[SettingAction.Team]: () => window.open('https://smartpass.app/team.html'),
			[SettingAction.Term]: () => window.open('https://www.smartpass.app/terms'),
			[SettingAction.WishList]: () => window.open('https://wishlist.smartpass.app'),
			[SettingAction.About]: () => window.open('https://about.smartpass.app/about'),
			[SettingAction.GetStarted]: () => this.router.navigate(['admin', 'gettingstarted']),
		};
		actionMap[action]?.();
	}

	private openDialog<T>(component: Type<T>, config: MatDialogConfig = {}): void {
		this.dialog.open(component, {
			...config,
		});
	}

	private openFavoriteForm(): void {
		const favRef = this.dialog.open(FavoriteFormComponent, {
			panelClass: 'form-dialog-container',
			backdropClass: 'custom-backdrop',
			data: { isStaff: this.isStaff },
		});

		favRef.afterClosed().subscribe((data) => {
			this.locationService.updateFavoriteLocationsRequest(data);
		});
	}

	private handleNotificationAction(): void {
		if (!this.isSafari) {
			Notification.requestPermission();
		}

		if (NotificationService.hasSupport && NotificationService.canRequestPermission && !this.isSafari) {
			this.notifService.initNotifications(true).then(() => {
				this.openDialog(NotificationFormComponent, {
					panelClass: 'form-dialog-container',
					backdropClass: 'custom-backdrop',
					width: '462px',
					height: '600px',
				});
			});
		} else {
			this.openDialog(NotificationFormComponent, {
				panelClass: 'form-dialog-container',
				backdropClass: 'custom-backdrop',
				width: '462px',
				height: '600px',
			});
		}
	}

	private handleReferAction(): void {
		if (this.introsData?.referral_reminder.universal && !this.introsData.referral_reminder.universal.seen_version) {
			this.userService.updateIntrosRequest(this.introsData, 'universal', '1');
		}
		this.router.navigate(['main', 'refer_us']);
	}

	private handleReferSchoolAction(): void {
		this.userService.updateIntrosShareSmartpassRequest(this.introsData, 'universal', '1');
		if (this.user) {
			const emailParam = this.user.primary_email.includes('@spnx.local') ? '' : `?referrer_email=${encodeURIComponent(this.user.primary_email)}`;
			window.open(`https://www.smartpass.app/refer${emailParam}`);
		}
	}

	private handleSupportAction(): void {
		const supportUrl = this.isStaff || this.isAssistant ? 'https://smartpass.app/support' : 'https://smartpass.app/studentdocs';
		window.open(supportUrl);
	}

	private async openIDCard(): Promise<void> {
		if (!this.IDCardDetails || !this.user) {
			return;
		}
		const idCardData: IDCard = {
			backgroundColor: this.IDCardDetails.color,
			greadLevel: this.IDCardDetails.show_grade_levels ? this.user.grade_level : undefined,
			idNumberData: this.user.custom_id
				? {
						idNumber: this.user.custom_id,
						barcodeURL: await this.qrBarcodeGenerator.selectBarcodeType(this.IDCardDetails.barcode_type, this.user.custom_id),
				  }
				: { idNumber: '', barcodeURL: '' },
			barcodeType: this.IDCardDetails.barcode_type,
			backsideText: this.IDCardDetails.backside_text,
			logoURL: this.IDCardDetails.signed_url,
			profilePicture: this.user.profile_picture,
			schoolName: 'Demo School',
			userName: this.user?.display_name,
			userRole: 'Student',
			showCustomID: this.IDCardDetails.show_custom_ids,
			stickers: this.IDCardDetails.stickers,
		};

		this.dialog.open(IdcardOverlayContainerComponent, {
			panelClass: 'id-card-overlay-container',
			backdropClass: 'custom-bd',
			data: { idCardData: idCardData, isLoggedIn: true },
		});
	}
}
