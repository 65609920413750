import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectedHolidayData } from '../holidays.component';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Holiday } from 'app/models/Schedule';
import { DynamicDialogService } from 'app/dynamic-dialog.service';
import { Util } from '../../../../../Util';
import { CalendarToggleOptions } from '../../../../calendar-components/admin-calendar-toggle/admin-calendar-toggle.component';

type DateError = {
	error: boolean;
	message: string;
};
export type SelectedDate = {
	start: string;
	end: string;
};
@Component({
	selector: 'sp-add-edit-holidays',
	templateUrl: './add-edit-holidays.component.html',
	styleUrls: ['./add-edit-holidays.component.scss'],
})
export class AddEditHolidaysComponent implements OnInit {
	@ViewChild('toggleCalendarModalBody') toggleCalendarModalBodyTemplate: TemplateRef<any>;

	@Input() holidayData: SelectedHolidayData;
	@Input() addHolidayDialogService: DynamicDialogService;
	@Input() holidaysList: Holiday[] = [];
	@Input() selectedDate: SelectedDate = {
		start: new Date().toISOString().split('T')[0] + 'T00:00:00Z',
		end: new Date().toISOString().split('T')[0] + 'T00:00:00Z',
	};

	holidayForm = new FormGroup({});
	toggleResult: CalendarToggleOptions = 'Single Day';
	private dialogRef: any;
	isCalendarOpen = false;
	calendarSelectedDates: { start: moment.Moment; end: moment.Moment };
	dateError: DateError = { error: false, message: '' };
	private holidaysDateObjects: { start_date: string; end_date: string }[];
	private destroy$: Subject<void> = new Subject<void>();

	constructor(private dialog: MatDialog) {}

	ngOnInit(): void {
		this.exportHolidayDates();
		if (this.holidayData.type === 'edit') {
			this.selectedDate = {
				start: this.isDateInUTCFormat(this.holidayData.holiday.start_date)
					? this.holidayData.holiday.start_date
					: this.convertToUTCFormat(this.holidayData.holiday.start_date),
				end: this.isDateInUTCFormat(this.holidayData.holiday.end_date)
					? this.holidayData.holiday.end_date
					: this.convertToUTCFormat(this.holidayData.holiday.end_date),
			};
			if (moment(this.selectedDate.start).format('yyyy-MM-DD') !== moment(this.selectedDate.end).format('yyyy-MM-DD')) {
				this.toggleResult = 'Multiple Days';
			}
		} else {
			const overlapDates = Util.getOverlapDates(this.selectedDate.start, this.selectedDate.end, this.holidaysDateObjects);
			if (overlapDates.length > 0) {
				this.selectedDate.start = Util.findNextAvailableWeekdayAfterHoliday(this.selectedDate.start, this.holidaysDateObjects);
				this.selectedDate.end = this.selectedDate.start;
			}
			this.holidayData.holiday.start_date = this.selectedDate.start;
			this.holidayData.holiday.end_date = this.selectedDate.start;
		}
		this.holidayForm.addControl(
			'holidayName',
			new FormControl(this.holidayData.type === 'edit' ? this.holidayData.holiday.name : '', [Validators.required])
		);

		this.holidayForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
			this.addHolidayDialogService.setDialogConfig({ disablePrimaryButton: !res.holidayName || this.dateError.error });
			if (res) {
				this.holidayData.holiday.name = res.holidayName;
			}
		});
	}

	private isDateInUTCFormat(dateString: string): boolean {
		// Regular expression to check if the date string ends with 'Z'
		const utcFormatRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
		return utcFormatRegex.test(dateString);
	}

	convertToUTCFormat(dateString: string): string {
		// Append 'T00:00:00Z' to make sure the date is treated as UTC
		const dateInUTC = new Date(dateString + 'T00:00:00Z');
		// Convert to ISO string, which is in UTC format
		return dateInUTC.toISOString();
	}

	openCalendar(event): void {
		this.calendarSelectedDates = { start: moment.utc(this.selectedDate.start), end: moment.utc(this.selectedDate.end) };
		this.isCalendarOpen = true;

		const targetElement = event.currentTarget;
		const targetRect = (targetElement as HTMLElement).getBoundingClientRect();
		const dialogConfig = {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			position: {
				top: `${targetRect.top + 100}px`,
				left: `${targetRect.left + 100}px`,
			},
		};

		// Check if there's enough space at the bottom
		const windowHeight = window.innerHeight;
		const dialogHeight = 444; // Adjust this value as needed
		const spaceAtBottom = windowHeight - (targetRect.top + targetRect.height);
		// const spaceAtTop = targetRect.top;

		if (spaceAtBottom >= dialogHeight) {
			// Display dialog at the bottom
			dialogConfig.position.top = `${targetRect.top + targetRect.height}px`;
		} else {
			// Display dialog at the default position
			delete dialogConfig.position;
		}
		this.dialogRef = this.dialog.open(this.toggleCalendarModalBodyTemplate, dialogConfig);

		this.dialogRef.afterClosed().subscribe((result) => {
			this.isCalendarOpen = false;
			if (result) {
				const newStartDate = result.date.start;
				const newEndDate = result.date.end;

				this.selectedDate = { start: `${newStartDate.format('YYYY-MM-DD')}T00:00:00Z`, end: `${newEndDate.format('YYYY-MM-DD')}T00:00:00Z` };

				// Validate date range
				this.dateError = this.validateDateRange(newStartDate.format('YYYY-MM-DD'), newEndDate.format('YYYY-MM-DD'));
				if (!this.dateError.error) {
					this.holidayData.holiday.start_date = `${newStartDate.format('YYYY-MM-DD')}T00:00:00Z`;
					this.holidayData.holiday.end_date = `${newEndDate.format('YYYY-MM-DD')}T00:00:00Z`;
					this.addHolidayDialogService.setDialogConfig({ disablePrimaryButton: !this.holidayData?.holiday?.name });
				} else {
					this.addHolidayDialogService.setDialogConfig({ disablePrimaryButton: true });
				}
			}
		});
	}

	private validateDateRange(selectedStartDate: string, selectedEndDate: string): DateError {
		const endDate = new Date(selectedEndDate);
		const startDate = new Date(selectedStartDate);
		const overlapDates = Util.getOverlapDates(selectedStartDate, selectedEndDate, this.holidaysDateObjects);
		// Adjust the end date to include the entire day
		const adjustedEndDate = endDate;
		adjustedEndDate.setHours(23, 59, 59, 999);

		if (overlapDates.length === 0) {
			return { error: false, message: '' }; // No validation error
		}

		// Convert dates back to readable format for message
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		const readableDates = overlapDates.map((date) => {
			const utcDate = new Date(date);
			return `${months[utcDate.getUTCMonth()]} ${utcDate.getUTCDate()}`;
		});

		// Case 1: Single day holiday
		if (overlapDates.length === 1) {
			return { error: true, message: `Holiday already set for ${readableDates[0]}.` };
		}

		// Check if dates are consecutive
		let isConsecutive = true;
		for (let i = 1; i < overlapDates.length; i++) {
			if (new Date(overlapDates[i]).getTime() - new Date(overlapDates[i - 1]).getTime() > 24 * 60 * 60 * 1000) {
				isConsecutive = false;
				break;
			}
		}

		// Case 2: Entire range is duplicated
		// if (overlapDates.length === endDate.getDate() - startDate.getDate() + 1 && isConsecutive) {
		if (overlapDates.length === Math.round((adjustedEndDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1 && isConsecutive) {
			return { error: true, message: `Holiday already set for this range.` };
		}

		// Case 3: Multiple dates in range are duplicated, consecutive
		if (isConsecutive) {
			return { error: true, message: `Holiday already set for ${readableDates[0]} - ${readableDates[readableDates.length - 1]}.` };
		}

		// Case 4: Multiple dates in range are duplicated, not consecutive
		// Case 5: Single date in range is duplicated
		// For simplicity, both cases can be handled together by listing the dates
		console.log('readableDates', readableDates);
		const message = `Holiday already set for ${
			readableDates.length > 1 ? `${readableDates.slice(0, -1).join(', ')} and ${readableDates[readableDates.length - 1]}` : readableDates[0]
		}.`;

		return { error: true, message };
	}

	private exportHolidayDates(): void {
		// Convert holidays to Date objects and sort them
		this.holidaysDateObjects = this.holidaysList
			?.filter((holiday) => holiday.id !== this.holidayData.holiday.id)
			.map((holiday) => ({
				start_date: holiday.start_date,
				end_date: holiday.end_date,
			}))
			.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
	}

	calendarResult(dates): void {
		this.dialogRef.close({ date: dates, toggleResult: this.toggleResult });
	}

	saveOptions(options): void {
		this.toggleResult = options.toggleResult;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
