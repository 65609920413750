<div
	[ngClass]="_class"
	[style.transition]="'background-color .25s ease'"
	[style.background-color]="bgColor"
	(mouseenter)="hovered = true"
	(mouseleave)="hovered = false"
	(mousedown)="pressed = true"
	(mouseup)="pressed = false"
	(click)="click.emit()">
	<div class="ds-flex-center-center" [style.width]="'75px'">
		<img
			class="back-image"
			[src]="
				darkTheme.getIcon({
					iconName: 'Chevron Left',
					darkFill: allowDarkTheme ? 'White' : 'Blue-Gray',
					lightFill: 'Blue-Gray'
				}) | resolveAsset
			" />
		<span
			class="back-text"
			[style.color]="
				darkTheme.getColor({
					dark: allowDarkTheme ? '#FFFFFF' : '#7F879D',
					white: '#7F879D'
				})
			"
			>Back</span
		>
	</div>
</div>
