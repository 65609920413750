import { Component, OnInit, Input, SecurityContext, Output, EventEmitter, OnDestroy, TemplateRef } from '@angular/core';
import { I18nPluralPipe } from '@angular/common';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ColorProfile } from '../../../models/ColorProfile';
import { TimeAgoPipe } from '../../../pipes/time-ago.pipe';
import { HallPass } from '../../../models/HallPass';
import { getFormattedPassDateForRequest } from '../../../pass-tile/pass-display-util';
import { Request } from '../../../models/Request';
import { Util } from '../../../../Util';
import { of, Subject, throwError, timer } from 'rxjs';
import { catchError, concatMap, finalize, map, takeUntil } from 'rxjs/operators';
import { RequestsService } from '../../../services/requests.service';
import { ToastService } from '../../../services/toast.service';
import { HomepageService } from '../../../services/homepage.service';
import { ViewChild } from '@angular/core';
import { RequestCardComponent } from '../../../request-card/request-card.component';
import { HttpErrorResponse } from '@angular/common/http';
import { HallPassesService } from '../../../services/hall-passes.service';
import { EncounterPreventionService } from '../../../services/encounter-prevention.service';

@Component({
	selector: 'sp-pass-tile-medium-v2',
	templateUrl: './pass-tile-medium-v2.component.html',
	styleUrls: ['./pass-tile-medium-v2.component.scss'],
	providers: [I18nPluralPipe],
})
export class PassTileMediumV2Component implements OnInit, OnDestroy {
	@Input() colorProfile: ColorProfile;
	@Input() isStaff: boolean;
	@Input() isStudent: boolean;
	@Input() studentProfilePicture: string;
	@Input() studentName: string;
	@Input() roomName: string;
	@Input() roomIcon: string;
	@Input() startTime: Date;
	@Input() created: Date;
	@Input() status: string;
	@Input() sent: boolean;
	@Input() received: boolean;
	@Input() timerEvent: Subject<any>;
	@Input() pass: Request;
	@Output() endPass = new EventEmitter<HallPass>();
	@Output() openPass = new EventEmitter();

	@ViewChild(RequestCardComponent) requestCardComponent: RequestCardComponent;
	@ViewChild('PassLimitOverride') overriderBody: TemplateRef<any>;

	studentProfilePictureSafe: SafeStyle;
	roomIconSafe: SafeStyle;
	roomIconBackgroundStyle: SafeStyle;
	tileBackgroundStyle = 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(228, 140, 21, 0.3), rgba(228, 140, 21, 0.3))';
	tileBorder: string;
	hasBoxShadow = true;
	defaultBoxShadow = '0px 2px 4px 0px #10141814';
	boxShadow = '0px 2px 4px 0px #10141814';
	hoverBoxShadow = '0px 2px 8px 0px #10141833';
	pressed = false;
	isMissed: boolean;
	isDeclined: boolean;
	isFutureRequest: boolean;

	dateMessage: string; // this is the "time until" string
	sentOrReceivedTime: string; // this is the time the pass or request or invitation was created

	private destroy$: Subject<any> = new Subject<any>();

	constructor(
		private domSanitizer: DomSanitizer,
		private timeAgoPipe: TimeAgoPipe,
		private pluralPipe: I18nPluralPipe,
		private requestService: RequestsService,
		private toast: ToastService,
		public homePageService: HomepageService,
		private encounterService: EncounterPreventionService,
		private hallpassService: HallPassesService
	) {}

	ngOnInit(): void {
		timer(0, 10000)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				if (new Date(this.startTime).getTime() !== new Date(this.created).getTime()) {
					this.isFutureRequest = true;
					this.isMissed = Util.invalidDate(this.startTime);
				}
				if (this.status === 'declined') {
					this.isDeclined = true;
					this.isMissed = false;
				}
				this.getDateMessage();
				this.getSentOrReceivedTime();
				this.getTileBackground();
			});

		if (this.studentProfilePicture) {
			this.studentProfilePictureSafe = this.domSanitizer.sanitize(SecurityContext.URL, this.studentProfilePicture);
		}
		if (this.roomIcon) {
			this.roomIconSafe = this.domSanitizer.sanitize(SecurityContext.URL, this.roomIcon);
		}
		const colors = this.colorProfile.gradient_color.split(',');
		this.roomIconBackgroundStyle = 'radial-gradient(circle at 73% 71%, ' + colors[0] + ', ' + colors[1] + ')';
		this.tileBorder = `1px solid ${this.colorProfile.solid_color}4D`;
	}

	private getDateMessage(): void {
		if (this.status === 'pending' && this.sent && this.isStudent && !this.isMissed && !this.isFutureRequest) {
			this.dateMessage = 'Waiting for Approval...';
			return;
		}

		if (this.isDeclined && !this.isFutureRequest) {
			this.dateMessage = 'Denied';
			return;
		}

		// teacher pending for now does not show date
		if (this.status === 'pending' && this.isStaff && !this.isFutureRequest) {
			return;
		}

		this.dateMessage = getFormattedPassDateForRequest(this.startTime);
	}

	private getSentOrReceivedTime(): void {
		if (this.status === 'pending' && this.sent && this.isStudent && !this.isFutureRequest) {
			return;
		}
		if (this.isDeclined) {
			this.sentOrReceivedTime = null;
			return;
		}
		if (this.isMissed) {
			this.sentOrReceivedTime = null;
			return;
		}

		const timeStartInSeconds = this.timeAgoPipe.transform(this.created);
		const timeStartInMinutes = Math.floor(Math.abs(timeStartInSeconds) / 60);
		const timeStartInHours = Math.floor(timeStartInMinutes / 60);
		const timeStartInDays = Math.floor(timeStartInHours / 24);

		const hoursPluralMap = { '=0': 'hr', '=1': 'hr', other: 'hrs' };
		const minutesPluralMap = { '=0': 'min', '=1': 'min', other: 'mins' };
		const daysPluralMap = { '=0': 'day', '=1': 'day', other: 'days' };
		let sentOrReceivedStr = '';
		if (timeStartInDays === 1) {
			sentOrReceivedStr = `Yesterday`;
		} else if (timeStartInDays > 1) {
			sentOrReceivedStr = `${timeStartInDays} ${this.pluralPipe.transform(timeStartInDays, daysPluralMap)} ago`;
		} else if (timeStartInHours > 0) {
			sentOrReceivedStr = `${timeStartInHours} ${this.pluralPipe.transform(timeStartInHours, hoursPluralMap)} ago`;
		} else if (timeStartInMinutes > 0) {
			sentOrReceivedStr = `${timeStartInMinutes} ${this.pluralPipe.transform(timeStartInMinutes, minutesPluralMap)} ago`;
		} else {
			sentOrReceivedStr = 'Just now';
		}
		this.sentOrReceivedTime = sentOrReceivedStr;
	}

	private getTileBackground(): void {
		if (this.isMissed || this.status === 'declined') {
			this.tileBackgroundStyle = 'linear-gradient(0deg, #F0F2F5, #F0F2F5),linear-gradient(0deg, rgba(228, 140, 21, 0.3), rgba(228, 140, 21, 0.3))';
		}
	}

	openPassInFullScreen(): void {
		this.pressed = true;
		this.openPass.emit();
	}

	openPassWithMessage(event): void {
		event.stopPropagation();
		this.homePageService.openPassDialogFromSidebar({ pass: this.pass, fromClick: true, fromEndButton: false, pageNumber: 2 });
	}

	approvePass(event): void {
		event.stopPropagation();
		this.requestService
			.checkLimits({}, this.pass, this.overriderBody)
			.pipe(
				concatMap((httpBody) => {
					return this.requestService.acceptRequest(this.pass, httpBody);
				}),
				catchError((error) => {
					if (error instanceof HttpErrorResponse && error.error?.conflict_student_ids) {
						return this.encounterService.getExclusionGroups({ student: error.error?.conflict_student_ids }).pipe(
							map((exclusionGroups) => {
								this.hallpassService.showEncounterPreventionToast({
									exclusionPass: this.pass,
									isStaff: true,
									exclusionGroups,
								});
								return throwError(error);
							})
						);
					}

					return throwError(error);
				})
			)
			.subscribe({
				error: (err: Error) => {
					this.openErrorToast(err);
					console.error(err);
				},
			});
	}

	denyPass(event: MouseEvent): void {
		event.stopPropagation();
		this.requestService
			.denyRequest(this.pass.id, { message: 'No Message' })
			.pipe(
				catchError((error) => {
					this.openErrorToast(error);
					return of(error);
				})
			)
			.subscribe();
	}

	deletePass(event: MouseEvent): void {
		event.stopPropagation();
		this.requestService
			.cancelRequest(this.pass.id)
			.pipe(
				catchError((error) => {
					this.openErrorToast(error);
					return of(error);
				})
			)
			.subscribe();
	}

	openErrorToast(error) {
		this.toast.openToast(
			{
				title: 'Oh no! Something went wrong',
				subtitle: `Please try refreshing the page. If the issue keeps occurring, contact us at support@smartpass.app. (${error.status})`,
				type: 'error',
			},
			`${error.status}`
		);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
