<div class="card-container" *ngIf="invitation">
	<div
		class="pass-card-container {{ invitation.status }}"
		[ngClass]="{ 'for-staff': forStaff, 'in-form-container': inFormContainer }"
		*ngIf="invitation">
		<div
			class="pass-card-wrapper"
			[ngClass]="{ 'pass-border': isModal }"
			[@scalePassCards]="scaleCardTrigger$ | async"
			[style.background-image]="backgroundGradient"
			[style.border-color]="invitation.color_profile.solid_color"
			ondragstart="return false">
			<sp-pass-modal-header
				[pass]="invitation"
				[inFormContainer]="inFormContainer"
				[passStatus]="invitation.status"
				[forStaff]="forStaff"
				[isMissed]="isMissedInvitation"
				[invalidDate]="invalidDate"
				(denyInvitationEmit)="denyInvitation($event)"></sp-pass-modal-header>

			<!-- **************** Creating pass in form flow ******************-->
			<ng-container *ngIf="inFormContainer">
				<div class="center-block">
					<div class="duration-picker">
						<app-duration-picker
							*ngIf="forStaff && forInput"
							[forStaff]="forStaff"
							[plural]="durationPlural"
							(onChange)="selectedDuration = $event"
							(onSelectTravelType)="selectedTravelType = $event"
							[inFormContainer]="inFormContainer"
							[choices]="invitation.destination.travel_types"
							[maxDuration]="invitation.destination.max_allowed_time ? invitation.destination.max_allowed_time : 15"></app-duration-picker>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="isModal && !inFormContainer">
				<div class="center-block">
					<div class="center-block-inner">
						<!-- **************** STUDENT VIEW ******************-->
						<ng-container *ngIf="!forStaff">
							<!-- pending -->
							<ng-container *ngIf="invitation.status === 'pending' && !isMissedInvitation">
								<ng-container *ngTemplateOutlet="studentPendingTemplateCenter"></ng-container>
							</ng-container>
							<!-- missed -->
							<ng-container *ngIf="invitation.status === 'pending' && isMissedInvitation">
								<ng-container *ngTemplateOutlet="studentMissedTemplateCenter"></ng-container>
							</ng-container>
							<!-- declined -->
							<ng-container *ngIf="invitation.status === 'declined'">
								<ng-container *ngTemplateOutlet="studentDeclinedTemplateCenter"></ng-container>
							</ng-container>
							<!-- accepted -->
							<ng-container *ngIf="invitation.status === 'accepted'">
								<ng-container *ngTemplateOutlet="studentAcceptedTemplateCenter"></ng-container>
							</ng-container>
						</ng-container>

						<!-- **************** STAFF VIEW ******************-->
						<ng-container *ngIf="forStaff">
							<ng-container *ngIf="isMobile">
								<ng-container *ngTemplateOutlet="mobileTemplateCenter"></ng-container>
							</ng-container>

							<ng-container *ngIf="!isMobile && invitation.status === 'pending' && !isMissedInvitation">
								<ng-container *ngTemplateOutlet="teacherPendingTemplateCenter"></ng-container>
							</ng-container>

							<ng-container *ngIf="!isMobile && invitation.status === 'pending' && isMissedInvitation">
								<ng-container *ngTemplateOutlet="teacherMissedTemplateCenter"></ng-container>
							</ng-container>

							<ng-container *ngIf="!isMobile && invitation.status === 'accepted'">
								<ng-container *ngTemplateOutlet="teacherAcceptedTemplateCenter"></ng-container>
							</ng-container>

							<ng-container *ngIf="!isMobile && invitation.status === 'declined'">
								<ng-container *ngTemplateOutlet="teacherDeclinedTemplateCenter"></ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</ng-container>
			<div
				class="bottom-block {{ invitation.status }}"
				[style.background]="footerBackground"
				[style.border-bottom-left-radius]="
					(forStaff && (invitation.status === 'pending' || invitation.status === 'accepted')) || invitation.status === 'declined' ? '20px' : ''
				"
				[style.border-bottom-right-radius]="
					(forStaff && (invitation.status === 'pending' || invitation.status === 'accepted')) || invitation.status === 'declined' ? '20px' : ''
				">
				<!-- **************** STUDENT VIEW ******************-->
				<ng-container *ngIf="!forStaff">
					<!-- pending -->
					<ng-container *ngIf="invitation.status === 'pending' && !isMissedInvitation">
						<ng-container *ngTemplateOutlet="studentPendingTemplateBottom"></ng-container>
					</ng-container>
					<!-- missed -->
					<ng-container *ngIf="invitation.status === 'pending' && isMissedInvitation">
						<ng-container *ngTemplateOutlet="studentMissedTemplateBottom"></ng-container>
					</ng-container>
					<!-- declined -->
					<ng-container *ngIf="invitation.status === 'declined'">
						<ng-container *ngTemplateOutlet="studentDeclinedTemplateBottom"></ng-container>
					</ng-container>
					<!-- accepted -->
					<ng-container *ngIf="invitation.status === 'accepted'">
						<ng-container *ngTemplateOutlet="studentAcceptedTemplateBottom"></ng-container>
					</ng-container>
				</ng-container>

				<!-- **************** STAFF VIEW ******************-->
				<ng-container *ngIf="forStaff">
					<ng-container *ngIf="isMobile">
						<ng-container *ngTemplateOutlet="mobileTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="!isMobile && invitation.status === 'pending' && !isMissedInvitation">
						<ng-container *ngTemplateOutlet="teacherPendingTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="!isMobile && invitation.status === 'pending' && isMissedInvitation">
						<ng-container *ngTemplateOutlet="teacherMissedTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="!isMobile && invitation.status === 'accepted'">
						<ng-container *ngTemplateOutlet="teacherAcceptedTemplateBottom"></ng-container>
					</ng-container>

					<ng-container *ngIf="!isMobile && invitation.status === 'declined'">
						<ng-container *ngTemplateOutlet="teacherDeclinedTemplateBottom"></ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="student-footer-info" *ngIf="forStaff && isModal">
			<app-student-passes [pass]="invitation" [profile]="invitation.student"></app-student-passes>
		</div>
	</div>
</div>

<ng-template #studentPendingTemplateCenter>
	<app-pager [arrowPosition]="'-24px'" [isStaff]="true" [activeDotColor]="invitation.color_profile.solid_color">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="280">
					<div>{{ invitation.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper">
				<ng-container *ngTemplateOutlet="messagesForStudent"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>

<ng-template #studentMissedTemplateCenter>
	<div class="missed-or-declined-wrapper timer-wrapper">
		<sp-circle-countdown [topText]="'Request Missed'" [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="316">
			<div>{{ passDuration }} min</div>
		</sp-circle-countdown>
	</div>
</ng-template>

<ng-template #studentDeclinedTemplateCenter>
	<app-pager [page]="1" [arrowPosition]="'-20px'" [inactiveDotColor]="'#E2E6EC'" [activeDotColor]="'#7083A0'">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<app-travel-view
				[textColor]="'#7083a0'"
				[iconColor]="'#000000'"
				[forStaff]="forStaff"
				[height]="'170px'"
				[pass]="invitation"
				(locationSelected)="setLocation($event)"></app-travel-view>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="message">
				<div>
					<div class="message-label">Message from {{ invitation.issuer?.display_name }}:</div>
					<div class="message-copy">{{ invitation.issuer_message === '' ? 'No message' : invitation.issuer_message }}</div>
				</div>
			</div>
		</div>
	</app-pager>
</ng-template>
<ng-template #studentAcceptedTemplateCenter>
	<app-pager
		[page]="1"
		[arrowPosition]="'-20px'"
		[inactiveDotColor]="'#FFFFFF40'"
		[activeDotColor]="'#FFF'"
		[leftArrowSrc]="'icon-white-left.svg'"
		[rightArrowSrc]="'icon-white-right.svg'">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [diameter]="280" [ringColor]="'#F0F2F5'">
					<div>{{ invitation.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="message">
				<div>
					<div class="message-label">Message from {{ invitation.issuer?.display_name }}:</div>
					<div class="message-copy">{{ invitation.issuer_message === '' ? 'No message' : invitation.issuer_message }}</div>
				</div>
			</div>
		</div>
	</app-pager>
</ng-template>

<!-- pending invitation sent to student (teacher view) -->
<ng-template #teacherPendingTemplateCenter>
	<app-pager [arrowPosition]="'-87px'" [isStaff]="true" [activeDotColor]="invitation.color_profile.solid_color">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [diameter]="280" [ringColor]="'#F0F2F5'">
					<div>{{ invitation.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper">
				<ng-container *ngTemplateOutlet="messagesForTeacher"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>

<!-- accepted invitation sent to student (teacher view) -->
<ng-template #teacherAcceptedTemplateCenter>
	<app-pager
		[page]="1"
		[arrowPosition]="'-20px'"
		[inactiveDotColor]="'#FFFFFF40'"
		[activeDotColor]="'#FFF'"
		[leftArrowSrc]="'icon-white-left.svg'"
		[rightArrowSrc]="'icon-white-right.svg'">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="timer-wrapper">
				<sp-circle-countdown [progress]="0" [diameter]="280">
					<div>{{ invitation.duration / 60 }} min</div>
				</sp-circle-countdown>
			</div>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="message">
				<div>
					<div class="message-label">Message from {{ invitation.issuer?.display_name }}:</div>
					<div class="message-copy">{{ invitation.issuer_message === '' ? 'No message' : invitation.issuer_message }}</div>
				</div>
			</div>
		</div>
	</app-pager>
</ng-template>

<!-- declined invitation sent to student (teacher view) -->
<ng-template #teacherDeclinedTemplateCenter>
	<app-pager [page]="1" [arrowPosition]="'-20px'" [inactiveDotColor]="'#E2E6EC'" [activeDotColor]="'#7083A0'">
		<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<app-travel-view
				[textColor]="'#7083a0'"
				[iconColor]="'#000000'"
				[forStaff]="forStaff"
				[height]="'170px'"
				[pass]="invitation"
				(locationSelected)="setLocation($event)"></app-travel-view>
		</div>
		<div class="page-2 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="message">
				<div>
					<div class="message-label">Message from {{ invitation.issuer?.display_name }}:</div>
					<div class="message-copy">{{ invitation.issuer_message === '' ? 'No message' : invitation.issuer_message }}</div>
				</div>
			</div>
		</div>
	</app-pager>
</ng-template>

<ng-template #teacherMissedTemplateCenter>
	<div class="missed-or-declined-wrapper timer-wrapper">
		<sp-circle-countdown [topText]="'Request Missed'" [progress]="0" [ringColor]="'#E2E6EC'" [diameter]="316">
			<div>{{ invitation.duration / 60 }} min</div>
		</sp-circle-countdown>
	</div>
</ng-template>

<!-- mobile for staff -->
<ng-template #mobileTemplateCenter>
	<app-pager *ngIf="!forInput" [arrowPosition]="'-87px'" [isStaff]="true" [activeDotColor]="invitation.color_profile.solid_color">
		<div class="page-5 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<app-travel-view
				[textColor]="'#7083a0'"
				[forStaff]="forStaff"
				[height]="'170px'"
				[pass]="invitation"
				(locationSelected)="setLocation($event)"></app-travel-view>
		</div>
		<div class="page-6 page-size message-page" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="page-2-wrapper" style="display: flex; justify-content: center">
				<ng-container *ngTemplateOutlet="messagesForTeacher"></ng-container>
			</div>
		</div>
	</app-pager>
</ng-template>

<ng-template #studentPendingTemplateBottom>
	<div class="button-wrapper multiple-buttons">
		<div class="button">
			<app-card-button
				(onClick)="openSetOriginDialog()"
				[disabled]="performingAction"
				[height]="inFormContainer ? '84px' : '144px'"
				[useOverlay]="false"
				[backgroundGradient]="buttonBackgroundGradient">
				<div class="button-content sent-to">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction" class="button-content-title">Accept</div>
				</div>
			</app-card-button>
		</div>
		<div class="button">
			<app-card-button
				(onClick)="denyInvitationByStudent()"
				*ngIf="!invalidDate"
				[disabled]="performingAction"
				[height]="inFormContainer ? '84px' : '144px'"
				[useOverlay]="false"
				[border]="'1px solid #D4D9E2'"
				[backgroundGradient]="'#fff,#fff'">
				<div class="button-content sent-to">
					<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
					<div *ngIf="!performingAction" class="button-content-title" [style.color]="invitation.color_profile.solid_color">Decline</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>

<ng-template #studentAcceptedTemplateBottom>
	<div class="upcoming-block">
		<div class="pass-created">Pass Created</div>
		<div class="pass-created-by-me">By {{ invitation.issuer.last_name }} - {{ formatDateTime(invitation.created) }}</div>
	</div>
</ng-template>

<ng-template #studentMissedTemplateBottom>
	<div class="button-wrapper change-date">
		<div class="button single-button">
			<app-card-button
				[height]="inFormContainer ? '81px' : '144px'"
				(onClick)="changeDateForStudentMissedInvitation()"
				[useOverlay]="false"
				[backgroundGradient]="buttonBackgroundGradient">
				<div class="button-content">
					<div class="button-content-title">Change Date & Time</div>
					<div class="button-content-subtitle">Resend Pass Request to Teacher</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>
<ng-template #studentDeclinedTemplateBottom>
	<app-pager [pages]="2" [page]="1" [pagerClass]="'pager-top'" [activeDotColor]="'#7083A0'" [inactiveDotColor]="'#F5F6F8'">
		<div class="page-1 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Declined</div>
			<div class="subtitle">By You</div>
		</div>
		<div class="page-2 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Created</div>
			<div class="subtitle">By {{ invitation.issuer.display_name }}</div>
		</div>
	</app-pager>
</ng-template>

<!-- pending invitation sent to student (teacher view) -->
<ng-template #teacherPendingTemplateBottom>
	<div class="upcoming-block" *ngIf="!inFormContainer">
		<div class="pass-created">Request Sent</div>
		<div class="pass-created-by-me">By Me - {{ formatDateTime(invitation.created) }}</div>
	</div>
	<div *ngIf="inFormContainer && (studentName || selectedStudents.length > 0)">
		<div class="button-wrapper in-form-container">
			<div class="button single-button">
				<app-card-button [height]="'81px'" (onClick)="newInvitation()" [disabled]="performingAction" [backgroundGradient]="buttonBackgroundGradient">
					<div class="button-content">
						<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
						<div *ngIf="!performingAction" class="button-content-text">
							{{ 'Send Pass Request' + (selectedStudents.length > 1 ? 's' : '') }}
						</div>
					</div>
				</app-card-button>
				<div *ngIf="studentName" class="pass-created-by-me">{{ studentName }}</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #teacherMissedTemplateBottom>
	<div class="button-wrapper change-date">
		<div class="button single-button">
			<app-card-button [height]="inFormContainer ? '81px' : '144px'" (onClick)="changeDate(true)" [backgroundGradient]="buttonBackgroundGradient">
				<div class="button-content">
					<div class="button-content">
						<div class="button-content-title">Change Date & Time</div>
						<div class="button-content-subtitle">Resend Pass Request to Student</div>
					</div>
				</div>
			</app-card-button>
		</div>
	</div>
</ng-template>
<ng-template #teacherAcceptedTemplateBottom>
	<div class="upcoming-block">
		<div class="pass-created">Pass Request Sent</div>
		<div class="pass-created-by-me">By Me - {{ formatDateTime(invitation.created) }}</div>
	</div>
</ng-template>

<ng-template #teacherDeclinedTemplateBottom>
	<app-pager [pages]="2" [page]="1" [pagerClass]="'pager-top'" [activeDotColor]="'#7083A0'" [inactiveDotColor]="'#F5F6F8'">
		<div class="page-1 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Declined</div>
			<div class="subtitle">By {{ studentName }} - {{ formatDateTime(invitation.created) }}</div>
		</div>
		<div class="page-2 teacher-details" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="title">Pass Created</div>
			<div class="subtitle">By Me</div>
		</div>
	</app-pager>
</ng-template>

<!-- mobile for staff -->
<ng-template #mobileTemplateBottom>
	<app-pager *ngIf="invitation.status !== 'pending'; else pending" [page]="2" [activeDotColor]="invitation.color_profile.solid_color">
		<div class="page-2 page-size" [attr.data-motion-translate]="true">
			<span class="bold">Pass Request {{ invitation.status.charAt(0).toUpperCase() + invitation.status.slice(1) }}</span
			><span class="smaller"
				><br />by <var>{{ studentName + ' - ' + formatDateTime(invitation.last_updated) }}</var></span
			>
		</div>
	</app-pager>
	<ng-template #pending>
		<div>
			<span class="bold">Pass Request Sent</span
			><span class="smaller"
				><br />by <var>{{ issuerName + ' - ' + formatDateTime(invitation.created) }}</var></span
			>
		</div>
	</ng-template>
</ng-template>

<ng-template #messagesForStudent>
	<div class="message">
		<div>
			<div class="message-label">{{ 'Message from ' + issuerName }}</div>
			<div class="message-copy">{{ invitation.issuer_message ? invitation.issuer_message : 'No Message' }}</div>
		</div>
	</div>
</ng-template>

<ng-template #messagesForTeacher>
	<div class="message">
		<div>
			<div class="message-label">{{ 'Your message:' }}</div>
			<div class="message-copy">{{ invitation.issuer_message ? invitation.issuer_message : 'No message' }}</div>
		</div>
	</div>
</ng-template>
