<div class="no-fly-times-wrapper">
	<div class="no-fly-times-list" *ngFor="let control of nftFormControls; let i = index">
		<div class="no-fly-time-range" *ngIf="noFlyTimes[i]?.change_type !== 'delete'">
			<time-range-selector>
				<sp-time-range-input [control]="control" (onInputChange)="onInputChange(i, $event)" (onFocusChange)="onFocusChange(i, $event)">
				</sp-time-range-input>
			</time-range-selector>
			<img
				*ngIf="numberOfNft > 1"
				class="delete-icon"
				[ngClass]="{ 'delete-icon-with-error': control?.errors !== null && focusedIndex !== i }"
				[src]="'./assets/trash.svg' | resolveAsset"
				(click)="deleteNoFlyTime(i)" />
		</div>
	</div>
	<app-gradient-button
		class="add-time-range-button"
		[size]="'editable'"
		[width]="'127px'"
		[minHeight]="'31px'"
		[fontSize]="'12px'"
		[cursor]="'pointer'"
		[gradient]="'#FFFFFF'"
		[textColor]="'#7083A0'"
		[border]="'1px solid var(--gray-300, #D4D9E2)'"
		[customShadow]="'0px 2px 10px 0px #1014181A'"
		[hoverColor]="'#F0F2F5'"
		(buttonClick)="addNoFlyTime()">
		<span>+ Add Time Range</span>
	</app-gradient-button>
</div>
