import { Directive, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[spRestrictInput]',
})
export class RestrictInputDirective {
	@Input() disallowedPattern: RegExp | undefined;

	@HostListener('input', ['$event']) onInput(event: Event) {
		const input = event.target as HTMLInputElement;
		if (this.disallowedPattern) {
			const filteredValue = input.value.replace(this.disallowedPattern, '');
			if (input.value !== filteredValue) {
				input.value = filteredValue;
				const event = new Event('input', { bubbles: true });
				input.dispatchEvent(event);
			}
		}
	}
}
