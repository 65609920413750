import { Component, Inject, Input, OnInit } from '@angular/core';
import { NoFlyTime } from '../../../models/NoFlyTime';
import { NoFlyTimeService } from '../../../services/no-fly-time.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-no-fly-time-dialog',
	templateUrl: './no-fly-time-dialog.component.html',
	styleUrls: ['./no-fly-time-dialog.component.scss'],
})
export class NoFlyTimeDialogComponent implements OnInit {
	@Input() noFlyTimes: NoFlyTime[] = [];
	@Input() currentNoFlyTimeIndex: number;

	nftTextList: string[] = [];
	currentEndTimeString = '';

	constructor(
		public nftService: NoFlyTimeService,
		@Inject(MAT_DIALOG_DATA)
		public data: { tooltipType: string; noFlyTimes: NoFlyTime[]; currentNoFlyTimeIndex: number; closeDialogRef: () => void }
	) {}

	ngOnInit() {
		if (this.data) {
			this.noFlyTimes = this.data?.noFlyTimes || this.noFlyTimes;
			this.currentNoFlyTimeIndex = this.data?.currentNoFlyTimeIndex;
		}
		this.populateNftTextList();
	}

	populateNftTextList() {
		this.nftTextList = [];
		for (let i = 0; i < this.noFlyTimes.length; i++) {
			const nft = this.noFlyTimes[i];
			const startTimeStr = this.nftService.momentize(nft.start_time);
			const endTimeStr = this.nftService.momentize(nft.end_time);

			const formattedStartTime = this.nftService.formatTimeString(startTimeStr);
			const formattedEndTime = this.nftService.formatTimeString(endTimeStr);

			const formattedListTime = `${formattedStartTime} - ${formattedEndTime}`;

			this.nftTextList.push(formattedListTime);
			if (i === this.currentNoFlyTimeIndex) {
				this.currentEndTimeString = formattedEndTime;
			}
		}
	}

	closeDialog() {
		this.data?.closeDialogRef();
	}
}
