import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'sp-add-remove-chip',
	templateUrl: './add-remove-chip.component.html',
	providers: [DatePipe],
})
export class AddRemoveChipComponent implements OnInit {
	@Input() text: string;
	@Input() isSelected: boolean;
	@Input() start: string;
	@Input() end: string;

	@Output() onAddRemove = new EventEmitter<boolean>();

	constructor(private datePipe: DatePipe) {}

	hoverText = '';

	ngOnInit() {
		const startAsDate = new Date(this.start);
		const startDate = this.datePipe.transform(startAsDate, 'MMMM d, yyyy', 'UTC');
		const endAsDate = new Date(this.end);
		const endDate = this.datePipe.transform(endAsDate, 'MMMM d, yyyy', 'UTC');
		this.hoverText = `${startDate} - ${endDate}`;
	}

	handleClick(): void {
		this.onAddRemove.next(!this.isSelected);
	}
}
