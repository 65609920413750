<ng-template #modalTemplate>
	<!-- TERMS ARE ONLY BEING SHORTENED -->
	<ng-container *ngIf="termBeingShortened && !termsBeingDeleted.length">
		<div class="tw-text-gray-500 tw-text-base">
			This action will shorten <span class="tw-font-bold">{{ termBeingShortened.term_name }}</span> to
			<span class="tw-font-bold">{{ startDate | date : 'MMMM d, yyyy' }} - {{ endDate | date : 'MMMM d, yyyy' }}</span>
			because terms can't extend beyond the school year.
		</div>
	</ng-container>
	<!-- END TERMS ARE ONLY BEING SHORTENED -->

	<!-- TERMS ARE ONLY BEING DELETED -->
	<ng-container *ngIf="termsBeingDeleted.length && !termBeingShortened">
		<div class="tw-text-gray-500 tw-text-base">
			This action will remove
			<span class="tw-font-bold" *ngFor="let term of termsBeingDeleted; let last = last; let first = first"
				><span *ngIf="last && !first" class="tw-font-normal"> and </span>{{ term.term_name
				}}<span *ngIf="!last && termsBeingDeleted.length > 2">, </span> </span
			>, during
			<span class="tw-font-bold">{{ startDate | date : 'MMMM d, yyyy' }} - {{ endDate | date : 'MMMM d, yyyy' }}</span>
			because terms can't extend beyond the school year.
		</div>
	</ng-container>
	<!-- END TERMS ARE ONLY BEING DELETED -->

	<!-- TERMS ARE BEING DELETED AND SHORTENED -->
	<ng-container *ngIf="termsBeingDeleted.length && termBeingShortened">
		<div class="tw-text-gray-500 tw-text-base">
			This action will remove
			<span class="tw-font-bold" *ngFor="let term of termsBeingDeleted; let last = last; let first = first"
				><span *ngIf="last && !first">and </span>{{ term.term_name }}<span *ngIf="!last">, </span> </span
			>, and shorten <span class="tw-font-bold">{{ termBeingShortened.term_name }}</span> to
			<span class="tw-font-bold">{{ startDate | date : 'MMMM d, yyyy' }} - {{ endDate | date : 'MMMM d, yyyy' }}</span>
			because terms can't extend beyond the school year.
		</div>
	</ng-container>
	<!-- END TERMS ARE BEING DELETED AND SHORTENED -->
</ng-template>
