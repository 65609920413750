import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-google-notice',
	template: `<div
		[class]="
			'tw-animate-fadein-200 tw-z-50 tw-absolute tw-flex tw-flex-col tw-p-2.5 tw-pb-4 tw-bg-gray-900 tw-shadow-drop tw-rounded-2xl tw-text-white ' +
			classes
		">
		<img class="tw-w-10 tw-mx-auto tw-mb-3.5" src="assets/Computers Connecting.svg" alt="Connecting" />
		<div class="tw-flex tw-items-center tw-justify-center tw-mb-1">
			<span class="tw-font-bold tw-text-base">Synced from</span
			><img class="tw-h-4 tw-ml-1" src="assets/Google-classroom-gray.svg" alt="Google Classroom" />
		</div>
		<div class="tw-text-sm tw-font-normal tw-w-48 tw-m-auto tw-text-center">{{ message }}</div>
	</div>`,
})
export class GoogleNoticeComponent {
	@Input() message: string;
	@Input() classes = '';
}
