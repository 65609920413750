<div class="ds-flex-row tw-relative" #selectWrapper>
	<div class="tw-text-navy-500 tw-text-base tw-font-semibold tw-mb-3">{{ label }}</div>
	<div
		class="tw-w-full tw-flex tw-items-center tw-justify-between tw-font-normal tw-text-base tw-p-3 tw-rounded-lg tw-border tw-border-gray-150 tw-border-solid tw-box-border tw-cursor-pointer tw-bg-gray-050"
		[ngClass]="{ active: optionsVisible, 'tw-text-navy-500': selectedOption, 'tw-text-gray-500': !selectedOption }"
		(click)="displaySelectOptions()">
		<img *ngIf="selectedOption && selectedOption.iconSelected" [src]="selectedOption.iconSelected | resolveAsset" class="tw-mr-2" />
		<span class="tw-flex-grow">
			{{ selectedOption?.title || placeholder }}
		</span>
		<img [src]="'assets/Dropdown (Gray).svg' | resolveAsset" [ngClass]="{ 'dropdown-icon': optionsVisible }" />
	</div>

	<div *ngIf="description" class="tw-text-gray-500 tw-font-normal tw-text-sm tw-mt-4">{{ description }}</div>
</div>
