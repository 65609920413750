<sp-thin-pass
	[title]="pass.destination.title"
	[background]="
		user.isAdmin()
			? 'linear-gradient(0deg, rgba(16, 20, 24, 0.50) 0%, rgba(16, 20, 24, 0.50) 100%),' + pass.color_profile.solid_color
			: pass.color_profile.solid_color
	"
	[width]="width"
	[height]="height"
	*ngIf="pass">
	<img class="icon" circle [src]="pass.icon" alt="pass icon" />
	<div subtitle class="subtitle" [style.font-size]="width == '460px' ? '16px' : '14px'">{{ waitInLineTitle | async }}</div>
</sp-thin-pass>
