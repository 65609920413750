<div class="nav-tabs">
	<ng-container *ngFor="let button of buttons">
		<div *ngIf="!button.hidden && hasAnyRole(button.requiredRoles, roles | async)" class="nav-tab">
			<div class="nav-button-wrapper">
				<app-nav-button
					[selected]="selectedRoute$ | async | matchesRoute : button.route"
					[content]="button.title"
					[icon]="button.imgUrl"
					(onClick)="router.navigateByUrl(button.route)"></app-nav-button>
			</div></div
	></ng-container>
</div>
