import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataService } from '../services/data-service';
import { InvitationCardComponent } from '../invitation-card/invitation-card.component';
import { HallPass } from '../models/HallPass';
import { Invitation } from '../models/Invitation';
import { Request } from '../models/Request';
import { PassLike } from '../models';
import { PassCardComponent } from '../pass-card/pass-card.component';
import { ReportFormComponent } from '../report-form/report-form.component';
import { RequestCardComponent } from '../request-card/request-card.component';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { DarkThemeSwitch } from '../dark-theme-switch';
import { KioskModeService } from '../services/kiosk-mode.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ScreenService } from '../services/screen.service';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { HallPassesService, MODAL_MAX_HEIGHT } from '../services/hall-passes.service';
import { SpAppearanceComponent } from '../sp-appearance/sp-appearance.component';
import { User } from '../models/User';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { PassTileComponent } from '../pass-tile/pass-tile.component';
import { WaitInLineCardComponent } from '../pass-cards/wait-in-line-card/wait-in-line-card.component';
import { WaitingInLinePass } from '../models/WaitInLine';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';
import { DeviceDetection } from '../device-detection.helper';
import { captureMessage } from '@sentry/angular';
import { hallPassInstance, idGetter, KioskMonitoringService } from '../services/kiosk-monitoring.service';
import { TimeService } from '../services/time.service';

export class SortOption {
	constructor(private name: string, public value: string) {}

	toString() {
		return this.name;
	}
}

@Component({
	selector: 'app-pass-collection',
	templateUrl: './pass-collection.component.html',
	styleUrls: ['./pass-collection.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassCollectionComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() mock = false;
	@Input() title: string;
	@Input() icon: string;
	@Input() emptyMessage;
	@Input() columns = 3;
	@Input() waitInLine = false;
	@Input() fromPast = false;
	@Input() forFuture = false;
	@Input() isActive = false;
	@Input() forStaff = false;
	@Input() forMonitor = false;
	@Input() hasSort = false;
	@Input() maxHeight;
	@Input() showEmptyHeader: boolean;
	@Input() columnViewIcon = true;
	@Input() smoothlyUpdating = false;
	@Input() grid_template_columns = '143px';
	@Input() grid_gap = '12px';
	@Input() isAdminPage: boolean;
	@Input() headerWidth = '100%';
	@Input() passProvider: Observable<any>;
	@Input() hasFilterPasses: boolean;
	@Input() filterModel: string;
	@Input() filterDate: moment.Moment;
	@Input() user: User;
	@Input() selectedSort = null;
	@Input() searchPanel: boolean;
	@Input() showProfilePictures = true;
	@Input() applyClassToTiles: (pass: PassLike) => string = () => '';

	@Output() sortMode = new EventEmitter<string>();
	@Output() reportFromPassCard = new EventEmitter();
	@Output() currentPassesEmit = new EventEmitter();
	@Output() filterPasses = new EventEmitter();
	@Output() passClick = new EventEmitter<boolean>();
	@Output() searchValue = new EventEmitter<string>();
	@Output() randomString = new EventEmitter<string>();

	@ViewChildren(PassTileComponent) passTileComponents: QueryList<PassTileComponent>;

	currentPasses$: Observable<PassLike[]>;
	activePassTime$;
	search: string;
	timers: number[] = [];
	timerEvent: Subject<void> = new BehaviorSubject(null);
	sort$ = this.dataService.sort$;

	isEnabledProfilePictures$: Observable<boolean>;
	private dialogWidth = '40%';
	private isSmartphone = DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile();
	destroy$ = new Subject();

	@HostListener('window:resize')
	checkDeviceWidth() {
		if (this.screenService.isDeviceSmallExtra) {
			this.grid_template_columns = '143px';
		}

		if (!this.screenService.isDeviceSmallExtra && this.screenService.isDeviceMid) {
			this.grid_template_columns = '157px';
		}
	}

	// pass parameter must always be defined
	private static getDetailDialog(pass: PassLike): any {
		if (!pass) {
			throw new Error('Cannot open dialog with undefined pass data');
		}

		if (pass instanceof HallPass) {
			return PassCardComponent;
		}

		if (pass instanceof Invitation) {
			return InvitationCardComponent;
		}

		// noinspection SuspiciousInstanceOfGuard
		if (pass instanceof Request) {
			return RequestCardComponent;
		}

		if (pass instanceof WaitingInLinePass) {
			return WaitInLineCardComponent;
		}

		return null;
	}

	constructor(
		public dialog: MatDialog,
		private dataService: DataService,
		public darkTheme: DarkThemeSwitch,
		private kioskMode: KioskModeService,
		private kioskMonitoring: KioskMonitoringService,
		private sanitizer: DomSanitizer,
		public screenService: ScreenService,
		private cdr: ChangeDetectorRef,
		private passesService: HallPassesService,
		private userService: UserService
	) {}

	get gridTemplate() {
		if (this.screenService.isDeviceMid && !this.screenService.isDeviceSmallExtra) {
			this.grid_template_columns = '157px';
		}
		return this.sanitizer.bypassSecurityTrustStyle(`repeat(auto-fill, ${this.grid_template_columns})`);
	}

	get gridGap() {
		return this.grid_gap;
	}

	get isKioskMode() {
		return this.kioskMode.isKioskMode();
	}

	get selectedText() {
		if (this.selectedSort === 'past-hour') {
			return 'Past hour';
		} else if (this.selectedSort === 'today') {
			return 'Today';
		} else if (this.selectedSort === 'past-three-days') {
			return 'Past 3 days';
		} else if (this.selectedSort === 'past-seven-days') {
			return 'Past 7 days';
		} else if (this.selectedSort === 'all_time' || !this.selectedSort) {
			return 'All Time';
		} else if (this.selectedSort === 'school-year') {
			return 'This school year';
		}
	}

	ngOnInit() {
		if (this.screenService.isIpadWidth) {
			this.dialogWidth = '60%';
		}
		if (this.passProvider) {
			this.currentPasses$ = this.passProvider.pipe(
				tap((passes) => {
					if (this.isActive) {
						passes.forEach((p) => {
							if (p instanceof HallPass) {
								this.kioskMonitoring.markTraceCheckpoint(p.id, 5, 'arrived_in_collection', {
									collectionList: passes.filter(hallPassInstance).map(idGetter),
								});
							}
						});
					}
				}),
				map((passes) => {
					if (!this.isActive) {
						return passes;
					}
					return passes.filter((p) => {
						if (p instanceof HallPass && !p.was_scheduled) {
							return true;
						}
						const now = TimeService.getNowDate().getTime();
						/**
						 * We expect p.start_time to be further into the past than now, but there
						 * are sentry error logs that say otherwise.
						 */
						const hasStarted = p.start_time - now < 1000;
						const hasNotEnded = now < p.end_time;
						const isActive = hasStarted && hasNotEnded;
						this.kioskMonitoring.markTraceCheckpoint(p.id, 6, 'before_collection_filter', {
							clientTime: Date.now(),
							clientTimeWithDrift: TimeService.getNowDate().getTime(),
							startTime: new Date(p.start_time).getTime(),
							endTime: new Date(p.end_time).getTime(),
							hasStarted,
							hasNotEnded,
							passed: isActive,
							collectionList: passes.filter(hallPassInstance).map(idGetter),
						});
						return isActive;
					});
				}),
				tap((passes: PassLike[]) => {
					if (this.isActive) {
						passes.forEach((p) => {
							if (p instanceof HallPass) {
								this.kioskMonitoring.markTraceCheckpoint(p.id, 7, 'after_collection_filter', {
									collectionList: passes.filter(hallPassInstance).map(idGetter),
								});
							}
						});
					}
				}),
				tap((passes) => {
					this.currentPassesEmit.emit(passes);
					this.cdr.detectChanges();
				})
			);

			this.currentPasses$
				.pipe(
					filter((r) => !!r.length),
					take(1)
				)
				.subscribe((passes) => {
					const pass = passes[Math.floor(Math.random() * passes.length)];
					try {
						if (pass instanceof HallPass || pass instanceof Request || pass instanceof WaitingInLinePass) {
							if (!pass?.origin) {
								captureMessage(`Pass Collection ${this.title} on page ${window.location.href} missing either pass or pass origin`, 'warning');
							}
						}
						if (!pass?.destination) {
							captureMessage(`Pass Collection ${this.title} on page ${window.location.href} missing either pass or pass destination`, 'warning');
						}
					} catch (e) {
						console.log('error trying to capture message in sentry', e);
					}

					if (pass?.destination?.title) {
						const destinationName = pass.destination.title;
						const random = [destinationName];
						this.randomString.emit(random[Math.floor(Math.random() * random.length)]);
					}

					const dialog = window.history.state.open_on_load?.dialog;
					const id = window.history.state.id;
					passes.forEach((p) => {
						if ((p instanceof HallPass || pass instanceof Invitation) && dialog === 'main/passes/open_pass' && p.id === id) {
							this.initializeDialog(p);
						}

						if (pass instanceof Request && dialog === 'main/passes/open_request' && p.id === id) {
							this.initializeDialog(p);
						}
					});
				});
		}

		if (this.isActive) {
			this.timers.push(
				window.setInterval(() => {
					this.timerEvent.next(null);
					this.cdr.detectChanges();
				}, 1000)
			);
		}

		this.userService.userJSON$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.user = user;
		});

		this.isEnabledProfilePictures$ = this.userService.isEnableProfilePictures$;
	}

	ngAfterViewInit() {
		const id = window.history.state.id;
		let opened = false;
		this.passTileComponents.changes.subscribe((passTiles) => {
			if (opened) {
				return;
			}

			passTiles.forEach((passTile) => {
				if (passTile.pass.id !== id) {
					return;
				}

				const dialog = window.history.state.open_on_load?.dialog;
				opened = true;

				const event = {
					time$: passTile.activePassTime$,
					pass: passTile.pass,
				};

				if (
					(passTile.pass instanceof HallPass || passTile.pass instanceof Invitation) &&
					dialog === 'main/passes/open_pass' &&
					passTile.pass.id === id
				) {
					this.showPass(event);
				} else if (passTile.pass instanceof Request && dialog === 'main/passes/open_request' && passTile.pass.id === id) {
					this.showPass(event);
				}
			});
		});
	}

	ngOnDestroy() {
		this.timers.forEach((id) => {
			clearInterval(id);
		});
		this.timers = [];
		this.destroy$.next();
		this.destroy$.complete();
	}

	get _icon() {
		return this.darkTheme.getIcon({
			iconName: this.icon,
			darkFill: 'White',
			lightFill: 'Navy',
			setting: null,
		});
	}

	getEmptyMessage() {
		return this.emptyMessage;
	}

	showPass({ time$, pass }) {
		this.activePassTime$ = time$;
		this.passClick.emit(true);
		if (!this.waitInLine) {
			this.dataService.markRead(pass).subscribe();
		}
		this.initializeDialog(pass);
	}

	openFilter(target) {
		const sortOptions = [
			{ display: 'Past hour', color: this.darkTheme.getColor(), action: 'past-hour' },
			{ display: 'Today', color: this.darkTheme.getColor(), action: 'today' },
			{ display: 'Past 3 days', color: this.darkTheme.getColor(), action: 'past-three-days' },
			{ display: 'Past 7 days', color: this.darkTheme.getColor(), action: 'past-seven-days' },
			{ display: 'This school year', color: this.darkTheme.getColor(), action: 'school-year' },
			{
				display: 'All Time',
				color: this.darkTheme.getColor(),
				action: 'all_time',
				divider: this.user.show_expired_passes && !User.fromJSON(this.user).isStudent(),
			},
		];
		if (this.user.show_expired_passes && User.fromJSON(this.user).isTeacher()) {
			sortOptions.push({
				display: 'Hide Expired Passes',
				color: this.darkTheme.getColor(),
				action: 'hide_expired_pass',
			});
		}
		const filterDialog = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: target.currentTarget,
				sortData: sortOptions,
				selectedSort: this.selectedSort || 'all_time',
				maxHeight: '332px',
			},
		});

		filterDialog
			.afterClosed()
			.pipe(filter((res) => !!res))
			.subscribe((action) => {
				if (action !== 'hide_expired_pass') {
					if (this.selectedSort === action) {
						this.selectedSort = 'all_time';
					} else {
						this.selectedSort = action;
					}

					const value = this.selectedSort === 'all_time' ? null : this.selectedSort;
					this.cdr.detectChanges();
					this.passesService.updateFilterRequest(this.filterModel, value);
					this.passesService.filterExpiredPassesRequest(this.user, value);
					this.filterPasses.emit(value);
				} else {
					this.openAppearance();
				}
			});
	}

	openAppearance() {
		this.dialog.open(SpAppearanceComponent, {
			panelClass: 'sp-form-dialog',
			data: { fromFilter: true },
		});
	}

	onSortSelected(sort: string) {
		this.sort$.next(sort);
		this.sortMode.emit(sort);
	}

	initializeDialog(pass: PassLike) {
		let data: any;

		if (pass instanceof HallPass) {
			if (this.kioskMode.getCurrentRoom().value) {
				pass['cancellable_by_student'] = false;
			}

			const { fromPast, isActive, forFuture } = this.passesService.calculatePassStatus(pass);
			const passStatus = this.passesService.getPassStatus(pass.start_time, pass.end_time, pass.expiration_time, false, false, false);
			data = {
				pass: pass,
				fromPast,
				forFuture,
				isActive: passStatus === 'active' || passStatus === 'overtime',
				forMonitor: this.forMonitor,
				forStaff: this.forStaff && !this.kioskMode.getCurrentRoom().value,
				kioskMode: !!this.kioskMode.getCurrentRoom().value,
				hideReport: this.isAdminPage,
				activePassTime$: this.activePassTime$,
				showStudentInfoBlock: this.forStaff || this.kioskMode.getCurrentRoom().value,
			};
			data.isActive = passStatus === 'active' || passStatus === 'overtime';
		} else {
			data = {
				pass: pass,
				fromPast: this.fromPast,
				forFuture: this.forFuture,
				waitInLine: this.waitInLine,
				forMonitor: this.forMonitor,
				isActive: this.isActive,
				forStaff: this.forStaff,
			};
		}
		if (data.isActive) {
			data.hideReport = true;
			if (this.kioskMode.isKioskMode() && !this.forFuture) {
				data['hasDeleteButton'] = false;
			}
		}
		const modalHeight = this.passesService.getModalHeight(pass, this.forStaff, this.isKioskMode, this.forMonitor);

		const modalWidth = this.passesService.getModalWidth(this.isSmartphone);
		const modalMinWidth = this.passesService.getModalMinWidth(this.isSmartphone);
		const config: MatDialogConfig = {
			panelClass: (this.forStaff ? 'teacher-' : 'student-') + 'pass-card-dialog-container',
			backdropClass: 'custom-backdrop',
			width: modalWidth,
			height: modalHeight,
			minWidth: modalMinWidth,
			maxWidth: '686px',
			maxHeight: `${MODAL_MAX_HEIGHT}px`,
			data: data,
		};
		const dialogRef = this.dialog.open(PassCollectionComponent.getDetailDialog(pass), config);

		const background = this.passesService.setPassOverlayColor(!this.forStaff, pass);
		this.screenService.customBackdropStyle$.next({ background });
		this.screenService.customBackdropEvent$.next(true);

		dialogRef.afterClosed().subscribe((dialogData) => {
			this.screenService.customBackdropEvent$.next(false);
			this.screenService.customBackdropStyle$.next(null);
			this.passClick.emit(false);
			if (dialogData && dialogData['report']) {
				const reportRef = this.dialog.open(ReportFormComponent, {
					width: '425px',
					height: '500px',
					panelClass: 'form-dialog-container',
					backdropClass: 'cdk-overlay-transparent-backdrop',
					data: { report: dialogData['report'] },
				});
			}
		});
	}

	openSortDialog(event) {
		const sortOptions = [
			{ display: 'Pass Expiration Time', color: this.darkTheme.getColor(), action: 'expiration_time', toggle: false },
			{ display: 'Student Name', color: this.darkTheme.getColor(), action: 'student_name', toggle: false },
			{ display: 'To Location', color: this.darkTheme.getColor(), action: 'destination_name', toggle: false },
		];
		UNANIMATED_CONTAINER.next(true);

		const sortDialog = this.dialog.open(DropdownComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				trigger: event.currentTarget,
				sortData: sortOptions,
				selectedSort: this.selectedSort,
			},
		});

		sortDialog
			.afterClosed()
			.pipe(
				tap(() => UNANIMATED_CONTAINER.next(false)),
				filter((res) => !!res)
			)
			.subscribe((sortMode) => {
				this.selectedSort = sortMode;
				this.onSortSelected(this.selectedSort);
			});
	}

	endPass(pass: HallPass): void {
		this.passesService.endPass(pass.id).subscribe();
	}
}
