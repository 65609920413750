<ng-container *ngIf="data">
	<sp-about-class [data]="data" (editType)="editDetails($event)" [userCanEdit]="userCanEdit"></sp-about-class>

	<sp-archive
		[resourceName]="'Class'"
		[archiveModalBody]="archiveClassModalBody"
		[currentStatus]="data.classDetails.status"
		[userCanEdit]="userCanEdit"
		[tooltipTemplate]="onlyAdminEditTooltip"
		[archiveText]="'Archive Class for Everyone'"
		[unarchiveText]="'Unarchive Class for Everyone'"
		(updateStatus)="updateClassArchivedStatus($event)"></sp-archive>
	<ng-template #onlyAdminEditTooltip>
		<div class="ds-tooltip" style="max-width: 321px; text-align: left; padding: 12px">
			<p class="tw-w-max tw-my-0 tw-bg-purple-100 tw-py-1 tw-px-1.5 tw-text-purple-500 tw-text-xs tw-font-bold tw-uppercase tw-rounded">Admin Only</p>
			<p class="tw-text-sm tw-font-medium tw-mb-0 tw-mt-1.5">Only admins can edit classes.</p>
		</div>
	</ng-template>

	<sp-class-sync-info [syncData]="syncData$ | async"></sp-class-sync-info>

	<ng-template #editRoomModalBody>
		<sp-edit-room-form
			[user]="user"
			[editRoomForm]="editRoomForm"
			[initialRoom]="room"
			(onSelect)="editRoomDialogService.setDialogConfig({ disablePrimaryButton: false })"
			(onRoomChanges)="handleRoomChange($event)">
		</sp-edit-room-form>
	</ng-template>

	<!-- BEGIN ADD TEACHERS MODAL -->
	<ng-template #addTeachersModalBody>
		<sp-edit-teacher-form [editTeachersForm]="editTeachersForm" [teachers]="teachers" (editTeachers)="editTeachers($event)"></sp-edit-teacher-form>
	</ng-template>

	<!-- END EDIT TEACHERS MODAL -->

	<!-- BEGIN ARCHIVE CLASS MODAL -->
	<ng-template #archiveClassModalBody>
		<div class="tw-mt-0 tw-text-gray-500 tw-text-base tw-font-normal tw-w-full">
			<p class="tw-mt-0">
				When you archive a class, it’s archived for everyone. That means no students or teachers will see this class as part of their daily schedule.
			</p>
			<p>We will automatically hide classes when the class is over, or it hasn’t started yet.</p>
			<p class="tw-mb-7">You can always unarchive the class in the future from the same window.</p>
		</div>
	</ng-template>
	<!-- END ARCHIVE CLASS MODAL -->

	<!-- BEGIN RENAME CLASS MODAL -->
	<ng-template #renameClassModalBody>
		<sp-rename [renameForm]="renameClassForm" #renameClassComponent [name]="data.classDetails?.display_name"> </sp-rename>
	</ng-template>

	<!-- END RENAME CLASS MODAL -->

	<!-- BEGIN EDIT CLASSES DATE AND TIMES MODAL -->
	<ng-template #editClassDateTimeModalBody>
		<ng-container *ngIf="data.schedules | async as schedules">
			<ng-container *ngIf="data.listGroups | async as listGroups">
				<sp-class-term-and-period-form
					[schedules]="schedules"
					[listGroups]="listGroups"
					[initialSelectedTermIds]="initiallySelectedTermIds"
					[initialSelectedPeriodGroupingIds]="initiallySelectedPeriodGroupingIds"
					(selectedPeriodGroupingIds)="didSelectPeriodGroupingIds($event)"
					(selectedTermIds)="didSelectTermIds($event)">
				</sp-class-term-and-period-form>
			</ng-container>
		</ng-container>
	</ng-template>

	<ng-template #editClassSubHeaderModalBody>
		<div *ngIf="data?.classDetails?.synced_name; else onlyClassName">
			<p class="tw-text-base tw-font-bold tw-text-navy-500 tw-mt-3 tw-mb-0">{{ data?.classDetails?.synced_name }}</p>
			<p class="tw-text-base tw-font-normal tw-text-gray-500 tw-mt-1.5 tw-mb-0">
				{{ data?.classDetails?.class_users.students | andOthers : 'user.display_name' : 3 }}
				<span class="tw-underline" customToolTip [contentTemplate]="moreDetailsTooltip">More Details</span>
			</p>
			<ng-template #moreDetailsTooltip>
				<sp-class-sync-info-tooltip [syncData]="currentSyncInfo$ | async"></sp-class-sync-info-tooltip>
			</ng-template>
		</div>
	</ng-template>

	<ng-template #onlyClassName>
		<div>
			<p class="tw-text-base tw-font-bold tw-text-navy-500 tw-mt-3 tw-mb-0">{{ data?.classDetails?.display_name }}</p>
		</div>
	</ng-template>

	<ng-template #archivedClassTemplate>
		<div>You can view this class in <a [routerLink]="['/admin/classes']" [queryParams]="{ filter: 'archived' }"> Archived Classes</a>.</div>
	</ng-template>
</ng-container>
