import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavFloatingMenuComponent } from 'app/backpack/sidenav/sidenav-floating-menu.component';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
	selector: 'sp-sidenav-expanding-tab',
	template: `
		<div #tab>
			<sp-sidenav-tab
				[doesExpand]="true"
				[label]="label"
				[icon]="icon"
				[isActive]="(selectedRoute$ | async | matchesRoute : route) || (isExpanded$ | async) || false"
				(click)="expand()">
				<ng-content></ng-content>
			</sp-sidenav-tab>
		</div>
	`,
	styleUrls: ['./sidenav-expanding-tab.component.scss'],
})
export class SidenavExpandingTabComponent {
	@Input() icon = 'Admin';
	@Input() label = '';
	@Input() route = '';
	@Input() submenu?: TemplateRef<unknown>;

	@ViewChild('tab') sidenavTab?: ElementRef<HTMLElement>;

	constructor(private router: Router, private dialog: MatDialog) {}

	selectedRoute$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		map((event: NavigationEnd) => event.urlAfterRedirects),
		startWith(this.router.url),
		distinctUntilChanged(),
		map((url) => url.split('?')[0])
	);

	isExpanded$ = new BehaviorSubject(false);

	expand(): void {
		if (!this.sidenavTab?.nativeElement) {
			return;
		}

		this.isExpanded$.next(true);

		const rect = this.sidenavTab.nativeElement.getBoundingClientRect();
		const submenuDialog = this.dialog.open(SidenavFloatingMenuComponent, {
			position: { left: `${rect.left}px`, top: `${rect.bottom + 13}px` },
			panelClass: 'sidenav-expanding-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				submenu: this.submenu,
			},
		});

		submenuDialog.afterClosed().subscribe(() => {
			this.isExpanded$.next(false);
		});
	}
}
