import { Component, Input, OnInit } from '@angular/core';
import { ClassAgendaItem, ScheduleService, THIRTY_MINUTES_IN_MILLISECONDS } from '../../../services/schedule.service';
import { User } from '../../../models/User';
import { HomepageService } from '../../../services/homepage.service';
import { ClassesService, SPClassWithUsers } from '../../../services/classes.service';
import { BootstrapService } from '../../../services/bootstrap.service';

@Component({
	selector: 'app-origin-location-tooltip',
	templateUrl: './origin-location-tooltip.component.html',
	styleUrls: ['./origin-location-tooltip.component.scss'],
})
export class OriginLocationTooltipComponent implements OnInit {
	@Input() classes: SPClassWithUsers[];
	@Input() agendaItems: ClassAgendaItem[];
	@Input() tooltipType = 'missing';
	@Input() close: () => void;
	@Input() student: User;

	@Input() background = '#101418';
	@Input() width = 'auto';
	@Input() height = 'auto';
	@Input() borderRadius = '10px';
	@Input() padding = '16px 12px 16px 12px';
	@Input() dayType: string;
	@Input() period: string;
	@Input() headerOn = false;

	classesTextList: string[] = [];
	currentEndTimeString = '';
	thirtyMinutesInMilliseconds = THIRTY_MINUTES_IN_MILLISECONDS;
	teachersString: string;

	constructor(
		public scheduleService: ScheduleService,
		public homePageService: HomepageService,
		public classesService: ClassesService,
		public bootstrapService: BootstrapService
	) {}

	ngOnInit(): void {
		if (this.agendaItems) {
			this.classes = this.agendaItems.map((agenda) => agenda.class);
		}
		if (this.classes) {
			if (this.classes.length >= 2) {
				this.populateClassTextList();
			}
			if (this.classes.length === 1) {
				this.populateTeachersString();
			}
		}
	}

	populateTeachersString(): void {
		this.teachersString = '';
		this.classes[0].class_users?.teachers.forEach((teacher, i) => {
			if (i === this.classes[0].class_users?.teachers?.length - 2) {
				this.teachersString += teacher.user.first_name + ' ' + teacher.user.last_name + ', and ';
			} else if (i === this.classes[0].class_users?.teachers?.length - 1) {
				this.teachersString += teacher.user.first_name + ' ' + teacher.user.last_name;
			} else {
				this.teachersString += teacher.user.first_name + ' ' + teacher.user.last_name + ', ';
			}
		});
	}

	populateClassTextList(): void {
		this.classesTextList = [];
		for (let i = 0; i < this.classes.length; i++) {
			let classString = this.classes[i].display_name + ' (' + this.classes[i].room.title + ')';
			let teacherString = '';
			this.classesService.getClassUsers(this.classes[i].id).subscribe((cu) => {
				if (cu.teachers && cu.teachers.length) {
					teacherString += ': ';
					for (let j = 0; j < cu.teachers.length; j++) {
						const teacher = cu.teachers[j].user;
						if (j === cu.teachers.length - 2) {
							teacherString += teacher.first_name + ' ' + teacher.last_name + ', and ';
						} else if (j === cu.teachers.length - 1) {
							teacherString += teacher.first_name + ' ' + teacher.last_name;
						} else {
							teacherString += teacher.first_name + ' ' + teacher.last_name + ', ';
						}
					}
				}
				classString += teacherString;
				this.classesTextList.push(classString);
			});
		}
	}
}
