import { Pipe, PipeTransform } from '@angular/core';
import { HallPass } from '../models/HallPass';
import { ClassAgendaItem, PeriodAgendaItem } from '../services/schedule.service';
import * as moment from 'moment/moment';
import { User } from '../models/User';

@Pipe({
	name: 'comingThisPeriod',
})
export class ComingThisPeriodPipe implements PipeTransform {
	transform(passes: HallPass[], period: PeriodAgendaItem, classes: ClassAgendaItem[]): HallPass[] {
		const allStudents: User[] = [];
		// Iterate over each class in the agenda
		classes?.forEach((classItem) => {
			// Iterate over each student in the class
			classItem?.class.class_users?.students.forEach((student) => {
				// Add the student to the 'allStudents' array
				allStudents.push(student.user);
			});
		});
		if (passes && passes.length && period) {
			const startTime = moment(period.start_time, 'HH:mm');
			const endTime = moment(period.end_time, 'HH:mm');
			return passes.filter((pass) => {
				const passStartTime = moment(pass.start_time);
				// todo - do we need to worry about pass end time?
				//const passEndTime = moment(pass.expiration_time);

				// Check if the student ID is not in allStudents array
				const studentNotInAllStudents = !allStudents.some((student) => student.id === pass.student.id);
				return passStartTime.isBetween(startTime, endTime) && studentNotInAllStudents;
			});
		}
		return [];
	}
}
