import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WaitingInLinePass } from '../models';
import { HttpService } from './http-service';
import { filter, map } from 'rxjs/operators';
import { StartWaitingInLinePassResponse } from './hall-passes.service';
import { LiveDataService } from '../live-data/live-data.service';
import { PollingEvent } from './polling-service';
import { UserService } from './user.service';
import { TimeService } from './time.service';
import { KioskModeService } from './kiosk-mode.service';
import { Title } from '@angular/platform-browser';

// This was causing issues with the "ready to start" popup not opening in kiosk mode if multiple WIL passes
// existed. I created a separate sort sortWilForKioskMode below for kiosk mode.
export const sortWil = (pass1: WaitingInLinePass, pass2: WaitingInLinePass): number => {
	// Sort order
	// 1. Ready to start passes - Pass with the most time to start comes first
	// 2. Not ready to start passes - Smallest line position comes first

	if (!!pass1.start_attempt_end_time && !!pass2.start_attempt_end_time) {
		return pass2.start_attempt_end_time.getTime() - pass1.start_attempt_end_time.getTime();
	}

	if (!pass1.start_attempt_end_time && !!pass2.start_attempt_end_time) {
		return 1;
	}

	if (!!pass1.start_attempt_end_time && !pass2.start_attempt_end_time) {
		return -1;
	}

	return pass1.line_position - pass2.line_position;
};

/**
 * This service contains all business logic responsible for interacting with the WaitingInLine API and
 * handling its data between components.
 */
@Injectable({
	providedIn: 'root',
})
export class WaitInLineService {
	constructor(
		private http: HttpService,
		private liveData: LiveDataService,
		private userService: UserService,
		private timeService: TimeService,
		private kioskMode: KioskModeService,
		private titleService: Title
	) {}

	fetchWaitingInLinePasses(body: Record<string, any>) {
		return this.http
			.post<{ passes: WaitingInLinePass[] }>('v2/waiting_in_line_pass/list_all', body, {}, false)
			.pipe(map((response) => response.passes.map((r) => WaitingInLinePass.fromJSON(r))));
	}

	startWilPassNow(id: number, encounterPreventionOverrideId?: number): Observable<StartWaitingInLinePassResponse> {
		return this.http.post<StartWaitingInLinePassResponse>(
			'v2/hall_passes/start_waiting_in_line_pass',
			{
				waiting_in_line_pass_id: id,
				encounter_prevention_override_id: encounterPreventionOverrideId,
			},
			undefined,
			false
		);
	}

	checkWilEncounterPrevention(id: number): Observable<any> {
		const waiting_in_line_pass_id = id;
		return this.http.post('v2/hall_passes/wil_encounter_prevention', { waiting_in_line_pass_id }, undefined, false);
	}

	deleteWilPass(id: number): Observable<never> {
		return this.http.post('v2/waiting_in_line_pass/delete', { waiting_in_line_pass_id: id }, undefined, false);
	}

	listenForWilUpdate(id: string | number): Observable<PollingEvent> {
		return this.liveData.watchUpdatedWaitingInLine().pipe(filter((event: PollingEvent) => (event.data as WaitingInLinePass).id == id));
	}

	listenForWilDeletion(id: string | number): Observable<PollingEvent> {
		return this.liveData.watchDeletedWaitingInLine().pipe(filter((event: PollingEvent) => (event.data as WaitingInLinePass).id == id));
	}

	remainingAttemptSeconds(wilp: WaitingInLinePass): number {
		if (wilp instanceof WaitingInLinePass) {
			return Math.floor((wilp.start_attempt_end_time.getTime() - this.timeService.now()) / 1000);
		}
	}

	setTabTitleForWilp(tabTitle: string): void {
		if (!(this.userService.userData.value.isStudent() || this.kioskMode.isKioskMode())) {
			return;
		}

		this.titleService.setTitle(tabTitle);
	}

	changeFaviconForWilp(assetLink: string): void {
		if (!(this.userService.userData.value.isStudent() || this.kioskMode.isKioskMode())) {
			return;
		}
		const selectors = ['link[rel="shortcut icon"]', 'link[rel="icon"]'];

		for (const selector of selectors) {
			document.querySelectorAll<HTMLElement>(selector).forEach((e: HTMLLinkElement) => {
				e.type = 'image/png';
				e.href = assetLink;
			});
		}
	}
}
