import { Component, Input } from '@angular/core';

/**
 * This component is a tab in the sidenav. It primarily serves as a visual building block,
 * and you should rarely need to use this component directly. For common use cases,
 * @see SidenavRouteTabComponent and @see SidenavExpandingTabComponent.
 */
@Component({
	selector: 'sp-sidenav-tab',
	templateUrl: './sidenav-tab.component.html',
	styleUrls: ['./sidenav-tab.component.scss'],
	host: {
		'[class.active]': 'isActive',
	},
})
export class SidenavTabComponent {
	@Input() icon = 'Admin';
	@Input() label = '';
	@Input() isActive = false;

	// These inputs control the decorators on the tab
	@Input() isSparkly = false;
	@Input() doesExpand = false;
	@Input() isBeta = false;

	get iconColor() {
		return this.isActive ? '(Navy)' : '(Blue-Gray)';
	}

	get iconSrc() {
		return `assets/${this.icon} ${this.iconColor}.svg`;
	}

	get iconAlt() {
		return `${this.icon} icon`;
	}
}
