import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { TimeService } from '../../services/time.service';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

@Component({
	selector: 'sp-countdown-label',
	template: `<span>{{ timeLabel }} {{ timeDescription }}</span>`,
	styles: [``],
})
export class CountdownLabelComponent implements OnInit, OnChanges {
	private destroy$ = new Subject<void>();

	@Input()
	date: Date;

	@Input() showRemainingText: boolean;

	@Input() showOvertimeText: boolean;

	@Input()
	heartbeat: Subject<void>;

	timeDescription = '';
	timeLabel = '';

	ngOnChanges(changes: SimpleChanges) {
		if (changes.date && !!changes.date.currentValue) {
			this.updateLabel(changes.date.currentValue);
		}
	}
	constructor(private timeService: TimeService, private cdr: ChangeDetectorRef) {}

	updateLabel(date: Date) {
		const now: Date = this.timeService.nowDate();
		const diff: number = (date.getTime() - now.getTime()) / 1000;
		const mins: number = Math.floor(Math.abs(Math.floor(diff) / 60));
		const secs: number = Math.abs(Math.floor(diff) % 60);

		// Add leading zero when seconds is less than 10
		this.timeLabel = `${mins}:${secs < 10 ? '0' : ''}${secs}`;
		this.cdr.detectChanges();

		if (diff >= 0 && this.showRemainingText) {
			this.timeDescription = 'Remaining';
		} else if (diff < 0 && this.showOvertimeText) {
			this.timeDescription = 'Overtime';
		} else {
			this.timeDescription = '';
		}
	}

	ngOnInit(): void {
		this.heartbeat.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.updateLabel(this.date);
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
