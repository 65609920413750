<div class="schedule-wrapper">
	<div class="icon">
		<img *ngIf="!!icon" [src]="icon | resolveAsset" />
	</div>
	<div *ngIf="title" class="title">
		{{ title }}
	</div>
	<div *ngIf="!displayReviewTemplate && subtitle" class="subtitle" [innerHTML]="subtitle"></div>
	<ng-container *ngIf="displayReviewTemplate">
		<ng-container *ngTemplateOutlet="reviewContent"></ng-container>
	</ng-container>
</div>

<ng-template #reviewContent>
	<div class="review-subtitle">Please review each of the schedules to confirm the times and labels are correct.</div>
</ng-template>
