<div #passButtonsContainer>

	<app-create-pass-button
	cdkOverlayOrigin
	#secondOverlayTrigger="cdkOverlayOrigin"
	[title]="screenService.windowWidth < screenService.largeDevicesBreakPoint ? '' : 'Create Pass'"
	[backgroundColor]="'#00B476'"
	[width]="screenService.isDeviceLarge ? '51px' : ''"
	[hoverBackgroundColor]="'#009964'"
	[icon]="'add-icon.svg'"
	[textColor]="'#fff'"
	[disabled]="(emergencyService.isEmergencyActivated | async) !== false"
	(onClick)="this.showMainForm()">
</app-create-pass-button>
</div>