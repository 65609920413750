<div
	class="period-container"
	*ngIf="tooltipType === 'staff'"
	[style.background]="background"
	[style.width]="width"
	[style.height]="height"
	[style.border-radius]="borderRadius"
	[style.padding]="padding">
	<div *ngIf="!!close; else noClose" class="icon-container">
		<img class="period-icon" [src]="'./assets/Clock Schedule (White).svg' | resolveAsset" />
		<img class="close-icon" [src]="'./assets/Close (White).svg' | resolveAsset" alt="" (click)="close()" />
	</div>
	<ng-template #noClose>
		<img class="period-icon" [src]="'./assets/Clock Schedule (White).svg' | resolveAsset" />
	</ng-template>

	<div class="period-text">
		<span class="header-text" *ngIf="!isRotating">Today's Schedule</span>
		<span class="day-text" *ngIf="multipleDayTypes"> {{ dayType }}</span>
	</div>
	<div *ngIf="scheduleGroups?.length > 1; else noTabs">
		<mat-tab-group
			class="mat-tab-group"
			id="schedule-grades-tab-group"
			disableRipple
			disablePagination
			[selectedIndex]="selectedTabIndex"
			(selectedIndexChange)="onSelectScheduleGroup($event)">
			<mat-tab *ngFor="let sg of scheduleGroups">
				<ng-template mat-tab-label>
					<div class="tab-label tw-font-medium tw-text-white">{{ sg.name }}</div>
				</ng-template>
				<!-- MAT TAB CONTENT -->
				<ng-container [ngTemplateOutlet]="periodContent"></ng-container>
				<!-- END MAT TAB CONTENT -->
			</mat-tab>
		</mat-tab-group>
	</div>
	<ng-template #noTabs>
		<ng-container [ngTemplateOutlet]="periodContent"></ng-container>
	</ng-template>
	<ng-template #periodContent>
		<div *ngIf="periodTimes.length > 0; else noPeriods" class="period-list">
			<span
				*ngFor="let period of periodTimes"
				[ngClass]="{ 'active-period-text': currentPeriod === period, 'upcoming-period-text': noActivePeriod && nextPeriod === period }">
				{{ period.long_name }}: {{ period.start_time | militaryTimeString }} - {{ period.end_time | militaryTimeString }}
			</span>
		</div>

		<ng-template #noPeriods>
			<div class="no-periods">
				SmartPass doesn't have<br />
				today's period times.
			</div>
		</ng-template>

		<mat-divider></mat-divider>
		<ng-container *ngIf="!isAdmin; else adminUserButton">
			<div class="footer-text">
				Schedule wrong?<br />
				Please contact your admin.
			</div>
		</ng-container>
	</ng-template>
</div>

<ng-template #adminUserButton>
	<div class="tw-flex tw-justify-around">
		<app-gradient-button [leftIcon]="'./assets/Edit (White).svg'" (click)="adminScheduleRoute()">Change Schedule</app-gradient-button>
	</div>
</ng-template>
