import { HttpService } from './http-service';
import { Observable } from 'rxjs';
import { AttendanceRecord } from '../models/AttendanceRecord';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

// AttendanceAddRecordsReq
export type AttendanceAddRecordsReq = {
	activity_instance_id: number;
	attendance_user_ids: number[];
	state: string;
};

// AttendanceRecordUpdateReq
export type AttendanceRecordUpdateReq = {
	id: number;
	state: string;
};

export type AttendanceRecordExportReq = {
	started_at: string;
	stopped_at: string;
};

@Injectable({
	providedIn: 'root',
})
export class AttendanceRecordService {
	constructor(private http: HttpService) {}

	getRecordsForInstanceId(activityInstanceId: number): Observable<AttendanceRecord[]> {
		return this.http
			.post<any>('v2/attendance/activity/list', { activity_instance_id: activityInstanceId }, undefined, false)
			.pipe(map((resp) => resp.attendance_records.map((a) => new AttendanceRecord(a))));
	}

	addRecords(req: AttendanceAddRecordsReq): Observable<AttendanceRecord[]> {
		return this.http
			.post<any>('v2/attendance/activity/add', req, undefined, false)
			.pipe(map((resp) => resp.attendance_records.map((a) => new AttendanceRecord(a))));
	}

	updateRecordState(req: AttendanceRecordUpdateReq): Observable<AttendanceRecord> {
		return this.http
			.post<any>('v2/attendance/activity/update', req, undefined, false)
			.pipe(map((resp) => resp.attendance_records.map((a) => new AttendanceRecord(a))[0]));
	}

	downloadRecords(req: AttendanceRecordExportReq): Observable<boolean> {
		return this.http.post<{ job_id: string }>('v2/attendance/activity/export', req, undefined, false).pipe(map(() => true));
	}
}
