import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { isArray, isNil } from 'lodash';
import { hasKeys } from 'Util';
import { HttpService } from './http-service';

interface Sticker {
	id: number;
	school_id: number;
	name: string;
	icon: string;
	color: string;
	visibility_type: string;
	visibility_grade: string[] | null;
	students_with_certain_visibility: unknown[] | null;
}

const STICKER_REQUIRED_KEYS: (keyof Sticker)[] = [
	'id',
	'school_id',
	'name',
	'icon',
	'color',
	'visibility_type',
	'visibility_grade',
	'students_with_certain_visibility',
];

function isSticker(obj: unknown): obj is Sticker {
	return (
		hasKeys(obj, STICKER_REQUIRED_KEYS) &&
		typeof obj.id === 'number' &&
		typeof obj.school_id === 'number' &&
		typeof obj.name === 'string' &&
		typeof obj.icon === 'string' &&
		typeof obj.color === 'string' &&
		(obj.visibility_type === null || typeof obj.visibility_type === 'string') &&
		(obj.visibility_grade === null || (isArray(obj.visibility_grade) && obj.visibility_grade.every((g) => typeof g === 'string')))
	);
}

export interface IDCard {
	userName?: string;
	schoolName?: string;
	userRole?: string;
	profilePicture?: string;
	idNumberData?: { idNumber: string; barcodeURL: any };
	greadLevel?: string;
	backgroundColor?: string;
	logoURL?: string;
	backsideText?: string;
	showCustomID?: boolean;
	barcodeType?: string;
	stickers?: Sticker[] | null;
}

export interface BarcodeTypes {
	label: string;
	action: string;
	icon: string;
	textColor: string;
	backgroundColor: string;
}

export interface IDCardResponse {
	results: {
		digital_id_card: IDCardDetails;
	};
}

export interface IDCardDetails {
	backside_text: string;
	barcode_type: 'qr-code' | 'code39';
	color: string; // hex color
	datetime_created: string; // ISO Date String with Timezone "2022-07-26T06:23:52.35771Z"
	enabled: boolean;
	id: number;
	logo_bucket: string;
	logo_file_name: string;
	logo_object_name: string;
	school_id: number;
	show_custom_ids: boolean;
	show_grade_levels: boolean;
	signed_url: string;
	visible_to_who: string;
	grade_colors: unknown;
	allow_apple_wallet: boolean;
	stickers?: Sticker[] | null;
}

const ID_CARD_DETAILS_REQUIRED_KEYS: (keyof IDCardDetails)[] = [
	'id',
	'color',
	'logo_file_name',
	'logo_bucket',
	'logo_object_name',
	'backside_text',
	'school_id',
	'enabled',
	'visible_to_who',
	'datetime_created',
	'barcode_type',
	'show_grade_levels',
	'show_custom_ids',
	'grade_colors',
	'signed_url',
	'allow_apple_wallet',
];

export const BARCODE_TYPES: BarcodeTypes[] = [
	{
		label: 'Traditional',
		icon: './assets/Barcode (Black).svg',
		textColor: '#7f879d',
		backgroundColor: '#F4F4F4',
		action: 'code39',
	},
	{
		label: 'QR Code',
		icon: './assets/QR Code (Black).svg',
		textColor: '#7f879d',
		backgroundColor: '#F4F4F4',
		action: 'qr-code',
	},
];

export function isIdCardResponse(obj: unknown): obj is IDCardResponse {
	return (
		hasKeys(obj, ['results']) &&
		hasKeys(obj.results, ['digital_id_card']) &&
		hasKeys(obj.results.digital_id_card, ID_CARD_DETAILS_REQUIRED_KEYS) &&
		typeof obj.results.digital_id_card.backside_text === 'string' &&
		typeof obj.results.digital_id_card.barcode_type === 'string' &&
		typeof obj.results.digital_id_card.color === 'string' &&
		typeof obj.results.digital_id_card.datetime_created === 'string' &&
		typeof obj.results.digital_id_card.enabled === 'boolean' &&
		typeof obj.results.digital_id_card.id === 'number' &&
		typeof obj.results.digital_id_card.logo_bucket === 'string' &&
		typeof obj.results.digital_id_card.logo_file_name === 'string' &&
		typeof obj.results.digital_id_card.logo_object_name === 'string' &&
		typeof obj.results.digital_id_card.school_id === 'number' &&
		typeof obj.results.digital_id_card.show_custom_ids === 'boolean' &&
		typeof obj.results.digital_id_card.show_grade_levels === 'boolean' &&
		typeof obj.results.digital_id_card.signed_url === 'string' &&
		typeof obj.results.digital_id_card.visible_to_who === 'string' &&
		typeof obj.results.digital_id_card.allow_apple_wallet === 'boolean' &&
		(isNil(obj.results.digital_id_card.stickers) ||
			(hasKeys(obj.results.digital_id_card, ['stickers']) &&
				isArray(obj.results.digital_id_card.stickers) &&
				obj.results.digital_id_card.stickers.every(isSticker)))
	);
}

@Injectable({
	providedIn: 'root',
})
export class IDCardService {
	stickerroomNameBlur$: Subject<any> = new Subject();

	constructor(private http: HttpService) {}

	// Function to check if a string is a valid color
	isValidColor(color: string): boolean {
		// Regular expression to match hex color code (#RRGGBB or #RGB)
		const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

		// Regular expression to match rgb/rgba color code
		const rgbColorRegex = /^(rgb(a)?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*(,\s*\d+\s*)?\))$/;

		// Regular expression to match color names (e.g., "red", "blue")
		const namedColorRegex = /^(red|blue|green|yellow|purple|orange|pink|brown|black|white)$/i;

		// Check if the color matches any of the valid formats
		return hexColorRegex.test(color) || rgbColorRegex.test(color) || namedColorRegex.test(color);
	}

	addIDCard(body: object) {
		return this.http.post('v1/id_card', body);
	}

	enableIDCard() {
		return this.http.patch('v1/id_card/enable');
	}

	disableIDCard() {
		return this.http.patch('v1/id_card/disable');
	}

	getIDCardDetails(): Observable<IDCardResponse> {
		return this.http.get('v1/id_card');
	}

	getStudentIDCardDetails(studentID: number) {
		return this.http.get(`v1/id_card/student/${studentID}`);
	}

	getIDCardDetailsEdit() {
		return this.http.get<IDCardResponse>('v1/id_card/edit');
	}

	updateIDCardField(body: object) {
		return this.http.patch('v1/id_card', body);
	}

	postStickers(body: unknown) {
		return this.http.post('v2/stickers', body, undefined, false);
	}

	patchStickers(body: object | undefined) {
		return this.http.patch('v2/stickers', body);
	}

	deleteSticker(body: unknown) {
		return this.http.deleteV2(`v2/stickers`, body);
	}
}
