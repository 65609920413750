<div *ngIf="cleverSyncInfo" class="ds-tooltip sync-tooltip">
	<p>
		Section Name: <span>{{ cleverSyncInfo.section_name }}</span>
	</p>
	<p>
		Course: <span>{{ cleverSyncInfo.course_name }}</span>
	</p>
	<p>
		Period: <span>{{ cleverSyncInfo.period_name }}</span>
	</p>
	<p>
		Term: <span>{{ cleverSyncInfo.term_name }}</span>
	</p>
	<p>
		Grade: <span>{{ cleverSyncInfo.grade }}</span>
	</p>
	<p>
		Subject: <span>{{ cleverSyncInfo.subject }}</span>
	</p>
	<p>
		Last Modified: <span>{{ cleverSyncInfo.last_modified | date : 'MMM d, h:mm a' }}</span>
	</p>
</div>

<div *ngIf="classlinkSyncInfo" class="ds-tooltip sync-tooltip">
	<p>
		Class Name: <span>{{ classlinkSyncInfo.section_name }}</span>
	</p>
	<p>
		Course: <span>{{ classlinkSyncInfo.course_name }}</span>
	</p>
	<p>
		Class Code: <span>{{ classlinkSyncInfo.class_code }}</span>
	</p>
	<p>
		Class Type: <span>{{ classlinkSyncInfo.class_type }}</span>
	</p>
	<p>
		Location: <span>{{ classlinkSyncInfo.location }}</span>
	</p>
	<p>
		Period: <span>{{ classlinkSyncInfo.period_name }}</span>
	</p>
	<p>
		Term: <span>{{ classlinkSyncInfo.term_name }}</span>
	</p>
	<p>
		Grade: <span>{{ classlinkSyncInfo.grades }}</span>
	</p>
	<p>
		Subject: <span>{{ classlinkSyncInfo.subjects }}</span>
	</p>
	<p>
		Last Modified: <span>{{ classlinkSyncInfo.last_modified | date : 'MMM d, h:mm a' }}</span>
	</p>
</div>

<div *ngIf="googleSyncInfo" class="ds-tooltip sync-tooltip">
	<p>
		Class Name: <span>{{ googleSyncInfo.name }}</span>
	</p>
	<p>
		Section: <span>{{ googleSyncInfo.section }}</span>
	</p>
	<p>
		Room: <span>{{ googleSyncInfo.room }}</span>
	</p>
	<p>
		Last Modified: <span>{{ googleSyncInfo.last_modified | date : 'MMM d, h:mm a' }}</span>
	</p>
</div>
