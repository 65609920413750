import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LiveDataService } from '../live-data/live-data.service';
import { HttpService } from './http-service';

@Injectable({
	providedIn: 'root',
})
export class EmergencyService {
	isEmergencyActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	constructor(private http: HttpService, private liveData: LiveDataService) {}

	isRightSchool(school_id): boolean {
		return this.http.getSchool().id == school_id;
	}

	addEmergency(body) {
		return this.http.post('v2/pass-events/add', body, undefined, false);
	}

	getEmergencyEvent(body) {
		return this.http.post(`v2/pass-events`, body, undefined, false);
	}

	endEmergencyEvent(body) {
		return this.http.post(`v2/pass-events/update`, body, undefined, false);
	}

	getEmergencyEventMessage(body) {
		return this.http.post(`v2/pass-events/messages`, body, undefined, false);
	}

	addEmergencyEventMessage(body) {
		return this.http.post(`v2/pass-events/messages/add`, body, undefined, false);
	}

	getCheckInList(body) {
		return this.http.post(`v2/pass-events/checkin/list`, body, undefined, false);
	}

	addCheckInList(body) {
		return this.http.post(`v2/pass-events/checkin/add`, body, undefined, false);
	}

	listenForEmergencyEventsCreate() {
		return this.liveData.watchCreatedEvents();
	}

	listenForEmergencyEventsCreatePublic() {
		return this.liveData.watchPublicCreatedEmergency();
	}

	listenForEmergencyEventsUpdatePublic() {
		return this.liveData.watchPublicUpdatedEmergency();
	}

	listenForEmergencyEventsUpdate() {
		return this.liveData.watchUpdatedEvents();
	}

	listenForEmergencyMessageUpdate() {
		return this.liveData.watchEmergencyMessages();
	}

	listenForEmergencyStudentCheckin() {
		return this.liveData.watchEmergencyStudentCheckin();
	}
}
