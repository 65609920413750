<div class="complete-wrapper">
	<div class="tri-wrapper">
		<div
			class="tri-time-container"
			(click)="startInput.focusOnHour()"
			[ngClass]="{
				active: startInputFocused,
				warning: initError > 1 && !!(control?.errors?.both || control?.errors?.start) && !startInputFocused && !endInputFocused,
				left: true
			}">
			<sp-time-input #startInput [isDisabled]="control?.disabled" [initTime]="initStartTime"></sp-time-input>
		</div>
		<div class="center-arrow" [ngClass]="{ active: startInputFocused || endInputFocused || !!(initError > 1 && control?.errors) }">
			<img [src]="'./assets/Arrow Short (Grey).svg' | resolveAsset" alt="Icon" />
		</div>
		<div
			class="tri-time-container"
			(click)="endInput.focusOnHour()"
			[ngClass]="{
				active: endInputFocused,
				warning: initError > 1 && !!(control?.errors?.both || control?.errors?.end) && !startInputFocused && !endInputFocused,
				right: true
			}">
			<sp-time-input #endInput [isDisabled]="control?.disabled" [initTime]="initEndTime"></sp-time-input>
		</div>
	</div>
	<div class="tri-error-msg" *ngIf="!startInputFocused && !endInputFocused && initError > 1 && !!control?.errors">
		<img
			*ngIf="initError > 1 && control?.errors && !startInputFocused && !endInputFocused"
			[src]="'./assets/error-02.svg' | resolveAsset"
			alt="Icon" />
		<div *ngIf="initError > 1 && control?.errors && !startInputFocused && !endInputFocused">
			<span *ngIf="control?.errors?.both; else startError">{{ control.errors.both }}</span>
			<ng-template #startError>
				<span *ngIf="control?.errors?.start; else endError">{{ control.errors.start }}</span>
			</ng-template>
			<ng-template #endError>
				<span *ngIf="control?.errors?.end">{{ control.errors.end }}</span>
			</ng-template>
		</div>
	</div>
</div>
