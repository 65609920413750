import { ConnectionPositionPair, Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeStyle } from '@angular/platform-browser';
import * as moment from 'moment';
import { BehaviorSubject, fromEvent, interval, Observable, Subject, timer } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Util } from '../../Util';
import { bumpIn, studentPassFadeInOut } from '../animations';
import { PositionPipe } from '../core/position.pipe';
import { DeviceDetection } from '../device-detection.helper';
import { PassLike } from '../models';
import { HallPass, HallPassStatus } from '../models/HallPass';
import { Invitation } from '../models/Invitation';
import { Request } from '../models/Request';
import { WaitingInLinePass } from '../models/WaitInLine';
import { TimeAgoPipe } from '../pipes/time-ago.pipe';
import { ReportFormComponent } from '../report-form/report-form.component';
import { DomCheckerService } from '../services/dom-checker.service';
import { HallPassesService } from '../services/hall-passes.service';
import { KioskModeService } from '../services/kiosk-mode.service';
import { hallPassInstance, KioskMonitoringService } from '../services/kiosk-monitoring.service';
import { TimeService } from '../services/time.service';
import { getFormattedPassDate, getInnerPassContent, isBadgeVisible } from './pass-display-util';

interface PassData {
	pass: PassLike;
	fromPast: boolean;
	forFuture: boolean;
	isActive: boolean;
	forStaff: boolean;
	forMonitor: boolean;
	kioskMode?: boolean;
	showStudentInfoBlock?: boolean;
}

@Component({
	selector: 'app-pass-tile',
	templateUrl: './pass-tile.component.html',
	styleUrls: ['./pass-tile.component.scss'],
	animations: [bumpIn, studentPassFadeInOut],
	providers: [DatePipe, TimeAgoPipe, PositionPipe],
})
export class PassTileComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
	@Input() mock = null;
	@Input() pass: PassLike;
	@Input() fromPast = false;
	@Input() forFuture: boolean;
	@Input() waitInLine: boolean;
	// just presents a less ''active'' version of pass tile
	@Input() presentational: boolean;
	@Input() isActive = false;
	@Input() forStaff = false;
	@Input() forMonitor = false;
	@Input() timerEvent: Subject<void>;
	@Input() allowPopup: boolean;
	@Input() profileImage: boolean;
	@Input() isEnableProfilePictures: boolean;
	@Input() isMiniCard: boolean;
	@Input() encounterPreventionToast: boolean;
	@Input() encounterPreventionDialog = false;
	@Input() disableFooterHover = false;
	@Input() disableReportButton = false;
	@Input() clickable = true;

	@Output() tileSelected = new EventEmitter<{ time$: Observable<string>; pass: PassLike }>();
	@Output() endPass = new EventEmitter<HallPass>();

	@ViewChild('studentPasses') studentPasses: ElementRef;
	@ViewChild('avatar') avatar: ElementRef;
	@ViewChild('passTileWrapper') passTileWrapper: ElementRef<HTMLDivElement>;

	pressed = false;
	timeLeft = '--:--';
	private valid = true;
	hovered: boolean;
	private hoverDestroyer$: Subject<void>;
	isOpenTooltip: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	showBackgroundOverlay: boolean;
	destroyAnimation$ = new Subject<void>();
	private destroyOpen$ = new Subject();
	disableClose$ = new Subject();

	tileName: string;
	backgroundGradient: string;
	footerBackgroundGradient: string;
	border: string;
	endedBgColorClass = '';
	private activePassTime$: BehaviorSubject<string> = new BehaviorSubject<string>('');

	overlayPositions: ConnectionPositionPair[];
	scrollStrategy;
	destroyCloseQuickPreview = false;

	private destroy$ = new Subject<void>();

	passCssClasses: string;
	boxShadow: SafeStyle;
	isKioskMode = false;
	tileContent: string;
	isBadgeVisible = false;
	passStatus: HallPassStatus;
	passFooterText: string;
	footerHover = false;
	topView = false;
	clickWholeTile = false;

	waitInLineEllipse: Observable<string> = timer(0, 750).pipe(
		takeUntil(this.destroy$),
		map((count) => `${'.'.repeat(count % 4)}`)
	);

	constructor(
		private dialog: MatDialog,
		private timeService: TimeService,
		public overlay: Overlay,
		private domCheckerService: DomCheckerService,
		private kioskMode: KioskModeService,
		private kioskMonitoring: KioskMonitoringService,
		private positionPipe: PositionPipe,
		private hallPassesService: HallPassesService
	) {}

	ngOnInit(): void {
		this.isKioskMode = !!this.kioskMode.getCurrentRoom().getValue();
		if ((this.pass && (this.pass instanceof HallPass || this.pass instanceof WaitingInLinePass)) || this.presentational) {
			this.getCssClasses();
			this.setStudentName();
			this.tileContent = this.getTileContent();
			this.isBadgeVisible = this.getIsBadgeVisible();
		}
		this.scrollStrategy = this.overlay.scrollStrategies.close();

		fromEvent(document, 'click')
			.pipe(takeUntil(this.destroy$))
			.subscribe((event: PointerEvent) => {
				// event.path is non-standard, event.composedPath() is standard
				const path: HTMLElement[] | undefined = event['path'] ?? (event?.composedPath && event.composedPath());
				if (
					path &&
					!path
						.map((e) => e?.tagName?.toLowerCase())
						.filter(Boolean)
						.includes('app-student-passes')
				) {
					this.hideStudentInfoTooltip();
				}
			});

		if (this.pass instanceof HallPass) {
			this.valid = this.isActive;
			this.valid = this.pass.expiration_time > this.timeService.nowDate();
			this.timeLeft = this.dateToClockTime(this.pass.expiration_time);
			this.activePassTime$.next(this.timeLeft);
			this.clickWholeTile = this.passStatus === 'upcoming' || this.passStatus === 'ended' || this.passStatus === 'waitinline';
			if (this.timerEvent && (this.passStatus === 'active' || this.passStatus === 'overtime')) {
				this.timerEvent
					.pipe(
						filter(() => !!this.pass && !!(this.pass as HallPass).expiration_time),
						takeUntil(this.destroy$)
					)
					.subscribe(() => {
						const end: Date = (this.pass as HallPass).expiration_time;
						const now: Date = this.timeService.nowDate();
						this.valid = end > now;
						this.timeLeft = this.dateToClockTime((this.pass as HallPass).expiration_time);
						this.activePassTime$.next(this.timeLeft);
						if (!this.valid) {
							this.passStatus = 'overtime';
							this.updateTileDisplay();
						}
					});
			}
		}
	}

	private setStudentName(): void {
		let studentName = this.pass.student.display_name;
		if (studentName.length > 14) {
			studentName = studentName.substring(0, 14) + '...';
		}
		this.tileName = studentName;
	}

	private dateToClockTime(date: Date): string {
		const now: Date = this.timeService.nowDate();
		if (date) {
			const diff: number = (date.getTime() - now.getTime()) / 1000;
			const mins: number = Math.floor(Math.abs(Math.floor(diff) / 60));
			const secs: number = Math.abs(Math.floor(diff) % 60);
			return mins + ':' + (secs < 10 ? '0' + secs : secs);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.profileImage) {
			this.overlayPositions = [
				{
					panelClass: 'student-panel1',
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
					offsetX: -154,
					offsetY: 202,
				},
				{
					panelClass: 'student-panel2',
					originX: 'start',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
					offsetX: 154,
					offsetY: 202,
				},
				{
					panelClass: 'student-panel3',
					originX: 'start',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'bottom',
					offsetX: 154,
					offsetY: 202,
				},
				{
					panelClass: 'student-panel4',
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'bottom',
					offsetX: -154,
					offsetY: 202,
				},
			];
		}

		if (changes.pass && !!changes.pass.currentValue) {
			this.pass = changes.pass.currentValue as HallPass;
			if (this.isActive) {
				this.passStatus = this.hallPassesService.getPassStatus(this.pass.start_time, this.pass.end_time, this.pass.expiration_time);
			} else if (this.encounterPreventionToast) {
				this.passStatus = 'ended';
			} else if (this.waitInLine) {
				this.passStatus = 'waitinline';
			} else {
				this.passStatus = this.hallPassesService.getPassStatus(this.pass.start_time, this.pass.end_time, this.pass.expiration_time);
			}
			this.updateTileDisplay();
		}
	}

	ngAfterViewInit(): void {
		this.setStudentName();
		setTimeout(() => {
			if (this.pass instanceof HallPass) {
				this.kioskMonitoring.markTraceCheckpoint(this.pass.id, 8, 'end:pass_in_tile', {
					domElementFound: !!this.passTileWrapper?.nativeElement,
					nativeDOMSelector: document.querySelector('div.tile-wrapper.for-staff.active'),
					isPass: hallPassInstance(this.pass),
				});
			}
		}, 2500);
	}

	private updateTileDisplay() {
		this.getCssClasses();
		this.setBackgroundColor();
		this.setBorderColor();
		this.getFooterText();
	}

	private getCssClasses(): void {
		this.passCssClasses = '';
		if (this.presentational) {
			this.passCssClasses = this.passCssClasses + ' presentational';
		}
		if (this.forStaff) {
			this.passCssClasses = this.passCssClasses + ' for-staff';
		} else {
			this.passCssClasses = this.passCssClasses + ' for-student';
		}
		if (this.isMiniCard) {
			this.passCssClasses = this.passCssClasses + ' mini-card';
		}
		this.passCssClasses = this.passCssClasses + ' ' + this.passStatus;
		if (this.passStatus === 'ended') {
			this.endedBgColorClass = `tw-bg-${this.pass.color_profile.title.toLowerCase().replace(' ', '-')}-150`;

			if (this.encounterPreventionToast) {
				this.passCssClasses = this.passCssClasses + ' encounter-prevention';
			}
		}
	}

	private getFooterText(): void {
		if (this.encounterPreventionToast) {
			this.passFooterText = 'Prevented';
			return;
		}

		if (!(this.pass as HallPass).end_time && !(this.pass as HallPass).expiration_time) {
			const startTime = moment((this.pass as HallPass).start_time);
			this.passFooterText = startTime.format('MMM D, h:mm A');
			return;
		}

		if (this.isMiniCard) {
			const passEnd = moment(this.isActive || this.forFuture ? (this.pass as HallPass).expiration_time : (this.pass as HallPass).end_time);
			const passStart = moment((this.pass as HallPass).start_time);
			const passLengthInSeconds = passEnd.diff(passStart, 'seconds');
			const passLengthInMinutes = Math.abs(passLengthInSeconds) / 60;
			const passLengthInHours = passLengthInMinutes / 60;
			const hoursToDisplay = Math.floor(passLengthInHours);
			let minutesToDisplay = Math.floor(passLengthInMinutes);
			const secondsToDisplay = passLengthInSeconds - minutesToDisplay * 60;

			this.passFooterText = '';
			if (hoursToDisplay > 0) {
				this.passFooterText = this.passFooterText + `${hoursToDisplay}:`;
				minutesToDisplay -= hoursToDisplay * 60;
				if (minutesToDisplay === 0) {
					this.passFooterText += '0';
				}
			}
			if (this.passStatus === 'ended' && !this.encounterPreventionDialog) {
				this.passFooterText =
					this.passFooterText +
					`${minutesToDisplay}:${secondsToDisplay.toLocaleString('en-US', {
						minimumIntegerDigits: 2,
						useGrouping: false,
					})} Total Time`;
			}
			if (this.passStatus === 'ended' && this.encounterPreventionDialog) {
				const endedTime = moment((this.pass as HallPass).end_time);
				this.passFooterText = endedTime.format('MMM D, h:mm A');
				if (endedTime.isSame(new Date(), 'day')) {
					this.passFooterText = `Today, ${endedTime.format('h:mm A')}`;
				}
			}
			if (this.passStatus === 'upcoming') {
				this.passFooterText = 'Scheduled';
			}
			if (this.presentational && this.passStatus === 'overtime') {
				this.passFooterText = 'Overtime';
			}
		} else {
			switch (this.passStatus) {
				case 'upcoming':
					{
						this.passFooterText = Util.formatRelativeTime((this.pass as HallPass).start_time);
					}
					break;
				case 'overtime':
					this.passFooterText = `Overtime`;
					break;
				case 'ended':
					{
						const endedTime = moment((this.pass as HallPass).end_time);
						this.passFooterText = endedTime.format('MMM D, h:mm A');
						if (endedTime.isSame(new Date(), 'day')) {
							this.passFooterText = `Today, ${endedTime.format('h:mm A')}`;
						}
					}
					break;
			}
		}
	}

	private setBackgroundColor(): void {
		const gradient: string[] = this.pass.color_profile.gradient_color.split(',');
		if (this.presentational) {
			if (this.passStatus !== 'ended') {
				this.backgroundGradient = 'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')';
			}
			if (this.passStatus === 'overtime') {
				this.backgroundGradient = 'linear-gradient(139.13deg, #E32C66 1.57%, #CB1B53 100%)';
			}
			if (this.passStatus === 'ended') {
				this.footerBackgroundGradient = this.pass.color_profile.solid_color;
			}
			return;
		}

		if (this.encounterPreventionToast) {
			this.backgroundGradient = 'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')';
			this.footerBackgroundGradient = '#e32c66';
			return;
		}

		switch (this.passStatus) {
			case 'overtime':
				this.backgroundGradient = 'linear-gradient(139.13deg, #E32C66 1.57%, #CB1B53 100%)';
				break;
			case 'active':
			case 'upcoming':
				this.backgroundGradient = 'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')';
				break;
			case 'waitinline':
				this.backgroundGradient =
					'linear-gradient(0deg, rgba(16, 20, 24, 0.60) 0%, rgba(16, 20, 24, 0.60) 100%), radial-gradient(circle at 73% 71%, ' +
					gradient[0] +
					', ' +
					gradient[1] +
					')';

				break;
			case 'ended':
				{
					if (!this.encounterPreventionToast) {
						this.footerBackgroundGradient = this.pass.color_profile.solid_color;
					}
				}
				break;
		}
	}

	private setBorderColor(): void {
		switch (this.passStatus) {
			case 'overtime':
				this.border = '';
				break;
			case 'active':
				this.border = '';
				break;
			case 'waitinline':
				this.border = '';
				break;
			case 'ended':
				this.border = `1px solid ${this.pass.color_profile.solid_color}33`;
				break;
			case 'upcoming':
				this.border = '';
				break;
		}
	}

	private getIsBadgeVisible(): boolean {
		if (this.encounterPreventionToast) {
			return false;
		}
		return (
			(isBadgeVisible(this.pass) && this.pass instanceof Invitation && !this.forStaff) ||
			(this.pass instanceof Invitation && this.pass.status === 'declined') ||
			(this.forStaff && this.pass instanceof Request)
		);
	}

	private getTileContent(): string {
		if (this.isActive) {
			return this.timeLeft + (this.valid ? ' Remaining' : ' Expiring');
		} else if (this.waitInLine) {
			const waitingInLineCopy = `${this.positionPipe.transform((this.pass as WaitingInLinePass).line_position)} in Line`;
			return (this.pass as WaitingInLinePass).isReadyToStart() ? 'Ready to Start!' : waitingInLineCopy;
		} else {
			return this.pass instanceof Request
				? this.pass.request_time && this.forFuture
					? !this.forStaff
						? getInnerPassContent(this.pass)
						: getFormattedPassDate(this.pass)
					: this.forStaff
					? 'Pass for Now'
					: ''
				: getInnerPassContent(this.pass, (!this.pass['request_time'] && this.pass instanceof Request) || !(this.pass instanceof Invitation));
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	onClick(event: PointerEvent | MouseEvent): void {
		event.stopImmediatePropagation();
		if (this.presentational) return;
		this.tileSelected.emit({ time$: this.activePassTime$, pass: this.pass });
	}

	onHover(evt: Event, container: HTMLElement): void {
		if (this.presentational) return;

		this.hoverDestroyer$ = new Subject<void>();
		const target = evt.target as HTMLElement;
		target.style.width = `auto`;
		target.style.transition = `none`;

		const targetWidth = target.getBoundingClientRect().width;
		const containerWidth = container.getBoundingClientRect().width;

		let margin = 0;
		interval(35)
			.pipe(takeUntil(this.hoverDestroyer$))
			.subscribe(() => {
				if (targetWidth - margin > containerWidth) {
					target.style.marginLeft = `-${margin}px`;
					margin++;
				}
			});
	}

	onLeave({ target: target }): void {
		if (this.presentational) return;

		target.style.marginLeft = '0px';
		target.style.transition = `margin-left .4s ease`;
		target.style.width = `100%`;

		this.hoverDestroyer$.next();
		this.hoverDestroyer$.complete();
	}

	setAnimationTrigger(value): void {
		if (this.presentational) return;

		if (!this.showBackgroundOverlay && !this.destroyCloseQuickPreview) {
			interval(200)
				.pipe(take(1), takeUntil(this.destroyAnimation$))
				.subscribe(() => {
					this.domCheckerService.fadeInOutTrigger$.next(value);
				});
		}
	}

	displayStudentInfoTooltip(): void {
		if (this.presentational) return;
		if (this.allowPopup && !this.isKioskMode) {
			this.disableClose$.next();
			this.setAnimationTrigger('fadeIn');
			interval(500)
				.pipe(take(1), takeUntil(this.destroyOpen$))
				.subscribe(() => {
					this.isOpenTooltip.next(true);
				});
		}
	}

	hideStudentInfoTooltip(): void {
		if (this.presentational) return;

		if (this.allowPopup && !this.isKioskMode && !this.destroyCloseQuickPreview) {
			this.destroyOpen$.next();
			interval(300)
				.pipe(take(1), takeUntil(this.disableClose$))
				.subscribe(() => {
					this.isOpenTooltip.next(false);
				});
		}
	}

	updateOverlayPosition(event): void {
		this.topView = event.connectionPair.originY === 'top';
		if (this.presentational) return;
	}

	overlayLeave(): void {
		if (this.presentational) return;

		this.showBackgroundOverlay = false;
		this.destroyOpen$.next();
	}

	openReport(event: Event): void {
		event.stopPropagation();
		const passData = this.preparePassData(this.pass);
		const isHallPass = this.pass instanceof HallPass;
		const data = {
			// to skip choosing the students
			// as the student's pass is to be reported
			report: this.pass.student,
			isHallPass,
			...passData,
		};

		this.dialog.open(ReportFormComponent, {
			panelClass: ['form-dialog-container', DeviceDetection.isIOSTablet() ? 'ios-report-dialog' : 'report-dialog'],
			hasBackdrop: false,
			data,
		});
	}

	private preparePassData(pass: PassLike): PassData {
		const now = this.timeService.nowDate();
		now.setSeconds(now.getSeconds() + 10);

		const fromPast = pass instanceof HallPass ? pass.end_time < now : false;
		const forFuture = pass instanceof HallPass ? pass.start_time > now : false;
		const isActive = !fromPast && !forFuture;
		const forStaff = true;
		const forMonitor = false;
		const kioskMode = !!this.kioskMode.getCurrentRoom().value;

		const data: PassData = {
			pass,
			fromPast,
			forFuture,
			isActive,
			forStaff: forStaff && !kioskMode,
			forMonitor,
			kioskMode: kioskMode,
			showStudentInfoBlock: !kioskMode,
		};

		return data;
	}
}
