<div class="tw-flex tw-flex-col tw-gap-y-6 tw-items-center tw-mt-24 tw-animate-fadein-200">
	<img class="tw-w-21" src="./assets/Class (Gray 200).svg" />
	<div class="tw-text-gray-500 tw-flex tw-flex-col tw-text-center">
		<div class="tw-text-lg tw-font-bold tw-mb-3">{{ title }}</div>
		<div class="tw-text-base tw-font-normal" [innerHTML]="subtitle"></div>
	</div>
	<div>
		<ng-content></ng-content>
	</div>
</div>
