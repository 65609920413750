import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';

const instatusScriptSrc = 'https://smartpass.instatus.com/en/f769c4cb/widget/script.js';
// test script 'https://smartpass-test.instatus.com/en/257ae047/widget/script.js'

@Component({
	selector: 'sp-outage-warning',
	template: "<script id='status-widget-container'></script>",
})
export class OutageWarningComponent implements AfterViewInit {
	constructor(private renderer2: Renderer2, private el: ElementRef) {}

	ngAfterViewInit(): void {
		this.showOutageWarning();
	}

	private showOutageWarning(): void {
		const script = this.renderer2.createElement('script');
		script.src = instatusScriptSrc;
		script.async = true;
		this.renderer2.appendChild(this.el.nativeElement, script);
	}
}
