export function relativeDate(inputDate: Date): string {
	const currentDate = new Date();
	currentDate.setHours(12, 0, 0, 0);

	const givenDate = new Date(inputDate);
	givenDate.setHours(12, 0, 0, 0);

	const timeDiff = currentDate.getTime() - givenDate.getTime();
	const oneDayInMillis = 24 * 60 * 60 * 1000;

	if (timeDiff === 0) {
		return 'Today';
	} else if (timeDiff === oneDayInMillis) {
		return 'Yesterday';
	} else if (timeDiff === -oneDayInMillis) {
		return 'Tomorrow';
	} else {
		const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		return daysOfWeek[givenDate.getDay()];
	}
}
