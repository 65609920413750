import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class CollapsibleStatesService {
	private collapsedStates = new Map<number, boolean>();

	setCollapsedState(classIndex: number, collapsed: boolean) {
		this.collapsedStates.set(classIndex, collapsed);
	}

	getCollapsedState(classIndex: number): boolean {
		return this.collapsedStates.get(classIndex);
	}

	hasCollapsedState(classIndex: number): boolean {
		return this.collapsedStates.has(classIndex);
	}
}
