<div class="tw-flex tw-flex-col {{ bgColor }} tw-rounded-xl {{ width }} tw-relative">
	<ng-container *ngFor="let datum of data; let index = index; let last = last">
		<div
			*ngIf="countToShow ? index <= countToShow : true"
			class="tw-border-0 tw-border-b tw-border-solid tw-border-gray-300"
			[ngClass]="{ 'tw-border-none': last }">
			<ng-container [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{ data: datum }"></ng-container>
		</div>
		<div *ngIf="hideRows && index === countToShow" class="see-all tw-absolute tw-bottom-0 tw-flex tw-justify-center tw-items-center tw-w-full">
			<div (click)="seeAllData()" class="tw-rounded-lg tw-bg-white tw-text-gray-500 tw-p-3 tw-cursor-pointer see-all-button">
				<img src="/src/assets/chevron-collapse.svg" /> See All {{ resourceName }}
			</div>
		</div>
	</ng-container>
</div>
