<div class="wrapper">
	<div class="group">
		<div class="label">Students</div>
		<app-sp-search
			[chipsMode]="true"
			[placeholder]="'Select students'"
			[inputField]="true"
			[selectedOptions]="group.users"
			(onUpdate)="group.users = $event; onUpdate()"
			[showStudentInfo]="false"></app-sp-search>
	</div>

	<div class="group">
		<div class="label">Group name <span style="font-weight: normal">(optional)</span></div>
		<app-input [width]="'245px'" [height]="'40px'" [maxLength]="'25'" [formGroup]="form" [input_value]="group.name" [controlName]="form.get('name')">
		</app-input>
	</div>

	<div class="group">
		<div class="label">Notes <span style="font-weight: normal">(optional)</span></div>
		<app-textarea
			[control]="form.get('notes')"
			[placeholder]="'Ex. Parents requested'"
			[width]="'351px'"
			[height]="'75px'"
			[maxLength]="150"></app-textarea>

		<div class="info">Students will not be able to see which students they are in a group with or details about the group.</div>
	</div>

	<!--  <div class="group">-->
	<!--    <div class="label">Weekly report emails</div>-->
	<!--    <app-sp-search-->
	<!--      [chipsMode]="true"-->
	<!--      [placeholder]="'Search staff'"-->
	<!--      [role]="'_profile_teacher'"-->
	<!--      [inputField]="true"-->
	<!--      [selectedOptions]="state.createGroup.teachers"-->
	<!--      (onUpdate)="state.createGroup.teachers = $event; onUpdate()"-->
	<!--    ></app-sp-search>-->

	<!--    <div class="info">-->
	<!--      Teachers and admins will get weekly summary reports of any prevented activity.-->
	<!--    </div>-->
	<!--  </div>-->
</div>
