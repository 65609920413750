import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, Subject } from 'rxjs';
import { SmartpassSearchService } from '../services/smartpass-search.service';
import { filter, tap } from 'rxjs/operators';
import { ProfileStatus, UserService } from '../services/user.service';
import { User } from '../models/User';

@Component({
	selector: 'app-smartpass-search',
	templateUrl: './smartpass-search.component.html',
	styleUrls: ['./smartpass-search.component.scss'],
	animations: [
		trigger('inputAnimate', [
			state(
				'open',
				style({
					width: '260px',
				})
			),
			state(
				'close',
				style({
					width: '242px',
				})
			),
			transition('open <=> close', animate('.3s ease')),
		]),
		trigger('logoAnimate', [
			state(
				'open',
				style({
					'margin-right': '12px',
					display: 'block',
				})
			),
			state(
				'close',
				style({
					'margin-right': '0',
					display: 'none',
				})
			),
			transition('open <=> close', animate('.3s ease')),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartpassSearchComponent implements OnInit, OnDestroy {
	@Input() focused: boolean;
	@Input() height = '30px';
	@Input() width: string;
	@Input() ignoreStatuses: ProfileStatus[] = [];

	isFocus: boolean;
	result: any[] = [];
	searchLoading$: Observable<boolean>;
	searchLoaded$: Observable<boolean>;
	searchResult$: Observable<any>;
	resetInputValue$: Subject<string> = new Subject<string>();
	user: User;

	private destroy$ = new Subject();

	constructor(
		private router: Router,
		private spSearchService: SmartpassSearchService,
		private userService: UserService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.searchResult$ = this.spSearchService.searchResult$;
		this.searchLoading$ = this.spSearchService.searchLoading$;
		this.searchLoaded$ = this.spSearchService.searchLoaded$;

		this.userService.userJSON$.pipe(
			filter((r) => !!r),
			tap((user) => {
				this.user = user;
			})
		);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	search(value) {
		if (!value) {
			this.spSearchService.clearResult();
			return;
		}
		this.spSearchService.searchRequest(value, this.ignoreStatuses);
	}

	goToUserPage(value) {
		this.router.navigateByUrl(`/main/student/${value.id}`);
		this.isFocus = false;
		this.resetInputValue$.next('');
		this.spSearchService.postSearchRequest(value.id);
		this.spSearchService.clearResult();
		this.cdr.detectChanges();
	}

	goToHomePage() {
		this.router.navigateByUrl(`/main/passes`);
		this.isFocus = false;
		this.resetInputValue$.next('');
		this.spSearchService.clearResult();
		this.cdr.detectChanges();
	}

	focusEvent(value) {
		this.isFocus = value;
		this.cdr.detectChanges();
	}
}
