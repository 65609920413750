import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { chain, difference, get, isEqual, isObject, keys, partialRight } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, concat, defer, fromEvent, identity, merge, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { EitherValueOrError, pickTruthy, recoverError } from '../../Util';
import { LoginResult, LoginServer } from './http-service';
import { StorageService } from './storage.service';

const LOGIN_SERVER_KEYS = ['api_root', 'client_id', 'client_secret', 'domain', 'icon_url', 'icon_search_url', 'name', 'ws_url'];
const AUTHENTICATION_URLS = ['v1/users/@me', 'v1/schools', 'v1/locations', 'v1/pinnables/arranged'];

function isLoginServerWrapper(obj: unknown): obj is { server: LoginServer } {
	return isObject(obj) && 'server' in obj && isLoginServer(obj['server']);
}

function isLoginServer(obj: unknown): obj is LoginServer {
	return isObject(obj) && LOGIN_SERVER_KEYS.every((key) => key in obj);
}

class UnexpectedLoginServerError extends Error {
	constructor(invalidValue: unknown) {
		if (!isObject(invalidValue)) {
			super(`invalid LoginServer, expected object, got ${typeof invalidValue}`);
		} else {
			const missingKeys = difference(LOGIN_SERVER_KEYS, keys(invalidValue));
			super(`invalid LoginServer, missing keys: ${missingKeys}`);
		}
	}
}

/**
 * Transforms the given arguments into to a decision for whether the login server
 * should be ignored.
 */
function addShouldIgnoreServer([loginServer, smartpassTokenExists, isUnauthenticatedError]: [
	EitherValueOrError<LoginServer>,
	EitherValueOrError<boolean>,
	EitherValueOrError<boolean>
]) {
	const errors = chain([loginServer.error, smartpassTokenExists.error, isUnauthenticatedError.error])
		.filter(identity)
		.map((e) => e.toString())
		.value();
	const applicationReasons = [];

	if (!smartpassTokenExists.value) {
		applicationReasons.push('smartpassToken does not exist');
	}

	if (isUnauthenticatedError.value) {
		applicationReasons.push('received http unauthenticated error response');
	}

	const reasons = [...applicationReasons, ...errors];

	if (reasons.length > 0) {
		return { loginServer, smartpassTokenExists, isUnauthenticatedError, shouldIgnoreServer: { value: true, reasons } };
	} else {
		return { loginServer, smartpassTokenExists, isUnauthenticatedError, shouldIgnoreServer: { value: false } };
	}
}

/**
 * This service is responsible for all operations related to authentication. This
 * includes deciding whether the user is logged in.
 *
 * It **is not** responsible for authorization.
 *
 * @remarks
 * In order to determine the authentication state of the user, the service uses three
 * indicators (mostly for legacy reasons):
 *
 * 1. The presence of a _valid_ `storage` value in local storage
 *
 * 2. The presence of _any_ `smartpassToken` (could be invalid)
 *
 * 3. The non-401 response of certain HTTP requests
 *
 * Due to the way the server behaves, it is not enough to detect whether the application
 * cookie is present, the service must also listen to the incoming HTTP responses. Only
 * certain routes are monitored for 401 responses to avoid improperly logging out a user
 * that is not authorized to access a route. Ideally, the server should be responding
 * with a 403 in that case, but that's consistently enforced as of writing.
 *
 * This logic is encapsulated in {@link addShouldIgnoreServer}.
 *
 * @see {@link addShouldIgnoreServer}
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService implements HttpInterceptor, OnDestroy {
	private static SERVER_KEY = 'server';
	private static COOKIE_POLL_INTERVAL = 2_500;

	/**
	 * Emits the most recent HTTP response.
	 */
	private httpErrorResponses$ = new Subject<HttpErrorResponse>();

	/**
	 * Emits the stored login server on subscribe, then whenever its value changes.
	 * If there's no server (because the user is not or should not be logged in),
	 * the observable emits a null.
	 */
	server$: Observable<LoginServer | null>;
	private serverSubscription: Subscription;

	/**
	 * Emits whether the user is logged in on subscribe, then whenever its value
	 * changes.
	 */
	isAuthenticated$: Observable<boolean>;

	/**
	 * Emits when authentication should be cleared.
	 */
	private logout$ = new Subject<void>();

	/**
	 * Emits whenever a login is attempted.
	 *
	 */
	private loginResult$ = new Subject<LoginResult>();

	constructor(private storageService: StorageService, private cookieService: CookieService) {
		const initialServer$ = defer(() => {
			return of(this.storageService.getItem(AuthenticationService.SERVER_KEY));
		});
		const logoutEvents$ = this.logout$.pipe(
			map(() => null),
			distinctUntilChanged()
		);
		const localStorageEvents$ = this.loginResult$.pipe(
			pickTruthy(),
			filter(({ ok }) => ok),
			map(({ server }) => JSON.stringify({ server }))
		);
		// externalStorageEvents$ only emit for observables from other contexts (ex other tabs)
		const externalStorageEvents$ = fromEvent<StorageEvent>(window, 'storage').pipe(
			filter((e) => e.key === AuthenticationService.SERVER_KEY),
			map((e) => e.newValue)
		);

		const smartpassTokenExists$ = timer(0, AuthenticationService.COOKIE_POLL_INTERVAL).pipe(
			map(() => this.cookieService.check('smartpassToken')),
			recoverError(),
			distinctUntilChanged()
		);

		const loginServer$ = concat(initialServer$, merge(logoutEvents$, localStorageEvents$, externalStorageEvents$)).pipe(
			map((stored) => {
				if (stored === null) {
					return null;
				}

				const parsed = JSON.parse(stored);
				if (isLoginServerWrapper(parsed)) {
					return parsed.server;
				} else {
					throw new UnexpectedLoginServerError(parsed);
				}
			}),
			recoverError()
		);

		const isUnauthenticatedError$ = concat(
			of(false),
			this.httpErrorResponses$.pipe(
				filter((resp) => resp.status === 401 && AUTHENTICATION_URLS.some((url) => resp.url?.includes(url))),
				map(() => true)
			)
		).pipe(recoverError());

		this.server$ = combineLatest([loginServer$, smartpassTokenExists$, isUnauthenticatedError$]).pipe(
			map(addShouldIgnoreServer),
			distinctUntilChanged(isEqual, partialRight(get, ['shouldIgnoreServer'])),
			tap(({ loginServer, shouldIgnoreServer }) => {
				if ((shouldIgnoreServer.value && loginServer.value) || loginServer.error) {
					console.warn('[Authentication Service] Ignoring server value', { loginServer, shouldIgnoreServer });
				}
			}),
			map(({ loginServer, shouldIgnoreServer }) => (shouldIgnoreServer.value ? null : loginServer.value)),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		this.isAuthenticated$ = this.server$.pipe(map((server) => server !== null));

		this.serverSubscription = this.server$.subscribe({
			next: (s) => {
				console.debug('[Authentication Service] login server', s);
			},
		});
	}

	/**
	 * Clears the current authentication session.
	 *
	 * @remarks
	 * Currently there are other parts of the application that handle (and interfere with)
	 * whether there's an actively authenticated user. Over the longer term, those instances
	 * should be migrated to call this function on logout, and use the `server$` observable
	 * or its other helpers to determine the authentication state.
	 *
	 */
	logout(): void {
		this.logout$.next();
	}

	/**
	 * Notifies the service of a login attempt.
	 *
	 * @remarks
	 * Currently the login attempt is handeled externally, but over the longer term, this
	 * service should be handling the login. A login function should take in authentication
	 * arguments and return whether the arguments are valid.
	 *
	 */
	loginAttempted(loginResult: LoginResult): void {
		this.loginResult$.next(loginResult);
	}

	ngOnDestroy(): void {
		this.serverSubscription.unsubscribe();
	}

	/**
	 * This function is an implementation for the {@link HttpInterceptor} interface.
	 * @inheritdoc
	 *
	 * @see {@link HttpInterceptor}
	 */
	intercept(req: HttpRequest<unknown>, next: HttpHandler) {
		return next.handle(req).pipe(
			catchError((err) => {
				if (err instanceof HttpErrorResponse) {
					this.httpErrorResponses$.next(err);
				}
				return throwError(err);
			})
		);
	}
}
