/*
 *
 * This component is used for modals for all pass types - waiting in line, hall pass,
 * requests and invitations.
 * It's inputs and emitters will vary by pass type.
 *
 */

import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { HallPass, HallPassStatus } from '../../../models/HallPass';
import { Invitation } from '../../../models/Invitation';
import { WaitingInLinePass } from '../../../models/WaitInLine';
import { Request } from '../../../models/Request';
import { Util } from '../../../../Util';

@Component({
	selector: 'sp-pass-modal-header',
	templateUrl: './pass-modal-header.component.html',
	styleUrls: ['./pass-modal-header.component.scss'],
})
export class PassModalHeaderComponent implements OnInit {
	// This input is used when a pass modal is within the hall pass creation workflow.
	// It is used to apply different styling when the pass is in the smaller form container,
	// except for when the pass is within the kiosk mode form flow.
	@Input() inFormContainer = false;

	// This input is used when a pass modal is within the hall pass creation workflow in kiosk mode.
	@Input() forKioskModeFormContainer = false;

	@Input() pass: HallPass | Request | Invitation | WaitingInLinePass;
	@Input() originRoomIcon: string;
	@Input() originRoomTitle: string;
	@Input() forStaff: boolean;
	@Input() displayBackButton: boolean; // requests
	@Input() openedFrom: string; // requests
	@Input() isMissed: boolean; // invitations and requests
	@Input() invalidDate: boolean; // invitations and requests
	@Input() optionsIcon: string; // wait in line pass
	@Input() isRecurringFuture: boolean; // hall passes
	@Input() recurringConfigTooltip: TemplateRef<any>; // hall passes
	@Input() passStatus: HallPassStatus | string; // hall passes use hallPassStatus enum, request and invitations use a string

	@Output() showOptionsEmit: EventEmitter<any> = new EventEmitter<any>(); // wait in line pass
	@Output() cancelEditEmit: EventEmitter<any> = new EventEmitter<any>(); // hall passes
	@Output() closeDialogEmit: EventEmitter<void> = new EventEmitter<void>(); // hall passes
	@Output() cancelRequestEmit: EventEmitter<any> = new EventEmitter<any>(); // requests
	@Output() displayTeacherPinEntryEmit: EventEmitter<boolean> = new EventEmitter<boolean>(); // requests
	@Output() denyInvitationEmit: EventEmitter<any> = new EventEmitter<any>(); // invitations

	headerTextColor = '#FFFFFF';
	fromTextColor = '#7083A0';
	headerSubtitleTextColor = '#FFFFFF';
	chipBgColor = '#FFFFFF';
	chipTextColor = '#7083A0';
	dividerColor = '#D4D9E250';
	iconColor = '#FFF';
	closeIcon = './assets/Dots (Transparent).svg';
	passDateTimeStr: string;
	topRightIcon: string;

	isHallPass: boolean;
	isRequest: boolean;
	isInvitation: boolean;
	isWaitInLinePass: boolean;

	ngOnInit(): void {
		// *************** HALL PASS *************** //
		if (this.pass instanceof HallPass) {
			this.isHallPass = true;
			this.headerSubtitleTextColor = '#fff';
			if (this.passStatus === 'upcoming' && this.inFormContainer) {
				this.headerTextColor = this.pass.color_profile.solid_color;
			}
			if (this.passStatus === 'upcoming' && !this.inFormContainer) {
				this.passDateTimeStr = Util.formatDateTime(this.pass.start_time);
			}
			this.chipBgColor = '#F0F2F5';
			if ((this.passStatus === 'upcoming' || this.passStatus === 'active') && !this.inFormContainer) {
				this.chipBgColor = '#ffffff33';
				this.chipTextColor = '#FFF';
			}
			if (this.passStatus === 'overtime') {
				this.chipBgColor = '#ffffff33';
				this.chipTextColor = '#FFF';
			}
			if (this.inFormContainer) {
				this.iconColor = this.pass.color_profile.solid_color;
				this.headerSubtitleTextColor = '#7083A0';
			} else {
				this.fromTextColor = '#FFF';
				this.dividerColor = '#ffffff33';
			}
			if (this.passStatus === 'ended') {
				this.headerTextColor = this.pass.color_profile.solid_color;
				this.iconColor = this.pass.color_profile.solid_color;
				this.chipTextColor = '#7083A0';
				this.fromTextColor = '#7083A0';
				this.chipBgColor = '#ffffff60';
				this.headerSubtitleTextColor = '#7083A0';
			}
			if (this.forKioskModeFormContainer) {
				this.headerTextColor = this.pass.color_profile.solid_color;
				this.iconColor = this.pass.color_profile.solid_color;
				this.dividerColor = '#D4D9E2';
				this.fromTextColor = '#7083A0';
				this.chipTextColor = '#7083A0';
				this.chipBgColor = '#F5F6F8';
				this.fromTextColor = '#7083A0';
			}
			if (!this.forStaff) {
				this.closeIcon = './assets/' + (this.inFormContainer ? 'Chevron Left ' : 'Delete ') + '(Transparent).svg';
			}
		}

		// *************** PASS REQUEST *************** //
		if (this.pass instanceof Request) {
			this.passStatus = this.pass.status;
			this.isRequest = true;
			this.headerTextColor = this.pass.color_profile.solid_color;
			if (this.passStatus === 'declined') {
				if (this.pass.request_time) {
					this.passDateTimeStr = Util.formatDateTime(this.pass.request_time);
				}
				this.chipTextColor = '#7083A0';
				this.chipBgColor = '#FFF';
			}
			if (this.passStatus === 'pending') {
				this.chipTextColor = '#7083A0';
				this.chipBgColor = '#F5F6F8';
			}
			if (this.passStatus === 'pending' && this.isMissed) {
				this.chipBgColor = '#FFFFFF';
			}
			this.headerSubtitleTextColor = '#7083a0';
			if (this.passStatus === 'declined' || this.passStatus === 'pending') {
				this.iconColor = this.pass.color_profile.solid_color;
			}
			if (this.inFormContainer) {
				this.chipTextColor = '#7083A0';
				this.chipBgColor = '#F0F2F5';
			}
			if (!this.isMissed) {
				if (this.pass.request_time) {
					this.passDateTimeStr = Util.formatDateTime(this.pass.request_time);
				}
				this.headerSubtitleTextColor = '#7083a0';
			}
		}

		// *************** INVITATION *************** //
		if (this.pass instanceof Invitation) {
			this.passStatus = this.pass.status;
			this.iconColor = this.pass.color_profile.solid_color;
			this.headerTextColor = this.pass.color_profile.solid_color;
			this.headerSubtitleTextColor = '#7083a0';
			if (this.passStatus === 'accepted') {
				this.iconColor = '#FFF';
				this.headerTextColor = '#FFF';
				this.headerSubtitleTextColor = '#FFF';
			}
			// forFuture && !isMissedInvitation
			if (!this.isMissed) {
				this.passDateTimeStr = Util.formatDateTime(this.pass.date_choices[0]);
			}
			this.isInvitation = true;
			let iconName: string;
			let iconColor = ' (Blue-Gray).svg';
			if (this.forStaff && !this.invalidDate) {
				iconName = 'Delete';
				if (this.inFormContainer) {
					iconName = 'Chevron Left';
				}
			} else {
				iconName = 'Dots';
			}
			if (this.passStatus === 'accepted') {
				iconColor = ' (White)';
			}
			if (this.forStaff) {
				this.topRightIcon = `./assets/${iconName}${iconColor}.svg`;
			}
			if (this.inFormContainer) {
				this.chipTextColor = '#7083A0';
				this.chipBgColor = '#F0F2F5';
			}
		}

		// *************** WAITING IN LINE *************** //
		if (this.pass instanceof WaitingInLinePass) {
			this.isWaitInLinePass = true;
			this.fromTextColor = '#FFF';
			this.chipBgColor = '#ffffff33';
			this.chipTextColor = '#FFF';
		}
	}
}
