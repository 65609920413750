import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { memo } from '../../../helpers/helper';
import { DateTimeFilterComponent } from '../../explore/date-time-filter/date-time-filter.component';
import { MatDialog } from '@angular/material/dialog';

export type TimeRange = { start: moment.Moment; end: moment.Moment };

@Component({
	selector: 'sp-new-time-range-picker',
	templateUrl: './new-time-range-picker.component.html',
	styleUrls: ['./new-time-range-picker.component.scss'],
})
export class NewTimeRangePicker {
	constructor(public dialog: MatDialog) {}

	@Input()
	selectedRange: TimeRange;

	@Output()
	onRangeSelected = new EventEmitter<TimeRange>();

	calendarOptions = { rangeId: 'range_6', toggleResult: 'Range' };

	getDateText = memo((start: moment.Moment, end: moment.Moment) => {
		if ((start && !end) || start.isSame(end, 'day')) {
			return start.format('MMM D');
		}

		if (end.isSame(moment(new Date()), 'day')) {
			if (start.isSame(moment(new Date()).subtract(3, 'days'), 'day')) {
				return 'Last 3 days';
			} else if (start.isSame(moment(new Date()).subtract(7, 'days'), 'day')) {
				return 'Last 7 days';
			} else if (start.isSame(moment(new Date()).subtract(30, 'days'), 'day')) {
				return 'Last 30 days';
			} else if (start.isSame(moment(new Date()).subtract(90, 'days'), 'day')) {
				return 'Last 90 days';
			}
		}

		return start.format('MMM D') + ' to ' + end.format('MMM D');
	});

	openDateTimePicker(event: Event) {
		const calendar = this.dialog.open(DateTimeFilterComponent, {
			id: 'calendar_filter',
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				target: new ElementRef(event),
				date: this.selectedRange,
				options: this.calendarOptions,
			},
		});

		calendar.afterClosed().subscribe(({ date, options }) => {
			console.log(date);
			this.selectedRange = date;
			this.calendarOptions = options;
			this.onRangeSelected.emit(date);
		});
	}
}
