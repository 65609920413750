<div class="wrapper" (mouseenter)="enterTooltip.emit()" (mouseleave)="leaveTooltip.emit()" [ngClass]="{ before: nonDisappearing }">
	<div class="tooltip-container" @tooltipAnimate>
		<ng-template #simpleText>
			<ng-container *ngIf="!allowVarTag; else allowed">
				<div class="ds-tooltip" *ngIf="text" [style.width]="width">
					{{ text }}
				</div>
			</ng-container>
			<ng-template #allowed>
				<div class="ds-tooltip" *ngIf="text" [style.width]="width" [innerHTML]="text"></div>
			</ng-template>
		</ng-template>
		<ng-container *ngTemplateOutlet="contentTemplate || simpleText"> </ng-container>
	</div>
</div>
