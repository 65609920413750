import * as React from 'react';

interface ITextButtonProps {
	text: string;
	onClick: () => void;
	color: 'gray' | 'green' | 'red';
	disabled?: boolean;
	className?: string;
	disabledClassName?: string;
}

export function TextButton({ text, onClick, color, disabled = false, className = '', disabledClassName = '' }: ITextButtonProps): React.ReactElement {
	let classes = '';

	switch (color) {
		case 'green':
			classes = 'tw-bg-green-500 tw-text-white';
			break;
		case 'red':
			classes = 'tw-bg-red-500 tw-text-white';
			break;
		case 'gray':
		default:
			classes = 'tw-bg-gray-100 tw-text-gray-500';
			break;
	}

	if (!disabled) {
		classes += ' tw-cursor-pointer hover:tw-opacity-85';
	} else {
		classes += ` ${disabledClassName}`;
	}

	return (
		<div
			className={`
                tw-text-button3
                tw-font-medium
                tw-rounded-lg
                tw-px-4
                tw-h-10
                tw-flex
                tw-items-center
                tw-text-center
				tw-max-w-full
                ${classes}
                ${className}
            `}
			onClick={() => !disabled && onClick()}>
			{text}
		</div>
	);
}
