<div class="standalone-wrapper">
	<img *ngIf="isStaff" class="printSVG" width="35" height="35" [src]="'./assets/Print (Gray-500).svg' | resolveAsset" (click)="print()" />
	<app-id-card
		[stickers]="IDCardstickers"
		[isLoggedIn]="true"
		[profile_picture]="IDCARDDETAILS?.profilePicture"
		[userName]="IDCARDDETAILS?.userName"
		[userRole]="IDCARDDETAILS?.userRole"
		[buttonBackColor]="'#F0F2F5'"
		[backgroundColor]="IDCARDDETAILS?.backgroundColor"
		[logoURL]="IDCARDDETAILS?.logoURL"
		[backsideText]="IDCARDDETAILS?.backsideText"
		[showCustomID]="IDCARDDETAILS?.showCustomID"
		[barcodeType]="IDCARDDETAILS?.barcodeType"
		[IDNumberData]="IDCARDDETAILS?.idNumberData"
		[greadLevel]="IDCARDDETAILS?.greadLevel">
	</app-id-card>
</div>
