import { Component, Input } from '@angular/core';
import { PassLimitInfo } from '../models/HallPassLimits';
import { NextStep } from '../animations';

declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

@Component({
	selector: 'app-pass-limit-info',
	templateUrl: './pass-limit-info.component.html',
	styleUrls: ['./pass-limit-info.component.scss'],
	animations: [NextStep],
})
export class PassLimitInfoComponent {
	@Input() passLimitInfo: PassLimitInfo;

	openIntercom() {
		window.Intercom('showNewMessage');
	}
}
