<div class="tw-mb-10">
	<div @adjustHeight class="search-box-container" [ngClass]="{ inactive: !isSearchFocused }" (click)="setFocusOnSearch()">
		<div *ngFor="let selection of selectedGroupsAndStudents">
			<app-selection [selection]="selection" [isUser]="selection.display_name" (removeSelectionEvent)="removeSelection($event)"></app-selection>
		</div>
		<input
			#searchInput
			class="dynamic-search-box"
			[placeholder]="selectedGroupsAndStudents.length === 0 ? 'Search students & groups' : ''"
			[(ngModel)]="searchText"
			(input)="searchInputChanges$.next($event.target.value)"
			(focus)="onSearchFocus(true)"
			(blur)="onSearchFocus(false)" />
	</div>
	<div
		*ngIf="isSearchFocused && (searchResults.length > 0 || (usersLoaded && (searchText.length > 0 || selectedGroupsAndStudents.length === 0)))"
		class="tw-animate-fadein-200 sc-search-results groups-students-list tw-w-100">
		<mat-list [style.padding-top]="'0px'">
			<div *ngIf="selectedGroupsAndStudents.length === 0 || (searchText.length > 0 && usersLoaded)">
				<mat-list-item
					*ngFor="let group of groups"
					class="sc-search group"
					(mousedown)="onSelect(group)"
					[style.background-color]="getBackground(_item)"
					#_item>
					<div class="sc-search selection">
						<img class="group-icon" src="assets/Groups Icon.svg" />
						<span class="sc-search name">{{ group.title }}</span>
						<span class="number-of-students">
							{{ group.users.length }}
							<ng-container [ngPlural]="group.users.length">
								<ng-template ngPluralCase="=1"> student</ng-template>
								<ng-template ngPluralCase="other"> students</ng-template>
							</ng-container>
						</span>
						<img *ngIf="canEditGroup && _item.hovered" class="edit-icon" src="assets/Edit (Blue-Gray).svg" (mousedown)="editGroup($event, group)" />
					</div>
				</mat-list-item>
			</div>
			<mat-list-item
				*ngFor="let result of searchResults"
				class="sc-search group"
				[ngClass]="{ group_selected: selectedGroup && selectedGroup.id === group.id, 'cant-add': result.alreadyIn || result.alreadySelected }"
				(mousedown)="!(result.alreadyIn || result.alreadySelected) && onSelect(result.student)"
				#_item>
				<div class="tw-animate-fadein-200 sc-search selection" [ngClass]="{ 'cant-add': result.alreadyIn || result.alreadySelected }">
					<div
						*ngIf="result.student.profile_picture; else d"
						[ngStyle]="{ background: 'url(' + result.student.profile_picture + ') no-repeat left center/cover' }"
						class="profile-picture"></div>
					<ng-template #d>
						<img class="profile-picture" src="assets/Avatar Default.svg" alt="Avatar" />
					</ng-template>
					<span class="sc-search name">{{ result.student.display_name }}</span>
					<div class="sc-search message" *ngIf="result.alreadyIn || result.alreadySelected">
						<img src="assets/Check (Gray).svg" alt="Can't add" /><span>{{ result.alreadyIn ? 'Already In This Class' : 'Already Selected' }}</span>
					</div>
				</div>
			</mat-list-item>
			<div *ngIf="searchResults?.length === 0 && searchText?.length > 0 && usersLoaded" class="no-results-found-container">No Results Found</div>
			<div
				*ngIf="searchText?.length === 0 && selectedGroupsAndStudents?.length === 0"
				[ngStyle]="{ 'min-height': 60 - this.groups.length * 12 + 'px' }"></div>
			<div *ngIf="selectedGroupsAndStudents?.length === 0 && searchText?.length === 0" class="upload-students-button-container">
				<div class="upload-students-button" (mousedown)="showImportStudentList()">Upload a list of students</div>
			</div>
		</mat-list>
	</div>
</div>
