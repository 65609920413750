<form [formGroup]="termForm">
	<div class="tw-relative">
		<div *ngIf="termForm.get('term_name')" class="tw-mb-3">
			<sp-input
				[group]="termForm"
				[isFocused]="isFocused"
				[control]="'term_name'"
				[label]="label"
				[displayPlaceholder]="true"
				[placeholder]="placeholder"
				[errorMessage]="termForm.errors?.invalidTermName"></sp-input>
		</div>
		<div
			class="ds-px-20 ds-py-10 tw-bg-white calendar-wrapper tw-absolute tw-rounded-xl"
			[ngClass]="{ 'tw-left-0': calendarFor === 'start', 'tw-right-0': calendarFor === 'end' }"
			*ngIf="openCalendar"
			#calendar>
			<app-calendar-picker
				[min]="minDate"
				[max]="maxDate"
				[outsideRangeTooltip]="outsideRangeTooltip"
				[showWeekend]="false"
				[width]="300"
				[showTime]="false"
				[selectedDates]="[selectedDate]"
				(onSelectDate)="calendarResult($event)"
				[tooltipDates]="tooltipDates"
				[exclusionId]="exclusionId"
				[showYear]="false">
			</app-calendar-picker>
		</div>
		<div class="tw-flex tw-justify-between">
			<div class="tw-w-1/2 tw-mr-5">
				<p class="tw-mb-2 tw-text-base tw-font-bold tw-text-navy-500 starts-header">Starts</p>
				<div
					[showToolTip]="disableStartDate == true"
					customToolTip
					[position]="'top'"
					[contentTemplate]="disableStartDateTooltip"
					[ngClass]="{ error: termForm.errors?.invalidStartDate || termForm.errors?.invalidDateRange }"
					class="tw-cursor-pointer tw-relative tw-flex tw-justify-between tw-rounded-lg tw-bg-gray-050 tw-border tw-border-gray-150 tw-border-solid tw-p-2.5"
					(click)="!disableStartDate && !openCalendar && toggleCalendar('start')"
					style="min-height: 16px">
					<p class="tw-m-0 tw-text-sm tw-font-normal" [ngClass]="termForm.get('start_date').value ? 'tw-text-navy-500' : 'tw-text-gray-500'">
						{{ termForm.get('start_date').value ? (termForm.get('start_date').value | date : 'MMMM d, yyyy' : 'UTC') : 'Select start date' }}
					</p>
					<img *ngIf="!disableStartDate" class="tw-w-4" [src]="'./assets/Chevron Down (Gray-500).svg' | resolveAsset" />
					<img *ngIf="disableStartDate == true" class="tw-w-4 tw-z-10" [src]="'./assets/Exclamation Mark Circle (Gray-500).svg' | resolveAsset" />
				</div>

				<div *ngIf="termForm.errors?.invalidStartDate" class="tw-text-red-500 tw-text-sm tw-flex tw-items-center tw-pt-3 tw-gap-1">
					<img [src]="'./assets/error-02.svg' | resolveAsset" alt="Icon" />
					<div class="tw-whitespace-nowrap">
						{{ termForm.errors?.invalidStartDate }}
						<span
							[showToolTip]="termsWithClassesBeingDeleted?.length > 0"
							customToolTip
							[position]="'top'"
							[additionalOffsetY]="-20"
							[additionalOffsetY]="-20"
							[contentTemplate]="startDateTooltipLearnMore"
							*ngIf="termsWithClassesBeingDeleted?.length > 0"
							class="tw-underline"
							>Learn more</span
						>
					</div>
				</div>
				<ng-template #startDateTooltipLearnMore>
					<sp-tooltip [contentTemplate]="errorMessageTooltipContent"></sp-tooltip>
				</ng-template>
				<div *ngIf="termForm.errors?.invalidDateRange" class="tw-text-red-500 tw-text-sm tw-flex tw-items-center tw-pt-3 tw-gap-1">
					<img [src]="'./assets/error-02.svg' | resolveAsset" alt="Icon" /> {{ termForm.errors?.invalidDateRange }}
				</div>
			</div>
			<ng-template #disableStartDateTooltip>
				<div class="ds-tooltip" style="width: 223px">This start date has passed and cannot be edited.</div>
			</ng-template>
			<div class="tw-w-1/2">
				<p class="tw-mb-2 tw-text-base tw-font-bold tw-text-navy-500 ends-header">Ends</p>
				<div
					[ngClass]="{ error: termForm.errors?.invalidEndDate }"
					(click)="!openCalendar && toggleCalendar('end')"
					class="tw-cursor-pointer tw-relative tw-flex tw-justify-between tw-rounded-lg tw-bg-gray-050 tw-border tw-border-gray-150 tw-border-solid tw-p-2.5"
					style="min-height: 16px">
					<p class="tw-m-0 tw-text-sm tw-font-normal" [ngClass]="termForm.get('end_date').value ? 'tw-text-navy-500' : 'tw-text-gray-500'">
						{{ termForm.get('end_date').value ? (termForm.get('end_date').value | date : 'MMMM d, yyyy' : 'UTC') : 'Select end date' }}
					</p>
					<img class="tw-w-4" [src]="'./assets/Chevron Down (Gray-500).svg' | resolveAsset" />
				</div>

				<div *ngIf="termForm.errors?.invalidEndDate" class="tw-text-red-500 tw-text-sm tw-flex tw-items-center tw-pt-3 tw-gap-1">
					<img [src]="'./assets/error-02.svg' | resolveAsset" alt="Icon" />
					<div class="tw-whitespace-nowrap">
						{{ termForm.errors?.invalidEndDate }}
						<span
							[showToolTip]="termsWithClassesBeingDeleted.length > 0"
							customToolTip
							[position]="'top'"
							[additionalOffsetY]="-20"
							[contentTemplate]="endDateTooltipLearnMore"
							*ngIf="termsWithClassesBeingDeleted.length > 0"
							class="tw-underline"
							>Learn more</span
						>
					</div>
					<ng-template #endDateTooltipLearnMore>
						<sp-tooltip [contentTemplate]="errorMessageTooltipContent"></sp-tooltip>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</form>

<ng-template #errorMessageTooltipContent>
	This date can’t be changed to <span class="tw-font-bold">{{ dateBeingChanged.format('MMMM D, YYYY') }}</span> because
	<span class="tw-font-bold" *ngFor="let term of termsWithClassesBeingDeleted; let first = first; let last = last"
		><span *ngIf="last && termsWithClassesBeingDeleted.length > 1" class="tw-font-normal"> and </span>{{ term.term_name
		}}<span *ngIf="!first && !last">, </span></span
	>
	already {{ termsWithClassesBeingDeleted.length === 1 ? 'has' : 'have' }} classes and can’t be removed.
</ng-template>
