import * as React from 'react';

interface IImageButtonProps {
	defaultImage: string;
	pressedImage?: string;
	disabledImage?: string;
	alt?: string;
	disabled?: boolean;
	className?: string;
	disabledClassName?: string;
	pressedClassName?: string;
	hoverClassName?: string;
	onClick: () => void;
}

export function ImageButton({
	defaultImage,
	pressedImage,
	disabledImage,
	alt,
	disabled = false,
	onClick,
	className = '',
	disabledClassName = '',
	pressedClassName = '',
	hoverClassName = '',
}: IImageButtonProps) {
	const [isPressed, setIsPressed] = React.useState(false);

	const getImage = (): string => {
		if (disabled) return disabledImage || defaultImage;
		if (isPressed) return pressedImage || defaultImage;
		return defaultImage;
	};

	const handleMouseDown = (): void => {
		if (!disabled) setIsPressed(true);
	};

	const handleMouseUp = (): void => {
		if (!disabled) setIsPressed(false);
	};

	const handleClick = (): void => {
		if (!disabled && onClick) {
			onClick();
		}
	};

	return (
		<img
			src={getImage()}
			alt={alt}
			aria-label={alt}
			className={`
                tw-cursor-${disabled ? 'default' : 'pointer'}
                ${className}
                hover:${!disabled && !isPressed ? hoverClassName : ''}
                ${disabled ? disabledClassName : ''}
                ${isPressed && !disabled ? pressedClassName : ''}
                tw-transition-opacity
                tw-duration-200
                ${disabled ? 'tw-opacity-50' : 'tw-opacity-100'}
				tw-max-w-full
            `}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseLeave={handleMouseUp}
			onClick={handleClick}
		/>
	);
}
