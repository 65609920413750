<div [ngClass]="{ 'for-staff': forStaff, 'in-form-container': inFormContainer }" *ngIf="pass">
	<div class="pass-card-header">
		<div class="header-content">
			<div class="header-info">
				<sp-room-icon
					[iconPath]="pass.icon"
					[height]="inFormContainer ? '28px' : '48px'"
					[width]="inFormContainer ? '28px' : '48px'"
					[iconColor]="iconColor"
					[altText]="pass.destination.title"></sp-room-icon>

				<div class="pass-card-header-text" [style.color]="headerTextColor">
					{{ pass.destination.title }}
					<div notranslate class="card-subtitle" *ngIf="passDateTimeStr" [style.color]="headerSubtitleTextColor">
						{{ passDateTimeStr }}
					</div>
					<div class="card-subtitle" *ngIf="!passDateTimeStr && !inFormContainer" [style.color]="headerSubtitleTextColor">
						{{ pass.travel_type === 'one_way' ? 'One-way' : 'Round-trip' }}
					</div>
				</div>
				<div
					customToolTip
					[contentTemplate]="recurringConfigTooltip"
					[width]="'281px'"
					[showToolTip]="isRecurringFuture"
					*ngIf="isRecurringFuture"
					style="display: inline-block">
					<img style="width: 17px" class="ds-ml-10" [src]="'./assets/Repeat (White).svg' | resolveAsset" alt="Recurring Future Pass Icon" />
				</div>

				<ng-container *ngIf="!inFormContainer">
					<ng-container *ngIf="isWaitInLinePass">
						<ng-container [ngTemplateOutlet]="wilTopRightButton"></ng-container>
					</ng-container>
					<ng-container *ngIf="isHallPass">
						<ng-container [ngTemplateOutlet]="passTopRightButton"></ng-container>
					</ng-container>
					<ng-container *ngIf="isRequest">
						<ng-container [ngTemplateOutlet]="requestTopRightButton"></ng-container>
					</ng-container>
					<ng-container *ngIf="isInvitation">
						<ng-container [ngTemplateOutlet]="invitationTopRightButton"></ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="mat-divider-container" *ngIf="originRoomTitle">
			<mat-divider [style.border-top-width]="inFormContainer ? '2px' : '3px'" [style.border-color]="dividerColor"></mat-divider>
			<span [style.color]="fromTextColor"> from</span>
			<div class="origin-room" [style.color]="chipTextColor" [style.background-color]="chipBgColor">
				<div class="origin-room-icon">
					<sp-room-icon
						[iconPath]="originRoomIcon"
						[height]="'20px'"
						[width]="'20px'"
						[iconColor]="chipTextColor"
						[altText]="originRoomTitle"></sp-room-icon>
				</div>
				<div>{{ originRoomTitle }}</div>
			</div>
		</div>
	</div>
</div>
<ng-template #wilTopRightButton>
	<app-icon-button [srcString]="optionsIcon" [onDarkShade]="true" [size]="26" (clickEvent)="showOptionsEmit.emit($event)"></app-icon-button>
</ng-template>

<ng-template #passTopRightButton>
	<app-icon-button
		*ngIf="passStatus === 'upcoming' && pass.id > 0 && (forStaff || (!forStaff && pass.cancellable_by_student))"
		[srcString]="closeIcon"
		[onDarkShade]="true"
		[size]="26"
		(clickEvent)="cancelEditEmit.emit($event)"></app-icon-button>
</ng-template>

<ng-template #requestTopRightButton>
	<app-icon-button
		[backgroundColor]="'#1014180A'"
		[newIconButton]="true"
		[color]="'Blue-Gray'"
		[onDarkShade]="false"
		[blueWhite]="false"
		[switchColor]="false"
		*ngIf="!displayBackButton && openedFrom !== 'navbar'"
		[srcString]="
			'./assets/' +
			(!forStaff && !inFormContainer && !invalidDate ? 'Delete' : !inFormContainer && (forStaff || invalidDate) ? 'Dots' : 'Chevron Left') +
			' (Blue-Gray).svg'
		"
		[size]="inFormContainer ? 14 : 26"
		(clickEvent)="cancelRequestEmit.emit($event)"></app-icon-button>
	<app-icon-button
		[backgroundColor]="'#1014180A'"
		[newIconButton]="true"
		*ngIf="displayBackButton"
		[srcString]="'./assets/Chevron Left (White).svg'"
		[onDarkShade]="false"
		[switchColor]="false"
		[size]="26"
		(clickEvent)="displayTeacherPinEntryEmit.emit(false)"></app-icon-button>
</ng-template>

<ng-template #invitationTopRightButton>
	<app-icon-button
		*ngIf="topRightIcon"
		[backgroundColor]="'#1014180A'"
		[newIconButton]="true"
		[srcString]="topRightIcon"
		[switchColor]="false"
		[size]="26"
		[onDarkShade]="passStatus === 'pending' || passStatus === 'declined' ? false : true"
		(clickEvent)="denyInvitationEmit.emit($event)"></app-icon-button>
</ng-template>
