import { Pipe, PipeTransform } from '@angular/core';
import { Invitation } from '../models/Invitation';
import { Request } from '../models/Request';

@Pipe({
	name: 'requestdate',
})
export class RequestDatePipe implements PipeTransform {
	transform(value: Request | Invitation): Date {
		return value instanceof Invitation ? value?.date_choices?.[0] || value.created : value?.request_time || value.created;
	}
}
