import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageKeys, StorageService } from '../services/storage.service';
import { DynamicStylingService } from 'app/dynamic-styling.service';
import { TABBED_CONTENT_MODAL } from 'app/dynamic-styling-consts';
export type TabStyle = 'cards' | 'full-width';
export type Tabs = {
	title: string;
	tabContentTemplate?: TemplateRef<any>;
	tabStyle: TabStyle;
	disableScroll: boolean;
};
export type TabbedContentModalData = {
	icon?: string;
	title: string;
	subtitle: string;
	displayCloseIcon: boolean;
	tabs: Tabs[];
	selectedTabIndex: number;
};

const MAT_TAB_HEADER_HEIGHT = 44;
const MODAL_HEIGHT = 836;
const MODAL_TOP_AND_BOTTOM_MARGINS = 146; // 73 px each
@Component({
	selector: 'sp-tabbed-content-modal',
	templateUrl: './tabbed-content-modal.component.html',
	styleUrls: ['./tabbed-content-modal.component.scss'],
})
export class TabbedContentModalComponent implements OnInit, AfterViewInit {
	@ViewChild('header', { static: false, read: ElementRef }) header: ElementRef | undefined;
	@ViewChild('content', { static: false, read: ElementRef }) content: ElementRef | undefined;
	@ViewChild('tabbedContentModal', { static: false, read: ElementRef }) tabbedContentModal: ElementRef | undefined;

	selectedTabIndex = 0;
	modalHeightStr = 'auto';
	modalHeight = MODAL_HEIGHT;
	headerHeight = 0;
	contentHeightStr = 'auto';
	contentHeight = 0;
	constructor(
		public dialogRef: MatDialogRef<TabbedContentModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TabbedContentModalData,
		private cdr: ChangeDetectorRef,
		private storage: StorageService,
		private dynamicStylingService: DynamicStylingService
	) {}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.updateModalHeight();
	}

	ngOnInit(): void {
		if (this.data?.selectedTabIndex) {
			this.selectedTabIndex = this.data.selectedTabIndex;
		} else {
			if (this.storage.getItem(StorageKeys.schoolYearTabIndex)) {
				this.selectedTabIndex = Number(this.storage.getItem(StorageKeys.schoolYearTabIndex));
			}
		}
	}

	ngAfterViewInit(): void {
		this.updateModalHeight();
		if (this.tabbedContentModal) {
			this.dynamicStylingService.registerElement(TABBED_CONTENT_MODAL, this.tabbedContentModal);
		}
	}

	private updateModalHeight(): void {
		this.modalHeightStr = `${MODAL_HEIGHT}px`;
		const modalHeight = window.innerHeight - MODAL_TOP_AND_BOTTOM_MARGINS;
		if (modalHeight < MODAL_HEIGHT) {
			this.modalHeightStr = `${modalHeight}px`;
			this.modalHeight = modalHeight;
		}
		this.dialogRef.updateSize('580px', this.modalHeightStr);

		this.headerHeight = this.header?.nativeElement.getBoundingClientRect().height + MAT_TAB_HEADER_HEIGHT;
		this.contentHeight = window.innerHeight - MODAL_TOP_AND_BOTTOM_MARGINS - this.headerHeight;
		this.contentHeightStr = `${this.contentHeight + 44}px`;

		this.cdr.detectChanges();
	}

	onSelectTab(index: number): void {
		this.selectedTabIndex = index;
		this.storage.setItem('selectedTabIndex', index);
	}
}
