<div class="currently-in-wrapper">
	<div class="currently-in">
		<ng-container *ngIf="roomOptions && selectedLocation; else noRooms">
			<div class="label">Currently In</div>
			<div class="selected-teacher" appCrossPointerEventTarget (click)="displayOptionsPopover(target)" #target>
				{{ selectedLocation.title || roomOptions[0].title }}
				<img *ngIf="roomOptions?.length > 1" src="assets/icon-arrow-down.svg" class="arrow-down" />
			</div>
		</ng-container>
		<ng-template #noRooms>
			<div class="label">Currently Unavailable</div>
		</ng-template>
	</div>
	<div #twinkieMenu *ngIf="roomOptions?.length > 0">
		<app-twinkie-button (buttonClick)="onClickOptions()"></app-twinkie-button>
	</div>
</div>
