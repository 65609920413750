<div
	class="no-fly-time-container"
	*ngIf="tooltipType === 'staff'"
	[style.background]="background"
	[style.width]="width"
	[style.height]="height"
	[style.border-radius]="borderRadius"
	[style.padding]="padding">
	<div *ngIf="!!close; else noClose" class="icon-container">
		<img class="no-fly-time-icon" [src]="'./assets/no_fly_time.svg' | resolveAsset" />
		<img class="close-icon" [src]="'./assets/Close (White).svg' | resolveAsset" alt="" (click)="close()" />
	</div>
	<ng-template #noClose>
		<img class="no-fly-time-icon" [src]="'./assets/no_fly_time.svg' | resolveAsset" />
	</ng-template>

	<div class="no-fly-time-text">
		<span class="header-text"> No Fly Time </span>
		<span> {{ isStaff ? 'Students' : 'You' }} can't create</span>
		<span> passes during these times: </span>
	</div>

	<div class="no-fly-time-list">
		<span *ngFor="let nft of nftTextList; let i = index" [ngClass]="{ 'active-no-fly-time-text': i == currentNoFlyTimeIndex }">
			{{ nft }}
		</span>
	</div>
</div>

<div class="no-fly-time-container-student" *ngIf="tooltipType === 'student'" [style.background]="background" [style.border-radius]="borderRadius">
	<div class="no-fly-time-header">
		<img class="no-fly-time-icon" [src]="'./assets/no-fly-time (2).svg' | resolveAsset" />
		<span class="header-text"> NO FLY TIME </span>
	</div>

	<span class="no-fly-time-student-text">
		<span> You can create passes </span>
		<span> again at {{ currentEndTimeString }}.</span>
	</span>
</div>
