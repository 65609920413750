<div class="wrapper ds-tooltip">
	<div class="main-content">
		<img *ngIf="isHand" class="image" [src]="'./assets/Hand (White).svg' | resolveAsset" alt="stop" />
		<div class="content">
			{{ description }}
		</div>
	</div>

	<div class="admin-can-edit" *ngIf="adminCanEdit">Only admin can edit.</div>
</div>
