import { Component, Input, OnInit } from '@angular/core';

export type TagType = 'default' | 'pill' | 'secondary';
export type TagStyle = 'primary' | 'gray' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

// these are determined by type
export type TypeClasses = {
	fontSizeClass: string;
	fontWeightClass: string;
	fontStyleClass: string;
	borderRadiusClass: string;
};

// these are determined by both type and style
export type StyleClasses = {
	backgroundColorClass: string;
	fontColorClass: string;
};

@Component({
	selector: 'sp-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {
	@Input() type: TagType = 'default';
	@Input() style: TagStyle = 'primary';

	paddingStyle: string;
	typeClasses: TypeClasses;
	styleClasses: StyleClasses;

	ngOnInit(): void {
		this.paddingStyle = this.getPaddingStyle(this.type);
		this.typeClasses = this.getClassesByType(this.type);
		this.styleClasses = this.getClassesByTypeAndStyle(this.type, this.style);
	}
	private getPaddingStyle(type: TagType): string {
		switch (type) {
			case 'default':
			case 'pill':
				return '2px 6px 2px 6px';
				break;
			case 'secondary':
				return '4px 6px 4px 6px';
				break;
		}
	}
	private getClassesByType(type: TagType): TypeClasses {
		switch (type) {
			case 'default':
				return {
					borderRadiusClass: 'tw-rounded',
					fontSizeClass: 'tw-text-xs',
					fontWeightClass: 'tw-font-bold',
					fontStyleClass: 'tw-uppercase',
				};
				break;
			case 'pill':
				return {
					borderRadiusClass: 'tw-rounded-full',
					fontSizeClass: 'tw-text-xs',
					fontWeightClass: 'tw-font-bold',
					fontStyleClass: 'tw-uppercase',
				};
				break;
			case 'secondary':
				return {
					borderRadiusClass: 'tw-rounded',
					fontSizeClass: 'tw-text-sm',
					fontWeightClass: 'tw-font-normal',
					fontStyleClass: 'tw-capitalize',
				};
				break;
		}
	}
	private getClassesByTypeAndStyle(type: TagType, style: TagStyle): StyleClasses {
		let backgroundColorClass: string;
		let fontColorClass = 'tw-text-white';
		switch (type) {
			case 'default':
				switch (style) {
					case 'primary':
						backgroundColorClass = 'tw-bg-green-500';
						break;
					case 'gray':
						backgroundColorClass = 'tw-bg-gray-500';
						break;
					case 'danger':
						backgroundColorClass = 'tw-bg-red-500';
						break;
					case 'warning':
						backgroundColorClass = 'tw-bg-orange-500';
						break;
					case 'info':
						backgroundColorClass = 'tw-bg-light-blue-500';
						break;
					case 'light':
						backgroundColorClass = 'tw-bg-white';
						break;
					case 'dark':
						backgroundColorClass = 'tw-bg-gray-900';
						break;
				}
				break;
			case 'pill':
				switch (style) {
					case 'primary':
						backgroundColorClass = 'tw-bg-green-500';
						break;
					case 'gray':
						backgroundColorClass = 'tw-bg-gray-500';
						break;
					case 'danger':
						backgroundColorClass = 'tw-bg-red-500';
						break;
					case 'warning':
						backgroundColorClass = 'tw-bg-orange-500';
						break;
					case 'info':
						backgroundColorClass = 'tw-bg-light-blue-500';
						break;
					case 'light':
						backgroundColorClass = 'tw-bg-white';
						break;
					case 'dark':
						backgroundColorClass = 'tw-bg-gray-900';
						break;
				}
				break;
			case 'secondary':
				switch (style) {
					case 'primary':
						fontColorClass = 'tw-text-green-500';
						backgroundColorClass = 'tw-bg-green-100';
						break;
					case 'gray':
						fontColorClass = 'tw-text-gray-500';
						backgroundColorClass = 'tw-bg-gray-050';
						break;
					case 'danger':
						fontColorClass = 'tw-text-red-500';
						backgroundColorClass = 'tw-bg-red-100';
						break;
					case 'warning':
						fontColorClass = 'tw-text-orange-500';
						backgroundColorClass = 'tw-bg-orange-a00';
						break;
					case 'info':
						fontColorClass = 'tw-text-light-blue-500';
						backgroundColorClass = 'tw-bg-light-blue-100';
						break;
					case 'light':
						fontColorClass = 'tw-text-gray-900';
						backgroundColorClass = 'tw-bg-gray-050';
						break;
					case 'dark':
						fontColorClass = 'tw-white';
						backgroundColorClass = 'tw-bg-gray-500';
						break;
				}
				break;
		}
		return {
			backgroundColorClass,
			fontColorClass,
		};
	}
}
