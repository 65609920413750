import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { bumpIn } from '../animations';
import { SortMenuComponent } from '../sort-menu/sort-menu.component';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Location } from '../models/Location';
import { ScreenService } from '../services/screen.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DisplayOptionsComponent } from './display-options/display-options.component';
import { clickAction } from '../models/PassFilters';
import { ColorProfile } from '../models/ColorProfile';
import { Pinnable } from '../models/Pinnable';
import { HallPassesService } from '../services/hall-passes.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-currently-in-v2',
	templateUrl: './currently-in-v2.component.html',
	styleUrls: ['./currently-in-v2.component.scss'],
	animations: [bumpIn],
})
export class CurrentlyInComponentV2 implements OnChanges, OnDestroy {
	@Input() roomOptions: Location[] = [];
	@Input() selectedLocation: Location;
	@Input() pinnableColorProfile: ColorProfile;
	@Input() showEndedPasses: boolean;
	@Output() selectedLocationEmit: EventEmitter<Location> = new EventEmitter<Location>();
	@Output() kioskModeEmit: EventEmitter<Location> = new EventEmitter<Location>();
	@Output() roomCodeEmit: EventEmitter<Location> = new EventEmitter<Location>();
	@Output() showEndedPassesEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('menu') menu: ElementRef<HTMLElement>;

	currentRoom: Location;
	pinnableBackground: SafeStyle;
	pinnableIcon: string;
	private dialogRefSortMenu: MatDialogRef<SortMenuComponent, any>;
	private dialogRefDropDown: MatDialogRef<DropdownComponent, any>;
	private dialogRefDisplayOptions: MatDialogRef<DisplayOptionsComponent, any>;
	private destroy$ = new Subject<void>();

	constructor(
		public screenService: ScreenService,
		public dialog: MatDialog,
		private sanitizer: DomSanitizer,
		private pinnableService: HallPassesService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.dialogRefDisplayOptions?.componentInstance?.data?.roomOptions) {
			this.dialogRefDisplayOptions.componentInstance.data.roomOptions = this.roomOptions;
		}
		this.currentRoom = this.selectedLocation || (this.roomOptions && this.roomOptions.length > 0 ? this.roomOptions[0] : undefined);
		this.pinnableService.pinnables$.pipe(takeUntil(this.destroy$)).subscribe((pinnables) => {
			this.setPinnableGradient(pinnables);
		});
		if (changes.selectedLocation) {
			this.currentRoom = changes.selectedLocation.currentValue;
		}
		if (changes.roomOptions) {
			if (!this.roomOptions?.length) {
				this.dialogRefDisplayOptions?.close();
			}
			this.currentRoom = this.selectedLocation || (this.roomOptions && this.roomOptions.length > 0 ? this.roomOptions[0] : undefined);
			this.cdr.detectChanges();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setPinnableGradient(pinnables: Pinnable[]): void {
		let pinnable: Pinnable;
		if (pinnables) {
			pinnable = pinnables.find((p) => p?.location?.id === this.currentRoom?.id) || pinnables.find((p) => p?.category === this.currentRoom?.category);
		}

		if (pinnable && pinnable.color_profile) {
			const gradient: string[] = pinnable?.color_profile?.gradient_color.split(',');
			this.pinnableBackground = this.sanitizer.bypassSecurityTrustStyle(
				'radial-gradient(circle at 73% 71%, ' + gradient[0] + ', ' + gradient[1] + ')'
			);
		}

		if (pinnable && pinnable.icon) {
			this.pinnableIcon = pinnable.icon;
		}
	}

	showOptions(target: HTMLElement): void {
		if (this.screenService.isDeviceMid || this.screenService.isIpadWidth) {
			this.openOptionsMenu();
		} else {
			this.displayOptionsPopover(target);
		}
	}

	openOptionsMenu(): void {
		this.dialogRefSortMenu = this.dialog.open(SortMenuComponent, {
			position: { bottom: '0' },
			panelClass: 'options-dialog',
			data: {
				selectedItem: this.selectedLocation,
				items: this.roomOptions,
				showAll: true,
			},
		});
		this.dialogRefSortMenu.componentInstance.onListItemClick.subscribe((location) => {
			this.selectedLocation = location;
			this.selectedLocationEmit.emit(location);
		});
	}

	private displayOptionsPopover(target: HTMLElement): void {
		if (this.roomOptions && this.roomOptions.length > 1) {
			UNANIMATED_CONTAINER.next(true);
			this.dialogRefDropDown = this.dialog.open(DropdownComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'invis-backdrop',
				data: {
					heading: 'CHANGE ROOM',
					locations: this.roomOptions,
					selectedLocation: this.selectedLocation,
					trigger: target,
					//scrollPosition: this.holdScrollPosition,
				},
			});

			this.dialogRefDropDown
				.afterClosed()
				.pipe(
					tap(() => {
						UNANIMATED_CONTAINER.next(false);
					}),
					filter((res) => !!res)
				)
				.subscribe((data) => {
					this.selectedLocation = data.selectedRoom === 'all_rooms' ? null : data.selectedRoom;
					this.selectedLocation = data.selectedRoom;
					this.selectedLocationEmit.emit(data.selectedRoom);
				});
		}
	}

	onClickOptions(): void {
		const top = this.menu.nativeElement.getClientRects()[0].top + this.menu.nativeElement.offsetHeight + 2;
		const left = this.menu.nativeElement.getClientRects()[0].left;
		this.dialogRefDisplayOptions = this.dialog.open(DisplayOptionsComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: {
				showEndedPasses: this.showEndedPasses,
				showSwitchRooms: true,
				roomOptions: this.roomOptions,
				selectedLocation: this.selectedLocation,
				selectedLocationEmit: this.selectedLocationEmit,
			},
			position: {
				top: `${top}px`,
				left: `${left}px`,
			},
		});

		this.dialogRefDisplayOptions
			.afterClosed()
			.pipe(
				tap(() => {
					UNANIMATED_CONTAINER.next(false);
				}),
				filter((res) => !!res)
			)
			.subscribe((data: clickAction) => {
				switch (data) {
					case 'kiosk':
						this.kioskModeEmit.emit(this.selectedLocation);
						break;
					case 'room_code':
						this.roomCodeEmit.emit(this.selectedLocation);
						break;
					case 'show_ended_passes':
						this.showEndedPasses = !this.showEndedPasses;
						this.showEndedPassesEmit.emit(this.showEndedPasses);
						break;
				}
			});
	}
}
