<div
	*ngIf="yearInReviewPdfUrl"
	class="tw-bg-white tw-flex tw-rounded-xl tw-p-8 tw-ml-5 tw-mb-8"
	style="box-shadow: 0px 2px 4px 0px rgba(16, 20, 24, 0.08)">
	<img class="tw-h-11" [src]="'./assets/year_review.png' | resolveAsset" alt="" />
	<div class="tw-pl-7">
		<p class="tw-my-0 tw-text-navy-500 tw-text-lg tw-font-bold">Your SmartPass Wrapped is Ready!</p>
		<p class="tw-my-0 tw-pt-2 tw-text-gray-500 tw-text-sm tw-font-normal tw-w-4/5 tw-text-justify">
			Explore in-depth insights from your school's SmartPass usage this year. View detailed statistics on pass creation, traffic patterns, and
			understand how SmartPass helped manage your school more effectively.
		</p>
		<div class="tw-mt-6">
			<app-gradient-button
				[size]="'editable'"
				[fontSize]="'16px'"
				[minHeight]="'50px'"
				[minWidth]="'126px'"
				[fontWeight]="'400'"
				[cursor]="'pointer'"
				[buttonLink]="yearInReviewPdfUrl"
				[download]="title"
				[text]="'View Report'">
			</app-gradient-button>
		</div>
	</div>
</div>
