<div class="notif-wrapper" *ngIf="user$ | async as user">
	<div class="notif-top-wrapper">
		<div class="notif-header">
			<div class="notif-title-wrapper"><span class="notif-title">Notifications</span></div>
			<div class="notif-exit-wrapper"><img (click)="close()" alt="" [src]="'./assets/Close.svg' | resolveAsset" /></div>
		</div>
		<div class="notif-helper-wrapper">
			<app-notification-form-info *ngIf="isSafari" [style]="'warn'" [iconBackground]="undefined" image="./assets/Safari.svg">
				<div title>Browser Notifications in Safari Aren’t Supported</div>
				<div byline>Please use Chrome or Firefox browsers for notifications</div>
			</app-notification-form-info>
			<app-notification-form-info *ngIf="isDisabledNotif && !isSafari" [iconBackground]="undefined" image="./assets/Window (Blue-Gray).svg">
				<div title>Browser Notifications are off</div>
				<div byline>
					To receive notifications, you’ll need to <b>turn on notifications in your browser settings.</b>
					<a href="https://www.smartpass.app/notifications" tabindex="-1" target="_blank">Get help</a>
				</div>
			</app-notification-form-info>
		</div>
	</div>
	<div class="notif-bottom-wrapper-wrapper loading-theme_blue-gray">
		<div class="notif-bottom-wrapper" *ngIf="form; else loading">
			<ng-container *ngIf="isUserLoaded && isStudent">
				<div class="notif-student-info">
					<div class="notif-student-bell">
						<img alt="" src="./assets/Notifications (White).svg" />
					</div>

					<span class="notif-student-title">What Notifications You Receive</span>
					<span class="notif-student-body">
						You’ll receive notifications for new passes from <br />
						teachers, when a teacher approves your pass, when <br />
						your pass is about to end, and more...
					</span>
					<div *ngIf="studentBottomText" class="notif-student-bottom">
						<span>{{ studentBottomText }}</span>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="isUserLoaded && notificationSoundsFlag">
				<div class="notif-big-text notif-no-top-pad notif-tooltip">
					<span>Notification Sounds</span>
					<div [customToolTip]="'Alerts for pass requests, calls downs, expiring and expired passes.'">
						<img width="13px" src="./assets/Support (Gray-200).svg" />
					</div>
				</div>
				<div class="notif-pass-requests all-sounds-option">
					<app-notification-option
						icon="./assets/Audio Symbol (White).svg"
						text="All Sounds"
						[form]="form"
						control="allNotificationSounds"></app-notification-option>
				</div>
			</ng-container>

			<ng-container *ngIf="isUserLoaded && !isStudent && !isKioskMode">
				<span class="notif-big-text" [ngClass]="{ 'notif-no-top-pad': !notificationSoundsFlag }">What Notifications You Receive</span>
				<app-notification-form-info>
					<div title>Pass Requests</div>
					<div byline>
						{{ activeNotifications('passRequests') }}
					</div>
					<div class="notif-pass-requests">
						<app-notification-option
							icon="./assets/Devices (Navy).svg"
							text="Push"
							[form]="form"
							control="passRequestsPush"></app-notification-option>
						<app-notification-option icon="./assets/Mail (Navy).svg" text="Email" [form]="form" control="passRequestsEmail"></app-notification-option>
						<span class="setting-info">Notifications when a student sends you a pass request or accepts/declines your pass request.</span>
					</div>
				</app-notification-form-info>
				<app-notification-form-info>
					<div title>Scheduled Passes</div>
					<div byline>
						{{ activeNotifications('scheduledPasses') }}
					</div>
					<div class="notif-pass-requests">
						<app-notification-option
							icon="./assets/Devices (Navy).svg"
							text="Push"
							tooltip="You'll get a notification 5 minutes before the scheduled pass starts."
							[form]="form"
							control="scheduledPassesPush"></app-notification-option>
						<app-notification-option
							icon="./assets/Mail (Navy).svg"
							text="Email"
							tooltip="You'll get a summary email at the beginning of the day, and 10 minutes before the scheduled pass starts."
							[form]="form"
							control="scheduledPassesEmail"></app-notification-option>
						<span class="setting-info">Notifications when a scheduled pass is starting, or has been deleted by a student.</span>
					</div>
				</app-notification-form-info>
				<app-notification-form-info>
					<div title>My Rooms</div>
					<div byline>
						{{ activeNotifications('myRooms') }}
					</div>
					<div>
						<div class="notif-scheduled-passes">
							<app-notification-option icon="./assets/Devices (Navy).svg" text="Push" [form]="form" control="myRoomsPush"></app-notification-option>
							<div class="div"></div>
							<div class="room-list">
								<div *ngFor="let room of rooms$ | async" (click)="openRoomInfo(room)" class="room-wrapper">
									<div class="room">
										<div class="room-image" [style.background-image]="roomBackground(room['color_profile']['gradient_color'])">
											<img alt="" [src]="room['icon']" />
										</div>
										<div class="room-info">
											<span class="room-name">{{ room['title'] }}</span>
											<span class="room-events">{{ activeRoomsNotifications(room['id']) }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span class="setting-info unwrapped">Notifications when a pass starts to or from this room, or a pass from this room is overtime.</span>
					</div>
				</app-notification-form-info>
				<app-notification-form-info>
					<div title>When Certain Students Make Passes</div>
					<div byline>
						{{ activeNotifications('studentPasses') }}
					</div>
					<div>
						<div class="notif-pass-requests">
							<app-notification-option
								[tooltip]="!students?.length ? 'Add students to unlock Push' : null"
								[position]="'mouse'"
								[disabled]="!students?.length"
								icon="./assets/Devices (Navy).svg"
								text="Push"
								[form]="form"
								control="studentPassesPush"></app-notification-option>
							<app-notification-option
								[tooltip]="!students?.length ? 'Add students to unlock Email' : null"
								[position]="'mouse'"
								[disabled]="!students?.length"
								icon="./assets/Mail (Navy).svg"
								text="Email"
								[form]="form"
								control="studentPassesEmail"></app-notification-option>
						</div>
						<div class="div"></div>
						<div class="app-notif-select-students">
							<app-notification-select-students [ids]="students"></app-notification-select-students>
						</div>
						<span class="setting-info unwrapped">Notifications when certain students have an active pass.</span>
					</div>
				</app-notification-form-info>
			</ng-container>

			<ng-container *ngIf="isUserLoaded && isAdmin && !isKioskMode">
				<app-notification-form-info>
					<div title>Reports</div>
					<div byline>
						{{ activeNotifications('reports') }}
					</div>
					<div class="notif-pass-requests">
						<app-notification-option icon="./assets/Mail (Navy).svg" text="Email" [form]="form" control="reportsEmail"></app-notification-option>
						<span class="setting-info">Notifications when a teacher reports a student.</span>
					</div>
				</app-notification-form-info>
				<app-notification-form-info>
					<div title>Encounter Prevention</div>
					<div byline>
						{{ activeNotifications('encounterPrevention') }}
					</div>
					<div class="notif-pass-requests">
						<app-notification-option
							icon="./assets/Mail (Navy).svg"
							text="Email"
							[form]="form"
							control="encounterPreventionEmail"></app-notification-option>
						<span class="setting-info">Notifications when an encounter is prevented.</span>
					</div>
				</app-notification-form-info>
			</ng-container>

			<ng-container *ngIf="isUserLoaded && !isStudent && !isKioskMode">
				<span class="notif-big-text">Where You Receive Notifications</span>
				<app-notification-form-info [style]="isUsingPush ? 'normal' : 'disabled'" image="./assets/Devices (White).svg">
					<div title>Push</div>
					<div byline>Notifications in your browser and mobile devices</div>
				</app-notification-form-info>
				<app-notification-form-info [style]="isUsingEmail ? 'normal' : 'disabled'" image="./assets/Mail (White).svg" iconBackground="#139BE6">
					<div title>Email</div>
					<div byline>
						{{ emailByline }}
					</div>
				</app-notification-form-info>
				<span class="notif-big-text">Having Issues With Notifications?</span>
				<span class="setting-info"
					>Check out our
					<a href="https://www.smartpass.app/support-articles/troubleshoot-notifications" tabindex="-1" target="_blank"> support guides </a> for more
					help.</span
				>
				<app-gradient-button
					size="editable"
					textColor="#7083A0"
					fontSize="14px"
					fontWeight="500"
					leftImageWidth="17px"
					minWidth="265px"
					width="265px"
					minHeight="40px"
					leftIcon="./assets/Send (Blue-Gray).svg"
					customBackground="#F0F2F5"
					(buttonClick)="send()">
					Send a test browser notification
				</app-gradient-button>
			</ng-container>
		</div>
	</div>

	<ng-template #loading>
		<mat-spinner [diameter]="30"></mat-spinner>
	</ng-template>
</div>
