<div class="wrapper">
	<div class="heading" #header>
		<div class="back-button">
			<img (click)="back()" [src]="'./assets/Back Button (Light-Gray).svg'" alt="Back Button (Blue-White).svg" />
		</div>
		<div class="ds-dialog-title-white ds-flex-center-center">
			<span>ClassLink Settings</span>
		</div>
	</div>
	<div class="content">
		<div class="page class-link-block">
			<div class="block" style="margin-top: 15px">
				<img [src]="'./assets/ClassLink.svg' | resolveAsset" />
				<div class="page-info">
					<div class="title">Accounts Synced with ClassLink</div>
					<p>Need help? <a href="https://www.smartpass.app/schedule-a-support-meeting" target="_blank">Contact us</a></p>
				</div>
			</div>
		</div>
	</div>
</div>
