import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { getStartOfSchoolYearAsMoment } from '../../helpers/helper';
@Component({
	selector: 'app-date-button',
	templateUrl: './date-button.component.html',
	styleUrls: ['./date-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('pressState', [
			state(
				'down',
				style({
					transform: 'scale(0.98)',
				})
			),
			state(
				'up',
				style({
					transform: 'scale(1)',
				})
			),
			transition('up => down', animate('100ms ease-out')),
			transition('down => up', animate('100ms ease-in')),
		]),
	],
})
export class DateButtonComponent {
	@Input() date: { start: moment.Moment; end: moment.Moment };

	@Output() buttonClick: EventEmitter<any> = new EventEmitter();

	hover: boolean;
	pressed: boolean;

	bgColor() {
		if (this.hover) {
			if (this.pressed) {
				return '#EAEDF1';
			}
			return '#F0F2F5';
		}
		return '#FFFFFF';
	}

	//This sets the strings values in the view of the student snapshot based on the time outputs from the child components
	dateText({ start }): string {
		if (start.isSame(moment().subtract(3, 'days'), 'day')) {
			return 'Last 3 days';
		} else if (start.isSame(moment().subtract(7, 'days'), 'day')) {
			return 'Last 7 days';
		} else if (start.isSame(moment().subtract(30, 'days'), 'day')) {
			return 'Last 30 days';
		} else if (start.isSame(moment().subtract(90, 'days'), 'day')) {
			return 'Last 90 days';
		} else if (start.isSame(moment('1/7/' + getStartOfSchoolYearAsMoment().year(), 'DD/MM/YYYY'))) {
			return 'This school year';
		} else if (start.isSame(moment(), 'day')) {
			return 'Today';
		}
		return 'Custom';
	}

	selectedDate({ start, end }) {
		if (!end) {
			if (moment(start).isSame(moment(), 'day')) {
				return moment(start).format('MMM DD') + ' - Today';
			} else if (moment(start).isSame(moment().add(1, 'day'), 'day')) {
				return moment(start).format('MMM DD') + ' - Tomorrow';
			} else if (moment(start).isSame(moment().subtract(1, 'day'), 'day')) {
				return moment(start).format('MMM DD') + ' - Yesterday';
			} else {
				return moment(start).format('MMM DD');
			}
		}

		return start.isSame(end, 'day') ? start.format('MMM D') : start.format('MMM D') + ' to ' + end.format('MMM D');
	}
}
