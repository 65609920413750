<div class="consent-wrapper" [style.background-color]="darkTheme.getBackground('low')">
	<!--  <div class="pointer-wrapper" ><div class="pointer" [style.background-color]="darkTheme.getBackground('low')"></div></div>-->
	<div class="rounded-corners-box">
		<!--    <div class="ds-mt-15" *ngIf="!header"></div>-->
		<div *ngIf="header">
			<div class="consent-header" [style.color]="'#7F879D'" [innerHTML]="header"></div>
			<div class="option-divider"></div>
		</div>

		<div *ngIf="!!ConsentText">
			<span>
				{{ ConsentText }}
			</span>
			<div mat-dialog-actions class="center">
				<div [style.color]="getConcentButtonColor(ConsentButtonColor)" class="option-wrapper marRight" (click)="_matDialogRef.close('doProcess')">
					{{ ConsentYesText }}
				</div>
				<div [style.color]="getConcentButtonColor(ConsentButtonColor)" class="option-wrapper" (click)="onNoClick()">{{ ConsentNoText }}</div>
			</div>
		</div>

		<div class="ds-w-100 options-container">
			<div *ngFor="let option of options; let i = index" style="padding: 0 10px">
				<div *ngIf="optionsView === 'button'; else stringOptionsView" class="ds-w-100">
					<app-gradient-button
						[cursor]="option?.cursor ? option.cursor : 'pointer'"
						[minHeight]="'32px'"
						[width]="'200px'"
						[fontSize]="'14px'"
						[fontWeight]="'400'"
						[buttonDownColor]="option.color"
						[gradient]="option.buttonColor"
						[textColor]="getColor(option)"
						(buttonClick)="_matDialogRef.close(option.action)">
						<span>{{ option.display }}</span>
					</app-gradient-button>
				</div>
				<ng-template #stringOptionsView>
					<div
						[style.cursor]="option?.cursor ? option.cursor : 'pointer'"
						[style.color]="option.color"
						#optionDiv
						class="option-wrapper ds-w-100"
						appCrossPointerEventTarget
						(pointerClickEvent)="interceptClick(option, optionDiv)"
						(pointerDownEvent)="setActiveBackground(option, optionDiv)"
						(mouseover)="
							option?.mouseOver && option.mouseOver();
							optionDiv.style.background = this.darkTheme.isEnabled$.value
								? isSort
									? '#47447E'
									: 'rgba(226, 231, 244, .2)'
								: option.hoverBackground
								? option.hoverBackground
								: 'rgba(127, 135, 157, .1)'
						"
						(mouseleave)="option?.mouseLeave && option.mouseLeave(); optionDiv.style.background = 'transparent'">
						<div class="ds-w-100 ds-flex-center-between" *ngIf="isSort; else simpleOptions">
							<span style="white-space: nowrap">{{ option.display }}</span>
							<img
								width="16"
								height="16"
								[src]="darkTheme.getIcon({ iconName: 'Check', lightFill: 'Navy' })"
								*ngIf="sortMode === option.action"
								alt="Check.svg" />
						</div>
						<ng-template #simpleOptions>
							<img width="16" height="16" *ngIf="option.icon" [src]="option.icon | resolveAsset" alt="Icon" />
							<span style="white-space: nowrap" [style.margin-left]="option.icon ? '8px' : 0">{{ option.display }}</span>
						</ng-template>
					</div>
				</ng-template>
				<!--        <div class="option-divider" *ngIf="i !== options.length - 1"></div>-->
			</div>
		</div>
	</div>
</div>
