import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe is used to compare the current URL with a given prefix.
 * The route is considered to match if the current URL starts with the prefix.
 * It only compares path segments, not substrings.
 * ('/passes' will be matched by '/passes/calendar' but not '/passes-calendar')
 */
@Pipe({
	name: 'matchesRoute',
})
export class MatchesRoutePipe implements PipeTransform {
	transform(value: string | null, route: string): boolean {
		if (value == null) {
			return false;
		}

		const valueSegments = value.split('/');
		const routeSegments = route.split('/');
		return routeSegments.every((segment, index) => segment === valueSegments[index]);
	}
}
