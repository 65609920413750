import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImportStudentListComponent } from '../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { CreateFormService } from '../../create-hallpass-forms/create-form.service';
import { StudentList } from '../../models/StudentList';
import { ROLES, User } from '../../models/User';
import { ScreenService } from '../../services/screen.service';
import { UserService } from '../../services/user.service';
import { GroupsAndStudentSelectComponent } from '../../shared/shared-components/groups-and-student-select/groups-and-student-select.component';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { ScheduleService } from '../../services/schedule.service';
import { HallPassesService } from '../../services/hall-passes.service';
import { HomepageService } from '../../services/homepage.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { SPClassUser } from '../../services/classes.service';
import { KioskModeService } from '../../services/kiosk-mode.service';

interface SearchResult {
	student: User;
	alreadyIn: boolean;
	alreadySelected: boolean;
}

@Component({
	selector: 'sp-student-class-search',
	styleUrls: [
		'../../shared/shared-components/groups-and-student-select/groups-and-student-select.component.scss',
		'../../create-hallpass-forms/main-hallpass--form/student-groups/groups-container-v2/groups-container-v2.component.scss',
		'../../teacher-activity/attendees/student-instance-search/student-instance-search.component.scss',
		'student-class-search.component.scss',
	],
	templateUrl: 'student-class-search.component.html',
	animations: [trigger('adjustHeight', [transition('* => *', [style({ height: '*' }), animate('200ms ease', style({ height: '!' }))])])],
})
export class StudentClassSearchComponent extends GroupsAndStudentSelectComponent implements OnInit {
	@Input() studentsInClass: SPClassUser[];
	@Input() autoSetFocusOnSearch = true;
	@Output() toAdd = new Subject<(StudentList | User)[]>();

	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;

	isSearchFocused = false;
	searchInputChanges$ = new Subject<string>();
	searchText = '';
	selectedGroupsAndStudents: (StudentList | User)[] = [];
	searchResults: SearchResult[] = [];
	usersLoaded = false;
	private allGroups: StudentList[] = [];

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		public dialog: MatDialog,
		private renderer: Renderer2,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public kioskService: KioskModeService
	) {
		super(
			userService,
			sanitizer,
			formService,
			screenService,
			dialog,
			featureFlagService,
			scheduleService,
			pinnableService,
			homePageService,
			kioskService
		);
	}

	ngOnInit() {
		this.userService.getStudentGroupsRequest().pipe(takeUntil(this.destroy$));
		this.setupSearchListener();
		this.loadGroups();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.autoSetFocusOnSearch == true) {
				this.isSearchFocused = true;
				this.setFocusOnSearch();
			}
		});
	}

	private setupSearchListener(): void {
		this.searchInputChanges$
			.pipe(
				tap((search) => (this.usersLoaded = search === '')),
				distinctUntilChanged(),
				debounceTime(200),
				switchMap((search) => this.performSearch(search)),
				takeUntil(this.destroy$)
			)
			.subscribe(
				(filteredStudents) =>
					(this.searchResults = filteredStudents.map((student) => {
						const alreadyIn = this.isInSelectStudents(student);
						const alreadySelected = !!this.selectedGroupsAndStudents.find((entity) => entity.id === student.id);
						return {
							student,
							alreadyIn,
							alreadySelected,
						};
					}))
			);
	}

	private loadGroups(): void {
		this.userService.studentGroups$.pipe(takeUntil(this.destroy$)).subscribe((groups) => {
			this.allGroups = groups;
			this.groups = groups;
		});
	}

	private performSearch(searchText: string): Observable<User[]> {
		if (searchText === '') {
			this.resetSearchResults();
			return of([]);
		}
		this.groups = this.allGroups.filter(
			(group) =>
				!!this.selectedGroupsAndStudents.find((entity) => entity.id !== group.id) && group.title.toLowerCase().includes(this.searchText.toLowerCase())
		);

		return this.userService.searchProfile(ROLES.Student, 100, searchText).pipe(
			map((responses) => responses.results.map((r) => User.fromJSON(r))),
			takeUntil(this.destroy$),
			finalize(() => (this.usersLoaded = true))
		);
	}

	private resetSearchResults(): void {
		this.groups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.find((selectedGroup) => selectedGroup.id === group.id));
		this.searchResults = [];
	}

	setFocusOnSearch(): void {
		if (this.searchInput?.nativeElement) {
			setTimeout(() => this.renderer.selectRootElement(this.searchInput.nativeElement).focus(), 50);
			this.onSearchFocus(true);
		}
	}

	onSearchFocus(isFocused: boolean): void {
		setTimeout(() => (this.isSearchFocused = isFocused), 50);
		this.searchInputChanges$.next(this.searchText);
	}

	onSelect(entity: StudentList | User): void {
		this.selectedGroupsAndStudents.push(entity);
		this.searchText = '';
		this.searchInputChanges$.next('');
		this.searchResults = [];
		this.setFocusOnSearch();
		this.toAdd.next(this.selectedGroupsAndStudents);
	}

	removeSelection(selection: User | StudentList): void {
		this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.filter((s) => s.id !== selection.id);
		this.setFocusOnSearch();
		this.toAdd.next(this.selectedGroupsAndStudents);
	}

	showImportStudentList(): void {
		const dialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((students: User[]) => {
				if (students?.length > 0) {
					students.forEach((student) => {
						if (!this.isInSelectStudents(student)) {
							this.selectedGroupsAndStudents.push(student);
						}
					});
					this.toAdd.next(this.selectedGroupsAndStudents);
				}
			});
	}

	private isInSelectStudents(student: User): boolean {
		return !!this.studentsInClass?.find((studentInClass) => studentInClass.user.id === student.id);
	}
}
