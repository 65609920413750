import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'sp-schedule-processing',
	templateUrl: './schedule-processing.component.html',
	styleUrls: ['./schedule-processing.component.scss'],
})
export class ScheduleProcessingComponent {
	@Input() icon: string;
	@Input() title: string;
	@Input() subtitle: string;
	@Input() displayReviewTemplate = false;

	openIntercom(event: Event) {
		event.preventDefault();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.Intercom('showNewMessage');
	}
}
