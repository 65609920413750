import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { ClasslinkClassInfo, CleverClassInfo, GetExternalInfoResp, GoogleClassInfo } from '../../services/classes.service';

type ImgTempl = {
	logoSrc: string;
	logoAlt: string;
};

@Pipe({
	name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
	transform(date: Date): string {
		let daysAgo: string;

		const diff = moment().diff(moment(date), 'day');
		console.log(diff);
		switch (diff) {
			case 0:
				daysAgo = 'Today';
				break;
			case 1:
				daysAgo = 'Yesterday';
				break;
			default:
				daysAgo = `${diff} days ago`;
		}

		const time = moment(date).format('HH:mm A');
		return `${daysAgo}, ${time}`;
	}
}

@Component({
	selector: 'sp-class-sync-info',
	templateUrl: './class-sync-info.component.html',
	styleUrls: ['./class-sync-info.component.scss'],
})
export class ClassSyncInfoComponent {
	@Input() set syncData(data: GetExternalInfoResp) {
		if (data) {
			this.fetchedData = data;
			if (data.clever_class) {
				this.cleverSyncInfo = {
					...data.clever_class,
					logoSrc: './assets/clever-logo.svg',
					logoAlt: 'Clever',
				};
			}

			if (data.classlink_class) {
				this.classlinkSyncInfo = {
					...data.classlink_class,
					logoSrc: './assets/ClassLink-navy.svg',
					logoAlt: 'ClassLink',
				};
			}

			if (data.google_class) {
				this.googleSyncInfo = {
					...data.google_class,
					logoSrc: './assets/Google-classroom-gray.svg',
					logoAlt: 'Google Classroom',
				};
			}
		}
	}

	cleverSyncInfo: CleverClassInfo & ImgTempl;
	classlinkSyncInfo: ClasslinkClassInfo & ImgTempl;
	googleSyncInfo: GoogleClassInfo & ImgTempl;
	fetchedData: GetExternalInfoResp;
}
