<div *ngIf="scheduleService.getTermData$ | async as termData">
	<div class="wrapper" *ngIf="page === 1" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div class="header" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div class="background-header"></div>
			<img class="back-button" (click)="close()" [src]="'./assets/Back Button (Blue-White).svg' | resolveAsset" />
			<div class="title-header ds-flex-center-center" style="z-index: 10">
				<div class="ds-dialog-title" [style.margin-left]="'12px'">
					Pass Limit for <span ignore>{{ data?.studentPassLimit?.student.display_name }}</span>
				</div>
			</div>
		</div>

		<div class="content ds-p-20" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div
				class="limit-section school-pass-limit ds-flex-row ds-flex-center-between"
				[ngClass]="{ disabled: data?.studentPassLimit?.isIndividual, 'disabled-border': !data?.studentPassLimit?.schoolPassLimitEnabled }">
				<div class="ds-flex-column" style="display: flex">
					<div class="ds-dialog-heading section-header" style="padding-bottom: 0 !important">
						Limit for All Students
						<div
							*ngIf="schoolPassLimit"
							style="display: flex"
							customToolTip
							[additionalOffsetY]="schoolPassLimit?.frequency === 'term' ? (termData?.currentTerm || termData.upcomingTerm ? -80 : -60) : -30"
							[position]="'top'"
							[contentTemplate]="passLimitLearnMore">
							<img class="support-icon" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
						</div>
						<ng-template #passLimitLearnMore>
							<div class="ds-tooltip">
								<div style="display: flex; flex-direction: column; width: 212px">
									<span> Limit the number of passes all students can have per {{ schoolPassLimit?.frequency }}. </span>
									<a *ngIf="schoolPassLimit?.frequency === 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank">
										Learn more</a
									>
								</div>
								<a *ngIf="schoolPassLimit?.frequency !== 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank">
									Learn more</a
								>

								<div *ngIf="schoolPassLimit?.frequency === 'term'" class="term-tooltip">
									<div class="header">
										<span class="title">
											{{ termData?.currentTerm?.name ? 'Current Term: ' : termData?.upcomingTerm?.name ? 'Upcoming Term: ' : 'No Upcoming Term' }}
										</span>
										<span>
											{{
												termData?.currentTerm?.name ? termData?.currentTerm?.name : termData?.upcomingTerm?.name ? termData?.upcomingTerm?.name : ''
											}}
										</span>
									</div>
									<span class="dates" *ngIf="termData?.currentTerm || termData?.upcomingTerm">
										{{ (termData?.currentTerm ? termData?.currentTerm.start_date : termData?.upcomingTerm?.start_date) | date : 'MM/dd/yyyy' }}
										-
										{{ (termData?.currentTerm ? termData?.currentTerm.end_date : termData?.upcomingTerm?.end_date) | date : 'MM/dd/yyyy' }}
									</span>
								</div>
							</div>
						</ng-template>
					</div>

					<span
						style="font-size: 14px; font-weight: 400; color: #6651f1"
						*ngIf="
							(data.studentPassLimit?.passLimit >= 0 && data.studentPassLimit?.passLimit != null && data.studentPassLimit?.schoolPassLimitEnabled) ||
							data?.studentPassLimit?.passLimit === -2
						">
						{{ schoolPassLimit?.passLimit }}
						{{ schoolPassLimit?.passLimit === 1 ? 'pass' : 'passes' }}/{{ schoolPassLimit?.frequency }}
					</span>
				</div>

				<app-gradient-button
					*ngIf="!schoolEditButton; else schoolEditButtonTemplate"
					(buttonClick)="navigateToAdminPage()"
					size="tiny"
					customToolTip
					[contentTemplate]="editButtonAdminRedirect"
					[disabled]="!isAdmin"
					gradient="#6651F1"
					textColor="#FFFFFF"
					[rightIcon]="'assets/External%20Link%20(White).svg'"
					rightIconWidth="15px"
					rightIconHeight="15px"
					cornerRadius="4px">
					Turn on
				</app-gradient-button>

				<ng-template #schoolEditButtonTemplate>
					<ng-template
						[ngTemplateOutlet]="editButton"
						[ngTemplateOutletContext]="{ clickFunc: navigateToAdminPage.bind(this), isIndividual: false }"></ng-template>
				</ng-template>
			</div>

			<div
				class="limit-section individual-pass-limit ds-flex-row ds-flex-center-between"
				[ngClass]="{
					'disabled-border':
						(data?.studentPassLimit?.schoolPassLimitEnabled && !data?.studentPassLimit?.isIndividual) ||
						!data?.studentPassLimit?.passLimit ||
						data?.studentPassLimit?.noLimitsSet,
					'show-individual-border': individualBorder
				}">
				<div class="ds-flex-column" style="display: flex">
					<div class="ds-dialog-heading section-header" style="align-self: flex-start; padding-bottom: 0 !important">
						Individual Limit
						<div class="tooltip-container" style="display: inline">
							<div
								*ngIf="schoolPassLimit"
								style="display: flex"
								customToolTip
								[additionalOffsetY]="schoolPassLimit?.frequency === 'term' ? (termData?.currentTerm || termData.upcomingTerm ? -120 : -100) : -70"
								[position]="'top'"
								[contentTemplate]="individualLimitLearnMore">
								<img class="support-icon" style="width: 17px" [src]="'./assets/Support (Gray-200).svg' | resolveAsset" />
							</div>
						</div>
						<ng-template #individualLimitLearnMore>
							<div class="ds-tooltip">
								<div style="display: flex; flex-direction: column; width: 212px">
									<span>
										Limit the number of passes certain students can have per {{ schoolPassLimit?.frequency }}. If enabled, it overrides the Limit for
										All Students.
									</span>
									<a *ngIf="schoolPassLimit?.frequency === 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank">
										Learn more</a
									>
								</div>
								<a *ngIf="schoolPassLimit?.frequency !== 'term'" href="https://www.smartpass.app/passlimits" style="color: white" target="_blank">
									Learn more</a
								>

								<div *ngIf="schoolPassLimit?.frequency === 'term'" class="term-tooltip">
									<div class="header">
										<span class="title">
											{{ termData?.currentTerm?.name ? 'Current Term: ' : termData?.upcomingTerm?.name ? 'Upcoming Term: ' : 'No Upcoming Term' }}
										</span>
										<span>
											{{
												termData?.currentTerm?.name ? termData?.currentTerm?.name : termData?.upcomingTerm?.name ? termData?.upcomingTerm?.name : ''
											}}
										</span>
									</div>
									<span class="dates" *ngIf="termData?.currentTerm || termData?.upcomingTerm">
										{{ (termData?.currentTerm ? termData?.currentTerm.start_date : termData?.upcomingTerm?.start_date) | date : 'MM/dd/yyyy' }}
										-
										{{ (termData?.currentTerm ? termData?.currentTerm.end_date : termData?.upcomingTerm?.end_date) | date : 'MM/dd/yyyy' }}
									</span>
								</div>
							</div>
						</ng-template>
					</div>

					<div
						*ngIf="
							(data.studentPassLimit?.passLimit >= 0 && data.studentPassLimit?.passLimit != null && data.studentPassLimit?.isIndividual) ||
							data?.studentPassLimit?.passLimit === -2
						"
						class="ds-flex-column ds-mt-5"
						style="display: flex">
						<span style="color: #e48c15; font-size: 14px; font-weight: 500"
							>{{ data?.studentPassLimit?.passLimit === -2 ? 'Unlimited' : data?.studentPassLimit?.passLimit }}
							{{ data?.studentPassLimit?.passLimit === 1 ? 'pass' : 'passes' }}/{{ schoolPassLimit?.frequency }}</span
						>
						<span style="color: grey; font-size: 14px" *ngIf="data?.studentPassLimit?.description">{{ data?.studentPassLimit?.description }}</span>
					</div>
				</div>

				<app-gradient-button
					*ngIf="!individualEditButton; else individualEditButtonTemplate"
					size="tiny"
					customToolTip
					[contentTemplate]="editButtonAdminRedirect"
					[disabled]="!isAdmin"
					(buttonClick)="loadForm()"
					gradient="#E48C15"
					textColor="#FFFFFF"
					[leftIcon]="'assets/Plus (White).svg'"
					leftImageWidth="15px"
					leftImageHeight="15px"
					cornerRadius="4px">
					Add
				</app-gradient-button>

				<ng-template #individualEditButtonTemplate>
					<ng-template
						[ngTemplateOutlet]="editButton"
						[ngTemplateOutletContext]="{ clickFunc: loadForm.bind(this), isIndividual: true }"></ng-template>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="wrapper" *ngIf="page === 2" [@NextStep]="{ value: '', params: frameMotion$ | async }">
		<div
			class="header"
			[attr.data-motion-translate]="(frameMotion$ | async).direction"
			*ngIf="!(passLimitFormChanged | async); else changedFormHeader">
			<div class="background-header"></div>
			<img class="back-button" (click)="backToHomePage()" [src]="'./assets/Back Button (Blue-White).svg' | resolveAsset" />
			<div class="title-header ds-flex-center-center" style="z-index: 10">
				<div class="ds-dialog-title" [style.margin-left]="'12px'">
					{{ data?.studentPassLimit?.isIndividual ? 'Edit' : 'Add' }} Limit for <span ignore>{{ data?.studentPassLimit?.student.display_name }}</span>
				</div>
			</div>
		</div>

		<ng-template #changedFormHeader>
			<div class="header" [attr.data-motion-translate]="(frameMotion$ | async).direction" style="justify-content: space-around">
				<div class="background-header"></div>
				<app-gradient-button [disabled]="requestLoading || deleteLoading" (buttonClick)="resetPassLimitsForm()" customBackground="#7083A0"
					>Cancel</app-gradient-button
				>
				<div class="title-header" style="z-index: 10; display: flex">
					<div class="ds-dialog-title" [style.margin-left]="'12px'">Edit Limits</div>
				</div>
				<app-gradient-button
					(buttonClick)="updatePassLimits()"
					customBackground="#00B476"
					textColor="white"
					[disabled]="passLimitForm.invalid || deleteLoading">
					<ng-template [ngTemplateOutlet]="buttonLoading" [ngTemplateOutletContext]="{ loading: requestLoading, text: 'Save' }"></ng-template>
				</app-gradient-button>
			</div>
		</ng-template>

		<div class="content ds-p-20" style="justify-content: space-between" [@NextStep]="{ value: '', params: frameMotion$ | async }">
			<div>
				<div style="display: flex" class="ds-flex-column ds-mt-10">
					<span class="ds-dialog-heading" style="padding-bottom: 0 !important">Limit</span>
					<p *ngIf="data.studentPassLimit?.passLimit" class="ds-dialog-normal-text">
						This limit overrides the Limit for All Students of {{ passLimit?.passLimit }} {{ passLimit?.passLimit === 1 ? 'pass' : 'passes' }}/{{
							schoolPassLimit?.frequency
						}}.
					</p>
				</div>

				<app-pass-limit-input
					#passLimitInput
					class="ds-mt-10"
					[formGroup]="passLimitForm"
					[control]="passLimitForm.get('passLimit')"
					[frequency]="schoolPassLimit?.frequency"
					[currentTerm]="termData?.currentTerm"
					[upcomingTerm]="termData?.upcomingTerm"
					[isIndividual]="true"></app-pass-limit-input>

				<section class="individual-limit-description ds-mt-20" style="margin-bottom: 30px">
					<span class="ds-dialog-heading ds-blue-gray" style="padding: 0 !important">Description</span>
					<p class="ds-dialog-normal-text" style="margin-top: 4px">This description is only visible to staff.</p>
					<app-textarea class="ds-mt-10" width="85%" [control]="passLimitForm.get('description')" placeholder="Ex. Parents requested"></app-textarea>
				</section>
			</div>

			<div class="delete-button ds-flex-center-center" *ngIf="!data?.studentPassLimit?.noLimitsSet && data?.studentPassLimit?.isIndividual">
				<app-gradient-button
					[disabled]="requestLoading"
					size="editable"
					minHeight="40px"
					minWidth="190px"
					fontSize="14px"
					cornerRadius="8px"
					padding="0px 16px"
					(buttonClick)="openDeleteDialog()"
					gradient="#E32C66"
					textColor="#FFFFFF">
					<ng-template
						[ngTemplateOutlet]="buttonLoading"
						[ngTemplateOutletContext]="{ loading: deleteLoading, text: 'Remove Individual Limit' }"></ng-template>
				</app-gradient-button>
			</div>
		</div>
	</div>
</div>

<ng-template #buttonLoading let-loading="loading" let-text="text">
	<span *ngIf="!loading">{{ text }}</span>
	<mat-spinner class="request-loading" *ngIf="loading" [diameter]="25"></mat-spinner>
</ng-template>

<ng-template #editButton let-clickFunc="clickFunc" let-isIndividual="isIndividual">
	<app-gradient-button
		size="tiny"
		customToolTip
		[contentTemplate]="editButtonAdminRedirect"
		[disabled]="!isAdmin"
		(buttonClick)="clickFunc()"
		gradient="#FFFFFF, #FFFFFF"
		textColor="#7f879d"
		[leftIcon]="'assets/Edit%20(Blue-Gray).svg'"
		[rightIcon]="isIndividual ? '' : 'assets/External%20Link%20(Blue-Gray).svg'">
		Edit
	</app-gradient-button>
</ng-template>

<ng-template #editButtonAdminRedirect>
	<div *ngIf="!isAdmin" class="ds-tooltip" style="text-align: left">
		<div class="only-admin">ADMIN ONLY</div>
		Only admins are able to manage pass limits.
	</div>
</ng-template>

<ng-template #deleteDialogBody>
	<p class="ds-dialog-normal-text" style="font-size: 15px !important; margin: 4px 0">
		When you remove the individual limit, the student's pass limit will be restored to the Limit for All Students, if enabled.
	</p>
</ng-template>
