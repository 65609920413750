<div class="rest-tar-wrapper">
	<div>
		<div class="text ds-dialog-title-white">Select teacher</div>
	</div>
	<div class="content-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction"></div>
	<div class="rest-tar-content" (scroll)="tableScroll($event)" #rc>
		<div
			[attr.data-motion-translate]="(frameMotion$ | async).direction"
			style="width: 100%; height: 100%; display: flex; justify-content: center; flex: 1 1 auto">
			<div class="search-wrapper">
				<app-sp-search
					[hideStatuses]="['suspended', 'disabled']"
					[focused]="false"
					[width]="'400px'"
					[height]="'40px'"
					[role]="'_profile_teacher'"
					[searchTarget]="'users'"
					[placeholder]="'Search teachers'"
					[list]="false"
					[fontSize]="'16px'"
					[searchingTeachers]="filteredTeachers"
					(isOpenedOptions)="isOpenedOptions.emit($event)"
					[emitSingleProfile]="true"
					(onUpdate)="updateTarget($event)"></app-sp-search>
			</div>
		</div>
	</div>
</div>
