<div class="wrapper ds-flex-center-between ds-flex-column ds-p-20">
	<div class="header ds-dialog-title ds-navy ds-w-100">
		<span>Upload a list of student emails</span>
	</div>

	<div appDragAndDrop (filesDraggedAndDropped)="onFileDrag($event)" class="content ds-flex-center-center ds-w-100">
		<ng-container [ngTemplateOutlet]="tpl" [ngTemplateOutletContext]="{ $implicit: tplImplicit }"></ng-container>
	</div>

	<div class="footer ds-w-100 ds-flex-center-between">
		<div class="template-link">
			<a
				href="https://cdn-std.droplr.net/files/acc_1148136/krqp31?download=&response-content-disposition=attachment; filename=Upload%20List%20of%20Students%20Template%20-%20SmartPass%20Upload.csv"
				target="_self"
				>Template
				<span><img class="span-download" [src]="'./assets/Download circle (Blue-Gray).svg'" /></span>
			</a>
		</div>
		<div class="cancel-button" (click)="closeModal()">Cancel</div>
		<ng-container *ngIf="buttonState$ | async as vm">
			<app-gradient-button
				[size]="'editable'"
				[disabled]="!vm.active"
				[buttonDownColor]="'#00B476'"
				[cursor]="'pointer'"
				(buttonClick)="addStudents(vm?.students)"
				[minHeight]="'51px'"
				[width]="'164px'">
				<span class="add-students-text">{{ vm.text }}</span>
			</app-gradient-button>
		</ng-container>
	</div>
</div>

<ng-template #upload let-data>
	<div #dropArea appDragAndDrop class="uploading-area ds-flex-center-center" [class.has-no-issues]="data.showClose">
		<span class="link-like" [class]="{ gray: !data.showClose, green: data.showClose }" (click)="file.click()">
			{{ data.text }}
		</span>
		<span *ngIf="data.secondaryText" class="secondary-text"> {{ data.secondaryText }} </span>

		<!--//
      <app-gradient-button
        [style.margin-left]="'7px'"
        [size]="'small'"
        [gradient]="'#F4F4F4, #F4F4F4'"
        [buttonLink]="'https://www.smartpass.app/roomvisibilitytemplate'"
        [linkType]="'_blank'"
        [download]="true"
        [buttonDownColor]="'#F4F4F4'"
        [textColor]="'#1E194F'"
        [leftIcon]="'./assets/Download (Navy).svg'"
        [text]="'Download Template'"
        [cursor]="'pointer'">
      </app-gradient-button>
-->
		<!-- <app-gradient-button
			[style.margin-left]="'7px'"
			[size]="'small'"
			[gradient]="'#F4F4F4, #F4F4F4'"
			[buttonLink]="'./assets/xlsx/SmartPass Room Visibility Template.xlsx'"
			[linkType]="'_self'"
			[draggable]="true"
			[buttonDownColor]="'#F4F4F4'"
			[textColor]="'#1E194F'"
			[leftIcon]="'./assets/Download (Navy).svg'"
			[text]="'Download Template'"
			[cursor]="'pointer'">
		</app-gradient-button> -->
		<img *ngIf="data.showClose" class="close" [src]="'./assets/Close (Navy).svg'" (click)="goUpload()" alt="Check.svg" />
	</div>

	<input type="file" accept=".xls,.xlsx,.csv" #file style="display: none" />
</ng-template>

<ng-template #spinning let-data>
	<div class="ds-flex-center-center ds-flex-column">
		<span class="ds-pb-10 gray">{{ data.hint }}</span>
		<div class="loading-theme_blue-gray">
			<mat-spinner [color]="'accent'" [diameter]="30"></mat-spinner>
		</div>
	</div>
</ng-template>

<ng-template #issues let-data>
	<div class="uploading-area ds-flex-center-center ds-flex-column has-issues">
		<div class="warning-text ds-dialog-heading ds-pt-10 ds-pb-10">{{ data.warning }}</div>
		<div class="wrap-for-scroll">
			<p *ngFor="let email of data.fails" class="ds-cell-text-2 ds-pb-10">
				{{ email }}
			</p>
		</div>

		<img class="close" [src]="'./assets/Close (Navy).svg'" (click)="goUpload()" alt="Check.svg" />
	</div>
</ng-template>
