<div class="id-card-main-wrapper" *ngIf="isIdCardLoaded">
	<!-- <p class="ds-dialog-title" [style.color]="darkTheme.getColor({white: '#1F195E', dark: '#FFFFFF'})">Preview</p> -->
	<div class="id-card-wrapper flip-box" [ngStyle]="{ height: selectedBarcodeType == 'qr-code' ? '415px' : '375px' }">
		<div class="flip-box-inner" id="flip-box-inner">
			<div class="flip-box-front">
				<sp-id-card-front
					[userName]="userName"
					[backgroundColor]="backgroundColor"
					[showCustomID]="showCustomID"
					[profile_picture]="profile_picture"
					[logoURL]="logoURL"
					[isDummyCard]="isDummyCard"
					[isLoggedIn]="isLoggedIn"
					[greadLevel]="greadLevel"
					[IDNumberData]="IDNumberData"
					[barcodeType]="barcodeType"
					[stickers]="stickers"></sp-id-card-front>
			</div>
			<div class="flip-box-back">
				<pre [innerHTML]="backsideText"></pre>
			</div>
		</div>
		<div class="design" [ngClass]="{ 'id-free-plan': !idCardAccess }">
			<img [src]="'./assets/DIgital ID Card Design.svg' | resolveAsset" alt="" />
		</div>
	</div>
	<div class="button flip-btn">
		<app-gradient-button
			[style.margin-left]="'15px'"
			[buttonDownColor]="'#00B476'"
			[textColor]="'#7083A0'"
			[border]="'#D4D9E2'"
			[gradient]="buttonBackColor"
			[rightIcon]="'./assets/Undo.svg'"
			[rightIconWidth]="'13px'"
			(buttonClick)="toggleFlip()">
			{{ getButtonText }}
		</app-gradient-button>
	</div>
</div>
