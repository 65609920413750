<form [formGroup]="renameForm">
	<sp-input
		[group]="renameForm"
		[control]="'name'"
		[label]="label"
		[description]="description"
		[displayPlaceholder]="true"
		[placeholder]="placeholder"
		[errorMessage]="errorMessage"></sp-input>
</form>
