import { User } from './User';

export enum AttendanceRecordState {
	Present = 'present',
	Absent = 'absent',
	LateExcused = 'late-excused',
	LateNotExcused = 'late-not-excused',
	NotSelected = 'not-selected',
}
export class AttendanceRecord {
	id: number;
	school_id: number;
	activity_id: number;
	activity_instance_id: number;
	attendance_user: User;
	created_at: Date;
	created_by_user: User;
	updated_at: Date;
	last_updated_by_user: User;
	state: AttendanceRecordState;

	constructor(json) {
		if (!json) {
			return null;
		}

		this.id = json.id;
		this.school_id = json.school_id;
		this.activity_id = json.activity_id;
		this.activity_instance_id = json.activity_instance_id;
		this.attendance_user = User.fromJSON(json.attendance_user);
		this.created_at = new Date(json.created_at);
		this.created_by_user = User.fromJSON(json.created_by_user);
		this.updated_at = new Date(json.updated_at);
		this.last_updated_by_user = User.fromJSON(json.last_updated_by_user);
		this.state = json.state;
	}
}
