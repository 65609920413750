import { Component, EventEmitter, Input, AfterViewInit, OnChanges, Output, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { bumpIn } from '../../animations';
import { PassLimitInfo } from '../../models/HallPassLimits';
import { UserService } from '../../services/user.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { filter } from 'rxjs/operators';
import { IntroData } from '../../ngrx/intros';
import { DeviceDetection } from '../../device-detection.helper';

@Component({
	selector: 'app-create-pass-button',
	templateUrl: './create-pass-button.component.html',
	styleUrls: ['./create-pass-button.component.scss'],
	animations: [bumpIn],
})
export class CreatePassButtonComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() title = '';
	@Input() fontSize = '16px';
	@Input() fontWeight = '500';
	@Input() iconMarginRight = '5px';
	@Input() textColor = '#7083A0';
	@Input() gradient = '';
	@Input() backgroundColor = '#fff';
	@Input() hoverBackgroundColor = '#F0F2F5';
	@Input() width = '144px';
	@Input() height = '51px';
	@Input() icon = '';
	@Input() disabled = false;
	@Input() passLimitInfo!: PassLimitInfo;
	@Input() hoverText = '';
	@Output() onClick = new EventEmitter<void>();

	buttonDown = false;
	hovered = false;
	nuxWrapperPosition: ConnectedPosition = {
		originX: 'center',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
		offsetY: 15,
	};
	showPassLimitNux = new Subject<boolean>();
	solid_color = '#FFF';
	introsData!: IntroData;
	introSubs!: Subscription;
	passLimitNuxDesc = '';
	isIOSTablet = false;

	constructor(private sanitizer: DomSanitizer, private userService: UserService) {}

	ngOnChanges() {
		this.isIOSTablet = DeviceDetection.isIOSTablet();
		if (this.title !== 'Now') {
			return;
		}

		if (!this.passLimitInfo) {
			this.passLimitInfo = { showPasses: false };
		}

		const frequency = this.passLimitInfo.frequency === 'day' ? 'today' : 'this ' + this.passLimitInfo.frequency;
		if (this.passLimitInfo.showPasses) {
			this.passLimitNuxDesc = `You have ${this.passLimitInfo.current}/${this.passLimitInfo.max} passes remaining ${frequency}.
      After that, you will have to request a pass from your teacher.`;
		}

		this.introSubs = this.userService.introsData$.pipe(filter((i) => !!i)).subscribe({
			next: (intros) => {
				this.introsData = intros;
				this.showPassLimitNux.next(!!this.passLimitInfo?.showPasses && !intros?.student_pass_limit?.universal?.seen_version);
			},
		});
	}

	ngAfterViewInit() {
		if (this.title === 'Now') {
			this.nuxWrapperPosition = {
				originX: 'start',
				originY: 'top',
				overlayX: 'start',
				overlayY: 'top',
				offsetY: 40,
				offsetX: -10,
			};
		}
	}

	get buttonState() {
		return this.buttonDown && !this.disabled ? 'down' : 'up';
	}

	get boxShadow() {
		let i = 0;
		const hexColors = [];
		const rawHex = this.solid_color.slice(1);
		do {
			hexColors.push(rawHex.slice(i, i + 2));
			i += 2;
		} while (i < rawHex.length);
		const rgbString = hexColors.map((color) => parseInt(color, 16)).join(', ');
		return this.sanitizer.bypassSecurityTrustStyle(
			this.hovered
				? `0px 3px 10px rgba(${rgbString}, 0.3)`
				: this.buttonDown
				? `0px 3px 5px rgba(${rgbString}, 0.15)`
				: '0px 3px 5px rgba(0, 0, 0, 0.1)'
		);
	}

	buttonClick() {
		if (!this.disabled) {
			this.onClick.emit();
		}
	}

	dismissNux() {
		this.showPassLimitNux.next(false);
		this.userService.updateIntrosStudentPassLimitRequest(this.introsData, 'universal', '1');
	}

	ngOnDestroy() {
		if (this.introSubs) {
			this.introSubs.unsubscribe();
		}
	}
}
