import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DropdownOptions<T> {
	title: string;
	value: T;
	icon?: string;
	iconSelected?: string;
}

export interface DropdownConfig<T> {
	currentlySelected: DropdownOptions<T> | null;
	options: DropdownOptions<T>[];
	comparison?(selected: T | undefined, checkValue: T): boolean;
}

/**
 * DropdownSelectionComponent takes a generic list of options and displays on the screen
 * in a dialog window.
 * In addition to listing the options that are passed in, this component also highlights the
 * currently selected value;
 * For objects or custom-types, a comparison function should be passed in so the check for equality
 * can determine which item was selected. If a comparison function is not specified, the component will
 * assume that the option type is a primitive (number, string, boolean, etc.) and will be compared with ===
 */
@Component({
	selector: 'app-dropdown-selection',
	templateUrl: './dropdown-selection.component.html',
	styleUrls: ['./dropdown-selection.component.scss'],
})
export class DropdownSelectionComponent<T> {
	comparisonFunc = (selected: T | undefined, checkValue: T): boolean => selected === checkValue;

	constructor(@Inject(MAT_DIALOG_DATA) public data: DropdownConfig<T>, private dialogRef: MatDialogRef<DropdownSelectionComponent<T>>) {
		if (data.comparison) {
			this.comparisonFunc = data.comparison;
		}
	}

	isSelected(option: DropdownOptions<T>): boolean {
		return this.comparisonFunc(this.data.currentlySelected?.value, option.value);
	}

	selectOption(option: DropdownOptions<T>) {
		this.dialogRef.close(option);
	}
}
