import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleGroupBellSchedule } from '../models/Schedule';

@Pipe({
	name: 'selectedBellSchedule',
	pure: false,
})
export class SelectedBellSchedulePipe implements PipeTransform {
	transform(bellScheduleId: number, data: ScheduleGroupBellSchedule[], isDisplayingHolidayPane: boolean): boolean {
		if (isDisplayingHolidayPane) {
			return false;
		}
		for (const datum of data) {
			if (datum.bell_schedule_id === bellScheduleId) {
				return true;
			}
		}
		return false;
	}
}
