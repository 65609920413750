import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../models/User';
import { UserService } from '../services/user.service';
import { filter } from 'rxjs/operators';
@Component({
	selector: 'app-idcard-overlay-container',
	templateUrl: './idcard-overlay-container.component.html',
	styleUrls: ['./idcard-overlay-container.component.scss'],
})
export class IdcardOverlayContainerComponent implements OnInit {
	IDCARDDETAILS: any;
	IDCardstickers = [];
	user: User;
	isUserStaff = false;
	constructor(
		public dialogRef: MatDialogRef<IdcardOverlayContainerComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.IDCARDDETAILS = this.dialogData.idCardData;
		this.IDCardstickers = JSON.parse(JSON.stringify(this.IDCARDDETAILS.stickers));
		this.userService.userJSON$.pipe(filter((u) => !!u)).subscribe({
			next: (user) => {
				this.user = User.fromJSON(user);
				this.isUserStaff = this.user.isStaff();
			},
		});
	}

	print() {
		window.print();
	}
}
