<div class="activity-box-wrapper" (click)="!isFull && !isSelected && onSelectActivity()">
	<div class="chip" *ngIf="isFull"><app-sp-chip [text]="'Full'"></app-sp-chip></div>
	<div class="activity-box" [ngClass]="{ full: isFull, selected: isSelected }">
		<img [src]="'./assets/School (Blue-Gray).svg' | resolveAsset" alt="icon" class="activity-icon" />
		<div>
			<div class="activity-name">{{ activity.name }}</div>
			<div class="activity-teacher">
				<img
					*ngIf="activity.profile_picture"
					src="{{ activity.profile_picture }}"
					class="teacher-photo"
					alt="photo of {{ activity.teacher_name }}" />{{ activity.teacher_name }}
			</div>
			<div class="activity-capacity">{{ studentCapacity }}</div>
			<div>{{ instance.start_time | date : 'MMM dd hh:mm a' }}</div>
		</div>
	</div>
</div>
