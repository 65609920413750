import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-mooving-tiles',
	templateUrl: './mooving-tiles.component.html',
	styleUrls: ['./mooving-tiles.component.scss'],
})
export class MoovingTilesComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
