import { useEffect, useState } from 'react';
import { ActivityWithInstance } from '../../../hooks/use-activity-instance';
import { countAttendees } from '../lib';

export function useNumAttendees(activityInstances: ActivityWithInstance[]) {
	const [numAttending, setNumAttending] = useState(0);
	useEffect(() => {
		setNumAttending(countAttendees(activityInstances));
	}, [activityInstances]);

	return { numAttending };
}
