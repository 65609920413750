import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { captureMessage } from '@sentry/angular';
import { remove } from 'lodash';
import { BehaviorSubject, combineLatest, iif, interval, merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, pluck, retryWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Util } from '../../Util';
import { scalePassCards } from '../animations';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { CreateFormService, FrameMotionTransition } from '../create-hallpass-forms/create-form.service';
import { MainHallPassFormComponent, Navigation } from '../create-hallpass-forms/main-hallpass--form/main-hall-pass-form.component';
import { DeviceDetection } from '../device-detection.helper';
import { HallPass, HallPassStatus } from '../models/HallPass';
import { HallPassLimit } from '../models/HallPassLimits';
import { Location } from '../models/Location';
import { RecurringConfig } from '../models/RecurringFutureConfig';
import { School } from '../models/School';
import { User } from '../models/User';
import { CalendarService } from '../services/calendar.service';
import { DomCheckerService } from '../services/dom-checker.service';
import { FeatureFlagService, FLAGS } from '../services/feature-flag.service';
import { HallPassesService, METRICS_FOOTER_HEIGHT, STUDENT_INFO_FOOTER_HEIGHT } from '../services/hall-passes.service';
import { KeyboardShortcutsService } from '../services/keyboard-shortcuts.service';
import { KioskMonitoringService } from '../services/kiosk-monitoring.service';
import { LocationsService } from '../services/locations.service';
import { PassLimitService } from '../services/pass-limit.service';
import { RecurringSchedulePassService } from '../services/recurring-schedule-pass.service';
import { ScreenService } from '../services/screen.service';
import { SoundService } from '../services/sound.service';
import { StorageKeys, StorageService } from '../services/storage.service';
import { TimeService } from '../services/time.service';
import { ToastService } from '../services/toast.service';
import { UserService } from '../services/user.service';
import { ConfirmationDialogComponent, ConfirmationTemplates } from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';

interface PassOption {
	display: string;
	color: string;
	action: string;
	icon: string;
}

interface PassCardComponentData {
	pass: HallPass;
	kioskMode: boolean;
}

@Component({
	selector: 'app-pass-card',
	templateUrl: './pass-card.component.html',
	styleUrls: ['./pass-card.component.scss'],
	animations: [scalePassCards],
	providers: [DatePipe],
})
export class PassCardComponent implements OnInit, OnDestroy, AfterViewInit {
	@HostListener('window:resize', ['$event.target'])
	onResize() {
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (!this.forStaff && !this.forKioskMode && this.passStatus === 'active') {
				footerHeight = METRICS_FOOTER_HEIGHT;
			} else if (
				(this.data.kioskMode && !this.isModal) ||
				(this.forStaff && this.isModal && (this.showStudentInfoBlock || this.passStatus === 'overtime')) ||
				(this.forKioskMode && this.isModal && this.showStudentInfoBlock)
			) {
				footerHeight = STUDENT_INFO_FOOTER_HEIGHT;
			}
			this.hallPassesService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
			this.setIconPosition();
			this.cdr.detectChanges();
		}
	}

	// This input is used when a pass modal is within the hall pass creation workflow.
	// It is used to apply different styling when the pass is in the smaller form container,
	// except for when the pass is within the kiosk mode form flow.
	@Input() inFormContainer = false;

	// This input is used when a pass modal is within the hall pass creation workflow in kiosk mode.
	@Input() forKioskModeFormContainer = false;

	@Input() pass: HallPass;
	@Input() forInput = false;
	@Input() fromPast = false;
	@Input() forFuture = false;
	@Input() isActive = false;
	@Input() forStaff = false;
	@Input() forMonitor = false;
	@Input() forKioskMode = false;
	@Input() formState: Navigation;
	@Input() students: User[] = [];

	@Output() cardEvent = new EventEmitter<Navigation>();

	@ViewChild('modalContainer') modalContainer: ElementRef;
	@ViewChild('cardWrapper') cardWrapper: ElementRef;
	@ViewChild('confirmDialogBody') confirmDialog: TemplateRef<HTMLElement>;
	@ViewChild('confirmDialogBodyVisibility') confirmDialogVisibility: TemplateRef<HTMLElement>;

	timeLeft = '';
	timeLeftForProgressCircle = 1;
	valid = true;

	selectedDuration: number;
	selectedTravelType = 'one_way';
	private cancelOpen = false;
	selectedStudents: User[] = [];

	pagerPages = 0;

	closeIconRightPos: string;
	closeIconTopPos: string;

	p1Title;
	p1Subtitle;
	p1Stamp;
	p2Title;
	p2Subtitle;
	p2Stamp;
	p3Title;
	p3Subtitle;
	p3Stamp;
	p4Title;
	p4Subtitle;
	p4Stamp;

	private user: User;
	activePage;

	performingAction: boolean;
	isModal: boolean;
	showStudentInfoBlock = true;
	passForStudentsComponent: boolean;

	private header: string;
	private options: PassOption[] = [];
	frameMotion$: BehaviorSubject<FrameMotionTransition>;
	private school: School;
	recurringConfig: RecurringConfig;
	passStatus: HallPassStatus;
	backgroundGradient: string;
	footerBackgroundGradient: string;
	endedBgColorClass = '';
	secondaryColor: string;
	passCssClasses: string;
	borderRadius: string;
	borderClasses: string;
	showCloseIcon = false;
	selectedLocation: Location;
	selectedLocation$: BehaviorSubject<Location> = new BehaviorSubject<Location>(null);
	showTeacherPinEntry = false;
	activeRoomCodePin: boolean;
	activeTeacherPin: boolean;
	activeTeacherSelection: boolean;
	selectedTeacher: User;
	buttonText: string;
	isMobile = false;
	isStudentSnapshotPage = false;
	duration: string;
	upcomingPassDuration: string;
	originRoomIcon: string;
	createdBy: string;
	isOpenedOptions = false;
	passLimit: HallPassLimit;
	private isSmartphone = DeviceDetection.isAndroid() || DeviceDetection.isIOSMobile();

	scaleCardTrigger$: Observable<string>;

	private startPassTrigger$ = new Subject<void>();

	private destroy$ = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<PassCardComponent | MainHallPassFormComponent>,
		@Inject(MAT_DIALOG_DATA) public data: PassCardComponentData,
		private viewContainerRef: ViewContainerRef,
		private hallPassService: HallPassesService,
		public dialog: MatDialog,
		private formService: CreateFormService,
		private timeService: TimeService,
		public screenService: ScreenService,
		private shortcutsService: KeyboardShortcutsService,
		private domCheckerService: DomCheckerService,
		private userService: UserService,
		private toastService: ToastService,
		private locationsService: LocationsService,
		private recurringConfigService: RecurringSchedulePassService,
		private soundService: SoundService,
		private storageService: StorageService,
		private features: FeatureFlagService,
		private cdr: ChangeDetectorRef,
		private hallPassesService: HallPassesService,
		private locationService: LocationsService,
		private calendarService: CalendarService,
		private datePipe: DatePipe,
		private router: Router,
		private kioskMonitoring: KioskMonitoringService,
		private passLimitService: PassLimitService
	) {}

	private getUserName(user: User): string {
		if (!(user instanceof User)) {
			user = User.fromJSON(user);
		}
		return user.isSameObject(this.user) ? 'Me' : user.abbreviatedName();
	}

	private getCreatedByUserName(pass: HallPass): string {
		return pass.issuer?.display_name ? pass.issuer.display_name : '';
	}

	get waitInLineEnabled(): boolean {
		return this.features.isFeatureEnabled(FLAGS.WaitInLine);
	}

	get originWaitInLineEnabled(): boolean {
		return this.features.isFeatureEnabledV2(FLAGS.OriginWaitInLine);
	}

	get gradient() {
		return 'radial-gradient(circle at 73% 71%, ' + this.pass.color_profile.gradient_color + ')';
	}

	get closeIcon() {
		if ((this.isActive && this.forStaff) || this.forMonitor) {
			return './assets/Dots (Transparent).svg';
		} else {
			return './assets/' + (this.forInput ? 'Chevron Left ' : 'Delete ') + '(Transparent).svg';
		}
	}

	getButtonText(): string {
		// If there are no selected students, this pass is being created for oneself (numStudents becomes 1)
		const numStudents = this.selectedStudents?.length || 1;
		const PASS = `Pass${numStudents > 1 ? 'es' : ''}`;

		if (this.forFuture && this.forInput) {
			return `Schedule ${PASS}`;
		}

		const destLimitReached = this.formState?.data?.destLimitReached;
		const origLimitReached = this.originWaitInLineEnabled && this.formState?.data?.origLimitReached;
		if (this.waitInLineEnabled && (destLimitReached || origLimitReached) && numStudents === 1) {
			return this.forStaff ? 'Send to Line' : 'Wait in Line';
		}

		return this.forStaff ? `Send ${PASS}` : `Start ${PASS}`;
	}

	ngOnInit() {
		this.isStudentSnapshotPage = window.location.pathname.includes('main/student');
		this.borderRadius = this.formState?.openedFrom !== 'navbar' ? '20px' : '0 0 20px 20px';
		this.frameMotion$ = this.formService.getFrameMotionDirection();
		this.scaleCardTrigger$ = this.domCheckerService.scalePassCard;
		this.school = this.userService.getUserSchool();
		this.isMobile = DeviceDetection.isMobile();
		this.passLimitService.getPassLimit().subscribe((pl) => (this.passLimit = pl.pass_limit));

		try {
			const passObj = JSON.stringify(this.pass);
			// no pass at all, in input or dialog data
			if (!this.pass && !this.data['pass']) {
				captureMessage('no pass in pass card from input or data - schoolId: ' + this.school.id);
			} else {
				// has input but no destination
				if (this.pass && !this.pass.destination) {
					captureMessage('from pass input - no pass destination in pass card: ' + passObj + ' schoolId: ' + this.school.id);
				} else if (this.data['pass'] && !this.data['pass'].destination) {
					// has dialog data but no destination
					captureMessage('from pass data - no pass destination in pass card: ' + passObj + ' schoolId: ' + this.school.id);
				}
				// has input but no origin
				if (this.pass && !this.pass.origin) {
					captureMessage('from pass input - no pass origin in pass card: ' + +' schoolId: ' + this.school.id);
				} else if (this.data['pass'] && !this.data['pass'].origin) {
					// has dialog data but no origin
					captureMessage('from pass data - no pass origin in pass card: ' + passObj + ' schoolId: ' + this.school.id);
				}
			}
		} catch (e) {
			console.log('error trying to capture message in sentry', e);
		}

		if (this.data['pass']) {
			this.isModal = true;
			this.pass = this.data['pass'];
			this.forKioskMode = this.data['kioskMode'];
			this.passStatus = this.hallPassesService.getPassStatus(
				this.pass.start_time,
				this.pass.end_time,
				this.pass.expiration_time,
				this.waitInLineEnabled && (this.formState?.data?.destLimitReached || this.formState?.data?.origLimitReached),
				this.inFormContainer,
				this.forKioskModeFormContainer
			);
			this.forInput = this.data['forInput'];
			this.isActive = this.data['isActive'];
			this.forFuture = this.data['forFuture'];
			this.fromPast = this.data['fromPast'];
			this.forStaff = this.data['forStaff'];
			this.selectedStudents = this.data['selectedStudents'];
			this.forMonitor = this.data['forMonitor'];
			this.showStudentInfoBlock = this.data['showStudentInfoBlock'];
			this.passForStudentsComponent = this.data['passForStudentsComponent'];
			if (!this.forFuture && this.pass.end_time) {
				this.duration = this.getDuration();
			}
			if (this.data['showTeacherPinEntry']) {
				this.showTeacherPinEntry = this.data['showTeacherPinEntry'];
				this.activeRoomCodePin = this.data['showTeacherPinEntry'];
			}
		} else {
			this.selectedStudents = this.students;
		}
		if (this.pass && !this.forFuture && this.pass.end_time) {
			this.duration = this.getDuration();
		}
		// set custom modal backdrop if pass is active
		if (this.isActive) {
			this.screenService.customBackdropStyle$.next({
				background: this.hallPassesService.setPassOverlayColor(!this.forStaff, this.pass),
			});
			this.screenService.customBackdropEvent$.next(true);
		}
		this.buttonText = this.getButtonText();
		this.getOriginRoomIcon();
		this.setPassUI();
		if (this.passStatus === 'upcoming' && !this.inFormContainer && !this.forKioskModeFormContainer) {
			const start: Date = this.pass.start_time;
			const end: Date = this.pass.expiration_time;
			const timeDiff = Math.abs(start.getTime() - end.getTime());
			const diffSecs = Math.ceil(timeDiff / 1000);

			this.upcomingPassDuration = `${Math.floor(diffSecs / 60)} min`;
		}
		this.createdBy = this.getCreatedByUserName(this.pass);
		this.userService.userData
			.pipe(
				filter<User>(Boolean),
				takeUntil(this.destroy$),
				switchMap((user) => {
					return combineLatest([
						this.locationService.getLocationsWithTeacherRequest(user).pipe(filter((res: Location[]) => !!res.length)),
						this.locationService.myRoomSelectedLocation$,
					]);
				})
			)
			.subscribe({
				next: ([locations, selected]) => {
					if (!selected) {
						this.selectedLocation = locations[0];
						this.selectedLocation$.next(locations[0]);
					} else {
						this.selectedLocation = selected;
						this.selectedLocation$.next(selected);
					}
				},
			});

		this.hallPassService
			.watchStartPass(this.pass.id)
			.pipe(takeUntil(this.destroy$))
			.subscribe((pass) => {
				this.pass = pass;
				this.passStatus = this.hallPassesService.getPassStatus(this.pass.start_time, this.pass.end_time, this.pass.expiration_time);
				this.setPassUI();
				this.isActive = true;
				this.forFuture = false;
				this.startPassTrigger$.next();
			});

		merge(this.hallPassService.watchEndPass(this.pass.id), this.hallPassService.watchCancelPass(this.pass.id))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.dialogRef.close();
				this.screenService.clearCustomBackdrop();
			});

		if (this.pass?.schedule_config_id) {
			this.recurringConfigService.getRecurringScheduledConfig(this.pass.schedule_config_id).subscribe({
				next: (c) => (this.recurringConfig = c),
			});
		}

		this.userService.userJSON$
			.pipe(
				map((user) => User.fromJSON(user)),
				takeUntil(this.destroy$)
			)
			.subscribe((user) => {
				this.user = user;
				this.buildPages();
			});

		merge(of(0), interval(1000), this.startPassTrigger$.pipe(switchMap(() => interval(1000))))
			.pipe(
				filter(() => !!this.pass && this.isActive),
				tap(() => {
					const start: Date = this.pass.start_time;
					const expire: Date = this.pass.expiration_time;
					const end: Date = this.pass.end_time;
					const now: Date = this.timeService.nowDate();
					if (now > end) {
						this.endPass(false);
						return;
					}
					const diff: number = (expire.getTime() - now.getTime()) / 1000;
					const mins: number = Math.floor(Math.abs(Math.floor(diff) / 60));
					const secs: number = Math.abs(Math.floor(diff) % 60);
					this.timeLeft = mins + ':' + (secs < 10 ? '0' + secs : secs);
					const passTotalTime = expire.getTime() - start.getTime();
					const timeElapsed = now.getTime() - start.getTime();
					this.timeLeftForProgressCircle = 1 - timeElapsed / passTotalTime;
					this.valid = expire > now;
					if (!this.valid) {
						this.passStatus = 'overtime';
						this.getCssClasses();
						this.setBackgroundColor();
						this.setBackDropColor();
					}
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();

		this.shortcutsService.onPressKeyEvent$
			.pipe(
				filter(() => this.forStaff),
				pluck('key'),
				takeUntil(this.destroy$)
			)
			.subscribe((key) => {
				if (key[0] === 'e') {
					this.endPass(false);
				} else if (key[0] === 'r') {
					this.dialogRef.close({ report: this.pass.student });
				}
			});

		this.locationsService
			.listenLocationSocket()
			.pipe(
				takeUntil(this.destroy$),
				filter((res) => !!res),
				tap((res) => {
					try {
						const loc: Location = Location.fromJSON(res.data);
						this.locationsService.updateLocationSuccessState(loc);
						this.pass.destination.title = loc.title;
					} catch (e) {
						console.log(e);
					}
				})
			)
			.subscribe();

		/**
		 * The close button of this component is absolutely positioned. Since the close button is relative to the host
		 * component wrapper, it will be absolutely positioned relative to the dialog container while the container is
		 * opening. After the container is fully opened, it will then be positioned relative to the viewport. This is
		 * the default behaviour and causes the button to appear within the dialog container and then suddenly jump to
		 * the edge of the viewport.
		 *
		 * The following two dialog listeners address this issue. So far, it's the simplest hack to avoid the close
		 * button jumping around due to its absolute positioning.
		 */

		this.dialogRef.afterOpened().subscribe(() => {
			this.showCloseIcon = this.isModal;
		});

		this.dialogRef.backdropClick().subscribe(() => {
			this.screenService.clearCustomBackdrop();
		});

		this.dialogRef.backdropClick().subscribe(() => {
			this.showCloseIcon = false;
		});
	}

	ngAfterViewInit(): void {
		if (this.forKioskModeFormContainer) {
			const modalHeight = this.hallPassesService.getModalHeight(this.pass, false, true);
			const modalWidth = this.hallPassesService.getModalWidth(this.isSmartphone);
			this.dialogRef.updateSize(modalWidth, modalHeight);
		}
		// scale modal and position close icon if not in hall pass creation form container
		if (!this.inFormContainer) {
			let footerHeight = 0;
			if (!this.forStaff && !this.forKioskMode && this.passStatus === 'active') {
				footerHeight = METRICS_FOOTER_HEIGHT;
			} else if (
				(this.data.kioskMode && !this.isModal) ||
				(this.forStaff && this.isModal && (this.showStudentInfoBlock || this.passStatus === 'overtime')) ||
				(this.forKioskMode && this.isModal && this.showStudentInfoBlock)
			) {
				footerHeight = STUDENT_INFO_FOOTER_HEIGHT;
			}
			this.hallPassesService.scaleMatDialog(this.dialogRef, this.viewContainerRef.element.nativeElement, footerHeight);
			this.dialogRef.updatePosition();
			this.setIconPosition();
			this.cdr.detectChanges();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.pass && !!changes.pass.currentValue) {
			this.pass = changes.pass.currentValue as HallPass;
			this.passStatus = this.hallPassesService.getPassStatus(
				this.pass.start_time,
				this.pass.end_time,
				this.pass.expiration_time,
				this.inFormContainer,
				this.forKioskModeFormContainer
			);
			this.setIconPosition();
			this.cdr.detectChanges();
			this.setPassUI();
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		this.showTeacherPinEntry = false;
		this.activeRoomCodePin = false;
	}

	formatDateTime(date: Date) {
		date = new Date(date);
		return Util.formatDateTime(date);
	}

	getDuration() {
		const start: Date = this.pass.start_time;
		const end: Date = this.pass.expiration_time;
		const timeDiff = Math.abs(start.getTime() - end.getTime());
		const diffSecs = Math.ceil(timeDiff / 1000);
		return Math.floor(diffSecs / 60) + ':' + (diffSecs % 60 < 10 ? '0' : '') + (diffSecs % 60);
	}

	buildPages() {
		if (this.pass.parent_invitation) {
			this.buildPage(
				'Pass Request Accepted',
				'By ' + this.getUserName(this.pass.student),
				this.formatDateTime(this.pass.created),
				this.pagerPages + 1
			);
		} else if (this.pass.parent_request) {
			this.buildPage(
				'Pass Request Accepted',
				'By ' + this.getUserName(this.pass.issuer),
				this.formatDateTime(this.pass.created),
				this.pagerPages + 1
			);
		} else if (this.forFuture && this.pass.issuer) {
			this.buildPage('Scheduled to start', '', this.formatDateTime(this.pass.start_time), this.pagerPages + 1);
		} else if (this.pass.issuer) {
			this.buildPage('Pass Created', 'By ' + this.getUserName(this.pass.issuer), this.formatDateTime(this.pass.created), this.pagerPages + 1);
		}

		if (this.isActive) {
			this.buildPage('Pass Started', '', this.formatDateTime(this.pass.start_time), this.pagerPages + 1);
			this.activePage = this.pagerPages;
		} else if (this.fromPast) {
			const start: Date = this.pass.start_time;
			const end: Date = this.pass.end_time;
			const diff: number = (end.getTime() - start.getTime()) / 1000;
			const mins: number = Math.floor(Math.floor(diff) / 60);
			const secs: number = Math.abs(Math.floor(diff) % 60);
			const totalTime = mins + ':' + (secs < 10 ? '0' + secs : secs);
			const stamp =
				this.user.id == this.data?.pass?.ended_by_user_id
					? 'By Me'
					: this.data?.pass?.ended_by_user_name
					? 'By ' + this.data?.pass.ended_by_user_name
					: 'Automatically Ended';
			this.buildPage('Pass Ended', '', stamp + ' - ' + totalTime, this.pagerPages + 1);
		}
	}

	buildPage(title: string, subtitle: string, stamp: string, page: number) {
		if (page === 1) {
			this.p1Title = title;
			this.p1Subtitle = subtitle;
			this.p1Stamp = stamp;
		} else if (page === 2) {
			this.p2Title = title;
			this.p2Subtitle = subtitle;
			this.p2Stamp = stamp;
		} else if (page === 3) {
			this.p3Title = title;
			this.p3Subtitle = subtitle;
			this.p3Stamp = stamp;
		} else if (page === 4) {
			this.p4Title = title;
			this.p4Subtitle = subtitle;
			this.p4Stamp = stamp;
		}
		this.pagerPages++;
	}

	private prepareTemplateDataVisibility({ visibility_alerts, students }, origin, destination) {
		const out = [];
		for (const a of visibility_alerts) {
			const ss = a['room_students'].map((sid) => {
				const found = students.find((s) => s.User.id === sid);
				if (!found) return '<unknown name>';
				return found.User['first_name'] + ' ' + found.User['last_name'];
			});
			let joint = 'for';
			if (a['location_id'] == origin) {
				joint = 'to come from';
			} else if (a['location_id'] == destination) {
				joint = 'to go to';
			}
			//const phrase = `These students do not have permission ${joint} this room "${a['location_title']}"`;
			const phrase = `These students do not have permission ${joint} this room`;
			out.push({ phrase, students: ss.join(', ') });
		}
		return out;
	}

	get isRecurringFuture(): boolean {
		if (Number.isInteger(this.pass.schedule_config_id)) {
			// retrieving a pass from the server with a schedule_config_id
			return true;
		}

		return !!this.formState?.data?.date?.schedule_option;
	}

	newPass() {
		this.performingAction = true;

		const body = {
			duration: this.selectedDuration * 60,
			origin: this.pass.origin.id,
			destination: this.pass.destination.id,
			travel_type: this.selectedTravelType,
		};

		if (this.forStaff) {
			let ss: User[] = this.selectedStudents;
			if (this.formState.data?.roomStudents?.length > 0) {
				ss = this.formState.data.roomStudents;
			}
			body['students'] = ss.map((user) => user.id);
		} else {
			body['student'] = this.pass.student.id;
		}
		body['override_visibility'] = this.formState.data.roomOverride;

		if (this.forFuture) {
			body['issuer_message'] = this.pass.issuer_message;
			body['start_time'] = this.pass.start_time.toISOString();
			if (this.isRecurringFuture) {
				// if the schedule config is present and is repeating, then set the `recurring_scheduled_config` key, otherwise omit the key
				// from the body.
				// the backend will ignore if the key is not present
				body['recurring_scheduled_config'] = this.formState.data.date.schedule_option;
			}
		}
		let shouldPlaySound = false;
		if (this.forKioskMode) {
			body['self_issued'] = true;
			// We check if the limit is reached to avoid playing the sound when clicking on Send to Line
			if (
				!(this.formState?.data?.destLimitReached || this.formState?.data?.origLimitReached) &&
				this.school.feature_flag_alert_sounds &&
				!!this.storageService.getItem(StorageKeys.sounds)
			) {
				shouldPlaySound = true;
			}
		}
		if (!this.forStaff) {
			delete body['override_visibility'];
			if (this.forKioskMode) {
				// that's it, the origin room should be considered room visibility free
				// to permits a student in kiosk mode to appear in who-are-you component
				// so the code below
				body['override_visibility_origin'] = true;
			}
			body['isNotBulk'] = true;
		}

		of(body)
			.pipe(
				concatMap((b) => (this.forStaff ? this.hallPassService.bulkCreatePass(b) : this.hallPassService.createPass(b))),
				takeUntil(this.destroy$),
				switchMap(({ conflict_student_ids, passes, error, conflict_passes, prevented_encounters }) => {
					if (passes) {
						this.kioskMonitoring.sendCreatedPassForMonitoring(passes);
					}
					if (error) {
						const code = error.code;
						switch (code) {
							case 'max_pass_limit_reached':
								this.toastService.openToast({
									title: 'Maximum Hall Pass Limit Reached',
									subtitle: 'The maximum hall pass limit for the school has been reached. Please try again later.',
									type: 'error',
								});
								break;
							case 'pass_cooldown':
								this.toastService.openToast({
									title: error.message,
									type: 'error',
								});
								break;
							case 'no_fly_time_active':
								this.toastService.openToast({
									title: 'No Fly Time Active',
									subtitle: error.message,
									type: 'error',
								});
								break;
							default:
								this.toastService.openToast({
									title: 'Unknown error code',
									type: 'error',
								});
								break;
						}
						return of(null);
					}

					if (conflict_student_ids) {
						return this.hallPassService.handleEncounterPrevention({
							conflictPasses: conflict_passes,
							preventedEncounters: prevented_encounters,
							conflictStudentIds: conflict_student_ids,
							passes: passes,
							body: body,
							selectedTravelType: this.selectedTravelType,
							selectedStudents: this.selectedStudents,
							selectedLocation: this.selectedLocation,
							forStaff: this.forStaff,
							attemptedPass: this.pass,
							currentUser: this.user,
							onClose: () => this.dialogRef.close(),
						});
					}

					if (shouldPlaySound) {
						this.soundService.playSound('assets/audio/exquisite.ogg');
					}

					return of(passes);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) => {
					const getOverrideFromDialog = (error) => {
						const afterDialogClosed$ = this.dialog
							.open(ConfirmationDialogComponent, {
								panelClass: 'overlay-dialog',
								backdropClass: 'custom-backdrop',
								closeOnNavigation: true,
								data: {
									headerText: '',
									body: this.confirmDialogVisibility,
									buttons: {
										confirmText: 'Override',
										denyText: 'Skip These Students',
									},
									templateData: { alerts: this.prepareTemplateDataVisibility(error, body.origin, body.destination) },
									icon: {
										name: 'Eye (Green-White).svg',
										background: '',
										spacing: '5px',
									},
								} as ConfirmationTemplates,
							})
							.afterClosed();

						return afterDialogClosed$.pipe(
							map((override) => ({
								override,
								students: error.students.map((s) => s.User.id),
							}))
						);
					};

					return errors.pipe(
						tap((errorResponse) => {
							const isVisibilityError = 'visibility_alerts' in errorResponse.error;
							// not our error case? dispatch it to the next retryWhen
							if (!isVisibilityError) {
								throw errorResponse;
							}
							// a student has been checked server side and had no room visibility
							const isRoomsClosed = 'rooms_closed' in errorResponse.error;
							if (!this.forStaff && !isRoomsClosed) {
								const roomNames = errorResponse.error.visibility_alerts.map((r) => r?.location_title ?? '');
								const title = `You don't have access to ${
									roomNames.length > 1 ? 'Rooms ' + roomNames.join(', ') : roomNames[0] === '' ? 'Room' : 'Room ' + roomNames[0]
								}.`;
								this.toastService.openToast({
									title,
									subtitle: 'Please ask your teacher to create a pass for you.',
									type: 'error',
								});

								this.performingAction = false;
								throw 'this student has been subject of room visibility rules';
							}
							// both teachers and students must see as a room is closed only by an admin
							if (isRoomsClosed) {
								const roomNames = errorResponse.error.visibility_alerts.filter((r) => !r.enable).map((r) => r?.location_title ?? '');
								this.toastService.openToast({
									title: 'Room status',
									subtitle: `Closed: ${roomNames.join(',')}`,
									type: 'error',
								});

								this.performingAction = false;
								throw 'room has been closed';
							}
						}),
						concatMap(({ error }) => getOverrideFromDialog(error)),
						concatMap(({ override, students }: { override: boolean; students: number[] }) => {
							if (override === undefined) {
								this.dialogRef.close();
								throw 'confirmation closed';
							}
							if (override === true) {
								body['override_visibility'] = true;
								return of(null);
							}

							if (override === false) {
								remove(body['students'] as number[], (elem) => students.includes(elem));
								// removal left us with no students
								// this is as canceling the process
								if (body['students'].length === 0) {
									this.performingAction = false;
									//this.dialogRef.close();
									throw 'no students after skiping';
								}
								return of(null);
							}
						})
					);
				}),
				retryWhen((errors: Observable<HttpErrorResponse>) =>
					this.hallPassService
						.handlePassLimitError({
							errors,
							studentIds: body['students'],
							studentName: this.selectedStudents[0].display_name,
							frequency: this.passLimit?.frequency,
							confirmDialog: this.confirmDialog,
						})
						.pipe(
							filter(({ override }) => {
								this.performingAction = override !== undefined;
								return override !== undefined;
							}),
							concatMap(({ override, students }: { override: boolean; students: number[] }) => {
								if (override === true) {
									body['override'] = true;
									return of(null);
								}

								if (override === false) {
									remove(body['students'] as number[], (elem) => students.includes(elem));
									// server side "no students" case is seen as bad request
									if (body['students'].length === 0) {
										this.dialogRef.close();
										throw new Error('No students to create passes for');
									}
									return of(null);
								}
							})
						)
				),
				catchError((error: HttpErrorResponse) => {
					if (error.error.detail === 'could not create pass' && this.pass.student.status === 'suspended') {
						this.toastService.openToast({
							title: 'Your account is suspended. Please contact your school admin',
							type: 'error',
						});
						return throwError(error);
					}

					if (error.status === 403 && !this.forStaff && this.pass.destination.max_passes_to_active && this.pass.destination.max_passes_to === 0) {
						this.toastService.openToast({
							title: "Can't Start Pass to This Room",
							subtitle: 'Your admin has the max capacity of this room set to zero. Please contact them if you think this is a mistake.',
							type: 'error',
						});
						return throwError(error);
					}

					this.toastService.openToast({
						title: 'Something went wrong!',
						subtitle: 'Could not create pass, contact support for more info',
						type: 'error',
					});
					return throwError(error);
				})
			)
			.subscribe({
				next: (createdPasses: HallPass[] | HallPass) => {
					this.performingAction = false;
					this.hallPassService.createPassEvent$.next(true);

					const parsedCreated = (Array.isArray(createdPasses) ? createdPasses : [createdPasses])
						.map((p) => {
							try {
								return HallPass.fromJSON(p);
							} catch {
								console.warn('could not parse created pass');
								return undefined;
							}
						})
						.filter(Boolean);

					if (parsedCreated.length > 0 && this.forStaff) {
						const origin = parsedCreated[0].origin;
						const destination = parsedCreated[0].destination;
						if (
							!this.router.url.includes('student') &&
							this.user.roles.includes('access_hall_monitor') &&
							origin &&
							destination &&
							(!this.selectedLocation || (origin?.id !== this.selectedLocation?.id && destination?.id !== this.selectedLocation?.id))
						) {
							this.router.navigate(['main/hallmonitor']);
						}
					}
					// navigate student to calendar if student created scheduled pass
					if (parsedCreated?.length > 0 && !this.forStaff && this.forFuture && !this.forKioskMode) {
						const date = this.datePipe.transform(parsedCreated[0].start_time, 'yyyy-MM-dd');
						const startTime = `${parsedCreated[0].start_time.getHours()}:${parsedCreated[0].start_time.getMinutes()}`;
						const url = this.router
							.createUrlTree(['main', 'passes-calendar'], {
								queryParams: { date: date, start_time: startTime },
							})
							.toString();
						this.router.navigateByUrl(url);
					}

					this.dialogRef.close(parsedCreated);
				},
				error: () => {
					this.performingAction = false;
					this.dialogRef.close();
				},
			});
	}

	cancelEdit(evt: MouseEvent) {
		if (!this.cancelOpen) {
			const target = new ElementRef(evt.currentTarget);
			this.options = [];
			this.header = '';

			if ((this.isActive && this.forStaff) || this.forMonitor) {
				if (this.user.isTeacher() && !this.data['hideReport']) {
					this.options.push(this.genOption('Report Student', '#E32C66', 'report'));
				}
				this.options.push(this.genOption('End Pass', '#7083a0', 'end'));

				this.header = '';
			} else {
				if (this.forInput) {
					this.formState.step = 3;
					this.formState.previousStep = 4;
					this.formService.setFrameMotionDirection('disable');
					if (this.formState.data) {
						this.formState.data.roomStudents = null;
					}
					this.cardEvent.emit(this.formState);
					return false;
				} else if (this.forFuture) {
					this.options.push(this.genOption('Delete Pass', '#7083a0', 'delete'));
				}
			}

			UNANIMATED_CONTAINER.next(true);
			this.cancelOpen = true;
			const cancelDialog = this.dialog.open(ConsentMenuComponent, {
				panelClass: 'consent-dialog-container',
				backdropClass: 'invis-backdrop',
				data: { header: this.header, options: this.options, trigger: target, adjustForScroll: true },
			});

			cancelDialog
				.afterClosed()
				.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
				.subscribe((action) => {
					this.chooseAction(action);
				});
		}
	}

	chooseAction(action) {
		this.cancelOpen = false;
		if (action === 'delete') {
			// is an active or overtime recurring pass is being deleted, we use the end pass endpoint with a query parameter
			if (this.pass.schedule_config_id && this.calendarService.getActiveHallPassById(this.pass.id)) {
				this.endPass(true);
			} else {
				const body = {};
				this.hallPassService.cancelPass(this.pass.id, body).subscribe(() => {
					this.dialogRef.close();
				});
			}
		} else if (action === 'report') {
			this.dialogRef.close({ report: this.pass.student });
		} else if (action === 'end') {
			this.endPass(false);
		}
	}

	endPass(isDeletingRecurringPass = false) {
		if (this.pass.needs_check_in && this.user.isStudent() && !this.showTeacherPinEntry && !this.activeRoomCodePin) {
			this.showTeacherPinEntry = true;
			this.activeRoomCodePin = true;
			return;
		}
		this.hallPassService.endPassRequest(this.pass.id, isDeletingRecurringPass);
		this.screenService.clearCustomBackdrop();
		this.dialogRef.close();
	}

	private genOption(display: string, color: string, action: string, icon?: string): PassOption {
		return { display, color, action, icon };
	}

	private getOriginRoomIcon(): void {
		// make sure pinnables are loaded in the store before getting room icon
		this.hallPassService.loadedPinnables$
			.pipe(
				take(1),
				switchMap((loaded) => {
					return iif(() => loaded, of(null), this.hallPassesService.getPinnablesRequest());
				}),
				switchMap(() => {
					return this.hallPassesService.getPinnable(this.pass.origin);
				}),
				filter((pin) => !!pin),
				take(1)
			)
			.subscribe((pin) => {
				this.originRoomIcon = pin.icon;
			});
	}

	private setPassUI() {
		this.getCssClasses();
		this.setBackgroundColor();
		this.setBackDropColor();
		this.setSecondaryColor();
		this.setBorderClass();
	}

	private setIconPosition(): void {
		if ((!this.forStaff && !this.inFormContainer && !this.forKioskMode && this.passStatus === 'active') || this.passStatus === 'overtime') {
			const windowWidth = window.innerWidth;
			this.closeIconTopPos = `-${this.modalContainer.nativeElement.getClientRects()[0].top - 40}px`;
			this.closeIconRightPos = `${this.modalContainer.nativeElement.getClientRects()[0].right - 40 - windowWidth}px`;
		}
	}

	private getCssClasses(): void {
		this.passCssClasses = '';
		if (this.forStaff) {
			this.passCssClasses = this.passCssClasses + ' for-staff';
		} else {
			this.passCssClasses = this.passCssClasses + ' for-student';
		}
		if (this.forKioskModeFormContainer) {
			this.passCssClasses = this.passCssClasses + ' kiosk-modal';
		}
		if (this.inFormContainer) {
			this.passCssClasses = this.passCssClasses + ' in-form-container';
		}
		this.passCssClasses = this.passCssClasses + ' ' + this.passStatus;
	}

	private setSecondaryColor(): void {
		if (this.pass) {
			switch (this.passStatus) {
				case 'overtime':
					this.secondaryColor = '##E0507E';
					break;
				case 'active':
					this.secondaryColor = this.pass.color_profile.solid_color;
					break;
				case 'ended':
				case 'upcoming':
					this.secondaryColor = '#D4D9E2';
					break;
			}
		}
	}

	private setBackgroundColor(): void {
		let gradientArray = [];
		// if (this.formState?.data?.gradient) {
		//   gradientArray = this.formState.data.gradient.split(",");
		//   this.backgroundGradient = "radial-gradient(circle at 73% 71%, " + gradientArray[0] + ", " + gradientArray[1] + ")";
		// }
		if (this.pass) {
			gradientArray = this.pass.color_profile.gradient_color.split(',');
			switch (this.passStatus) {
				case 'overtime':
					this.backgroundGradient = 'linear-gradient(139.13deg, #E32C66 1.57%, #CB1B53 100%)';
					break;
				case 'active':
				case 'upcoming':
					this.backgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';
					break;
				case 'ended':
					this.backgroundGradient = '';
					this.footerBackgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';
					this.endedBgColorClass = `tw-bg-${this.pass.color_profile.title.toLowerCase().replace(' ', '-')}-150`;
					break;
			}
			if ((this.passStatus === 'upcoming' && this.forFuture && this.forInput) || this.inFormContainer || this.forKioskModeFormContainer) {
				this.backgroundGradient = '#FFFFFF';
				this.footerBackgroundGradient = 'radial-gradient(144.10% 144.10% at 72.94% 71.48%, ' + gradientArray[0] + ', ' + gradientArray[1] + ')';
			}
		}
	}

	private setBackDropColor(): void {
		if (this.pass && this.passStatus === 'overtime' && !this.forStaff) {
			this.screenService.customBackdropStyle$.next({
				background: 'linear-gradient(139.13deg, #E32C66 1.57%, #CB1B53 100%)',
			});
			this.screenService.customBackdropEvent$.next(true);
		}
	}

	private setBorderClass(): void {
		if (this.passStatus === 'upcoming') {
			this.borderClasses = 'upcoming';
		} else {
			this.borderClasses = 'pass-border';
		}
	}

	selectTeacher(): void {
		this.activeRoomCodePin = false;
		this.activeTeacherPin = false;
		this.activeTeacherSelection = true;
	}

	requestTarget(teacher): void {
		this.enableTeacherPin();
		this.selectedTeacher = teacher;
	}

	private enableTeacherPin(): void {
		this.activeRoomCodePin = false;
		this.activeTeacherPin = true;
		this.activeTeacherSelection = false;
	}
}
