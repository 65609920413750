<div
	#but
	class="button-wrapper"
	[style.background-color]="hovered ? hoverBackgroundColor : backgroundColor"
	[style.color]="textColor"
	[style.width]="width"
	[style.height]="height"
	[style.font-size]="fontSize"
	[style.font-weight]="fontWeight"
	[ngClass]="{ disabled: disabled, ios: isIOSTablet }"
	[@pressState]="buttonState"
	(mouseenter)="hovered = disabled ? false : true"
	(mouseleave)="buttonDown = false; hovered = false"
	appCrossPointerEventTarget
	(pointerClickEvent)="buttonDown = false; buttonClick()"
	(pointerDownEvent)="buttonDown = true"
	(pointerCancelEvent)="buttonDown = false"
	[customToolTip]="hoverText">
	<div nuxTooltip [contentTemplate]="nuxTemplate" [showToolTip$]="showPassLimitNux" [position]="nuxWrapperPosition" class="pass-limit-nux-wrapper">
		<ng-container *ngIf="title === 'Now' && passLimitInfo.showPasses" [ngSwitch]="passLimitInfo.current">
			<div *ngSwitchCase="'0'" class="pass-limit-counter pass-limit-locked" customToolTip [contentTemplate]="passLimitToolTip">
				<img [src]="'assets/Pass Limit Lock (White).svg' | resolveAsset" style="width: 85%" />
			</div>

			<div *ngSwitchCase="'1'" class="pass-limit-counter pass-limit-one-remaining">
				<span customToolTip [contentTemplate]="passLimitToolTip">{{ passLimitInfo.current }}</span>
			</div>

			<div *ngSwitchDefault class="pass-limit-counter">
				<span customToolTip [contentTemplate]="passLimitToolTip">{{ passLimitInfo.current }}</span>
			</div>

			<ng-template #passLimitToolTip>
				<div class="ds-tooltip" style="width: 200px">
					<span
						>{{ passLimitInfo.current }}/{{ passLimitInfo.max }} passes remaining
						{{ passLimitInfo.frequency === 'day' ? 'today' : 'this ' + passLimitInfo.frequency }}.</span
					>
					<span *ngIf="passLimitInfo.current === 0"> You must request a pass from a teacher.</span>
				</div>
			</ng-template>
		</ng-container>
	</div>

	<div *ngIf="!icon" [style.font-size]="fontSize" class="title">{{ title }}</div>

	<div *ngIf="icon" [style.font-size]="fontSize" class="title icon-title">
		<span *ngIf="icon" class="button-icon" [style.margin-right]="title ? iconMarginRight : '0px'">
			<img src="assets/{{ icon }}" alt="icon" [style.width]="fontSize" [style.height]="fontSize" /> </span
		>{{ title }}
	</div>

	<ng-template #nuxTemplate>
		<app-nux-universal [showBadge]="false" [description]="passLimitNuxDesc" (gotItEvent)="dismissNux()"></app-nux-universal>
	</ng-template>
</div>
