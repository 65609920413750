<div style="position: relative">
	<div class="rest-mes-wrapper">
		<div [ngClass]="headerTransition">
			<div class="header-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction" [style.background-image]="headerGradient"></div>
			<div class="back-button-wrapper" (click)="back()">
				<img
					[ngClass]="isIOSTablet ? 'ios-back-btn-top' : 'back-btn-top'"
					class="back-button back-button-white"
					[src]="'./assets/Back Button (Light-Gray).svg' | resolveAsset" />
			</div>
			<div class="rest-mes-header-text" [attr.data-motion-translate]="(frameMotion$ | async).direction">
				<img class="search-icon" [src]="'./assets/Message (White).svg' | resolveAsset" />
				<div class="text ds-dialog-title-white">Message</div>
			</div>
		</div>

		<div class="content-background" [attr.data-motion-opacity]="(frameMotion$ | async).direction"></div>
		<div class="content-wrapper">
			<div class="rest-mes-content" [attr.data-motion-translate]="(frameMotion$ | async).direction">
				<div class="selected-teacher-header" *ngIf="(formState.forInput || formState.missedRequest) && (!isStaff || formState.kioskMode)">
					<div class="ds-dialog-subtitle teachers">
						Sending Pass Request To:&nbsp;
						<div class="teacher" *ngFor="let teacher of filteredTeachers; let i = index">
							<span class="" style="color: #7f879d; font-size: 16px; font-weight: normal; margin-right: 3px" notranslate>
								{{ teacher?.display_name + (filteredTeachers.length > 1 && i !== filteredTeachers.length - 1 ? ', ' : '') + ' ' }}
							</span>
						</div>
					</div>
				</div>
				<div style="width: 100%; display: flex; justify-content: center">
					<textarea
						#messageBox
						class="message-box"
						[maxlength]="160"
						cdkTextareaAutosize
						#autosize="cdkTextareaAutosize"
						cdkAutosizeMinRows="6"
						cdkAutosizeMaxRows="11"
						[placeholder]="'Attach a message'"
						[formControl]="message"
						[style.width]="messageBoxViewRestriction.textAreaWidth"
						[style.height]="'88px'"
						[style.min-height]="'88px'"
						[style.max-height]="date ? '190px' : '207px'"
						autofocus>
					</textarea>
				</div>
				<div style="margin-right: 25px" *ngIf="formState.forInput || formState.missedRequest">
					<app-gradient-button
						[gradient]="gradient"
						[cursor]="'pointer'"
						[minWidth]="messageBoxViewRestriction.btnWidth"
						[minHeight]="messageBoxViewRestriction.btnHeight"
						[fontSize]="messageBoxViewRestriction.fontSize"
						(buttonClick)="sendRequest()"
						class="message-button">
						{{ message.value ? 'Next' : 'Skip' }}
					</app-gradient-button>
				</div>
			</div>
		</div>
		<app-gradient-button
			*ngIf="!formState.forInput && !formState.missedRequest"
			[style.margin-bottom]="'30px'"
			[gradient]="'#DA2370, #FB434A'"
			[buttonDownColor]="'#DA2370'"
			[cursor]="'pointer'"
			[minWidth]="'181px'"
			[minHeight]="'50px'"
			[fontSize]="'14px'"
			(buttonClick)="sendRequest()"
			class="message-deny-button"
			>Deny Pass Request</app-gradient-button
		>
		<app-student-footer
			*ngIf="formState.forInput || formState.missedRequest"
			[formState]="formState"
			[date]="date"
			(changeAnimationDirectionEvent)="onChangeAnimationDirection($event)"></app-student-footer>
	</div>

	<img style="position: absolute" class="" [src]="'./assets/Back Button (Light-Gray).svg' | resolveAsset" />
</div>
