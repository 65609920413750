<div class="back-to-school-container">
	<div class="header">
		<h2 class="welcome">Welcome Back, {{ firstName }}</h2>
		<h1 class="what-is-new">🥳 What's New in SmartPass</h1>
	</div>

	<div class="content">
		<div class="left-container">
			<div class="feature-section">
				<img class="feature-image" src="../../../assets/School (Gray).svg" />
				<div class="feature-text" [style.width]="'308px'">
					<h3>Refreshed Home Screen</h3>
					<p>Passes to and from your room(s) now show on your home screen. No more switching tabs!</p>
				</div>
			</div>
			<div class="feature-section">
				<img class="feature-image" src="../../../assets/Walking Two (Gray).svg" />
				<div class="feature-text" [style.width]="'341px'">
					<h3>Overtime Passes Turn Red</h3>
					<p>Passes that are overtime show bright red, so you know when a student has been out for too long.</p>
				</div>
			</div>
			<div class="feature-section">
				<img class="feature-image" src="../../../assets/Volume (Gray).svg" />
				<div class="feature-text" [style.width]="'323px'">
					<h3>Notification Sounds</h3>
					<p>Get audible alerts for certain notifications, so you never miss a thing.</p>
				</div>
			</div>
			<div class="feature-section">
				<img class="feature-image" src="../../../assets/Calendar (Gray).svg" />
				<div class="feature-text" [style.width]="'341px'">
					<h3>Calendar View</h3>
					<p>See your scheduled passes on the brand new calendar tab.</p>
				</div>
			</div>
			<div class="feature-section">
				<img class="feature-image" src="../../../assets/Plus (Gray).svg" />
				<div class="feature-text" [style.width]="'301px'">
					<h3>Creating a Pass? Check the Top Right</h3>
					<p>We moved creating passes to the top right, so it's accessible from wherever you are.</p>
				</div>
			</div>
		</div>

		<img class="gif" src="https://cdn-std.droplr.net/files/acc_1148136/l6CJDU" />
	</div>

	<div class="footer">
		<div class="need-help">
			<h3>Need help?</h3>
			<div [style.display]="'flex'">
				<h4>Click the</h4>
				<h4 class="question-mark">?</h4>
				<h4>in the bottom right corner.</h4>
			</div>
		</div>
		<app-gradient-button
			class="got-it-button"
			[size]="'editable'"
			[width]="'117px'"
			[minHeight]="'51px'"
			[fontSize]="'16px'"
			[cursor]="'pointer'"
			(buttonClick)="onClose()">
			Got it!
		</app-gradient-button>
	</div>
</div>
