import { Component, Input } from '@angular/core';

@Component({
	selector: 'sp-beta-pill',
	template: ` <div [ngClass]="{ 'beta-pill_selected': isActive, 'beta-pill': true }">Beta</div> `,
	styles: [
		`
			.beta-pill {
				border-radius: 6px;
				border: 1px solid var(--gray-500, #7083a0);

				display: flex;
				padding: 4px 6px;
				justify-content: center;
				align-items: center;
				gap: 2px;

				text-align: center;
			}

			.beta-pill_selected {
				border: 1px solid var(--green-500, #00b476);
			}
		`,
	],
})
export class BetaPillComponent {
	@Input() isActive = false;
}
