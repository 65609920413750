export enum PassFilterModel {
	PastPasses = 'past-passes',
	ReceivedPassRequests = 'received-pass-requests',
	SentPassRequests = 'sent-pass-requests',
	PassesToRoom = 'passes-to-room',
	PassesFromRoom = 'passes-from-room',
}

export interface PassFilters {
	default: PassActionEnum;
	filters: PassActionEnum[];
	model: string;
	model_filter: string;
}
export enum PassCollectionEnum {
	ENDED = 'expired-passes',
	REQUESTED = 'received-pass-requests',
	SENT = 'sent-pass-requests',
}

export enum PassActionEnum {
	PAST_HOUR = 'past-hour',
	TODAY = 'today',
	PAST_THREE_DAYS = 'past-three-days',
	PAST_SEVEN_DAYS = 'past-seven-days',
	ALL_TIME = 'all_time',
	SCHOOL_YEAR = 'school-year',
	HIDE_EXPIRED_PASS = 'hide_expired_pass',
	LOCATION = 'location',
	EXPIRATION_TIME = 'expiration_time',
}
export type clickAction = 'kiosk' | 'room_code' | 'show_ended_passes';
