import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { AngularReactBrowserModule, registerElement } from '../../../angular-react';
import { FlexStats, FlexStatsComponent } from './components';

const components = [FlexStatsComponent];

@NgModule({
	imports: [AngularReactBrowserModule],
	declarations: components,
	exports: components,
	schemas: [NO_ERRORS_SCHEMA],
})
export class ReactComponentsModule {
	constructor() {
		registerElement('FlexStats', () => FlexStats);
	}
}
