<div
	class="tw-flex tw-items-center tw-gap-x-1 tw-cursor-pointer tw-font-medium tw-text-sm tw-py-2 tw-px-3 tw-rounded-full tw-border tw-border-solid"
	[ngClass]="{
		'tw-bg-green-500 tw-border-green-500 tw-text-white hover:tw-bg-green-600 hover:tw-border-green-600': isSelected,
		'tw-bg-white tw-border-gray-200 tw-text-gray-500 hover:tw-bg-gray-100': !isSelected
	}"
	customToolTip
	[contentTemplate]="tooltip"
	(click)="handleClick()">
	{{ text }}
	<img height="15" width="15" [src]="isSelected ? './assets/Close (White).svg' : './assets/Plus (Gray-500).svg'" />
</div>
<ng-template #tooltip>
	<div class="ds-tooltip">
		<div class="tw-mb-1">{{ hoverText }}</div>
		<span class="tw-opacity-50">Click to {{ isSelected ? 'remove' : 'add' }}</span>
	</div>
</ng-template>
