<div class="tw-bg-white tw-flex tw-flex-col tw-rounded-xl" style="width: 33.75rem">
	<div class="tw-text-navy-500 tw-p-7 tw-pb-0 tw-flex tw-gap-x-4 tw-mb-4 tw-font-bold tw-text-xl tw-items-center tw">
		<img class="tw-animate-fadein-300 tw-w-10" [src]="'./assets/Encounter Prevention Stop Sign.svg' | resolveAsset" alt="Encounter Prevention" />
		<div class="tw-mr-auto">Override Encounter Prevention</div>
		<div class="tw-mb-auto">
			<img
				(click)="!loading && close()"
				class="tw-w-3.25 tw-h-3.25 tw-cursor-pointer"
				[ngClass]="{ 'tw-cursor-default': loading }"
				[src]="'./assets/Close.svg' | resolveAsset"
				alt="Close" />
		</div>
	</div>
	<div
		#overrideContainer
		class="tw-overflow-y-scroll tw-px-7 tw-track-w-0 tw-flex tw-flex-col tw-gap-y-6 tw-max-h-96 tw-border-solid tw-border-gray-200 tw-border-0"
		[ngClass]="{ 'tw-border-t': showTopBorder, 'tw-border-b': showBottomBorder }"
		(scroll)="setBorderVisibility()">
		<div class="tw-mh-10">
			<span class="tw-font-medium tw-text-base tw-text-gray-500 tw-mt-2" *ngIf="!loading">
				{{ descText }}
				<span
					class="tw-underline tw-cursor-pointer tw-inline-block"
					customToolTip
					[contentTemplate]="tooltip"
					[toolTipHideDelay]="400"
					[additionalOffsetY]="-10"
					[position]="'bottom'"
					>View Group Details</span
				>
				<ng-template #tooltip>
					<app-encounter-prevention-tooltip
						[user]="user"
						[groups]="blockedStudentGroups"
						[conflictStudentIds]="data.conflictStudentIds"
						[conflictPasses]="blockingPassesPasses"></app-encounter-prevention-tooltip>
				</ng-template>
			</span>
		</div>
		<div
			#passesContainer
			[@heightAnimation]="heightAnimationParams"
			class="tw-h-full tw-flex-wrap tw-p-3 tw-mb-px tw-rounded-lg tw-border tw-border-solid tw-border-gray-300 tw-flex tw-gap-3.5 tw-text-sm tw-text-white tw-w-115">
			<ng-container *ngIf="!loading; else loadingTemplate">
				<div
					*ngFor="let student of blockedStudents"
					class="tw-animate-fadein-200 tw-shadow-1 tw-w-36 tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-font-bold"
					[style]="'height: 190px; background: ' + blockedGradient">
					<img
						class="tw-mt-2.5 tw-mb-1.5 tw-w-22 tw-h-22 tw-rounded-full"
						[src]="student.profile_picture | resolveAsset"
						[alt]="student.display_name" />
					<div class="tw-text-base tw-mb-1">{{ student.display_name }}</div>
					<div class="tw-font-normal tw-mb-1.5">{{ dest.title }}</div>
					<div class="tw-bg-red-500 tw-w-full tw-h-10 tw-flex tw-items-center tw-rounded-b-xl">
						<div class="tw-mx-auto">Prevented</div>
					</div>
				</div>
				<ng-container *ngFor="let bp of blockingPassesPasses | filterEndedPasses : currentTime">
					<app-pass-tile
						class="tw-animate-fadein-200"
						[fromPast]="true"
						[forStaff]="true"
						[pass]="bp"
						[allowPopup]="false"
						[disableFooterHover]="true"
						[disableReportButton]="true"
						[clickable]="false"
						[timerEvent]="heartbeat"></app-pass-tile>
				</ng-container>
			</ng-container>

			<ng-template #loadingTemplate>
				<div class="loading-theme_blue-gray tw-flex tw-items-center tw-m-auto" style="height: 192px">
					<mat-spinner [diameter]="50" [color]="'primary'"></mat-spinner>
				</div>
			</ng-template>
		</div>
	</div>
	<div class="tw-flex tw-gap-x-2 tw-items-end tw-mt-6 tw-px-7 tw-pb-6">
		<div
			(click)="!loading && cancel()"
			class="tw-ml-auto tw-rounded-xl tw-cursor-pointer tw-p-3 tw-font-medium tw-text-center tw-text-sm tw-bg-gray-100 tw-text-gray-500"
			[ngClass]="{ 'tw-cursor-default tw-opacity-50': loading }">
			Cancel
		</div>
		<div
			(click)="!loading && overridePass()"
			class="tw-rounded-xl tw-cursor-pointer tw-p-3 tw-font-medium tw-text-center tw-text-sm tw-bg-red-500 tw-text-white"
			[ngClass]="{ 'tw-cursor-default tw-opacity-50': loading }">
			{{ blockedStudents.length === 1 && blockingPasses.length === 0 && !loading ? 'Create' : 'Override' }}
		</div>
	</div>
</div>

<ng-template #confirmDialogBody let-totalStudents="totalStudents" let-limitReachedStudents="limitReachedStudents">
	<div *ngIf="totalStudents > 1" class="confirm-body-text">
		<p *ngFor="let s of limitReachedStudents">
			{{ s.displayName }}: {{ s.passLimit }}/{{ s.passLimit }} passes
			{{ s.passLimit?.frequency === 'day' ? 'today' : 'this ' + s.passLimit?.frequency }}
		</p>
	</div>

	<div *ngIf="totalStudents === 1" class="confirm-body-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>
