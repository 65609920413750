<div class="wrapper">
	<form class="time" [formGroup]="timeForm">
		<div class="hours" (mouseenter)="hourHovered = true" (mouseleave)="hourHovered = false">
			<img
				class="arrow"
				[style.opacity]="hourHovered ? 1 : 0"
				(mousedown)="changeTime('hours', 'up')"
				(mouseup)="destroyInterval('hours', 'up')"
				(mouseleave)="destroyInterval('hours', 'up')"
				(click)="clickChangeTime('hours', 'up')"
				[src]="'./assets/Chevron Up (Blue-Gray).svg' | resolveAsset"
				alt="Icon" />
			<input [tabindex]="-1" class="timeInput" type="text" maxlength="2" formControlName="hour" (blur)="updateHour()" />
			<img
				class="arrow"
				[style.opacity]="hourHovered && !isDisabledSwitchHourButton ? 1 : 0"
				(mousedown)="!isDisabledSwitchHourButton ? changeTime('hours', 'down') : false"
				(mouseup)="destroyInterval('hours', 'down')"
				(mouseleave)="destroyInterval('hours', 'down')"
				(click)="!isDisabledSwitchHourButton ? clickChangeTime('hours', 'down') : false"
				[style.cursor]="!isDisabledSwitchHourButton ? 'pointer' : 'default'"
				[src]="'./assets/Chevron Down (Blue-Gray).svg' | resolveAsset"
				alt="Icon" />
		</div>
		<div class="separator">:</div>
		<div class="minutes" (mouseenter)="minHovered = true" (mouseleave)="minHovered = false">
			<img
				class="arrow"
				[style.opacity]="minHovered ? 1 : 0"
				(mousedown)="changeTime('minutes', 'up')"
				(mouseup)="destroyInterval('minutes', 'up')"
				(mouseleave)="destroyInterval('minutes', 'up')"
				(click)="clickChangeTime('minutes', 'up')"
				[src]="'./assets/Chevron Up (Blue-Gray).svg' | resolveAsset"
				alt="Icon" />
			<input [tabindex]="timeForm.touched ? 1 : -1" class="timeInput" maxlength="2" type="text" formControlName="minute" (blur)="updateMinute()" />
			<img
				class="arrow"
				[style.opacity]="minHovered && !isDisabledSwitchMinButton ? 1 : 0"
				(mousedown)="!isDisabledSwitchMinButton ? changeTime('minutes', 'down') : false"
				(mouseup)="destroyInterval('minutes', 'down')"
				(mouseleave)="destroyInterval('minutes', 'down')"
				(click)="!isDisabledSwitchMinButton ? clickChangeTime('minutes', 'down') : false"
				[style.cursor]="!isDisabledSwitchMinButton ? 'pointer' : 'default'"
				[src]="'./assets/Chevron Down (Blue-Gray).svg' | resolveAsset"
				alt="Icon" />
		</div>
	</form>
	<div
		notranslate
		class="format"
		draggable="false"
		[style.cursor]="!isDisabledSwitchHourButton && !isDisabledSwitchFormat ? 'pointer' : 'default'"
		(click)="changeFormat()">
		{{ _currentDate.format('A') }}
	</div>
</div>
