import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
	selector: 'sp-sidenav-route-tab',
	template: `
		<sp-sidenav-tab
			[icon]="icon"
			[label]="label"
			[isSparkly]="false"
			[doesExpand]="false"
			[isBeta]="isBeta"
			[isActive]="selectedRoute$ | async | matchesRoute : route"
			[routerLink]="route"></sp-sidenav-tab>
	`,
})
export class SidenavRouteTabComponent {
	@Input() icon = 'Admin';
	@Input() label = '';
	@Input() isBeta = false;
	@Input() route = '';

	constructor(private router: Router) {}

	selectedRoute$ = this.router.events.pipe(
		filter((event): event is NavigationEnd => event instanceof NavigationEnd),
		map((event) => event.urlAfterRedirects),
		startWith(this.router.url),
		distinctUntilChanged(),
		map((url) => url.split('?')[0])
	);
}
