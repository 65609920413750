import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-pass-limit-tooltip',
	templateUrl: './pass-limit-tooltip.component.html',
	styleUrls: ['./pass-limit-tooltip.component.scss'],
})
export class PassLimitTooltipComponent {
	@Input() description: string;
	@Input() isHand = true;
	@Input() adminCanEdit = false;
}
