import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';
import { DialogService } from './app/dialog.service';

if (environment.production) {
	enableProdMode();
}

console.log(`Frontend build type: ${environment.buildType}`);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then((ref) => {
		// Ensure Angular destroys itself on hot reloads.
		// Needed  for managing the lifecycle of Angular when it's used  in React.
		if (window['ngRef']) {
			window['ngRef'].destroy();
		}
		window['ngRef'] = ref;

		// Expose Angular services globally
		window['dialogService'] = ref.injector.get(DialogService);
	})
	.catch((err) => {
		console.log('failed to bootstrap: ' + err);
	});
