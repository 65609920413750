import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { HttpService } from '../services/http-service';
import { UserService } from '../services/user.service';
@Injectable({
	providedIn: 'root',
})
export class IdCardStandaloneGuard implements CanActivate {
	constructor(private http: HttpService, private router: Router, private userService: UserService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return combineLatest([this.http.currentSchool$, this.userService.userJSON$]).pipe(
			filter(([school, user]) => school !== null),
			map(([school, user]) => {
				if (school.hall_pass_access === 'no_access') {
					if (state.url.includes('id-card')) {
						return true;
					}
					return this.router.parseUrl('id-card');
				} else {
					if (state.url.includes('id-card')) {
						return this.router.parseUrl(user.isAdmin() ? '/dashboard' : '/main');
					}

					return true;
				}
			})
		);
	}
}
