<div class="card-container" #modalContainer>
	<div
		class="close-modal-icon"
		appCrossPointerEventTarget
		(pointerClickEvent)="showCloseIcon = false; dialogRef.close()"
		[style.top]="closeIconTopPos"
		[style.right]="closeIconRightPos"
		*ngIf="!forStaff && !inFormContainer && !forKioskMode && (passStatus === 'active' || passStatus === 'overtime')">
		<img [src]="'assets/close-modal-icon.svg' | resolveAsset" alt="close modal" />
	</div>

	<div class="pass-card-container {{ passStatus }} {{ passCssClasses }}" *ngIf="pass">
		<div
			class="pass-card-wrapper {{ borderClasses }} {{ endedBgColorClass }}"
			[ngClass]="{ 'pass-border': passStatus !== 'ended' }"
			[ngStyle]="{ overflow: isOpenedOptions ? 'visible' : 'hidden' }"
			[@scalePassCards]="scaleCardTrigger$ | async"
			#cardWrapper
			[style.background]="backgroundGradient"
			[style.border-radius]="borderRadius"
			ondragstart="return false">
			<sp-pass-modal-header
				*ngIf="originRoomIcon"
				[pass]="pass"
				[passStatus]="passStatus"
				[forStaff]="forStaff"
				[isRecurringFuture]="isRecurringFuture"
				[inFormContainer]="inFormContainer"
				[forKioskModeFormContainer]="forKioskModeFormContainer"
				[isMissed]="false"
				[originRoomIcon]="originRoomIcon"
				[originRoomTitle]="pass.origin.title"
				[recurringConfigTooltip]="recurringConfigTooltip"
				(closeDialogEmit)="dialogRef.close()"
				(cancelEditEmit)="cancelEdit($event)"></sp-pass-modal-header>

			<!-- **************** Creating pass in form flow ******************-->
			<ng-container *ngIf="inFormContainer || forKioskModeFormContainer">
				<div class="center-block">
					<div class="center-block-inner">
						<div class="duration-picker">
							<!-- ************************* TIME LIMIT SELECTION **************************-->
							<app-duration-picker
								[forStaff]="forStaff"
								[plural]="selectedStudents.length > 1"
								(onChange)="selectedDuration = $event"
								(onSelectTravelType)="selectedTravelType = $event"
								[inFormContainer]="inFormContainer"
								[choices]="pass.destination.travel_types"
								[maxDuration]="pass.destination.max_allowed_time ? pass.destination.max_allowed_time : 15"></app-duration-picker>
						</div>
					</div>
				</div>
				<div class="bottom-block">
					<div class="button-wrapper">
						<div class="button single-button">
							<app-card-button
								(onClick)="newPass()"
								[disabled]="performingAction"
								[backgroundGradient]="footerBackgroundGradient"
								[useOverlay]="false"
								[inFormContainer]="inFormContainer"
								[height]="inFormContainer ? '75px' : '144px'">
								<div class="button-content">
									<mat-spinner *ngIf="performingAction" [color]="'accent'" [diameter]="35"></mat-spinner>
									<img
										*ngIf="
											!performingAction &&
											(formState?.data?.destLimitReached || (waitInLineEnabled && formState?.data?.origLimitReached)) &&
											formState?.data?.selectedStudents.length === 1 &&
											waitInLineEnabled
										"
										class="ds-mx-15"
										width="36"
										[src]="'./assets/Clock (White).svg' | resolveAsset"
										alt="Clock face" />
									<div *ngIf="!performingAction" class="button-content-title">
										{{ buttonText }}
									</div>
									<img *ngIf="!performingAction" class="button-icon-right" [src]="'./assets/Chevron Right (White).svg' | resolveAsset" />
								</div>
							</app-card-button>
						</div>
					</div>
				</div>
			</ng-container>

			<!-- **************** CENTER BLOCK CONTENT ******************-->
			<ng-container *ngIf="isModal && !inFormContainer">
				<div class="center-block">
					<div class="center-block-inner">
						<!-- ************************* ACTIVE PASS TIMER **************************-->
						<!-- ************************* OVERTIME PASS TIMER  **************************-->
						<ng-container *ngIf="passStatus === 'active' || passStatus === 'overtime'">
							<ng-container *ngIf="!isMobile; else mobile">
								<div class="timer-wrapper">
									<sp-circle-countdown
										[topText]="passStatus === 'overtime' ? 'Overtime' : null"
										[progress]="timeLeftForProgressCircle"
										[ringColor]="passStatus === 'active' ? 'rgba(255, 255, 255, 0.40)' : '#A71644'"
										[diameter]="316">
										<img [src]="'assets/icon-walking.svg' | resolveAsset" alt="Walking person icon" />
										<div>{{ timeLeft }}</div>
									</sp-circle-countdown>
								</div>
							</ng-container>
						</ng-container>
						<!-- ************************* ENDED PASS **************************-->
						<ng-container *ngIf="passStatus === 'ended'">
							<app-pager [arrowPosition]="'-20px'" [inactiveDotColor]="'#FFFFFF'" [activeDotColor]="pass.color_profile.solid_color">
								<div class="page-1 page-size" *ngIf="p1Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
									<app-travel-view
										*ngIf="passStatus === 'ended'"
										[pass]="pass"
										[textColor]="'#7083a0'"
										[iconColor]="pass.color_profile.solid_color"
										[height]="'130px'"></app-travel-view>
								</div>
								<div class="page-2 page-size message-page" *ngIf="p2Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
									<div class="message">
										<div *ngIf="passStatus !== 'pending'">
											<div class="message-label">Message from {{ pass.issuer.display_name }}:</div>
											<div class="message-copy">{{ pass.issuer_message === '' ? 'No message' : pass.issuer_message }}</div>
										</div>
									</div>
								</div>
							</app-pager>
						</ng-container>

						<!-- ************************* PENDING/UPCOMING PASS **************************-->
						<ng-container *ngIf="passStatus === 'upcoming'">
							<app-pager
								[arrowPosition]="'-20px'"
								[inactiveDotColor]="'#FFFFFF40'"
								[activeDotColor]="'#FFF'"
								[leftArrowSrc]="'icon-white-left.svg'"
								[rightArrowSrc]="'icon-white-right.svg'">
								<div class="page-1 page-size" [attr.data-motion-translate]="(frameMotion$ | async).direction">
									<div class="timer-wrapper">
										<sp-circle-countdown [progress]="0" [diameter]="280">
											<div *ngIf="upcomingPassDuration !== ''">{{ upcomingPassDuration }}</div>
										</sp-circle-countdown>
									</div>
								</div>
								<div class="page-2 page-size message-page" *ngIf="p1Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
									<div class="message">
										<div class="message-label">Message from {{ pass.issuer.display_name }}:</div>
										<div class="message-copy">{{ pass.issuer_message === '' ? 'No message' : pass.issuer_message }}</div>
									</div>
								</div>
							</app-pager>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<!-- **************** BOTTOM BLOCK CONTENT ******************-->

			<div
				class="bottom-block"
				*ngIf="!inFormContainer && !forKioskModeFormContainer"
				[ngClass]="{ 'end-pass-pin': pass.needs_check_in }"
				[style.background]="passStatus === 'ended' ? footerBackgroundGradient : ''"
				[style.border-bottom-left-radius]="passStatus === 'ended' ? '20px' : ''"
				[style.border-bottom-right-radius]="passStatus === 'ended' ? '20px' : ''">
				<div *ngIf="passStatus === 'upcoming' && !inFormContainer" class="upcoming-block">
					<div *ngIf="forFuture" class="pass-created">Pass Created</div>
					<div *ngIf="forFuture" class="pass-created-by-me">
						<span *ngIf="createdBy">By {{ createdBy === user?.display_name ? 'Me' : createdBy }} -</span> {{ pass.created | date : 'MMMM d, h:mm a' }}
					</div>
					<div *ngIf="!forFuture">{{ duration }} - Total Time</div>
				</div>

				<!-- ************************* ACTIVE PASSES ACTION AREA **************************-->
				<ng-container *ngIf="(passStatus === 'active' || passStatus === 'overtime') && !showTeacherPinEntry">
					<div class="button-wrapper">
						<div class="button single-button">
							<app-card-button
								[useOverlay]="true"
								[backgroundGradient]="backgroundGradient"
								[disabled]="performingAction"
								[inFormContainer]="inFormContainer"
								[height]="inFormContainer ? '75px' : '144px'"
								(onClick)="endPass(false)">
								<div class="button-content end-pass-content">End Pass</div>
							</app-card-button>
						</div>
					</div>
				</ng-container>

				<!-- ************************* ENDING PASS CONTENT **************************-->
				<!-- ************************* displays if room requires teacher pin to end pass **************************-->
				<ng-container *ngIf="showTeacherPinEntry">
					<div class="inline-pass-card-content" *ngIf="activeRoomCodePin">
						<app-room-code [pass]="pass"></app-room-code>
						<div class="pin-info" (click)="selectTeacher()">End Pass with Teacher Pin</div>
					</div>
					<div class="teacher-selection" *ngIf="activeTeacherSelection">
						<app-teacher-selection
							[pass]="pass"
							(requestTarget)="requestTarget($event)"
							(isOpenedOptions)="isOpenedOptions = $event"></app-teacher-selection>
					</div>
					<div class="teacher-pin" *ngIf="activeTeacherPin">
						<app-teacher-pin-end-pass
							[pass]="pass"
							[selectedTeacher]="selectedTeacher"
							(pinResult)="activeTeacherPin = false"></app-teacher-pin-end-pass>
					</div>
				</ng-container>

				<!-- ************************* ENDED PASSES PAGER AREA **************************-->
				<ng-container *ngIf="passStatus === 'ended'">
					<app-pager
						[pages]="pagerPages"
						[page]="pagerPages"
						[pagerClass]="'pager-top'"
						[activeDotColor]="'#FFF'"
						[inactiveDotColor]="'#FFFFFF50'"
						[leftArrowSrc]="'icon-white-left.svg'"
						[rightArrowSrc]="'icon-white-right.svg'">
						<div class="page-1 teacher-details" *ngIf="p1Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 1 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p1Title }}</div>
							<div class="subtitle">{{ p1Subtitle }} <span *ngIf="p1Subtitle">-</span> {{ p1Stamp }}</div>
						</div>
						<div class="page-2 teacher-details" *ngIf="p2Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 2 ? (activePassTime$ | async) + (timeLeft === '' ? '' : ' - ') : '') + p2Title }}</div>
							<div class="subtitle">{{ p2Subtitle + (p2Subtitle === '' ? '' : ' - ') }} {{ p2Stamp }}</div>
						</div>
						<div class="page-3 teacher-details" *ngIf="p3Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 3 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p3Title }}</div>
							<div class="subtitle">{{ p3Subtitle }} {{ p3Stamp }}</div>
						</div>
						<div class="page-4 teacher-details" *ngIf="p4Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 4 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p4Title }}</div>
							<div class="subtitle">{{ p4Subtitle }} {{ p4Stamp }}</div>
						</div>

						<div class="page-5 teacher-details" *ngIf="p1Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 1 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p1Title }}</div>
							<div class="subtitle">
								{{ p1Subtitle }}
								<div *ngIf="p1Subtitle">-</div>
								{{ p1Stamp }}
							</div>
						</div>
						<div class="page-6 teacher-details" *ngIf="p2Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 2 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p2Title }}</div>
							<div class="subtitle">{{ p2Subtitle + (p2Subtitle === '' ? '' : ' - ') }} {{ p2Stamp }}</div>
						</div>
						<div class="page-7 teacher-details" *ngIf="p3Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 3 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p3Title }}</div>
							<div class="subtitle">{{ p3Subtitle }} {{ p3Stamp }}</div>
						</div>
						<div class="page-8 teacher-details" *ngIf="p4Title" [attr.data-motion-translate]="(frameMotion$ | async).direction">
							<div class="title">{{ (activePage == 4 ? timeLeft + (timeLeft === '' ? '' : ' - ') : '') + p4Title }}</div>
							<div class="subtitle">{{ p4Subtitle }} {{ p4Stamp }}</div>
						</div>
					</app-pager>
				</ng-container>
			</div>
		</div>

		<!-- ************************* FOOTER for active pass displays for student **************************-->
		<div class="metrics" *ngIf="!forStaff && !forKioskMode && passStatus === 'active'">
			<app-student-metrics></app-student-metrics>
		</div>

		<!-- ************************* FOOTER for kiosk mode create pass **************************-->
		<div class="student-footer-info" *ngIf="forKioskModeFormContainer">
			<app-student-passes
				*ngIf="selectedStudents && selectedStudents.length === 1"
				[pass]="pass"
				[profile]="selectedStudents[0]"
				[displayEncounterPreventionInfo]="false"
				[disableActions]="true"></app-student-passes>
		</div>
		<!-- ************************* FOOTER for staff active pass **************************-->
		<div class="student-footer-info" *ngIf="forStaff && isModal && !isStudentSnapshotPage && (showStudentInfoBlock || passStatus === 'overtime')">
			<app-student-passes
				[pass]="pass"
				[profile]="pass.student"
				[forMonitor]="forMonitor"
				[disableActions]="false"
				[displayEncounterPreventionInfo]="true"></app-student-passes>
		</div>
		<!-- ************************* FOOTER for kiosk mode active pass **************************-->
		<div class="student-footer-info" *ngIf="forKioskMode && isModal && showStudentInfoBlock">
			<app-student-passes
				[pass]="pass"
				[profile]="pass.student"
				[forMonitor]="forMonitor"
				[disableActions]="true"
				[displayEncounterPreventionInfo]="false"></app-student-passes>
		</div>
	</div>
</div>
<ng-template #recurringConfigTooltip>
	<div class="ds-tooltip" style="text-align: start">
		<ng-container [ngSwitch]="recurringConfig?.recurrence_type || formState?.data?.date?.schedule_option">
			<span *ngSwitchCase="1">Repeats Daily</span>
			<span *ngSwitchCase="2">Repeats Weekly</span>
		</ng-container>
		<br />
		<span>To edit, please delete this scheduled pass and create a new one.</span>
	</div>
</ng-template>
<ng-template #mobile>
	<app-pager [arrowPosition]="'-87px'" [isStaff]="true" [activeDotColor]="pass.color_profile.solid_color">
		<div class="page-5" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<app-travel-view [forStaff]="forStaff" [height]="'170px'" [pass]="pass" [iconColor]="pass.color_profile.solid_color"></app-travel-view>
		</div>
		<div class="page-6" [attr.data-motion-translate]="(frameMotion$ | async).direction">
			<div style="display: flex; justify-content: center">
				<div class="message">
					<span [style.font-weight]="'bold'">{{ forStaff ? 'Your message:' : 'Message from' }}</span
					><span notranslate [style.font-weight]> {{ pass.issuer.display_name }}</span
					><br />
					{{ pass.issuer_message | json }}
					<span [attr.notranslate]="pass.issuer_message ? true : null" style="user-select: text">{{
						pass.issuer_message ? pass.issuer_message : 'No Message'
					}}</span>
				</div>
			</div>
		</div>
	</app-pager>
</ng-template>

<ng-template #confirmDialogBody let-totalStudents="totalStudents" let-limitReachedStudents="limitReachedStudents">
	<div *ngIf="totalStudents > 1" class="confirm-body-text">
		<p *ngFor="let s of limitReachedStudents">
			{{ s.displayName }}: {{ s.passLimit }}/{{ s.passLimit }} passes {{ passLimit?.frequency === 'day' ? 'today' : 'this ' + passLimit?.frequency }}
		</p>
	</div>

	<div *ngIf="totalStudents === 1" class="confirm-body-text">
		<span>If it's an emergency, you can override the limit.</span>
	</div>
</ng-template>

<ng-template let-alerts="alerts" #confirmDialogBodyVisibility>
	<ng-container *ngFor="let a of alerts">
		<div class="ds-dialog-title">
			<span>{{ a.phrase }}</span>
		</div>

		<div class="ds-dialog-normal-text ds-mb-10">
			<p notranslate>{{ a.students }}</p>
		</div>
	</ng-container>
</ng-template>
