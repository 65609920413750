<div
	class="option"
	#cell
	*ngFor="let option of options; let i = index"
	(click)="selectedOptions(option.id)"
	(mouseenter)="cell.hovered = true"
	(mouseleave)="cell.hovered = false; cell.pressed = false"
	(mousedown)="cell.pressed = true"
	(mouseup)="cell.pressed = false">
	<div class="option-content" [style.background-color]="getBackground(cell, option)" [style.color]="textColor(option)">
		<div class="title">{{ option.title }}</div>
		<div class="icon" *ngIf="selectedId === option.id || option.arrowIcon">
			<img draggable="false" [src]="(selectedId === option.id ? option.selectedIcon : option.arrowIcon) | resolveAsset" alt="option" />
		</div>
	</div>
	<!--  <mat-divider *ngIf="i !== options.length - 1"></mat-divider>-->
</div>
