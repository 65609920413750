import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ENTITLEMENTS, EntitlementsService } from 'app/services/entitlements.service';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
	selector: 'sp-sidenav-entitled-tab',
	template: `
		<ng-container *ngIf="entitlement && entitlementsService.isEntitlementEnabled$(entitlement) | async; else unauthorized">
			<sp-sidenav-route-tab
				[icon]="icon"
				[label]="label"
				[isActive]="selectedRoute$ | async | matchesRoute : route"
				[routerLink]="route"></sp-sidenav-route-tab>
		</ng-container>
		<ng-template #unauthorized>
			<a [href]="learnMore" target="_blank">
				<sp-sidenav-tab [icon]="icon" [label]="label" [isSparkly]="true" [doesExpand]="false" [isActive]="false"></sp-sidenav-tab>
			</a>
		</ng-template>
	`,
	styles: [
		`
			a {
				text-decoration: none;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavEntitledTabComponent {
	@Input() icon = 'Admin';
	@Input() label = '';

	@Input() entitlement?: ENTITLEMENTS;
	@Input() route = '';
	@Input() learnMore = 'https://articles.smartpass.app';

	constructor(private router: Router, public entitlementsService: EntitlementsService) {}

	selectedRoute$ = this.router.events.pipe(
		filter((event): event is NavigationEnd => event instanceof NavigationEnd),
		map((event) => event.urlAfterRedirects),
		startWith(this.router.url),
		distinctUntilChanged(),
		map((url) => url.split('?')[0])
	);
}
