<div *ngIf="pass && passType === 'active'" class="student-pass-info">
	<div class="room-tag" [style.background]="pass.color_profile.solid_color" #mainBody>
		<sp-room-icon
			[style.display]="'flex'"
			[iconPath]="pass.icon"
			[height]="'14px'"
			[width]="'14px'"
			[iconColor]="'#FFF'"
			[altText]="pass.destination.title">
		</sp-room-icon>

		<div class="pass-card-header-text">
			{{ pass.destination.title }}
		</div>
	</div>

	<div class="pass-time-range">{{ pass.start_time | date : 'h:mm a' }} - {{ pass.expiration_time | date : 'h:mm a' }}</div>
</div>

<div
	*ngIf="pass && passType === 'wait_in_line'"
	class="student-pass-info"
	customToolTip
	[additionalOffsetY]="-40"
	[position]="'top'"
	[contentTemplate]="pass.isReadyToStart() ? passAttemptTooltip : null">
	<svg
		*ngIf="pass.is_blocking && pass.line_position === 1"
		[style.width.px]="18"
		[style.height.px]="18"
		[style.color]="pass.color_profile.solid_color"
		viewBox="0 0 13 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.49961 0.100098C2.96521 0.100098 0.0996094 2.9657 0.0996094 6.5001C0.0996094 10.0345 2.96521 12.9001 6.49961 12.9001C10.034 12.9001 12.8996 10.0345 12.8996 6.5001C12.8996 2.9657 10.034 0.100098 6.49961 0.100098ZM7.03294 7.03343H2.76361C2.47028 7.03343 2.23294 6.7961 2.23294 6.50276V6.4969C2.23294 6.2041 2.47028 5.96676 2.76361 5.96676H5.96628V1.69743C5.96628 1.4041 6.20361 1.16676 6.49694 1.16676H6.50281C6.79561 1.16676 7.03294 1.4041 7.03294 1.69743V7.03343Z"
			fill="currentColor" />
	</svg>
	<div
		class="room-tag"
		#mainBody
		[style.background]="pass.color_profile.solid_color"
		[style.padding]="pass.isReadyToStart() ? '8px' : ''"
		[style.border-radius]="pass.isReadyToStart() ? '8px' : ''">
		<!--
      Chip text rules:
      - If the waiting in line pass is a destination pass, then we show the position in the destination line
      and the destination eg. "1st in Line to Nurse" (Nurse would be the destination that's limited)

      - If the waiting in line pass is an origin-only-limited pass, then we show only the destination and no
      line position. eg. "Library" (In this case, a wilp would be coming from some limited origin going to
      Library that's not limited

      - If the waiting in line pass is a doubly-linked pass, then we show the position in the destination line
      and the destination. eg. "2nd in Line to Nurse" (In this case, the doubly-linked pass is 2nd in line at
      the limited-destination Nurse)
    -->

		<ng-container *ngIf="pass">
			<div *ngIf="!pass.is_origin_line || (pass.is_origin_line && !pass.linked_pass)">
				<ng-container *ngIf="pass.isReadyToStart(); else notReady"> Start Pass to </ng-container>
				<ng-template #notReady>
					<span *ngIf="!pass.is_origin_line">{{ pass.line_position | position }} in Line to</span>
					<span *ngIf="pass.is_origin_line && !!pass.linked_pass">{{ pass.linked_pass.line_position | position }}</span>
				</ng-template>
			</div>
		</ng-container>

		<sp-room-icon
			[style.display]="'flex'"
			[iconPath]="pass.icon"
			[height]="'14px'"
			[width]="'14px'"
			[iconColor]="'#FFF'"
			[altText]="pass.destination.title">
		</sp-room-icon>

		<div class="pass-card-header-text">
			{{ pass.destination.title }}
		</div>

		<div *ngIf="pass.isReadyToStart()" class="spinner-timer">
			<sp-timer-spinner [maxSeconds]="30" [startAt]="remainingAttemptSeconds" [showNumber]="false" [size]="15" (pulse)="readyToStartTick($event)">
			</sp-timer-spinner>
		</div>
	</div>
</div>
<ng-template #passAttemptTooltip>
	<div class="ds-tooltip" style="width: 181px">
		<span
			>{{ acceptingPassTimeRemaining }}s left to start your pass or it will be
			{{ pass.missed_start_attempts === 0 ? 'sent back in line' : 'deleted' }}.</span
		>
	</div>
</ng-template>
