import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type OnboardingStatus = 'processing' | 'in review' | 'pending' | 'active';

export type UpdateOnboardingStatusReq = {
	status: OnboardingStatus;
	id: number;
};
export type OnboardingStatusResp = {
	schedule_status: OnboardingStatus;
	ids: number[];
};
@Injectable({
	providedIn: 'root',
})
export class OnboardingScheduleService {
	onBoardingStatus$: BehaviorSubject<OnboardingStatus> = new BehaviorSubject<OnboardingStatus>(null);
	onBoardingStatusId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

	constructor(private http: HttpService) {}

	getOnboardingStatus(): Observable<OnboardingStatus> {
		return this.http.post<OnboardingStatusResp>('v2/schedules/GetScheduleOnboardingStatus', {}, undefined, false).pipe(
			catchError((error) => {
				return of<OnboardingStatusResp>(null);
			}),
			map((resp) => {
				this.onBoardingStatus$.next(resp.schedule_status);
				if (resp.ids?.length > 0) {
					this.onBoardingStatusId$.next(resp.ids[0]);
				}
				return resp.schedule_status;
			})
		);
	}

	updateOnboardingStatus(status: UpdateOnboardingStatusReq): Observable<OnboardingStatus> {
		return this.http.post<OnboardingStatusResp>('v2/schedules/UpdateScheduleOnboardingStatus', status, undefined, false).pipe(
			catchError((error) => {
				return of<OnboardingStatusResp>(null);
			}),
			map((resp) => {
				this.onBoardingStatus$.next(resp.schedule_status);
				this.onBoardingStatusId$.next(resp.ids[0]);
				return resp.schedule_status;
			})
		);
	}
}
