<div
	class="wrapper"
	#wrapper
	[@pressState]="pressed ? 'down' : 'up'"
	appCrossPointerEventTarget
	(pointerDownEvent)="pressed = true"
	(pointerClickEvent)="pressed = false; !disabled ? buttonClick.emit($event) : null"
	(mouseenter)="hover = true"
	(mouseleave)="hover = false; pressed = false">
	<div class="wrapper" #wrapper appCrossPointerEventTarget>
		<div class="profile-icon">
			<img [src]="userProfileImage ? (userProfileImage | resolveAsset) : 'assets/Avatar Default.svg'" />
			<div class="dropdown-arrow"><img src="assets/dropdown-arrow.svg" /></div>
		</div>
	</div>
</div>
