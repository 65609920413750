import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { StudentList } from '../../../models/StudentList';
import { SchoolActivityAttendee, SchoolActivityInstance, SchoolActivityService } from '../../../services/school-activity.service';
import { ROLES, User } from '../../../models/User';
import { SchedulePassActivityService } from '../../../services/schedule-pass-activity.service';
import { UserService } from '../../../services/user.service';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin, of } from 'rxjs';
import { GroupsAndStudentSelectComponent } from '../../../shared/shared-components/groups-and-student-select/groups-and-student-select.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CreateFormService } from '../../../create-hallpass-forms/create-form.service';
import { ScreenService } from '../../../services/screen.service';
import { ConfirmationComponent } from '../../../shared/shared-components/confirmation/confirmation.component';
import { ImportStudentListComponent } from '../../../admin/overlay-container/visibility-room/import-student-list/import-student-list.component';
import { FeatureFlagService, FLAGS } from '../../../services/feature-flag.service';
import { ScheduleService } from '../../../services/schedule.service';
import { HallPassesService } from '../../../services/hall-passes.service';
import { HomepageService } from '../../../services/homepage.service';
import { KioskModeService } from '../../../services/kiosk-mode.service';

type StudentSearchResult = {
	user: User;
	overwriteable: boolean;
	assignerId?: number;
	activityName?: string;
	message?: string;
	alreadyIn?: boolean;
	groupId?: number;
};

@Component({
	selector: 'sp-student-instance-search',
	templateUrl: './student-instance-search.component.html',
	styleUrls: [
		'../../../shared/shared-components/groups-and-student-select/groups-and-student-select.component.scss',
		'../../../create-hallpass-forms/main-hallpass--form/student-groups/groups-container-v2/groups-container-v2.component.scss',
		'./student-instance-search.component.scss',
	],
})
export class StudentInstanceSearchComponent extends GroupsAndStudentSelectComponent implements OnInit {
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;

	closeDialog: (students: User[], attendeeIds: number[]) => void;
	searchText = '';
	allGroups: StudentList[] = [];
	searchResults: StudentSearchResult[] = [];
	selectedStudents: User[] = [];
	overrideableStudents: StudentSearchResult[] = [];
	loading = true;
	isSearchFocused = false;
	overLimit = 0;
	addDisabled = true;
	overrideAllDisabled = false;
	searchInputChanges$ = new Subject<string>();
	usersLoaded = false;
	selectedGroupsAndStudents: (StudentList | User)[] = this.visibilityService.getInitialStudent();
	private activitiesNameMap: { [id: number]: string } = {};
	private staffNameMap: { [id: number]: string } = {};
	private attendeeMap: { [userId: number]: SchoolActivityAttendee } = {};
	private user: User;
	private attendeeIdsToDelete: number[] = [];
	hasSchedulesFF = false;

	constructor(
		public userService: UserService,
		public sanitizer: DomSanitizer,
		public formService: CreateFormService,
		public screenService: ScreenService,
		private featureFlags: FeatureFlagService,
		public dialog: MatDialog,
		private visibilityService: SchedulePassActivityService,
		private activityService: SchoolActivityService,
		private renderer: Renderer2,
		private changeDetector: ChangeDetectorRef,
		public featureFlagService: FeatureFlagService,
		public scheduleService: ScheduleService,
		public pinnableService: HallPassesService,
		public homePageService: HomepageService,
		public kioskService: KioskModeService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			instance: SchoolActivityInstance;
			maxAttendance: number;
		}
	) {
		super(
			userService,
			sanitizer,
			formService,
			screenService,
			dialog,
			featureFlagService,
			scheduleService,
			pinnableService,
			homePageService,
			kioskService
		);
	}

	ngOnInit(): void {
		this.hasSchedulesFF = this.featureFlags.isFeatureEnabledV2(FLAGS.Schedules);
		const from = new Date(this.data.instance.start_time);
		const to = new Date(this.data.instance.end_time);
		from.setMinutes(from.getMinutes() - 1);
		to.setMinutes(to.getMinutes() + 1);

		this.activityService
			.GetAttendeeRecordsForTimePeriod(from, to)
			.pipe(takeUntil(this.destroy$))
			.subscribe((attendees) => attendees.map((attendee) => (this.attendeeMap[attendee.user_id] = attendee)));

		this.activityService
			.GetActivities()
			.pipe(takeUntil(this.destroy$))
			.subscribe((activities) => activities.map((activity) => (this.activitiesNameMap[activity.id] = activity.name)));

		forkJoin([this.userService.getUsersList('_profile_teacher'), this.userService.getUsersList('_profile_admin')])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([tList, aList]) => {
				const staffList: User[] = [...tList, ...aList];
				staffList.map((staff) => (this.staffNameMap[staff.id] = staff.display_name));
			});

		this.userService.effectiveUser$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (effectiveUser) => {
				this.user = effectiveUser;
			},
		});

		this.userService.getStudentGroupsRequest().pipe(takeUntil(this.destroy$));
		this.userService.studentGroups$.pipe(takeUntil(this.destroy$)).subscribe((groups) => {
			this.allGroups = groups;
			this.groups = groups;
		});

		this.frameMotion$ = this.formService.getFrameMotionDirection();

		of(!this.groups || (this.groups && !this.groups.length))
			.pipe(takeUntil(this.destroy$))
			.subscribe((v) => {
				this.isEmptyGroups = v;
			});

		this.searchInputChanges$
			.pipe(
				tap(() => (this.usersLoaded = false)),
				distinctUntilChanged(),
				debounceTime(300),
				takeUntil(this.destroy$),
				tap((search) => {
					this.onSearch(search);
				})
			)
			.subscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.loading = false;
			this.setFocusOnSearch();
			this.changeDetector.detectChanges();
		});
	}

	onSearch(searchText: string): void {
		if (searchText !== '') {
			this.groups = this.allGroups.filter((group) => {
				const titleMatches = group.title.toLowerCase().includes(searchText.toLowerCase());
				const alreadyAdded = this.selectedGroupsAndStudents.find((selectedGroup) => group.id === selectedGroup.id);
				return titleMatches && !alreadyAdded;
			});
			this.userService
				.searchProfile(ROLES.Student, 100, searchText)
				?.pipe(
					map((responses) => responses.results.map((r) => User.fromJSON(r))),
					map((students) => {
						return students.filter(
							(student) =>
								!this.selectedGroupsAndStudents.find((groupStudent) => groupStudent.id === student.id) &&
								!this.overrideableStudents.find((result) => result.user.id === student.id)
						);
					}),
					takeUntil(this.destroy$),
					finalize(() => (this.usersLoaded = true))
				)
				.subscribe(
					(filteredStudents) =>
						(this.searchResults = filteredStudents.map((student) => {
							const attendeeRecord = this.attendeeMap[student.id];
							const result = this.buildMessage(student, attendeeRecord, this.activitiesNameMap[attendeeRecord?.activity_id]);
							return {
								user: student,
								overwriteable: result.overwriteable,
								assignerId: attendeeRecord?.assigner_id,
								activityName: this.activitiesNameMap[attendeeRecord?.activity_id],
								message: result.message,
								alreadyIn: this.attendeeMap[student.id]?.activity_instance_id === this.data.instance.id,
							};
						}))
				);
		} else {
			this.groups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.find((selectedGroup) => selectedGroup.id === group.id));
			this.searchResults = [];
		}
	}

	onSelect(item: StudentList | StudentSearchResult): void {
		// Adding Group
		if (typeof item === 'object' && 'users' in item) {
			this.selectedGroupsAndStudents.push(item);
			this.cleanUpSearch();
		}

		// Adding Student
		if (typeof item === 'object' && !('users' in item)) {
			// Not in another activity
			if (!item?.message && item.overwriteable) {
				this.selectedGroupsAndStudents.push(item.user);
				this.cleanUpSearch();
			}
			// In another activity
			else if (item.overwriteable) {
				const overrideStudent = () => {
					this.selectedGroupsAndStudents.push(item.user);
					this.cleanUpSearch();
				};
				if (item.assignerId !== this.user.id) {
					const data = {
						title: `Override Scheduled Activity?`,
						message: item.message,
						okButtonText: 'Override',
					};
					this.openConfirmationDialog(data).then((res: boolean) => {
						if (res) {
							overrideStudent();
						}
					});
				} else {
					// Teacher assigned them elsewhere
					overrideStudent();
				}
			}
		}
	}

	private cleanUpSearch(): void {
		this.setSelectedStudents();
		this.searchText = '';
		this.groups = this.allGroups.filter((group) => !this.selectedGroupsAndStudents.find((selectedGroup) => selectedGroup.id === group.id));
		this.searchResults = [];
		this.setFocusOnSearch();
	}

	onSearchFocus(isFocused: boolean): void {
		setTimeout(() => (this.isSearchFocused = isFocused), 50);
	}

	private setFocusOnSearch(): void {
		if (this.searchInput?.nativeElement) {
			setTimeout(() => this.renderer.selectRootElement(this.searchInput.nativeElement).focus(), 50);
		}
	}

	private openConfirmationDialog(data: { title: string; message: string; okButtonText: string }): Promise<unknown> {
		return new Promise((resolve) => {
			const dialogRef = this.dialog.open(ConfirmationComponent, {
				panelClass: 'search-pass-card-dialog-container',
				backdropClass: 'custom-bd',
				disableClose: true,
				data: data,
			});

			dialogRef
				.afterClosed()
				.pipe(takeUntil(this.destroy$))
				.subscribe((result) => {
					return resolve(result);
				});
		});
	}

	private setSelectedStudents(): void {
		this.selectedStudents = [];
		this.attendeeIdsToDelete = [];
		// Student
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if (!('users' in groupOrStudent) && !this.isInSelectStudents(groupOrStudent)) {
				this.selectedStudents.push(groupOrStudent);
				const attendee = this.attendeeMap[groupOrStudent.id];
				if (attendee) {
					this.attendeeIdsToDelete.push(attendee.id);
				}
			}
		});
		// Group
		this.selectedGroupsAndStudents.forEach((groupOrStudent) => {
			if (typeof groupOrStudent === 'object' && 'users' in groupOrStudent) {
				groupOrStudent.users.forEach((student) => {
					if (!this.isInSelectStudents(student)) {
						const attendeeRecord = this.attendeeMap[student.id];
						// Add all the students in the group that aren't in another activity
						if (!attendeeRecord) {
							this.selectedStudents.push(student);
						}
						// Add the students in another activity to the override list
						else if (
							attendeeRecord.activity_instance_id !== this.data.instance.id &&
							!this.overrideableStudents.find((result) => result.user.id === student.id)
						) {
							const result = this.buildMessage(student, attendeeRecord, this.activitiesNameMap[attendeeRecord?.activity_id]);
							this.overrideableStudents.push({
								user: student,
								overwriteable: result.overwriteable,
								assignerId: attendeeRecord?.assigner_id,
								activityName: this.activitiesNameMap[attendeeRecord?.activity_id],
								message: result.message,
								groupId: groupOrStudent.id,
							});
						}
					}
				});
			}
		});
		// Order by overrideablity and name
		this.overrideableStudents.sort((a, b) => {
			if (a.overwriteable && !b.overwriteable) {
				return -1;
			} else if (!a.overwriteable && b.overwriteable) {
				return 1;
			}

			return a.user.display_name.localeCompare(b.user.display_name);
		});

		// Validators update
		this.overLimit =
			this.data.maxAttendance > 0 ? this.data.instance.current_num_attendees + this.selectedStudents.length - this.data.maxAttendance : 0;
		this.addDisabled = this.selectedStudents.length === 0 || this.overLimit > 0;
		this.overrideAllDisabled =
			(this.data.maxAttendance > 0 ? this.overrideableStudents.filter((student) => student.overwriteable).length + this.overLimit > 0 : false) ||
			this.overrideableStudents.filter((student) => student.overwriteable).length === 0;
	}

	private isInSelectStudents(user: User): boolean {
		return !!this.selectedStudents.find((student) => student.id === user.id);
	}

	private buildMessage(student: User, attendeeRecord?: SchoolActivityAttendee, activityName?: string): { message: string; overwriteable: boolean } {
		if (!attendeeRecord) {
			return { message: '', overwriteable: true };
		}

		if (this.attendeeMap[student.id]?.activity_instance_id === this.data.instance.id) {
			return { message: '', overwriteable: false };
		}

		if (student.id === attendeeRecord.assigner_id) {
			return { message: `${student.display_name} added themselves to ${activityName}.`, overwriteable: true };
		}

		if (attendeeRecord.assigner_id === this.user.id) {
			return { message: `You added this student to ${activityName}.`, overwriteable: this.data.instance.id !== attendeeRecord.activity_instance_id };
		}

		const assignerName = this.staffNameMap[attendeeRecord.assigner_id];
		if (this.user.isAdmin()) {
			return { message: `${assignerName} added this student to ${activityName}.`, overwriteable: true };
		}

		return {
			message: `${assignerName} added this student to ${activityName}.\nIf you want to add this student, ${assignerName} must remove them first.`,
			overwriteable: false,
		};
	}

	onSubmit(): void {
		this.loading = true;
		this.closeDialog(this.selectedStudents, this.attendeeIdsToDelete);
	}

	removeSelection(selection: User | StudentList): void {
		this.selectedGroupsAndStudents = this.selectedGroupsAndStudents.filter((s) => s.id !== selection.id);
		// Remove group members from override list
		if (typeof selection === 'object' && 'users' in selection) {
			this.groups.push(selection);
			this.overrideableStudents = this.overrideableStudents.filter((r) => r?.groupId !== selection.id);
		}
		this.setSelectedStudents();
		this.setFocusOnSearch();
	}

	showImportStudentList(): void {
		const dialogRef = this.dialog.open(ImportStudentListComponent, {
			closeOnNavigation: true,
			panelClass: 'main-form-dialog-container',
			backdropClass: 'custom-backdrop',
			maxWidth: '100vw',
			data: {
				forInput: true,
			},
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((students: User[]) => {
				if (students?.length > 0) {
					students.forEach((student) => {
						if (!this.isInSelectStudents(student)) {
							const attendeeRecord = this.attendeeMap[student.id];
							// Add student if not in another activity
							if (!attendeeRecord) {
								this.selectedGroupsAndStudents.push(student);
							}
							// Add student in another activity to the override list
							else if (attendeeRecord.activity_instance_id !== this.data.instance.id) {
								const result = this.buildMessage(student, attendeeRecord, this.activitiesNameMap[attendeeRecord?.activity_id]);
								this.overrideableStudents.push({
									user: student,
									overwriteable: result.overwriteable,
									assignerId: attendeeRecord?.assigner_id,
									activityName: this.activitiesNameMap[attendeeRecord?.activity_id],
									message: result.message,
								});
							}
						}
					});
					this.setSelectedStudents();
				}
			});
	}

	overrideStudent(userId: number, singleOverride = true): void {
		const studentToOverride = this.overrideableStudents.find((student) => student.user.id === userId);
		if (studentToOverride && studentToOverride.overwriteable) {
			const overrideStudent = () => {
				this.overrideableStudents = this.overrideableStudents.filter((student) => student.user.id !== userId);
				this.selectedGroupsAndStudents.push(studentToOverride.user);
			};

			if (singleOverride) {
				// Don't show confirmation for students the teacher already assigned
				if (studentToOverride.assignerId !== this.user.id) {
					const data = {
						title: `Override Scheduled Activity?`,
						message: studentToOverride.message,
						okButtonText: 'Override',
					};
					this.openConfirmationDialog(data).then((res: boolean) => {
						if (res) {
							overrideStudent();
							this.setSelectedStudents();
						}
					});
				} else {
					overrideStudent();
					this.setSelectedStudents();
				}
			} else {
				overrideStudent();
			}
		}
	}

	overrideAll(): void {
		const data = {
			title: `Override Scheduled Activities?`,
			message: `This will override ${
				this.overrideableStudents.filter((student) => student.overwriteable).length
			} students assigned to other activities`,
			okButtonText: 'Override',
		};
		this.openConfirmationDialog(data).then((res: boolean) => {
			if (res) {
				for (const student of this.overrideableStudents) {
					this.overrideStudent(student.user.id, false);
				}
				this.setSelectedStudents();
			}
		});
	}
}
